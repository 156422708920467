import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TTabPanelTabs } from '@sim-admin-frontends/ui-shared';
import { generatePath, Link } from 'react-router-dom';
import { State } from '@sim-admin-frontends/data-access';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { CtaWrapper, StyledPanel } from '../../common/TabsStyles';
import EmergencyViewContainer from './EmergencyView.Container';
import { TEmergencyTabTypes } from '../../../types/TEmergency';

const EmergencyTabs: FC = () => {
  const { t } = useTranslation();
  const emergencyTabs = [TEmergencyTabTypes.VISIBLE, TEmergencyTabTypes.HIDDEN];
  const { setTabIndex, tabIndex } = useTabNavigation(ROUTES.emergencyOverview.path, emergencyTabs);

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: <EmergencyViewContainer state={State.Pinned} />,
        label: t('emergency.table.visible'),
        testId: 'EmergencyTabs#visible',
      },
      {
        content: <EmergencyViewContainer state={State.Published} />,
        label: t('emergency.table.hidden'),
        testId: 'EmergencyTabs#hidden',
      },
    ],
    [t],
  );

  return (
    <StyledPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex}>
      <CtaWrapper>
        <Link to={generatePath(ROUTES.emergencyEdit.path)}>
          <Button testId="EmergencyList#AddEmergency">{t('emergency.addEmergency')}</Button>
        </Link>
      </CtaWrapper>
    </StyledPanel>
  );
};

export default EmergencyTabs;
