import styled from 'styled-components';
import { FC } from 'react';
import { FieldError } from 'react-hook-form';

import { TBaseProps } from '../../../types/TBaseComponent';
import { Label } from '../../label/Label';

export interface FormErrorProps extends TBaseProps {
  error: FieldError;
}

const FormErrorWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing8};
`;

const ErrorLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.red60};
`;

const getErrorMessage = (errors: FieldError[]) =>
  errors.find((err) => (err?.message ? err.message : null))?.message;

const FormError: FC<FormErrorProps> = ({ error, testId, className }) => {
  return (
    <FormErrorWrapper className={className}>
      <ErrorLabel testId={testId}>
        {Array.isArray(error) ? getErrorMessage(error) : error.message}
      </ErrorLabel>
    </FormErrorWrapper>
  );
};

export { FormError };
