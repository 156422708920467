import { FC } from 'react';
import {
  Button,
  FormButtonsWrapper,
  FormInput,
  FormSectionHeader,
  FormWrapper,
  FormWysiwyg,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
} from '@sim-admin-frontends/ui-shared';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { TPlaceDetail } from '@sim-admin-frontends/data-access-admin-be';
import { useHistory } from 'react-router-dom';

import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import { TPlaceInfoFormValues } from '../../../types/TPlace';
import SettingsAboutCityPreview from './SettingsAboutCityPreview';
import {
  MainContent,
  PreviewPanel,
  ScreenWrapper,
  StyledPageHeader,
} from './SettingsAboutCityFormStyles';
import {
  SIDEPANEL_COLLAPSED_WIDTH_PX,
  SIDEPANEL_EXPANDED_WIDTH_PX,
} from '../../../constants/Constants';
import ROUTES from '../../../routing/routes';
import useYTVideoInEditor from '../../../hooks/useYTVideoInEditor';

const schema = (t: TFunction) =>
  Yup.object().shape({
    aboutHeader: Yup.string().required(t('common.validation.required')),
    reportsEmail: Yup.string().email(t('common.validation.email')),
  });

type SettingsAboutCityProps = {
  onSubmit: (values: TPlaceInfoFormValues) => Promise<void>;
  onCancel?: () => void;
  place?: TPlaceDetail;
};

const SettingsAboutCity: FC<SettingsAboutCityProps> = ({ place, onSubmit }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const initialValues: TPlaceInfoFormValues = {
    aboutHeader: place?.info?.aboutHeader || place?.name || '',
    description: place?.info?.description || '',
    reportsEmail: place?.info?.reportsEmail || '',
    about: place?.info?.about || SERIALIZED_WYSIWYG_EMPTY_VALUE,
  };

  const methods = useForm<TPlaceInfoFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });

  const { handleSubmit, register, formState, getValues, control, reset } = methods;
  const { errors, isSubmitting, isDirty } = formState;
  const [previewName, previewInformation, previewDescription] = useWatch({
    name: ['aboutHeader', 'about', 'description'],
    control,
  });

  const goHome = () => {
    history.push(ROUTES.home.path);
  };

  const submit = (values: TPlaceInfoFormValues) => {
    onSubmit(values);
    reset(values);
  };

  const onSubmitClick = () => {
    handleSubmit(submit)();
  };

  const { youtubeModal } = useYTVideoInEditor(JSON.parse(getValues('about')));

  return (
    <ScreenWrapper>
      <MainContent>
        {youtubeModal}
        <FormWrapper>
          <StyledPageHeader data-testid={'SettingsAboutCity#title'}>
            {t('aboutCity.title')}
          </StyledPageHeader>
          <FormProvider {...methods}>
            <FormInput
              label={t('aboutCity.cityName')}
              {...register('aboutHeader')}
              error={errors.aboutHeader}
              testId="SettingsAboutCityEdit#cityName"
            />
            <FormInput
              label={t('aboutCity.description')}
              placeholder={t('aboutCity.descriptionPlaceholder')}
              {...register('description')}
              error={errors.description}
              testId="SettingsAboutCityEdit#description"
            />
            <FormWysiwyg
              control={control}
              name="about"
              label={t('aboutCity.information')}
              initialValue={JSON.parse(getValues('about'))}
              testId="SettingsAboutCityEdit#wysiwyg"
            />
            <FormSectionHeader
              title={t('aboutCity.emailTitle')}
              description={t('aboutCity.emailDescription')}
            />
            <FormInput
              placeholder={t('aboutCity.email')}
              {...register('reportsEmail')}
              error={errors.reportsEmail}
              testId="SettingsAboutCity#email"
            />

            <FormButtonsWrapper>
              <Button
                testId="SettingsAboutCity#cancel"
                variant="tertiary"
                onClick={goHome}
                disabled={isSubmitting}
              >
                {t('updates.form.cancel')}
              </Button>
              <Button
                testId="SettingsAboutCity#save"
                type="submit"
                onClick={onSubmitClick}
                isLoading={isSubmitting}
                disabled={isSubmitting || !isEmpty(errors) || !isDirty}
              >
                {t('common.save')}
              </Button>
            </FormButtonsWrapper>
          </FormProvider>
        </FormWrapper>
      </MainContent>
      <PreviewPanel
        collapsedWidth={SIDEPANEL_COLLAPSED_WIDTH_PX}
        expandedWidth={SIDEPANEL_EXPANDED_WIDTH_PX}
        title={t('aboutCity.previewTitle')}
        side="right"
      >
        <SettingsAboutCityPreview
          cityName={previewName}
          description={previewDescription}
          information={previewInformation}
        />
      </PreviewPanel>
      <RouteLeavingGuard when={isDirty && !isSubmitting} />
    </ScreenWrapper>
  );
};

export default SettingsAboutCity;
