import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BookmarkIcon, ShareIcon } from '@sim-admin-frontends/ui-shared';
import { useInstitutionBrandingQuery } from '@sim-admin-frontends/data-access';

import { TAnnouncementFormValues } from '../../../types/TAnnouncements';
import PageContentPreview from '../../layout/pageContent/PageContentPreview';
import PreviewItemText from '../../previewItem/PreviewItemText';
import PreviewItemCategory from '../../previewItem/PreviewItemCategory';
import { ContentPreview } from '../../layout/pageContent/PageContentPreviewStyles';
import { FALLBACK_INSTITUTION_NAME } from '../../../constants/Constants';

const AnnouncementPreview: FC<Partial<TAnnouncementFormValues>> = ({
  title,
  images,
  videos,
  content,
  categories,
  publisher,
}) => {
  const { t } = useTranslation();
  const testIdPrefix = 'AnnouncementPreview';

  const publisherUuid = publisher?.value || '';
  const institutionName = publisher?.label || FALLBACK_INSTITUTION_NAME;

  const { data: institutionBranding } = useInstitutionBrandingQuery({ id: publisherUuid });

  const institutionLogo = institutionBranding?.institution?.branding?.avatar.links?.self || '';

  return (
    <PageContentPreview
      name={institutionName}
      logo={institutionLogo}
      images={images}
      videos={videos}
      title={title}
      titlePlaceholder={t('updates.preview.titlePlaceholder')}
      testIdPrefix={testIdPrefix}
      buttons={[
        { icon: <BookmarkIcon />, testId: 'Like', text: t('common.save') },
        { icon: <ShareIcon />, testId: 'Share', text: t('common.share') },
      ]}
    >
      <ContentPreview>
        <PreviewItemText
          slateText={content}
          textPlaceholder={t('updates.preview.contentPlaceholder')}
          testIdPrefix={testIdPrefix}
        />
        {categories?.length ? (
          categories.map((item) => (
            <PreviewItemCategory key={item.value} label={item.label} testIdPrefix={testIdPrefix} />
          ))
        ) : (
          <PreviewItemCategory
            label={t('updates.preview.categoryPlaceholder')}
            testIdPrefix={testIdPrefix}
          />
        )}
      </ContentPreview>
    </PageContentPreview>
  );
};

export default AnnouncementPreview;
