import { Controller, FieldValues } from 'react-hook-form';
import { ImageFile } from '@sim-admin-frontends/utils-shared';
import styled from 'styled-components';

import { FormItemLabel, FormItemWrapper } from '../formStyles';
import { FormError } from '../form-error/FormError';
import { Upload, UploadProps } from '../upload/Upload';
import { TFormControlElement } from '../../../types/TForm';

const InnerWrapper = styled.div<{ noMargin?: boolean }>`
  padding-top: ${({ theme, noMargin }) => (noMargin ? '0' : theme.spaces.spacing16)};
`;

export interface FormUploadProps<T> extends UploadProps, TFormControlElement<T> {
  label?: string;
  noMargin?: boolean;
}

const FormUpload = <T extends FieldValues>({
  control,
  name,
  onChange,
  testId,
  label,
  noMargin,
  ...rest
}: FormUploadProps<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange: onChangeField, value }, fieldState: { error } }) => {
      const onChangeMerged = (files?: (File | ImageFile)[]) => {
        onChangeField(files);
        if (onChange) onChange(files);
      };

      return (
        <FormItemWrapper noMargin={noMargin}>
          {label && <FormItemLabel testId={`${testId}-label`}>{label}</FormItemLabel>}
          <InnerWrapper noMargin={noMargin}>
            <Upload
              {...rest}
              onChange={onChangeMerged}
              files={value}
              fieldName={name}
              error={error}
              testId={testId}
            />
          </InnerWrapper>
          {error && <FormError error={error} testId={`${testId}-error`} />}
        </FormItemWrapper>
      );
    }}
  />
);

export { FormUpload };
