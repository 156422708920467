import { PageHeader, TableActionHeader, useSearchFilter } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Redirect } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import RaffleTabs from '../../components/raffles/view/RafflesTabs';
import { useAuthInfo } from '../../contexts/userContext';

const RafflesListPage: FC = () => {
  const { visitorMode } = useAuthInfo();
  const { t } = useTranslation();
  const { filter, renderFilterComponent } = useSearchFilter({
    testId: 'RafflesListPage#Search',
  });

  if (visitorMode) {
    return <Redirect to="/404" />;
  }

  return (
    <PageWrapper>
      <PageHeader
        title={t('raffles.pageTitle')}
        caption={t('raffles.pageCaption')}
        testId="RafflesTabs#PageHeader"
      />
      <TableActionHeader prependItem={renderFilterComponent()} />
      <RaffleTabs filter={filter} />
    </PageWrapper>
  );
};

export default RafflesListPage;
