import { captureException } from '@sentry/react';
import {
  MessagingProblemConnectorStatus,
  MessagingProblemConnectorType,
  UploadType,
} from '@sim-admin-frontends/data-access';
import {
  UpdateMessagingProblemMutationVariables,
  useMessagingCategoriesByQuery,
  useMessagingProblemQuery,
  useUpdateMessagingProblemMutation,
} from '@sim-admin-frontends/data-access-admin-be';
import {
  Error,
  Spinner,
  SpinnerWrapper,
  TToastType,
  loadingToast,
  updateToast,
  getErrorMessage,
} from '@sim-admin-frontends/ui-shared';
import { useUploadImage } from '@sim-admin-frontends/utils-shared';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { transformToSelectItem } from '../../../utils/selectUtils';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { useGenerateReportProblemActions } from '../../../hooks/actionButtons/useGenerateReportProblemActions';
import { REPORT_A_PROBLEM_TOAST_SHARED_ID } from '../../../constants/Constants';
import { useAuthInfo } from '../../../contexts/userContext';
import { TProblemsFormValues } from '../../../types/TProblems';
import { changeReportProblemTab, transformFormValues } from '../../../utils/reportProblemUtils';
import ReportProblemEdit from './ReportProblemEdit';

type Props = {
  id?: string;
};

const ReportProblemEditContainer: FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { uploadFormImages } = useUploadImage();
  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();
  const location = useLocation();
  const isPreview = location.search === '?preview';
  const placeUuid = places?.[0].id;

  const { detailActions } = useGenerateReportProblemActions({ id: id || '', isEditPage: true });

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries({ queryKey: ['messagingProblemsBy'] });
  };

  const { mutate: handleSubmitProblem } = useUpdateMessagingProblemMutation();

  const { data, isLoading, isError, refetch } = useMessagingProblemQuery(
    { id: id || '' },
    { enabled: !!id },
  );

  const categoriesFilter = { filter: { placeUuid: placeUuid || '' } };

  const {
    data: categoriesData,
    isLoading: categoriesIsLoading,
    isError: categoriesIsError,
    refetch: categoriesRefetch,
  } = useMessagingCategoriesByQuery(categoriesFilter);

  const onSubmit = async (formValues: TProblemsFormValues) => {
    try {
      loadingToast(t('updates.form.toastSubmitting'), {
        toastId: REPORT_A_PROBLEM_TOAST_SHARED_ID,
      });
      const uploadedImages = await uploadFormImages(UploadType.News, formValues.imageObjects, {
        institutionId: institutionUuid,
      });
      const mutationVariables: UpdateMessagingProblemMutationVariables = {
        data: transformFormValues(
          formValues,
          uploadedImages,
          placeUuid,
          data?.messagingProblem?.installationUuid,
          institutionUuid,
        ),
        id: id || '',
      };
      await handleSubmitProblem(mutationVariables, {
        onSuccess: async () => {
          await invalidateQuery();
          changeReportProblemTab(history, formValues.status?.value);
        },
      });
      updateToast(
        REPORT_A_PROBLEM_TOAST_SHARED_ID,
        t('reportProblem.form.toastUpdate'),
        TToastType.SUCCESS,
      );
    } catch (err) {
      updateToast(REPORT_A_PROBLEM_TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const refetchData = () => {
    refetch();
    categoriesRefetch();
  };

  const connectorTypeOptions = useMemo(
    () => Object.keys(MessagingProblemConnectorType).map(transformToSelectItem),
    [],
  );

  const connectorStatusOptions = useMemo(
    () => Object.keys(MessagingProblemConnectorStatus).map(transformToSelectItem),
    [],
  );

  const loading = isLoading || categoriesIsLoading;
  const error = isError || categoriesIsError;

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (error) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetchData} />
      </SpinnerWrapper>
    );
  }
  return (
    <ReportProblemEdit
      isPreview={isPreview}
      problem={data?.messagingProblem}
      categories={categoriesData?.messagingCategoriesBy?.messagingCategories}
      onSubmit={onSubmit}
      reportProblemFormActions={detailActions}
      connectorStatusOptions={connectorStatusOptions}
      connectorTypeOptions={connectorTypeOptions}
    />
  );
};

export default ReportProblemEditContainer;
