import { useHistory } from 'react-router-dom';

import ROUTES from '../routing/routes';
import { TAnnouncementsFormTypes, TAnnouncementsTabTypes } from '../types/TAnnouncements';
import { changeAnnouncementsTab } from '../utils/announcementsUtils';
import {
  TUseAnnouncementSubmitAfterSubmitCb,
  useAnnouncementTypeSubmit,
} from './useAnnouncementTypeSubmit';
import { changePollsTab } from '../utils/pollsUtils';
import { announcementFormTypeToToastMessage } from '../utils/toastMessageUtils';
import { changeDonationsTab } from '../utils/donationsUtils';
import { changeMarketTab } from '../utils/marketUtils';

export const useAnnouncementSubmit = (announcementType: TAnnouncementsFormTypes) => {
  const history = useHistory();

  const afterSubmitCb: TUseAnnouncementSubmitAfterSubmitCb = (announcementTabType) => {
    switch (announcementType) {
      case TAnnouncementsFormTypes.EMERGENCY:
        history.push(ROUTES.emergencyOverview.path);
        return;
      case TAnnouncementsFormTypes.POLL:
        changePollsTab(history, announcementTabType);
        return;
      case TAnnouncementsFormTypes.DONATION:
        changeDonationsTab(history, announcementTabType);
        return;
      case TAnnouncementsFormTypes.MARKET:
        changeMarketTab(history, announcementTabType);
        return;
      case TAnnouncementsFormTypes.ANNOUNCEMENT:
      default:
        changeAnnouncementsTab(history, announcementTabType);
    }
  };

  const afterDraftSubmitCb: TUseAnnouncementSubmitAfterSubmitCb = () => {
    switch (announcementType) {
      case TAnnouncementsFormTypes.EMERGENCY:
        history.push(ROUTES.emergencyOverview.path);
        return;
      case TAnnouncementsFormTypes.POLL:
        changePollsTab(history, TAnnouncementsTabTypes.DRAFTS);
        return;
      case TAnnouncementsFormTypes.ANNOUNCEMENT:
      default:
        changeAnnouncementsTab(history, TAnnouncementsTabTypes.DRAFTS);
    }
  };

  const { handleSubmitAnnouncement, handleSubmitDraftAnnouncement } = useAnnouncementTypeSubmit({
    announcementType,
    afterSubmitCb,
    afterDraftSubmitCb,
    toastMessageType: announcementFormTypeToToastMessage(announcementType),
  });

  return {
    handleSubmitAnnouncement,
    handleSubmitDraftAnnouncement,
  };
};
