import { FC, useMemo } from 'react';
import { FormProvider, get, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormSectionHeader,
  FormSelect,
  FormTextarea,
  FormWrapper,
  TableIconProps,
  ActionButtons,
} from '@sim-admin-frontends/ui-shared';
import {
  TInstitution,
  TInstitutionListItem,
  TQuickQuestion,
} from '@sim-admin-frontends/data-access';
import {
  getInstitutionInitValues,
  getLangOptionByVal,
  getLangOptions,
} from '@sim-admin-frontends/utils-shared';

import { ContentHeader } from '../../layout/pageContent/PageContentEditable';
import { TQuickQuestionsFormValues } from '../../../types/TQuickQuestions';
import { TChatFormValues } from '../../../types/TChat';
import { TLoggedUser } from '../../../types/TUser';
import FormButtons from '../../announcements/edit/FormButtons';
import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import { usePlaceInfo } from '../../../contexts/placeContext';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spaces.spacing48};
`;

const schema = (t: TFunction) =>
  Yup.object().shape({
    question: Yup.string().required(t('common.validation.required')),
  });

type Props = {
  user: TLoggedUser | null;
  quickQuestion?: TQuickQuestion;
  submit: (values: TChatFormValues) => void;
  onDiscard: () => void;
  institution: TInstitution;
  userInstitutions: TInstitutionListItem[];
  chatFormActions: TableIconProps[];
};

const CityChatEdit: FC<Props> = ({
  quickQuestion,
  institution,
  user,
  submit,
  onDiscard,
  userInstitutions,
  chatFormActions,
}) => {
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const countryCode = places?.[0].countryCode || '';
  const institutionLang = institution?.lang || undefined;

  const initialValues: TChatFormValues = {
    question: quickQuestion?.question ?? '',
    publisher: { label: institution?.name || '', value: institution?.id || '' },
    lang: getLangOptionByVal(quickQuestion?.lang || institutionLang, countryCode),
  };

  const methods = useForm<TQuickQuestionsFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });

  const { handleSubmit, register, formState, getValues, control } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const pageTitle = quickQuestion ? t('chat.editPageTitle') : t('chat.createPageTitle');

  const institutionOptions = useMemo(
    () =>
      getInstitutionInitValues(
        user?.attributes?.['custom:institutionUuids'],
        institution,
        userInstitutions,
      ),
    [userInstitutions, institution, user?.attributes],
  );

  const showPublishers = institutionOptions?.length > 1;

  const onSubmitClick = () => {
    handleSubmit(submit)();
  };

  return (
    <Wrapper>
      <FormWrapper>
        <ActionButtons actionButtons={chatFormActions} />
        <ContentHeader>{pageTitle}</ContentHeader>
        <FormProvider {...methods}>
          <FormTextarea
            label={t('cityChat.message')}
            placeholder={t('cityChat.messagePlaceholder')}
            {...register('question')}
            error={errors.question}
            testId={'CityChatEdit#title'}
          />
          <>
            <FormSectionHeader
              title={t('updates.form.language')}
              description={t('updates.form.languageDescription')}
            />
            <FormSelect
              control={control}
              name="lang"
              options={getLangOptions()}
              defaultValue={initialValues.lang}
              testId={'CityChatEdit#languageSlect'}
              allSelectable
            />
          </>
          {showPublishers && (
            <>
              <FormSectionHeader
                title={t('updates.form.publisher')}
                description={t('updates.form.publisherDescription')}
              />
              <FormSelect
                control={control}
                name="publisher"
                error={get(errors, 'publisher')}
                options={institutionOptions || []}
                defaultValue={initialValues.publisher}
                testId="CityChatEdit#publisher"
              />
            </>
          )}
          <FormButtons
            isSubmitting={isSubmitting}
            getValues={getValues}
            errors={errors}
            publishmentState={quickQuestion?.publishmentState}
            isScheduling={false}
            onDiscard={onDiscard}
            onSubmitClick={onSubmitClick}
            isQuickQuestion
          />
        </FormProvider>
        <RouteLeavingGuard when={isDirty && !isSubmitting} />
      </FormWrapper>
    </Wrapper>
  );
};

export default CityChatEdit;
