import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';
import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import {
  AnnouncementsByFilterInput,
  AnnouncementSortBy,
  CategoryType,
  SortOrder,
  State,
  TAnnouncementListItem,
  useCategoriesQuery,
  useInfiniteAnnouncementsQuery,
} from '@sim-admin-frontends/data-access';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';

import { getGqlSorting } from '../../announcements/view/helper';
import EmergencyView from './EmergencyView';
import { createTableColumns, findEmergencyCategory } from './helper';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import VideoTutorialBanner from '../../videoTutorials/VideoTutorialBanner';

type Props = {
  state: State;
};

const EmergencyViewContainer: FC<Props> = ({ state }) => {
  const { t } = useTranslation();

  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();
  const [orderBy, setOrderBy] = useState({
    by: AnnouncementSortBy.CreatedAt,
    order: SortOrder.Desc,
  });
  const institutionId = institutionUuid || '';

  const {
    isLoading: categoriesLoading,
    error: categoriesError,
    data: categories,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.Emergency,
    },
  });
  const emergencyCategory = useMemo(() => findEmergencyCategory(categories), [categories]);

  const filter: AnnouncementsByFilterInput = useMemo(
    () => ({
      state,
      institutions: [institutionId],
      ...(emergencyCategory && { categories: [emergencyCategory.id] }),
    }),
    [institutionId, emergencyCategory],
  );

  const { fetchNextPage, hasNextPage, data, isError, isLoading, isFetchingNextPage, refetch } =
    useInfiniteAnnouncementsQuery(filter, orderBy, !!emergencyCategory);

  const { currentPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  const onSortingChanged = useCallback(
    (newSorting: SortingRule<TAnnouncementListItem>[]) => {
      setOrderBy(getGqlSorting(newSorting));
      setCurrentPageIndex(0);
    },
    [setCurrentPageIndex],
  );

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [institutionUuid]);

  useEffect(() => {
    refetch();
  }, [state]);

  if (isError || categoriesError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }
  const columns = createTableColumns(t, refetch, places?.[0].timezoneCode);

  const itemsCount = data?.pages[0].announcementsBy.count;

  const announcements = data?.pages[currentPageIndex]?.announcementsBy.announcements.filter(
    (a): a is TAnnouncementListItem => a.__typename === 'Announcement',
  );

  const loading = isLoading || isFetchingNextPage || categoriesLoading;
  const isTutorialBannerVisible = !loading && !announcements?.length;

  return (
    <>
      <EmergencyView
        hasNextPage={!!hasNextPage}
        loading={loading}
        itemsCount={itemsCount as number}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        columns={columns}
        data={announcements || []}
        onSortingChanged={onSortingChanged}
        initialTableSorting={[
          {
            id: 'publishedAt',
            desc: true,
          },
        ]}
      />
      <VideoTutorialBanner
        text={t('videoTutorials.banner.emergencyAnnouncements')}
        visible={isTutorialBannerVisible}
        testId={'EmergencyView#VideoTutorialBanner'}
      />
    </>
  );
};

export default EmergencyViewContainer;
