import { FetchNextPageOptions } from 'react-query/types/core/types';

import { useTablePaging } from './useTablePaging';

export const useOffsetTablePaging = (
  fetchNextPage: (options?: FetchNextPageOptions) => void,
  pageSize: number,
  hasNextPage?: boolean,
) => {
  const { currentPageIndex, maxPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } =
    useTablePaging(() => {
      const offset = currentPageIndex * pageSize + pageSize;
      return fetchNextPage({ pageParam: offset.toString() });
    }, hasNextPage);

  return { onNextPress, onPreviousPress, currentPageIndex, setCurrentPageIndex, maxPageIndex };
};
