import { captureException } from '@sentry/react';
import {
  AddAnnouncementMutationVariables,
  CategoryType,
  isAnnouncement,
  State,
  UploadType,
  useAnnouncementQuery,
  useCategoriesQuery,
  useInstitutionQuery,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useUploadImage, useUploadVideo } from '@sim-admin-frontends/utils-shared';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ANNOUNCEMENT_TOAST_SHARED_ID } from '../../../constants/Constants';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import { useGenerateEmergencyActions } from '../../../hooks/actionButtons/useGenerateEmergencyActions';
import { useAnnouncementSubmit } from '../../../hooks/useAnnouncementSubmit';
import NotFound from '../../../routing/NotFound';
import ROUTES from '../../../routing/routes';
import { TAnnouncementsFormTypes, TEmergencyFormValues } from '../../../types/TAnnouncements';
import { findEmergencyCategory } from '../view/helper';
import EmergencyEdit from './EmergencyEdit';
import { transformFormValues } from './helper';

type Props = {
  id?: string;
};

const EmergencyEditContainer: FC<Props> = ({ id }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { institutionUuid, visitorMode } = useAuthInfo();
  const { places } = usePlaceInfo();

  const navigateOnDone = () => {
    history.push(ROUTES.emergencyOverview.path);
  };
  const countryCode = places?.[0].countryCode || '';

  const { uploadFormImages } = useUploadImage();
  const { uploadFormVideos } = useUploadVideo();
  const { handleSubmitAnnouncement } = useAnnouncementSubmit(TAnnouncementsFormTypes.EMERGENCY);

  const institutionId = institutionUuid || '';
  const {
    data,
    isLoading: announcementIsLoading,
    error: announcementError,
    refetch,
  } = useAnnouncementQuery({ id: id || '' }, { enabled: !!id });

  const emergencyData = isAnnouncement(data?.announcement) ? data?.announcement : undefined;

  const {
    data: institution,
    isLoading: isInstitutionLoading,
    error: institutionError,
  } = useInstitutionQuery({
    id: institutionId,
  });

  const {
    isLoading: categoriesLoading,
    error: categoriesError,
    data: categories,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.Emergency,
      visitorMode,
    },
  });
  const emergencyCategory = useMemo(() => findEmergencyCategory(categories), [categories]);

  const onSubmit = async (formValues: TEmergencyFormValues) => {
    try {
      loadingToast(t('updates.form.toastSubmitting'), {
        toastId: ANNOUNCEMENT_TOAST_SHARED_ID,
      });
      const uploadedImages = await uploadFormImages(UploadType.News, formValues.images, {
        institutionId,
      });
      const uploadedVideos = await uploadFormVideos(formValues.videos, { institutionId });
      const mutationVariables: AddAnnouncementMutationVariables = {
        announcement: transformFormValues(
          formValues,
          uploadedImages,
          uploadedVideos,
          institutionId,
          emergencyCategory,
        ),
      };
      await handleSubmitAnnouncement(emergencyData?.publishmentState, mutationVariables, id);
    } catch (err) {
      updateToast(ANNOUNCEMENT_TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const pinned = emergencyData?.publishmentState === State.Pinned;

  const { detailActions, renderModal } = useGenerateEmergencyActions({
    postId: id || '',
    pinned,
    refetch,
    isEditPage: true,
  });

  const isLoading = announcementIsLoading || categoriesLoading || isInstitutionLoading;

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  const isError = !!announcementError || !!categoriesError || institutionError;

  if (isError)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  if (id && !emergencyData)
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );
  return (
    <>
      <EmergencyEdit
        onSubmit={onSubmit}
        onDiscard={navigateOnDone}
        announcement={emergencyData}
        institution={institution?.institution}
        countryCode={countryCode}
        emergencyFormActions={id ? detailActions : []}
      />
      {renderModal()}
    </>
  );
};

export default EmergencyEditContainer;
