import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { BookmarkIcon, ShareIcon } from '@sim-admin-frontends/ui-shared';
import { getIsSameDay } from '@sim-admin-frontends/utils-shared';
import { useInstitutionBrandingQuery } from '@sim-admin-frontends/data-access';

import { TEventsFormValues } from '../../../types/TEvents';
import PageContentPreview from '../../layout/pageContent/PageContentPreview';
import PreviewItemLocation from '../../previewItem/PreviewItemLocation';
import PreviewItemDate from '../../previewItem/PreviewItemDate';
import PreviewItemText from '../../previewItem/PreviewItemText';
import { ContentPreview } from '../../layout/pageContent/PageContentPreviewStyles';
import { FALLBACK_INSTITUTION_NAME } from '../../../constants/Constants';

const EditPreview: FC<Partial<TEventsFormValues>> = ({
  title,
  images,
  videos,
  content,
  location,
  startDate,
  endDate,
  publisher,
}) => {
  const { t } = useTranslation();
  const testIdPrefix = 'EventPreview';

  const dateFrom = startDate && format(startDate, 'iii, MMM d, y');
  const timeFrom = startDate && format(startDate, 'hh:mm a');
  const dateTo = endDate && format(endDate, 'iii, MMM d, y');
  const timeTo = endDate && format(endDate, 'hh:mm a');
  const isSameDay = startDate && endDate ? getIsSameDay(startDate, endDate) : false;

  const publisherUuid = publisher?.value || '';
  const institutionName = publisher?.label || FALLBACK_INSTITUTION_NAME;

  const { data: institutionBranding } = useInstitutionBrandingQuery({ id: publisherUuid });
  const institutionLogo = institutionBranding?.institution?.branding?.avatar.links?.self || '';

  return (
    <PageContentPreview
      name={institutionName}
      logo={institutionLogo}
      title={title}
      images={images}
      videos={videos}
      titlePlaceholder={t('events.preview.titlePlaceholder')}
      testIdPrefix={testIdPrefix}
      buttons={[
        { icon: <BookmarkIcon />, testId: 'Interested', text: t('common.save') },
        { icon: <ShareIcon />, testId: 'Share', text: t('common.share') },
      ]}
    >
      <ContentPreview>
        <PreviewItemDate
          dateFrom={dateFrom}
          timeFrom={timeFrom}
          dateTo={dateTo}
          timeTo={timeTo}
          isSameDay={isSameDay}
          testIdPrefix={testIdPrefix}
        />
        <PreviewItemLocation location={location} testIdPrefix={testIdPrefix} />
        <PreviewItemText
          slateText={content}
          textPlaceholder={t('events.preview.contentPlaceholder')}
          testIdPrefix={testIdPrefix}
        />
      </ContentPreview>
    </PageContentPreview>
  );
};

export default EditPreview;
