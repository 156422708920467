import {
  Button,
  Spinner,
  SpinnerWrapper,
  TabPanel,
  TTabPanelTabs,
} from '@sim-admin-frontends/ui-shared';
import { FC, useCallback, useMemo } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  State,
  useInstitutionAnnouncementCountsQuery,
  useUnpublishAnnouncementMutation,
} from '@sim-admin-frontends/data-access';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';

import { changeAnnouncementsTab } from '../../../utils/announcementsUtils';
import { AnnouncementsViewContainer } from './AnnouncementsView.container';
import ROUTES from '../../../routing/routes';
import AnnouncementDraftsViewContainer from './AnnouncementDraftsView.container';
import { useAuthInfo } from '../../../contexts/userContext';
import { CtaWrapper } from '../../common/TabsStyles';
import { TAnnouncementsTabTypes } from '../../../types/TAnnouncements';
import { useBulkUnpublish } from '../../../hooks/useBulkUnpublish';
import { AnalyticsEvent } from '../../../utils/analytics/analyticsEvents';

const announcementsTabs = [
  TAnnouncementsTabTypes.PUBLISHED,
  TAnnouncementsTabTypes.SCHEDULED,
  TAnnouncementsTabTypes.DRAFTS,
  TAnnouncementsTabTypes.UNPUBLISHED,
];

type Props = {
  filter: string;
};

const AnnouncementTabs: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { institutionUuid } = useAuthInfo();

  const { data, isLoading } = useInstitutionAnnouncementCountsQuery({
    id: institutionUuid,
  });
  const publishedCount = data?.institution?.publishedNewsCount;
  const unpublishedCount = data?.institution?.unpublishedNewsCount;
  const scheduledCount = data?.institution?.scheduledNewsCount;
  const draftsCount = data?.institution?.draftsNewsCount;
  const wasAnythingPublished = !!publishedCount || !!unpublishedCount;

  const {
    postsToUnpublish,
    onUnpublishSelect,
    renderBulkUnpublishButton,
    isUnpublishMutationSettled,
    clearSelection,
  } = useBulkUnpublish(useUnpublishAnnouncementMutation, AnalyticsEvent.ANNOUNCEMENT_ACTION);

  const { setTabIndex, tabIndex } = useTabNavigation(
    ROUTES.announcementsOverview.path,
    announcementsTabs,
    clearSelection,
  );

  const getViewContainer = useCallback(
    (state: State) =>
      state === State.Draft ? (
        <AnnouncementDraftsViewContainer wasAnythingPublished={wasAnythingPublished} />
      ) : (
        <AnnouncementsViewContainer
          searchFilter={filter}
          state={state}
          wasAnythingPublished={wasAnythingPublished}
          postsToUnpublish={postsToUnpublish}
          onUnpublishSelect={onUnpublishSelect}
          isUnpublishMutationSettled={isUnpublishMutationSettled}
        />
      ),
    [filter, isUnpublishMutationSettled, onUnpublishSelect, postsToUnpublish, wasAnythingPublished],
  );

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: getViewContainer(State.Published),
        label: t('updates.tabs.published'),
        testId: 'AnnouncementsTabs#published',
      },
      {
        content: getViewContainer(State.Scheduled),
        label: t('updates.tabs.scheduled'),
        count: scheduledCount,
        testId: 'AnnouncementsTabs#scheduled',
      },
      {
        content: getViewContainer(State.Draft),
        label: t('updates.tabs.drafts'),
        count: draftsCount,
        testId: 'AnnouncementsTabs#drafts',
      },
      {
        content: getViewContainer(State.Unpublished),
        label: t('updates.tabs.unpublished'),
        testId: 'AnnouncementsTabs#unpublished',
      },
    ],
    [getViewContainer, t, scheduledCount, draftsCount],
  );

  const redirectToUnpublishedTab = () => {
    changeAnnouncementsTab(history, TAnnouncementsTabTypes.UNPUBLISHED);
  };

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <TabPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex}>
      <CtaWrapper>
        {renderBulkUnpublishButton(redirectToUnpublishedTab)}
        <Link to={generatePath(ROUTES.announcementsEdit.path)}>
          <Button testId="AnnouncementsTabs#AddAnnouncement">{t('updates.addUpdate')}</Button>
        </Link>
      </CtaWrapper>
    </TabPanel>
  );
};

export default AnnouncementTabs;
