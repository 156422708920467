import { useEffect, useState } from 'react';
import { Video } from '@sim-admin-frontends/data-access';

import { decodeFileToBlob, ImageFile } from '../file-utils';

const useDecodeFilesToBlobs = (
  files: (File | ImageFile | Video | null | undefined)[] | null | undefined,
) => {
  const [finalBlobs, setFinalBlobs] = useState<string[]>([]);
  useEffect(() => {
    (async () => {
      const blobs = files?.map(async (file) => decodeFileToBlob(file));
      const resultStrings = await Promise.all(blobs || []);
      setFinalBlobs(resultStrings);
    })();
  }, [files]);
  return finalBlobs;
};

export { useDecodeFilesToBlobs };
