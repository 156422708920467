import { useHistory } from 'react-router-dom';

import { TAnnouncementsFormTypes, TAnnouncementsTabTypes } from '../types/TAnnouncements';
import { changeRafflesTab } from '../utils/raffleUtils';
import {
  TUseAnnouncementSubmitAfterSubmitCb,
  useAnnouncementTypeSubmit,
} from './useAnnouncementTypeSubmit';
import { TToastMessageType } from '../types/TToastMessage';

export const useRaffleSubmit = () => {
  const history = useHistory();

  const afterSubmitCb: TUseAnnouncementSubmitAfterSubmitCb = () => {
    changeRafflesTab(history, TAnnouncementsTabTypes.PUBLISHED);
  };

  const { handleSubmitAnnouncement } = useAnnouncementTypeSubmit({
    announcementType: TAnnouncementsFormTypes.ANNOUNCEMENT,
    toastMessageType: TToastMessageType.RAFFLE,
    afterSubmitCb,
  });

  return { handleSubmitAnnouncement };
};
