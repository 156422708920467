import { FC } from 'react';
import styled from 'styled-components';

const StyledIframe = styled.iframe`
  border: none;
  display: block;
  width: 100%;
`;

type Props = {
  source: string;
};

const RichTextVideo: FC<Props> = ({ source }) => <StyledIframe title={source} src={source} />;

export default RichTextVideo;
