import styled from 'styled-components';
import { FormButtonsWrapper } from '@sim-admin-frontends/ui-shared';

export const ButtonsWrapper = styled(FormButtonsWrapper)`
  justify-content: space-between;
`;

export const SaveDraftButtonWrapper = styled.div`
  flex: 1;
`;

export const SwitchableSectionContentWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
`;
