import { FC, useMemo, useCallback, useState, useEffect } from 'react';
import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import {
  AnnouncementsByFilterInput,
  ANNOUNCEMENTS_PAGE_SIZE,
  CategoryType,
  State,
  TPollListItem,
  useCategoriesQuery,
  useInfinitePollsQuery,
} from '@sim-admin-frontends/data-access';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';
import { useOffsetTablePaging } from '@sim-admin-frontends/utils-shared';

import { useAuthInfo } from '../../../contexts/userContext';
import {
  getGqlSorting,
  getInitialGqlSorting,
  getInitialTableSorting,
} from '../../announcements/view/helper';
import { createTableColumns, findPollCategory } from '../../../utils/pollsUtils';
import { PollsView } from './PollsView';
import VideoTutorialBanner from '../../videoTutorials/VideoTutorialBanner';
import { TUnpublishContainerProps } from '../../../types/TUnpublish';

export interface PollsViewContainerProps extends TUnpublishContainerProps {
  state: State;
  wasAnythingPublished: boolean;
}

const PollsViewContainer: FC<PollsViewContainerProps> = ({
  state,
  wasAnythingPublished,
  postsToUnpublish,
  onUnpublishSelect,
}) => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const [orderBy, setOrderBy] = useState(getInitialGqlSorting(state));
  const institutionUuidWithFallback = institutionUuid ?? '';

  const {
    isLoading: categoriesLoading,
    error: categoriesError,
    data: categories,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.Poll,
    },
  });

  const pollCategory = useMemo(() => findPollCategory(categories), [categories]);

  const filter: AnnouncementsByFilterInput = useMemo(
    () => ({
      institutions: [institutionUuidWithFallback],
      state,
      categories: [pollCategory?.id ?? ''],
    }),
    [institutionUuidWithFallback, state, pollCategory],
  );

  const { fetchNextPage, hasNextPage, data, isError, isLoading, isFetchingNextPage, refetch } =
    useInfinitePollsQuery(filter, orderBy);

  const { currentPageIndex, maxPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } =
    useOffsetTablePaging(fetchNextPage, ANNOUNCEMENTS_PAGE_SIZE, hasNextPage);

  const onSortingChanged = useCallback(
    (newSorting: SortingRule<TPollListItem>[]) => {
      setOrderBy(getGqlSorting(newSorting, state));
      setCurrentPageIndex(0);
    },
    [setCurrentPageIndex, state],
  );

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [institutionUuid]);

  if (isError || categoriesError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }
  const columns = createTableColumns(t, refetch, state, postsToUnpublish, onUnpublishSelect);

  const itemsCount = data?.pages[0].announcementsBy.count;

  const polls = data?.pages[currentPageIndex]?.announcementsBy.announcements.filter(
    (a): a is TPollListItem => a.__typename === 'Announcement',
  );
  const loading = isLoading || isFetchingNextPage || categoriesLoading;
  const isTutorialBannerVisible = !loading && !wasAnythingPublished;

  return (
    <>
      <PollsView
        hasNextPage={hasNextPage || currentPageIndex < maxPageIndex}
        loading={loading}
        itemsCount={itemsCount as number}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        columns={columns}
        data={polls ?? []}
        onSortingChanged={onSortingChanged}
        initialTableSorting={getInitialTableSorting(state)}
      />
      <VideoTutorialBanner
        text={t('videoTutorials.banner.polls')}
        visible={isTutorialBannerVisible}
        testId={'PollsView#VideoTutorialBanner'}
      />
    </>
  );
};

export { PollsViewContainer };
