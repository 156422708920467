import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import QuickQuestionEditContainer from '../../components/quickQuestions/edit/QuickQuestionEdit.container';

type QuickQuestionEditPageProps = {
  id?: string;
};

const QuickQuestionEditPage: FC<RouteComponentProps<QuickQuestionEditPageProps>> = ({ match }) => {
  const id = match.params.id;
  return <QuickQuestionEditContainer id={id} />;
};

export default QuickQuestionEditPage;
