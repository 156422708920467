import { FC } from 'react';
import styled from 'styled-components';
import { YTRegex, YTVideoPrefix } from '@sim-admin-frontends/ui-shared';

import { GRID_ITEM_TOTAL_WIDTH, GRID_ITEM_WIDTH } from './constanst';
import { getTestId } from '../../utils/testUtils';

type VideoTutorialItemProps = {
  tutorial: Tutorial;
  showDivider: boolean;
  testId: string;
};

export type Tutorial = {
  link: string;
  title: string;
  description: string;
};

const GridItem = styled.div`
  width: ${GRID_ITEM_WIDTH + 'px'};
  position: relative;
  padding-top: ${({ theme }) => theme.spaces.spacing32};
  padding-bottom: ${({ theme }) => theme.spaces.spacing32};
`;

const Divider = styled.div`
  height: 1px;
  position: absolute;
  bottom: 0;
  width: ${GRID_ITEM_TOTAL_WIDTH + 'px'};
  background-color: ${({ theme }) => theme.colors.coolGray20};
`;

const StyledIframe = styled.iframe`
  width: 100%;
  flex: 1;
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  margin-bottom: ${({ theme }) => theme.spaces.spacing8};
`;

const Title = styled.div`
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.preview};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.coolGray100};
  margin-bottom: ${({ theme }) => theme.spaces.spacing8};
`;

const Description = styled.div`
  text-align: justify;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.coolGray100};
  white-space: pre-line;
`;

const VideoTutorialItem: FC<VideoTutorialItemProps> = ({ tutorial, showDivider, testId }) => {
  const embedUrl = YTVideoPrefix + tutorial.link.match(YTRegex)?.[1];

  return (
    <GridItem data-testid={getTestId(testId, tutorial.title)}>
      <StyledIframe title={tutorial.title} src={embedUrl} loading="lazy" allowFullScreen />
      <Title>{tutorial.title}</Title>
      <Description>{tutorial.description}</Description>
      {showDivider && <Divider />}
    </GridItem>
  );
};

export default VideoTutorialItem;
