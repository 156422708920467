import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { DropzoneOptions, ErrorCode, useDropzone } from 'react-dropzone';
import { FieldError } from 'react-hook-form';
import { ImageFile, isFile } from '@sim-admin-frontends/utils-shared';
import { TFunction } from 'i18next';

import { ReactComponent as Close } from '../../../assets/img/close.svg';
import { Caption1 } from '../../texts/Texts';
import { TBaseProps } from '../../../types/TBaseComponent';
import { TUploadSize } from '../../../types/TUpload';
import { Label } from '../../label/Label';

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
`;

const DropzoneLabel = styled(Caption1)`
  color: ${({ theme }) => theme.colors.primary};
`;

const DropzoneRoot = styled.div<{
  isDragActive: boolean;
  $size: TUploadSize;
  showTopMargin: boolean;
}>`
  padding: ${({ theme, $size }) =>
    $size === TUploadSize.BIG ? theme.spaces.spacing24 : theme.spaces.spacing12};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.colors.coolGray20};
  cursor: pointer;
  transition: background-color 0.33s ease-in-out;
  background-color: ${({ isDragActive, theme }) =>
    isDragActive ? theme.colors.coolGray10 : theme.colors.white};
  margin-top: ${({ theme, showTopMargin }) => (showTopMargin ? theme.spaces.spacing8 : 'unset')};
  &:hover {
    background-color: ${({ theme }) => theme.colors.coolGray10};
  }
`;

const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  width: 100%;
`;

const FileWrapper = styled.div<{ isError: boolean }>`
  background-color: ${({ theme }) => theme.colors.coolGray10};
  padding: ${({ theme }) => theme.spaces.spacing8} ${({ theme }) => theme.spaces.spacing8}
    ${({ theme }) => theme.spaces.spacing8} ${({ theme }) => theme.spaces.spacing16};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid
    ${({ theme, isError }) => (isError ? theme.colors.red60 : theme.colors.coolGray10)};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  box-sizing: border-box;
  margin-top: ${({ theme }) => theme.spaces.spacing8};
`;

const FileName = styled(Caption1)`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 95%;
`;

const ErrorWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing8};
`;

const ErrorLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.red60};
`;

const RemoveFileIcon = styled(Close)`
  fill: ${({ theme }) => theme.colors.coolGray100};
  cursor: pointer;
  transition: fill 0.33s ease-in-out;
  &:hover {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export interface UploadProps extends TBaseProps {
  dropzoneLabel: string;
  maxFiles?: number;
  multiple?: boolean;
  fieldName?: string;
  onChange?: (files?: (File | ImageFile)[]) => void;
  files?: (File | ImageFile)[];
  error?: FieldError;
  size?: TUploadSize;
  t: TFunction;
  accept?: string | string[];
  fileTypeErrorLabel?: string;
}

const Upload = ({
  className,
  testId,
  dropzoneLabel,
  maxFiles = 10,
  multiple = false,
  fieldName,
  onChange,
  files,
  error,
  t,
  size = TUploadSize.BIG,
  accept,
  fileTypeErrorLabel,
}: UploadProps) => {
  const [maxFilesError, setMaxFilesError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const onDrop = useCallback<NonNullable<DropzoneOptions['onDrop']>>(
    (droppedFiles) => {
      if (files) {
        const filteredDroppedFiles = droppedFiles.filter((file) => {
          return !files.some((f) => {
            if (isFile(f) && isFile(file)) {
              return f.lastModified === file.lastModified;
            } else if (!isFile(f) && !isFile(file)) {
              return f.id === (file as ImageFile).id;
            } else {
              return false;
            }
          });
        });
        const newValue = [...files, ...filteredDroppedFiles];
        if (newValue.length > maxFiles) {
          setMaxFilesError(true);
        } else {
          setMaxFilesError(false);
          if (onChange) {
            onChange(newValue);
          }
        }
      } else {
        if (onChange) {
          onChange(droppedFiles);
        }
      }
    },
    [onChange, files],
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    maxFiles,
    onDrop,
    multiple,
    accept: accept,
  });

  useEffect(() => {
    setMaxFilesError(
      (previousMaxFilesError) =>
        fileRejections?.[0]?.errors.some(
          (fileError) => fileError.code === ErrorCode.TooManyFiles,
        ) || previousMaxFilesError,
    );

    setFileTypeError(
      fileRejections?.[0]?.errors.some((fileError) => fileError.code === ErrorCode.FileInvalidType),
    );
  }, [fileRejections]);

  const onRemoveFileClick = useCallback(
    (index: number) => () => {
      const newValue = files?.filter((_, i) => index !== i);
      if (newValue && newValue.length <= maxFiles) {
        setMaxFilesError(false);
      }
      if (onChange) {
        onChange(newValue?.length ? newValue : undefined);
      }
    },
    [files],
  );

  const shouldShowDropzone = !files || files.length < (multiple ? maxFiles : 1);

  return (
    <UploadWrapper className={className}>
      {files && (
        <FilesWrapper>
          {files.map((image, index) => (
            <FileWrapper key={isFile(image) ? image.lastModified : image.id} isError={!!error}>
              <FileName>{isFile(image) ? image.name : image.fileName}</FileName>
              <RemoveFileIcon onClick={onRemoveFileClick(index)} data-testid={`${testId}Remove`} />
            </FileWrapper>
          ))}
        </FilesWrapper>
      )}
      {shouldShowDropzone && (
        <DropzoneRoot
          {...getRootProps()}
          showTopMargin={!!files}
          isDragActive={isDragActive}
          $size={size}
        >
          <input
            data-testid={testId}
            id={fieldName}
            {...getInputProps()}
            aria-invalid={error ? 'true' : 'false'}
          />
          <DropzoneLabel>{dropzoneLabel}</DropzoneLabel>
        </DropzoneRoot>
      )}
      {maxFilesError && (
        <ErrorWrapper className={className}>
          <ErrorLabel testId={`${testId}-error`}>
            {t('dropzone.maxFilesError', { maxFiles: multiple ? maxFiles : 1 })}
          </ErrorLabel>
        </ErrorWrapper>
      )}
      {fileTypeError && (
        <ErrorWrapper className={className}>
          <ErrorLabel testId={`${testId}-error`}>
            {fileTypeErrorLabel
              ? fileTypeErrorLabel
              : t('common.validation.file.unsupportedFormat')}
          </ErrorLabel>
        </ErrorWrapper>
      )}
    </UploadWrapper>
  );
};

export { Upload };
