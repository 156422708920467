import { StrictMode, Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { initSentry } from '@sim-admin-frontends/utils-shared';

import App from './app/app';
import './i18n/i18n';

initSentry();

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>
  </StrictMode>,
  document.getElementById('root'),
);
