import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import { TBaseProps } from '../../types/TBaseComponent';

const ANIMATION_DURATION_MS = 1000;
const BORDER_MULTIPLIER = 1 / 6;

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Wrapper = styled.div<{ size: number }>`
  box-sizing: border-box;
  border: ${({ size }) => size * BORDER_MULTIPLIER}px solid transparent;
  border-radius: 50%;
  border-top: ${({ size }) => size * BORDER_MULTIPLIER}px solid
    ${({ theme }) => theme.colors.primary};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation: ${rotate} ${ANIMATION_DURATION_MS}ms linear infinite;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
`;

export interface SpinnerProps extends TBaseProps {
  size?: number;
}

const Spinner: FC<SpinnerProps> = ({ testId = 'Spinner', className, size = 24 }) => {
  return <Wrapper data-testid={testId} className={className} size={size} />;
};

export { Spinner, SpinnerWrapper };
