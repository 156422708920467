import {
  ACTION_ITEMS_MIN_WIDTH_SMALL,
  TableHeaderTitle,
  TableImageCell,
  TExtendedColumn,
} from '@sim-admin-frontends/ui-shared';
import { TFunction } from 'i18next';
import { CellProps } from 'react-table';
import { formatTableDateTime } from '@sim-admin-frontends/utils-shared';
import { MessagingCategory } from '@sim-admin-frontends/data-access-admin-be';
import { Image } from '@sim-admin-frontends/data-access';

import { TReportProblemListItem } from '../../../types/TProblems';
import ReportProblemTableMenu from './ReportProblemTableMenu';

export const createTableColumns = (
  t: TFunction,
  timeZone?: string,
  categories?: MessagingCategory[],
) => {
  const columns: TExtendedColumn<TReportProblemListItem>[] = [
    {
      Header: `${t('petDirectory.table.image')}`,
      testId: 'PetDirecotryTable#Image',
      accessor: 'imageObjects',
      Cell: ({ value }: { value: Image[] }) => {
        const src = value?.[0]?.links?.self;
        const thumbnail = value?.[0]?.links?.thumbnail || '';

        if (!src) {
          return null;
        }
        return <TableImageCell image={src} thumbnail={thumbnail} alt="reportProblemImage" />;
      },
    },
    {
      Header: `${t('reportProblem.table.dateCreated')}`,
      accessor: 'createdAt',
      disableSortBy: true,
      testId: 'ReportProblemTable#createdAt',
      Cell: ({ value }: { value: string }) => (value ? formatTableDateTime(value, timeZone) : ''),
    },
    {
      Header: `${t('reportProblem.table.category')}`,
      accessor: 'categoryUuid',
      testId: 'ReportProblemTable#category',
      Cell: ({ value }: { value: string }) => {
        const category = categories?.find((cat) => cat.id === value);
        return category?.name || '';
      },
    },
    {
      Header: `${t('reportProblem.table.description')}`,
      testId: 'ReportProblemTable#description',
      accessor: (item) => <TableHeaderTitle item={item.description || ''} maxTextLength={100} />,
    },
    {
      Header: `${t('reportProblem.table.reporter')}`,
      accessor: 'reporter',
      testId: 'ReportProblemTable#reporter',
    },
    {
      Header: `${t('reportProblem.table.email')}`,
      accessor: 'email',
      testId: 'ReportProblemTable#email',
    },
    {
      Header: `${t('reportProblem.table.address')}`,
      accessor: 'address',
      testId: 'ReportProblemTable#address',
    },
    {
      Header: '',
      id: '0',
      testId: 'ReportProblemTable#buttons',
      disableSortBy: true,
      minWidth: ACTION_ITEMS_MIN_WIDTH_SMALL,
      skeleton: {
        width: 50,
        align: 'right',
      },
      Cell: ({ row }: CellProps<TReportProblemListItem>) => {
        return (
          <ReportProblemTableMenu
            problemId={row.original.id}
            testId={`ReportProblemView#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  return columns;
};
