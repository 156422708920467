import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { usePlaceQuery } from '@sim-admin-frontends/data-access-admin-be';
import { Error, Spinner, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';

import HeaderContent from './HeaderContent';
import { getHeaderTabsSettings, getHeaderTabsType } from './headerTabs';
import { useIsPlaceAdmin } from '../../../hooks/useIsPlaceAdmin';
import { usePlaceInfo } from '../../../contexts/placeContext';
import useIsReportProblemEnabled from '../../../hooks/useIsReportProblemEnabled';

const HeaderContentContainer: FC = () => {
  const { places } = usePlaceInfo();
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const { isPlaceAdmin } = useIsPlaceAdmin();
  const tabType = getHeaderTabsType(path);
  const placeId = places?.[0]?.id || '';

  const { data, isLoading, isError, refetch } = usePlaceQuery({
    id: placeId,
  });

  const {
    isEnabled: isReportProblemEnabled,
    isLoading: isReportProblemLoading,
    isError: isReportProblemError,
  } = useIsReportProblemEnabled();

  const settings = useMemo(
    () =>
      getHeaderTabsSettings(
        isPlaceAdmin,
        t,
        tabType,
        data?.place?.featureFlags || [],
        isReportProblemEnabled,
      ),
    [isPlaceAdmin, t, tabType, data, isReportProblemEnabled],
  );

  if (!tabType) {
    return null;
  }

  if (isLoading || !placeId || isReportProblemLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError || isReportProblemError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  return <HeaderContent tabs={settings} />;
};

export default HeaderContentContainer;
