import { FC } from 'react';

import { truncateText } from '../../../utils/stringUtils';

type Props = {
  item: string;
  maxTextLength?: number;
};

const TableHeaderTitle: FC<Props> = ({ item, maxTextLength }) => {
  const { title, truncatedText } = truncateText(item, maxTextLength);
  return <span title={title}>{truncatedText}</span>;
};

export { TableHeaderTitle };
