import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import DonationEditContainer from '../../components/donations/edit/DonationEdit.container';

export type DonationEditPageProps = {
  id?: string;
};

const DonationsEditPage: FC<RouteComponentProps<DonationEditPageProps>> = ({ match }) => {
  return <DonationEditContainer id={match?.params?.id} />;
};

export default DonationsEditPage;
