import {
  AnnouncementInput,
  CategoriesQuery,
  TCategory,
  TUploadedFile,
} from '@sim-admin-frontends/data-access';
import { prepareFileObjects } from '@sim-admin-frontends/utils-shared';
import { serializeToString } from '@simplicity-tech/sim-slate-types';
import { History, LocationState } from 'history';
import { generatePath } from 'react-router-dom';

import ROUTES from '../routing/routes';
import { TAnnouncementsTabTypes } from '../types/TAnnouncements';
import { TRaffleFormValues } from '../types/TRaffles';

const RAFFLE_CATEGORY_NAME = 'raffle';

export const changeRafflesTab = (history: History<LocationState>, type: TAnnouncementsTabTypes) => {
  history.push(
    generatePath(ROUTES.rafflesOverview.path, {
      type,
    }),
  );
};

export const findRaffleCategory = (categories?: CategoriesQuery): TCategory | undefined =>
  categories?.categoriesBy.categories.find((category) =>
    category.name.toLowerCase().includes(RAFFLE_CATEGORY_NAME),
  );

export const transformFormValues = (
  formData: TRaffleFormValues,
  uploadedImages: TUploadedFile[] | undefined,
  category: TCategory | undefined,
  defaultPostLang = '',
): AnnouncementInput => ({
  title: formData.title,
  subtitle: formData.subtitle,
  content: formData.invitationMessage,
  pushContent: serializeToString(formData.invitationMessage),
  raffleOptions: {
    titleWidth: formData.titleWidth,
    textWidth: formData.subtitleWidth,
    textColor: formData.textColor,
    textPosition: formData.textPosition.value,
    datetimeFrom: formData.datetimeFrom?.toISOString(),
    datetimeTo: formData.datetimeTo?.toISOString(),
    showIncChanceFlag: formData.showIncChanceFlag,
    termsAndConditionsUrl: formData.termsAndConditionsUrl,
    privacyPolicyUrl: formData.privacyPolicyUrl,
  },
  imageObjects: prepareFileObjects(uploadedImages),
  institutionUuid: formData.publisher?.value ?? '',
  categoryUuid: category?.id,
  categoryUuids: category?.id ? [category.id] : [],
  lang: formData.lang?.value || defaultPostLang,
});
