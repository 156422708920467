import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  FormInput,
  SmallText,
  Button,
  ArrowUpWithLine2,
  PasswordRequirements,
} from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ReactComponent as Logo } from '../../assets/img/simplicity_logo-full.svg';
import { TResetPasswordFormValues } from '../../types/TUser';
import ROUTES from '../../routing/routes';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeadlineText = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spaces.spacing32}; ;
`;

const FormWrapper = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.coolGray100};
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  text-decoration: none;
  & > svg {
    margin-right: ${({ theme }) => theme.spaces.spacing8};
    transform: rotate(-90deg);
  }
  &:hover {
    box-shadow: 0 1px 0 0 ${({ theme }) => theme.colors.coolGray100};
  }
`;

const SubmitButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  width: 100%;
`;

const PASSWORD_SPECIAL_CHARS = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

const schema = (t: TFunction) =>
  Yup.object().shape({
    code: Yup.string().required(t('common.validation.required')),
    password: Yup.string()
      .required(t('common.validation.required'))
      .test('has-one-lower', t('common.validation.passwordMustContainsOneLower'), (value) =>
        value ? !!value.match(/[a-z]/) : false,
      )
      .test('has-one-upper', t('common.validation.passwordMustContainsOneUpper'), (value) =>
        value ? !!value.match(/[A-Z]/g) : false,
      )
      .test('has-one-num', t('common.validation.passwordMustContainsOneNumber'), (value) =>
        value ? !!value.match(/[0-9]/g) : false,
      )
      .test('has-one-special', t('common.validation.passwordMustContainsOneSpecial'), (value) =>
        value ? !!value.match(new RegExp(`[${PASSWORD_SPECIAL_CHARS}]`, 'g')) : false,
      )
      .min(8, t('common.validation.min', { num: 8 })),
    passwordConfirm: Yup.string()
      .required(t('common.validation.required'))
      .test('passwords-match', t('common.validation.passwordsMustMatch'), function (value) {
        return this.parent.password === value;
      }),
  });

type Props = {
  onReset: (values: TResetPasswordFormValues) => void;
};

const ResetPassword: FC<Props> = ({ onReset }) => {
  const { t } = useTranslation();
  const { handleSubmit, register, formState } = useForm<TResetPasswordFormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema(t)),
  });

  const {
    errors,
    isSubmitting,
    dirtyFields: { password: passwordIsDirty },
    isSubmitted,
  } = formState;

  const showPasswordRequirements = passwordIsDirty || isSubmitted;

  const onSubmit = async (values: TResetPasswordFormValues) => {
    return onReset(values);
  };

  const passwordRequirements = [
    { errorKey: 'has-one-lower', text: t('common.passwordRequirements.hasOneLower') },
    { errorKey: 'has-one-upper', text: t('common.passwordRequirements.hasOneUpper') },
    { errorKey: 'has-one-num', text: t('common.passwordRequirements.hasOneNum') },
    {
      errorKey: 'has-one-special',
      text: (
        <span>
          {t('common.passwordRequirements.hasOneSpecial')}
          <br />
          <span dangerouslySetInnerHTML={{ __html: PASSWORD_SPECIAL_CHARS }} />
        </span>
      ),
    },
    { errorKey: 'min', text: t('common.passwordRequirements.min', { num: 8 }) },
  ];

  return (
    <Wrapper>
      <FormWrapper>
        <Logo />
        <HeadlineText>{t('resetPassword.headline')}</HeadlineText>
        <FormInput
          label={t('resetPassword.code')}
          type="text"
          {...register('code', { required: true })}
          error={errors.code}
        />
        <FormInput
          label={t('resetPassword.password')}
          {...register('password', { required: true })}
          type="password"
          error={errors.password}
        />
        {showPasswordRequirements && (
          <PasswordRequirements
            title={t('completeRegistration.passwordRequirements')}
            items={passwordRequirements}
            errors={errors?.password?.types}
          />
        )}
        <FormInput
          label={t('resetPassword.passwordConfirm')}
          {...register('passwordConfirm', { required: true })}
          type="password"
          error={errors.passwordConfirm}
        />
        <SubmitButton
          size="larger"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          disabled={isSubmitting}
          testId="ResetPassword#submit"
        >
          {t('resetPassword.submit')}
        </SubmitButton>
        <StyledLink to={ROUTES.login.path}>
          <ArrowUpWithLine2 />
          {t('login.backToLogin')}
        </StyledLink>
      </FormWrapper>
    </Wrapper>
  );
};

export default ResetPassword;
