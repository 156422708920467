import { FC, useMemo, useCallback, useState, useEffect } from 'react';
import { SortingRule } from 'react-table';
import { useTranslation } from 'react-i18next';
import {
  CouponsByFilterInput,
  State,
  TCouponListItem,
  useInfiniteCouponsQuery,
} from '@sim-admin-frontends/data-access';
import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';

import CouponsView from './CouponsView';
import { useAuthInfo } from '../../../contexts/userContext';
import {
  createTableColumns,
  getInitialGqlSorting,
  getGqlSorting,
  getInitialTableSorting,
} from './helper';
import { usePlaceInfo } from '../../../contexts/placeContext';

interface CouponsViewContainer {
  state: State;
  searchFilter: string;
}

const CouponsViewContainer: FC<CouponsViewContainer> = ({ state, searchFilter }) => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();
  const [orderBy, setOrderBy] = useState(getInitialGqlSorting(state));
  const institutionId = institutionUuid || '';

  const filter: CouponsByFilterInput = useMemo(
    () => ({
      institutionUuid: institutionId,
      state,
      text: searchFilter || undefined,
    }),
    [institutionId],
  );

  const {
    fetchNextPage,
    hasNextPage,
    data,
    isError,
    isLoading: areCouponsLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteCouponsQuery(filter, orderBy);

  const { currentPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  const isLoading = areCouponsLoading || isFetchingNextPage;

  const onSortingChanged = useCallback(
    (newSorting: SortingRule<TCouponListItem>[]) => {
      setOrderBy(getGqlSorting(newSorting, state));
      setCurrentPageIndex(0);
    },
    [setCurrentPageIndex, state],
  );

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [institutionUuid]);

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const columns = createTableColumns(t, refetch, state, places?.[0].timezoneCode);
  const itemsCount = data?.pages[0].couponsBy.count;
  const coupons = data?.pages[currentPageIndex]?.couponsBy.coupons;

  return (
    <CouponsView
      hasNextPage={!!hasNextPage}
      loading={isLoading}
      itemsCount={itemsCount as number}
      pageIndex={currentPageIndex}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      columns={columns}
      data={coupons || []}
      onSortingChanged={onSortingChanged}
      initialTableSorting={getInitialTableSorting(state)}
    />
  );
};

export default CouponsViewContainer;
