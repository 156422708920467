import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Heading1, Modal, SmallText, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { HarmfulContentReason } from '@sim-admin-frontends/data-access';

import ModeratorModalOption from './ModeratorModalOption';

const TEST_ID = 'Moderator#NegativeReasonModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spaces.spacing24} ${({ theme }) => theme.spaces.spacing32};
`;

const Content = styled(SmallText)`
  margin-top: ${({ theme }) => theme.spaces.spacing8};
`;

const OptionsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  padding-left: ${({ theme }) => theme.spaces.spacing16};
`;

const ConfirmButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spaces.spacing24};
`;

const BackButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spaces.spacing8};
`;

export interface ModeratorModalProps extends TBaseProps {
  isOpen: boolean;
  onConfirmClick: (reason: HarmfulContentReason, reactionText?: string) => void;
  onBackClick: () => void;
}

const ModeratorModal: FC<ModeratorModalProps> = ({ isOpen, onConfirmClick, onBackClick }) => {
  const { t } = useTranslation();
  const [selectedReason, setSelectedReason] = useState<HarmfulContentReason>(
    HarmfulContentReason.Spam,
  );
  const [reactionText, setReactionText] = useState('');

  useEffect(() => {
    setSelectedReason(HarmfulContentReason.Spam);
    setReactionText('');
  }, [isOpen]);

  const onReactionTextChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (selectedReason === HarmfulContentReason.Other) {
        setReactionText(e.target.value);
      }
    },
    [selectedReason],
  );

  const renderOptions = useCallback(
    () => (
      <OptionsWrapper>
        {Object.values(HarmfulContentReason).map((key) => {
          const reason = key as HarmfulContentReason;

          return (
            <ModeratorModalOption
              key={reason}
              reason={reason}
              reactionText={reactionText}
              isSelected={reason === selectedReason}
              onSelect={(newSelectedReason) => {
                setSelectedReason(newSelectedReason);
                setReactionText('');
              }}
              onReactionTextChanged={onReactionTextChanged}
            />
          );
        })}
      </OptionsWrapper>
    ),
    [onReactionTextChanged, reactionText, selectedReason],
  );

  return (
    <Modal isOpen={isOpen}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Heading1>{t('moderator.modal.title')}</Heading1>
        <Content>{t('moderator.modal.description')}</Content>
        {renderOptions()}
        <ConfirmButton
          onClick={() => onConfirmClick(selectedReason, reactionText)}
          testId={`${TEST_ID}-Confirm`}
        >
          {t('moderator.modal.confirmButton')}
        </ConfirmButton>
        <BackButton variant={'tertiary'} onClick={onBackClick} testId={`${TEST_ID}-Back`}>
          {t('moderator.modal.backButton')}
        </BackButton>
      </Wrapper>
    </Modal>
  );
};

export { ModeratorModal };
