import { FC } from 'react';
import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { TEventsList, TEventsListItem } from '@sim-admin-frontends/data-access';
import { SortingRule } from 'react-table';

export interface EventsViewProps {
  data: TEventsList;
  columns: TExtendedColumn<TEventsListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  onSortingChanged: (value: SortingRule<TEventsListItem>[]) => void;
  initialTableSorting: SortingRule<TEventsListItem>[];
  hasNextPage?: boolean;
}

const EventsView: FC<EventsViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  onSortingChanged,
  initialTableSorting,
  hasNextPage,
}) => (
  <Table
    loading={loading}
    itemsCount={itemsCount}
    pageIndex={pageIndex}
    data={data}
    columns={columns}
    hasPagination={true}
    hasNextPage={hasNextPage}
    onNextPress={onNextPress}
    onPreviousPress={onPreviousPress}
    onSortingChanged={onSortingChanged}
    initialSortBy={initialTableSorting}
    testId="EventsView#Table"
  />
);

export default EventsView;
