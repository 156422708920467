import React, { FC, useState, MouseEvent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@sim-admin-frontends/ui-shared';

const COPIED_TEXT_DELAY = 2000;

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spaces.spacing10};
`;

type LinkDetailButtonProps = {
  value: string;
};

const LinkDetailButton: FC<LinkDetailButtonProps> = ({ value }) => {
  const { t } = useTranslation();
  const defaultLabel = t('updates.table.link');
  const [text, setText] = useState<string>(defaultLabel);
  const onClick = (e: MouseEvent) => {
    // If metaKey (Mac) or ctrlKey (Windows) is pressed, open the link in a new tab
    if (e.metaKey || e.ctrlKey) {
      window.open(value, '_blank');
    } else {
      navigator.clipboard.writeText(value);
      setText(t('common.copiedToClipboard'));
      setTimeout(() => setText(defaultLabel), COPIED_TEXT_DELAY);
    }
  };
  return (
    <StyledButton variant="secondary" type="submit" onClick={onClick}>
      {text}
    </StyledButton>
  );
};

export { LinkDetailButton };
