import React, { createContext, Dispatch, FC, useContext, useState } from 'react';

export type THighlightableElementsContext = {
  highlightedElementId?: string;
  setHighlightedElementId: Dispatch<string | undefined>;
};

const createCtx = <ContextType,>() => {
  const ctx = createContext<ContextType | undefined>(undefined);
  const useCtx = () => {
    const c = useContext(ctx);
    if (!c) {
      throw new Error('useCtx must be inside a Provider with a value');
    }
    return c;
  };
  return [useCtx, ctx.Provider] as const;
};

const [useHighlightableElements, Provider] = createCtx<THighlightableElementsContext>();

const HighlightableElementsProvider: FC = ({ children }) => {
  const [highlightedElementId, setHighlightedElementId] = useState<string | undefined>();

  return (
    <Provider
      value={{
        highlightedElementId,
        setHighlightedElementId,
      }}
    >
      {children}
    </Provider>
  );
};

export { useHighlightableElements, HighlightableElementsProvider };
