import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PageWrapper } from '../../components/layout/PageLayout';
import DonationsTabs from '../../components/donations/view/DonationsTabs';

const DonationsListPage: FC = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <PageHeader
        title={t('donations.pageTitle')}
        caption={t('donations.pageCaption')}
        testId="DonationsTabs#PageHeader"
      />
      <DonationsTabs />
    </PageWrapper>
  );
};

export default DonationsListPage;
