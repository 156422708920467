import { FC } from 'react';
import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { TCouponList, TCouponListItem } from '@sim-admin-frontends/data-access';
import { SortingRule } from 'react-table';

type CouponsViewProps = {
  data: TCouponList;
  columns: TExtendedColumn<TCouponListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  onSortingChanged: (value: SortingRule<TCouponListItem>[]) => void;
  initialTableSorting: SortingRule<TCouponListItem>[];
  hasNextPage?: boolean;
};

const CouponsView: FC<CouponsViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  onSortingChanged,
  initialTableSorting,
  hasNextPage,
}) => (
  <Table
    hasNextPage={hasNextPage}
    loading={loading}
    itemsCount={itemsCount}
    pageIndex={pageIndex}
    data={data}
    columns={columns}
    hasPagination={true}
    onNextPress={onNextPress}
    onPreviousPress={onPreviousPress}
    onSortingChanged={onSortingChanged}
    initialSortBy={initialTableSorting}
    testId="CouponsView#Table"
  />
);

export default CouponsView;
