export enum TEmergencyTabTypes {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

export enum EmergencyModalActions {
  DELETE = 'DELETE',
  UNPIN = 'UNPIN',
  PIN = 'PIN',
}
