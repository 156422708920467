import { State } from '@sim-admin-frontends/data-access';
import { Checkbox, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { CellProps } from 'react-table';

import { TPostTableItem } from '../types/TUnpublish';

export const getUnpublishCheckbox = <U, T extends TExtendedColumn<TPostTableItem<U>>[]>(
  columns: T,
  testId: string,
  postsToUnpublish?: string[],
  onCheckboxChange?: (id: string) => () => void,
) => {
  if (!onCheckboxChange || !postsToUnpublish) {
    return;
  }

  columns.unshift({
    testId: `${testId}#checkBox`,
    id: '2',
    disableSortBy: true,
    width: '2%',
    skeleton: {
      width: 50,
    },
    Cell: ({ row }: CellProps<TPostTableItem<U>>) => {
      const isPublished = row.original.publishmentState === State.Published;
      if (!isPublished) {
        return null;
      }

      const isChecked = postsToUnpublish.includes(row.original.id);
      return <Checkbox checked={isChecked} onChange={onCheckboxChange(row.original.id)} />;
    },
  });
};
