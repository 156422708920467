import { FC } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import ReportProblemEditContainer from '../../components/reportProblem/edit/ReportProblemEdit.container';
import { useIsPlaceAdmin } from '../../hooks/useIsPlaceAdmin';

type ReportProblemEditPageProps = {
  id?: string;
};

const ReportProblemEditPage: FC<RouteComponentProps<ReportProblemEditPageProps>> = ({ match }) => {
  const id = match.params.id;
  const { isPlaceAdmin } = useIsPlaceAdmin();

  if (!isPlaceAdmin) {
    return <Redirect to="/404" />;
  }

  return <ReportProblemEditContainer id={id} />;
};

export default ReportProblemEditPage;
