export const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
};

export const device = {
  sm: `@media only screen and (min-width: ${breakpoints.sm})`,
  md: `@media only screen and (min-width: ${breakpoints.md})`,
  lg: `@media only screen and (min-width: ${breakpoints.lg})`,
  xl: `@media only screen and (min-width: ${breakpoints.xl})`,
};

/*
 This function is used to strip unit from string
 For example: stripPx('24px') returns 24
*/
export const stripPx = (arg: string) => parseInt(arg, 10);
