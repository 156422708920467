import { stripPx } from '@sim-admin-frontends/ui-shared';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { getTestId } from '../../utils/testUtils';
import SquareImageWithTexts from './image/SquareImageWithTexts';

const Wrapper = styled.div<{ height: number }>`
  display: flex;
  flex-direction: row;
  height: ${({ height, theme }) => height + stripPx(theme.spaces.spacing4)}px;
  overflow: hidden;
  align-items: center;
`;

const PlaceholderWrapper = styled.div<{ height: number }>`
  display: flex;
  position: relative;
  height: ${({ height }) => height}px;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border: 1px solid;
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  border-color: ${({ theme }) => theme.colors.coolGray20};
  margin-right: ${({ theme }) => theme.spaces.spacing16};
`;

const IMAGE_HEIGHT = 174;

type Props = {
  image?: string;
  placeHolderImage: ReactNode;
  overlay: string;
  height?: number;
  title?: string;
  subtitle?: string;
  testIdPrefix: string;
};

const PreviewItemImageInList: FC<Props> = ({
  image,
  placeHolderImage,
  overlay,
  height = IMAGE_HEIGHT,
  title,
  subtitle,
  testIdPrefix,
}) => (
  <Wrapper height={height}>
    <SquareImageWithTexts
      height={height}
      image={image}
      overlay={overlay}
      title={title || ''}
      subtitle={subtitle || ''}
      testId={getTestId(testIdPrefix, 'ImageInList')}
    />
    <PlaceholderWrapper height={height}>{placeHolderImage}</PlaceholderWrapper>
  </Wrapper>
);

export default PreviewItemImageInList;
