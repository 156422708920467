import { FC, useEffect, useMemo, useState } from 'react';
import {
  FormWrapper,
  FormInput,
  FormSwitcherWithLabel,
  FormDatePicker,
  Label,
  FormDateTimePicker,
  SwitchableFormSection,
  FormSectionHeader,
  FormSelect,
  FormMultipleInputs,
  TableIconProps,
  ActionButtons,
} from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import { FormProvider, get, useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { utcToZonedTime } from 'date-fns-tz';
import { format, addDays } from 'date-fns';
import {
  getInstitutionInitValues,
  getLangOptionByVal,
  getLangOptions,
  MaybeDate,
  roundMinutes,
} from '@sim-admin-frontends/utils-shared';
import {
  State,
  TInstitution,
  TInstitutionListItem,
  TQuickQuestion,
} from '@sim-admin-frontends/data-access';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import PageContentEditable, { ContentHeader } from '../../layout/pageContent/PageContentEditable';
import { SwitchableSectionContentWrapper } from '../../announcements/edit/AnnouncementEditStyles';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { FALLBACK_TIMEZONE } from '../../../constants/Constants';
import useDateTimeChanged from '../../../hooks/useDateTimeChanged';
import FormButtons from '../../announcements/edit/FormButtons';
import { requiredTitleSchema } from '../../../utils/announcementsUtils';
import { TQuickQuestionOptions, TQuickQuestionsFormValues } from '../../../types/TQuickQuestions';
import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import QuickQuestionNotificationsSection from './notifications/QuickQuestionNotificationsSection';
import QuickQuestionPreview from '../preview/QuickQuestionPreview';
import { TLoggedUser } from '../../../types/TUser';
import { notificationsValidation } from '../../announcements/edit/validation';

const SwitcherWrapper = styled.div`
  margin: ${({ theme }) => theme.spaces.spacing32} 0;
`;

const StyledLabel = styled(Label)`
  margin-bottom: ${({ theme }) => theme.spaces.spacing4};
`;

const DateWrapper = styled.div`
  width: 50%;
`;

const schema = (t: TFunction, timezone: string, isScheduling: boolean) =>
  Yup.object().shape({
    question: Yup.string().required(t('common.validation.required')),
    questionOptions: Yup.array()
      .of(Yup.string())
      .test(
        'minLength',
        t('quickQuestions.validation.min2Answers'),
        (value: (string | undefined)[] | undefined) => {
          return !!value && value.length >= 2;
        },
      ),
    quickQuestionEndDate: Yup.date()
      .test('required', t('common.validation.required'), (value: MaybeDate) => !!value)
      .when('notInPast', () => {
        const dayStart = new Date().setHours(0, 0, 0, 0);
        return Yup.date()
          .typeError(t('common.validation.required'))
          .test(
            'notInPast',
            t('common.validation.date.pastDate'),
            (value?: Date) => !!value && value > utcToZonedTime(dayStart, timezone),
          );
      }),
    notifications: notificationsValidation(t, timezone),
    scheduledAt: Yup.mixed()
      .test(
        'required',
        t('common.validation.required'),
        (value: MaybeDate) => !isScheduling || !!value,
      )
      .when('publishedAt', (publishedAt) =>
        Yup.date()
          .typeError(t('common.validation.required'))
          .test(
            'greaterThanPublished',
            t('common.validation.date.greaterThanPublished'),
            (value?: MaybeDate) => !isScheduling || (!!value && value > publishedAt),
          ),
      ),
  });

type Props = {
  user: TLoggedUser | null;
  quickQuestion?: TQuickQuestion;
  submit: (values: TQuickQuestionsFormValues) => void;
  submitDraft: () => void;
  onDiscard: () => void;
  institution: TInstitution;
  userInstitutions: TInstitutionListItem[];
  qucikQuestionFormActions: TableIconProps[];
};

const QuickQuestionEdit: FC<Props> = ({
  quickQuestion,
  submit,
  submitDraft,
  onDiscard,
  institution,
  userInstitutions,
  user,
  qucikQuestionFormActions,
}) => {
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;
  const countryCode = places?.[0].countryCode || '';
  const now = new Date();
  const weekFromNow = utcToZonedTime(addDays(now, 7), timezone);
  const endDateMinValue = utcToZonedTime(now, timezone);
  const cantSchedule =
    quickQuestion?.publishmentState === State.Published ||
    quickQuestion?.publishmentState === State.Unpublished;
  const institutionLang = institution?.lang || undefined;

  const [isScheduling, setScheduling] = useState(!!quickQuestion?.scheduledAt && !cantSchedule);
  const [isDraft, setIsDraft] = useState(false);

  const getInitialOptions = () => {
    if (!quickQuestion?.questionOptions) {
      return [''];
    }
    const parsedOptions: TQuickQuestionOptions = Object.values(
      JSON.parse(quickQuestion?.questionOptions),
    );

    return parsedOptions.map((option) => option.answer);
  };

  const initialValues: TQuickQuestionsFormValues = {
    ...quickQuestion,
    question: quickQuestion?.question || '',
    questionOptions: getInitialOptions(),
    scheduledAt:
      quickQuestion?.scheduledAt && !cantSchedule
        ? utcToZonedTime(quickQuestion?.scheduledAt, timezone)
        : undefined,
    notifications: quickQuestion?.notifications
      ? quickQuestion?.notifications.map((notification) => ({
          value: utcToZonedTime(notification, timezone),
        }))
      : [],
    notifyNow: quickQuestion ? !!quickQuestion?.notifyNow : false,
    publisher: { label: institution?.name || '', value: institution?.id || '' },
    publishedAt: quickQuestion?.publishedAt
      ? utcToZonedTime(quickQuestion?.publishedAt, timezone)
      : utcToZonedTime(now, timezone),
    quickQuestionEndDate: utcToZonedTime(
      quickQuestion?.quickQuestionEndDate ?? weekFromNow,
      timezone,
    ),
    lang: getLangOptionByVal(quickQuestion?.lang || institutionLang, countryCode),
  };

  const pageTitle = quickQuestion
    ? t('quickQuestion.editPageTitle')
    : t('quickQuestion.createPageTitle');

  const methods = useForm<TQuickQuestionsFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, timezone, isScheduling)),
    mode: 'all',
  });
  const { handleSubmit, register, formState, getValues, setValue, trigger, control } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const [previewQuestion, previewOptions, previewPublisher, previewEndDate] = useWatch({
    name: ['question', 'questionOptions', 'publisher', 'quickQuestionEndDate'],
    control,
  });

  const onAnonymousSwitcherChange = (value: boolean) => {
    setValue('privateResults', value);
  };

  const onOptionsChange = (values: string[]) => {
    setValue('questionOptions', values);
    if (values.length > 1) {
      trigger('questionOptions');
    }
  };

  const onNotificationsEnabledChange = (value: boolean) => {
    setValue('notifyNow', value);
  };

  const initialPublishedAtDate = initialValues.publishedAt || utcToZonedTime(now, timezone);
  const minScheduledDate = roundMinutes(getValues('publishedAt') || utcToZonedTime(now, timezone));
  const initialScheduleDate = initialValues.scheduledAt || roundMinutes(initialPublishedAtDate);

  const { onDateTimeChanged: onScheduleDateTimeChanged } = useDateTimeChanged(
    'scheduledAt',
    setValue,
    trigger,
  );

  const onSchedulingSectionVisibilityChanged = () => {
    setValue(
      'scheduledAt',
      !isScheduling ? roundMinutes(utcToZonedTime(now, timezone)) : undefined,
    );
    setScheduling(!isScheduling);
    trigger('scheduledAt');
  };

  useEffect(() => {
    trigger('scheduledAt');
  }, [isScheduling, trigger]);

  const onSubmitClick = () => {
    setIsDraft(false);
    methods.control._updateProps({
      resolver: yupResolver(schema(t, timezone, isScheduling)),
    });
    handleSubmit(submit)();
  };

  const onSaveDraftClick = () => {
    setIsDraft(true);
    methods.control._updateProps({
      resolver: yupResolver(requiredTitleSchema(t)),
    });
    handleSubmit(submitDraft)();
  };

  // ToDo: Use real function once drafts ready on BE
  const isSaveDraftButtonVisible = false;
  // shouldSaveDraftButtonBeVisible(
  //   quickQuestion?.publishmentState,
  //   isScheduling,
  // );

  const institutionOptions = useMemo(
    () =>
      getInstitutionInitValues(
        user?.attributes?.['custom:institutionUuids'],
        institution,
        userInstitutions,
      ),
    [userInstitutions, institution, user?.attributes],
  );

  const showPublishers = institutionOptions?.length > 1;

  return (
    <PageContentEditable
      showOnlyPreview={false}
      previewTitle={t('quickQuestions.previewTitle')}
      preview={
        <QuickQuestionPreview
          question={previewQuestion}
          questionOptions={previewOptions}
          publisher={previewPublisher}
          quickQuestionEndDate={previewEndDate}
        />
      }
    >
      <FormWrapper>
        <ActionButtons actionButtons={qucikQuestionFormActions} />
        <ContentHeader>{pageTitle}</ContentHeader>
        <FormProvider {...methods}>
          <FormInput
            label={t('quickQuestions.question')}
            placeholder={t('quickQuestions.questionPlaceholder')}
            {...register('question')}
            error={errors.question}
            testId={'QuickQuestionEdit#title'}
          />
          <FormMultipleInputs
            label={t('quickQuestions.answer')}
            placeholder={t('quickQuestions.answerPlaceholder')}
            buttonLabel={t('quickQuestions.addMore')}
            onChange={onOptionsChange}
            testId={'QuickQuestionEdit#answers'}
            values={initialValues.questionOptions}
            error={get(errors, 'questionOptions')}
          />
          <SwitcherWrapper>
            <FormSwitcherWithLabel
              label={t('quickQuestions.setResultsPrivate')}
              testId={'QuickQuestionEdit#privateSwitch'}
              initialValue={initialValues.privateResults ?? false}
              onChange={onAnonymousSwitcherChange}
            />
          </SwitcherWrapper>
          <DateWrapper>
            <StyledLabel>{t('quickQuestions.setEndDate')}</StyledLabel>
            <FormDatePicker
              minDate={endDateMinValue}
              initialValue={weekFromNow}
              control={control}
              label={t('quickQuestions.to')}
              name={'quickQuestionEndDate'}
              testId={'QuickQuestionEndDate#date'}
            />
          </DateWrapper>
          <>
            <FormSectionHeader
              title={t('updates.form.language')}
              description={t('updates.form.languageDescription')}
            />
            <FormSelect
              control={control}
              name="lang"
              options={getLangOptions()}
              defaultValue={initialValues.lang}
              testId={'QuickQuestionEdit#languageSlect'}
              allSelectable
            />
          </>
          {showPublishers && (
            <>
              <FormSectionHeader
                title={t('updates.form.publisher')}
                description={t('updates.form.publisherDescription')}
              />
              <FormSelect
                control={control}
                name="publisher"
                error={get(errors, 'publisher')}
                options={institutionOptions || []}
                defaultValue={initialValues.publisher}
                testId="QuickQuestionEdit#publisher"
              />
            </>
          )}
          <SwitchableFormSection
            title={t('quickQuestions.schedulePublishing')}
            description={t('quickQuestions.scheduleDescription')}
            onVisibilityChanged={onSchedulingSectionVisibilityChanged}
            initiallyOpened={isScheduling}
            disabled={cantSchedule}
            disabledText={t('updates.form.disabledShedule')}
            testId={'QuickQuestionSchedule#switch'}
          >
            <SwitchableSectionContentWrapper>
              <FormDateTimePicker
                control={control}
                name="scheduledAt"
                initialDate={initialScheduleDate}
                minDate={minScheduledDate}
                onChange={onScheduleDateTimeChanged}
                dateLabel=""
                timeLabel=""
                initialTime={format(initialScheduleDate, 'hh:mm')}
                initialDayPeriod={format(initialScheduleDate, 'a')}
                testId="QuickQuestionSchedule#date"
              />
            </SwitchableSectionContentWrapper>
          </SwitchableFormSection>
          <QuickQuestionNotificationsSection
            control={control}
            notifyNow={initialValues.notifyNow}
            publishmentState={quickQuestion?.publishmentState}
            isEdit={!!quickQuestion}
            onNotificationsEnabledChange={onNotificationsEnabledChange}
          />
          <FormButtons
            isSubmitting={isSubmitting}
            isDraft={isDraft}
            getValues={getValues}
            errors={errors}
            publishmentState={quickQuestion?.publishmentState}
            isScheduling={isScheduling}
            onSaveDraftClick={onSaveDraftClick}
            onDiscard={onDiscard}
            onSubmitClick={onSubmitClick}
            isSaveDraftButtonVisible={isSaveDraftButtonVisible}
            isQuickQuestion
          />
        </FormProvider>
        <RouteLeavingGuard when={isDirty && !isSubmitting} />
      </FormWrapper>
    </PageContentEditable>
  );
};

export default QuickQuestionEdit;
