import { FC, useState } from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { TBaseProps } from '../..';
import { DropdownMenuItem, DropdownMenuItemProps } from './DropDownMenuItem';

import 'tippy.js/dist/tippy.css';

export interface DropdownMenuProps extends TBaseProps {
  items: DropdownMenuItemProps[];
  menuWidth?: number;
  onVisibilityChange?: (arg: boolean) => void;
}

const StyledTippy = styled(Tippy)`
  &.tippy-box {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0;
    border-radius: ${({ theme }) => theme.borderRadius.radius8};

    > .tippy-content {
      padding: 0;
    }
    > .tippy-arrow {
      color: ${({ theme }) => theme.colors.coolGray10};
    }
  }
`;

const ContentWrapper = styled.div<{ width?: number }>`
  overflow-y: auto;
  max-height: 400px;
  width: ${({ width }) => `${width}px` || 'auto'};
  border: ${({ theme }) => `1px solid ${theme.colors.coolGray10}`};
  padding: ${({ theme }) => theme.spaces.spacing4};
  box-shadow: 10px 10px 44px rgba(0, 0, 0, 0.04), 2px 2px 15px rgba(0, 0, 0, 0.08);
  border-radius: ${({ theme }) => theme.borderRadius.radius8};

  > * {
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  pointer-events: auto;
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownMenu: FC<DropdownMenuProps> = ({
  children,
  items,
  menuWidth,
  onVisibilityChange,
  testId,
}) => {
  const [ariaExpanded, setAriaExpanded] = useState(false);

  const onMount = () => {
    setAriaExpanded(true);
    onVisibilityChange && onVisibilityChange(true);
  };
  const onHide = () => {
    setAriaExpanded(false);
    onVisibilityChange && onVisibilityChange(false);
  };

  return (
    <StyledTippy
      content={
        <ContentWrapper width={menuWidth}>
          {items.map((item) => (
            <DropdownMenuItem key={item.label} {...item} />
          ))}
        </ContentWrapper>
      }
      placement="bottom-end"
      arrow={false}
      animation="fade"
      trigger="click"
      appendTo="parent"
      onMount={onMount}
      onHide={onHide}
    >
      <DropdownWrapper
        aria-haspopup="true"
        aria-expanded={ariaExpanded}
        className="content"
        data-testid={testId}
      >
        {children}
      </DropdownWrapper>
    </StyledTippy>
  );
};

export { DropdownMenu };
