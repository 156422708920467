import { State } from '@sim-admin-frontends/data-access';
import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateAnnoucementActions } from '../../../hooks/actionButtons/useGenerateAnnoucementActions';
import ROUTES from '../../../routing/routes';
import { changeAnnouncementsTab } from '../../../utils/announcementsUtils';

export interface AnnouncementsTableMenuProps extends TBaseProps {
  postId: string;
  isFromAdmin?: boolean | null;
  refetch: () => void;
  published: boolean;
  type: State;
}

const AnnouncementsTableMenu: FC<AnnouncementsTableMenuProps> = ({
  postId,
  isFromAdmin,
  refetch,
  published,
  testId,
  type,
}) => {
  const { listActions: items, renderModal } = useGenerateAnnoucementActions({
    isFromAdmin,
    type,
    published,
    postId,
    refetch,
    settings: {
      translationKey: 'updates',
      testIdKey: 'Announcements',
      editPagePath: ROUTES.announcementsEdit.path,
      changeTab: changeAnnouncementsTab,
    },
  });

  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export { AnnouncementsTableMenu };
