import {
  RadioButton,
  Label,
  FormDatePicker,
  FormInput,
  FormError,
} from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CouponRecurrence, RecurrenceEnd, TCouponFormValues } from '../../types/TCoupons';
import { getTestId } from '../../utils/testUtils';
import { FormRow, InputWrapper, LabelWrapper, MarginBottomTop } from './RecurrenceSettings';

const StyledLabel = styled(Label)`
  font-weight: ${({ theme }) => theme.fontWeights.light};
  margin-left: ${({ theme }) => theme.spaces.spacing16};
  margin-right: ${({ theme }) => theme.spaces.spacing8};
`;

const DatePickerWrapper = styled.div`
  width: 282px;
`;

type RecurrenceEndsSectionProps = {
  initialRecurrence: CouponRecurrence;
  minToDate: Date;
  maxToDate: Date;
  endsOn: RecurrenceEnd;
  onEndOnChange: (recurrenceType: RecurrenceEnd) => void;
  register: UseFormRegister<TCouponFormValues>;
  control: Control<TCouponFormValues>;
  errors: FieldErrors<TCouponFormValues>;
  testIdPrefix: string;
};

const RecurrenceEndsSection: FC<RecurrenceEndsSectionProps> = ({
  initialRecurrence,
  minToDate,
  maxToDate,
  endsOn,
  onEndOnChange,
  register,
  control,
  errors,
  testIdPrefix,
}) => {
  const { t } = useTranslation();

  const endsNever = endsOn === RecurrenceEnd.NEVER;
  const endsOnDate = endsOn === RecurrenceEnd.ON_DATE;
  const endsAfterOccurrences = endsOn === RecurrenceEnd.AFTER_OCCURRENCES;

  const initialToDate = initialRecurrence.toDate || undefined;
  const initialOccurrences = initialRecurrence.occurrences || undefined;

  const errorToDate = errors.recurrence?.toDate;
  const errorOccurrences = errors.recurrence?.occurrences;

  const onEndsNeverChange = () => {
    onEndOnChange(RecurrenceEnd.NEVER);
  };

  const onEndsOnDateChange = () => {
    onEndOnChange(RecurrenceEnd.ON_DATE);
  };

  const onEndsAfterChange = () => {
    onEndOnChange(RecurrenceEnd.AFTER_OCCURRENCES);
  };

  return (
    <>
      <MarginBottomTop>
        <FormRow>
          <LabelWrapper>
            <RadioButton
              value={endsNever}
              onChange={onEndsNeverChange}
              testId={getTestId(testIdPrefix, 'endsNever')}
            />
            <StyledLabel>{t('coupon.form.recurrence.never')}</StyledLabel>
          </LabelWrapper>
        </FormRow>
      </MarginBottomTop>
      <MarginBottomTop>
        <FormRow>
          <LabelWrapper>
            <RadioButton
              value={endsOnDate}
              onChange={onEndsOnDateChange}
              testId={getTestId(testIdPrefix, 'endsOnDate')}
            />
            <StyledLabel>{t('coupon.form.recurrence.on')}</StyledLabel>
          </LabelWrapper>
          <DatePickerWrapper>
            <FormDatePicker
              initialValue={initialToDate}
              minDate={minToDate}
              maxDate={maxToDate}
              control={control}
              name="recurrence.toDate"
              disabled={!endsOnDate}
              noMargin
              noError
              testId={getTestId(testIdPrefix, 'toDate')}
            />
          </DatePickerWrapper>
        </FormRow>
        {errorToDate && (
          <FormError error={errorToDate} testId={getTestId(testIdPrefix, 'toDate-error')} />
        )}
      </MarginBottomTop>
      <MarginBottomTop>
        <FormRow>
          <LabelWrapper>
            <RadioButton
              value={endsAfterOccurrences}
              onChange={onEndsAfterChange}
              testId={getTestId(testIdPrefix, 'endsAfterDays')}
            />
            <StyledLabel>{t('coupon.form.recurrence.after')}</StyledLabel>
          </LabelWrapper>
          <InputWrapper>
            <FormInput
              type="number"
              defaultValue={initialOccurrences}
              noMargin
              disabled={!endsAfterOccurrences}
              {...register(`recurrence.occurrences`)}
              testId={getTestId(testIdPrefix, 'occurrences')}
            />
          </InputWrapper>
          <LabelWrapper>
            <StyledLabel>{t('coupon.form.recurrence.occurrences')}</StyledLabel>
          </LabelWrapper>
        </FormRow>
        {errorOccurrences && (
          <FormError
            error={errorOccurrences}
            testId={getTestId(testIdPrefix, 'occurrences-error')}
          />
        )}
      </MarginBottomTop>
    </>
  );
};

export default RecurrenceEndsSection;
