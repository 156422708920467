import { TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import styled from 'styled-components';

import { HiddenOverflow } from './SquareImageWithTexts';

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  aspect-ratio: 1.775;
  border: 1px solid;
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  border-color: ${({ theme }) => theme.colors.coolGray20};
  margin-right: ${({ theme }) => theme.spaces.spacing16};
`;

const StyledImage = styled.img`
  width: 100%;
  aspect-ratio: 1.775;
  border: 1px solid;
  background-color: ${({ theme }) => theme.colors.coolGray40};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  border-color: ${({ theme }) => theme.colors.coolGray20};
  overflow: hidden;
  object-fit: cover;
`;

const StyledOverlay = styled.img`
  width: 100%;
  aspect-ratio: 1.775;
  position: absolute;
`;

const TextsWrapper = styled.div`
  display: inline-table;
  position: absolute;
  width: 90%;
  max-height: 90%;
  bottom: 0;
  margin: ${({ theme }) => theme.spaces.spacing8};
`;

const Title = styled(HiddenOverflow)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  font-size: ${({ theme }) => theme.fontSizes.heading2};
  line-height: ${({ theme }) => theme.lineHeights.heading2};
`;

const Subtitle = styled(HiddenOverflow)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.large};
`;

interface Props extends TBaseProps {
  image?: string;
  overlay: string;
  title: string;
  subtitle: string;
}

const WideImageWithTexts: FC<Props> = ({ image, overlay, title, subtitle, testId }) => {
  return (
    <ImageWrapper>
      {image && <StyledImage src={image} data-testid={`${testId}-Image`} />}
      <StyledOverlay src={overlay} />
      <TextsWrapper>
        <Title data-testid={`${testId}-Title`}>{title}</Title>
        <Subtitle data-testid={`${testId}-Subtitle`}>{subtitle}</Subtitle>
      </TextsWrapper>
    </ImageWrapper>
  );
};

export default WideImageWithTexts;
