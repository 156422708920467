import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import PlaceLoading from '../../components/placeLoading/PlaceLoading';
import useAnalytics from '../../hooks/useAnalytics';

const PlaceLoadingPage: FC<RouteComponentProps> = () => {
  useAnalytics();
  return <PlaceLoading />;
};

export default PlaceLoadingPage;
