import { FC, useMemo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';
import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import {
  AnnouncementsByFilterInput,
  ANNOUNCEMENTS_PAGE_SIZE,
  State,
  TAnnouncementListItem,
  useInfiniteAnnouncementsQuery,
} from '@sim-admin-frontends/data-access';
import { useOffsetTablePaging } from '@sim-admin-frontends/utils-shared';

import { AnnouncementsView } from './AnnouncementsView';
import {
  createTableColumns,
  getGqlSorting,
  getInitialGqlSorting,
  getInitialTableSorting,
} from './helper';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import VideoTutorialBanner from '../../videoTutorials/VideoTutorialBanner';
import { TUnpublishContainerProps } from '../../../types/TUnpublish';

export interface AnnouncementsViewContainerProps extends TUnpublishContainerProps {
  state: State;
  wasAnythingPublished: boolean;
  postsToUnpublish: string[];
  onUnpublishSelect: (id: string) => () => void;
  isUnpublishMutationSettled: boolean;
  searchFilter?: string;
}

const AnnouncementsViewContainer: FC<AnnouncementsViewContainerProps> = ({
  state,
  wasAnythingPublished,
  postsToUnpublish,
  onUnpublishSelect,
  isUnpublishMutationSettled,
  searchFilter,
}) => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();
  const [orderBy, setOrderBy] = useState(getInitialGqlSorting(state));
  const institutionId = institutionUuid || '';
  const filter: AnnouncementsByFilterInput = useMemo(
    () => ({
      institutions: [institutionId],
      state,
      text: searchFilter || undefined,
    }),
    [institutionId, state, searchFilter],
  );

  const { fetchNextPage, hasNextPage, data, isError, isLoading, isFetchingNextPage, refetch } =
    useInfiniteAnnouncementsQuery(filter, orderBy);

  useEffect(() => {
    if (isUnpublishMutationSettled) {
      refetch();
    }
  }, [isUnpublishMutationSettled]);

  const { currentPageIndex, maxPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } =
    useOffsetTablePaging(fetchNextPage, ANNOUNCEMENTS_PAGE_SIZE, hasNextPage);

  const onSortingChanged = useCallback(
    (newSorting: SortingRule<TAnnouncementListItem>[]) => {
      setOrderBy(getGqlSorting(newSorting, state));
      setCurrentPageIndex(0);
    },
    [setCurrentPageIndex, state],
  );

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [institutionUuid]);

  const columns = createTableColumns(
    t,
    refetch,
    state,
    places?.[0].timezoneCode,
    postsToUnpublish,
    onUnpublishSelect,
  );

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const itemsCount = data?.pages[0].announcementsBy.count;

  const announcements = data?.pages[currentPageIndex]?.announcementsBy.announcements.filter(
    (a): a is TAnnouncementListItem => a.__typename === 'Announcement',
  );
  const loading = isLoading || isFetchingNextPage;

  const isTutorialBannerVisible = !loading && !wasAnythingPublished;

  return (
    <>
      <AnnouncementsView
        hasNextPage={hasNextPage || currentPageIndex < maxPageIndex}
        loading={loading}
        itemsCount={itemsCount as number}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        columns={columns}
        data={announcements || []}
        onSortingChanged={onSortingChanged}
        initialTableSorting={getInitialTableSorting(state)}
      />
      <VideoTutorialBanner
        text={t('videoTutorials.banner.announcements')}
        visible={isTutorialBannerVisible}
        testId={'AnnouncementsView#VideoTutorialBanner'}
      />
    </>
  );
};

export { AnnouncementsViewContainer };
