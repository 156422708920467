import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import { useOffsetTablePaging } from '@sim-admin-frontends/utils-shared';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PROBLEMS_PAGE_SIZE,
  useInfiniteReportProblemQuery,
  MessagingProblemStatus,
  MessagingProblemsSortBy,
  useMessagingCategoriesByQuery,
  usePlaceFeautureFlagsQuery,
} from '@sim-admin-frontends/data-access-admin-be';
import { SortOrder, TFeatureFlagType } from '@sim-admin-frontends/data-access';
import { Redirect } from 'react-router-dom';

import { createTableColumns } from './helper';
import ReportProblemView from './ReportProblemView';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { isFeatureEnabled } from '../../../utils/placeUtils';

type ReportProblemViewContainerProps = {
  status: MessagingProblemStatus;
};

const ReportProblemViewContainer: FC<ReportProblemViewContainerProps> = ({
  status,
}: ReportProblemViewContainerProps) => {
  const { t } = useTranslation();
  const { places } = usePlaceInfo();

  const placeUuid = places?.[0].id;

  const filter = useMemo(() => {
    return { placeUuid, status };
  }, [status]);

  const orderBy = { by: MessagingProblemsSortBy.CreatedAt, order: SortOrder.Desc };

  const { fetchNextPage, hasNextPage, data, isError, isLoading, isFetchingNextPage, refetch } =
    useInfiniteReportProblemQuery(filter, orderBy);

  const { currentPageIndex, maxPageIndex, onPreviousPress, onNextPress } = useOffsetTablePaging(
    fetchNextPage,
    PROBLEMS_PAGE_SIZE,
    hasNextPage,
  );

  const categoriesFilter = { filter: { placeUuid: placeUuid || '' } };

  const {
    data: placeFeatureFlagsData,
    isLoading: placeFeatureFlagsIsLoading,
    isError: placeFeatureFlagsIsError,
    refetch: placeFeatureFlagsRefetch,
  } = usePlaceFeautureFlagsQuery({
    id: placeUuid || '',
  });

  const isEnabled = useMemo(
    () =>
      isFeatureEnabled(
        placeFeatureFlagsData?.place?.featureFlags || [],
        TFeatureFlagType.REPORT_A_PROBLEM,
      ),
    [placeFeatureFlagsData?.place?.featureFlags],
  );

  const {
    data: categoriesData,
    isLoading: categoriesIsLoading,
    isError: categoriesIsError,
    refetch: categoriesRefetch,
  } = useMessagingCategoriesByQuery(categoriesFilter);

  useEffect(() => {
    refetch();
  }, [status]);

  const categories = categoriesData?.messagingCategoriesBy?.messagingCategories;
  const problems = data?.pages[currentPageIndex].messagingProblemsBy.messagingProblems;
  const itemsCount = data?.pages[currentPageIndex].messagingProblemsBy.totalCount;

  const columns = createTableColumns(t, places?.[0].timezoneCode, categories);

  const error = isError || categoriesIsError || placeFeatureFlagsIsError;
  const loading =
    isLoading || isFetchingNextPage || categoriesIsLoading || placeFeatureFlagsIsLoading;

  const refetchData = () => {
    refetch();
    categoriesRefetch();
    placeFeatureFlagsRefetch();
  };

  if (error) {
    return (
      <>
        <SpinnerWrapper>
          <Error caption={t('error.fetchingDataError')} onClick={refetchData} />
        </SpinnerWrapper>
      </>
    );
  }

  if (!placeFeatureFlagsIsLoading && !isEnabled) {
    return <Redirect to="/404" />;
  }

  return (
    <ReportProblemView
      hasNextPage={hasNextPage || currentPageIndex < maxPageIndex}
      loading={loading}
      itemsCount={itemsCount as number}
      pageIndex={currentPageIndex}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      columns={columns}
      data={problems || []}
      categories={categories}
    />
  );
};

export default ReportProblemViewContainer;
