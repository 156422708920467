import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  MultiValueGenericProps,
  StylesConfig,
} from 'react-select';
import { useTheme } from 'styled-components';

import { TBaseProps } from '../../types/TBaseComponent';
import { TSearchItem, TSelectItem, TSelectItems } from '../../types/TSelect';
import { ReactComponent as DownArrow } from './../../assets/img/arrow_down.svg';
import { SelectStyles as Wrapper } from './SelectStyles';

export interface SelectProps extends TBaseProps {
  options: TSelectItems;
  searchable?: boolean;
  placeholder?: string;
  defaultValue?: TSelectItem | TSelectItems | readonly TSelectItem[];
  hideIndicator?: boolean;
  isMulti?: boolean;
  onChange?: (arg: readonly TSelectItem[] | null) => void;
  disabled?: boolean;
  clearable?: boolean;
  invalid?: boolean;
  allSelectable?: boolean;
  onMenuScrollToBottom?: (event: Event) => void;
  isLoading?: boolean;
  isReadonly?: boolean;
  onClickItemCallback?: (props: TSearchItem) => void;
  onSearch?: (text: string) => void;
}

const Select = forwardRef<any, SelectProps>(
  (
    {
      searchable = false,
      options,
      placeholder = 'Select...',
      onChange,
      defaultValue,
      className,
      hideIndicator,
      testId,
      isMulti = false,
      clearable = false,
      allSelectable = false,
      disabled,
      invalid,
      onMenuScrollToBottom,
      isLoading,
      isReadonly,
      onClickItemCallback,
      onSearch,
    },
    ref,
  ) => {
    const [value, setValue] = useState(defaultValue);
    const { t } = useTranslation();
    const theme = useTheme();

    const DropdownIndicator = (props: DropdownIndicatorProps<TSelectItem, typeof isMulti>) => {
      if (isReadonly) {
        return null;
      }
      return (
        <components.DropdownIndicator {...props}>
          {!hideIndicator ? <DownArrow /> : <></>}
        </components.DropdownIndicator>
      );
    };

    const handleMultiValueClick = (
      e: React.MouseEvent<HTMLElement>,
      props: MultiValueGenericProps<TSelectItem, boolean, GroupBase<TSelectItem>>,
    ) => {
      e.stopPropagation();
      e.preventDefault();
      if (onClickItemCallback) {
        onClickItemCallback(props);
      }
    };

    const MultiValueLabel = (props: MultiValueGenericProps<TSelectItem, typeof isMulti>) => {
      return (
        <Wrapper onClick={(e) => handleMultiValueClick(e, props)}>
          <components.MultiValueLabel {...props} />
        </Wrapper>
      );
    };

    const isSingleItem = (
      item: TSelectItem | readonly TSelectItem[] | null,
    ): item is TSelectItem => {
      if (!item) {
        return false;
      }
      return 'value' in item;
    };

    const internalOptions =
      isMulti && allSelectable
        ? [{ label: t('common.selectAll'), value: 'all' }, ...options]
        : options;

    const internalOnChange = (option: TSelectItem | readonly TSelectItem[] | null) => {
      if (isSingleItem(option)) {
        if (onChange) {
          onChange([option]);
        }
        setValue(option);
      } else {
        const newValue =
          option?.length && option.find((o) => o.value === 'all')
            ? internalOptions.slice(1)
            : option;
        if (onChange) {
          onChange(newValue);
        }
        setValue(newValue || undefined);
      }
    };

    const internalFilterOption = (candidate: TSelectItem, input: string) => {
      if (onSearch) {
        onSearch(input);
      }

      return candidate.label.includes(input);
    };

    const internalOnMenuClose = () => {
      if (onSearch) {
        onSearch('');
      }
    };

    const customStyles: StylesConfig<TSelectItem, typeof isMulti> = {
      multiValueLabel: (styles, state) => ({
        ...styles,
        ...(state.data.disabled ? { textDecoration: 'line-through' } : {}),
      }),
      singleValue: (styles, state) => ({
        ...styles,
        ...(state.data.disabled ? { textDecoration: 'line-through' } : {}),
      }),
      option: (styles, state) => ({
        ...styles,
        cursor: 'pointer',
        ...(state.data.disabled ? { textDecoration: 'line-through' } : {}),
      }),
      control: (styles, { isDisabled }) => ({
        ...styles,
        cursor: 'pointer',
        ...(isDisabled ? { backgroundColor: theme.colors.disabledGray } : {}),
      }),
      multiValueRemove: (style) => {
        return {
          ...style,
          ...(isReadonly
            ? {
                visibility: 'hidden',
                width: '0px',
              }
            : {}),
        };
      },
    };

    return (
      <Wrapper
        className={className}
        data-testid={testId}
        invalid={invalid}
        valueClickable={!!onClickItemCallback}
      >
        <ReactSelect<TSelectItem, typeof isMulti>
          ref={ref}
          menuIsOpen={typeof isReadonly !== 'undefined' ? !isReadonly : undefined}
          value={value}
          isClearable={clearable}
          options={internalOptions}
          isMulti={isMulti}
          onInputChange={onSearch}
          onChange={internalOnChange}
          filterOption={internalFilterOption}
          components={{ DropdownIndicator, MultiValueLabel }}
          className="react-select-container"
          classNamePrefix={'react-select'}
          styles={customStyles}
          isSearchable={searchable}
          placeholder={placeholder}
          defaultValue={defaultValue}
          inputId={`${testId}-inputId`}
          id={testId}
          isDisabled={disabled}
          onMenuScrollToBottom={onMenuScrollToBottom}
          isLoading={isLoading}
          onMenuClose={internalOnMenuClose}
        />
      </Wrapper>
    );
  },
);

Select.displayName = 'Select';

export { Select };
