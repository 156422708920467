import { FC } from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { Label } from '../../label/Label';
import { FormItemLabel, FormItemWrapper } from '../formStyles';
import { FormSwitcher, FormSwitchProps } from './FormSwitcher';
import { Icon } from '../../page-header/PageHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const InfoContentWrapper = styled(Wrapper)`
  justify-content: flex-start;
`;

interface FormSwitcherWithLabelProps extends FormSwitchProps {
  label: string;
  infoContent?: string;
  isVertical?: boolean;
}

const FormSwitcherWithLabel: FC<FormSwitcherWithLabelProps> = ({
  label,
  isVertical,
  infoContent,
  ...rest
}) =>
  isVertical ? (
    <FormItemWrapper>
      <InfoContentWrapper>
        <FormItemLabel>{label}</FormItemLabel>
        {!!infoContent && (
          <Tippy placement="bottom" content={infoContent}>
            <Icon />
          </Tippy>
        )}
      </InfoContentWrapper>
      <FormSwitcher {...rest} />
    </FormItemWrapper>
  ) : (
    <Wrapper>
      <InfoContentWrapper>
        <Label>{label}</Label>
        {!!infoContent && (
          <Tippy placement="bottom" content={infoContent}>
            <Icon />
          </Tippy>
        )}
      </InfoContentWrapper>
      <FormSwitcher {...rest} />
    </Wrapper>
  );

export { FormSwitcherWithLabel };
