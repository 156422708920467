import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ResetPasswordContainer from '../../components/resetPassword/ResetPassword.container';

const ResetPasswordPage: FC<RouteComponentProps> = () => {
  return <ResetPasswordContainer />;
};

export default ResetPasswordPage;
