import {
  EventsByFilterInput,
  EventsByQuery,
  EventsOrderByInput,
  EVENTS_PAGE_SIZE,
  useEventsByQuery,
  useInfiniteGraphQLQuery,
} from '..';

export const useInfiniteEventsQuery = (
  filter: EventsByFilterInput,
  orderBy?: EventsOrderByInput,
) => {
  const getNextPageParam = (lastPage: EventsByQuery, allPages: EventsByQuery[]) =>
    lastPage.eventsBy.events.length === EVENTS_PAGE_SIZE ? allPages.length * EVENTS_PAGE_SIZE : 0;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    orderBy,

    pageOffset: Number(pageParam ?? 0),
    pageSize: EVENTS_PAGE_SIZE,
    filter,
  });

  return useInfiniteGraphQLQuery(useEventsByQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
  });
};
