export const defaultMapOptions = {
  strokeColor: '#FF0000',
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  strokeOpacity: 0.8,
  strokeWeight: 2,
  zIndex: 1,
};

export const METERS_IN_MILE = 1609.344;
export const DEFAULT_RADIUS_MILES_STRING = '1miles';
export const DEFAULT_RADIUS_METERS_NUMBER = 1000;
export const DEFAULT_MAP_ZOOM = 13;
