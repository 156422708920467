import { RecurringPeriod } from '@sim-admin-frontends/data-access';
import {
  Checkbox,
  CheckboxType,
  FormError,
  FormInput,
  FormSelect,
  Label,
  TCheckbox,
  TSelectItem,
} from '@sim-admin-frontends/ui-shared';
import { FC, useCallback, useState } from 'react';
import { FieldErrors, UseFormRegister, Control, UseFormSetValue, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CouponRecurrence, RecurrenceEnd, TCouponFormValues } from '../../types/TCoupons';
import { getRecurrenceTypeOptions } from '../../utils/couponsUtils';
import { getTestId } from '../../utils/testUtils';
import DayCheckboxGroup from '../dayCheckboxGroup/DayCheckboxGroup';
import { getCheckboxes } from './helper';
import RecurrenceEndsSection from './RecurrenceEndsSection';

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ theme }) => theme.spaces.spacing96};
  height: ${({ theme }) => theme.spaces.spacing48};
`;

export const StyledLabel = styled(Label)`
  font-weight: ${({ theme }) => theme.fontWeights.light};
  margin-right: ${({ theme }) => theme.spaces.spacing8};
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.spaces.spacing8};
`;

export const InputWrapper = styled.div`
  display: block;
  width: ${({ theme }) => theme.spaces.spacing64};
  margin-right: ${({ theme }) => theme.spaces.spacing8};
`;

const SelectWrapper = styled.div`
  display: block;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
`;

export const MarginBottomTop = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
  margin-top: ${({ theme }) => theme.spaces.spacing8};
`;

type RecurrenceSettings = {
  initialRecurrence: CouponRecurrence;
  minToDate: Date;
  maxToDate: Date;
  endsOn: RecurrenceEnd;
  onEndOnChange: (recurrenceType: RecurrenceEnd) => void;
  register: UseFormRegister<TCouponFormValues>;
  control: Control<TCouponFormValues>;
  errors: FieldErrors<TCouponFormValues>;
  setValue: UseFormSetValue<TCouponFormValues>;
};

const RecurrenceSettings: FC<RecurrenceSettings> = ({
  initialRecurrence,
  minToDate,
  maxToDate,
  endsOn,
  onEndOnChange,
  register,
  control,
  errors,
  setValue,
}) => {
  const isInitialWeekly = initialRecurrence.type.value === RecurringPeriod.Weekly;
  const [showDays, setShowDays] = useState(isInitialWeekly);
  const { t } = useTranslation();
  const parentTestId = 'RecurrenceSettings';
  const dayTestId = getTestId(parentTestId, 'day');
  const endsTestId = getTestId(parentTestId, 'RecurrenceEnds');
  const recurrenceTypeOptions = getRecurrenceTypeOptions(t);
  const checkboxes = getCheckboxes(t);

  const defaultRecurrenceDays = initialRecurrence.day;
  const defaultTypeEnd = initialRecurrence.type;

  const errorEvery = errors.recurrence?.every;
  const errorType = get(errors, 'recurrence.type');
  const errorDay = get(errors, 'recurrence.day');

  const renderCheckbox = useCallback(
    (checkbox: TCheckbox, name: string, index: number) => (
      <StyledCheckbox
        variant={CheckboxType.DAY}
        checked={defaultRecurrenceDays?.includes(`${index}`) || false}
        {...checkbox}
        {...register(`recurrence.day.${index}`)}
        testId={`${parentTestId}#${name}`}
        key={`${parentTestId}#${name}`}
      />
    ),
    [defaultRecurrenceDays, register],
  );

  const onRecurrenceTypeChange = (recurrenceType: readonly TSelectItem[] | null) => {
    if (!recurrenceType) {
      return;
    }
    const isWeekly = recurrenceType[0].value === RecurringPeriod.Weekly;
    setShowDays(isWeekly);
    if (!isWeekly) {
      setValue('recurrence.day', []);
    }
  };

  return (
    <>
      <MarginBottomTop>
        <FormRow>
          <LabelWrapper>
            <StyledLabel>{t('coupon.form.recurrence.repeatEvery')}</StyledLabel>
          </LabelWrapper>
          <InputWrapper>
            <FormInput
              min="1"
              type="number"
              {...register(`recurrence.every`)}
              noMargin
              testId={`${parentTestId}#every`}
            />
          </InputWrapper>
          <SelectWrapper>
            <FormSelect
              control={control}
              name="recurrence.type"
              options={recurrenceTypeOptions}
              defaultValue={defaultTypeEnd}
              onChange={onRecurrenceTypeChange}
              noMargin
              error={errorType}
              testId={`${parentTestId}#type`}
            />
          </SelectWrapper>
        </FormRow>
        {errorEvery && <FormError error={errorEvery} testId={`${parentTestId}#every-error`} />}
      </MarginBottomTop>
      {showDays && (
        <>
          <StyledLabel>{t('coupon.form.recurrence.repeatOn')}</StyledLabel>
          <DayCheckboxGroup
            name="day"
            checkboxes={checkboxes}
            renderCheckbox={renderCheckbox}
            testId={dayTestId}
          />
        </>
      )}
      {errorDay && <FormError error={errorDay} testId={`${parentTestId}#day-error`} />}
      <StyledLabel>{t('coupon.form.recurrence.ends')}</StyledLabel>
      <RecurrenceEndsSection
        initialRecurrence={initialRecurrence}
        minToDate={minToDate}
        maxToDate={maxToDate}
        endsOn={endsOn}
        onEndOnChange={onEndOnChange}
        register={register}
        control={control}
        errors={errors}
        testIdPrefix={endsTestId}
      />
    </>
  );
};

export default RecurrenceSettings;
