import {
  Component,
  ComponentState,
  SystemStatusQuery,
  useSystemStatusQuery,
} from '@sim-admin-frontends/data-access-admin-be';

const getInitialValues = (data?: SystemStatusQuery) => {
  let notificationsEnabled = false;
  let postsEnabled = false;
  data?.systemStatus.componentActions.forEach((action) => {
    if (action.component === Component.Notifications) {
      notificationsEnabled = action.componentState === ComponentState.TurnOn;
    }
    if (action.component === Component.Posts) {
      postsEnabled = action.componentState === ComponentState.TurnOn;
    }
  });
  return { notificationsEnabled, postsEnabled };
};

const useIsSystemDisabled = () => {
  const { data } = useSystemStatusQuery();
  const systemInfoValues = getInitialValues(data);
  const notificationsDisabled = !systemInfoValues.notificationsEnabled;
  const postsDisabled = !systemInfoValues.postsEnabled;
  return { notificationsDisabled, postsDisabled };
};

export default useIsSystemDisabled;
