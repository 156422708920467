import {
  State,
  useInstitutionPollsCountsQuery,
  useUnpublishAnnouncementMutation,
} from '@sim-admin-frontends/data-access';
import { Button, Spinner, SpinnerWrapper, TTabPanelTabs } from '@sim-admin-frontends/ui-shared';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';

import { changePollsTab } from '../../../utils/pollsUtils';
import { useAuthInfo } from '../../../contexts/userContext';
import { useBulkUnpublish } from '../../../hooks/useBulkUnpublish';
import ROUTES from '../../../routing/routes';
import { TAnnouncementsTabTypes } from '../../../types/TAnnouncements';
import { AnalyticsEvent } from '../../../utils/analytics/analyticsEvents';
import { CtaWrapper, StyledPanel } from '../../common/TabsStyles';
import PollDraftsViewContainer from './PollDraftsView.container';
import { PollsViewContainer } from './PollsView.container';

const pollsTabs = [
  TAnnouncementsTabTypes.PUBLISHED,
  TAnnouncementsTabTypes.SCHEDULED,
  TAnnouncementsTabTypes.DRAFTS,
  TAnnouncementsTabTypes.UNPUBLISHED,
];

const PollsTabs: FC = () => {
  const { t } = useTranslation();

  const {
    postsToUnpublish,
    onUnpublishSelect,
    renderBulkUnpublishButton,
    isUnpublishMutationSettled,
    clearSelection,
  } = useBulkUnpublish(useUnpublishAnnouncementMutation, AnalyticsEvent.ANNOUNCEMENT_ACTION);

  const { setTabIndex, tabIndex } = useTabNavigation(
    ROUTES.pollsOverview.path,
    pollsTabs,
    clearSelection,
  );
  const history = useHistory();
  const { institutionUuid } = useAuthInfo();
  const { data, isLoading } = useInstitutionPollsCountsQuery({
    id: institutionUuid,
  });
  const publishedCount = data?.institution?.publishedPollNewsCount;
  const unpublishedCount = data?.institution?.unpublishedPollNewsCount;
  const scheduledCount = data?.institution?.scheduledPollNewsCount;
  const draftsCount = data?.institution?.draftsPollNewsCount;
  const wasAnythingPublished = !!publishedCount || !!unpublishedCount;

  const getViewContainer = useCallback(
    (state: State) =>
      state === State.Draft ? (
        <PollDraftsViewContainer wasAnythingPublished={wasAnythingPublished} />
      ) : (
        <PollsViewContainer
          state={state}
          wasAnythingPublished={wasAnythingPublished}
          postsToUnpublish={postsToUnpublish}
          onUnpublishSelect={onUnpublishSelect}
          isUnpublishMutationSettled={isUnpublishMutationSettled}
        />
      ),
    [isUnpublishMutationSettled, onUnpublishSelect, postsToUnpublish, wasAnythingPublished],
  );

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: getViewContainer(State.Published),
        label: t('updates.tabs.published'),
        count: publishedCount,
        testId: 'PollsTabs#published',
      },
      {
        content: getViewContainer(State.Scheduled),
        label: t('updates.tabs.scheduled'),
        count: scheduledCount,
        testId: 'PollsTabs#scheduled',
      },
      {
        content: getViewContainer(State.Draft),
        label: t('updates.tabs.drafts'),
        count: draftsCount,
        testId: 'PollsTabs#drafts',
      },
      {
        content: getViewContainer(State.Unpublished),
        label: t('updates.tabs.unpublished'),
        count: unpublishedCount,
        testId: 'PollsTabs#unpublished',
      },
    ],
    [getViewContainer, t, publishedCount, scheduledCount, draftsCount, unpublishedCount],
  );

  const redirectToUnpublishedTab = () => {
    changePollsTab(history, TAnnouncementsTabTypes.UNPUBLISHED);
  };

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <StyledPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex}>
      <CtaWrapper>
        {renderBulkUnpublishButton(redirectToUnpublishedTab)}
        <Link to={generatePath(ROUTES.pollsEdit.path)}>
          <Button testId="PollsTabs#AddPoll">{t('polls.addUpdate')}</Button>
        </Link>
      </CtaWrapper>
    </StyledPanel>
  );
};

export default PollsTabs;
