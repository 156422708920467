import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { utcToZonedTime } from 'date-fns-tz';
import { ImageInput } from '@sim-admin-frontends/data-access';
import { isFile, roundMinutes } from '@sim-admin-frontends/utils-shared';
import { MAX_UPLOAD_SIZE, SUPPORTED_IMAGE_FORMATS } from '@sim-admin-frontends/ui-shared';

export const imagesValidation = (t: TFunction) =>
  Yup.array()
    .nullable()
    .of(
      Yup.mixed()
        .test('fileSize', t('common.validation.file.tooLarge'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
        )
        .test(
          'fileType',
          t('common.validation.file.unsupportedFormat'),
          (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_IMAGE_FORMATS.includes(file.type) : true,
        ),
    );

export const notificationsValidation = (t: TFunction, timezone: string) =>
  Yup.array().when('scheduledAt', (scheduledAt) => {
    const laterThanPresentSchema = Yup.date().min(
      roundMinutes(utcToZonedTime(new Date(), timezone)),
      t('common.validation.date.pastDate'),
    );

    const greaterThanScheduledSchema = Yup.date().test(
      'greaterThanScheduled',
      t('common.validation.date.greaterThanScheduled'),
      (value?: Date) => !!value && value > scheduledAt,
    );

    return Yup.array().of(
      Yup.object().shape({
        value: scheduledAt
          ? laterThanPresentSchema.concat(greaterThanScheduledSchema)
          : laterThanPresentSchema,
      }),
    );
  });
