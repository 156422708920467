import { FC, RefObject, useCallback, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import styled from 'styled-components';
import { TFunction } from 'i18next';

import { TBaseProps } from '../../types/TBaseComponent';
import { Button } from '../button/Button';
import { Modal } from '../modal/Modal';
import { useResizeWidth } from '../../hooks/useResizeWidth';
import { Slider } from '../slider/Slider';
import { SliderValue } from '../../types/TSlider';

import 'cropperjs/dist/cropper.css';

const LARGE_CROPPER_MODAL_WIDTH = 792;
const MEDIUM_CROPPER_MODAL_WIDTH = 528;
const SMALL_CROPPER_MODAL_WIDTH = 264;
const MAX_ZOOM = 4;

export const CropButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spaces.spacing16};
`;

export const CropperWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spaces.spacing32};

  & .cropper-container.cropper-bg {
    background: ${({ theme }) => theme.colors.white} !important;
  }
`;

const StyledCropper = styled(Cropper)`
  height: 400px;
  width: 100%;
`;

export interface ImageCropperProps extends TBaseProps {
  cropperRef: RefObject<ReactCropperElement>;
  aspectRatio?: number;
  minCropBoxWidth?: number;
  minCropBoxHeight?: number;
  src?: string;
  isCropperOpen: boolean;
  onCropClick: () => void;
  onCancelClick: () => void;
  t: TFunction;
}

const ImageCropper: FC<ImageCropperProps> = ({
  cropperRef,
  aspectRatio,
  minCropBoxWidth,
  minCropBoxHeight,
  src,
  isCropperOpen,
  onCropClick,
  onCancelClick,
  testId,
  t,
}) => {
  const [zoomRatio, setZoomRatio] = useState<number>();

  const onCropperReady = () => {
    const cropper = cropperRef?.current?.cropper;
    const image = cropper?.getImageData();
    if (image) {
      setZoomRatio(image.width / image.naturalWidth);
    }
  };

  const maxModalWidth = useResizeWidth(
    SMALL_CROPPER_MODAL_WIDTH,
    MEDIUM_CROPPER_MODAL_WIDTH,
    LARGE_CROPPER_MODAL_WIDTH,
  );

  const onZoomSliderChange = useCallback(
    (value: SliderValue) => {
      if (typeof value !== 'number') {
        return;
      }

      const cropper = cropperRef?.current?.cropper;

      if (cropper) {
        cropper.zoomTo(value);
      }
    },
    [cropperRef],
  );

  return (
    <Modal isOpen={isCropperOpen} maxWidth={maxModalWidth} shouldResize>
      <CropperWrapper>
        <StyledCropper
          ref={cropperRef}
          src={src}
          minCropBoxWidth={minCropBoxWidth}
          minCropBoxHeight={minCropBoxHeight}
          aspectRatio={aspectRatio}
          dragMode={'move'}
          cropBoxMovable={false}
          cropBoxResizable={false}
          toggleDragModeOnDblclick={false}
          guides={false}
          ready={onCropperReady}
          viewMode={1}
        />
        <CropButtonWrapper>
          <Slider
            onChange={onZoomSliderChange}
            min={zoomRatio}
            max={MAX_ZOOM}
            defaultValue={zoomRatio}
          />
          <Button variant="tertiary" onClick={onCancelClick} testId={`${testId}-cancelButton`}>
            {t('common.cancel')}
          </Button>
          <Button onClick={onCropClick} testId={`${testId}-cropButton`}>
            {t('common.save')}
          </Button>
        </CropButtonWrapper>
      </CropperWrapper>
    </Modal>
  );
};

export { ImageCropper };
