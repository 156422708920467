import { InstitutionPlaceRole } from '@sim-admin-frontends/data-access';

export const containsValue = (
  array: { label: string; value: string }[],
  value: string | null | undefined,
) => {
  return !!array.find((option) => {
    return option.value === value;
  });
};

export const getIsOfficial = (role: InstitutionPlaceRole | null | undefined) =>
  role === InstitutionPlaceRole.Official || role === InstitutionPlaceRole.City;

export const getInstitutionInitValues = (
  institutionUuids: string | null | undefined,
  initialInstitution: { id: string; name: string } | null | undefined,
  allInstitutions: { id: string; name: string }[] | undefined,
) => {
  const initialInstitutionOption = initialInstitution
    ? {
        label: initialInstitution.name,
        value: initialInstitution.id,
      }
    : undefined;

  const splitInstitutions = institutionUuids?.split(':');
  const initialInstitutions =
    splitInstitutions?.map((institutionUuid) => {
      if (institutionUuid === initialInstitutionOption?.value) {
        return initialInstitutionOption;
      }

      const foundInstitution = allInstitutions?.find(
        (institution) => institution.id === institutionUuid,
      );
      return { label: foundInstitution?.name || '', value: foundInstitution?.id || '' };
    }) || [];

  if (
    initialInstitutionOption &&
    !containsValue(initialInstitutions, initialInstitutionOption?.value)
  )
    initialInstitutions.push(initialInstitutionOption);

  const sortedInstitutions = initialInstitutions
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter((item) => !!item.label);

  return sortedInstitutions;
};
