import styled from 'styled-components';
import { FC, ReactElement, useState } from 'react';

import { TBaseProps } from '../../..';
import { ReactComponent as MinusIcon } from '../../../assets/img/minus.svg';
import { ReactComponent as PlusSmallIcon } from '../../../assets/img/plusSmall.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spaces.spacing2} ${({ theme }) => theme.spaces.spacing8};
  ${({ theme }) => theme.spaces.spacing16};
  align-self: flex-start;
  cursor: pointer;
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.coolGray100};
`;

export interface FormDateButtonProps extends TBaseProps {
  open?: boolean;
  label: string;
  onClick?: () => void;
  contentUnderButton?: boolean;
  className?: string;
  openedIcon?: ReactElement;
  closedIcon?: ReactElement;
  testId?: string;
}

const HideableFormItem: FC<FormDateButtonProps> = ({
  open,
  label,
  onClick,
  children,
  contentUnderButton,
  className,
  openedIcon = <MinusIcon />,
  closedIcon = <PlusSmallIcon />,
  testId,
}) => {
  const [isOpen, setIsOpen] = useState(open || false);
  const onButtonClick = () => {
    setIsOpen(!isOpen);
    if (onClick) {
      onClick();
    }
  };

  return (
    <Wrapper className={className}>
      {isOpen && !contentUnderButton && children}
      <ButtonWrapper onClick={onButtonClick} data-testid={testId}>
        {isOpen ? openedIcon : closedIcon}
        <Label>{label}</Label>
      </ButtonWrapper>
      {isOpen && contentUnderButton && children}
    </Wrapper>
  );
};

export { HideableFormItem };
