import { FC } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../../../types/TBaseComponent';

const Wrapper = styled.div<{ headerHeight: number }>`
  display: flex;
  justify-content: flex-end;
  height: ${({ headerHeight }) => headerHeight}px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray20};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 ${({ theme }) => theme.spaces.spacing16};
`;

export interface NavbarProps extends TBaseProps {
  headerHeight: number;
}

const Navbar: FC<NavbarProps> = ({ headerHeight, children }) => (
  <Wrapper headerHeight={headerHeight}>{children}</Wrapper>
);

export { Navbar };
