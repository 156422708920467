import { AnalyticsEvent } from '../utils/analytics/analyticsEvents';

export enum TItemAction {
  CREATE = 'Create',
  CREATE_DRAFT = 'Create Draft',
  EDIT_DRAFT = 'Edit Draft',
  EDIT = 'Edit',
  DELETE = 'Delete',
  PUBLISH = 'Publish',
  UNPUBLISH = 'Unpublish',
  PIN = 'Pin',
  UNPIN = 'Unpin',
  SCHEDULE = 'Schedule',
  CANCEL_SEND = 'Cancel Send',
  NOTIFY = 'NOTIFY',
}

export type TAnalyticsEvent =
  | {
      type: AnalyticsEvent.MENU_CLICK;
      data: { Name: string; InstitutionId?: string };
    }
  | {
      type: AnalyticsEvent.SCREEN_VIEW;
      data: {
        Name?: string;
        Subsection?: string;
        Referrer?: string;
        InstitutionId?: string;
      };
    }
  | {
      type: AnalyticsEvent.ANNOUNCEMENT_ACTION;
      data: {
        Id: string;
        Action: TItemAction;
        Notification?: boolean;
        InstitutionId?: string;
        Ids?: string[];
      };
    }
  | {
      type: AnalyticsEvent.COUPON_ACTION;
      data: {
        Id: string;
        Action: TItemAction;
        Notification?: boolean;
        InstitutionId?: string;
        Ids?: string[];
      };
    }
  | {
      type: AnalyticsEvent.EVENT_ACTION;
      data: {
        Id: string;
        Action: TItemAction;
        Notification?: boolean;
        InstitutionId?: string;
        Ids?: string[];
      };
    }
  | {
      type: AnalyticsEvent.EMERGENCY_ACTION;
      data: {
        Id: string;
        Action: TItemAction;
        Notification?: boolean;
        InstitutionId?: string;
        Ids?: string[];
      };
    }
  | {
      type: AnalyticsEvent.PET_ACTION;
      data: {
        Id: string;
        Action: TItemAction;
        Ids?: string[];
      };
    }
  | {
      type: AnalyticsEvent.RAFFLE_ACTION;
      data: {
        Id: string;
        Action: TItemAction;
        Notification?: boolean;
        InstitutionId?: string;
        Ids?: string[];
      };
    }
  | {
      type: AnalyticsEvent.QUICK_QUESTION_ACTION;
      data: {
        Id: string;
        Action: TItemAction;
        Notification?: boolean;
        InstitutionId?: string;
        Ids?: string[];
      };
    }
  | {
      type: AnalyticsEvent.CHAT_ACTION;
      data: {
        Id: string;
        Action: TItemAction;
        Notification?: boolean;
        InstitutionId?: string;
      };
    };

export type TPublishBulkAction =
  | AnalyticsEvent.ANNOUNCEMENT_ACTION
  | AnalyticsEvent.COUPON_ACTION
  | AnalyticsEvent.EVENT_ACTION
  | AnalyticsEvent.EMERGENCY_ACTION
  | AnalyticsEvent.RAFFLE_ACTION
  | AnalyticsEvent.QUICK_QUESTION_ACTION
  | AnalyticsEvent.PET_ACTION;
