import { History, LocationState } from 'history';
import { generatePath } from 'react-router-dom';
import { Pricing, PricingInput } from '@sim-admin-frontends/data-access';
import { TPrice } from '@sim-admin-frontends/ui-shared';

import ROUTES from '../routing/routes';
import { TEventsTabTypes } from '../types/TEvents';

export const changeEventsTab = (history: History<LocationState>, type: TEventsTabTypes) => {
  history.push(
    generatePath(ROUTES.eventsOverview.path, {
      type,
    }),
  );
};

export const transformInitialPriceValue = (price: Pricing): TPrice | undefined => {
  if (!price.summary?.fromPrice) {
    return undefined;
  }
  return {
    currency: price.currency,
    price: price.summary?.fromPrice,
    priceBeforeDiscount: price.summary?.fromPriceBeforeDiscount ?? undefined,
  };
};

export const transformPriceForBe = (price?: TPrice | null): PricingInput | undefined => {
  if (!price?.price || !price?.currency) {
    return undefined;
  }

  return {
    currency: price.currency,
    summary: {
      fromPrice: price.price,
      fromPriceBeforeDiscount: price.priceBeforeDiscount,
    },
  };
};
