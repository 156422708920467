import {
  IMAGE_TYPE_FALLBACK,
  ImageInput,
  uploadFile,
  UploadMethod,
  UploadType,
  useGenerateNamedUploadUrlsMutation,
} from '@sim-admin-frontends/data-access';

import { isFile } from '../file-utils';
import { TUploadDirectory, TUploadImagesFunction } from '../types/TUpload';

const useUploadImage = () => {
  const { mutateAsync: fetchUploadUrls } = useGenerateNamedUploadUrlsMutation();

  const filterImages = (
    imageFiles: (File | ImageInput | null | undefined)[] | null | undefined,
  ) => {
    const fileImages: File[] = [];
    const uploadedImages: ImageInput[] = [];

    if (imageFiles) {
      imageFiles.forEach((image) => {
        if (!!image && isFile(image)) {
          fileImages.push(image);
        }
        if (!!image && !isFile(image)) {
          uploadedImages.push(image);
        }
      });
    }
    return {
      fileImages,
      uploadedImages,
    };
  };

  const uploadImages = async (
    type: UploadType,
    images: { fileImages: File[]; uploadedImages: ImageInput[] },
    { institutionId, placeId, reminderCategoryCode }: TUploadDirectory,
  ) => {
    const uploadUrls = [];
    const files = images.fileImages.map((image) => ({
      fileName: image.name,
      contentType: image.type || IMAGE_TYPE_FALLBACK,
      uploadType: type,
    }));
    const { generateNamedUploadUrls } = await fetchUploadUrls({
      files,
      uploadMethod: UploadMethod.Put,
      reminderCategoryCode: reminderCategoryCode,
      institutionUuid: institutionId,
      placeUuid: placeId,
    });
    if (generateNamedUploadUrls) {
      for (const url of generateNamedUploadUrls) {
        const index = generateNamedUploadUrls.indexOf(url);
        await uploadFile(images.fileImages[index], url);
        uploadUrls.push(url);
      }
      images.uploadedImages.map((uploadedImage) => {
        uploadUrls.push(uploadedImage);
      });
    }
    return uploadUrls.length ? uploadUrls : undefined;
  };

  const uploadFormImages: TUploadImagesFunction = async (
    type,
    images,
    { institutionId, placeId, reminderCategoryCode },
  ) => {
    const filteredImages = filterImages(images);
    return uploadImages(type, filteredImages, { institutionId, placeId, reminderCategoryCode });
  };

  return { uploadFormImages };
};

export { useUploadImage };
