import { FC, ComponentPropsWithRef, ReactNode, ReactElement } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';

import { TBaseProps } from '../..';

export const MODAL_TRANSITION_LENGTH_MS = 250;

const Content = styled.div<{ maxWidth?: number; shouldResize?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};
  width: ${({ shouldResize }) => (shouldResize ? '100%' : 'unset')};
  background: ${({ theme }) => theme.colors.white};
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: ${({ theme }) => theme.borderRadius.radius16};
  text-align: center;
  display: flex;
  flex-direction: column;
  outline: none;
  box-shadow: 20px 20px 65px rgba(0, 0, 0, 0.07), 5px 5px 15px rgba(0, 0, 0, 0.12);
`;

const Overlay = styled.div`
  position: fixed;
  inset: 0px;
  background-color: rgba(18, 22, 25, 0.4);
  opacity: 0;
  transition: opacity ${MODAL_TRANSITION_LENGTH_MS}ms ease-in-out;
`;

export interface ModalProps extends TBaseProps {
  isOpen: boolean;
  maxWidth?: number;
  shouldResize?: boolean;
  onClose?: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
}

const Modal: FC<ModalProps> = ({ children, isOpen, maxWidth, shouldResize, onClose }) => {
  const contentElement = (props: ComponentPropsWithRef<'div'>, contentChildren: ReactNode) => (
    <Content {...props} maxWidth={maxWidth} shouldResize={shouldResize}>
      {contentChildren}
    </Content>
  );

  const overlayElement = (props: ComponentPropsWithRef<'div'>, contentEl: ReactElement) => (
    <Overlay {...props}>{contentEl}</Overlay>
  );

  return (
    // Note that the className properties are required to prevent react-modal
    // from overriding the styles defined in contentElement and overlayElement
    <ReactModal
      appElement={document.getElementById('root') || undefined}
      isOpen={isOpen}
      overlayClassName="_"
      className="_"
      contentElement={contentElement}
      overlayElement={overlayElement}
      onRequestClose={onClose}
      closeTimeoutMS={MODAL_TRANSITION_LENGTH_MS}
    >
      {children}
    </ReactModal>
  );
};

export { Modal };
