import { FC } from 'react';
import { getErrorMessage, toast, toastDismiss, TToastType } from '@sim-admin-frontends/ui-shared';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { useTranslation } from 'react-i18next';

import { TChangePasswordValues } from '../../types/TUser';
import { useAuthInfo } from '../../contexts/userContext';
import CompleteRegistration from './CompleteRegistration';

type Props = {
  user: CognitoUser;
  onChangePasswordSuccess: () => void;
};

const CompleteRegistrationContainer: FC<Props> = ({ user, onChangePasswordSuccess }) => {
  const { clearUser } = useAuthInfo();
  const { t } = useTranslation();

  const onSubmit = async (values: TChangePasswordValues) => {
    try {
      await Auth.completeNewPassword(user, values.password);
      //Amplify doesnt send us all data needed to setup analytics sdo lets reset everything and initiate login
      clearUser();
      Auth.signOut();
      toastDismiss();
      toast(t('completeRegistration.toastSuccess'), TToastType.INFO);
      onChangePasswordSuccess();
    } catch (err) {
      toast(getErrorMessage(err), TToastType.ERROR);
    }
  };
  return <CompleteRegistration onCompleteRegistration={onSubmit} />;
};

export default CompleteRegistrationContainer;
