import { FC, ReactNode } from 'react';

import { TBaseProps, TMenuEntry , TMenuEntries } from '../..';


export interface MenuProps extends TBaseProps {
  items: TMenuEntries;
  render: (item: TMenuEntry) => ReactNode;
}

const Menu: FC<MenuProps> = ({ items, className, render }) => (
  <div className={className}>{items.map(render)}</div>
);

export { Menu };
