export enum SuppportedLanguages {
  EN = 'en',
  UK = 'uk',
  RU = 'ru',
  SK = 'sk',
  ES = 'es',
  FIL = 'fil',
  AR = 'ar',
  FR = 'fr',
  UR = 'ur',
  BN = 'bn',
  KO = 'ko',
  PL = 'pl',
  ZH = 'zh',
  ZHTW = 'zh-TW',
  HT = 'ht',
  IT = 'it',
}
