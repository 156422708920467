import Tippy from '@tippyjs/react';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { InfoIcon, TBaseProps } from '../..';

const Wrapper = styled.div<{
  placement?: 'flex-start' | 'center' | 'flex-end';
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: ${({ placement }) => placement || 'flex-start'};
`;

export const Icon = styled(InfoIcon)`
  margin-left: ${({ theme }) => theme.spaces.spacing4};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  color: ${({ theme }) => theme.colors.coolGray100};
  margin-bottom: ${({ theme }) => theme.spaces.spacing4};
`;

const Caption = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.coolGray100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface PageHeaderProps extends TBaseProps {
  title: string;
  caption?: string | ReactNode;
  info?: string;
  placement?: 'flex-start' | 'center' | 'flex-end';
  shouldStretch?: boolean;
}

const PageHeader: FC<PageHeaderProps> = ({ title, caption, placement, children, testId, info }) => {
  return (
    <Wrapper placement={placement}>
      <Title data-testid={`${testId}-title`}>{title}</Title>
      {!!caption && (
        <Caption data-testid={`${testId}-caption`}>
          {caption}
          {info && (
            <Tippy placement="bottom" content={info}>
              <Icon />
            </Tippy>
          )}
        </Caption>
      )}
      {children}
    </Wrapper>
  );
};

export { PageHeader };
