export type TCheckbox = {
  value: string;
  text: string;
};

export type TCheckboxes = TCheckbox[];

export enum CheckboxType {
  REGULAR,
  DAY,
}
