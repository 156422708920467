import {
  EN_COUNTRY_CODE,
  SK_COUNTRY_CODE,
  UA_COUNTRY_CODE,
  US_COUNTRY_CODE,
} from '../common-utils';
import { SuppportedLanguages } from '../types/TLanguage';

export const langLabels = {
  [SuppportedLanguages.EN]: 'EN English',
  [SuppportedLanguages.UK]: 'UK українська',
  [SuppportedLanguages.RU]: 'RU русский',
  [SuppportedLanguages.SK]: 'SK Slovensky',
  [SuppportedLanguages.ES]: 'ES Español',
  [SuppportedLanguages.FIL]: 'FIL Filipino',
  [SuppportedLanguages.AR]: 'AR عربي',
  [SuppportedLanguages.FR]: 'FR Français',
  [SuppportedLanguages.UR]: 'UR اردو',
  [SuppportedLanguages.BN]: 'BN বাংলা',
  [SuppportedLanguages.KO]: 'KO 한국인',
  [SuppportedLanguages.PL]: 'PL Polski',
  [SuppportedLanguages.ZH]: 'ZH 简体中文',
  [SuppportedLanguages.ZHTW]: 'ZH-TW 中國傳統的',
  [SuppportedLanguages.HT]: 'HT Kreyòl ayisyen',
  [SuppportedLanguages.IT]: 'IT Italiano',
};

export const getLangLabel = (langCode: string) => langLabels[langCode as keyof typeof langLabels];

const getLangOption = (langCode: string) => ({
  label: getLangLabel(langCode),
  value: langCode,
});

export const getLangOptions = () => Object.values(SuppportedLanguages).map(getLangOption);

export const getDefaultLangCode = (countryCode = '') => {
  if (!countryCode) {
    return SuppportedLanguages.EN;
  }
  switch (countryCode) {
    case UA_COUNTRY_CODE:
      return SuppportedLanguages.UK;
    case SK_COUNTRY_CODE:
      return SuppportedLanguages.SK;
    case EN_COUNTRY_CODE:
    case US_COUNTRY_CODE:
      return SuppportedLanguages.EN;
    default:
      if (!Object.values(SuppportedLanguages).includes(countryCode as SuppportedLanguages)) {
        return SuppportedLanguages.EN;
      }

      return countryCode as SuppportedLanguages;
  }
};

export const getLangOptionByVal = (val = '', fallbackCountryCode = '') => {
  if (!val || !Object.values(SuppportedLanguages).includes(val as SuppportedLanguages)) {
    const fallbackCode = getDefaultLangCode(fallbackCountryCode);
    return getLangOption(fallbackCode);
  }
  return getLangOption(val);
};
