import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader, TableActionHeader, useSearchFilter } from '@sim-admin-frontends/ui-shared';
import { TFeatureFlagType } from '@sim-admin-frontends/data-access';

import { PageWrapper } from '../../components/layout/PageLayout';
import ModeratorTabs from '../../components/moderator/view/ModeratorTabs';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const ModeratorListPage: FC = () => {
  const { t } = useTranslation();
  const { shouldShowFeatureFlagDisabledUI, showFeatureFlagDisabledUI } = useFeatureFlag(
    TFeatureFlagType.MODERATOR,
  );
  const { filter, renderFilterComponent } = useSearchFilter({
    testId: 'ModeratorListPage#Search',
  });

  if (shouldShowFeatureFlagDisabledUI) {
    return showFeatureFlagDisabledUI();
  }

  return (
    <PageWrapper>
      <PageHeader
        title={t('moderator.pageTitle')}
        caption={t('moderator.pageCaption')}
        testId="ModeratorTabs#PageHeader"
      />
      <TableActionHeader prependItem={renderFilterComponent()} />
      <ModeratorTabs filter={filter} />
    </PageWrapper>
  );
};

export default ModeratorListPage;
