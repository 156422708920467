import { InstitutionPlaceRole, TInstitutionListItem } from '@sim-admin-frontends/data-access';
import { TSelectItem } from '@sim-admin-frontends/ui-shared';
import { useCallback, useState } from 'react';
import { getIsOfficial } from '@sim-admin-frontends/utils-shared';

import { useAuthInfo } from '../contexts/userContext';

type Props = {
  userInstitutions: TInstitutionListItem[];
};

const useChangePublisher = ({ userInstitutions }: Props) => {
  const {
    setInstitutionUuid,
    setIsLocalBusiness,
    setIsCityInstitution,
    setIsOfficialInstitution,
    setVisitorMode,
  } = useAuthInfo();
  const [publisherChanged, setPublisherChanged] = useState<string>();

  const onPublisherChange = useCallback(
    (selectedInstitution: readonly TSelectItem[] | null) => {
      if (!selectedInstitution) {
        return;
      }
      const foundInstitution = userInstitutions.find(
        (item) => item.id === selectedInstitution[0].value,
      );
      if (!foundInstitution?.id) {
        return;
      }
      const isOfficialInstitution = getIsOfficial(foundInstitution?.inPlaces?.[0]?.role);
      const isCityInstitution = foundInstitution?.inPlaces?.[0]?.role === InstitutionPlaceRole.City;
      const isLocalBusiness =
        foundInstitution?.inPlaces?.[0]?.role === InstitutionPlaceRole.LocalBusiness;
      const visitorMode = foundInstitution?.visitorMode || false;
      setIsOfficialInstitution(isOfficialInstitution);
      setIsCityInstitution(isCityInstitution);
      setIsLocalBusiness(isLocalBusiness);
      setVisitorMode(visitorMode);
      setInstitutionUuid(foundInstitution?.id);
      setPublisherChanged(foundInstitution?.id);
    },
    [setIsLocalBusiness, userInstitutions],
  );

  return { publisherChanged, onPublisherChange };
};

export default useChangePublisher;
