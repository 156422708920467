import * as Sentry from '@sentry/react';

import { DEFAULT_ADMIN_BE_GRAPHQL_ENDPOINT, ENDPOINT_NAME } from '../constants/Constants';

export const fetcher =
  <TData, TVariables>(query: string, variables?: TVariables) =>
  async (): Promise<TData> => {
    let graphqlEndpoint = process.env.NX_ADMIN_BE_GRAPHQL_ENDPOINT;
    if (!graphqlEndpoint) {
      graphqlEndpoint = DEFAULT_ADMIN_BE_GRAPHQL_ENDPOINT;
      Sentry.captureMessage(
        `Failed to set ${ENDPOINT_NAME} graphqlEndpoint from env, instead use default.`,
        {
          level: Sentry.Severity.Warning,
        },
      );
    }
    const res = await fetch(graphqlEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'preview-token': '1',
      },
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message, errorType } = json.errors[0];

      Sentry.captureMessage(`Backend ${ENDPOINT_NAME} GraphQL error ${message}`, {
        level: Sentry.Severity.Warning,
        extra: {
          variables,
          query,
          errorType,
        },
      });
      throw new Error(message);
    }

    return json.data;
  };
