import { ImageInput } from '@sim-admin-frontends/data-access';
import { TSelectItem } from '@sim-admin-frontends/ui-shared';

export interface TCouponFormValues {
  title: string;
  subtitle: string;
  images?: (File | ImageInput)[] | null;
  description: string;
  fromDate: Date;
  toDate: Date;
  fromTime?: string | null;
  fromTimePeriod?: string | null;
  toTime?: string | null;
  toTimePeriod?: string | null;
  notifications: { value: Date }[];
  notifyNow?: boolean;
  publishedAt?: Date | null;
  recurrence?: CouponRecurrence;
  isInfinite?: boolean | null;
}

export type TTimeValues = 'fromTime' | 'toTime' | 'fromTimePeriod' | 'toTimePeriod';

export type CouponRecurrence = {
  type: TSelectItem;
  every: number;
  day?: (string | boolean)[] | null;
  fromDate: Date;
  timeFrom: string;
  toDate?: Date | null;
  timeTo: string;
  occurrences?: number;
};

export enum RecurrenceEnd {
  NEVER = 'NEVER',
  ON_DATE = 'ON_DATE',
  AFTER_OCCURRENCES = 'AFTER_OCCURRENCES',
}

export enum TCouponsTabTypes {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}

export enum CouponModalActions {
  DELETE = 'DELETE',
  UNPUBLISH = 'UNPUBLISH',
  PUBLISH = 'PUBLISH',
  CANCEL_SEND = 'CANCEL_SEND',
}
