import { FC, KeyboardEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { createEditor, Transforms } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import styled, { css } from 'styled-components';
import isHotkey from 'is-hotkey';
import { TWysiwygValue } from '@simplicity-tech/sim-slate-types';
import { withHistory } from 'slate-history';

import { TBaseProps } from '../../types/TBaseComponent';
import {
  handleEnter,
  handleSpace,
  renderElement,
  renderLeaf,
  sanitizeWysiwygValue,
  toggleLink,
  toggleMark,
} from './helpers/helper';
import Toolbar from './toolbar/Toolbar';
import withLinks from './decorators/withLinks';
import withHelpers from './decorators/withHelpers';
import withMedia from './decorators/withMedia';

export interface WysiwygProps extends TBaseProps {
  initialValue?: TWysiwygValue;
  onValueChanged?: (value: TWysiwygValue) => void;
  invalid?: boolean;
  disabled?: boolean;
}

const StyledEditor = styled(Editable)<{ $invalid: boolean; disabled?: boolean }>`
  min-height: 250px;
  width: 100%;
  color: ${({ theme }) => theme.colors.coolGray100};
  ${({ disabled }) => disabled && 'background-color: whitesmoke'};
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  border-top: 0;
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: ${({ theme }) => `${theme.spaces.spacing8} ${theme.spaces.spacing16}`};
  font-family: ${({ theme }) => theme.fonts.primary}, serif;
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.fontSizes.small};

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.blue60};
    border-top: 1px solid ${({ theme }) => theme.colors.coolGray20};
    padding-left: ${({ theme }) => `calc(${theme.spaces.spacing16} - 1px)`};
    padding-right: ${({ theme }) => `calc(${theme.spaces.spacing16} - 1px)`};
    padding-bottom: ${({ theme }) => `calc(${theme.spaces.spacing8} - 1px)`};
    padding-top: ${({ theme }) => `calc(${theme.spaces.spacing8} - 1px)`};
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.lightText};
  }

  ${({ $invalid, theme }) =>
    $invalid &&
    css`
      border: 2px solid ${theme.colors.red60};
      border-top: 1px solid ${theme.colors.coolGray20};
    `}
`;

const EMPTY_WYSIWYG_VALUE: TWysiwygValue = [
  {
    type: 'paragraph',
    children: [{ text: '', type: 'text' }],
  },
];
/**
 * Before making any changes to this component/folder
 * make sure you check Slates docs and understand how stuff works
 * https://docs.slatejs.org/concepts/
 *
 * Mostly you should modify helper.tsx file
 */
const Wysiwyg: FC<WysiwygProps> = ({
  onValueChanged,
  initialValue = EMPTY_WYSIWYG_VALUE,
  invalid = false,
  testId,
  disabled,
}) => {
  const [value, setValue] = useState<TWysiwygValue>(sanitizeWysiwygValue(initialValue));
  useEffect(() => {
    setValue(sanitizeWysiwygValue(initialValue));
  }, [initialValue]);

  const editor = useMemo(
    () => withHelpers(withMedia(withLinks(withReact(withHistory(createEditor()))))),
    [],
  );
  const [isFocused, setFocused] = useState(false);

  const onBlur = useCallback(() => {
    //We need to remember selection before we lose focus to restore it later
    // e.g. for link popup & safari bugs
    editor.blurSelection = editor.selection;
    setFocused(false);
  }, [editor]);

  const onFocus = useCallback(
    (event) => {
      event.preventDefault();
      setFocused(true);
    },
    [setFocused],
  );

  const handleValueChanged = useCallback(
    (editorValue: TWysiwygValue) => {
      setValue(editorValue);
      if (onValueChanged) onValueChanged(editorValue);
    },
    [onValueChanged],
  );

  const focusOnToolbarClick = (event: MouseEvent<HTMLDivElement>) => {
    const editorRef = document.getElementById('editor');
    event.preventDefault();
    event.stopPropagation();
    if (editorRef) editorRef.focus();
  };

  const customRenderLeaf = useCallback(renderLeaf, []);

  const customRenderElement = useCallback(renderElement, []);

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (isHotkey('mod+k', event)) {
      event.preventDefault();
      toggleLink(editor);
      return;
    }
    if (isHotkey('mod+b', event)) {
      event.preventDefault();
      toggleMark(editor, 'bold');
      return;
    }
    if (isHotkey('mod+i', event)) {
      event.preventDefault();
      toggleMark(editor, 'italic');
      return;
    }
    if (isHotkey('escape', event)) {
      event.preventDefault();
      Transforms.collapse(editor, { edge: 'end' });
      return;
    }
    if (isHotkey('enter', event)) {
      handleEnter(editor, event);
    }
    if (isHotkey('space', event)) {
      handleSpace(editor, event);
    }
  }, []);
  return (
    <Slate editor={editor} value={value} onChange={handleValueChanged}>
      <Toolbar editorFocused={isFocused} invalid={invalid} onMouseDown={focusOnToolbarClick} />
      <StyledEditor
        disabled={disabled}
        readOnly={disabled}
        id={'editor'}
        tabIndex={0}
        $invalid={invalid}
        data-testid={testId}
        onBlur={onBlur}
        onFocus={onFocus}
        renderElement={customRenderElement}
        renderLeaf={customRenderLeaf}
        onKeyDown={handleKeyDown}
      />
    </Slate>
  );
};

export { Wysiwyg, EMPTY_WYSIWYG_VALUE };
