export enum AnalyticsEvent {
  MENU_CLICK = 'Menu click',
  SCREEN_VIEW = 'Screen View',
  ANNOUNCEMENT_ACTION = 'Announcement action',
  RAFFLE_ACTION = 'Raffle action',
  EVENT_ACTION = 'Event action',
  EMERGENCY_ACTION = 'Emergency action',
  PET_ACTION = 'Pet action',
  COUPON_ACTION = 'Coupon action',
  QUICK_QUESTION_ACTION = 'Quick Question Action',
  CHAT_ACTION = 'Chat Action',
}
