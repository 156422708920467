import { SERIALIZED_WYSIWYG_EMPTY_VALUE } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import styled from 'styled-components';

import { parseSlateText } from '../../utils/announcementsUtils';
import { getTestId } from '../../utils/testUtils';
import { TEST_ID_PAGE_PREVIEW_PREFIX } from '../layout/pageContent/PageContentPreview';
import RichTextRenderer from '../richText/RichTextRenderer';

const Text = styled.div<{ hasContent: boolean }>`
  color: ${({ theme, hasContent }) =>
    hasContent ? theme.colors.coolGray100 : theme.colors.coolGray30};
  font-size: ${({ theme }) => theme.fontSizes.preview};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  word-wrap: break-word;
`;

interface PreviewItemTextProps {
  slateText?: string;
  textPlaceholder?: string;
  testIdPrefix?: string;
}

const PreviewItemText: FC<PreviewItemTextProps> = ({
  slateText,
  textPlaceholder,
  testIdPrefix = TEST_ID_PAGE_PREVIEW_PREFIX,
}) => {
  const isTextEmpty = slateText === SERIALIZED_WYSIWYG_EMPTY_VALUE;
  const slateTextContent = parseSlateText(slateText);
  return (
    <Text hasContent={!isTextEmpty} data-testid={getTestId(testIdPrefix, 'Text')}>
      {isTextEmpty ? textPlaceholder : <RichTextRenderer value={slateTextContent} />}
    </Text>
  );
};

export default PreviewItemText;
