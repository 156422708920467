import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

export const initSentry = () =>
  Sentry.init({
    dsn: process.env.NX_SENTRY_DSN, // On localhost, the DSN shouldn't be set to disable sending development errors.
    environment: process.env.NX_ENVIRONMENT || 'development',
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });
