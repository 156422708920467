import { ChangeEvent, FC, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import {
  InfoIcon,
  Input,
  Label,
  RadioButton,
  SmallestText,
  TBaseProps,
} from '@sim-admin-frontends/ui-shared';
import { HarmfulContentReason } from '@sim-admin-frontends/data-access';

import { getInfoModalOptions, getInfoModalTitle } from './helper';
import { getTestId } from '../../../utils/testUtils';

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spaces.spacing16};
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spaces.spacing16};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.coolGray100};
  margin-left: ${({ theme }) => theme.spaces.spacing12};
`;

const StyledTippy = styled(Tippy)`
  &.tippy-box {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.spaces.spacing8};
    box-shadow: 20px 20px 65px rgba(0, 0, 0, 0.07), 5px 5px 15px rgba(0, 0, 0, 0.12);
  }
`;

const InputWrapper = styled.div`
  margin: ${({ theme }) => theme.spaces.spacing8} 0;
  margin-left: 30px;
`;

const ToolTipWrapper = styled.div`
  max-width: 326px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${({ theme }) => theme.spaces.spacing12};
`;

const Icon = styled.div`
  height: 10px;
  width: 10px;
  background: ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.spaces.spacing10};
  margin-right: ${({ theme }) => theme.spaces.spacing8};
  flex-shrink: 0;
`;

export interface ModeratorModalOptionProps extends TBaseProps {
  reason: HarmfulContentReason;
  reactionText: string;
  isSelected: boolean;
  onSelect: (reason: HarmfulContentReason) => void;
  onReactionTextChanged: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ModeratorModalOption: FC<ModeratorModalOptionProps> = ({
  reason,
  reactionText,
  isSelected,
  onSelect,
  onReactionTextChanged,
}) => {
  const { t } = useTranslation();
  const reasonName = getInfoModalTitle(reason, t);
  const showInput = reason === HarmfulContentReason.Other;
  const inputDisabled = !isSelected && showInput;

  const renderToolTipContent = useCallback(
    () =>
      getInfoModalOptions(reason, t).map((item) => (
        <ToolTipWrapper key={item}>
          <Icon />
          <SmallestText>{item}</SmallestText>
        </ToolTipWrapper>
      )),
    [reason, t],
  );

  return (
    <OptionWrapper key={reason}>
      <Row>
        <RadioWrapper>
          <RadioButton
            value={isSelected}
            onChange={() => onSelect(reason)}
            testId={getTestId('ModeratorModalReason', reason)}
          />
          <StyledLabel>{reasonName}</StyledLabel>
        </RadioWrapper>
        <StyledTippy arrow={false} placement="right-start" content={renderToolTipContent()}>
          <InfoIcon />
        </StyledTippy>
      </Row>
      {showInput && (
        <InputWrapper>
          <Input
            value={reactionText}
            disabled={inputDisabled}
            testId="OtherReason#Input"
            onChange={onReactionTextChanged}
          />
        </InputWrapper>
      )}
    </OptionWrapper>
  );
};

export default ModeratorModalOption;
