import styled from 'styled-components';
import { FC } from 'react';

const PROGRESS_PERCENTAGE_MULTIPLIER = 0.75;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  padding: ${({ theme }) => theme.spaces.spacing12} 0;
  background-color: ${({ theme }) => theme.colors.white};
  margin: ${({ theme }) => theme.spaces.spacing12};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  flex: 1;
`;

const ProgressWrapper = styled.div<{ width: number }>`
  display: flex;
  width: ${({ width }) => width}%;
  background-color: ${({ theme }) => theme.colors.coolGray20};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.coolGray100};
  margin: 0 ${({ theme }) => theme.spaces.spacing24};
  z-index: 2;
`;

type Props = {
  label: string;
  percentage: number;
};

const QuickQuestionResultItem: FC<Props> = ({ label, percentage = 0 }) => (
  <Wrapper>
    {<ProgressWrapper width={percentage * PROGRESS_PERCENTAGE_MULTIPLIER + 1} />}
    <ContentWrapper>
      <Label>{label}</Label>
    </ContentWrapper>
    {<Label>{Math.round(percentage)}%</Label>}
  </Wrapper>
);

export default QuickQuestionResultItem;
