import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';

import { FormInput } from '../form-input/FormInput';
import { ReactComponent as PlusIcon } from '.././../../assets/img/plus.svg';
import { FormError } from '../form-error/FormError';

const EMPTY_INPUT = 'empty';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spaces.spacing2} ${({ theme }) => theme.spaces.spacing8};
  ${({ theme }) => theme.spaces.spacing16};
  align-self: flex-start;
  cursor: pointer;
`;

const ButtonLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.coolGray100};
  margin-right: ${({ theme }) => theme.spaces.spacing8};
`;

interface FormMultipleInputsProps {
  label: string;
  placeholder?: string;
  buttonLabel: string;
  onChange: (values: string[]) => void;
  values?: string[];
  error?: FieldError;
  testId?: string;
}

const FormMultipleInputs: FC<FormMultipleInputsProps> = ({
  label,
  placeholder,
  buttonLabel,
  values,
  onChange,
  error,
  testId,
}) => {
  const initialValues = values ? values.map((value) => value) : [EMPTY_INPUT];

  const [valuesArray, setValuesArray] = useState<string[]>(initialValues);

  const onDeleteClick = (index: number) => () => {
    const arr = [...valuesArray];
    setValuesArray(arr.filter((_, i) => index !== i));
  };

  const onAddMoreClick = () => {
    setValuesArray([...valuesArray, EMPTY_INPUT]);
  };

  const onInputValueChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newArr = [...valuesArray];
    newArr[index] = event.target.value || EMPTY_INPUT;
    setValuesArray(newArr);
  };

  useEffect(() => {
    const filteredValues = valuesArray.filter((el) => el !== EMPTY_INPUT);
    onChange(filteredValues);
  }, [valuesArray]);

  const renderInputs = useCallback(() => {
    return valuesArray.map((inputValue, index) => (
      <FormInput
        label={label}
        placeholder={placeholder}
        value={inputValue === EMPTY_INPUT ? undefined : inputValue}
        onDeleteClick={valuesArray.length === 1 ? undefined : onDeleteClick(index)}
        onChange={(event) => onInputValueChange(event, index)}
        key={index}
        testId={testId}
      />
    ));
  }, [valuesArray]);

  return (
    <>
      {renderInputs()}
      <ButtonWrapper data-testid="QuickQuestion#addButton" onClick={onAddMoreClick}>
        <ButtonLabel>{buttonLabel}</ButtonLabel>
        <PlusIcon />
      </ButtonWrapper>
      {error && <FormError error={error} testId={`${testId}-error`} />}
    </>
  );
};

export { FormMultipleInputs };
