import { FC, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  PlaceQuery,
  usePlaceQuery,
  useSetPlaceInfoMutation,
  useMessagingCategoriesByQuery,
  useDeleteMessagingCategoryMutation,
} from '@sim-admin-frontends/data-access-admin-be';
import {
  Error,
  getErrorMessage,
  loadingToast,
  PageHeader,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { transformEmptyHelper } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import {
  TMessagingCategoriesMasterEmailFormValues,
  TMessagingCategory,
} from '../../../types/TMessagingCategories';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { PageWrapper } from '../../layout/PageLayout';
import SettingsMessagesCategoriesMaster from './SettingsMessagesCategoriesMasterEmail';
import MessagingCategoryAddModal from './MessagingCategoryAddModal';
import SettingsMessagesCategories from './SettingsMessagesCategories';
import useIsReportProblemEnabled from '../../../hooks/useIsReportProblemEnabled';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const PageContent = styled.div`
  max-width: 580px;
  width: 100%;
`;

const SHARED_TOAST_ID = 'settingsMessagesSubmit';

const SettingsMessagesContainer: FC = () => {
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<TMessagingCategory | null>(null);
  const history = useHistory();
  const queryClient = useQueryClient();
  const placeId = places?.[0]?.id || '';

  const {
    data: placeData,
    isLoading: placeIsLoading,
    isError: placeIsError,
  } = usePlaceQuery({
    id: placeId,
  });
  const placeQueryKey = usePlaceQuery.getKey({
    id: placeId,
  });

  const {
    isEnabled,
    isError: isReportProblemError,
    isLoading: isReportProblemLoading,
  } = useIsReportProblemEnabled();

  const {
    data: messagingCategories,
    isLoading: messagingCategoriesIsLoading,
    isError: messagingCategoriesIsError,
    refetch,
  } = useMessagingCategoriesByQuery({
    filter: { placeUuid: placeId },
  });

  const onMutateSuccess = () => {
    queryClient.invalidateQueries(
      useMessagingCategoriesByQuery.getKey({
        filter: { placeUuid: placeId },
      }),
    );
  };

  const { mutateAsync: setPlaceInfo } = useSetPlaceInfoMutation();
  const { mutateAsync: deleteMessagingCategory } = useDeleteMessagingCategoryMutation({
    onSuccess: onMutateSuccess,
  });

  const handleMasterEmailSubmit = async (formValues: TMessagingCategoriesMasterEmailFormValues) => {
    try {
      loadingToast(t('updates.form.toastSubmitting'), {
        toastId: SHARED_TOAST_ID,
      });
      const prevData = queryClient.getQueryData<PlaceQuery>(placeQueryKey);
      await setPlaceInfo({
        id: placeId,
        info: {
          ...prevData?.place?.info,
          reportsEmail: transformEmptyHelper(formValues.reportsEmail),
        },
      });
      updateToast(
        SHARED_TOAST_ID,
        t('settings.messaging.masterEmail.toastSuccess'),
        TToastType.SUCCESS,
      );
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };

  const handleMasterEmailDiscard = () => {
    history.push(ROUTES.home.path);
  };

  const onAddCategoryClick = () => {
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setSelectedCategory(null);
    setIsModalOpen(false);
  };

  const onSelectCategory = (category: TMessagingCategory) => {
    setSelectedCategory(category);
    setIsModalOpen(true);
  };

  const onDeleteCategory = async (category: TMessagingCategory) => {
    try {
      loadingToast(t('updates.form.toastSubmitting'), {
        toastId: SHARED_TOAST_ID,
      });
      const categoryId = category.id;
      await deleteMessagingCategory({ id: categoryId });
      updateToast(
        SHARED_TOAST_ID,
        t('settings.messaging.categories.toastDeleteSuccess'),
        TToastType.SUCCESS,
      );
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };

  if (
    placeIsLoading ||
    messagingCategoriesIsLoading ||
    isReportProblemLoading ||
    placeIsError ||
    messagingCategoriesIsError ||
    isReportProblemError
  ) {
    return (
      <SpinnerWrapper>
        {placeIsLoading || messagingCategoriesIsLoading || isReportProblemLoading ? (
          <Spinner />
        ) : (
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        )}
      </SpinnerWrapper>
    );
  }

  if (!isEnabled) {
    return <Redirect to="/404" />;
  }

  const categories = messagingCategories?.messagingCategoriesBy?.messagingCategories || [];
  const reportsEmail = placeData?.place?.info?.reportsEmail;

  return (
    <PageWrapper>
      <Page>
        <PageContent>
          <PageHeader
            title={t('settings.messaging.title')}
            caption={t('settings.messaging.description')}
            testId={'MessagesSettings#PageHeader'}
          />
          {categories.length ? (
            <SettingsMessagesCategories
              categories={categories}
              onSelect={onSelectCategory}
              onDelete={onDeleteCategory}
              onAddCategoryClick={onAddCategoryClick}
            />
          ) : (
            <SettingsMessagesCategoriesMaster
              onSubmit={handleMasterEmailSubmit}
              onDiscard={handleMasterEmailDiscard}
              onAddCategoryClick={onAddCategoryClick}
              reportsEmail={reportsEmail}
            />
          )}
          <MessagingCategoryAddModal
            isOpen={isModalOpen}
            onClose={onModalClose}
            selectedCategory={selectedCategory}
            onMutateSuccess={onMutateSuccess}
          />
        </PageContent>
      </Page>
    </PageWrapper>
  );
};

export default SettingsMessagesContainer;
