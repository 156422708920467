import { getLoginErrorMessage, toast, TToastType } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Login from './Login';
import { TLoggedUser, TLoginFormValues } from '../../types/TUser';
import { useAuthInfo } from '../../contexts/userContext';
import ROUTES from '../../routing/routes';
import CompleteRegistrationContainer from '../completeRegistration/CompleteRegistration.container';
import { analyticsSetUserId } from '../../utils/analytics/analyticsUtils';

const ADMIN_GROUP_ID = 'Admin';

type LoginResponse = TLoggedUser & {
  challengeName?: string;
};

const LoginContainer: FC = () => {
  const { setUser, setInstitutionUuid, clearUser, user: userLogged } = useAuthInfo();
  const [userWithoutNewPassword, setUserWithoutNewPassword] = useState<CognitoUser | null>(null);
  const { t } = useTranslation();
  const history = useHistory();

  const onChangePasswordSuccess = () => {
    setUserWithoutNewPassword(null);
  };

  useEffect(() => {
    if (userLogged) {
      history.push(ROUTES.home.path);
    }
  }, []);

  const onLogin = async (values: TLoginFormValues) => {
    const { password, username } = values;
    try {
      clearUser();
      const user: LoginResponse = await Auth.signIn(username, password);
      if (user.getSignInUserSession()) {
        const userGroup = user.getSignInUserSession()?.getIdToken().payload['cognito:groups'];
        if (!userGroup.includes(ADMIN_GROUP_ID)) {
          toast(t('error.loginError'), TToastType.ERROR);
        } else {
          setUser(user);
          setInstitutionUuid(user.attributes?.['custom:institutionUuid'] || '');
          const userSub = user.attributes?.sub;
          if (!userSub) {
            Sentry.captureMessage('Failed to send user sub to analytics. User sub is undefined.', {
              level: Sentry.Severity.Warning,
            });
          } else {
            analyticsSetUserId(userSub);
          }

          history.push(ROUTES.home.path);
        }
      }
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        toast(t('completeRegistration.toast'), TToastType.INFO, {
          autoClose: false,
        });
        setUserWithoutNewPassword(user);
      }
    } catch (err) {
      toast(getLoginErrorMessage(err, t), TToastType.ERROR);
    }
  };

  return userWithoutNewPassword ? (
    <CompleteRegistrationContainer
      user={userWithoutNewPassword}
      onChangePasswordSuccess={onChangePasswordSuccess}
    />
  ) : (
    <Login onLogin={onLogin} />
  );
};

export default LoginContainer;
