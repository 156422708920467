import { Maybe, PlaceFeatureFlag } from '@sim-admin-frontends/data-access';
import { TFunction } from 'i18next';
import { generatePath } from 'react-router-dom';

import ROUTES from '../../../routing/routes';
import { TSettingsTabs } from '../../../types/TSettings';

export type HeaderTab = {
  to: string;
  name: string;
  disabled?: boolean;
  testId?: string;
};

export type HeaderTabs = HeaderTab[];

export enum HeaderTabType {
  SETTINGS = 'settings',
}
export const getHeaderTabsType = (path: string): Maybe<HeaderTabType> => {
  switch (path) {
    case ROUTES.settings.path:
      return HeaderTabType.SETTINGS;
    default:
      return null;
  }
};

export const getHeaderTabsSettings = (
  isPlaceAdmin: boolean,
  t: TFunction,
  type: HeaderTabType | null,
  placeFeatureFlags: PlaceFeatureFlag[],
  isReportProblemEnabled: boolean,
): HeaderTabs => {
  switch (type) {
    case HeaderTabType.SETTINGS:
      return createSettingsTabs(isPlaceAdmin, t, placeFeatureFlags, isReportProblemEnabled);
    default:
      return [];
  }
};

const createSettingsTabs = (
  isPlaceAdmin: boolean,
  t: TFunction,
  placeFeatureFlags: PlaceFeatureFlag[],
  isReportProblemEnabled: boolean,
): HeaderTabs => [
  {
    name: t('settings.tabs.aboutCity'),
    to: generatePath(ROUTES.settings.path, {
      type: TSettingsTabs.ABOUT_CITY,
    }),
    disabled: !isPlaceAdmin,
    testId: 'settingsTabs#aboutCity',
  },
  {
    name: t('settings.tabs.profile'),
    to: ROUTES.settings.path,
    testId: 'settingsTabs#profile',
  },
  ...(isReportProblemEnabled
    ? [
        {
          name: t('settings.tabs.messages'),
          to: generatePath(ROUTES.settings.path, {
            type: TSettingsTabs.MESSAGES,
          }),
          disabled: !isPlaceAdmin,
          testId: 'settingsTabs#messages',
        },
      ]
    : []),
];
