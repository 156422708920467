import { FC } from 'react';
import styled from 'styled-components';
import { TCustomText } from '@simplicity-tech/sim-slate-types';

import RichTextText from './RichTextText';

export const Hyperlink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.primary};
`;

type Props = {
  url: string;
  content: TCustomText[];
};

const RichTestLink: FC<Props> = ({ url, content }) => {
  return (
    <Hyperlink href={url} target="_blank">
      {content.map((text, index) => (
        <RichTextText content={text} key={index} />
      ))}
    </Hyperlink>
  );
};

export default RichTestLink;
