import { State } from '@sim-admin-frontends/data-access';
import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateChatActions } from '../../../hooks/actionButtons/useGenerateChatActions';

export interface ChatTableMenuProps extends TBaseProps {
  postId: string;
  refetch: () => void;
  type: State;
}

const ChatTableMenu: FC<ChatTableMenuProps> = ({ postId, refetch, testId, type }) => {
  const { listActions: items, renderModal } = useGenerateChatActions({
    postId,
    refetch,
    type,
  });

  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export { ChatTableMenu };
