import { Dispatch, useState } from 'react';
import { Select, TSelectItem, TSelectItems } from '@sim-admin-frontends/ui-shared';
import styled from 'styled-components';

import { usePlaceInfo } from '../contexts/placeContext';

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const useMultiCityTableSelect = (onCityChange?: Dispatch<string>) => {
  const { places } = usePlaceInfo();

  const [selectedPlaceId, setSelectedPlaceId] = useState(places?.[0]?.id);

  const onDropdownCitySelect = (selectedCity: readonly TSelectItem[] | null) => {
    if (!selectedCity) return;
    if (onCityChange) onCityChange(selectedCity[0]?.label);
    setSelectedPlaceId(selectedCity[0]?.value);
  };

  const selectOptions: TSelectItems = places
    ? places.map((place) => ({
        label: place.name,
        value: place.id,
      }))
    : [];

  const defaultSelectValue = selectOptions.find((option) => option.value === selectedPlaceId);

  const renderSelect = () =>
    selectOptions.length > 1 ? (
      <SelectWrapper>
        <Select
          options={selectOptions}
          onChange={onDropdownCitySelect}
          defaultValue={defaultSelectValue}
        />
      </SelectWrapper>
    ) : null;

  return { selectedPlaceId, renderSelect };
};

export default useMultiCityTableSelect;
