import { FC } from 'react';
import { generatePath, Redirect, Route } from 'react-router-dom';

import ROUTES from './routes';
import Layout from '../components/layout/Layout';
import { usePlaceInfo } from '../contexts/placeContext';
import { useAuthInfo } from '../contexts/userContext';

const PrivateRoute: FC<{
  component: FC<any>;
  path: string;
  exact?: boolean;
}> = ({ component: Component, ...rest }) => {
  const { user } = useAuthInfo();
  const { places } = usePlaceInfo();

  if (!user) {
    return <Redirect to={generatePath(ROUTES.login.path)} />;
  }

  if (!places) {
    return <Redirect to={generatePath(ROUTES.placeLoading.path)} />;
  }

  return (
    <Route
      {...rest}
      render={({ match }) => {
        return (
          <Layout>
            <Component {...rest} match={match} />
          </Layout>
        );
      }}
    />
  );
};

export default PrivateRoute;
