import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ForgotPasswordContainer from '../../components/forgotPassword/ForgotPassword.container';

const ForgotPasswordPage: FC<RouteComponentProps> = () => {
  return <ForgotPasswordContainer />;
};

export default ForgotPasswordPage;
