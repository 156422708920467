import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import styled, { css } from 'styled-components';
import { components, DropdownIndicatorProps, OptionProps } from 'react-select';

import { SelectStyles } from '../select/SelectStyles';
import { TBaseProps } from '../../types/TBaseComponent';
import { TSelectItem, TSelectOption } from '../../types/TSelect';
import { FormItemLabel, FormItemWrapper } from '../form/formStyles';
import { FormError } from '../form/form-error/FormError';
import { ReactComponent as MapPin } from '../../assets/img/map_pin.svg';
import { ReactComponent as SearchIcon } from '../../assets/img/search.svg';

const Wrapper = styled(SelectStyles)<{ isError: boolean }>`
  & .react-select__placeholder,
  .react-select__menu-notice {
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: ${({ theme }) => theme.lineHeights.caption1};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-family: Manrope, serif;
    color: ${({ theme }) => theme.colors.lightText};
  }
  & .react-select__control {
    padding-left: 40px;
    border: 1px solid;
    border-color: ${({ isError, theme }) =>
      isError ? theme.colors.red60 : theme.colors.coolGray20};
  }

  & .react-select__control:hover {
    border: 1px solid;
    border-color: ${({ isError, theme }) =>
      isError ? theme.colors.red60 : theme.colors.coolGray20};
  }
`;

const Search = styled(SearchIcon)`
  position: absolute;
  z-index: 1;
  top: ${({ theme }) => theme.spaces.spacing8};
  left: ${({ theme }) => theme.spaces.spacing8};
`;

const OptionIconWrapper = styled.div`
  position: absolute;
  top: 2px;
  left: ${({ theme }) => theme.spaces.spacing8};
`;

const OptionWrapper = styled.div<{ hasIcon: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: ${({ theme }) => theme.spaces.spacing8};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: Manrope, serif;
  color: ${({ theme }) => theme.colors.coolGray100};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  padding: 10px ${({ theme }) => theme.spaces.spacing8};
  ${(props) => (props.hasIcon ? 'padding-left: 40px' : '')};
  :hover {
    background-color: ${({ theme }) => theme.colors.coolGray10};
  }
`;

const CustomOption: FC<any> = (props) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    optionIcon,
  } = props;
  return (
    <>
      <OptionWrapper
        hasIcon={!!optionIcon}
        ref={innerRef}
        className={cx(
          css(getStyles('option', props)),
          {
            option: true,
            'option--is-disabled': isDisabled,
            'option--is-focused': isFocused,
            'option--is-selected': isSelected,
          },
          className,
        )}
        {...innerProps}
      >
        <OptionIconWrapper>{optionIcon}</OptionIconWrapper>
        {children}
      </OptionWrapper>
    </>
  );
};

CustomOption.displayName = 'LocationOption';

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <></>
    </components.DropdownIndicator>
  );
};

export type LocationInputValue = {
  name: string;
  gps?: {
    lat: number;
    lon: number;
  };
};

export interface LocationInputProps extends TBaseProps {
  onValueChange: (value: LocationInputValue) => void;
  label?: string;
  placeholder?: string;
  defaultValue?: TSelectItem;
  error?: FieldError;
}

type TLocationSelectOption = TSelectOption<{ place_id: string }> | null;

const LocationInput: FC<LocationInputProps> = ({
  onValueChange,
  label,
  placeholder,
  defaultValue,
  error,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const onItemSelected = async (item: TLocationSelectOption) => {
    if (!item) onValueChange({ name: '' });
    else {
      const result = await geocodeByPlaceId(item.value.place_id);
      const location = {
        name: item.label,
        gps: {
          lat: result[0].geometry.location?.lat(),
          lon: result[0].geometry.location?.lng(),
        },
      };
      onValueChange(location);
    }
  };

  const apiKey =
    process.env.NX_TASK_TARGET_PROJECT === 'super-admin' ||
    process.env.NX_TASK_TARGET_PROJECT === 'super-admin-e2e'
      ? process.env.NX_GOOGLE_MAPS_SA_API_KEY
      : process.env.NX_GOOGLE_MAPS_IA_API_KEY;

  return (
    <FormItemWrapper>
      {label && <FormItemLabel>{label}</FormItemLabel>}
      <Wrapper isError={!!error}>
        <Search />
        <GooglePlacesAutocomplete
          apiOptions={{ language }}
          apiKey={apiKey || ''}
          selectProps={{
            isClearable: true,
            backspaceRemovesValue: true,
            className: 'react-select-container',
            classNamePrefix: 'react-select',
            placeholder,
            defaultValue,
            onChange: onItemSelected,
            components: {
              DropdownIndicator,
              // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
              Option: function Option(optionProps: OptionProps) {
                return <CustomOption optionIcon={<MapPin />} {...optionProps} />;
              },
            },
          }}
        />
      </Wrapper>
      {error && <FormError error={error} />}
    </FormItemWrapper>
  );
};

export { LocationInput };
