import { ErrorBoundary } from '@sentry/react';
import { ThemeProvider, ToastContainer } from '@sim-admin-frontends/ui-shared';
import { useInitAmplify } from '@sim-admin-frontends/utils-shared';
import { QueryClient, QueryClientProvider } from 'react-query';

import Routing from '../routing/Router';
import { GlobalError } from '../components/global-error/GlobalError';
import { UserContextProvider } from '../contexts/userContext';
import { PlaceContextProvider } from '../contexts/placeContext';
import useInitFacebookSdk from '../hooks/useInitFacebook';
import { HighlightableElementsProvider } from '../contexts/highlightableElementsContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const App = () => {
  useInitFacebookSdk();
  useInitAmplify();

  return (
    <ErrorBoundary fallback={<GlobalError />}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <PlaceContextProvider>
            <UserContextProvider>
              <HighlightableElementsProvider>
                <Routing />
                <ToastContainer />
              </HighlightableElementsProvider>
            </UserContextProvider>
          </PlaceContextProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
