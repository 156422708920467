import {
  CategoryType,
  QuickQuestionInput,
  State,
  TQuickQuestion,
  useAddQuickQuestionMutation,
  useCategoriesQuery,
  useInstitutionQuery,
  useQuickQuestionQuery,
  useUpdateQuickQuestionMutation,
  useUserInstitutionsQuery,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { zonedTimeToUtc } from 'date-fns-tz';
import set from 'date-fns/set';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getDefaultLangCode } from '@sim-admin-frontends/utils-shared';

import { FALLBACK_TIMEZONE } from '../../../constants/Constants';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import { useGenerateQuickQuestionActions } from '../../../hooks/actionButtons/useGenerateQuickQuestionActions';
import { TAnnouncementsTabTypes } from '../../../types/TAnnouncements';
import { TQuickQuestionsFormValues } from '../../../types/TQuickQuestions';
import { changeQuickQuestionsTab, transformOptions } from '../../../utils/quickQuestionsUtils';
import QuickQuestionEdit from './QuickQuestionEdit';

const SHARED_TOAST_ID = 'quickQuestionsToast';
const END_OF_DATE_VALUES = {
  hours: 23,
  minutes: 59,
  seconds: 59,
  milliseconds: 999,
};

type Props = {
  id?: string;
};

const QuickQuestionEditContainer: FC<Props> = ({ id }) => {
  const { user, institutionUuid } = useAuthInfo();
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const history = useHistory();
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;

  const {
    data: questionData,
    isError: isErrorQuestion,
    isLoading: isLoadingQuestion,
  } = useQuickQuestionQuery({ id: id ?? '' }, { enabled: !!id });

  const {
    data: initialInstitution,
    isLoading: isInstitutionLoading,
    isError: isInstitutionError,
    refetch: refetchInstitution,
  } = useInstitutionQuery({
    id: institutionUuid || '',
  });
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
    refetch: refetchUserData,
  } = useUserInstitutionsQuery({
    username: user?.username || '',
  });

  const {
    isLoading: areCategoriesLoading,
    error: categoriesError,
    data: categories,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.QuickQuestion,
    },
  });

  const quickQuestionCategory = useMemo(
    () =>
      categories?.categoriesBy.categories.find((category) =>
        category.categoryTypes.includes(CategoryType.QuickQuestion),
      ),
    [categories],
  );

  const { mutateAsync: addQuickQuestion } = useAddQuickQuestionMutation();
  const { mutateAsync: editQuickQuestion } = useUpdateQuickQuestionMutation();

  const refetch = () => {
    if (isUserDataError) {
      refetchUserData();
    }
    if (isInstitutionError) {
      refetchInstitution();
    }
  };

  const quickQuestion = questionData?.post as TQuickQuestion;

  const { detailActions, renderModal } = useGenerateQuickQuestionActions({
    postId: id || '',
    refetch,
    type: quickQuestion?.publishmentState || State.Published,
    isEditPage: true,
  });

  const isLoading =
    isInstitutionLoading || isUserDataLoading || areCategoriesLoading || isLoadingQuestion;
  const isError = isInstitutionError || isUserDataError || categoriesError || isErrorQuestion;

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  if (isError)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  const institutionOptions = userData?.adminUser?.institutions || [];
  const defaultPostLang = getDefaultLangCode(places?.[0].countryCode || '');

  const transformData = (formValues: TQuickQuestionsFormValues): QuickQuestionInput => {
    const transformedEndDate = set(formValues.quickQuestionEndDate, END_OF_DATE_VALUES);
    const transformedNotifications = formValues.notifications?.map((notification) => ({
      value: zonedTimeToUtc(notification.value, timezone),
    }));
    const transformedScheduledAt = formValues.scheduledAt
      ? zonedTimeToUtc(formValues.scheduledAt, timezone).toISOString()
      : undefined;
    const transformedPublishedAt = formValues.publishedAt
      ? zonedTimeToUtc(formValues.publishedAt, timezone)?.toISOString()
      : undefined;

    return {
      question: formValues.question,
      questionOptions: transformOptions(formValues.questionOptions),
      privateResults: formValues.privateResults ?? false,
      quickQuestionEndDate: transformedEndDate.toISOString(),
      scheduledAt: transformedScheduledAt,
      notifications:
        formValues.notifyNow &&
        formValues.notifications?.length &&
        formValues.notifications.length > 0
          ? transformedNotifications?.map((notification) => notification.value.toISOString())
          : null,
      notifyNow: formValues.notifyNow,
      institutionUuid: formValues.publisher?.value ?? '',
      publishedAt: formValues.scheduledAt ? undefined : transformedPublishedAt,
      lang: formValues.lang?.value || defaultPostLang,
      categoryUuids: [quickQuestionCategory?.id ?? ''],
    };
  };

  const onSubmit = async (formValues: TQuickQuestionsFormValues) => {
    try {
      loadingToast(t('updates.form.toastSubmitting'), {
        toastId: SHARED_TOAST_ID,
      });
      const transformedData = transformData(formValues);

      if (id) {
        await editQuickQuestion({
          id,
          quickQuestion: transformedData,
        });
      } else {
        await addQuickQuestion({
          quickQuestion: transformedData,
        });
      }
      updateToast(
        SHARED_TOAST_ID,
        t(id ? 'quickQuestions.form.toastEdit' : 'quickQuestions.form.toastCreate'),
        TToastType.SUCCESS,
      );
      changeQuickQuestionsTab(
        history,
        transformedData.scheduledAt
          ? TAnnouncementsTabTypes.SCHEDULED
          : TAnnouncementsTabTypes.PUBLISHED,
      );
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };

  const onDraftSubmit = () => {
    // ToDo: Implement when ready on BE
  };

  const onDiscard = () => {
    changeQuickQuestionsTab(history, TAnnouncementsTabTypes.PUBLISHED);
  };

  return (
    <>
      <QuickQuestionEdit
        quickQuestion={quickQuestion}
        submit={onSubmit}
        submitDraft={onDraftSubmit}
        onDiscard={onDiscard}
        user={user}
        institution={initialInstitution?.institution}
        userInstitutions={institutionOptions}
        qucikQuestionFormActions={id ? detailActions : []}
      />
      {renderModal()}
    </>
  );
};

export default QuickQuestionEditContainer;
