import { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { TBaseProps } from '../..';

const Wrapper = styled.div<{ type: DropdownMenuItemType }>`
  min-width: 150px;
  background-color: ${({ theme }) => theme.colors.white};

  color: ${({ theme, type }) => {
    switch (type) {
      case 'normal':
        return `${theme.colors.coolGray100}`;
      case 'destructive':
        return `${theme.colors.red60}`;
      default:
        return `${theme.colors.white}`;
    }
  }};

  display: flex;
  align-items: center;
  pointer-events: all;
  padding: ${({ theme }) => theme.spaces.spacing2} 0;
`;
const ItemsWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.spaces.spacing8} ${({ theme }) => theme.spaces.spacing12};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  align-items: center;
  height: ${({ theme }) => theme.spaces.spacing16};

  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.yellow10 : theme.colors.white};
  cursor: default;

  ${({ isActive, theme }) =>
    !isActive &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${theme.colors.coolGray10};
      }
    `}
`;

const Text = styled.div<{ hasIcon: boolean }>`
  padding-left: ${({ theme, hasIcon }) => (hasIcon ? theme.spaces.spacing8 : 0)};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const Icon = styled.span`
  line-height: ${({ theme }) => theme.lineHeights.small};
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.coolGray20};
  margin: 0 ${({ theme }) => theme.spaces.spacing12};
`;

const StyledLink = styled(NavLink)`
  all: unset;
  width: 100%;
`;

export type DropdownMenuItemType = 'normal' | 'destructive';

export interface DropdownMenuItemProps extends TBaseProps {
  label: string;
  action?: (id?: string) => void;
  type: DropdownMenuItemType;
  icon?: React.ReactElement;
  divider?: boolean;
  id?: string;
  isActive?: boolean;
  path?: string;
  hidden?: boolean;
}

const DropdownMenuItem: FC<DropdownMenuItemProps> = ({
  label,
  action,
  icon,
  type,
  divider = false,
  testId,
  id,
  isActive,
  path,
}) => {
  const onClick = (localId?: string) => () => {
    if (action) action(localId);
  };

  const renderContent = useCallback(() => {
    return (
      <ItemsWrapper isActive={!!isActive}>
        {icon && <Icon>{icon}</Icon>}
        <Text hasIcon={!!icon}>{label}</Text>
      </ItemsWrapper>
    );
  }, [isActive, label, icon]);

  return (
    <>
      {divider && <Divider />}
      <Wrapper onClick={onClick(id)} type={type} data-testid={testId}>
        {path ? <StyledLink to={path}>{renderContent()}</StyledLink> : renderContent()}
      </Wrapper>
    </>
  );
};

export { DropdownMenuItem };
