import { generatePath } from 'react-router-dom';

import ROUTES from '../../routing/routes';
import { TSettingsTabs } from '../../types/TSettings';

/* This map is used for analytics screen tracking purposes (Nicer names for screens) */

export const AnalyticsRoutes = {
  [ROUTES.login.path]: {
    name: 'Login',
  },
  [ROUTES.placeLoading.path]: {
    name: 'Place loading',
  },
  [ROUTES.home.path]: {
    name: 'Dashboard',
    subsection: 'Overview',
  },
  [ROUTES.couponsOverview.path]: {
    name: 'Coupons',
    subsection: 'Overview',
  },
  [ROUTES.couponsEdit.path]: {
    name: 'Coupons',
    subsection: 'Edit',
  },
  [ROUTES.announcementsOverview.path]: {
    name: 'Announcements',
    subsection: 'Overview',
  },
  [ROUTES.announcementsEdit.path]: {
    name: 'Announcements',
    subsection: 'Edit',
  },
  [ROUTES.eventsOverview.path]: {
    name: 'Events',
    subsection: 'Overview',
  },
  [ROUTES.eventsEdit.path]: {
    name: 'Events',
    subsection: 'Edit',
  },
  [ROUTES.emergencyOverview.path]: {
    name: 'Emergency alerts',
    subsection: 'Overview',
  },
  [ROUTES.emergencyEdit.path]: {
    name: 'Emergency alerts',
    subsection: 'Edit',
  },
  [ROUTES.insights.path]: {
    name: 'Insights',
    subsection: 'Overview',
  },
  [ROUTES.pollsOverview.path]: {
    name: 'Polls',
    subsection: 'Overview',
  },
  [ROUTES.quickQuestionsOverview.path]: {
    name: 'QuickQuestions',
    subsection: 'Overview',
  },
  [ROUTES.quickQuestionsEdit.path]: {
    name: 'QuickQuestions',
    subsection: 'Edit',
  },
  [ROUTES.pollsEdit.path]: {
    name: 'Polls',
    subsection: 'Edit',
  },
  [ROUTES.settings.path]: {
    name: 'Settings',
    subsection: 'Profile',
  },
  [ROUTES.videoTutorials.path]: {
    name: 'VideoTutorials',
    subsection: 'Overview',
  },
  [generatePath(ROUTES.settings.path, {
    type: TSettingsTabs.ABOUT_CITY,
  })]: {
    name: 'Settings',
    subsection: 'About city',
  },
  [ROUTES.petDirectoryOverview.path]: {
    name: 'PetDirectory',
    subsection: 'Overview',
  },
  [ROUTES.rafflesOverview.path]: {
    name: 'Raffles',
    subsection: 'Overview',
  },
  [ROUTES.moderator.path]: {
    name: 'Moderator',
    subsection: 'Overview',
  },
  [ROUTES.moderatorPreview.path]: {
    name: 'Moderator',
    subsection: 'Preview',
  },
  [ROUTES.chatOverview.path]: {
    name: 'Chat',
    subsection: 'Overview',
  },
  [ROUTES.chatEdit.path]: {
    name: 'Chat',
    subsection: 'Edit',
  },
  [ROUTES.postGenerator.path]: {
    name: 'PostGenerator',
  },
  [ROUTES.reportProblem.path]: {
    name: 'ReportProblem',
  },
  [ROUTES.donationsOverview.path]: {
    name: 'Donations',
  },
  [ROUTES.donationsEdit.path]: {
    name: 'Donations',
    subsection: 'Edit',
  },
  [ROUTES.marketOverview.path]: {
    name: 'Market',
  },
  [ROUTES.marketEdit.path]: {
    name: 'Market',
    subsection: 'Edit',
  },
};
