import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { NavLink } from 'react-router-dom';

import { TBaseProps } from '../../../types/TBaseComponent';

export const ACTION_ITEMS_MIN_WIDTH_SMALL = 120;
export const ACTION_ITEMS_MIN_WIDTH_LARGE = 150;
const TOOLTIP_SHOW_DELAY = 100;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
`;

const IconWrapper = styled.div`
  display: flex;
  width: ${({ theme }) => theme.spaces.spacing32};
  height: ${({ theme }) => theme.spaces.spacing32};
  justify-content: center;
  align-self: center;
  align-items: center;
  cursor: pointer;
`;

const StyledTippy = styled(Tippy)`
  &.tippy-box {
    > .tippy-content {
      padding: ${({ theme }) => theme.spaces.spacing8};
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spaces.spacing2};
  align-self: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export interface TableIconProps {
  label: string;
  testId: string;
  path?: string;
  action?: () => void;
  shown?: boolean;
  hidden?: boolean;
  hiddenInList?: boolean;
  icon?: ReactElement;
}

export interface TableMenuIconsProps extends TBaseProps {
  items: TableIconProps[];
}

const TableIcons: FC<TableMenuIconsProps> = ({ items, children, testId }) => {
  return (
    <Wrapper data-testid={testId}>
      {items
        .filter((item) => !item.hidden)
        .map((item, key) => {
          if (item.icon) {
            const ActionIconComponent = (
              <StyledTippy
                key={key}
                placement="top"
                content={item.label}
                delay={TOOLTIP_SHOW_DELAY}
              >
                <IconWrapper
                  data-testid={item.testId}
                  onClick={(e) => {
                    e.stopPropagation();
                    item.action && item.action();
                  }}
                >
                  {item.icon}
                </IconWrapper>
              </StyledTippy>
            );

            return item.path ? (
              <NavLink key={key} to={item.path}>
                {ActionIconComponent}
              </NavLink>
            ) : (
              ActionIconComponent
            );
          }
          return (
            <TextWrapper key={key} data-testid={item.testId} onClick={item.action}>
              {item.label}
            </TextWrapper>
          );
        })}
      {children}
    </Wrapper>
  );
};

export { TableIcons };
