import {
  AnnouncementsByFilterInput,
  AnnouncementsOrderByInput,
  ANNOUNCEMENTS_PAGE_SIZE,
  getAnnouncementsDraftsNextPageParam,
  getAnnouncementsNextPageParam,
  useInfiniteGraphQLQuery,
} from '..';
import { usePollDraftsQuery, usePollsQuery } from '../graphql/graphql';

export const useInfinitePollsQuery = (
  filter: AnnouncementsByFilterInput,
  orderBy?: AnnouncementsOrderByInput,
  enabled?: boolean,
) => {
  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    orderBy,
    pageOffset: Number(0 ?? pageParam),
    pageSize: ANNOUNCEMENTS_PAGE_SIZE,
    filter,
  });

  return useInfiniteGraphQLQuery(usePollsQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam: getAnnouncementsNextPageParam,
    enabled,
  });
};

export const useInfinitePollDraftsQuery = (
  filter: AnnouncementsByFilterInput,
  orderBy?: AnnouncementsOrderByInput,
) => {
  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    orderBy,
    pageOffset: Number(pageParam ?? 0),
    pageSize: ANNOUNCEMENTS_PAGE_SIZE,
    filter,
  });

  return useInfiniteGraphQLQuery(usePollDraftsQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam: getAnnouncementsDraftsNextPageParam,
  });
};
