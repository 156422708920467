import { TFunction } from 'react-i18next';
import {
  InstitutionPlaceRole,
  PlaceFeatureFlag,
  TFeatureFlagType,
} from '@sim-admin-frontends/data-access';
import {
  CalendarIcon,
  ChatIcon,
  CouponIcon,
  DashboardIcon,
  EmergencyIcon,
  HornIcon,
  ModeratorIcon,
  PetDirectoryIcon,
  PollsIcon,
  QuestionIcon,
  RaffleIcon,
  SettingsIcon,
  SimpliBotMenuIcon,
  TMenuEntries,
  DonationsIcon,
  MarketIcon,
} from '@sim-admin-frontends/ui-shared';

import ROUTES from '../../routing/routes';
import { isFeatureEnabled } from '../../utils/placeUtils';

export const createMainMenuItems = (
  t: TFunction,
  isPlaceAdmin: boolean,
  isCity: boolean,
  placeFeatureFlags: PlaceFeatureFlag[],
  institutionRole?: InstitutionPlaceRole | null,
  isChatEnabled?: boolean | null,
  visitorMode?: boolean,
): TMenuEntries => {
  const isLocalBusiness = institutionRole === InstitutionPlaceRole.LocalBusiness;
  const isReportProblemEnabled =
    isFeatureEnabled(placeFeatureFlags, TFeatureFlagType.REPORT_A_PROBLEM) &&
    isPlaceAdmin &&
    !isLocalBusiness;
  const isPetDirectoryEnabled =
    isFeatureEnabled(placeFeatureFlags, TFeatureFlagType.PET_DIRECTORY) &&
    (isPlaceAdmin || isCity) &&
    !isLocalBusiness;
  const isModeratorEnabled =
    isFeatureEnabled(placeFeatureFlags, TFeatureFlagType.MODERATOR) && (isPlaceAdmin || isCity);

  return [
    {
      label: t('mainMenu.dashboard'),
      icon: <DashboardIcon />,
      path: ROUTES.home.path,
      testId: 'mainMenu#dashboard',
    },
    {
      label: t('mainMenu.aiGenerator'),
      icon: <SimpliBotMenuIcon />,
      path: ROUTES.postGenerator.path,
      testId: 'mainMenu#postGenerator',
    },
    {
      label: t('mainMenu.announcements'),
      icon: <HornIcon />,
      path: ROUTES.announcementsOverview.path,
      testId: 'mainMenu#announcements',
    },
    {
      label: t('mainMenu.events'),
      icon: <CalendarIcon />,
      path: ROUTES.eventsOverview.path,
      testId: 'mainMenu#events',
    },
    {
      label: t('mainMenu.coupons'),
      icon: <CouponIcon />,
      path: ROUTES.couponsOverview.path,
      disabled: !isLocalBusiness,
      testId: 'mainMenu#coupons',
    },
    {
      label: t('mainMenu.emergency'),
      icon: <EmergencyIcon />,
      path: ROUTES.emergencyOverview.path,
      disabled: !isCity || visitorMode,
      disabledLabel: t('mainMenu.disabled.emergency'),
      testId: 'mainMenu#emergency',
    },
    {
      label: t('mainMenu.polls'),
      icon: <PollsIcon />,
      path: ROUTES.pollsOverview.path,
      testId: 'mainMenu#polls',
    },
    {
      label: t('mainMenu.quickQuestions'),
      icon: <QuestionIcon />,
      path: ROUTES.quickQuestionsOverview.path,
      testId: 'mainMenu#quickQuestions',
    },
    {
      label: t('mainMenu.chat'),
      icon: <ChatIcon />,
      path: ROUTES.chatOverview.path,
      disabled: !isChatEnabled || isLocalBusiness,
      testId: 'mainMenu#chat',
    },
    {
      label: t('mainMenu.raffles'),
      icon: <RaffleIcon />,
      path: ROUTES.rafflesOverview.path,
      disabled: isLocalBusiness || visitorMode,
      testId: 'mainMenu#raffles',
    },
    {
      label: t('mainMenu.donations'),
      icon: <DonationsIcon />,
      path: ROUTES.donationsOverview.path,
      disabled: isLocalBusiness,
      testId: 'mainMenu#donations',
    },
    {
      label: t('mainMenu.market'),
      icon: <MarketIcon />,
      path: ROUTES.marketOverview.path,
      disabled: !visitorMode,
      testId: 'mainMenu#market',
    },
    // {
    //   label: t('mainMenu.insights'),
    //   icon: <ChartIcon />,
    //   path: ROUTES.insights.path,
    //   testId: 'mainMenu#insights',
    // },
    {
      label: t('mainMenu.petDirectory'),
      icon: <PetDirectoryIcon />,
      path: ROUTES.petDirectoryOverview.path,
      disabled: !isPetDirectoryEnabled,
      testId: 'mainMenu#pets',
    },
    {
      label: t('mainMenu.reportProblem'),
      icon: <ModeratorIcon />,
      path: ROUTES.reportProblem.path,
      disabled: !isReportProblemEnabled,
      testId: 'mainMenu#reportProblem',
    },
    {
      label: t('mainMenu.moderator'),
      icon: <ModeratorIcon />,
      path: ROUTES.moderator.path,
      disabled: !isModeratorEnabled,
      testId: 'mainMenu#moderator',
    },
    {
      label: t('mainMenu.settings'),
      icon: <SettingsIcon />,
      path: ROUTES.settings.path,
      testId: 'mainMenu#settings',
    },
  ];
};
