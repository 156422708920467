import styled, { css } from 'styled-components';
import { ButtonHTMLAttributes, FC, ReactElement } from 'react';
import Tippy from '@tippyjs/react';

import { TBaseProps, Spinner } from '../..';
import { TButtonSize, TButtonVariant } from '../../types/TButton';
import { TTheme } from '../themeProvider/theme';

const variantStyles = (theme: TTheme, variant: TButtonVariant, disabled: boolean) =>
  ({
    primary: css`
      color: ${disabled ? theme.colors.white : theme.colors.coolGray100};
      background-color: ${disabled ? theme.colors.coolGray20 : theme.colors.yellow50};
    `,
    secondary: css`
      color: ${disabled ? theme.colors.white : theme.colors.coolGray100};
      background-color: ${disabled ? theme.colors.coolGray20 : theme.colors.coolGray10};
    `,
    tertiary: css`
      color: ${theme.colors.coolGray100};
      background-color: ${theme.colors.transparent};
    `,
    danger: css`
      color: ${theme.colors.red60};
      background-color: ${theme.colors.coolGray10};
    `,
    bordered: css`
      color: ${disabled ? theme.colors.coolGray20 : theme.colors.coolGray100};
      background-color: ${theme.colors.transparent};
      border: 1px solid ${theme.colors.coolGray20};
    `,
    dark: css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.coolGray100};
      border: 1px solid ${theme.colors.coolGray100};
    `,
  }[variant]);

const Wrapper = styled.button<{
  variant: TButtonVariant;
  size: TButtonSize;
  disabled: boolean;
  noPadding?: boolean;
}>`
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-family: ${({ theme }) => theme.fonts.primary};
  padding: ${({ theme, size }) => {
    switch (size) {
      case 'smallest':
        return `${theme.spaces.spacing2} ${theme.spaces.spacing8}`;
      case 'smaller':
        return `${theme.spaces.spacing8} ${theme.spaces.spacing16}`;
      case 'normal':
        return `10px ${theme.spaces.spacing16}`;
      case 'larger':
        return `${theme.spaces.spacing16} ${theme.spaces.spacing16}`;
      default:
        return `10px ${theme.spaces.spacing16}`;
    }
  }};
  ${({ noPadding }) => (noPadding ? 'padding: 0;' : '')};
  user-select: none;
  outline: none;
  border: none;
  ${({ theme, variant, disabled }) => variantStyles(theme, variant, disabled)}

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = styled(Spinner)`
  fill: ${({ theme }) => theme.colors.white};
`;

const AppendIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${({ theme }) => theme.spaces.spacing12};
`;

export interface ButtonProps extends TBaseProps, ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: TButtonVariant;
  size?: TButtonSize;
  isLoading?: boolean;
  prependIcon?: ReactElement;
  appendIcon?: ReactElement;
  noPadding?: boolean;
  disabledText?: string;
}

const Button: FC<ButtonProps> = ({
  children,
  onClick,
  disabled = false,
  variant = 'primary',
  size = 'normal',
  isLoading = false,
  testId,
  prependIcon,
  appendIcon,
  noPadding,
  disabledText,
  ...props
}) => (
  <Wrapper
    {...props}
    onClick={onClick}
    variant={variant}
    size={size}
    disabled={isLoading || disabled}
    data-testid={testId}
    noPadding={noPadding}
  >
    <Tippy disabled={!disabledText} content={disabledText}>
      {isLoading ? (
        <LoadingWrapper>
          <Loading size={16} />
        </LoadingWrapper>
      ) : (
        <InnerWrapper>
          {prependIcon}
          {children}
          {appendIcon && <AppendIconWrapper>{appendIcon}</AppendIconWrapper>}
        </InnerWrapper>
      )}
    </Tippy>
  </Wrapper>
);

export { Button };
