import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useDashboardTitle = (name: string) => {
  const [randomI, setRandomI] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setRandomI(Math.floor(Math.random() * 5 + 1));
  }, []);

  const translate = (index: number): string => {
    const map: { [key: number]: string } = {
      1: t('dashboard.pageTitle.title1', { name: name }),
      2: t('dashboard.pageTitle.title2', { name: name }),
      3: t('dashboard.pageTitle.title3', { name: name }),
      4: t('dashboard.pageTitle.title4', { name: name }),
      5: t('dashboard.pageTitle.title5', { name: name }),
      6: t('dashboard.pageTitle.title6', { name: name }),
    };

    return map[index];
  };

  return translate(randomI);
};
