import { FC } from 'react';
import { SortingRule } from 'react-table';
import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import {
  TAnnouncementDraftList,
  TAnnouncementDraftListItem,
  TAnnouncementList,
  TAnnouncementListItem,
} from '@sim-admin-frontends/data-access';

export interface AnnouncementsViewProps {
  data: TAnnouncementList | TAnnouncementDraftList;
  columns: TExtendedColumn<TAnnouncementListItem | TAnnouncementDraftListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  onSortingChanged: (
    value: SortingRule<TAnnouncementListItem | TAnnouncementDraftListItem>[],
  ) => void;
  initialTableSorting: SortingRule<TAnnouncementListItem | TAnnouncementDraftListItem>[];
  hasNextPage?: boolean;
}

const RafflesView: FC<AnnouncementsViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  onSortingChanged,
  initialTableSorting,
  hasNextPage,
}) => (
  <Table
    hasNextPage={hasNextPage}
    loading={loading}
    itemsCount={itemsCount}
    pageIndex={pageIndex}
    data={data}
    columns={columns}
    hasPagination={true}
    onNextPress={onNextPress}
    onPreviousPress={onPreviousPress}
    onSortingChanged={onSortingChanged}
    initialSortBy={initialTableSorting}
    testId="RafflesView#Table"
  />
);

export default RafflesView;
