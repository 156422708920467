import { FC } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import MarketEditContainer from '../../components/market/edit/MarketEdit.container';
import { useAuthInfo } from '../../contexts/userContext';

export type MarketEditPageProps = {
  id?: string;
};

const MarketEditPage: FC<RouteComponentProps<MarketEditPageProps>> = ({ match }) => {
  const { visitorMode } = useAuthInfo();

  if (!visitorMode) {
    return <Redirect to="/404" />;
  }

  return <MarketEditContainer id={match?.params?.id} />;
};

export default MarketEditPage;
