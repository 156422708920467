import { useMemo, useState } from 'react';
import {
  TCustomDataInterval,
  useAdminDashboardAnalyticsQuery,
} from '@sim-admin-frontends/data-access';
import { utcToZonedTime } from 'date-fns-tz';
import { formatDateObject, getNowUtc } from '@sim-admin-frontends/utils-shared';
import { differenceInDays } from 'date-fns';

import { TChartDataMetrics, TChartDataTimeSpan } from '../types/TDashboard';
import { usePlaceInfo } from '../contexts/placeContext';
import { FALLBACK_TIMEZONE } from '../constants/Constants';
import { useAuthInfo } from '../contexts/userContext';
import { parseAnalyticsStringData } from '../utils/dashboardUtils';

const CSV_FILENAME = 'CSV_Data_Export';

const useDashboardAnalyticsChart = () => {
  const { places } = usePlaceInfo();
  const placeUuid = places?.[0].id || '';
  const timezone = places?.[0]?.timezoneCode || FALLBACK_TIMEZONE;
  const { institutionUuid } = useAuthInfo();

  const [enabledMetrics, setEnabledMetrics] = useState({
    followers: true,
    subscribers: true,
  });

  const [selectedTimeSpan, setSelectedTimeSpan] = useState(TChartDataTimeSpan.QUARTER);
  const [customInterval, setCustomInterval] = useState<TCustomDataInterval>({
    startDate: new Date(getNowUtc()),
    endDate: new Date(getNowUtc()),
  });

  const onLegendButtonClick = (button: TChartDataMetrics) => () => {
    switch (button) {
      case TChartDataMetrics.FOLLOWERS:
        setEnabledMetrics({ ...enabledMetrics, followers: !enabledMetrics.followers });
        return;
      case TChartDataMetrics.SUBSCRIBERS:
        setEnabledMetrics({ ...enabledMetrics, subscribers: !enabledMetrics.subscribers });
        return;
    }
  };

  const filter = {
    institutionUuid,
    placeUuid,
  };

  const {
    data: receivedData,
    isLoading,
    isError,
    refetch,
    error,
  } = useAdminDashboardAnalyticsQuery({
    filter,
  });

  const analyticsData = receivedData?.adminDashboardAnalytics;

  const chartData = useMemo(() => {
    const followersData = parseAnalyticsStringData(analyticsData?.followers);
    const subscribersData = parseAnalyticsStringData(analyticsData?.subscribers);
    const reachData = parseAnalyticsStringData(analyticsData?.reach);
    const dateNow = new Date();
    const filterInterval: TCustomDataInterval = {
      startDate: new Date(utcToZonedTime(customInterval.startDate, timezone).setHours(0, 0, 0)),
      endDate: new Date(utcToZonedTime(customInterval.endDate, timezone).setHours(23, 59, 59)),
    };

    return Array.from({ length: followersData?.length || 0 }, (_, i) => ({
      dateTime: new Date(followersData?.[i].dateTime || ''),
      name: formatDateObject(new Date(followersData?.[i].dateTime || ''), 'LLLL dd yyyy'),
      followers: followersData?.[i]?.value || 0,
      subscribers: subscribersData?.[i]?.value || 0,
      reach: reachData?.[i]?.value || 0,
    }))
      .filter((entry) =>
        selectedTimeSpan === TChartDataTimeSpan.CUSTOM
          ? entry.dateTime >= filterInterval.startDate && entry.dateTime <= filterInterval.endDate
          : differenceInDays(dateNow, entry.dateTime) < selectedTimeSpan,
      )
      .sort((a, b) => (a.dateTime < b.dateTime ? -1 : 1));
  }, [analyticsData, customInterval, selectedTimeSpan]);

  const csvData = useMemo(
    () =>
      chartData.length
        ? chartData?.map((item) => ({
            dateTime: formatDateObject(item.dateTime, 'yyyy LLLL dd'),
            followers: item.followers,
            subscribers: item.subscribers,
            reach: item.reach,
          }))
        : [],
    [chartData],
  );

  const csvReport = {
    data: csvData || [],
    filename: csvData.length
      ? `${CSV_FILENAME}_${csvData?.[0].dateTime} - ${csvData?.[csvData?.length - 1].dateTime}.csv`
      : CSV_FILENAME,
  };

  return {
    chartData,
    isLoading,
    isError,
    error,
    refetch,
    selectedTimeSpan,
    setSelectedTimeSpan,
    customInterval,
    setCustomInterval,
    enabledMetrics,
    onLegendButtonClick,
    csvReport,
  };
};

export default useDashboardAnalyticsChart;
