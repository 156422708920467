import { DEVELOPMENT_ENVIRONMENT } from './constants';

export const IS_MAC = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

export const getEnvironment = () => process.env.NX_ENVIRONMENT || DEVELOPMENT_ENVIRONMENT;

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const findGenericOption = <T>(
  options: {
    label: string;
    value: T;
  }[],
  label?: T,
) => options.find((type) => type.value === label) ?? options[0];

export const UA_COUNTRY_CODE = 'UA';
export const SK_COUNTRY_CODE = 'SK';
export const EN_COUNTRY_CODE = 'EN';
export const US_COUNTRY_CODE = 'US';
