import { EventLocationInput, ImageInput, VideoInput } from '@sim-admin-frontends/data-access';
import { TPrice, TSelectItem, TSelectItems } from '@sim-admin-frontends/ui-shared';

export enum TEventType {
  Online = 'Online',
  Live = 'Live',
}

export enum TEventsTabTypes {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}

export enum EventModalActions {
  DELETE = 'DELETE',
  UNPUBLISH = 'UNPUBLISH',
  PUBLISH = 'PUBLISH',
  NOTIFY = 'NOTIFY',
}

export type TEventsFormValues = {
  title: string;
  startDate?: Date | null;
  eventType?: string;
  lang?: TSelectItem;
  endDate?: Date | null;
  content: string;
  images?: (File | ImageInput)[] | null;
  videos?: (File | VideoInput)[] | null;
  location?: EventLocationInput | null;
  publisher?: TSelectItem;
  places?: TSelectItems;
  publishedAt?: Date | null;
  categories?: TSelectItem[];
  marketUrl?: string | null;
  marketItemPricing?: TPrice | null;
};
