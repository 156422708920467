import { useState } from 'react';
import FsLightbox from 'fslightbox-react';

import SquareImageWithTexts from '../../previewItem/image/SquareImageWithTexts';
import couponOverlay from '../../../assets/img/couponOverlay.png';

type TableImageCellProps = {
  image?: string;
  thumbnail?: string;
  title: string;
  subtitle: string;
  alt: string;
  height: number;
};

const CouponTableImageCell = ({
  image,
  thumbnail,
  title,
  subtitle,
  height,
}: TableImageCellProps) => {
  const [toggler, setToggler] = useState(false);

  const imageOnClick = () => setToggler(!toggler);

  return (
    <>
      <SquareImageWithTexts
        onClick={imageOnClick}
        height={height}
        image={thumbnail}
        title={title}
        subtitle={subtitle}
        overlay={couponOverlay}
      />
      {image && (
        <FsLightbox
          toggler={toggler}
          sources={[image]}
          types={['image']}
          disableLocalStorage
          loadOnlyCurrentSource
        />
      )}
    </>
  );
};

export default CouponTableImageCell;
