import { Controller, FieldValues } from 'react-hook-form';

import { PriceInput, PriceInputProps } from '../../price-input/PriceInput';
import { TFormControlElement } from '../../../types/TForm';
import { Wrapper } from '../form-datetime-picker/FormDateTimePicker';
import { FormError } from '../form-error/FormError';

type Props<T> = Omit<PriceInputProps, 'onChange'> &
  TFormControlElement<T> & {
    className?: string;
    noError?: boolean;
    noMargin?: boolean;
  };

const FormPrice = <T extends FieldValues>({
  control,
  name,
  initialValue,
  className,
  noError,
  noMargin,
  testId,
  ...rest
}: Props<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <Wrapper className={className} noMargin={noMargin}>
        <PriceInput
          value={value}
          onChange={onChange}
          initialValue={initialValue}
          testId={testId}
          {...rest}
        />
        {!noError && !!error && <FormError error={error} testId={`${testId}-error`} />}
      </Wrapper>
    )}
  />
);

export { FormPrice };
