import {
  ActiveIcon,
  ApproveActionIcon,
  ErrorIcon,
  DenyActionIcon,
  TableIconProps,
  TableIcons,
  TBaseProps,
  WarnActionIcon,
  WarningIcon,
} from '@sim-admin-frontends/ui-shared';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HarmfulContentReason, ModerationCategories } from '@sim-admin-frontends/data-access';

import { ModeratorModal } from '../modal/ModeratorModal';

export interface ModeratorTableMenuProps extends TBaseProps {
  postId: string;
  postModeration: ModerationCategories | null | undefined;
  refetch: () => void;
}

const ModeratorTableMenu: FC<ModeratorTableMenuProps> = ({ postModeration, testId }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const addModerationFeedback = () => {
    //TODO call addModerationFeedback mutation
  };

  const handleNegativeReason = () => {
    setIsModalVisible(true);
  };

  // eslint-disable-next-line
  const handleConfirmClick = (reason: HarmfulContentReason, reactionText?: string) => {
    setIsModalVisible(false);
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const generateItems = (): TableIconProps[] => [
    {
      label: t('table.dropdown.approve'),
      action: addModerationFeedback,
      testId: 'ModeratorTableMenu#approve',
      icon:
        postModeration === ModerationCategories.Positive ? <ActiveIcon /> : <ApproveActionIcon />,
    },
    {
      label: t('table.dropdown.warn'),
      action: addModerationFeedback,
      testId: 'ModeratorTableMenu#warn',
      icon:
        postModeration === ModerationCategories.EdgePositive ? <WarningIcon /> : <WarnActionIcon />,
    },
    {
      label: t('table.dropdown.deny'),
      action: handleNegativeReason,
      testId: 'ModeratorTableMenu#deny',
      icon: postModeration === ModerationCategories.Negative ? <ErrorIcon /> : <DenyActionIcon />,
    },
  ];

  const items = generateItems();

  return (
    <TableIcons testId={testId} items={items}>
      <ModeratorModal
        isOpen={isModalVisible}
        onConfirmClick={handleConfirmClick}
        onBackClick={handleCancelClick}
      />
    </TableIcons>
  );
};

export default ModeratorTableMenu;
