export const HEADER_HEIGHT_PX = 64;
export const SIDER_WIDTH_PX = 256;
export const SIDER_COLLAPSED_WIDTH_PX = 70;
export const FALLBACK_TIMEZONE = 'America/Chicago';
export const SIDEPANEL_COLLAPSED_WIDTH_PX = 51;
export const SIDEPANEL_EXPANDED_WIDTH_PX = 340;
export const SIDEPANEL_DEFAULT_SIDE = 'right';
export const ANNOUNCEMENT_TOAST_SHARED_ID = 'submitAnnouncement';
export const REPORT_A_PROBLEM_TOAST_SHARED_ID = 'submitReportAProblem';
export const COUPON_TOAST_SHARED_ID = 'submitCoupon';
export const POST_GENERATOR_TOAST_SHARED_ID = 'postGeneratorToast';
export const DEFAULT_LANGUAGE = 'en';
export const FALLBACK_INSTITUTION_NAME = 'Unknown';
export const ALL_INSTITUTIONS_PAGE_SIZE = 100;
export const CREATE_FORM_URL = 'https://docs.google.com/forms/u/0/';
export const START_DAY_TIME_12H = '12:00';
export const END_DAY_TIME_12H = '11:45';
export const AM = 'AM';
export const PM = 'PM';
export const START_DAY_TIME_24H = '00:01';
export const END_DAY_TIME_24H = '23:59';
export const DEFAULT_TEXT_LENGTH = 40;
export const NEWS_CATEGORY_NAME = 'NEWS';

export const OVERPASS_API_URL = 'https://overpass-api.de/api/interpreter?data=[out:json];';
export const OPENSTREETMAP_LOOKUP_URL =
  'https://nominatim.openstreetmap.org/lookup?format=geojson&osm_ids=';
export const TESTING_LOCATION_ORIGIN = 'https://testing-admin.onesimplicity.com';
