import {
  QuickQuestionsByFilterInput,
  QuickQuestionsByQuery,
  QuickQuestionsOrderByInput,
  useInfiniteGraphQLQuery,
  useQuickQuestionsByQuery,
} from '..';

export const useInfiniteQuickQuestionsQuery = (
  filter: QuickQuestionsByFilterInput,
  orderBy?: QuickQuestionsOrderByInput,
  enabled?: boolean,
) => {
  const getNextPageParam = (lastPage: QuickQuestionsByQuery) => lastPage.quickQuestionsBy.nextToken;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    orderBy,
    nextToken: pageParam,
    filter,
  });

  return useInfiniteGraphQLQuery(useQuickQuestionsByQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
    enabled,
  });
};
