import {
  BellIcon,
  ConfirmModal,
  EditIcon,
  EyeIcon,
  EyeSlashIcon,
  loadingToast,
  RemoveCircleIcon,
  TableIconProps,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import {
  State,
  useCancelScheduledAnnouncementMutation,
  useDeleteAnnouncementMutation,
  usePublishAnnouncementMutation,
  useSendPostNotificationMutation,
  useUnpublishAnnouncementMutation,
} from '@sim-admin-frontends/data-access';
import { generatePath, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { History, LocationState } from 'history';

import { logAnnouncementEvent } from '../../utils/analytics/analyticsUtils';
import { TItemAction } from '../../types/TAnalytics';
import { AnnouncemetModalActions, TAnnouncementsTabTypes } from '../../types/TAnnouncements';
import { useAuthInfo } from '../../contexts/userContext';

type TActionsSettings = {
  translationKey: string;
  testIdKey: string;
  editPagePath: string;
  changeTab: (history: History<LocationState>, type: TAnnouncementsTabTypes) => void;
};

type TAnnoucementActions = {
  isFromAdmin?: boolean | null;
  type: State;
  published: boolean;
  postId: string;
  refetch: () => void;
  isEditPage?: boolean;
  settings: TActionsSettings;
};

export const useGenerateAnnoucementActions = ({
  isFromAdmin,
  type,
  published,
  postId,
  isEditPage,
  refetch,
  settings,
}: TAnnoucementActions) => {
  const { translationKey, testIdKey, editPagePath, changeTab } = settings;
  const { institutionUuid } = useAuthInfo();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(AnnouncemetModalActions.UNPUBLISH);
  const { t } = useTranslation();
  const editPath = generatePath(editPagePath, postId ? { id: postId } : undefined);
  const history = useHistory();

  const { mutate: deleteMutation } = useDeleteAnnouncementMutation({
    onMutate: () => {
      loadingToast(t('updates.toast.delete.loading'), {
        toastId: AnnouncemetModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(
        AnnouncemetModalActions.DELETE,
        t(`${translationKey}.toast.delete.error`),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logAnnouncementEvent(postId, institutionUuid, TItemAction.DELETE, false);
      updateToast(
        AnnouncemetModalActions.DELETE,
        t(`${translationKey}.toast.delete.success`),
        TToastType.SUCCESS,
      );
      changeTab(history, TAnnouncementsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const { mutate: unpublishMutation } = useUnpublishAnnouncementMutation({
    onMutate: () => {
      loadingToast(t(`${translationKey}.toast.unpublish.loading`), {
        toastId: AnnouncemetModalActions.UNPUBLISH,
      });
    },
    onError: () => {
      updateToast(
        AnnouncemetModalActions.UNPUBLISH,
        t(`${translationKey}.toast.unpublish.error`),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      updateToast(
        AnnouncemetModalActions.UNPUBLISH,
        t(`${translationKey}.toast.unpublish.success`),
        TToastType.SUCCESS,
      );
      logAnnouncementEvent(postId, institutionUuid, TItemAction.UNPUBLISH, false);
      changeTab(history, TAnnouncementsTabTypes.UNPUBLISHED);
      refetch();
    },
  });

  const { mutate: publishMutation } = usePublishAnnouncementMutation({
    onMutate: () => {
      loadingToast(t(`${translationKey}.toast.publish.loading`), {
        toastId: AnnouncemetModalActions.PUBLISH,
      });
    },
    onError: () => {
      updateToast(
        AnnouncemetModalActions.PUBLISH,
        t(`${translationKey}.toast.publish.error`),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logAnnouncementEvent(postId, institutionUuid, TItemAction.PUBLISH);
      updateToast(
        AnnouncemetModalActions.PUBLISH,
        t(`${translationKey}.toast.publish.success`),
        TToastType.SUCCESS,
      );
      changeTab(history, TAnnouncementsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const { mutate: sendPostNotification } = useSendPostNotificationMutation({
    onMutate: () => {
      loadingToast(t('updates.toast.notification.loading'), {
        toastId: AnnouncemetModalActions.NOTIFY,
      });
    },
    onError: () => {
      updateToast(
        AnnouncemetModalActions.NOTIFY,
        t('updates.toast.notification.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logAnnouncementEvent(postId, institutionUuid, TItemAction.NOTIFY);
      updateToast(
        AnnouncemetModalActions.NOTIFY,
        t('updates.toast.notification.success'),
        TToastType.SUCCESS,
      );
    },
  });

  const deleteArticle = async () => {
    await deleteMutation({
      id: postId,
    });
  };

  const unpublishArticle = async () => {
    await unpublishMutation({
      id: postId,
    });
  };

  const publishArticle = async () => {
    await publishMutation({
      id: postId,
    });
  };

  const sendNotification = async () => {
    await sendPostNotification({
      postUuid: postId,
    });
  };

  const { mutate: cancelScheduled } = useCancelScheduledAnnouncementMutation({
    onMutate: () => {
      loadingToast(t('updates.toast.cancelSend.loading'), {
        toastId: AnnouncemetModalActions.CANCEL_SEND,
      });
    },
    onError: () => {
      updateToast(
        AnnouncemetModalActions.CANCEL_SEND,
        t('updates.toast.cancelSend.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logAnnouncementEvent(postId, institutionUuid, TItemAction.CANCEL_SEND);
      updateToast(
        AnnouncemetModalActions.CANCEL_SEND,
        t(`${translationKey}.toast.cancelSend.success`),
        TToastType.SUCCESS,
      );
      refetch();
    },
  });

  const handleDeleteClick = () => {
    setModalDescription(t(`${translationKey}.modal.deleteContent`));
    setModalClickAction(AnnouncemetModalActions.DELETE);
    setIsModalVisible(true);
  };

  const handlePublishClick = () => {
    setModalDescription(t(`${translationKey}.modal.publishContent`));
    setModalClickAction(AnnouncemetModalActions.PUBLISH);
    setIsModalVisible(true);
  };

  const handleCancelSendClick = () => {
    cancelScheduled({
      id: postId,
    });
  };

  const handleUnpublishClick = () => {
    setModalDescription(t(`${translationKey}.modal.unpublishContent`));
    setModalClickAction(AnnouncemetModalActions.UNPUBLISH);
    setIsModalVisible(true);
  };

  const handleNotifyClick = () => {
    setModalDescription(t('updates.modal.sendNotification'));
    setModalClickAction(AnnouncemetModalActions.NOTIFY);
    setIsModalVisible(true);
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const handleConfirmClick = () => {
    if (modalClickAction === AnnouncemetModalActions.DELETE) {
      deleteArticle();
      setIsModalVisible(false);
    }
    if (modalClickAction === AnnouncemetModalActions.UNPUBLISH) {
      unpublishArticle();
      setIsModalVisible(false);
    }
    if (modalClickAction === AnnouncemetModalActions.PUBLISH) {
      publishArticle();
      setIsModalVisible(false);
    }
    if (modalClickAction === AnnouncemetModalActions.NOTIFY) {
      sendNotification();
      setIsModalVisible(false);
    }
  };

  const generateItems = (): TableIconProps[] => {
    if (!isFromAdmin) {
      return [
        {
          label: t('table.dropdown.notify'),
          action: handleNotifyClick,
          testId: `${testIdKey}TableMenu#notify`,
          icon: <BellIcon />,
        },
        {
          label: published ? t('table.dropdown.unpublish') : t('table.dropdown.publish'),
          action: published ? handleUnpublishClick : handlePublishClick,
          testId: published ? `${testIdKey}TableMenu#unpublish` : `${testIdKey}TableMenu#publish`,
          icon: published ? <EyeSlashIcon /> : <EyeIcon />,
        },
        {
          label: t('table.dropdown.categoryEdit'),
          path: editPath,
          hidden: isEditPage,
          testId: `${testIdKey}TableMenu#categoryEdit`,
          icon: <EditIcon />,
        },
      ];
    }
    switch (type) {
      case State.Draft:
        return [
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            hidden: isEditPage,
            testId: `${testIdKey}TableMenu#edit`,
            icon: <EditIcon />,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: `${testIdKey}TableMenu#delete`,
            icon: <TrashCanIcon />,
          },
        ];

      case State.Published:
        return [
          {
            label: t('table.dropdown.notify'),
            action: handleNotifyClick,
            testId: `${testIdKey}TableMenu#notify`,
            icon: <BellIcon />,
          },
          {
            label: t('table.dropdown.unpublish'),
            action: handleUnpublishClick,
            testId: `${testIdKey}TableMenu#unpublish`,
            icon: <EyeSlashIcon />,
          },
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            hidden: isEditPage,
            testId: `${testIdKey}TableMenu#edit`,
            icon: <EditIcon />,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: `${testIdKey}TableMenu#delete`,
            icon: <TrashCanIcon />,
          },
        ];

      case State.Scheduled:
        return [
          {
            label: t('table.dropdown.publish'),
            action: handlePublishClick,
            testId: `${testIdKey}TableMenu#publish`,
            icon: <EyeIcon />,
          },
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            hidden: isEditPage,
            testId: `${testIdKey}TableMenu#edit`,
            icon: <EditIcon />,
          },
          {
            label: t('table.dropdown.cancelSend'),
            action: handleCancelSendClick,
            testId: `${testIdKey}TableMenu#cancelSend`,
            icon: <RemoveCircleIcon />,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: `${testIdKey}TableMenu#delete`,
            icon: <TrashCanIcon />,
          },
        ];

      case State.Unpublished:
        return [
          {
            label: t('table.dropdown.publish'),
            action: handlePublishClick,
            testId: `${testIdKey}TableMenu#publish`,
            icon: <EyeIcon />,
          },
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            hidden: isEditPage,
            testId: `${testIdKey}TableMenu#edit`,
            icon: <EditIcon />,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: `${testIdKey}TableMenu#delete`,
            icon: <TrashCanIcon />,
          },
        ];
      default:
        return [];
    }
  };

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  const renderModal = () => (
    <ConfirmModal
      title={t('updates.modal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('updates.modal.backButton')}
      confirmButtonText={t('updates.modal.confirmButton')}
      testId={`${testIdKey}#ConfirmModal`}
    />
  );

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
