import { MessagingCategory, MessagingProblem } from '@sim-admin-frontends/data-access-admin-be';
import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { TReportProblemListItem } from '../../../types/TProblems';

export interface ReportProblemViewProps {
  data: MessagingProblem[];
  columns: TExtendedColumn<TReportProblemListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  hasNextPage?: boolean;
  categories?: MessagingCategory[];
}

const ReportProblemView: FC<ReportProblemViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,

  hasNextPage,
}) => (
  <Table
    loading={loading}
    itemsCount={itemsCount}
    pageIndex={pageIndex}
    data={data}
    columns={columns}
    hasPagination={true}
    hasNextPage={hasNextPage}
    onNextPress={onNextPress}
    onPreviousPress={onPreviousPress}
    testId="ReportProblemView#Table"
  />
);

export default ReportProblemView;
