import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import {
  Button,
  Caption1,
  FormButtonsWrapper,
  FormInput,
  FormItemWrapper,
  MediumText,
  PlusIcon,
} from '@sim-admin-frontends/ui-shared';

import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import { TMessagingCategoriesMasterEmailFormValues } from '../../../types/TMessagingCategories';

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing32};
`;

const HeadingAddCategory = styled(MediumText)`
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  line-height: ${({ theme }) => theme.lineHeights.large};
`;

const TextAddCategory = styled(Caption1)`
  padding-top: ${({ theme }) => theme.spaces.spacing8};
`;

const ButtonAdd = styled(Button)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  padding: 0;
  margin-left: ${({ theme }) => theme.spaces.spacing8};
`;

const ButtonAddText = styled(Caption1)`
  margin-left: ${({ theme }) => theme.spaces.spacing8};
`;

const StyledFormButtonWrapper = styled(FormButtonsWrapper)`
  margin-top: ${({ theme }) => theme.spaces.spacing32};
`;

const schema = (t: TFunction) =>
  Yup.object().shape({
    reportsEmail: Yup.string().email(t('common.validation.email')).max(255),
  });

type Props = {
  onSubmit: (values: TMessagingCategoriesMasterEmailFormValues) => Promise<void>;
  onDiscard: () => void;
  onAddCategoryClick: () => void;
  reportsEmail?: string | null;
};

const SettingsMessagesCategoriesMasterEmail: FC<Props> = ({
  onDiscard,
  onSubmit,
  onAddCategoryClick,
  reportsEmail,
}) => {
  const { t } = useTranslation();
  const initialValues = {
    reportsEmail: reportsEmail || '',
  };

  const methods = useForm<TMessagingCategoriesMasterEmailFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });
  const { handleSubmit, register, formState, reset } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const submit = async (values: TMessagingCategoriesMasterEmailFormValues) => {
    await onSubmit(values);
    reset(values);
  };

  const onSubmitClick = () => {
    handleSubmit(submit)();
  };

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <FormItemWrapper>
          <HeadingAddCategory>
            {t('settings.messaging.categories.master.heading')}
          </HeadingAddCategory>
          <TextAddCategory>{t('settings.messaging.categories.master.text')}</TextAddCategory>
        </FormItemWrapper>
        <FormInput
          testId="Settings#Messages#email"
          {...register('reportsEmail')}
          error={errors.reportsEmail}
        />
        <FormItemWrapper>
          <ButtonAdd
            testId="Settings#Messages#addCategory"
            variant="tertiary"
            onClick={onAddCategoryClick}
          >
            <PlusIcon />
            <ButtonAddText>{t('settings.messaging.categories.createNew')}</ButtonAddText>
          </ButtonAdd>
        </FormItemWrapper>
        <StyledFormButtonWrapper>
          <Button
            size="smaller"
            variant="tertiary"
            onClick={onDiscard}
            disabled={isSubmitting}
            testId="Settings#Messages#cancel"
          >
            {t('common.cancel')}
          </Button>
          <Button
            size="smaller"
            type="submit"
            onClick={onSubmitClick}
            isLoading={isSubmitting}
            disabled={isSubmitting || !isEmpty(errors) || !isDirty}
            testId="Settings#Messages#save"
          >
            {t('common.save')}
          </Button>
        </StyledFormButtonWrapper>
      </FormProvider>
      <RouteLeavingGuard when={isDirty && !isSubmitting} />
    </Wrapper>
  );
};

export default SettingsMessagesCategoriesMasterEmail;
