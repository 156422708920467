import { DonationQuery, DonationsQuery } from '@sim-admin-frontends/data-access';
import { TExtendedColumn, TSelectItem } from '@sim-admin-frontends/ui-shared';

import { TAnnouncementFormValues } from './TAnnouncements';

export interface TDonationFormValues extends TAnnouncementFormValues {
  donationCurrency: TSelectItem;
  donationTargetAmount: string;
}

export type TScrapedAnnouncementFormValues = {
  categories?: TSelectItem[];
};

export type TDonationListItem = Extract<
  DonationsQuery['announcementsBy']['announcements'][number],
  { __typename: 'Announcement' }
>;

export type TDonationsColumns = TExtendedColumn<TDonationListItem>[];

export type TDonationDetail = Extract<
  DonationQuery['announcement'],
  { __typename?: 'Announcement' }
>;

export const isDonation = (donation?: DonationQuery['announcement']): donation is TDonationDetail =>
  donation?.__typename === 'Announcement' && !!donation.donationTargetAmount;
