import { Controller, FieldValues } from 'react-hook-form';
import { ImageFile } from '@sim-admin-frontends/utils-shared';

import { FormItemLabel, FormItemWrapper, BottomCaption } from '../formStyles';
import { FormError } from '../form-error/FormError';
import { Upload, UploadProps } from '../upload/Upload';
import { TFormControlElement } from '../../../types/TForm';
import { TUploadSize } from '../../../types/TUpload';

export interface FormUploadSecondaryProps<T> extends UploadProps, TFormControlElement<T> {
  label?: string;
  bottomCaption?: string;
}

const FormUploadSecondary = <T extends FieldValues>({
  control,
  name,
  onChange,
  testId,
  label,
  bottomCaption,
  ...rest
}: FormUploadSecondaryProps<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange: onChangeField, value }, fieldState: { error } }) => {
      const onChangeMerged = (file?: (File | ImageFile)[]) => {
        onChangeField(file);
        if (onChange) onChange(file);
      };
      return (
        <FormItemWrapper>
          {label && <FormItemLabel testId={`${testId}-label`}>{label}</FormItemLabel>}
          <Upload
            {...rest}
            onChange={onChangeMerged}
            files={value}
            fieldName={name}
            error={error}
            testId={testId}
            size={TUploadSize.SMALL}
          />
          {error && <FormError error={error} testId={`${testId}-error`} />}
          {bottomCaption && <BottomCaption>{bottomCaption}</BottomCaption>}
        </FormItemWrapper>
      );
    }}
  />
);

export { FormUploadSecondary };
