import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '@sim-admin-frontends/ui-shared';

const GoogleFormUrlModal: FC = () => {
  const { t } = useTranslation();
  const [isModalVisible, setModalVisible] = useState(true);

  const closeModal = () => setModalVisible(false);

  return (
    <ConfirmModal
      isOpen={isModalVisible}
      title={t('modal.important')}
      content={t('modal.googleFormUrl.warning')}
      backButtonText={t('modal.iUnderstand')}
      onBackClick={closeModal}
      testId="GoogleFormUrlModal"
    />
  );
};

export default GoogleFormUrlModal;
