import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import {
  AnnouncementsByFilterInput,
  ANNOUNCEMENTS_PAGE_SIZE,
  CategoryType,
  State,
  TAnnouncementListItem,
  TRaffleListItem,
  useCategoriesQuery,
  useInfiniteRafflesQuery,
} from '@sim-admin-frontends/data-access';
import { useOffsetTablePaging } from '@sim-admin-frontends/utils-shared';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';

import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import { findRaffleCategory } from '../../../utils/raffleUtils';
import {
  createTableColumns,
  getGqlSorting,
  getInitialGqlSorting,
  getInitialTableSorting,
} from './helper';
import RafflesView from './RafflesView';
import { TUnpublishContainerProps } from '../../../types/TUnpublish';

export interface RafflesViewContainerProps extends TUnpublishContainerProps {
  state: State;
  searchFilter: string;
}

const RafflesViewContainer: FC<RafflesViewContainerProps> = ({
  state,
  postsToUnpublish,
  onUnpublishSelect,
  isUnpublishMutationSettled,
  searchFilter,
}) => {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState(getInitialGqlSorting(state));
  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();
  const institutionId = institutionUuid || '';

  const {
    isLoading: categoriesIsLoading,
    isError: categoriesIsError,
    data: categories,
    refetch: categoriesRefetch,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.Raffle,
    },
  });

  const raffleCategory = useMemo(() => findRaffleCategory(categories), [categories]);

  const filter: AnnouncementsByFilterInput = useMemo(
    () => ({
      institutions: [institutionId],
      categories: [raffleCategory?.id ?? ''],
      state,
      text: searchFilter || undefined,
    }),
    [institutionId, raffleCategory, state, searchFilter],
  );

  const {
    fetchNextPage,
    hasNextPage,
    data: rafflesData,
    isError: rafflesIsError,
    isLoading: rafflesIsLoading,
    refetch: rafflesRefetch,
    isFetchingNextPage,
  } = useInfiniteRafflesQuery(filter, orderBy, !!raffleCategory?.id);

  const { currentPageIndex, maxPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } =
    useOffsetTablePaging(fetchNextPage, ANNOUNCEMENTS_PAGE_SIZE, hasNextPage);

  const onSortingChanged = useCallback(
    (newSorting: SortingRule<TAnnouncementListItem>[]) => {
      setOrderBy(getGqlSorting(newSorting, state));
      setCurrentPageIndex(0);
    },
    [setCurrentPageIndex, state],
  );

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [institutionUuid]);

  const refetch = () => {
    categoriesRefetch();
    rafflesRefetch();
  };

  useEffect(() => {
    if (isUnpublishMutationSettled) {
      refetch();
    }
  }, [isUnpublishMutationSettled]);

  if (rafflesIsError || categoriesIsError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const columns = createTableColumns(
    t,
    rafflesRefetch,
    state,
    places?.[0].timezoneCode,
    postsToUnpublish,
    onUnpublishSelect,
  );

  const itemsCount = rafflesData?.pages[0].announcementsBy.count;
  const raffles =
    rafflesData?.pages[currentPageIndex]?.announcementsBy.announcements.filter(
      (a): a is TRaffleListItem => a.__typename === 'Announcement',
    ) || [];

  const isLoading = categoriesIsLoading || rafflesIsLoading || isFetchingNextPage;

  return (
    <RafflesView
      hasNextPage={hasNextPage || currentPageIndex < maxPageIndex}
      loading={isLoading}
      itemsCount={itemsCount as number}
      pageIndex={currentPageIndex}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      columns={columns}
      data={raffles}
      onSortingChanged={onSortingChanged}
      initialTableSorting={getInitialTableSorting(state)}
    />
  );
};

export { RafflesViewContainer };
