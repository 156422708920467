import { captureException } from '@sentry/react';
import {
  AddAnnouncementDraftMutationVariables,
  AddAnnouncementMutationVariables,
  CategoryType,
  isAnnouncement,
  State,
  UploadType,
  useAnnouncementQuery,
  useCategoriesQuery,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import {
  getDefaultLangCode,
  useUploadImage,
  useUploadVideo,
} from '@sim-admin-frontends/utils-shared';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ANNOUNCEMENT_TOAST_SHARED_ID } from '../../../constants/Constants';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import { useGeneratePollActions } from '../../../hooks/actionButtons/useGeneratePollActions';
import { useAnnouncementSubmit } from '../../../hooks/useAnnouncementSubmit';
import NotFound from '../../../routing/NotFound';
import { TAnnouncementsFormTypes, TAnnouncementsTabTypes } from '../../../types/TAnnouncements';
import { TPollFormValues } from '../../../types/TPolls';
import { changePollsTab, findPollCategory, transformFormValues } from '../../../utils/pollsUtils';
import PollEdit from './PollEdit';

type Props = {
  id?: string;
  showOnlyPreview?: boolean;
};

const PollEditContainer: FC<Props> = ({ id, showOnlyPreview }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const { uploadFormImages } = useUploadImage();
  const { uploadFormVideos } = useUploadVideo();
  const { places } = usePlaceInfo();
  const defaultPostLang = getDefaultLangCode(places?.[0]?.countryCode);

  const { handleSubmitAnnouncement, handleSubmitDraftAnnouncement } = useAnnouncementSubmit(
    TAnnouncementsFormTypes.POLL,
  );

  const {
    data,
    isLoading: isAnnouncementLoading,
    error: announcementError,
    refetch,
  } = useAnnouncementQuery({ id: id || '' }, { enabled: !!id });

  const pollData = isAnnouncement(data?.announcement) ? data?.announcement : undefined;

  const {
    isLoading: areCategoriesLoading,
    error: categoriesError,
    data: categories,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.Poll,
    },
  });

  const pollCategory = useMemo(() => findPollCategory(categories), [categories]);

  const onDiscard = () => {
    changePollsTab(history, TAnnouncementsTabTypes.PUBLISHED);
  };

  const onSubmit = async (formValues: TPollFormValues) => {
    try {
      loadingToast(t('polls.form.toastSubmitting'), {
        toastId: ANNOUNCEMENT_TOAST_SHARED_ID,
      });
      const uploadedImages = await uploadFormImages(UploadType.News, formValues.images, {
        institutionId: institutionUuid,
      });
      const uploadedVideos = await uploadFormVideos(formValues.videos, {
        institutionId: institutionUuid,
      });
      const mutationVariables: AddAnnouncementMutationVariables = {
        announcement: transformFormValues(
          formValues,
          uploadedImages,
          uploadedVideos,
          institutionUuid,
          pollCategory,
          defaultPostLang,
        ),
      };
      await handleSubmitAnnouncement(pollData?.publishmentState, mutationVariables, id);
    } catch (err) {
      updateToast(ANNOUNCEMENT_TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const onSubmitDraft = async (formValues: TPollFormValues) => {
    try {
      loadingToast(t('polls.form.toastSubmitting'), {
        toastId: ANNOUNCEMENT_TOAST_SHARED_ID,
      });
      const uploadedImage = await uploadFormImages(UploadType.News, formValues.images, {
        institutionId: institutionUuid,
      });
      const uploadedVideos = await uploadFormVideos(formValues.videos, {
        institutionId: institutionUuid,
      });
      const mutationVariables: AddAnnouncementDraftMutationVariables = {
        announcementDraft: transformFormValues(
          formValues,
          uploadedImage,
          uploadedVideos,
          institutionUuid,
          pollCategory,
          defaultPostLang,
        ),
      };
      await handleSubmitDraftAnnouncement(pollData?.publishmentState, mutationVariables, id);
    } catch (err) {
      updateToast(ANNOUNCEMENT_TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const isFromAdmin = pollData?.createdByAdmin;
  const published = pollData?.publishmentState === State.Published;
  const type = pollData?.publishmentState || State.Published;

  const { detailActions, renderModal } = useGeneratePollActions({
    postId: id || '',
    refetch,
    isFromAdmin,
    published,
    type,
    isEditPage: true,
  });

  const isLoading = isAnnouncementLoading || areCategoriesLoading;

  const isError = announcementError || categoriesError;

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  if (isError)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  if (id && !pollData)
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );

  return (
    <>
      <PollEdit
        showOnlyPreview={showOnlyPreview}
        onSubmit={onSubmit}
        onSubmitDraft={onSubmitDraft}
        onDiscard={onDiscard}
        poll={pollData}
        pollFormActions={id ? detailActions : []}
      />
      {renderModal()}
    </>
  );
};

export default PollEditContainer;
