import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { subDays } from 'date-fns';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { formatDateObject } from '@sim-admin-frontends/utils-shared';

import { PageWrapper } from '../../components/layout/PageLayout';
import { useAuthInfo } from '../../contexts/userContext';
import { DashboardViewContainer } from '../../components/dashboard/DashboardView.container';
import { usePlaceInfo } from '../../contexts/placeContext';
import { useDashboardTitle } from '../../hooks/useDashboardTitle';

const HomePage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { user } = useAuthInfo();
  const { places } = usePlaceInfo();

  const userName = user?.attributes?.email ? user.attributes.email : t('dashboard.administrator');
  const now = new Date();
  const thirtyDaysAgo = subDays(now, 30);
  const period = `${formatDateObject(now)} -> ${formatDateObject(thirtyDaysAgo)}`;

  const currentPlace = places?.[0]?.name;
  const title = useDashboardTitle(userName);

  return (
    <PageWrapper>
      <PageHeader
        title={title}
        caption={t('dashboard.pageCaption', { name: currentPlace })}
        info={period}
        testId="DashboardTabs#PageHeader"
      />
      <DashboardViewContainer />
    </PageWrapper>
  );
};

export default HomePage;
