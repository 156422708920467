import styled from 'styled-components';

export const Round = styled.div<{ $checked: boolean }>`
  display: inline-flex;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: ${({ theme, $checked }) =>
    $checked ? theme.colors.blue50 : theme.colors.coolGray20};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
`;

export const LabelText = styled.div<{ $checked: boolean }>`
  position: absolute;
  top: ${({ theme }) => theme.spaces.spacing4};
  margin: ${({ theme }) => theme.spaces.spacing2};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme, $checked }) => ($checked ? theme.colors.white : theme.colors.coolGray70)};
`;
