import { FC, useMemo, useCallback, useState, useEffect } from 'react';
import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import {
  QuickQuestionsByFilterInput,
  State,
  useInfiniteQuickQuestionsQuery,
} from '@sim-admin-frontends/data-access';
import { useTranslation } from 'react-i18next';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';

import { useAuthInfo } from '../../../contexts/userContext';
import { getInitialTableSorting } from '../../announcements/view/helper';
import { createTableColumns } from '../../../utils/quickQuestionsUtils';
import { QuickQuestionsView } from './QuickQuestionsView';
import { getAppLang } from '../../../i18n/i18n';
import { TUnpublishContainerProps } from '../../../types/TUnpublish';

export interface QuickQuestionsViewContainerProps extends TUnpublishContainerProps {
  state: State;
  searchFilter: string;
}

const QuickQuestionsViewContainer: FC<QuickQuestionsViewContainerProps> = ({
  state,
  postsToUnpublish,
  onUnpublishSelect,
  isUnpublishMutationSettled,
  searchFilter,
}) => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const [orderBy, setOrderBy] = useState(undefined);
  const institutionUuidWithFallback = institutionUuid ?? '';

  const filter: QuickQuestionsByFilterInput = useMemo(
    () => ({
      lang: getAppLang(),
      institutions: [institutionUuidWithFallback],
      state,
      text: searchFilter || undefined,
    }),
    [institutionUuidWithFallback, state, searchFilter],
  );

  const { fetchNextPage, hasNextPage, data, isError, isLoading, isFetchingNextPage, refetch } =
    useInfiniteQuickQuestionsQuery(filter, orderBy);

  useEffect(() => {
    if (isUnpublishMutationSettled) {
      refetch();
    }
  }, [isUnpublishMutationSettled]);

  const { currentPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  const onSortingChanged = useCallback(() => {
    // ToDo: Implement when ready on BE
    setOrderBy(undefined);
    setCurrentPageIndex(0);
  }, [setCurrentPageIndex, state]);

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [institutionUuid]);

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const columns = createTableColumns(t, refetch, state, postsToUnpublish, onUnpublishSelect);

  const quickQuestions = data?.pages[currentPageIndex]?.quickQuestionsBy.quickQuestions;
  const itemsCount = data?.pages[currentPageIndex].quickQuestionsBy.count ?? 0;
  const loading = isLoading || isFetchingNextPage;

  return (
    <>
      <QuickQuestionsView
        hasNextPage={hasNextPage}
        loading={loading}
        itemsCount={itemsCount}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        columns={columns}
        data={quickQuestions ?? []}
        onSortingChanged={onSortingChanged}
        initialTableSorting={getInitialTableSorting(state)}
      />
    </>
  );
};

export { QuickQuestionsViewContainer };
