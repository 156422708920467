import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import EventEditContainer from '../../components/events/edit/EventEdit.container';

type UpdateEditPageProps = {
  id?: string;
};

const EventEditPage: FC<RouteComponentProps<UpdateEditPageProps>> = ({ match }) => {
  return <EventEditContainer id={match?.params?.id} />;
};

export default EventEditPage;
