import styled from 'styled-components';
import { CollapsiblePanel, device } from '@sim-admin-frontends/ui-shared';

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: ${({ theme }) => theme.spaces.spacing48} ${({ theme }) => theme.spaces.spacing32};
  align-items: center;
  overflow: auto;
`;

export const PreviewPanel = styled(CollapsiblePanel)`
  display: none;
  ${device.lg} {
    display: flex;
  }
`;

export const ScreenWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

export const StyledPageHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  color: ${({ theme }) => theme.colors.coolGray100};
  text-align: start;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spaces.spacing24};
  display: block;
`;

export const ButtonsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing32};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spaces.spacing8};
`;
