import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import { FC, useState, forwardRef, InputHTMLAttributes, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.css';

import { TBaseProps } from '../..';
import { Input } from '../input/Input';
import { ReactComponent as DateCalendarIcon } from './../../assets/img/date_calendar.svg';
import { DatePickerHeader } from './DatePickerHeader';

const InputWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
`;

const StyledInput = styled(Input)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: Manrope, serif;
`;

const DateCalendar = styled(DateCalendarIcon)`
  position: absolute;
  right: ${({ theme }) => theme.spaces.spacing8};
  top: ${({ theme }) => theme.spaces.spacing8};
  bottom: ${({ theme }) => theme.spaces.spacing8};
`;

const Wrapper = styled.div`
  flex: 1;
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  & .react-datepicker {
    padding: ${({ theme }) => theme.spaces.spacing16};
    border: none;
    box-shadow: 10px 10px 44px rgba(0, 0, 0, 0.04), 2px 2px 15px rgba(0, 0, 0, 0.08);
  }

  & .react-datepicker-popper {
    z-index: ${({ theme }) => theme.zIndex.datePickerPopup};
  }

  & .react-datepicker__day,
  .react-datepicker__day-name {
    font-family: Manrope, serif;
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    width: 36px;
    height: 36px;
    line-height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  }

  & .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.lightText};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  & .react-datepicker__header {
    background-color: transparent;
    border: none;
  }

  & .react-datepicker__day--disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.coolGray50};
    background-color: transparent;
  }

  & .react-datepicker__day:hover {
    background-color: ${({ theme }) => theme.colors.coolGray10};
  }

  & .react-datepicker__day--selected,
  .react-datepicker__day:hover {
    border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  }

  & .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover {
    background-color: ${({ theme }) => theme.colors.blue60};
  }

  & .react-datepicker__day:focus {
    outline: none !important;
  }

  & .react-datepicker__day--outside-month {
    color: transparent;
    pointer-events: none;
  }

  & .react-datepicker__day--outside-month.react-datepicker__day--selected {
    color: transparent;
    pointer-events: none;
    background-color: transparent;
  }
`;

const DatePickerInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & TBaseProps
>(({ value, onClick, onChange, testId, disabled }, ref) => {
  return (
    <InputWrapper onClick={onClick}>
      <StyledInput
        ref={ref}
        value={value}
        onChange={onChange}
        data-testid={testId}
        disabled={disabled}
        shouldStretch
      />
      <DateCalendar />
    </InputWrapper>
  );
});

DatePickerInput.displayName = 'DatePickerInput';

export interface DatePickerProps extends TBaseProps {
  initialValue?: Date | null;
  onDateChanged?: (value: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}

const DatePicker: FC<DatePickerProps> = ({
  initialValue,
  onDateChanged,
  minDate,
  className,
  testId,
  disabled,
  maxDate,
}) => {
  const [value, setValue] = useState<Date | null>(initialValue || new Date());

  const handleInputDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value);
    if (onDateChanged) {
      onDateChanged(newDate || undefined);
    }
    setValue(newDate);
  };

  const handleClickDateChange = (newDate: Date | null) => {
    if (onDateChanged) {
      onDateChanged(newDate);
    }
    setValue(newDate);
  };

  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Wrapper className={className}>
      <ReactDatePicker
        maxDate={maxDate}
        disabledKeyboardNavigation
        minDate={minDate}
        selected={value}
        onChange={handleClickDateChange}
        customInput={
          <DatePickerInput onChange={handleInputDateChange} testId={testId} disabled={disabled} />
        }
        showPopperArrow={false}
        disabled={disabled}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
          <DatePickerHeader
            month={date.toLocaleString(language || 'en-us', { month: 'long' })}
            onNextClick={increaseMonth}
            onPrevClick={decreaseMonth}
          />
        )}
      />
    </Wrapper>
  );
};

export { DatePicker };
