import { ReactElement, useState } from 'react';

import { SearchIcon } from '..';
import { Input, InputProps } from '../lib/input/Input';

interface SearchFilterHookOptions {
  testId: string;
  icon?: ReactElement;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: InputProps;
  minNumOfChars?: number;
}

export const useSearchFilter = (options: SearchFilterHookOptions) => {
  const { testId, icon, onChange, inputProps, minNumOfChars = 0 } = options;
  const [filter, setFilter] = useState('');

  const renderFilterComponent = (onFilterChange = setFilter) => {
    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      onFilterChange(e.target.value);
      if (onChange) {
        onChange(e);
      }
    };
    return (
      <Input
        testId={testId}
        icon={icon ?? <SearchIcon />}
        value={filter}
        onChange={onChangeInput}
        {...(inputProps ?? {})}
      />
    );
  };

  return { filter: filter.length < minNumOfChars ? '' : filter, renderFilterComponent };
};
