import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TabPanel, TTabPanelTabs } from '@sim-admin-frontends/ui-shared';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import ModeratorViewContainer from './ModeratorView.container';

type Props = {
  filter: string;
};

const ModeratorTabs: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const { setTabIndex, tabIndex } = useTabNavigation(ROUTES.moderator.path, []);

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: <ModeratorViewContainer searchFilter={filter} />,
        label: t('moderator.tabs.all'),
        testId: 'ModeratorsTabs#all',
      },
    ],
    [filter, t],
  );

  return <TabPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex} />;
};

export default ModeratorTabs;
