import { Button, TabPanel, TTabPanelTabs } from '@sim-admin-frontends/ui-shared';
import { FC, useCallback, useMemo } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { State, useUnpublishQuickQuestionMutation } from '@sim-admin-frontends/data-access';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { CtaWrapper } from '../../common/TabsStyles';
import { QuickQuestionsViewContainer } from './QuickQuestionsView.container';
import { TAnnouncementsTabTypes } from '../../../types/TAnnouncements';
import { AnalyticsEvent } from '../../../utils/analytics/analyticsEvents';
import { useBulkUnpublish } from '../../../hooks/useBulkUnpublish';
import { changeQuickQuestionsTab } from '../../../utils/quickQuestionsUtils';

export const quickQuestionsTabs = [
  TAnnouncementsTabTypes.PUBLISHED,
  TAnnouncementsTabTypes.SCHEDULED,
  // TAnnouncementsTabTypes.DRAFTS,
  TAnnouncementsTabTypes.UNPUBLISHED,
];

type Props = {
  filter: string;
};

const QuickQuestionsTabs: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    postsToUnpublish,
    onUnpublishSelect,
    renderBulkUnpublishButton,
    isUnpublishMutationSettled,
    clearSelection,
  } = useBulkUnpublish(useUnpublishQuickQuestionMutation, AnalyticsEvent.QUICK_QUESTION_ACTION);

  const { setTabIndex, tabIndex } = useTabNavigation(
    ROUTES.quickQuestionsOverview.path,
    quickQuestionsTabs,
    clearSelection,
  );

  //ToDo: Check state here and return draft container when state is DRAFT
  const getViewContainer = useCallback(
    (state: State) => (
      <QuickQuestionsViewContainer
        searchFilter={filter}
        state={state}
        postsToUnpublish={postsToUnpublish}
        onUnpublishSelect={onUnpublishSelect}
        isUnpublishMutationSettled={isUnpublishMutationSettled}
      />
    ),
    [filter, isUnpublishMutationSettled, onUnpublishSelect, postsToUnpublish],
  );

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: getViewContainer(State.Published),
        label: t('updates.tabs.published'),
        testId: 'QuickQuestionsTabs#published',
      },
      {
        content: getViewContainer(State.Scheduled),
        label: t('updates.tabs.scheduled'),
        testId: 'QuickQuestionsTabs#scheduled',
      },
      // {
      //   content: getViewContainer(State.Draft),
      //   label: t('updates.tabs.drafts'),
      //   testId: 'QuickQuestionsTabs#drafts',
      // },
      {
        content: getViewContainer(State.Unpublished),
        label: t('updates.tabs.unpublished'),
        testId: 'QuickQuestionsTabs#unpublished',
      },
    ],
    [getViewContainer, t],
  );

  const redirectToUnpublishedTab = () => {
    changeQuickQuestionsTab(history, TAnnouncementsTabTypes.UNPUBLISHED);
  };

  return (
    <TabPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex}>
      <CtaWrapper>
        {renderBulkUnpublishButton(redirectToUnpublishedTab)}
        <Link to={generatePath(ROUTES.quickQuestionsEdit.path)}>
          <Button testId="PollsTabs#AddQuickQuestion">{t('quickQuestion.addQuickQuestion')}</Button>
        </Link>
      </CtaWrapper>
    </TabPanel>
  );
};

export default QuickQuestionsTabs;
