import { yupResolver } from '@hookform/resolvers/yup';
import {
  MessagingCategory,
  MessagingProblemStatus,
  MessagingProblemConnectorStatus,
  MessagingProblemConnectorType,
} from '@sim-admin-frontends/data-access-admin-be';
import {
  Button,
  FormButtonsWrapper,
  FormImageCropperUpload,
  FormInput,
  FormSectionHeader,
  FormSelect,
  FormTextarea,
  FormUpload,
  FormWrapper,
  FormWysiwyg,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  TableIconProps,
  useImageGrid,
  Wrapper,
  ActionButtons,
  MapAreaSelect,
  TSelectItems,
  FormSwitcherWithLabel,
} from '@sim-admin-frontends/ui-shared';
import { FC, useMemo } from 'react';
import { FormProvider, get, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { formatTableDateTime, useDecodeFilesToBlobs } from '@sim-admin-frontends/utils-shared';
import styled from 'styled-components';
import { generatePath, useHistory } from 'react-router-dom';
import { Marker } from '@react-google-maps/api';
import { MessagingProblemLane } from '@sim-admin-frontends/data-access';

import { transformToSelectItem } from '../../../utils/selectUtils';
import ROUTES from '../../../routing/routes';
import { getProblemStatusLabel } from '../../../utils/reportProblemUtils';
import { TProblemsFormValues, TReportProblem } from '../../../types/TProblems';
import { ContentHeader } from '../../layout/pageContent/PageContentEditable';
import { MainContent } from '../../settings/aboutCity/SettingsAboutCityFormStyles';
import { getTestId } from '../../../utils/testUtils';
import { ReportProblemConnector } from './ReportProblemConnector';

export const RAP_TEST_ID_PREFIX = 'ReportProblemEdit';

const ImageWrapper = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.radius16};
  width: 100%;
  aspect-ratio: 1.775;
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  margin-bottom: ${({ theme }) => theme.spaces.spacing8};
  border-color: ${({ theme }) => theme.colors.coolGray20};
  border-width: 1px;
`;

const StyledVideo = styled.video`
  width: 100%;
  aspect-ratio: 1.775;
  border-radius: ${({ theme }) => theme.borderRadius.radius16};
`;

const GeoPointWrapper = styled.div`
  display: flex;
`;

const Divider = styled.div`
  height: ${({ theme }) => theme.spaces.spacing16};
`;

const schema = () => Yup.object().shape({});

type Props = {
  onSubmit: (values: TProblemsFormValues) => Promise<void>;
  problem?: TReportProblem;
  categories?: MessagingCategory[];
  reportProblemFormActions: TableIconProps[];
  isPreview?: boolean;
  connectorStatusOptions: TSelectItems;
  connectorTypeOptions: TSelectItems;
};

const ReportProblemEdit: FC<Props> = ({
  onSubmit,
  problem,
  categories,
  reportProblemFormActions,
  isPreview,
  connectorStatusOptions,
  connectorTypeOptions,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const getProblemCategory = () => {
    const category = categories?.find((i) => i.id === problem?.categoryUuid);
    if (category) {
      return {
        label: category.name,
        value: category.id,
      };
    }
    return undefined;
  };

  const getInitalConnectorValue = () => {
    if (!problem?.connector) {
      return null;
    }
    return {
      id: problem.connector.id ?? '',
      status: transformToSelectItem<MessagingProblemConnectorStatus>(problem.connector.status),
      type: transformToSelectItem<MessagingProblemConnectorType>(problem.connector.type),
      statusMessage: problem.connector.statusMessage ?? '',
    };
  };

  const initialValues: TProblemsFormValues = {
    createdAt: problem?.createdAt ? formatTableDateTime(problem.createdAt) : '',
    status: problem?.status
      ? { label: getProblemStatusLabel(problem?.status, t), value: problem?.status }
      : {
          label: getProblemStatusLabel(MessagingProblemStatus.New, t),
          value: MessagingProblemStatus.New,
        },
    category: getProblemCategory(),
    resolution: problem?.resolution || SERIALIZED_WYSIWYG_EMPTY_VALUE,
    imageObjects: problem?.imageObjects || [],
    videoObjects: problem?.videoObjects || [],
    description: problem?.description || '',
    email: problem?.email || '',
    address: problem?.address || '',
    reporter: problem?.reporter || '',
    geoPoint: problem?.geoPoint,
    lane: problem?.lane || null,
    connector: getInitalConnectorValue(),
    commentFromAuthority: problem?.commentFromAuthority || '',
    cityIsNotCompetent: problem?.cityIsNotCompetent || false,
  };

  const methods = useForm<TProblemsFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema()),
    mode: 'all',
  });

  const { handleSubmit, formState, register, getValues, setValue, trigger, control } = methods;
  const { errors, isSubmitting } = formState;

  const [previewStatus, previewImages, previewVideos, previewResolution, previewLane] = useWatch({
    name: ['status', 'imageObjects', 'videoObjects', 'resolution', 'lane'],
    control,
  });

  const shouldDisplayResolution = (isPreview && previewResolution) || !isPreview;

  const getTranslatedLane = (laneValue: MessagingProblemLane) => {
    const map = {
      [MessagingProblemLane.BikeLane]: t('messaging.lane.bikeLane'),
      [MessagingProblemLane.DrivingLane]: t('messaging.lane.drivingLane'),
      [MessagingProblemLane.ParkingLane]: t('messaging.lane.parkingLane'),
      [MessagingProblemLane.Crosswalk]: t('messaging.lane.crosswalk'),
    };

    return map[laneValue];
  };

  const imageStrings = useDecodeFilesToBlobs(previewImages);
  const videoStrings = useDecodeFilesToBlobs(previewVideos);
  const { renderImageGrid } = useImageGrid(imageStrings);

  const statusOptions = useMemo(() => {
    if (previewStatus.value !== MessagingProblemStatus.New) {
      return Object.values(MessagingProblemStatus)
        .filter((i) => i !== MessagingProblemStatus.New)
        .map((status) => ({
          value: status,
          label: getProblemStatusLabel(status, t),
        }));
    }
    return Object.values(MessagingProblemStatus).map((status) => ({
      value: status,
      label: getProblemStatusLabel(status, t),
    }));
  }, [previewStatus]);

  const categoryOptions = useMemo(() => {
    return Object.values(categories || []).map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, []);

  const submit = async (values: TProblemsFormValues) => {
    return onSubmit(values);
  };

  const handleGoBack = () => {
    history.push(generatePath(ROUTES.reportProblem.path));
  };

  const geoPointCenter = {
    lat: initialValues.geoPoint?.lat || 0,
    lng: initialValues.geoPoint?.lon || 0,
  };

  const onCityNotCompetentChange = (value: boolean) => {
    setValue('cityIsNotCompetent', value);
  };

  return (
    <Wrapper>
      <MainContent>
        <FormWrapper>
          <ActionButtons actionButtons={reportProblemFormActions} />
          <ContentHeader>{t('reportProblem.preview.title')}</ContentHeader>
          <FormProvider {...methods}>
            <FormInput
              label={t('reportProblem.table.dateCreated')}
              {...register('createdAt')}
              error={errors.createdAt}
              readOnly
              disabled
              testId={getTestId(RAP_TEST_ID_PREFIX, 'CreatedAt')}
            />
            <FormInput
              label={t('reportProblem.table.reporter')}
              {...register('reporter')}
              error={errors.reporter}
              readOnly
              disabled
              testId={getTestId(RAP_TEST_ID_PREFIX, 'Reporter')}
            />
            <FormInput
              label={t('reportProblem.table.email')}
              {...register('email')}
              error={errors.email}
              readOnly
              disabled
              testId={getTestId(RAP_TEST_ID_PREFIX, 'Email')}
            />
            <FormInput
              label={t('reportProblem.table.address')}
              {...register('address')}
              error={errors.address}
              readOnly
              disabled
              testId={getTestId(RAP_TEST_ID_PREFIX, 'Address')}
            />
            {initialValues.geoPoint && (
              <>
                <MapAreaSelect
                  center={geoPointCenter}
                  env={process.env.NX_GOOGLE_MAPS_IA_API_KEY || ''}
                  zoom={16}
                  isResetButtonVisible={false}
                >
                  <Marker position={geoPointCenter} />
                </MapAreaSelect>
                <GeoPointWrapper>
                  <FormInput
                    label={t('reportProblem.table.geoPoint.lat')}
                    defaultValue={initialValues.geoPoint?.lat}
                    readOnly
                    disabled
                    testId={getTestId(RAP_TEST_ID_PREFIX, 'GeoPointLat')}
                  />
                  <FormInput
                    label={t('reportProblem.table.geoPoint.lon')}
                    defaultValue={initialValues.geoPoint?.lon}
                    readOnly
                    disabled
                    testId={getTestId(RAP_TEST_ID_PREFIX, 'GeoPointLon')}
                  />
                </GeoPointWrapper>
              </>
            )}
            {previewLane && (
              <FormInput
                label={t('reportProblem.form.lane')}
                value={getTranslatedLane(previewLane)}
                readOnly
                disabled
                testId={getTestId(RAP_TEST_ID_PREFIX, 'Lane')}
              />
            )}
            <FormTextarea
              label={t('reportProblem.form.description')}
              {...register('description')}
              error={errors.description}
              testId={getTestId(RAP_TEST_ID_PREFIX, 'Description')}
              readOnly
              disabled
            />

            <FormSelect
              control={control}
              name="category"
              disabled={isPreview}
              label={t('reportProblem.form.category')}
              error={get(errors, 'category')}
              options={categoryOptions}
              defaultValue={initialValues.category}
              testId={getTestId(RAP_TEST_ID_PREFIX, 'Category')}
            />
            <ReportProblemConnector
              register={register}
              control={control}
              testIdPrefix={RAP_TEST_ID_PREFIX}
              errors={errors}
              connector={initialValues.connector}
              connectorStatusOptions={connectorStatusOptions}
              connectorTypeOptions={connectorTypeOptions}
            />
            <FormSectionHeader
              title={t('reportProblem.form.status')}
              description={t('reportProblem.form.statusDescription')}
            />
            <FormSelect
              control={control}
              name="status"
              disabled={isPreview}
              error={get(errors, 'status')}
              options={statusOptions}
              defaultValue={initialValues.status}
              testId={getTestId(RAP_TEST_ID_PREFIX, 'Status')}
            />
            {shouldDisplayResolution && (
              <>
                <FormSectionHeader title={t('reportProblem.form.resolution')} />
                <FormWysiwyg
                  disabled={isPreview}
                  control={control}
                  name="resolution"
                  initialValue={JSON.parse(getValues('resolution'))}
                  testId={getTestId(RAP_TEST_ID_PREFIX, 'Wysiwyg')}
                />
              </>
            )}
            {!isPreview && (
              <FormImageCropperUpload
                initialValue={initialValues.imageObjects}
                title={t('events.form.imageLabel')}
                description={t('events.form.imageSublabel')}
                control={control}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
                name="imageObjects"
                dropzoneLabel={t('events.form.dropzoneLabel')}
                t={t}
                multiple
                testId={getTestId(RAP_TEST_ID_PREFIX, 'Image')}
              />
            )}
            {!!previewImages?.length && (
              <ImageWrapper>{renderImageGrid(imageStrings)}</ImageWrapper>
            )}
            {!isPreview && (
              <>
                <FormSectionHeader
                  title={t('updates.form.videoLabel')}
                  description={t('updates.form.videoSublabel')}
                />
                <FormUpload
                  control={control}
                  name="videoObjects"
                  dropzoneLabel={t('updates.form.dropzoneLabel')}
                  t={t}
                  testId={getTestId(RAP_TEST_ID_PREFIX, 'ImageUpload')}
                />
              </>
            )}
            {!!videoStrings.length && (
              <div>
                <StyledVideo
                  data-testid={getTestId(RAP_TEST_ID_PREFIX, 'Video')}
                  src={videoStrings[0]}
                  controls
                >
                  <track kind="captions" />
                </StyledVideo>
              </div>
            )}
            <FormTextarea
              label={t('reportProblem.form.comment')}
              {...register('commentFromAuthority')}
              error={errors.commentFromAuthority}
              testId={getTestId(RAP_TEST_ID_PREFIX, 'commentFromAuthority')}
            />
            <FormSwitcherWithLabel
              label={t('reportProblem.form.cityNotCompetent')}
              initialValue={initialValues.cityIsNotCompetent ?? false}
              onChange={onCityNotCompetentChange}
            />
            <Divider />
            <FormButtonsWrapper>
              {isPreview ? (
                <Button size="smaller" onClick={handleGoBack}>
                  {t('common.backToList')}
                </Button>
              ) : (
                <Button
                  size="smaller"
                  type="submit"
                  onClick={handleSubmit(submit)}
                  isLoading={isSubmitting}
                >
                  {t('common.save')}
                </Button>
              )}
            </FormButtonsWrapper>
          </FormProvider>
        </FormWrapper>
      </MainContent>
    </Wrapper>
  );
};

export default ReportProblemEdit;
