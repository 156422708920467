/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import {
  createAuthInterceptor,
  InterceptorId,
  unregisterInterceptor,
} from '@sim-admin-frontends/utils-shared';

import { TLoggedUser } from '../types/TUser';
import { analyticsClear } from '../utils/analytics/analyticsUtils';
import { usePlaceInfo } from './placeContext';

const LOCAL_STORAGE_USER_KEY = 'user';
const LOCAL_STORAGE_IS_OFFICIAL_KEY = 'isOfficial';
const LOCAL_STORAGE_IS_CITY_KEY = 'isCity';
const LOCAL_STORAGE_INSTITUTION_ID_KEY = 'institutionUuid';
const LOCAL_STORAGE_IS_LOCAL_BUSINESS_KEY = 'isLocalBusiness';
const LOCAL_STORAGE_VISITOR_MODE_KEY = 'visitorMode';
const LOCAL_STORAGE_COGNITO_KEY = 'CognitoIdentityServiceProvider';

const defaultContext: TUserContext = {
  user: null,
  setUser: () => {},
  clearUser: () => {},
  institutionUuid: '',
  institutionUuids: [],
  placeUuid: undefined,
  isOfficial: false,
  isCity: false,
  isLocalBusiness: false,
  visitorMode: false,
  setIsCityInstitution: () => {},
  clearIsCityInstitution: () => {},
  setIsOfficialInstitution: () => {},
  clearIsOfficialInstitution: () => {},
  setInstitutionUuid: () => {},
  clearInstitutionUuid: () => {},
  setIsLocalBusiness: () => {},
  clearIsLocalBusiness: () => {},
  setVisitorMode: () => {},
  clearVisitorMode: () => {},
  logout: () => {},
};

type TUserContext = {
  user: TLoggedUser | null;
  setUser: (user: TLoggedUser) => void;
  clearUser: () => void;
  institutionUuid: string;
  institutionUuids: string[];
  placeUuid?: string;
  isOfficial: boolean;
  setIsOfficialInstitution: (arg: boolean) => void;
  clearIsOfficialInstitution: () => void;
  isCity: boolean;
  setIsCityInstitution: (arg: boolean) => void;
  clearIsCityInstitution: () => void;
  setInstitutionUuid: (arg: string) => void;
  clearInstitutionUuid: () => void;
  isLocalBusiness: boolean;
  setIsLocalBusiness: (arg: boolean) => void;
  clearIsLocalBusiness: () => void;
  visitorMode: false;
  setVisitorMode: (arg: boolean) => void;
  clearVisitorMode: () => void;
  logout: () => void;
};

const UserContext = createContext<TUserContext>(defaultContext);

const UserContextProvider: FC = ({ children }) => {
  const { clearPlaces } = usePlaceInfo();
  const [user, setUserHook] = useState<TLoggedUser | null>(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) || 'null'),
  );

  const initialIsOfficialState = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_IS_OFFICIAL_KEY) || 'false',
  );

  const initialIsCityState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_IS_CITY_KEY) || 'false');

  const initialIsLocalBusinessState = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_IS_LOCAL_BUSINESS_KEY) || 'false',
  );

  const initialVisitorMode = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_VISITOR_MODE_KEY) || 'false',
  );

  const [isOfficial, setIsOfficial] = useState(initialIsOfficialState);
  const [isCity, setIsCity] = useState(initialIsCityState);
  const [isLocalBusiness, setIsLocalBusinessHook] = useState(initialIsLocalBusinessState);
  const [visitorMode, setVisitorModeHook] = useState(initialVisitorMode);

  const [institutionUuid, setInstitutionUuidHook] = useState<string | null>(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_INSTITUTION_ID_KEY) || 'null') ||
      user?.attributes?.['custom:institutionUuid'] ||
      null,
  );

  const logout = useCallback(() => {
    Auth.signOut();
    unregisterInterceptor(InterceptorId.AUTH);
    clearUser();
    clearPlaces();
    clearIsOfficialInstitution();
    clearIsCityInstitution();
    clearInstitutionUuid();
    analyticsClear();
    clearIsLocalBusiness();
  }, []);

  useEffect(() => {
    const token = user?.signInUserSession?.idToken?.jwtToken;
    if (token) {
      createAuthInterceptor(logout);
    }
    return () => {
      unregisterInterceptor(InterceptorId.AUTH);
    };
  }, [logout, user]);

  // Listen for changes in localStorage made from different tab/window
  useEffect(() => {
    const checkUserData = () => {
      const newUserData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) || 'null');
      if (user && !newUserData) {
        setUserHook(null);
        logout();
      }
    };

    window.addEventListener('storage', checkUserData);

    return () => {
      window.removeEventListener('storage', checkUserData);
    };
  }, [logout, user]);

  const setUser = (newUser: TLoggedUser | null) => {
    setUserHook(newUser);
    localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(newUser));
  };

  const clearUser = () => {
    localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
    removeCognitoKeys();
    setUserHook(null);
  };

  const setIsOfficialInstitution = (isOfficialInstitution: boolean) => {
    setIsOfficial(isOfficialInstitution);
    localStorage.setItem(LOCAL_STORAGE_IS_OFFICIAL_KEY, JSON.stringify(isOfficialInstitution));
  };

  const clearIsOfficialInstitution = () => {
    localStorage.removeItem(LOCAL_STORAGE_IS_OFFICIAL_KEY);
    setIsOfficial(false);
  };

  const setIsCityInstitution = (isCityInstitution: boolean) => {
    setIsCity(isCityInstitution);
    localStorage.setItem(LOCAL_STORAGE_IS_CITY_KEY, JSON.stringify(isCityInstitution));
  };

  const clearIsCityInstitution = () => {
    localStorage.removeItem(LOCAL_STORAGE_IS_CITY_KEY);
    setIsCity(false);
  };

  const setIsLocalBusiness = (isLocalBusinessInstitution: boolean) => {
    setIsLocalBusinessHook(isLocalBusinessInstitution);
    localStorage.setItem(
      LOCAL_STORAGE_IS_LOCAL_BUSINESS_KEY,
      JSON.stringify(isLocalBusinessInstitution),
    );
  };

  const clearIsLocalBusiness = () => {
    localStorage.removeItem(LOCAL_STORAGE_IS_LOCAL_BUSINESS_KEY);
    setIsLocalBusinessHook(false);
  };

  const setVisitorMode = (localVisitorMode: boolean) => {
    setVisitorModeHook(localVisitorMode);
    localStorage.setItem(LOCAL_STORAGE_VISITOR_MODE_KEY, JSON.stringify(localVisitorMode));
  };

  const clearVisitorMode = () => {
    localStorage.removeItem(LOCAL_STORAGE_VISITOR_MODE_KEY);
    setVisitorModeHook(false);
  };

  const setInstitutionUuid = (newInstitutionUuid: string) => {
    setInstitutionUuidHook(newInstitutionUuid);
    localStorage.setItem(LOCAL_STORAGE_INSTITUTION_ID_KEY, JSON.stringify(newInstitutionUuid));
  };

  const clearInstitutionUuid = () => {
    localStorage.removeItem(LOCAL_STORAGE_INSTITUTION_ID_KEY);
    setInstitutionUuidHook(null);
  };

  const institutionUuids = user?.attributes?.['custom:institutionUuids'];
  const placeUuid = user?.attributes?.['custom:placeUuid'];
  const value = {
    user,
    setUser,
    clearUser,
    institutionUuid: institutionUuid || '',
    institutionUuids: institutionUuids?.split(':') || [],
    placeUuid,
    isOfficial,
    setIsOfficialInstitution,
    clearIsOfficialInstitution,
    isCity,
    setIsCityInstitution,
    clearIsCityInstitution,
    setInstitutionUuid,
    clearInstitutionUuid,
    isLocalBusiness,
    setIsLocalBusiness,
    clearIsLocalBusiness,
    visitorMode,
    setVisitorMode,
    clearVisitorMode,
    logout,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useAuthInfo = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserInfo must be used within a UserContextProvider');
  }
  return context;
};

const removeCognitoKeys = () => {
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);
    if (key && key.startsWith(LOCAL_STORAGE_COGNITO_KEY)) {
      localStorage.removeItem(key);
    }
  }
};

export { UserContextProvider, useAuthInfo };
