import { FC, useEffect, useState } from 'react';

import { Switcher } from '../../switcher/Switcher';

export interface FormSwitchProps {
  initialValue: boolean;
  onChange: (arg: boolean) => void;
  testId?: string;
  disabled?: boolean;
  invokeOnDisabled?: boolean;
  shouldSyncInitalValue?: boolean;
}

const FormSwitcher: FC<FormSwitchProps> = ({
  onChange,
  testId,
  disabled,
  initialValue,
  invokeOnDisabled = false,
  shouldSyncInitalValue = false,
}) => {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const internalOnChange = (value: boolean) => {
    setCurrentValue(value);
    onChange(value);
  };

  useEffect(() => {
    if (!invokeOnDisabled) {
      return;
    }

    if (disabled) {
      internalOnChange(false);
      return;
    }

    internalOnChange(initialValue);
  }, [invokeOnDisabled, disabled]);

  useEffect(() => {
    if (!shouldSyncInitalValue) {
      return;
    }

    setCurrentValue(initialValue);
  }, [shouldSyncInitalValue, initialValue]);

  return (
    <Switcher
      value={currentValue}
      onChange={internalOnChange}
      testId={testId}
      disabled={disabled}
    />
  );
};

export { FormSwitcher };
