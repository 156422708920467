import { DEFAULT_TEXT_LENGTH } from '../constants/Constants';

export const truncateText = (text: string | undefined, length?: number) => {
  if (!text) {
    return { title: '', truncatedText: '' };
  }

  let truncatedText = text;
  const maxLength = length || DEFAULT_TEXT_LENGTH;

  if (text.length > maxLength) {
    const lastWholeWordIndex = text.substring(0, maxLength).lastIndexOf(' ');
    const lastWord = text.substring(lastWholeWordIndex + 1);
    if (lastWord.length > 20) {
      truncatedText = text.substring(0, maxLength) + '...';
    } else {
      truncatedText = text.substring(0, lastWholeWordIndex) + '...';
    }
  }

  return { title: text, truncatedText };
};
