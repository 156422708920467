import {
  ANNOUNCEMENTS_PAGE_SIZE,
  AnnouncementsByFilterInput,
  AnnouncementsOrderByInput,
  getAnnouncementsNextPageParam,
  useDonationsQuery,
  useInfiniteGraphQLQuery,
} from '..';

export const useInfiniteDonationsQuery = (
  filter: AnnouncementsByFilterInput,
  orderBy?: AnnouncementsOrderByInput,
  enabled?: boolean,
) => {
  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    orderBy,
    pageOffset: Number(pageParam ?? 0),
    pageSize: ANNOUNCEMENTS_PAGE_SIZE,
    filter,
  });

  return useInfiniteGraphQLQuery(useDonationsQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam: getAnnouncementsNextPageParam,
    enabled,
  });
};
