import { FC, useMemo } from 'react';
import {
  PaginatedAnnouncementFeedbacks,
  PaginatedEventFeedbacks,
} from '@sim-admin-frontends/data-access';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { ExtraSmallText, SmallText } from '@sim-admin-frontends/ui-shared';

type ModeratorHatespeechCellProps = {
  data: PaginatedEventFeedbacks | PaginatedAnnouncementFeedbacks;
};

const TOOLTIP_SHOW_DELAY = 100;
const TOOLTIP_PLACEMENT = 'bottom-start';

const StyledTippy = styled(Tippy)`
  &.tippy-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 20px 20px 65px rgba(0, 0, 0, 0.07), 5px 5px 15px rgba(0, 0, 0, 0.12);
  }
`;

const StyledNumber = styled(SmallText)`
  display: inline;
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  padding-right: ${({ theme }) => theme.spaces.spacing4};
`;

const StyledName = styled(ExtraSmallText)`
  display: inline;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const ToolTipWrapper = styled.div`
  flex: 1;
  margin: ${({ theme }) => theme.spaces.spacing12};
`;

const ToolTipContent = styled.div`
  display: inline-list-item;
`;

type TToolTipContent = {
  name: string;
  value: number | undefined | null;
};

const ModeratorHatespeechCell: FC<ModeratorHatespeechCellProps> = ({ data }) => {
  const { t } = useTranslation();

  const toolTipContent: TToolTipContent[] = useMemo(
    () =>
      [
        {
          name: t('moderator.reportOptions.fakeNews'),
          value: data.fakeNewsCount,
        },
        {
          name: t('moderator.reportOptions.spam'),
          value: data.spamCount,
        },
        {
          name: t('moderator.reportOptions.scam'),
          value: data.scamCount,
        },
        {
          name: t('moderator.reportOptions.inappropriatePost'),
          value: data.inappropriateCount,
        },
        {
          name: t('moderator.reportOptions.hatefulPost'),
          value: data.hatefulCount,
        },
        {
          name: t('moderator.reportOptions.other'),
          value: data.otherCount,
        },
      ].filter((content) => content.value),
    [data, t],
  );
  const totalCount = toolTipContent.reduce(
    (accumulator, content) => accumulator + (content.value || 0),
    0,
  );

  const renderToolTipContent = () => (
    <ToolTipWrapper>
      {toolTipContent.map((content, index) => (
        <ToolTipContent key={index}>
          <StyledNumber>{content.value}</StyledNumber>
          <StyledName>{content.name}</StyledName>
        </ToolTipContent>
      ))}
    </ToolTipWrapper>
  );

  return toolTipContent.length ? (
    <StyledTippy
      arrow={false}
      placement={TOOLTIP_PLACEMENT}
      delay={TOOLTIP_SHOW_DELAY}
      content={renderToolTipContent()}
    >
      <div>{totalCount}</div>
    </StyledTippy>
  ) : (
    <div>{totalCount}</div>
  );
};

export default ModeratorHatespeechCell;
