import { FC } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import RaffleEditContainer from '../../components/raffles/edit/RaffleEdit.container';
import { useAuthInfo } from '../../contexts/userContext';

type UpdateEditPageProps = {
  id?: string;
};

const RaffleEditPage: FC<RouteComponentProps<UpdateEditPageProps>> = ({ match }) => {
  const { visitorMode } = useAuthInfo();

  if (visitorMode) {
    return <Redirect to="/404" />;
  }

  return <RaffleEditContainer id={match?.params?.id} />;
};

export default RaffleEditPage;
