import { FC } from 'react';
import { Error, ThemeProvider } from '@sim-admin-frontends/ui-shared';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

export interface GlobalErrorProps {
  caption?: string;
  buttonLabel?: string;
  onClick?: () => void;
}

const GlobalError: FC<GlobalErrorProps> = ({
  caption,
  buttonLabel,
  onClick = () => window.location.reload(),
}) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider>
      <Wrapper>
        <Error
          caption={caption || t('error.globalError')}
          buttonLabel={buttonLabel || t('error.reload')}
          onClick={onClick}
        />
      </Wrapper>
    </ThemeProvider>
  );
};

export { GlobalError };
