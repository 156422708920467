import { TFunction } from 'i18next';
import { HarmfulContentReason } from '@sim-admin-frontends/data-access';

export const getInfoModalTitle = (type: HarmfulContentReason, t: TFunction) => {
  switch (type) {
    case HarmfulContentReason.Fake:
      return t('moderator.reportOptions.fakeNews');
    case HarmfulContentReason.Spam:
      return t('moderator.reportOptions.spam');
    case HarmfulContentReason.Scam:
      return t('moderator.reportOptions.scam');
    case HarmfulContentReason.Inappropriate:
      return t('moderator.reportOptions.inappropriatePost');
    case HarmfulContentReason.Hateful:
      return t('moderator.reportOptions.hatefulPost');
    case HarmfulContentReason.Other:
      return t('moderator.reportOptions.other');
    default:
      return '';
  }
};

export const getInfoModalOptions = (reportReason: HarmfulContentReason, t: TFunction): string[] => {
  switch (reportReason) {
    case HarmfulContentReason.Fake:
      return [
        t('moderator.reportOptions.info.fake1'),
        t('moderator.reportOptions.info.fake2'),
        t('moderator.reportOptions.info.fake3'),
      ];
    case HarmfulContentReason.Spam:
      return [
        t('moderator.reportOptions.info.spam1'),
        t('moderator.reportOptions.info.spam2'),
        t('moderator.reportOptions.info.spam3'),
      ];
    case HarmfulContentReason.Scam:
      return [t('moderator.reportOptions.info.scam1')];
    case HarmfulContentReason.Inappropriate:
      return [
        t('moderator.reportOptions.info.inappropriate1'),
        t('moderator.reportOptions.info.inappropriate2'),
        t('moderator.reportOptions.info.inappropriate3'),
      ];
    case HarmfulContentReason.Hateful:
      return [
        t('moderator.reportOptions.info.hateful1'),
        t('moderator.reportOptions.info.hateful2'),
        t('moderator.reportOptions.info.hateful3'),
      ];
    case HarmfulContentReason.Other:
      return [t('moderator.reportOptions.info.other1')];
    default:
      return [];
  }
};
