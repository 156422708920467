import styled from 'styled-components';
import { FC } from 'react';

const Wrapper = styled.div<{ selected: boolean }>`
  display: flex;
  flex: 1;
  border-bottom: 1px solid;
  border-color: ${({ theme, selected }) => (selected ? theme.colors.coolGray100 : 'transparent')};
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spaces.spacing16};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.caption1};
  line-height: ${({ theme }) => theme.lineHeights.caption1};
  transition: border-color 0.3s ease-in-out;
  margin-bottom: 1px;
`;

const Label = styled.div<{ selected: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.coolGray100 : theme.colors.coolGray70};
`;

const CountLabel = styled.div`
  color: ${({ theme }) => theme.colors.coolGray40};
  margin-left: ${({ theme }) => theme.spaces.spacing8};
`;
export interface TabHeaderProps {
  selected: boolean;
  count?: number | null;
  label: string;
  testId?: string;
}

const TabHeaderButton: FC<TabHeaderProps> = ({ selected, count, label, testId }) => {
  return (
    <Wrapper selected={selected}>
      <Label selected={selected} data-testid={testId}>
        {label}
      </Label>
      {count !== undefined && <CountLabel>{count}</CountLabel>}
    </Wrapper>
  );
};

export { TabHeaderButton };
