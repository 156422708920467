import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { MultipleFieldErrors } from 'react-hook-form';

import { SmallText, CheckIcon, CrossIcon } from '../..';

const Wrap = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.coolGray10};
  padding: ${({ theme }) => theme.spaces.spacing24};
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
`;

const Headline = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }) => theme.spaces.spacing8};
`;

const Items = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const Item = styled.li<{ isValid?: boolean }>`
  color: ${({ theme }) => theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: ${({ theme }) => theme.spaces.spacing8};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
  & > svg {
    display: inline-block;
    margin-right: ${({ theme }) => theme.spaces.spacing8};
  }
  ${({ theme, isValid }) =>
    isValid &&
    `
      color: ${theme.colors.green60};
      & > svg {
        fill: ${theme.colors.green60};
      }
      & > span {
        text-decoration: line-through;
      }
    `}
`;

const Requirement: FC<{
  isValid: boolean;
}> = ({ isValid, children }) => {
  const iconProps = { width: 14, height: 14 };
  if (isValid) {
    return (
      <Item isValid>
        <CheckIcon {...iconProps} /> <span>{children}</span>
      </Item>
    );
  }
  return (
    <Item>
      <CrossIcon {...iconProps} /> {children}
    </Item>
  );
};

export type PasswordRequirementsItem = {
  errorKey: string;
  text: ReactNode | string;
};

export type PasswordRequirementsItems = PasswordRequirementsItem[];

export type PasswordRequirementsProps = {
  title: string;
  items: PasswordRequirementsItems;
  errors?: MultipleFieldErrors;
};

export const PasswordRequirements: FC<PasswordRequirementsProps> = ({ title, items, errors }) => {
  const getIsValid = (key: string) => {
    return !errors?.[key];
  };
  return (
    <Wrap>
      {title && <Headline>{title}</Headline>}
      <Items>
        {items.map(({ errorKey, text }) => {
          return (
            <Requirement isValid={getIsValid(errorKey)} key={errorKey}>
              {text}
            </Requirement>
          );
        })}
      </Items>
    </Wrap>
  );
};
