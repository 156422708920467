import styled, { css } from 'styled-components';
import { forwardRef, TextareaHTMLAttributes } from 'react';

import { TBaseProps } from '../../types/TBaseComponent';

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, TBaseProps {
  invalid?: boolean;
}

const StyledTextArea = styled.textarea<{ invalid?: boolean; rows?: number }>`
  min-height: ${({ rows }) => (rows ? 'none' : '250px')};
  width: 100%;
  resize: vertical;
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  color: ${({ theme }) => theme.colors.coolGray100};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  padding: ${({ theme }) => theme.spaces.spacing16};
  font-family: ${({ theme }) => theme.fonts.primary}, serif;
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.fontSizes.small};

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.blue60};
    padding: ${({ theme }) => `calc(${theme.spaces.spacing16} - 4px)`};
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.lightText};
  }
  ${({ invalid, theme }) =>
    invalid &&
    css`
      border: 2px solid ${theme.colors.red60};
      padding: calc(${theme.spaces.spacing16} - 4px);
    `}
`;

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ testId, ...props }, ref) => {
  return <StyledTextArea ref={ref} data-testid={testId} {...props} />;
});

Textarea.displayName = 'Textarea';

export { Textarea };
