import Tippy from '@tippyjs/react';
import React, { FC, ReactNode, useCallback } from 'react';
import { useSlate } from 'slate-react';
import styled from 'styled-components';
import { TBlockType, TTextFormat } from '@simplicity-tech/sim-slate-types';

import {
  focusPreviousSelection,
  isBlockActive,
  isMarkActive,
  toggleBlock,
  toggleLink,
  toggleMark,
} from '../helpers/helper';
import ToolbarTooltip from './ToolbarTooltip';
import LinkTooltip from './LinkTooltip';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward.css';

const Wrapper = styled.button<{ active: boolean }>`
  margin: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 24px;
  background: ${({ active, theme }) => (active ? theme.colors.coolGray20 : theme.colors.white)};
  border-radius: 2px;
  display: flex;
  align-items: center;
`;

const Icon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

interface BaseActionProps {
  icon: ReactNode;
  tippyTarget: any;
  label: string;
  activeLabel: string;
  shortcut?: string;
}

interface MarkActionProps extends BaseActionProps {
  format: TTextFormat;
}

const MarkAction: FC<MarkActionProps> = ({
  icon,
  format,
  tippyTarget,
  label,
  activeLabel,
  shortcut,
}) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      //We need to refocus and reselect manually due to selection loss in safari after clicking outside of contenteditable
      focusPreviousSelection(editor);
      toggleMark(editor, format);
    },
    [format, editor],
  );
  return (
    <Tippy
      content={<ToolbarTooltip label={isActive ? activeLabel : label} shortcut={shortcut} />}
      singleton={tippyTarget}
    >
      <Wrapper active={isActive} onClick={handleClick}>
        <Icon>{icon}</Icon>
      </Wrapper>
    </Tippy>
  );
};

interface LinkActionProps extends BaseActionProps {
  className?: string;
  activeIcon: ReactNode;
}

// Link action has to stay separate from Mark & Block actions,
// because it partially does both; First, it creates a mark to capture link placement.
// Afterwards, after user inputs the link, it creates a link block.

const LinkAction: FC<LinkActionProps> = ({
  icon,
  activeIcon,
  className,
  tippyTarget,
  label,
  activeLabel,
  shortcut,
}) => {
  const editor = useSlate();
  const isActive = isBlockActive(editor, 'link');

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      //We need to refocus and reselect manually due to selection loss in safari after clicking outside of contenteditable
      focusPreviousSelection(editor);
      toggleLink(editor);
    },
    [editor],
  );
  return (
    <Tippy
      content={<ToolbarTooltip label={isActive ? activeLabel : label} shortcut={shortcut} />}
      singleton={tippyTarget}
      zIndex={9998}
    >
      <LinkTooltip>
        <Wrapper
          className={className}
          active={isActive}
          onClick={handleClick}
          aria-haspopup="true"
          aria-expanded={true}
        >
          <Icon>{isActive ? activeIcon : icon}</Icon>
        </Wrapper>
      </LinkTooltip>
    </Tippy>
  );
};

interface BlockActionProps extends BaseActionProps {
  className?: string;
  format: TBlockType;
}

const BlockAction: FC<BlockActionProps> = ({
  icon,
  format,
  tippyTarget,
  label,
  activeLabel,
  shortcut,
}) => {
  const editor = useSlate();
  const isActive = isBlockActive(editor, 'bullet-list');
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      //We need to refocus and reselect manually due to selection loss in safari after clicking outside of contenteditable
      focusPreviousSelection(editor);
      toggleBlock(editor, format);
    },
    [format, editor],
  );
  return (
    <Tippy
      content={<ToolbarTooltip label={isActive ? activeLabel : label} shortcut={shortcut} />}
      singleton={tippyTarget}
    >
      <Wrapper active={isActive} onClick={handleClick}>
        <Icon>{icon}</Icon>
      </Wrapper>
    </Tippy>
  );
};

export { MarkAction, LinkAction, BlockAction };
