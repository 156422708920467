import { FC } from 'react';
import { SortingRule } from 'react-table';
import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { TAnnouncementList, TAnnouncementListItem } from '@sim-admin-frontends/data-access';

export interface AnnouncementsViewProps {
  data: TAnnouncementList;
  columns: TExtendedColumn<TAnnouncementListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  onSortingChanged: (value: SortingRule<TAnnouncementListItem>[]) => void;
  initialTableSorting: SortingRule<TAnnouncementListItem>[];
  hasNextPage?: boolean;
}

const EmergencyView: FC<AnnouncementsViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  onSortingChanged,
  initialTableSorting,
  hasNextPage,
}) => (
  <Table
    hasNextPage={hasNextPage}
    loading={loading}
    itemsCount={itemsCount}
    pageIndex={pageIndex}
    data={data}
    columns={columns}
    hasPagination={true}
    onNextPress={onNextPress}
    onPreviousPress={onPreviousPress}
    onSortingChanged={onSortingChanged}
    initialSortBy={initialTableSorting}
    testId="EmergencyView#Table"
  />
);

export default EmergencyView;
