import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, get, useForm, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { format, isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
  getInstitutionInitValues,
  isEmpty,
  isFile,
  MaybeDate,
  roundMinutes,
  getLangOptionByVal,
  getLangOptions,
} from '@sim-admin-frontends/utils-shared';
import {
  ActionButtons,
  Button,
  ExternalLinkIcon,
  FormButtonsWrapper,
  FormDateTimePicker,
  FormImageCropperUpload,
  FormInput,
  FormLocationInput,
  FormSectionHeader,
  FormSelect,
  FormUpload,
  FormWrapper,
  FormWysiwyg,
  HideableFormItem,
  MAX_UPLOAD_SIZE,
  MAX_VIDEO_UPLOAD_SIZE,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  SuccessOutlinedIcon,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_VIDEO_FORMATS,
  TableIconProps,
  TSelectItem,
  TSelectItems,
  TSelectRef,
  FormPrice,
} from '@sim-admin-frontends/ui-shared';
import {
  EventLocationInput,
  ImageInput,
  State,
  TCategories,
  TEventDetail,
  TInstitution,
  TInstitutionListItem,
  VideoInput,
} from '@sim-admin-frontends/data-access';

import { TEventsFormValues, TEventType } from '../../../types/TEvents';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { FALLBACK_TIMEZONE } from '../../../constants/Constants';
import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import PageContentEditable, { ContentHeader } from '../../layout/pageContent/PageContentEditable';
import { useAuthInfo } from '../../../contexts/userContext';
import EventPreview from '../preview/EventPreview';
import useIsSystemDisabled from '../../../hooks/useIsSystemDisabled';
import { getPlaceOptions } from '../../../utils/placeUtils';
import useYTVideoInEditor from '../../../hooks/useYTVideoInEditor';
import useDateTimeChanged from '../../../hooks/useDateTimeChanged';
import { LinkDetailButton } from '../../common/LinkDetailButton';
import { getPostLink } from '../../../utils/postUtils';
import { getCategoriesOptions, getInitialCategoriesOptions } from '../../../utils/categoriesUtils';
import useChangePublisher from '../../../hooks/useChangePublisher';
import { transformInitialPriceValue } from '../../../utils/eventsUtils';

const schema = (t: TFunction, timezone: string, isEndDateVisible: boolean, isMultiCity: boolean) =>
  Yup.object().shape({
    title: Yup.string().required(t('common.validation.required')),
    location: Yup.object().shape({
      name: Yup.string().required(t('common.validation.required')),
    }),
    content: Yup.string().test(
      'equals',
      t('common.validation.required'),
      (value) => value !== SERIALIZED_WYSIWYG_EMPTY_VALUE,
    ),
    places: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !(isMultiCity && (value || []).length < 1),
    ),
    images: Yup.array().of(
      Yup.mixed()
        .test('fileSize', t('common.validation.file.tooLarge'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
        )
        .test(
          'fileType',
          t('common.validation.file.unsupportedFormat'),
          (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_IMAGE_FORMATS.includes(file.type) : true,
        ),
    ),
    videos: Yup.array().of(
      Yup.mixed()
        .test('fileSize', t('common.validation.file.tooLarge'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_VIDEO_UPLOAD_SIZE : true,
        )
        .test(
          'fileType',
          t('common.validation.file.unsupportedFormat'),
          (file: File | VideoInput) =>
            file && isFile(file) ? SUPPORTED_VIDEO_FORMATS.includes(file.type) : true,
        ),
    ),
    startDate: Yup.mixed()
      .test('required', t('common.validation.required'), (value: MaybeDate) => !!value)
      .when('publishedAt', (publishedAt) =>
        Yup.date().test(
          'greaterThanPublished',
          t('common.validation.date.greaterThanPublished'),
          (value?: Date) => !!value && value > publishedAt,
        ),
      ),
    endDate: Yup.mixed()
      .test(
        'required',
        t('common.validation.required'),
        (value: MaybeDate) => !isEndDateVisible || !!value,
      )
      .test('mindate', t('common.validation.date.greaterDate'), function (endDate: MaybeDate) {
        const { startDate } = this.parent;
        return !isEndDateVisible || (!!endDate && endDate > startDate);
      }),
    publishedAt: Yup.mixed()
      .test('required', t('common.validation.required'), (value: MaybeDate) => !!value)
      .test(
        'maxdate',
        t('common.validation.date.futureDate'),
        (value: Date) => value <= roundMinutes(utcToZonedTime(new Date(), timezone)),
      ),
    marketUrl: Yup.string().url().nullable(),
    marketItemPricing: Yup.mixed().test(
      'priceAndCurrency',
      t('common.validation.price.priceAndCurrency'),
      (value) => {
        if (!value) {
          return true;
        }
        return (value.price === 0 || !!value.price) && !!value.currency;
      },
    ),
  });

type Props = {
  onSubmit: (values: TEventsFormValues) => Promise<void>;
  onDiscard: () => void;
  event?: TEventDetail;
  institution: TInstitution;
  userInstitutions: TInstitutionListItem[];
  showOnlyPreview?: boolean;
  eventFormActions: TableIconProps[];
  categories: TCategories;
};

const EventEdit: FC<Props> = ({
  onSubmit,
  onDiscard,
  event,
  institution,
  userInstitutions,
  showOnlyPreview,
  eventFormActions,
  categories,
}) => {
  const now = new Date();
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const { user, visitorMode } = useAuthInfo();
  const categoriesRef = useRef<TSelectRef>();

  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;
  const countryCode = places?.[0].countryCode || '';
  const institutionLang = institution?.lang || undefined;
  const { link } = getPostLink(event?.id ?? '');

  const { postsDisabled } = useIsSystemDisabled();
  const submit = async (values: TEventsFormValues) => {
    return onSubmit(values);
  };

  const eventTypeOptions: TSelectItems = [
    { label: t('events.liveEvent'), value: TEventType.Live },
    { label: t('events.onlineEvent'), value: TEventType.Online },
  ];

  const institutionOptions = useMemo(
    () =>
      getInstitutionInitValues(
        user?.attributes?.['custom:institutionUuids'],
        institution,
        userInstitutions,
      ),
    [userInstitutions, institution, user?.attributes],
  );

  const categoriesOptions = getCategoriesOptions(categories);

  const initialPublisher = { label: institution?.name || '', value: institution?.id || '' };

  const initialEventType =
    event?.location?.name === TEventType.Online ? eventTypeOptions[1] : eventTypeOptions[0];

  const initialValues: TEventsFormValues = {
    title: event?.title || '',
    eventType: initialEventType.value,
    lang: getLangOptionByVal(event?.lang || institutionLang, countryCode),
    publisher: initialPublisher,
    content: event?.content || SERIALIZED_WYSIWYG_EMPTY_VALUE,
    images: event?.imageObjects,
    videos: event?.videoObjects,
    places: getPlaceOptions(event?.places) || [],
    startDate: event?.datetimeFrom
      ? utcToZonedTime(new Date(event?.datetimeFrom), timezone)
      : roundMinutes(utcToZonedTime(new Date(), timezone)),
    endDate: event?.datetimeTo ? utcToZonedTime(new Date(event?.datetimeTo), timezone) : undefined,
    location: event?.location,
    publishedAt: event?.publishedAt
      ? utcToZonedTime(event?.publishedAt, timezone)
      : utcToZonedTime(now, timezone),
    categories: getInitialCategoriesOptions(categoriesOptions, event?.categories),
    marketUrl: event?.marketUrl,
    marketItemPricing: event?.marketItemPricing
      ? transformInitialPriceValue(event?.marketItemPricing)
      : undefined,
  };
  const initialPrice = initialValues.marketItemPricing ?? undefined;
  const initialPublishedAtDate = initialValues.publishedAt || utcToZonedTime(now, timezone);

  const initialStartTime = format(
    initialValues?.startDate || roundMinutes(initialPublishedAtDate),
    'hh:mm',
  );

  const initialStartTimePeriod = format(
    initialValues?.startDate || roundMinutes(initialPublishedAtDate),
    'a',
  );

  const [minEndDate, setMinEndDate] = useState(initialValues.startDate);
  const [minEndTime, setMinEndTime] = useState(
    initialValues?.endDate && initialValues?.startDate
      ? format(initialValues?.endDate || roundMinutes(initialPublishedAtDate), 'hh:mm')
      : format(initialValues?.startDate || roundMinutes(initialPublishedAtDate), 'hh:mm'),
  );

  const [minEndPeriod, setMinEndPeriod] = useState(
    initialValues?.endDate && initialValues?.startDate
      ? format(initialValues?.endDate, 'a')
      : format(initialValues?.startDate || roundMinutes(initialPublishedAtDate), 'a'),
  );

  const [endDateVisible, setEndTimeVisible] = useState(!!initialValues.endDate);

  const initialEndDate =
    initialValues.endDate || minEndDate || roundMinutes(initialPublishedAtDate);

  const placesOptions = useMemo(() => getPlaceOptions(institution?.places), [institution]);

  const shouldShowCitySelect = (placesOptions || []).length > 1;
  const methods = useForm<TEventsFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, timezone, endDateVisible, shouldShowCitySelect)),
    mode: 'all',
  });

  const { handleSubmit, register, formState, getValues, setValue, trigger, control } = methods;
  const { errors, isSubmitting, isDirty, dirtyFields } = formState;

  const [
    previewTitle,
    previewImages,
    previewVideos,
    previewContent,
    previewLang,
    previewStartDate,
    previewEndDate,
    previewLocation,
    previewPublisher,
    previewMarketUrl,
  ] = useWatch({
    name: [
      'title',
      'images',
      'videos',
      'content',
      'lang',
      'startDate',
      'endDate',
      'location',
      'publisher',
      'marketUrl',
    ],
    control,
  });

  const handleEndDateVisible = () => {
    if (endDateVisible) {
      setEndTimeVisible(false);
      setValue('endDate', null);
      trigger('endDate');
    } else {
      const startDate = new Date(
        getValues('startDate') || roundMinutes(utcToZonedTime(new Date(), timezone)),
      );
      const newMinValue = roundMinutes(startDate);
      setValue('endDate', newMinValue);
      trigger('endDate');
      setMinEndDate(newMinValue);
      setMinEndTime(format(newMinValue, 'hh:mm'));
      setMinEndPeriod(format(newMinValue, 'a'));
      setEndTimeVisible(true);
      trigger('endDate');
    }
  };

  useEffect(() => {
    trigger('endDate');
  }, [endDateVisible]);

  const handleStartDateChange = useCallback((value?: Date | null) => {
    if (value === null) {
      setValue('startDate', null);
      trigger('startDate');
      return;
    }
    const newValue =
      value && isValid(value) ? value : utcToZonedTime(roundMinutes(new Date()), timezone);
    const newMinValue = roundMinutes(newValue);
    setValue('startDate', newValue);
    setMinEndDate(newMinValue);
    setMinEndTime(format(newMinValue, 'hh:mm'));
    setMinEndPeriod(format(newMinValue, 'a'));
    trigger(['startDate', 'endDate']);
  }, []);

  const onEventTypeOptionChange = useCallback(
    (options: readonly TSelectItem[] | null) => {
      if (options?.[0].value === TEventType.Online) {
        setValue('location', { name: 'Online' });
      } else {
        setValue('location', { name: getInitialLocationName() });
      }
      trigger('location');
    },
    [trigger, setValue],
  );

  const onLocationValueChange = useCallback((value: EventLocationInput) => {
    setValue('location', value);
    trigger('location');
  }, []);

  const handleEndDateChange = useCallback((value?: Date | null) => {
    setValue('endDate', value);
    trigger('endDate');
  }, []);

  const { onDateTimeChanged: onPublishedAtDateTimeChanged } = useDateTimeChanged(
    'publishedAt',
    setValue,
    trigger,
  );

  const locationInputVisible = getValues('location')
    ? getValues('location')?.name !== TEventType.Online
    : true;

  const getInitialLocationName = () => {
    if (initialValues?.location?.name === TEventType.Online) {
      return '';
    } else {
      return initialValues.location ? initialValues.location.name : '';
    }
  };

  const locationDefaultValue = initialValues.location
    ? {
        label: getInitialLocationName(),
        value: getInitialLocationName(),
      }
    : undefined;

  const pageTitle = event ? t('events.form.editPageTitle') : t('events.form.createPageTitle');

  const showPublishers = institutionOptions?.length > 1;
  const { youtubeModal } = useYTVideoInEditor(JSON.parse(getValues('content')));

  const showImage = !previewVideos?.length;
  const showVideo = !previewImages?.length;

  useEffect(() => {
    if (!showImage) {
      setValue('images', []);
    }
  }, [showImage]);

  useEffect(() => {
    if (!showVideo) {
      setValue('videos', []);
    }
  }, [showVideo]);

  const minStartDate = roundMinutes(getValues('publishedAt') || utcToZonedTime(now, timezone));

  const { publisherChanged, onPublisherChange } = useChangePublisher({ userInstitutions });

  useEffect(() => {
    if (!publisherChanged) {
      return;
    }
    const newInitialCategoriesOptions = getInitialCategoriesOptions(
      categoriesOptions,
      event?.categories,
    );
    setValue('categories', newInitialCategoriesOptions);
    categoriesRef?.current?.setValue(newInitialCategoriesOptions);
    trigger('places');
  }, [publisherChanged]);

  const validUrlField = dirtyFields.marketUrl && !errors.marketUrl;

  return (
    <PageContentEditable
      showOnlyPreview={showOnlyPreview}
      previewTitle={t('events.preview.title')}
      preview={
        <EventPreview
          title={previewTitle}
          images={previewImages}
          lang={previewLang}
          videos={previewVideos}
          content={previewContent}
          location={previewLocation}
          startDate={previewStartDate}
          endDate={previewEndDate}
          publisher={previewPublisher}
        />
      }
    >
      {youtubeModal}
      <FormWrapper>
        <ActionButtons actionButtons={eventFormActions}>
          {!!event && event.publishmentState === State.Published && (
            <LinkDetailButton value={link} />
          )}
        </ActionButtons>
        <ContentHeader>{pageTitle}</ContentHeader>
        <FormProvider {...methods}>
          <FormInput
            label={t('events.form.title')}
            testId={'EventEdit#title'}
            {...register('title')}
            error={errors.title}
          />
          <FormDateTimePicker
            control={control}
            name="startDate"
            onChange={handleStartDateChange}
            dateLabel={t('events.form.startDateLabel')}
            timeLabel={t('events.form.startTimeLabel')}
            minDate={minStartDate}
            initialDate={initialValues.startDate || roundMinutes(initialPublishedAtDate)}
            initialTime={initialStartTime}
            initialDayPeriod={initialStartTimePeriod}
            testId={'EventStartDate#date'}
          />

          <HideableFormItem
            onClick={handleEndDateVisible}
            open={endDateVisible}
            label={t('events.form.endTimeButtonLabel')}
            testId={endDateVisible ? 'EventEndDate#hide' : 'EventEndDate#show'}
          >
            <FormDateTimePicker
              control={control}
              name="endDate"
              initialDate={initialEndDate}
              minDate={minEndDate || roundMinutes(utcToZonedTime(new Date(), timezone))}
              onChange={handleEndDateChange}
              dateLabel={t('events.form.endDateLabel')}
              timeLabel={t('events.form.endTimeLabel')}
              initialTime={minEndTime}
              initialDayPeriod={minEndPeriod}
              testId={'EventEndDate#date'}
            />
          </HideableFormItem>
          {showPublishers && (
            <>
              <FormSectionHeader
                title={t('updates.form.publisher')}
                description={t('updates.form.publisherDescription')}
              />
              <FormSelect
                control={control}
                name="publisher"
                error={get(errors, 'publisher.value')}
                options={institutionOptions || []}
                defaultValue={initialValues.publisher}
                testId="EventEdit#publisher"
                onChange={onPublisherChange}
              />
            </>
          )}
          {!!visitorMode && (
            <>
              <FormSectionHeader
                title={t('posts.form.category')}
                description={t('posts.form.categoryDescription')}
              />
              <FormSelect
                ref={categoriesRef}
                control={control}
                name="categories"
                error={get(errors, 'categories')}
                options={categoriesOptions}
                defaultValue={initialValues.categories}
                testId="EventEdit#categories"
                isMulti
              />

              <FormSectionHeader title={t('posts.form.marketUrl')} />
              <FormInput
                {...register('marketUrl')}
                error={errors.marketUrl}
                testId="EventEdit#marketUrl"
                placeholder={t('market.form.urlPlaceholder')}
                icon={validUrlField ? <SuccessOutlinedIcon /> : undefined}
                noMargin
              />
              {validUrlField && !!previewMarketUrl && (
                <a href={previewMarketUrl} target="_blank" rel="noreferrer">
                  <Button variant="tertiary" appendIcon={<ExternalLinkIcon />}>
                    {t('market.form.openUrl')}
                  </Button>
                </a>
              )}

              <FormSectionHeader
                title={t('posts.form.pricing')}
                description={t('posts.form.pricingDescription')}
              />
              <FormPrice
                name="marketItemPricing"
                testId="EventEdit#marketUrl#marketItemPricing"
                initialValue={initialPrice}
              />
            </>
          )}

          {shouldShowCitySelect && (
            <>
              <FormSectionHeader
                title={t('updates.form.cities')}
                description={t('events.form.citiesDescription')}
              />
              <FormSelect
                control={control}
                name="places"
                error={get(errors, 'places')}
                options={placesOptions || []}
                defaultValue={initialValues.places}
                testId="AnnouncementEdit#places"
                allSelectable
                clearable
                isMulti
              />
            </>
          )}
          <FormSectionHeader
            title={t('events.form.locationLabel')}
            description={t('events.form.locationSublabel')}
          />
          <FormSelect
            control={control}
            name="eventType"
            error={get(errors, 'eventType.value')}
            label={t('events.form.typeLabel')}
            options={eventTypeOptions}
            onChange={onEventTypeOptionChange}
            defaultValue={initialEventType}
            className={'eventsFormSelect'}
            subLabel={!locationInputVisible ? t('events.form.onlineEventInfo') : undefined}
            testId={'EventType#select'}
          />
          {locationInputVisible && (
            <FormLocationInput
              control={control}
              name="location"
              error={get(errors, 'location.name')}
              label={t('events.form.eventLocation')}
              placeholder={t('events.form.searchLocation')}
              onValueChange={onLocationValueChange}
              defaultValue={locationDefaultValue}
            />
          )}
          <FormSectionHeader
            title={t('events.form.descriptionLabel')}
            description={t('events.form.descriptionSublabel')}
          />
          <FormWysiwyg
            control={control}
            name="content"
            label={t('events.form.textLabel')}
            initialValue={JSON.parse(getValues('content'))}
            testId={'EventEdit#wysiwyg'}
          />
          <>
            <FormSectionHeader
              title={t('updates.form.language')}
              description={t('updates.form.languageDescription')}
            />
            <FormSelect
              control={control}
              name="lang"
              options={getLangOptions()}
              defaultValue={initialValues.lang}
              testId={'Announcement#languageSlect'}
              allSelectable
            />
          </>
          {showImage && (
            <FormImageCropperUpload
              initialValue={initialValues.images}
              title={t('events.form.imageLabel')}
              description={t('events.form.imageSublabel')}
              control={control}
              setValue={setValue}
              getValues={getValues}
              trigger={trigger}
              name="images"
              dropzoneLabel={t('events.form.dropzoneLabel')}
              t={t}
              multiple
              testId="EventEdit#image"
            />
          )}
          {showVideo && (
            <>
              <FormSectionHeader
                title={t('events.form.videoLabel')}
                description={t('events.form.videoSublabel')}
              />
              <FormUpload
                control={control}
                name="videos"
                dropzoneLabel={t('events.form.dropzoneLabel')}
                t={t}
                testId="EventEdit#video"
                accept={SUPPORTED_VIDEO_FORMATS}
              />
            </>
          )}
          <FormSectionHeader title={t('updates.form.publishedAt')} />
          <FormDateTimePicker
            control={control}
            name="publishedAt"
            initialDate={initialPublishedAtDate}
            onChange={onPublishedAtDateTimeChanged}
            dateLabel=""
            timeLabel=""
            initialTime={format(initialPublishedAtDate, 'hh:mm')}
            maxDate={roundMinutes(utcToZonedTime(now, timezone))}
            initialDayPeriod={format(initialPublishedAtDate, 'a')}
            testId="EventEdit#publishedAt"
            isTimePickerWritable
          />
          <FormButtonsWrapper>
            <Button size="smaller" variant="tertiary" onClick={onDiscard} disabled={isSubmitting}>
              {t('events.form.discard')}
            </Button>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors) || postsDisabled}
              disabledText={postsDisabled ? t('updates.form.disabledText') : undefined}
              testId={'PublishButton'}
            >
              {t('events.form.publish')}
            </Button>
          </FormButtonsWrapper>
        </FormProvider>
      </FormWrapper>
      <RouteLeavingGuard when={isDirty && !isSubmitting} />
    </PageContentEditable>
  );
};

export default EventEdit;
