import { Button, TabPanel, TTabPanelTabs } from '@sim-admin-frontends/ui-shared';
import { FC, useCallback, useMemo } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { State, useUnpublishAnnouncementMutation } from '@sim-admin-frontends/data-access';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';

import { changeRafflesTab } from '../../../utils/raffleUtils';
import ROUTES from '../../../routing/routes';
import { CtaWrapper } from '../../common/TabsStyles';
import { TAnnouncementsTabTypes } from '../../../types/TAnnouncements';
import { RafflesViewContainer } from './RafflesView.container';
import { useBulkUnpublish } from '../../../hooks/useBulkUnpublish';
import { AnalyticsEvent } from '../../../utils/analytics/analyticsEvents';

export const announcementsTabs = [
  TAnnouncementsTabTypes.PUBLISHED,
  TAnnouncementsTabTypes.UNPUBLISHED,
];

type Props = {
  filter: string;
};

const RaffleTabs: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    postsToUnpublish,
    onUnpublishSelect,
    renderBulkUnpublishButton,
    isUnpublishMutationSettled,
    clearSelection,
  } = useBulkUnpublish(useUnpublishAnnouncementMutation, AnalyticsEvent.RAFFLE_ACTION);

  const { setTabIndex, tabIndex } = useTabNavigation(
    ROUTES.rafflesOverview.path,
    announcementsTabs,
    clearSelection,
  );

  const getViewContainer = useCallback(
    (state: State) => (
      <RafflesViewContainer
        searchFilter={filter}
        state={state}
        postsToUnpublish={postsToUnpublish}
        onUnpublishSelect={onUnpublishSelect}
        isUnpublishMutationSettled={isUnpublishMutationSettled}
      />
    ),
    [filter, isUnpublishMutationSettled, onUnpublishSelect, postsToUnpublish],
  );

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: getViewContainer(State.Published),
        label: t('raffles.tabs.published'),
        testId: 'RafflesTabs#published',
      },
      {
        content: getViewContainer(State.Unpublished),
        label: t('raffles.tabs.unpublished'),
        testId: 'RafflesTabs#unpublished',
      },
    ],
    [getViewContainer, t],
  );

  const redirectToUnpublishedTab = () => {
    changeRafflesTab(history, TAnnouncementsTabTypes.UNPUBLISHED);
  };

  return (
    <TabPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex}>
      <CtaWrapper>
        {renderBulkUnpublishButton(redirectToUnpublishedTab)}
        <Link to={generatePath(ROUTES.rafflesEdit.path)}>
          <Button testId="RafflesTabs#AddRaffle">{t('raffles.addRaffle')}</Button>
        </Link>
      </CtaWrapper>
    </TabPanel>
  );
};

export default RaffleTabs;
