import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarAppIcon } from '@sim-admin-frontends/ui-shared';

import PreviewItemIconText, { RowText } from './PreviewItemIconText';
import { TEST_ID_PAGE_PREVIEW_PREFIX } from '../layout/pageContent/PageContentPreview';
import { getTestId } from '../../utils/testUtils';

type Props = {
  dateFrom?: string | null;
  timeFrom?: string | null;
  dateTo?: string | null;
  timeTo?: string | null;
  isSameDay: boolean;
  testIdPrefix?: string;
};

const ItemDate: FC<Props> = ({
  dateFrom,
  timeFrom,
  dateTo,
  timeTo,
  isSameDay,
  testIdPrefix = TEST_ID_PAGE_PREVIEW_PREFIX,
}) => {
  const { t } = useTranslation();

  const getDateElement = () => {
    // Only start date
    if (!dateTo && !timeTo)
      return (
        <>
          <RowText isFirst data-testid={getTestId(testIdPrefix, 'startDate')}>
            {dateFrom}
          </RowText>
          <RowText data-testid={getTestId(testIdPrefix, 'startTime')}>{`${t(
            'events.startsAt',
          )} ${timeFrom}`}</RowText>
        </>
      );

    // Start and end date with equal day
    if (isSameDay) {
      return (
        <>
          <RowText isFirst data-testid={getTestId(testIdPrefix, 'startDate')}>
            {dateFrom}
          </RowText>
          <RowText
            data-testid={getTestId(testIdPrefix, 'start-endTime')}
          >{`${timeFrom} - ${timeTo}`}</RowText>
        </>
      );
    }

    // Start and end date with different days
    // INFO: This case expects dateFrom and timeFrom to be always non-null, because admin doesn't allow
    // creation of event without them
    return (
      <>
        <RowText
          isFirst
          data-testid={getTestId(testIdPrefix, 'startDateTime')}
        >{`${dateFrom} ${timeFrom}`}</RowText>
        <RowText
          isFirst
          data-testid={getTestId(testIdPrefix, 'endDateTime')}
        >{`${dateTo} ${timeTo}`}</RowText>
      </>
    );
  };

  const dateElement = getDateElement();
  return (
    <PreviewItemIconText icon={<CalendarAppIcon />} testIdPrefix={testIdPrefix} testIdSuffix="Date">
      {dateElement}
    </PreviewItemIconText>
  );
};

export default ItemDate;
