import { FC } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useInstiutionRoleQuery } from '@sim-admin-frontends/data-access';
import { Error, Spinner, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { UpdateEditPageProps } from '../announcements/AnnouncementsEditPage';
import CityChatEditContainer from '../../components/cityChat/edit/CityChatEdit.container';
import { useAuthInfo } from '../../contexts/userContext';

export type Props = {
  id?: string;
};

const ChatEditPage: FC<RouteComponentProps<UpdateEditPageProps>> = ({ match }) => {
  const { institutionUuid } = useAuthInfo();
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useInstiutionRoleQuery({ id: institutionUuid });
  const isEnabled = data?.institution?.isChatEnabled;

  if (isLoading || !institutionUuid) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  if (!isEnabled) {
    return <Redirect to="/404" />;
  }

  return <CityChatEditContainer id={match?.params?.id} />;
};

export default ChatEditPage;
