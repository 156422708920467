/* eslint-disable @typescript-eslint/ban-ts-comment */
import { generatePath } from 'react-router-dom';
import { History, LocationState } from 'history';
import { TFunction } from 'react-i18next';
import {
  formatTableDate,
  formatTableDateTime,
  getSelectedMapArea,
  prepareFileObjects,
} from '@sim-admin-frontends/utils-shared';
import {
  AnnouncementInput,
  PostType,
  State,
  TUploadedFile,
} from '@sim-admin-frontends/data-access';
import { CellProps } from 'react-table';
import { TableHeaderTitle } from '@sim-admin-frontends/ui-shared';
import { serializeToString } from '@simplicity-tech/sim-slate-types';
import { zonedTimeToUtc } from 'date-fns-tz';

import ROUTES from '../routing/routes';
import { TAnnouncementsTabTypes, TAnnouncementTableItem } from '../types/TAnnouncements';
import { getDisabledColumns, getPublishingDateAccessor, parseRichText } from './announcementsUtils';
import { getUnpublishCheckbox } from './publishUtils';
import { TDonationFormValues, TDonationListItem, TDonationsColumns } from '../types/TDonations';
import { DonationsTableMenu } from '../components/donations/view/DonationsTableMenu';

export const changeDonationsTab = (
  history: History<LocationState>,
  type: TAnnouncementsTabTypes,
) => {
  history.push(
    generatePath(ROUTES.donationsOverview.path, {
      type,
    }),
  );
};

export const transformFormValues = (
  formData: TDonationFormValues,
  uploadedImages: TUploadedFile[] | undefined,
  uploadedVideos: TUploadedFile[] | undefined,
  defaultPostLang = '',
): AnnouncementInput => {
  const imageObjects = prepareFileObjects(uploadedImages);
  const videoObjects = prepareFileObjects(uploadedVideos);
  const areaOfInterest = getSelectedMapArea(formData.areaOfInterest);

  return {
    title: formData.title,
    content: formData.content,
    pushContent: serializeToString(formData.content),
    institutionUuid: formData.publisher?.value ?? '',
    categoryUuids: formData.categories?.map((category) => category.value),
    placeUuids: formData.places?.map((place) => place.value),
    lang: formData.lang?.value || defaultPostLang,
    imageObjects,
    videoObjects,
    scheduledAt: formData.scheduledAt?.toISOString(),
    notifications:
      formData.notifyNow && formData.notifications?.length && formData.notifications.length > 0
        ? formData.notifications?.map((notification) => notification.value.toISOString())
        : null,
    notifyAlsoNonCategoried: formData.notifyAlsoNonCategoried,
    notifyNow: formData.notifyNow,
    publishedAt: formData.scheduledAt ? undefined : formData.publishedAt?.toISOString(),
    areaOfInterest,
    donationCurrency: formData.donationCurrency.value,
    donationTargetAmount: parseFloat(formData.donationTargetAmount.replace(',', '.')),
    typename: PostType.Donation,
  };
};

export const createTableColumns = (
  t: TFunction,
  refetch: () => void,
  type: State,
  timeZone?: string,
  postsToUnpublish?: string[],
  onCheckboxChange?: (id: string) => () => void,
) => {
  const disabledColumns = getDisabledColumns(type);

  const columns: TDonationsColumns = [
    {
      Header: `${t('updates.table.title')}`,
      accessor: (item) => (
        <TableHeaderTitle
          item={item.title || parseRichText(item?.content ?? '')}
          maxTextLength={100}
        />
      ),
      testId: 'DonationsTable#title',
      disabled: disabledColumns.includes('title'),
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('updates.table.published')}`,
      // @ts-ignore - No no typescript i know better - hopefully :)
      accessor: getPublishingDateAccessor(type),
      testId: 'DonationsTable#publicationDate',
      Cell: disabledColumns.includes('publishedAt')
        ? ''
        : ({ value }: any) => (value ? formatTableDateTime(value, timeZone) : ''),
      disabled: disabledColumns.includes('publishedAt'),
      disableSortBy: disabledColumns.includes('publishedAt'),
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('updates.table.lastUpdate')}`,
      accessor: 'updatedAt',
      testId: 'DonationsTable#lastUpdate',
      Cell: disabledColumns.includes('updatedAt')
        ? ''
        : ({ value }) => (value ? formatTableDate(value) : ''),
      disabled: disabledColumns.includes('updatedAt'),
      disableSortBy: disabledColumns.includes('updatedAt'),
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('donations.table.raisedAmount')}`,
      accessor: 'donationRaisedAmount',
      testId: 'DonationsTable#raisedAmount',
      disableSortBy: true,
      skeleton: {
        width: 70,
      },
    },
    {
      Header: `${t('donations.table.targetAmount')}`,
      accessor: 'donationTargetAmount',
      testId: 'DonationsTable#targetAmount',
      disableSortBy: true,
      skeleton: {
        width: 70,
      },
    },
    {
      Header: '',
      id: '0',
      testId: 'DonationsTable#dots',
      disableSortBy: true,
      minWidth: 100,
      skeleton: {
        width: 50,
        align: 'right',
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ row }: CellProps<TAnnouncementTableItem>) => {
        const isPublished = row.original.publishmentState === State.Published;
        const isFromAdmin = row.original.createdByAdmin;
        return (
          <DonationsTableMenu
            type={type}
            isFromAdmin={isFromAdmin}
            postId={row.original.id}
            published={isPublished}
            refetch={refetch}
            testId={`DonationsView#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  getUnpublishCheckbox<TDonationListItem, TDonationsColumns>(
    columns,
    'DonationsTable',
    postsToUnpublish,
    onCheckboxChange,
  );

  return columns;
};

export const prepareFormValues = (
  values: TDonationFormValues,
  isScheduling: boolean,
  timezone: string,
) => {
  return {
    ...values,
    lang: values.lang,
    scheduledAt:
      isScheduling && values.scheduledAt ? zonedTimeToUtc(values.scheduledAt, timezone) : undefined,
    notifications: values.notifications?.map((notification) => ({
      value: zonedTimeToUtc(notification.value, timezone),
    })),
    publishedAt: values.publishedAt ? zonedTimeToUtc(values.publishedAt, timezone) : undefined,
  };
};
