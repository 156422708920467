import { Control, useFieldArray } from 'react-hook-form';
import styled from 'styled-components';
import { addMinutes, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { roundMinutes } from '@sim-admin-frontends/utils-shared';
import { Button, FormDateTimePicker, PlusSmallIcon } from '@sim-admin-frontends/ui-shared';

import { TAnnouncementFormValues } from '../../../types/TAnnouncements';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { FALLBACK_TIMEZONE } from '../../../constants/Constants';

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type AnnouncementNotificationSettingsProps = {
  control: Control<TAnnouncementFormValues>;
};

const INITIAL_MINUTES_ADDED = 15;

const AnnouncementNotificationSettings = ({ control }: AnnouncementNotificationSettingsProps) => {
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;

  const name = 'notifications';

  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });

  const onAddClick = () => {
    const now = roundMinutes(
      utcToZonedTime(addMinutes(new Date(), INITIAL_MINUTES_ADDED), timezone),
    );
    append({ value: now });
  };

  return (
    <div>
      {fields.map((field, index) => {
        const now = roundMinutes(utcToZonedTime(new Date(), timezone));

        return (
          <ItemWrapper key={field.id}>
            <FormDateTimePicker
              control={control}
              name={`${name}.${index}.value`}
              onCloseClick={() => remove(index)}
              minDate={now}
              initialDate={field.value}
              initialTime={format(field.value, 'hh:mm')}
              initialDayPeriod={format(field.value, 'a')}
              isDisabled={field.value < now}
              testId="AnnouncementNotification#date"
            />
          </ItemWrapper>
        );
      })}
      <Button
        variant="tertiary"
        size="smallest"
        onClick={onAddClick}
        prependIcon={<PlusSmallIcon />}
        testId="AnnouncementNotification#add"
      >
        {t('updates.form.addNotification')}
      </Button>
    </div>
  );
};

export { AnnouncementNotificationSettings };
