import { useInstitutionInfoQuery } from '@sim-admin-frontends/data-access';
import {
  fetcher,
  GeneratePostDocument,
  GeneratePostQuery,
  GeneratePostQueryVariables,
} from '@sim-admin-frontends/data-access-admin-be';
import { useTranslation } from 'react-i18next';

import { useAuthInfo } from '../contexts/userContext';
import { TGeneratorPostType } from '../types/TPostGenerator';

const usePostGenerator = () => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();

  const {
    data: institutionData,
    refetch,
    isLoading,
    isError,
  } = useInstitutionInfoQuery({
    id: institutionUuid || '',
  });

  const institutionName = institutionData?.institution?.name || '';
  const institutionAddress = institutionData?.institution?.info?.location?.address || '';

  const getNameFromType = (type?: TGeneratorPostType) => {
    if (!type) {
      return '';
    }

    const map = {
      [TGeneratorPostType.PRESS_RELEASE]: t('postGenerator.form.postType.pressRelease'),
      [TGeneratorPostType.ANNOUNCEMENT]: t('postGenerator.form.postType.announcement'),
      [TGeneratorPostType.TWITTER_POST]: t('postGenerator.form.postType.twitterPost'),
    };

    return map[type];
  };

  const fetchPost = async (specification: string, postType?: TGeneratorPostType) => {
    const fetcherFn = await fetcher<GeneratePostQuery, GeneratePostQueryVariables>(
      GeneratePostDocument,
      {
        institutionAddress,
        institutionName,
        postType: getNameFromType(postType),
        specification,
      },
    );

    const res = await fetcherFn();
    return res;
  };

  return { isError, isLoading, refetch, fetchPost };
};

export default usePostGenerator;
