import { TWysiwygValue } from '@simplicity-tech/sim-slate-types';
import { Controller, FieldValues } from 'react-hook-form';

import { TFormControlElement } from '../../../types/TForm';
import { Wysiwyg, WysiwygProps } from '../../wysiwyg/Wysiwyg';
import { FormError } from '../form-error/FormError';
import { FormItemLabel, FormItemWrapper } from '../formStyles';

interface Props<T> extends WysiwygProps, TFormControlElement<T> {
  label?: string;
  disabled?: boolean;
}

const FormWysiwyg = <T extends FieldValues>({
  label,
  testId,
  control,
  name,
  disabled,
  onValueChanged,
  ...rest
}: Props<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: onChangeField, value }, fieldState: { error } }) => {
        const onChangeMerged = (newValue: TWysiwygValue) => {
          const stringified = JSON.stringify(newValue);
          if (value !== stringified) {
            onChangeField(stringified);
          }
          if (onValueChanged) onValueChanged(newValue);
        };
        return (
          <FormItemWrapper>
            {label && <FormItemLabel testId={`${testId}-label`}>{label}</FormItemLabel>}
            <Wysiwyg
              {...rest}
              testId={testId}
              disabled={disabled}
              invalid={!!error}
              onValueChanged={onChangeMerged}
            />
            {error && <FormError error={error} testId={`${testId}-error`} />}
          </FormItemWrapper>
        );
      }}
    />
  );
};

export { FormWysiwyg };
