export type TPostAIFormValues = {
  input: string;
  output?: string;
  postType?: TGeneratorPostType;
};

export enum TGeneratorPostType {
  PRESS_RELEASE,
  ANNOUNCEMENT,
  TWITTER_POST,
}
