import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateReportProblemActions } from '../../../hooks/actionButtons/useGenerateReportProblemActions';

export interface ReportProblemTableMenuProps extends TBaseProps {
  problemId: string;
  testId: string;
}

const ReportProblemTableMenu: FC<ReportProblemTableMenuProps> = ({ problemId, testId }) => {
  const { listActions: items } = useGenerateReportProblemActions({ id: problemId });

  return <TableIcons testId={testId} items={items} />;
};

export default ReportProblemTableMenu;
