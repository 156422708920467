/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CellProps } from 'react-table';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import { formatTableDateTime } from '@sim-admin-frontends/utils-shared';
import { InferenceStatus, TModeratorListItem } from '@sim-admin-frontends/data-access';
import { TExtendedColumn } from '@sim-admin-frontends/ui-shared';

import ModeratorTableMenu from './ModeratorTableMenu';
import { getCategoryType } from '../../../utils/moderatorUtils';
import ModeratorHatespeechCell from './ModeratorHatespeechCell';

const StyledHeader = styled.div`
  flex: 1;
  align-items: flex-end;
  padding-right: ${({ theme }) => theme.spaces.spacing12};
  text-align: right;
`;

export const createTableColumns = (t: TFunction, refetch: () => void, timeZone?: string) => {
  const columns: TExtendedColumn<TModeratorListItem>[] = [
    {
      title: (value: string) => value,
      Header: `${t('moderator.table.title')}`,
      accessor: 'title',
      testId: 'ModeratorTable#title',
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('moderator.table.published')}`,
      accessor: 'publishedAt',
      testId: 'ModeratorTable#published',
      Cell: ({ value }) => (value ? formatTableDateTime(value, timeZone) : ''),
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('moderator.table.category')}`,
      accessor: 'categories',
      testId: 'ModeratorTable#category',
      Cell: ({ value }) =>
        value ? t(`moderator.postType.${getCategoryType(value).toLowerCase()}s`) : '',
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      id: 'reports',
      Header: `${t('moderator.table.reports')}`,
      accessor: 'installationInteraction',
      Cell: ({ value }) => <ModeratorHatespeechCell data={value} />,
      testId: 'ModeratorTable#reports',
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      id: 'result',
      Header: `${t('moderator.table.prediction')}`,
      accessor: 'aiMetadata',
      testId: 'ModeratorTable#prediction',
      Cell: ({ value }) =>
        value?.hatespeech?.probability && value?.hatespeech?.status === InferenceStatus.Done
          ? Math.round(value?.hatespeech?.probability) + '%'
          : '-',
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      id: 'status',
      Header: `${t('moderator.table.status')}`,
      accessor: 'aiMetadata',
      testId: 'ModeratorTable#status',
      Cell: ({ value }) =>
        value?.hatespeech?.status
          ? t(`moderator.hateSpeechStatus.${value?.hatespeech?.status.toLowerCase()}`)
          : '-',
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      id: 'Moderation',
      Header: () => <StyledHeader>{t('moderator.table.moderation')}</StyledHeader>,
      testId: 'ModeratorTable#menu',
      disableSortBy: true,
      skeleton: {
        width: 50,
        align: 'right',
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ row }: CellProps<TModeratorListItem>) => {
        return (
          <ModeratorTableMenu
            postId={row.original.id}
            postModeration={row.original.moderationCategory}
            refetch={refetch}
            testId={`ModeratorView#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  return columns;
};
