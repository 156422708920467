import {
  DEFAULT_RADIUS_METERS_NUMBER,
  MapAreaCircle,
  MapAreaPolygon,
  MapAreaSelect,
} from '@sim-admin-frontends/ui-shared';
import { TGeoShape, TMapCenter, TSelectedMapAreaShape } from '@sim-admin-frontends/utils-shared';

import { useMapAreaSelect } from '../../../hooks/useMapAreaSelect';
import {
  generatePolygonPaths,
  getMeters,
  getPolygonCenter,
  isCircleGeoShape,
} from '../../../utils/mapUtils';

type Props = {
  initalValue: TGeoShape | null;
  currentValue: TGeoShape | null;
  onChange: (value: TGeoShape | null) => void;
  center: TMapCenter;
};

const AnnouncementAreaOfInterest = ({ initalValue, currentValue, onChange, center }: Props) => {
  const isCircle = isCircleGeoShape(currentValue);
  const polygonCenter = getPolygonCenter((currentValue?.coordinates ?? [])[0]);
  const polygonPaths =
    !isCircleGeoShape(currentValue) && generatePolygonPaths(currentValue?.coordinates ?? []);
  const areaRadius = isCircleGeoShape(currentValue) && getMeters(currentValue?.radius);

  const { onLoadPolygon, onEditPath, reset, changeCircleToPolygon } = useMapAreaSelect(
    onChange,
    initalValue,
    center,
  );

  return (
    <MapAreaSelect
      center={polygonCenter || center}
      env={process.env.NX_GOOGLE_MAPS_IA_API_KEY || ''}
      onReset={reset}
      isResetButtonVisible={currentValue?.type === TSelectedMapAreaShape.POLYGON}
    >
      {isCircle ? (
        <MapAreaCircle
          center={center}
          radius={areaRadius || DEFAULT_RADIUS_METERS_NUMBER}
          onClick={() => changeCircleToPolygon(currentValue)}
        />
      ) : (
        <MapAreaPolygon
          editable
          draggable
          paths={polygonPaths || []}
          onMouseUp={onEditPath}
          onLoad={onLoadPolygon}
        />
      )}
    </MapAreaSelect>
  );
};

export default AnnouncementAreaOfInterest;
