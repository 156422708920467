import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'navigator', 'htmlTag'],
    },
    load: 'languageOnly',
    supportedLngs: [
      'en',
      'uk',
      'sk',
      'es',
      'ru',
      'fil',
      'fr',
      'ar',
      'ur',
      'ko',
      'bn',
      'pl',
      'zh',
      'zh-TW',
      'ht',
    ],
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v3',
  });

export const getAppLang = () => i18n.language.slice(0, 2).toLowerCase();

export default i18n;
