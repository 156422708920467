import { captureException } from '@sentry/react';
import {
  AddAnnouncementMutationVariables,
  CategoryType,
  isRaffle,
  State,
  UploadType,
  useCategoriesQuery,
  useInstitutionQuery,
  useRaffleQuery,
  useUserInstitutionsQuery,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { getDefaultLangCode, useUploadImage } from '@sim-admin-frontends/utils-shared';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ANNOUNCEMENT_TOAST_SHARED_ID } from '../../../constants/Constants';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import { useGenerateRaffleActions } from '../../../hooks/actionButtons/useGenerateRaffleActions';
import { useRaffleSubmit } from '../../../hooks/useRaffleSubmit';
import ROUTES from '../../../routing/routes';
import { TRaffleFormValues } from '../../../types/TRaffles';
import { findRaffleCategory, transformFormValues } from '../../../utils/raffleUtils';
import RaffleEdit from './RaffleEdit';

type Props = {
  id?: string;
  showOnlyPreview?: boolean;
};

const RaffleEditContainer: FC<Props> = ({ id, showOnlyPreview }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { uploadFormImages } = useUploadImage();
  const { institutionUuid, user } = useAuthInfo();
  const { places } = usePlaceInfo();
  const defaultPostLang = getDefaultLangCode(places?.[0]?.countryCode);

  const {
    data,
    isLoading: isRaffleLoading,
    isError: isRaffleError,
    refetch,
  } = useRaffleQuery({ id: id || '' }, { enabled: !!id });

  const raffleData = isRaffle(data?.announcement) ? data?.announcement : undefined;

  const {
    data: categories,
    isLoading: categoriesIsLoading,
    isError: isCategoriesError,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.Raffle,
    },
  });

  const {
    data: initialInstitution,
    isLoading: isIntitutionLoading,
    isError: isInstitutionError,
  } = useInstitutionQuery({
    id: institutionUuid || '',
  });

  const {
    data: userData,
    isLoading: isLoadingInstitutions,
    isError: isErrorInstitutions,
  } = useUserInstitutionsQuery({
    username: user?.username || '',
  });

  const { handleSubmitAnnouncement } = useRaffleSubmit();
  const raffleCategory = useMemo(() => findRaffleCategory(categories), [categories]);

  const onDiscard = () => {
    history.push(ROUTES.rafflesOverview.path);
  };

  const onSubmit = async (formValues: TRaffleFormValues) => {
    try {
      loadingToast(t('raffles.form.toastSubmitting'), {
        toastId: ANNOUNCEMENT_TOAST_SHARED_ID,
      });

      const publisherId = formValues.publisher?.value ?? '';

      const uploadedImages = await uploadFormImages(UploadType.Announcement, formValues.bgImage, {
        institutionId: publisherId,
      });

      const mutationVariables: AddAnnouncementMutationVariables = {
        announcement: transformFormValues(
          formValues,
          uploadedImages,
          raffleCategory,
          defaultPostLang,
        ),
      };

      await handleSubmitAnnouncement(raffleData?.publishmentState, mutationVariables, id);
    } catch (err) {
      updateToast(ANNOUNCEMENT_TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const { detailActions, renderModal } = useGenerateRaffleActions({
    raffleId: id || '',
    refetch,
    published: raffleData?.publishmentState === State.Published,
    isEditPage: true,
  });

  const isLoading =
    categoriesIsLoading || isRaffleLoading || isIntitutionLoading || isLoadingInstitutions;
  const isError = isCategoriesError || isRaffleError || isInstitutionError || isErrorInstitutions;

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  if (isError) return <Error caption={t('error.fetchingDataError')} onClick={refetch} />;

  const institutionOptions = userData?.adminUser?.institutions || [];

  return (
    <>
      <RaffleEdit
        showOnlyPreview={showOnlyPreview}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        raffle={raffleData}
        institution={initialInstitution?.institution}
        userInstitutions={institutionOptions}
        raffleDetailActions={id ? detailActions : []}
      />
      {renderModal()}
    </>
  );
};

export default RaffleEditContainer;
