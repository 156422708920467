import styled from 'styled-components';
import { useUserInstitutionsQuery } from '@sim-admin-frontends/data-access';
import { Spinner } from '@sim-admin-frontends/ui-shared';

import UserPrefsHeader from './UserPrefsHeader';
import { useAuthInfo } from '../../../contexts/userContext';

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const UserPrefsHeaderContainer = () => {
  const { user } = useAuthInfo();
  const {
    data: userData,
    isLoading,
    error,
  } = useUserInstitutionsQuery({
    username: user?.username || '',
  });

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  const institutions = userData?.adminUser?.institutions || [];

  return <UserPrefsHeader hasError={!!error} institutions={institutions} />;
};

export default UserPrefsHeaderContainer;
