import { FC } from 'react';
import {
  Error,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  getErrorMessage,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  PlaceQuery,
  usePlaceQuery,
  useSetPlaceInfoMutation,
} from '@sim-admin-frontends/data-access-admin-be';
import { useQueryClient } from 'react-query';
import { transformEmptyHelper } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { TPlaceInfoFormValues } from '../../../types/TPlace';
import SettingsAboutCity from './SettingsAboutCity';
import { useAuthInfo } from '../../../contexts/userContext';

const SHARED_TOAST_ID = 'submitAboutCity';

const SettingsAboutCityContainer: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { placeUuid } = useAuthInfo();
  const queryClient = useQueryClient();
  const placeId = placeUuid || '';

  const { data, isLoading, isError, refetch } = usePlaceQuery({
    id: placeId,
  });
  const placeQueryKey = usePlaceQuery.getKey({
    id: placeId,
  });

  const { mutateAsync: setPlaceInfo } = useSetPlaceInfoMutation();

  const goHome = () => {
    history.push(ROUTES.home.path);
  };

  const onSubmit = async (formValues: TPlaceInfoFormValues) => {
    try {
      loadingToast(t('aboutCity.toastSubmitting'), {
        toastId: SHARED_TOAST_ID,
      });

      const prevData = queryClient.getQueryData<PlaceQuery>(placeQueryKey);

      await setPlaceInfo({
        id: placeId,
        info: {
          description: transformEmptyHelper(formValues.description),
          about:
            formValues.about && formValues.about !== SERIALIZED_WYSIWYG_EMPTY_VALUE
              ? formValues.about
              : null,
          aboutHeader: transformEmptyHelper(formValues.aboutHeader),
          reportsEmail: transformEmptyHelper(formValues.reportsEmail),
        },
      });

      if (prevData && prevData.place) {
        const optimisticUpdate: PlaceQuery = {
          ...prevData,
          place: {
            ...prevData.place,
            info: {
              ...prevData.place?.info,
              ...formValues,
            },
          },
        };

        queryClient.setQueryData(placeQueryKey, optimisticUpdate);
      }
      // ToDo - add query invalidation once caching problem is fixed on BE
      updateToast(SHARED_TOAST_ID, t('aboutCity.toastSuccess'), TToastType.SUCCESS, {
        autoClose: false,
      });
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  if (isError || !data?.place)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  return <SettingsAboutCity place={data?.place} onSubmit={onSubmit} onCancel={goHome} />;
};

export default SettingsAboutCityContainer;
