import { FieldError } from 'react-hook-form';
import { FC, forwardRef, InputHTMLAttributes, ReactElement, Ref } from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { TBaseProps } from '../../../types/TBaseComponent';
import { Input } from '../../input/Input';
import { FormError } from '../form-error/FormError';
import { FormItemLabel, FormItemWrapper } from '../formStyles';
import { CircleCross, InfoIcon } from '../../../index';

const DeleteButton = styled.div`
  position: absolute;
  right: -${({ theme }) => theme.spaces.spacing8};
  top: 13px; // 16px - 6px (label) + 3px (label/2 for centering)
`;

const Icon = styled(InfoIcon)`
  margin-left: ${({ theme }) => theme.spaces.spacing4};
`;

const LabelWrapper = styled.div`
  display: flex;
`;

export interface FormInputProps extends InputHTMLAttributes<HTMLInputElement>, TBaseProps {
  label?: string;
  placeholder?: string;
  info?: string;
  ref?: Ref<HTMLInputElement>;
  error?: FieldError;
  icon?: ReactElement;
  noMargin?: boolean;
  onDeleteClick?: () => void;
}

const FormInput: FC<FormInputProps> = forwardRef(
  ({ label, testId, error, noMargin, onDeleteClick, info, ...inputProps }, ref) => {
    return (
      <FormItemWrapper noMargin={noMargin}>
        <LabelWrapper>
          {!!label && <FormItemLabel testId={`${testId}-label`}>{label}</FormItemLabel>}
          {!!info && (
            <Tippy placement="bottom" content={info}>
              <Icon />
            </Tippy>
          )}
        </LabelWrapper>
        <Input {...inputProps} testId={testId} ref={ref} invalid={!!error} />
        {!!onDeleteClick && (
          <DeleteButton data-testid="QuickQuestion#deleteButton" onClick={onDeleteClick}>
            <CircleCross />
          </DeleteButton>
        )}
        {error && <FormError error={error} testId={`${testId}-error`} />}
      </FormItemWrapper>
    );
  },
);

FormInput.displayName = 'FormInput';

export { FormInput };
