import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OpenAiIcon } from '@sim-admin-frontends/ui-shared';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
`;

const SmallText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.smallest};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.colors.coolGray70};
  margin-right: ${({ theme }) => theme.spaces.spacing16};
`;

const MediumText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.caption1};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.green70};
  margin-left: ${({ theme }) => theme.spaces.spacing8};
`;

const OpenAIBadge = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  border: 1px solid ${({ theme }) => theme.colors.green70};
  background-color: ${({ theme }) => theme.colors.green10};
  padding: ${({ theme }) => theme.spaces.spacing12} ${({ theme }) => theme.spaces.spacing16};
  padding-right: ${({ theme }) => theme.spaces.spacing24};
`;

const PostGeneratorBadge = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SmallText> {t('updates.form.enhancedAI')}</SmallText>
      <OpenAIBadge>
        <OpenAiIcon />
        <MediumText>{t('postGenerator.form.banner.openAi')}</MediumText>
      </OpenAIBadge>
    </Wrapper>
  );
};

export default PostGeneratorBadge;
