import { useEffect } from 'react';

const useScript = (url: string, onAfterLoad?: () => void) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;
    if (onAfterLoad) {
      script.addEventListener('load', onAfterLoad);
    }
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;
