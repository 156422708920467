import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { captureException } from '@sentry/react';
import {
  AddAnnouncementMutationVariables,
  CategoryType,
  State,
  UploadType,
  useCategoriesQuery,
  useMarketItemDetailQuery,
  useInstitutionInfoQuery,
  useInstitutionQuery,
  useUserInstitutionsQuery,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import {
  filterCategories,
  getDefaultLangCode,
  useUploadImage,
} from '@sim-admin-frontends/utils-shared';

import { TAnnouncementsFormTypes, TAnnouncementsTabTypes } from '../../../types/TAnnouncements';
import { useAuthInfo } from '../../../contexts/userContext';
import { usePlaceInfo } from '../../../contexts/placeContext';
import NotFound from '../../../routing/NotFound';
import { useAnnouncementSubmit } from '../../../hooks/useAnnouncementSubmit';
import { ANNOUNCEMENT_TOAST_SHARED_ID } from '../../../constants/Constants';
import { changeMarketTab, transformFormValues } from '../../../utils/marketUtils';
import { useGenerateAnnoucementActions } from '../../../hooks/actionButtons/useGenerateAnnoucementActions';
import { isMarketDetail, TMarketFormValues } from '../../../types/TMarket';
import MarketEdit from './MarketEdit';
import ROUTES from '../../../routing/routes';

type Props = {
  id?: string;
};

const MarketEditContainer: FC<Props> = ({ id }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user, institutionUuid, isLocalBusiness: isLocalBusinessInitial } = useAuthInfo();
  const { places } = usePlaceInfo();
  const { uploadFormImages } = useUploadImage();
  const [isLocalBusiness] = useState(isLocalBusinessInitial);
  const defaultPostLang = getDefaultLangCode(places?.[0].countryCode || '');

  const { handleSubmitAnnouncement } = useAnnouncementSubmit(TAnnouncementsFormTypes.MARKET);

  const {
    data,
    isLoading: isAnnouncementLoading,
    error: announcementError,
    refetch,
  } = useMarketItemDetailQuery({ id: id || '' }, { enabled: !!id });

  const marketItem = isMarketDetail(data?.announcement) ? data?.announcement : undefined;

  const {
    isLoading: areCategoriesLoading,
    error: categoriesError,
    data: categoriesOfficial,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.Market,
      visitorMode: true,
    },
  });

  const {
    data: initialInstitution,
    isLoading: isInstitutionsLoading,
    error: institutionError,
  } = useInstitutionQuery({
    id: institutionUuid || '',
  });

  const {
    data: institutionInfoData,
    isLoading: isInstitutionInfoLoading,
    isError: isInstitutionInfoError,
  } = useInstitutionInfoQuery({
    id: institutionUuid,
  });

  const institutionGeolocation = institutionInfoData?.institution?.info?.location?.geo;
  const institutionCenter = {
    lat: institutionGeolocation?.lat || 0,
    lng: institutionGeolocation?.lon || 0,
  };

  const onDiscard = () => {
    changeMarketTab(history, TAnnouncementsTabTypes.PUBLISHED);
  };

  const onSubmit = async (formValues: TMarketFormValues) => {
    try {
      loadingToast(t('updates.form.toastSubmitting'), {
        toastId: ANNOUNCEMENT_TOAST_SHARED_ID,
      });
      const publisherId = formValues.publisher?.value ?? '';
      const uploadedImages = await uploadFormImages(UploadType.News, formValues.images, {
        institutionId: publisherId,
      });
      const mutationVariables: AddAnnouncementMutationVariables = {
        announcement: transformFormValues(formValues, uploadedImages, defaultPostLang),
      };
      await handleSubmitAnnouncement(marketItem?.publishmentState, mutationVariables, id);
    } catch (err) {
      updateToast(ANNOUNCEMENT_TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const {
    data: userData,
    isLoading: isLoadingInstitutions,
    isError: isErrorInstitutions,
  } = useUserInstitutionsQuery({
    username: user?.username || '',
  });

  const isLoading =
    isAnnouncementLoading ||
    areCategoriesLoading ||
    isInstitutionsLoading ||
    isLoadingInstitutions ||
    isInstitutionInfoLoading;

  const isError =
    announcementError ||
    categoriesError ||
    institutionError ||
    isErrorInstitutions ||
    isInstitutionInfoError;

  const categories = useMemo(() => {
    if (isLocalBusiness) {
      return [];
    }
    return filterCategories(categoriesOfficial?.categoriesBy.categories || [], {
      filterLegacy: true,
    });
  }, [categoriesOfficial, isLocalBusiness]);

  const isFromAdmin = marketItem?.createdByAdmin;
  const published = marketItem?.publishmentState === State.Published;
  const type = marketItem?.publishmentState || State.Published;
  const postId = marketItem?.id || '';

  const { detailActions, renderModal: renderActionModal } = useGenerateAnnoucementActions({
    isFromAdmin,
    type,
    published,
    postId,
    refetch,
    isEditPage: true,
    settings: {
      translationKey: 'market',
      testIdKey: 'Market',
      editPagePath: ROUTES.marketEdit.path,
      changeTab: changeMarketTab,
    },
  });

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  if (isError)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  if (id && !marketItem)
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );

  const institutionOptions = userData?.adminUser?.institutions || [];

  return (
    <>
      <MarketEdit
        user={user}
        isLocalBusiness={isLocalBusiness}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        marketItem={marketItem}
        categories={categories}
        institution={initialInstitution?.institution}
        userInstitutions={institutionOptions}
        institutionCenter={institutionCenter}
        marketFormActions={id ? detailActions : []}
      />
      {renderActionModal()}
    </>
  );
};

export default MarketEditContainer;
