/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { FC, ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';
import ReactAsyncSelect from 'react-select/async';
import { components, DropdownIndicatorProps, OptionProps } from 'react-select';

import { TBaseProps, TSelectItem, TSelectItems } from '../../..';
import { ReactComponent as Search } from '../../../assets/img/search.svg';
import { SelectStyles } from '../SelectStyles';

const SearchIcon = styled(Search)`
  position: absolute;
  z-index: 1;
  top: ${({ theme }) => theme.spaces.spacing8};
  left: ${({ theme }) => theme.spaces.spacing8};
`;

const OptionIconWrapper = styled.div`
  position: absolute;
  top: 2px;
  left: ${({ theme }) => theme.spaces.spacing8};
`;

const OptionWrapper = styled.div<{ hasIcon: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: ${({ theme }) => theme.spaces.spacing8};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: Manrope, serif;
  color: ${({ theme }) => theme.colors.coolGray100};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  padding: 10px ${({ theme }) => theme.spaces.spacing8};
  ${(props) => (props.hasIcon ? 'padding-left: 40px' : '')};
  :hover {
    background-color: ${({ theme }) => theme.colors.coolGray10};
  }
`;

const Wrapper = styled(SelectStyles)`
  & .react-select__placeholder,
  .react-select__menu-notice {
    font-size: ${({ theme }) => theme.fontSizes.caption1};
    line-height: ${({ theme }) => theme.lineHeights.caption1};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-family: Manrope, serif;
    color: ${({ theme }) => theme.colors.lightText};
  }
  & .react-select__control {
    padding-left: 40px;
  }
`;

const DropdownIndicator = (props: DropdownIndicatorProps<TSelectItem, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <></>
    </components.DropdownIndicator>
  );
};

const CustomOption: FC<any> = (props) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    optionIcon,
  } = props;
  return (
    <>
      <OptionWrapper
        hasIcon={!!optionIcon}
        ref={innerRef}
        className={cx(
          css(getStyles('option', props)),
          {
            option: true,
            'option--is-disabled': isDisabled,
            'option--is-focused': isFocused,
            'option--is-selected': isSelected,
          },
          className,
        )}
        {...innerProps}
      >
        <OptionIconWrapper>{optionIcon}</OptionIconWrapper>
        {children}
      </OptionWrapper>
    </>
  );
};

CustomOption.displayName = 'AsyncOption';

export interface AsyncSelectProps extends TBaseProps {
  noOptionsLabel?: string;
  placeholder?: string;
  onError?: (error: Error) => void;
  fetchData: (text: string) => Promise<TSelectItems>;
  onItemSelected: (item: TSelectItem | null) => void;
  optionIcon?: ReactElement;
  defaultValue?: TSelectItem;
}

const AsyncSelect: FC<AsyncSelectProps> = ({
  noOptionsLabel,
  placeholder,
  onError,
  fetchData,
  onItemSelected,
  optionIcon,
  defaultValue,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const loadItems = async (text: string) => {
    try {
      setIsLoading(true);
      const items = await fetchData(text);
      setIsLoading(false);
      return items;
    } catch (e) {
      onError && onError(e as Error);
    }
    return [];
  };

  return (
    <Wrapper>
      <SearchIcon />
      <ReactAsyncSelect
        className={'react-select-container'}
        classNamePrefix={'react-select'}
        components={{
          DropdownIndicator,
          Option: function Option(optionProps: OptionProps<TSelectItem, false>) {
            return <CustomOption optionIcon={optionIcon} {...optionProps} />;
          },
        }}
        loadOptions={loadItems}
        isLoading={isLoading}
        defaultValue={defaultValue}
        noOptionsMessage={() => noOptionsLabel || 'No results'}
        placeholder={placeholder || 'Search location'}
        onChange={(item: TSelectItem | null) => onItemSelected(item)}
      />
    </Wrapper>
  );
};

export { AsyncSelect };
