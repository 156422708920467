import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TCustomElement } from '@simplicity-tech/sim-slate-types';
import { usePlaceQuery } from '@sim-admin-frontends/data-access-admin-be';
import { SERIALIZED_WYSIWYG_EMPTY_VALUE } from '@sim-admin-frontends/ui-shared';

import RichTextRenderer from '../../richText/RichTextRenderer';
import { useAuthInfo } from '../../../contexts/userContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  position: relative;
  padding: 0 ${({ theme }) => theme.spaces.spacing16};
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spaces.spacing32};
`;

const CityLogo = styled.img`
  padding: 0 ${({ theme }) => theme.spaces.spacing24};
  width: 120px;
`;

const CityName = styled.div<{ hasContent: boolean }>`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  color: ${({ theme, hasContent }) =>
    hasContent ? theme.colors.coolGray100 : theme.colors.coolGray30};
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  word-wrap: break-word;
`;

const Description = styled.div<{ hasContent: boolean }>`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  color: ${({ theme, hasContent }) =>
    hasContent ? theme.colors.coolGray100 : theme.colors.coolGray30};
  font-size: ${({ theme }) => theme.fontSizes.preview};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  word-wrap: break-word;
`;

const OtherInfo = styled.div<{ hasContent: boolean }>`
  color: ${({ theme, hasContent }) =>
    hasContent ? theme.colors.coolGray100 : theme.colors.coolGray30};
  font-size: ${({ theme }) => theme.fontSizes.preview};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  flex: 1;
  margin-top: ${({ theme }) => theme.spaces.spacing32};
  word-wrap: break-word;
`;

type Props = {
  cityName: string;
  information?: string;
  description?: string;
};

const SettingsAboutCityPreview: FC<Props> = ({ cityName, description, information }) => {
  const { t } = useTranslation();
  const isContentEmpty = information === SERIALIZED_WYSIWYG_EMPTY_VALUE;
  const informationPlaceholder = t('aboutCity.information');
  const namePlaceHolder = t('aboutCity.cityName');
  const descriptionPlaceHolder = t('aboutCity.description');
  const parsedInformation = JSON.parse(information || 'null') as TCustomElement[];
  const maybeName = cityName || namePlaceHolder;
  const maybeDescription = description || descriptionPlaceHolder;
  const { placeUuid } = useAuthInfo();
  const placeId = placeUuid || '';
  const { data } = usePlaceQuery({
    id: placeId,
  });
  const cityLogo = data?.place?.branding?.logo?.links?.self;

  return (
    <Wrapper>
      <ImageWrapper>
        {cityLogo && (
          <CityLogo
            src={cityLogo}
            alt={`${cityName} logo`}
            data-testid="SettingsAboutCityPreview#Logo"
          />
        )}
      </ImageWrapper>
      <CityName hasContent={!!cityName} data-testid="SettingsAboutCityPreview#Title">
        {maybeName}
      </CityName>
      <Description hasContent={!!description} data-testid="SettingsAboutCityPreview#Description">
        {maybeDescription}
      </Description>
      <OtherInfo hasContent={!isContentEmpty} data-testid="SettingsAboutCityPreview#Content">
        {isContentEmpty ? informationPlaceholder : <RichTextRenderer value={parsedInformation} />}
      </OtherInfo>
    </Wrapper>
  );
};

export default SettingsAboutCityPreview;
