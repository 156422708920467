import { Range, Text, Transforms } from 'slate';
import { TCustomElement, TWysiwygEditor } from '@simplicity-tech/sim-slate-types';

import { toggleBlock } from '../helpers/helper';

const withHelpers = (editor: TWysiwygEditor) => {
  const { normalizeNode, deleteBackward } = editor;

  // information about selection in the moment of last blur event
  editor.blurSelection = null;

  //Ensure all text nodes have type: text property. Makes our life easier when rendering data in mobile app
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (Text.isText(node)) {
      Transforms.setNodes(editor, { type: 'text' }, { at: path });
      return;
    }

    normalizeNode(entry);
  };

  // Ensures pressing backspace when a single empty block is present turns the block into default (in our case, paragraph).

  editor.deleteBackward = (unit) => {
    const { selection } = editor;

    if (
      selection &&
      selection.focus.offset === 0 &&
      selection.anchor.offset === 0 &&
      Range.isCollapsed(selection)
    ) {
      const node = editor.children[selection.anchor.path[0]] as TCustomElement | undefined;
      if (node?.type === 'bullet-list' && !!node.children.length) {
        toggleBlock(editor, 'bullet-list');
      } else {
        deleteBackward(unit);
      }
    } else {
      deleteBackward(unit);
    }
  };

  return editor;
};

export default withHelpers;
