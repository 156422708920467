import { TCategories } from '@sim-admin-frontends/data-access';
import { TSelectItems } from '@sim-admin-frontends/ui-shared';

export const getCategoriesOptions = (categories: TCategories): TSelectItems => {
  return categories.map((category) => ({
    label: category.name,
    value: category.id,
  }));
};

export const getCategoryOptionFromId = (options: TSelectItems, id: string | undefined) =>
  options.find((o) => o.value === id);

export const getCategoryOptionFromIds = (options: TSelectItems, ids: string[] | undefined) =>
  options.reduce((prev, option) => {
    if (ids?.includes(option.value)) {
      return [...prev, option];
    }
    return prev;
  }, [] as TSelectItems);

export const getInitialCategoriesOptions = (
  options?: TSelectItems | null,
  announcementCategories?: TCategories | null,
) => {
  const resultOptions = getCategoryOptionFromIds(
    options || [],
    announcementCategories?.map((category) => category.id),
  );
  return resultOptions.length ? resultOptions : [];
};
