import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { TFeatureFlagType } from '@sim-admin-frontends/data-access';

import { PageWrapper } from '../../components/layout/PageLayout';
import PetDirectoryTabs from '../../components/petDirectory/view/PetDirectoryTabs';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const PetDirectoryListPage: FC = () => {
  const { t } = useTranslation();
  const { shouldShowFeatureFlagDisabledUI, showFeatureFlagDisabledUI } = useFeatureFlag(
    TFeatureFlagType.PET_DIRECTORY,
  );

  if (shouldShowFeatureFlagDisabledUI) {
    return showFeatureFlagDisabledUI();
  }

  return (
    <PageWrapper>
      <PageHeader
        title={t('petDirectory.pageTitle')}
        caption={t('petDirectory.pageCaption')}
        testId="petDirectoryTabs#PageHeader"
      />
      <PetDirectoryTabs />
    </PageWrapper>
  );
};

export default PetDirectoryListPage;
