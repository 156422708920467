import {
  AddAnnouncementDraftMutation,
  AddAnnouncementDraftMutationVariables,
  AddAnnouncementMutation,
  AddAnnouncementMutationVariables,
  AnnouncementDraftInput,
  AnnouncementInput,
  State,
  TAnnouncementCategory,
  TAreaOfInterest,
  TUploadedFile,
  UpdateAnouncementDraftMutation,
  UpdateAnouncementDraftMutationVariables,
  UpdateAnouncementMutation,
  UpdateAnouncementMutationVariables,
} from '@sim-admin-frontends/data-access';
import {
  getSelectedMapArea,
  prepareFileObjects,
  TGeoShape,
  TSelectedMapAreaShape,
} from '@sim-admin-frontends/utils-shared';
import { serializeToString, TCustomElement, TWysiwygValue } from '@simplicity-tech/sim-slate-types';
import { zonedTimeToUtc } from 'date-fns-tz';
import { History, LocationState } from 'history';
import { TFunction } from 'react-i18next';
import { QueryClient, UseMutateAsyncFunction } from 'react-query';
import { generatePath } from 'react-router-dom';
import * as Yup from 'yup';
import { Node } from 'slate';

import ROUTES from '../routing/routes';
import { TItemAction } from '../types/TAnalytics';
import {
  TAnnouncementFormValues,
  TAnnouncementsFormTypes,
  TAnnouncementsTabTypes,
} from '../types/TAnnouncements';
import { TPollFormValues } from '../types/TPolls';
import { TToastMessageActionType, TToastMessageType } from '../types/TToastMessage';
import { logAnnouncementEvent, logEmergencyEvent } from './analytics/analyticsUtils';
import { isCircleType } from './mapUtils';
import { invalidateAnnouncementDraftsQuery, invalidateAnnouncementQuery } from './queryUtils';
import { getToastMessage } from './toastMessageUtils';
import { TDonationFormValues } from '../types/TDonations';

const EMERGENCY_CATEGORY_NAME = 'Emergency';

export const getDisabledColumns = (type: State) => {
  switch (type) {
    case State.Scheduled:
      return ['createdByAdmin', 'link'];
    case State.Draft:
      return ['createdByAdmin', 'publishedAt', 'link'];
    case State.Unpublished:
      return ['publishedAt', 'link'];
    default:
      return [];
  }
};

export const getPublishingDateAccessor = (type: State) => {
  switch (type) {
    case State.Published:
      return 'publishedAt';
    case State.Scheduled:
      return 'scheduledAt';
    default:
      return 'createdAt'; // doesnt really matter for other states its disabled anyway
  }
};

export const changeAnnouncementsTab = (
  history: History<LocationState>,
  type: TAnnouncementsTabTypes,
) => {
  history.push(
    generatePath(ROUTES.announcementsOverview.path, {
      type,
    }),
  );
};

export const parseSlateText = (slateText?: string) =>
  JSON.parse(slateText || 'null') as TCustomElement[];

export const parseRichText = (text: string) => {
  let previewText = '';
  let parsedText;

  try {
    parsedText = parseSlateText(text);
  } catch {
    return text;
  }

  parsedText?.map((item: TCustomElement) => {
    item.children.map((i) => {
      if (i.type === 'text') {
        previewText += i.text;
      }
    });
  });
  return previewText;
};

const isAnnouncementFormValues = (
  obj: TAnnouncementFormValues | TPollFormValues,
): obj is TAnnouncementFormValues => !!(obj as TAnnouncementFormValues).publishedAt;

export const prepareFormValues = (
  values: TAnnouncementFormValues | TPollFormValues | TDonationFormValues,
  isScheduling: boolean,
  timezone: string,
  isDraft?: boolean,
) => {
  const newValues = {
    ...values,
    lang: values.lang,
    scheduledAt:
      isScheduling && values.scheduledAt ? zonedTimeToUtc(values.scheduledAt, timezone) : undefined,
    notifications: values.notifications?.map((notification) => ({
      value: zonedTimeToUtc(notification.value, timezone),
    })),
  };
  return isDraft
    ? newValues
    : {
        ...newValues,
        publishedAt:
          isAnnouncementFormValues(values) && values.publishedAt
            ? zonedTimeToUtc(values.publishedAt, timezone)
            : undefined,
      };
};

export const transformFormValues = (
  formData: TAnnouncementFormValues,
  uploadedImages: TUploadedFile[] | undefined,
  uploadedVideos: TUploadedFile[] | undefined,
  defaultPostLang = '',
): AnnouncementInput => {
  const imageObjects = prepareFileObjects(uploadedImages);
  const videoObjects = prepareFileObjects(uploadedVideos);
  const areaOfInterest = getSelectedMapArea(formData.areaOfInterest);

  return {
    title: formData.title,
    content: formData.content,
    pushContent: serializeToString(formData.content),
    institutionUuid: formData.publisher?.value ?? '',
    categoryUuids: formData.categories?.map((category) => category.value),
    placeUuids: formData.places?.map((place) => place.value),
    lang: formData.lang?.value || defaultPostLang,
    imageObjects,
    videoObjects,
    scheduledAt: formData.scheduledAt?.toISOString(),
    notifications:
      formData.notifyNow && formData.notifications?.length && formData.notifications.length > 0
        ? formData.notifications?.map((notification) => notification.value.toISOString())
        : null,
    notifyAlsoNonCategoried: formData.notifyAlsoNonCategoried,
    notifyVolunteersOnly: formData.notifyVolunteersOnly,
    notifyNow: formData.notifyNow,
    publishedAt: formData.scheduledAt ? undefined : formData.publishedAt?.toISOString(),
    areaOfInterest,
  };
};

export const shouldSaveDraftButtonBeVisible = (
  announcementPublishmentState: State | undefined | null,
  isScheduling: boolean,
) => {
  const isDraft = announcementPublishmentState && announcementPublishmentState !== State.Draft;
  return !isDraft || !isScheduling;
};

export const determinePrimaryButtonLabel = (
  announcementPublishmentState: State | undefined | null,
  isScheduling: boolean,
  t: TFunction,
) => {
  const isDraft = announcementPublishmentState && announcementPublishmentState !== State.Draft;
  if (isDraft && isScheduling) {
    return t('updates.form.save');
  } else if (isScheduling) {
    return t('updates.form.schedule');
  } else {
    return t('updates.form.publish');
  }
};

export const determineDraftToastMessage = (
  announcementPublishmentState: State | undefined | null,
  isScheduling: boolean,
  t: TFunction,
) =>
  !announcementPublishmentState
    ? t('updates.form.toastCreateDraft')
    : t('updates.form.toastEditDraft');

export const determineToastMessage = (
  announcementPublishmentState: State | undefined | null,
  isScheduling: boolean,
  t: TFunction,
  toastMessageType: TToastMessageType,
) => {
  const isDraft = announcementPublishmentState && announcementPublishmentState !== State.Draft;
  if (isDraft && isScheduling) {
    return getToastMessage(toastMessageType, TToastMessageActionType.edit, t);
  }
  if (isScheduling) {
    return getToastMessage(toastMessageType, TToastMessageActionType.schedule, t);
  }
  return getToastMessage(toastMessageType, TToastMessageActionType.create, t);
};

export const determineTabRedirect = (isScheduling: boolean): TAnnouncementsTabTypes =>
  isScheduling ? TAnnouncementsTabTypes.SCHEDULED : TAnnouncementsTabTypes.PUBLISHED;

export const submitAnnouncement = async (
  input: AnnouncementInput,
  id: string | undefined,
  queryClient: QueryClient,
  institutionUuid: string,
  announcementType: TAnnouncementsFormTypes,
  addAnnouncement: UseMutateAsyncFunction<
    AddAnnouncementMutation,
    unknown,
    AddAnnouncementMutationVariables
  >,
) => {
  const { addAnnouncement: result } = await addAnnouncement({
    announcement: { ...input, id },
  });

  const isScheduled = !!input.scheduledAt;

  const action = isScheduled ? TItemAction.SCHEDULE : TItemAction.CREATE;

  //TODO: Invalidate announcements query
  if (id) invalidateAnnouncementQuery(queryClient, id);
  switch (announcementType) {
    case TAnnouncementsFormTypes.EMERGENCY:
      logEmergencyEvent(result.id, institutionUuid, action);
      return;
    case TAnnouncementsFormTypes.ANNOUNCEMENT:
    default:
      logAnnouncementEvent(result.id, institutionUuid, action);
  }
};

export const submitUpdateAnnouncement = async (
  input: AnnouncementInput,
  updateId: string,
  queryClient: QueryClient,
  institutionUuid: string,
  announcementType: TAnnouncementsFormTypes,
  updateAnnouncement: UseMutateAsyncFunction<
    UpdateAnouncementMutation,
    unknown,
    UpdateAnouncementMutationVariables
  >,
) => {
  await updateAnnouncement({ id: updateId, announcement: input });
  //TODO: Invalidate announcements query
  invalidateAnnouncementQuery(queryClient, updateId);
  switch (announcementType) {
    case TAnnouncementsFormTypes.EMERGENCY:
      logEmergencyEvent(updateId, institutionUuid, TItemAction.EDIT);
      return;
    case TAnnouncementsFormTypes.ANNOUNCEMENT:
    default:
      logAnnouncementEvent(updateId, institutionUuid, TItemAction.EDIT);
  }
};

export const submitDraft = async (
  input: AnnouncementDraftInput,
  queryClient: QueryClient,
  institutionUuid: string,
  addDraft: UseMutateAsyncFunction<
    AddAnnouncementDraftMutation,
    unknown,
    AddAnnouncementDraftMutationVariables
  >,
) => {
  const { addAnnouncementDraft: result } = await addDraft({
    announcementDraft: input,
  });
  invalidateAnnouncementDraftsQuery(queryClient);
  logAnnouncementEvent(result.id, institutionUuid, TItemAction.CREATE_DRAFT);
};

export const submitUpdateDraft = async (
  input: AnnouncementDraftInput,
  updateId: string,
  queryClient: QueryClient,
  institutionUuid: string,
  updateDraft: UseMutateAsyncFunction<
    UpdateAnouncementDraftMutation,
    unknown,
    UpdateAnouncementDraftMutationVariables
  >,
) => {
  await updateDraft({
    id: updateId,
    announcementDraft: input,
  });
  invalidateAnnouncementDraftsQuery(queryClient);
  invalidateAnnouncementQuery(queryClient, updateId);
  logAnnouncementEvent(updateId, institutionUuid, TItemAction.EDIT_DRAFT);
};

export const requiredTitleSchema = (t: TFunction) =>
  Yup.object().shape({
    title: Yup.string().required(t('common.validation.required')),
  });

export const isEmergency = (category: TAnnouncementCategory) =>
  category?.name.includes(EMERGENCY_CATEGORY_NAME);

export const getAreaOfInterest = (value: TAreaOfInterest | null): TGeoShape | null => {
  if (!value || value.__typename !== 'GeoShapePolygon') {
    return null;
  }

  if (isCircleType(value)) {
    return {
      type: TSelectedMapAreaShape.CIRCLE,
      coordinates: value?.coordinates,
      radius: value.radius,
    };
  }
  return {
    type: TSelectedMapAreaShape.POLYGON,
    coordinates: value.polygonCoordinates,
    radius: null,
  };
};

// match first sentece started by any whitespaces, ended by . | ? | ! and any uppercase character
export const FIRST_SENTECE_REGEX = /^\s*(.*?(?<!\b\w)[.?!])\s+[A-Z0-9]/;

export const getTitleFromWysiwyg = (nodes: TWysiwygValue) => {
  if (!nodes) {
    return '';
  }
  const plaintext = nodes.map((n) => Node.string(n)).join('\n');
  const firstSentence = plaintext.split(FIRST_SENTECE_REGEX)?.[1] || '';
  return firstSentence;
};
