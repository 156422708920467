import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import CouponEditContainer from '../../components/coupons/edit/CouponEdit.container';
import { UpdateEditPageProps } from '../announcements/AnnouncementsEditPage';

const CouponsEditPage: FC<RouteComponentProps<UpdateEditPageProps>> = ({ match }) => (
  <CouponEditContainer id={match?.params?.id} />
);

export default CouponsEditPage;
