/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CellProps, SortingRule } from 'react-table';
import { TFunction } from 'i18next';
import { formatTableDate, formatTableDateTime } from '@sim-admin-frontends/utils-shared';
import {
  AnnouncementsOrderByInput,
  AnnouncementSortBy,
  SortOrder,
  State,
  TAnnouncementListItem,
} from '@sim-admin-frontends/data-access';
import {
  ACTION_ITEMS_MIN_WIDTH_SMALL,
  TableHeaderTitle,
  TExtendedColumn,
} from '@sim-admin-frontends/ui-shared';

import { getPublishingDateAccessor } from '../../../utils/announcementsUtils';
import RafflesTableMenu from './RafflesTableMenu';
import { TAnnouncementTableItem } from '../../../types/TAnnouncements';
import { getUnpublishCheckbox } from '../../../utils/publishUtils';

export const createTableColumns = (
  t: TFunction,
  refetch: () => void,
  type: State,
  timeZone?: string,
  postsToUnpublish?: string[],
  onCheckboxChange?: (id: string) => () => void,
) => {
  const columns: TExtendedColumn<TAnnouncementTableItem>[] = [
    {
      Header: `${t('updates.table.title')}`,
      accessor: (item) => <TableHeaderTitle item={item.title} maxTextLength={120} />,
      testId: 'RafflesTable#title',
      disableSortBy: true,
    },
    {
      Header: `${t('updates.table.published')}`,
      // @ts-ignore - No no typescript i know better - hopefully :)
      accessor: getPublishingDateAccessor(type),
      testId: 'RafflesTable#publicationDate',
      Cell: ({ value }: any) => (value ? formatTableDateTime(value, timeZone) : ''),
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('updates.table.lastUpdate')}`,
      accessor: 'updatedAt',
      testId: 'RafflesTable#lastUpdate',
      Cell: ({ value }) => (value ? formatTableDate(value) : ''),
      skeleton: {
        width: 100,
      },
    },
    {
      Header: '',
      id: '0',
      testId: 'RafflesTable#dots',
      disableSortBy: true,
      minWidth: ACTION_ITEMS_MIN_WIDTH_SMALL,
      skeleton: {
        width: 50,
        align: 'right',
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ row }: CellProps<TAnnouncementTableItem>) => {
        const isPublished = row.original.publishmentState === State.Published;
        return (
          <RafflesTableMenu
            raffleId={row.original.id}
            published={isPublished}
            refetch={refetch}
            testId={`RafflesView#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  getUnpublishCheckbox<TAnnouncementTableItem, TExtendedColumn<TAnnouncementTableItem>[]>(
    columns,
    'RafflesTable',
    postsToUnpublish,
    onCheckboxChange,
  );

  return columns;
};

export const getInitialTableSorting = (type: State): SortingRule<TAnnouncementListItem>[] => {
  switch (type) {
    case State.Draft:
    case State.Unpublished:
      return [
        {
          id: 'updatedAt',
          desc: true,
        },
      ];
    case State.Published:
      return [
        {
          id: 'publishedAt',
          desc: true,
        },
      ];
    default:
      return [];
  }
};

export const getInitialGqlSorting = (type?: State): AnnouncementsOrderByInput => {
  switch (type) {
    case State.Unpublished:
      return {
        by: AnnouncementSortBy.UpdatedAt,
        order: SortOrder.Desc,
      };
    case State.Published:
      return {
        by: AnnouncementSortBy.PublishedAt,
        order: SortOrder.Desc,
      };
    default:
      return {
        by: AnnouncementSortBy.CreatedAt,
        order: SortOrder.Desc,
      };
  }
};

export const getGqlSorting = (
  tableSorting: SortingRule<TAnnouncementListItem>[],
  type?: State,
): AnnouncementsOrderByInput => {
  if (!tableSorting || tableSorting.length !== 1) {
    return getInitialGqlSorting(type);
  }
  const rule = tableSorting[0];

  switch (rule.id) {
    case 'createdAt':
      return {
        by: AnnouncementSortBy.CreatedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    case 'updatedAt':
      return {
        by: AnnouncementSortBy.UpdatedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    case 'publishedAt':
      return {
        by: AnnouncementSortBy.PublishedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    default:
      return getInitialGqlSorting(type);
  }
};
