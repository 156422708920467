import { FC } from 'react';
import styled from 'styled-components';
import { TParagraphElement } from '@simplicity-tech/sim-slate-types';
import { Image } from '@sim-admin-frontends/data-access';

import { TBaseProps } from '../../..';
import { useImageGrid } from '../../../hooks/useImageGrid';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spaces.spacing8} 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spaces.spacing8} ${({ theme }) => theme.spaces.spacing16} 0
    ${({ theme }) => theme.spaces.spacing16};
`;

const DescriptionTextWrapper = styled.div`
  justify-content: center;
  padding-left: ${({ theme }) => theme.spaces.spacing8};
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: stretch;
  padding: ${({ theme }) => theme.spaces.spacing8} ${({ theme }) => theme.spaces.spacing16} 0
    ${({ theme }) => theme.spaces.spacing16};
`;

const InstitutionName = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.coolGray100};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const DateText = styled(InstitutionName)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.coolGray70};
  flex-grow: 1;
  word-wrap: break-word;
`;

const Description = styled(DateText)`
  line-height: ${({ theme }) => theme.lineHeights.small};
  -webkit-line-clamp: 3;
  line-clamp: 3;
`;

const Title = styled(InstitutionName)`
  font-size: ${({ theme }) => theme.fontSizes.caption1};
  line-height: ${({ theme }) => theme.lineHeights.caption1};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.coolGray100};

  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Icon = styled.img`
  border-radius: ${({ theme }) => theme.borderRadius.radius16};
  width: 25px;
  height: 25px;
  align-self: center;
`;

const IconSkeleton = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.radius16};
  background-color: ${({ theme }) => theme.colors.coolGray20};
  width: 25px;
  height: 25px;
  align-self: center;
`;

const Spacing = styled.div`
  flex-grow: 1;
`;

const ImageWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spaces.spacing8} ${({ theme }) => theme.spaces.spacing16};
`;

const EmptyPost = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export interface DashboardCardProps extends TBaseProps {
  institution: string;
  institutionLogo: string | null | undefined;
  updatedAt: string | null | undefined;
  title: string;
  images?: Image[] | null;
  description: string | null | undefined;
}

interface ErrorMessage {
  errMessage?: string;
}

const DashboardCard: FC<DashboardCardProps & ErrorMessage> = ({
  institution,
  institutionLogo,
  updatedAt,
  title,
  images,
  description,
  testId,
  errMessage,
}) => {
  const imageStrings = images?.map((image) => image?.links?.self || '') || [];
  const { renderImageGrid } = useImageGrid(imageStrings);
  const getTextFromWysiwyg = (): string | null => {
    try {
      if (description) {
        const obj = JSON.parse(description);
        const paragraphs = obj.map((p: TParagraphElement) => {
          if (p.type === 'paragraph')
            return p.children.map((t) => {
              if (t.type === 'text') {
                return t.text;
              }
              return null;
            });
          return [];
        });
        return paragraphs.join();
      }
    } catch (e) {
      return null;
    }
    return null;
  };

  if (!institution)
    return (
      <Wrapper data-testid={testId}>
        <EmptyPost>
          <Title>{errMessage}</Title>
        </EmptyPost>
      </Wrapper>
    );

  return (
    <Wrapper data-testid={testId}>
      <HeaderWrapper>
        {institutionLogo ? <Icon src={institutionLogo} /> : <IconSkeleton />}
        <DescriptionTextWrapper>
          <InstitutionName>{institution}</InstitutionName>
          <DateText>{updatedAt}</DateText>
        </DescriptionTextWrapper>
      </HeaderWrapper>

      <TextWrapper>
        <Title>{title}</Title>
        <Description>{getTextFromWysiwyg()}</Description>
      </TextWrapper>

      {images && <Spacing />}
      {images && <ImageWrapper>{renderImageGrid(imageStrings)}</ImageWrapper>}
    </Wrapper>
  );
};

export { DashboardCard };
