import React, { FC } from 'react';
import styled from 'styled-components';

import UserPrefsHeaderContainer from './UserPrefsHeader.container';
import HeaderContentContainer from './HeaderContent.container';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const HeaderContentWrapper = styled.div`
  flex: 1;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const Header: FC = () => {
  return (
    <Wrapper>
      <HeaderContentWrapper>
        <HeaderContentContainer />
      </HeaderContentWrapper>
      <UserPrefsHeaderContainer />
    </Wrapper>
  );
};

export default Header;
