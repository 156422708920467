import { FC, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation, TFunction } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Button,
  FormButtonsWrapper,
  FormTextarea,
  FormWrapper,
  FormWysiwyg,
  Label,
  RefreshIcon,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  toast,
  TToastType,
} from '@sim-admin-frontends/ui-shared';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import styled from 'styled-components';
import { Node } from 'slate';
import { TWysiwygValue } from '@simplicity-tech/sim-slate-types';

import { TGeneratorPostType, TPostAIFormValues } from '../../types/TPostGenerator';
import { SaveDraftButtonWrapper } from '../announcements/edit/AnnouncementEditStyles';
import PostGeneratorTypePicker from './PostGeneratorTypePicker';
import PostGeneratorBanner from './PostGeneratorBanner';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spaces.spacing48};
  margin-bottom: ${({ theme }) => theme.spaces.spacing64};
`;

const IconWrapper = styled.div`
  width: ${({ theme }) => theme.spaces.spacing20};
  height: ${({ theme }) => theme.spaces.spacing20};
  padding-right: ${({ theme }) => theme.spaces.spacing8};
`;

const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.blue60};
  color: ${({ theme }) => theme.colors.white};
`;

const schema = (t: TFunction) =>
  Yup.object().shape({
    input: Yup.string().required(t('common.validation.required')),
  });

type Props = {
  postContent?: string;
  onSubmit: (values: TPostAIFormValues) => Promise<void>;
  navigateToAnnouncement: (values: TPostAIFormValues) => void;
};

const PostGenerator: FC<Props> = ({ onSubmit, postContent, navigateToAnnouncement }) => {
  const initialValues: TPostAIFormValues = {
    postType: TGeneratorPostType.ANNOUNCEMENT,
    input: '',
  };
  const { t } = useTranslation();

  const methods = useForm<TPostAIFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });

  const { handleSubmit, register, formState, setValue, control } = methods;
  const { errors, isSubmitting } = formState;
  const [watchedOutput, watchedPostType] = useWatch({ name: ['output', 'postType'], control });

  const canEditContent = !!postContent && !isSubmitting;
  const canRegenerate = !!postContent;
  const canUseAnnouncement = watchedPostType === TGeneratorPostType.ANNOUNCEMENT;
  useEffect(() => {
    const stringifiedWysiwyg = JSON.stringify([
      {
        type: 'paragraph',
        children: [{ text: postContent || '', type: 'text' }],
      },
    ]);

    setValue('output', stringifiedWysiwyg);
  }, [postContent]);

  const copyToClipboard = () => {
    const nodes: TWysiwygValue = JSON.parse(watchedOutput || SERIALIZED_WYSIWYG_EMPTY_VALUE);
    const plaintext = nodes.map((n) => Node.string(n)).join('\n');
    navigator.clipboard.writeText(plaintext);
    toast(t('common.copiedToClipboard'), TToastType.INFO);
  };

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          <PostGeneratorBanner />
          <Label>{t('postGenerator.form.caption')}</Label>
          <PostGeneratorTypePicker methods={methods} />
          <FormTextarea
            label={t('postGenerator.form.input')}
            {...register('input')}
            placeholder={t('postGenerator.form.inputPlaceholder')}
            rows={4}
            error={errors.input}
            testId="PostGenerator#input"
          />
          {canEditContent && (
            <FormWysiwyg
              label={t('postGenerator.form.output')}
              name="output"
              control={control}
              testId="PostGenerator#output"
              initialValue={JSON.parse(watchedOutput || SERIALIZED_WYSIWYG_EMPTY_VALUE)}
            />
          )}
          <FormButtonsWrapper>
            {canRegenerate && (
              <>
                <SaveDraftButtonWrapper>
                  <Button
                    size="smaller"
                    variant={'tertiary'}
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isSubmitting}
                    disabled={isSubmitting || !isEmpty(errors)}
                    testId="PostGenerator#regenerate"
                  >
                    <IconWrapper>
                      <RefreshIcon />
                    </IconWrapper>
                    {t('postGenerator.form.reGenerate')}
                  </Button>
                </SaveDraftButtonWrapper>
                <Button size="smaller" variant="bordered" onClick={copyToClipboard}>
                  {t('postGenerator.form.copyText')}
                </Button>
              </>
            )}
            {canRegenerate && canUseAnnouncement && (
              <SubmitButton
                size="smaller"
                type="submit"
                onClick={handleSubmit(navigateToAnnouncement)}
              >
                {t('postGenerator.form.useAnnouncement')}
              </SubmitButton>
            )}
            {!canRegenerate && (
              <SubmitButton
                size="smaller"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                isLoading={isSubmitting}
                disabled={isSubmitting || !isEmpty(errors)}
              >
                {t('postGenerator.form.generate')}
              </SubmitButton>
            )}
          </FormButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default PostGenerator;
