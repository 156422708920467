import styled from 'styled-components';
import { Currency } from '@sim-admin-frontends/data-access';
import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TPrice } from '../../types/TPrice';
import { Input } from '../input/Input';
import { Select } from '../select/Select';
import { FormItemWrapper } from '../form/formStyles';
import { TSelectItem } from '../../types/TSelect';

const SELECT_OPTIONS = Object.values(Currency).map((value) => ({
  label: value,
  value: value,
}));

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacing16};
`;

export type PriceInputProps = {
  value?: TPrice;
  onChange: (value: TPrice | undefined) => void;
  initialValue?: TPrice;
  testId?: string;
  isDiscount?: boolean;
};

const PriceInput = ({ value, initialValue, testId, onChange, isDiscount }: PriceInputProps) => {
  const { t } = useTranslation();
  const initialCurrecy = useMemo(
    () => SELECT_OPTIONS.find((option) => option.value === initialValue?.currency),
    [initialValue?.currency],
  );

  const onCurrencyChange = (arg: readonly TSelectItem[] | null) => {
    onChange({
      ...value,
      currency: arg?.[0].value ? (arg[0].value as Currency) : undefined,
    });
  };

  const onPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      price: e.target.value?.length ? parseFloat(e.target.value) : undefined,
    });
  };

  const onPriceBeforeDiscountChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      priceBeforeDiscount: e.target.value?.length ? parseFloat(e.target.value) : undefined,
    });
  };

  return (
    <Wrapper>
      <FormItemWrapper>
        <Input
          testId={testId ? `${testId}#Price` : undefined}
          defaultValue={initialValue?.price}
          placeholder={t('common.form.price')}
          onChange={onPriceChange}
          type="number"
        />
      </FormItemWrapper>
      {!!isDiscount && (
        <FormItemWrapper>
          <Input
            testId={testId ? `${testId}#PriceBeforeDiscount` : undefined}
            defaultValue={initialValue?.priceBeforeDiscount}
            placeholder={t('common.form.priceBeforeDiscount')}
            onChange={onPriceBeforeDiscountChange}
            type="number"
          />
        </FormItemWrapper>
      )}
      <Select
        options={SELECT_OPTIONS}
        defaultValue={initialCurrecy}
        placeholder={t('common.form.currency')}
        onChange={onCurrencyChange}
        searchable
      />
    </Wrapper>
  );
};

export { PriceInput };
