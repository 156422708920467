import styled from 'styled-components';
import { FC } from 'react';

import { TMenuEntry } from '../../../types/TMenu';
import { device } from '../../../utils/styleUtils';
import { LockIcon } from '../../..';

export const MenuItemWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spaces.spacing8};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.coolGray40 : theme.colors.coolGray100};
  transition: background-color 125ms;
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadius.radius8};

  svg {
    fill: ${({ theme, disabled }) =>
      disabled ? theme.colors.coolGray40 : theme.colors.coolGray100};
  }

  svg,
  div {
    transition: all 200ms;
  }

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    background-color: ${({ theme }) => theme.colors.coolGray10};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spaces.spacing32};
  width: ${({ theme }) => theme.spaces.spacing32};
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.lineHeights.small};
  padding-left: ${({ theme }) => theme.spaces.spacing8};

  display: none;

  ${device.md} {
    display: flex;
  }
`;

const LockIconWrapper = styled(IconWrapper)`
  display: none;

  ${device.md} {
    display: flex;
  }
`;

export type MenuItemProps = TMenuEntry;

const MenuItem: FC<MenuItemProps> = ({ label, icon, disabled }) => (
  <>
    <IconWrapper>{icon}</IconWrapper>
    <Label>{label}</Label>
    {disabled && (
      <LockIconWrapper>
        <LockIcon />
      </LockIconWrapper>
    )}
  </>
);

export { MenuItem };
