import { FC } from 'react';
import { HeaderPropGetter } from 'react-table';
import styled from 'styled-components';

import { ArrowDownWithLine, ArrowUpWithLine } from '../..';

const Wrapper = styled.div<{ sortable: boolean; disabled: boolean }>`
  display: flex;
  cursor: ${({ sortable, disabled }) =>
    sortable && !disabled ? 'pointer' : disabled ? 'not-allowed' : 'default'};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  align-items: center;
`;
const Icon = styled.span`
  display: flex;
  margin-left: ${({ theme }) => theme.spaces.spacing4};
  align-items: center;
`;

type Props = HeaderPropGetter<any> & {
  sortable: boolean;
  sorted: boolean;
  desc?: boolean;
  disabled?: boolean;
};

const SortableHeaderCell: FC<Props> = ({
  children,
  sortable,
  sorted,
  desc,
  disabled = false,
  ...rest
}) => {
  return (
    <th {...rest} className={disabled ? 'disabled' : undefined}>
      <Wrapper sortable={sortable} disabled={disabled}>
        {children}
        {sortable && sorted && !disabled && (
          <Icon>{desc ? <ArrowDownWithLine /> : <ArrowUpWithLine />}</Icon>
        )}
      </Wrapper>
    </th>
  );
};

export default SortableHeaderCell;
