import styled from 'styled-components';
import { Controller, FieldValues } from 'react-hook-form';
import { MaybeDate } from '@sim-admin-frontends/utils-shared';

import { FormError } from '../../..';
import { DateTimePicker, DateTimePickerProps } from '../../datetime-picker/DateTimePicker';
import { FormItemLabel } from '../formStyles';
import { TFormControlElement } from '../../../types/TForm';

export const Wrapper = styled.div<{ noMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ theme, noMargin }) => (noMargin ? '0' : theme.spaces.spacing16)};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.spacing16};
`;

export const StyledLabel = styled(FormItemLabel)`
  flex: 1;
`;

export interface FormDateTimePickerProps<T> extends DateTimePickerProps, TFormControlElement<T> {
  timeLabel?: string;
  dateLabel?: string;
}

const FormDateTimePicker = <T extends FieldValues>({
  control,
  name,
  onChange,
  className,
  dateLabel,
  timeLabel,
  testId,
  ...rest
}: FormDateTimePickerProps<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange: onChangeField }, fieldState: { error } }) => {
      const onChangeMerged = (value: MaybeDate) => {
        onChangeField(value);
        if (onChange) onChange(value);
      };
      return (
        <Wrapper className={className}>
          <Header>
            <StyledLabel>{dateLabel}</StyledLabel>
            <StyledLabel>{timeLabel}</StyledLabel>
          </Header>
          <DateTimePicker {...rest} onChange={onChangeMerged} testId={testId} />
          {error && <FormError error={error} testId={`${testId}-error`} />}
        </Wrapper>
      );
    }}
  />
);

export { FormDateTimePicker };
