/* eslint-disable @typescript-eslint/ban-ts-comment */
import { generatePath } from 'react-router-dom';
import { History, LocationState } from 'history';
import { TFunction } from 'react-i18next';
import { formatTableDate } from '@sim-admin-frontends/utils-shared';
import {
  QuickQuestionsOrderByInput,
  QuickQuestionSortBy,
  SortOrder,
  State,
  TQuickQuestionListItem,
} from '@sim-admin-frontends/data-access';
import { CellProps } from 'react-table';
import { TableHeaderTitle, TExtendedColumn } from '@sim-admin-frontends/ui-shared';

import ROUTES from '../routing/routes';
import { TAnnouncementsTabTypes } from '../types/TAnnouncements';
import { getDisabledColumns, getPublishingDateAccessor } from './announcementsUtils';
import { QuickQuestionsTableMenu } from '../components/quickQuestions/view/QuickQuestionsTableMenu';
import QuickQuestionsResultsPopup from '../components/quickQuestions/results/QuickQuestionsResultsPopup';
import { getUnpublishCheckbox } from './publishUtils';

// ToDo: Implement when ready on BE
export const getInitialGqlSorting = (type?: State): QuickQuestionsOrderByInput => {
  switch (type) {
    //     case State.Unpublished:
    //     case State.Draft:
    //       return {
    //         by: QuickQuestionSortBy.UpdatedAt,
    //         order: SortOrder.Desc,
    //       };
    //     case State.Scheduled:
    //       return {
    //         by: QuickQuestionSortBy.ScheduledAt,
    //         order: SortOrder.Asc,
    //       };
    //     case State.Published:
    //       return {
    //         by: QuickQuestionSortBy.PublishedAt,
    //         order: SortOrder.Desc,
    //       };
    //
    default:
      return {
        by: QuickQuestionSortBy.PublishedAt,
        order: SortOrder.Desc,
      };
  }
};

export const changeQuickQuestionsTab = (
  history: History<LocationState>,
  type: TAnnouncementsTabTypes,
) => {
  history.push(
    generatePath(ROUTES.quickQuestionsOverview.path, {
      type,
    }),
  );
};

export const createTableColumns = (
  t: TFunction,
  refetch: () => void,
  type: State,
  postsToUnpublish?: string[],
  onCheckboxChange?: (id: string) => () => void,
) => {
  const disabledColumns = getDisabledColumns(type);
  const columns: TExtendedColumn<TQuickQuestionListItem>[] = [
    {
      Header: `${t('polls.table.title')}`,
      accessor: (item) => <TableHeaderTitle item={item.question} maxTextLength={120} />,
      testId: 'QuickQuestionsTable#question',
      disableSortBy: true,
    },
    {
      Header: `${t('polls.table.published')}`,
      // @ts-ignore - No no typescript i know better - hopefully :)
      accessor: getPublishingDateAccessor(type),
      testId: 'QuickQuestionsTable#publicationDate',
      Cell: disabledColumns.includes('publishedAt')
        ? ''
        : ({ value }: any) => formatTableDate(value),
      disabled: disabledColumns.includes('publishedAt'),
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('quickQuestions.table.results')}`,
      id: '0',
      testId: 'QuickQuestionsTable#results',
      disableSortBy: true,
      skeleton: {
        width: 50,
        align: 'right',
      },
      Cell: ({ row }: CellProps<TQuickQuestionListItem>) => {
        return <QuickQuestionsResultsPopup quickQuestion={row.original} />;
      },
    },
    {
      Header: '',
      id: '1',
      testId: 'QuickQuestionsTable#dots',
      disableSortBy: true,
      skeleton: {
        width: 50,
        align: 'right',
      },
      Cell: ({ row }: CellProps<TQuickQuestionListItem>) => {
        return (
          <QuickQuestionsTableMenu
            type={type}
            postId={row.original.id}
            refetch={refetch}
            testId={`QuickQuestions#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  getUnpublishCheckbox<TQuickQuestionListItem, TExtendedColumn<TQuickQuestionListItem>[]>(
    columns,
    'QuickQuestionsTable',
    postsToUnpublish,
    onCheckboxChange,
  );

  return columns;
};

export const transformOptions = (options: string[]) => {
  const optionsWithCount = options.map((option) => ({ answer: option, count: 0 }));
  const { ...formattedOptions } = optionsWithCount;
  return JSON.stringify(formattedOptions);
};
