import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useHighlightableElements } from '../../contexts/highlightableElementsContext';
import { HIGHLIGHTED_ELEMENT_Z_INDEX } from './Constants';

const Wrapper = styled.div<{ shouldUseOutline: boolean }>`
  z-index: ${HIGHLIGHTED_ELEMENT_Z_INDEX};
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  ${({ shouldUseOutline, theme }) =>
    shouldUseOutline ? `outline: ${theme.colors.white} ${theme.spaces.spacing16} solid` : ''}
`;

type Props = {
  id: string;
  shouldUseOutline?: boolean;
};

const HighlightableElement: FC<Props> = ({ id, children, shouldUseOutline = true }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { highlightedElementId } = useHighlightableElements();
  const isSelected = id === highlightedElementId;

  useEffect(() => {
    if (isSelected && ref && ref.current) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isSelected, ref]);

  if (!isSelected) {
    return <>{children}</>;
  }

  return (
    <Wrapper shouldUseOutline={shouldUseOutline} ref={ref}>
      {children}
    </Wrapper>
  );
};

export default HighlightableElement;
