import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { getErrorMessage, toast, TToastType } from '@sim-admin-frontends/ui-shared';
import { Auth } from '@aws-amplify/auth';

import { TResetPasswordFormValues } from '../../types/TUser';
import ResetPassword from './ResetPassword';
import ROUTES from '../../routing/routes';

const ResetPasswordContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ username?: string }>();
  const username = params?.username && decodeURIComponent(params.username);

  if (!username) {
    return <Redirect to={ROUTES.login.path} />;
  }

  const onReset = async (values: TResetPasswordFormValues) => {
    const { code, password: newPassword } = values;
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      toast(t('resetPassword.toastSuccess'), TToastType.SUCCESS);
      history.push(ROUTES.login.path);
    } catch (err) {
      toast(getErrorMessage(err), TToastType.ERROR);
    }
  };

  return <ResetPassword onReset={onReset} />;
};

export default ResetPasswordContainer;
