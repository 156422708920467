import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ANNOUNCEMENTS_PAGE_SIZE,
  AnnouncementsByFilterInput,
  PostType,
  State,
  useInfiniteMarketQuery,
} from '@sim-admin-frontends/data-access';
import { useTranslation } from 'react-i18next';
import { useOffsetTablePaging } from '@sim-admin-frontends/utils-shared';
import { SortingRule } from 'react-table';
import { SpinnerWrapper, Error } from '@sim-admin-frontends/ui-shared';

import { TMarketListItem, isMarket } from '../../../../src/types/TMarket';
import { createTableColumns } from '../../../../src/utils/marketUtils';
import { useAuthInfo } from '../../../../src/contexts/userContext';
import { usePlaceInfo } from '../../../../src/contexts/placeContext';
import { TUnpublishContainerProps } from '../../../types/TUnpublish';
import {
  getGqlSorting,
  getInitialGqlSorting,
  getInitialTableSorting,
} from '../../announcements/view/helper';
import MarketView from './MarketView';

export interface MarketViewContainerProps extends TUnpublishContainerProps {
  state: State;
}

const MarketViewContainer: FC<MarketViewContainerProps> = ({
  state,
  postsToUnpublish,
  onUnpublishSelect,
}) => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();
  const [orderBy, setOrderBy] = useState(getInitialGqlSorting(state));
  const institutionUuidWithFallback = institutionUuid ?? '';

  const filter: AnnouncementsByFilterInput = useMemo(
    () => ({
      institutions: [institutionUuidWithFallback],
      state,
      typename: PostType.MarketItem,
    }),
    [institutionUuidWithFallback, state],
  );

  const { fetchNextPage, hasNextPage, data, isError, isLoading, isFetchingNextPage, refetch } =
    useInfiniteMarketQuery(filter, orderBy);

  const { currentPageIndex, maxPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } =
    useOffsetTablePaging(fetchNextPage, ANNOUNCEMENTS_PAGE_SIZE, hasNextPage);

  const onSortingChanged = useCallback(
    (newSorting: SortingRule<TMarketListItem>[]) => {
      setOrderBy(getGqlSorting(newSorting, state));
      setCurrentPageIndex(0);
    },
    [setCurrentPageIndex, state],
  );

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [institutionUuid]);

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }
  const columns = createTableColumns(
    t,
    refetch,
    state,
    places?.[0]?.timezoneCode,
    postsToUnpublish,
    onUnpublishSelect,
  );

  const itemsCount = data?.pages[0].announcementsBy.count ?? 0;

  const marketItems = data?.pages[currentPageIndex]?.announcementsBy.announcements.filter(
    (a): a is TMarketListItem => isMarket(a),
  );
  const loading = isLoading || isFetchingNextPage;

  return (
    <MarketView
      hasNextPage={hasNextPage || currentPageIndex < maxPageIndex}
      loading={loading}
      itemsCount={itemsCount}
      pageIndex={currentPageIndex}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      columns={columns}
      data={marketItems ?? []}
      onSortingChanged={onSortingChanged}
      initialTableSorting={getInitialTableSorting(state)}
    />
  );
};

export default MarketViewContainer;
