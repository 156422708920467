import {
  PageHeader,
  SpinnerWrapper,
  Spinner,
  Error,
  TableActionHeader,
  useSearchFilter,
} from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { InstitutionPlaceRole, useInstiutionRoleQuery } from '@sim-admin-frontends/data-access';

import { PageWrapper } from '../../components/layout/PageLayout';
import CouponsTabs from '../../components/coupons/view/CouponsTabs';
import { useAuthInfo } from '../../contexts/userContext';

const CouponsListPage: FC = () => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const { filter, renderFilterComponent } = useSearchFilter({
    testId: 'CouponsListPage#Search',
  });
  const {
    data: iRoleData,
    isLoading,
    isError,
    refetch,
  } = useInstiutionRoleQuery({ id: institutionUuid });

  const institutionRole = iRoleData?.institution?.inPlaces?.[0]?.role;
  const isEnabled = institutionRole === InstitutionPlaceRole.LocalBusiness;

  if (isLoading || !institutionUuid) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  if (!isEnabled) {
    return <Redirect to="/404" />;
  }

  return (
    <PageWrapper>
      <PageHeader
        title={t('coupons.pageTitle')}
        caption={t('coupons.pageCaption')}
        testId="CouponsTabs#PageHeader"
      />
      <TableActionHeader prependItem={renderFilterComponent()} />
      <CouponsTabs filter={filter} />
    </PageWrapper>
  );
};

export default CouponsListPage;
