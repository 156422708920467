import { FC } from 'react';
import { LeafChild } from '@simplicity-tech/sim-slate-types';

import RichTextLink from './RichTextLink';
import RichTextText from './RichTextText';

type Props = {
  child: LeafChild;
  key: number;
};

const RichTextChild: FC<Props> = ({ child }) => {
  switch (child.type) {
    case 'link': {
      return <RichTextLink url={child.url} content={child.children} />;
    }
    case 'text': {
      if (child.text === '') {
        return <br />;
      }
      if (!child.text) {
        return null;
      }
      return <RichTextText content={child} />;
    }
    default:
      return null;
  }
};

export default RichTextChild;
