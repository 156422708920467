import { UploadMethod, UploadUrl } from '../graphql/graphql';

export const IMAGE_TYPE_FALLBACK = 'image/jpeg';
export const VIDEO_TYPE_FALLBACK = 'video/mp4';

export const uploadFile = async (file: File, uploadUrl: UploadUrl): Promise<void> => {
  await fetch(uploadUrl.url, {
    method: UploadMethod.Put,
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  });
};

export const uploadFilePost = async (file: File, uploadUrl: UploadUrl): Promise<void> => {
  const data = new Blob([new Uint8Array(await file.arrayBuffer())], {
    type: file.type,
  });
  const form = new FormData();
  for (const { key, value } of uploadUrl.fields ?? []) {
    form.append(key, value);
  }
  form.append('content-type', file.type);
  form.append('file', data);

  await fetch(uploadUrl.url, {
    method: UploadMethod.Post,
    body: form,
  });
};
