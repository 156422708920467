import { AnnouncementsQuery, AnnouncementQuery, AnnouncementDraftsQuery } from '..';
export type TAnnouncementDetail = Extract<
  AnnouncementQuery['announcement'],
  { __typename?: 'Announcement' | 'AnnouncementDraft' }
>;

export type TAnnouncementList = TAnnouncementListItem[];
export type TAnnouncementDraftList = TAnnouncementDraftListItem[];

export type TAnnouncementListItem = Extract<
  AnnouncementsQuery['announcementsBy']['announcements'][number],
  { __typename?: 'Announcement' }
>;

export type TAnnouncementDraftListItem = Extract<
  AnnouncementDraftsQuery['announcementsBy']['announcements'][number],
  { __typename?: 'AnnouncementDraft' }
>;

export type TAnnouncementCategory = TAnnouncementDetail['category'];

export type TAreaOfInterest = TAnnouncementDetail['areaOfInterest'];

export const isAnnouncement = (
  announcement?: AnnouncementQuery['announcement'],
): announcement is TAnnouncementDetail =>
  announcement?.__typename === 'Announcement' || announcement?.__typename === 'AnnouncementDraft';

export type TQueryGeoshapeCircle = Extract<TAreaOfInterest, { __typename?: 'GeoShapeCircle' }>;
export type TQueryGeoshapePolygon = Extract<TAreaOfInterest, { __typename?: 'GeoShapePolygon' }>;
