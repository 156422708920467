import { EMPTY_WYSIWYG_VALUE } from '../lib/wysiwyg/Wysiwyg';

export const MAX_UPLOAD_SIZE = 20971520; // 20 MB
export const MAX_VIDEO_UPLOAD_SIZE = 26214400; // 25MB
export const MAX_BRANDING_UPLOAD_SIZE = 204800; //200kB
export const MIN_IMAGE_UPLOAD_WIDTH = 1024;
export const MIN_IMAGE_UPLOAD_HEIGHT = 576;
export const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
export const SUPPORTED_WEB_IMAGE_FORMATS = ['image/png'];
export const SUPPORTED_VIDEO_FORMATS = [
  'video/mp4',
  'video/quicktime',
  'video/mpeg',
  'video/oog',
  'video/webm',
];
export const SERIALIZED_WYSIWYG_EMPTY_VALUE = JSON.stringify(EMPTY_WYSIWYG_VALUE);
