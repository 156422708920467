import { FC } from 'react';
import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import {
  TPollDraftList,
  TPollDraftListItem,
  TPollList,
  TPollListItem,
} from '@sim-admin-frontends/data-access';
import { SortingRule } from 'react-table';

export interface PollsViewProps {
  data: TPollList | TPollDraftList;
  columns: TExtendedColumn<TPollListItem | TPollDraftListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  onSortingChanged: (value: SortingRule<TPollListItem | TPollDraftListItem>[]) => void;
  initialTableSorting: SortingRule<TPollListItem | TPollDraftListItem>[];
  hasNextPage?: boolean;
}

const PollsView: FC<PollsViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  onSortingChanged,
  initialTableSorting,
  hasNextPage,
}) => {
  return (
    <>
      <Table
        hasNextPage={hasNextPage}
        loading={loading}
        itemsCount={itemsCount}
        pageIndex={pageIndex}
        data={data}
        columns={columns}
        hasPagination={true}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        onSortingChanged={onSortingChanged}
        initialSortBy={initialTableSorting}
        testId="PollsView#Table"
      />
    </>
  );
};

export { PollsView };
