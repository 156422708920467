import {
  AddEventMutationVariables,
  CategoryType,
  isEvent,
  UploadType,
  useAddEventMutation,
  useCategoriesQuery,
  useEventQuery,
  useInstitutionQuery,
  useUpdateEventMutation,
  useUserInstitutionsQuery,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import {
  filterCategories,
  getDefaultLangCode,
  prepareFileObjects,
  useUploadImage,
  useUploadVideo,
} from '@sim-admin-frontends/utils-shared';
import { serializeToString } from '@simplicity-tech/sim-slate-types';
import { zonedTimeToUtc } from 'date-fns-tz';
import isValid from 'date-fns/isValid';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { FALLBACK_TIMEZONE } from '../../../constants/Constants';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import { useGenerateEventActions } from '../../../hooks/actionButtons/useGenerateEventActions';
import ROUTES from '../../../routing/routes';
import { TItemAction } from '../../../types/TAnalytics';
import { TEventsFormValues } from '../../../types/TEvents';
import { AnalyticsEvent } from '../../../utils/analytics/analyticsEvents';
import { analyticsLogEvent } from '../../../utils/analytics/analyticsUtils';
import EventEdit from './EventEdit';
import { transformPriceForBe } from '../../../utils/eventsUtils';

const SHARED_TOAST_ID = 'eventsToast';

type Props = {
  id?: string;
  showOnlyPreview?: boolean;
};

const EventEditContainer: FC<Props> = ({ id, showOnlyPreview }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user, visitorMode, institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();
  const { uploadFormImages } = useUploadImage();
  const { uploadFormVideos } = useUploadVideo();

  const institutionId = institutionUuid || '';
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;
  const defaultPostLang = getDefaultLangCode(places?.[0]?.countryCode);

  const queryClient = useQueryClient();
  const { mutateAsync: addEvent } = useAddEventMutation();
  const { mutateAsync: updateEvent } = useUpdateEventMutation();

  const {
    data,
    isLoading: isEventLoading,
    error: eventError,
    refetch,
  } = useEventQuery({ eventId: id || '' }, { enabled: !!id });

  const {
    isLoading: categoriesLoading,
    error: categoriesError,
    data: categoriesData,
  } = useCategoriesQuery(
    {
      filter: {
        categoryType: CategoryType.Event,
        visitorMode,
      },
    },
    { enabled: !!visitorMode },
  );

  const categories = useMemo(() => {
    return filterCategories(categoriesData?.categoriesBy.categories || [], {
      filterLegacy: true,
    });
  }, [categoriesData]);

  const eventData = isEvent(data?.event) ? data?.event : undefined;

  const {
    data: userData,
    isLoading: isLoadingInstitutions,
    isError: isErrorInstitutions,
  } = useUserInstitutionsQuery({
    username: user?.username || '',
  });

  const {
    data: initialInstitution,
    isLoading: isInstitutionLoading,
    error: institutionError,
  } = useInstitutionQuery({
    id: institutionId,
  });

  const onDiscard = () => {
    history.push(ROUTES.eventsOverview.path);
  };

  const onSubmit = async (values: TEventsFormValues) => {
    try {
      loadingToast(t('events.form.toastSubmitting'), {
        toastId: SHARED_TOAST_ID,
      });
      const transformedData = await prepareBackendData(values);
      if (id) {
        await updateEvent({ id, event: transformedData.event });
        updateToast(SHARED_TOAST_ID, t('events.form.toastEdit'), TToastType.SUCCESS);
        analyticsLogEvent({
          type: AnalyticsEvent.EVENT_ACTION,
          data: {
            Id: id,
            Action: TItemAction.EDIT,
            Notification: true,
            InstitutionId: transformedData.event.institutionUuid,
          },
        });
        queryClient.invalidateQueries(useEventQuery.getKey({ eventId: id || '' }));
      } else {
        const eventItem = await addEvent({ event: transformedData.event });
        updateToast(SHARED_TOAST_ID, t('events.form.toastCreate'), TToastType.SUCCESS);
        analyticsLogEvent({
          type: AnalyticsEvent.EVENT_ACTION,
          data: {
            Id: eventItem.addEvent.id,
            Action: TItemAction.CREATE,
            Notification: true,
            InstitutionId: transformedData.event.institutionUuid,
          },
        });
      }
      history.push(ROUTES.eventsOverview.path);
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };

  const prepareBackendData = async (
    formValues: TEventsFormValues,
  ): Promise<AddEventMutationVariables> => {
    const publisherId = formValues.publisher?.value ?? '';
    const uploadedImages = await uploadFormImages(UploadType.Event, formValues.images, {
      institutionId: publisherId,
    });
    const uploadedVideos = await uploadFormVideos(formValues.videos, {
      institutionId: publisherId,
    });
    const imageObjects = prepareFileObjects(uploadedImages);
    const videoObjects = prepareFileObjects(uploadedVideos);
    const transformedData: AddEventMutationVariables = {
      event: {
        title: formValues.title,
        content: formValues.content,
        pushContent: serializeToString(formValues.content),
        institutionUuid: publisherId,
        placeUuids: formValues.places?.map((place) => place.value),
        lang: formValues.lang?.value || defaultPostLang,
        dateTimeFrom:
          isValid(formValues.startDate) && formValues.startDate
            ? zonedTimeToUtc(formValues.startDate, timezone).toISOString()
            : '',
        datetimeTo:
          formValues.endDate && isValid(formValues.endDate)
            ? zonedTimeToUtc(formValues.endDate, timezone).toISOString()
            : undefined,
        location: formValues.location,
        imageObjects,
        videoObjects,
        publishedAt: formValues.publishedAt
          ? zonedTimeToUtc(formValues.publishedAt, timezone).toISOString()
          : undefined,
        marketUrl: formValues.marketUrl || undefined,
        marketItemPricing: transformPriceForBe(formValues.marketItemPricing),
      },
    };

    if (visitorMode) {
      transformedData.event.categoryUuids = formValues.categories?.map(
        (category) => category.value,
      );
    }

    return transformedData;
  };

  const isLoading =
    isEventLoading || isInstitutionLoading || isLoadingInstitutions || categoriesLoading;

  const isError = eventError || institutionError || isErrorInstitutions || categoriesError;

  const { detailActions, renderModal } = useGenerateEventActions({
    isFromAdmin: eventData?.createdByAdmin,
    eventId: id || '',
    published: !!eventData?.publishedAt,
    refetch,
    isEditPage: true,
  });

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  if (isError) return <Error caption={t('error.fetchingDataError')} onClick={refetch} />;

  const institutionOptions = userData?.adminUser?.institutions || [];

  return (
    <>
      <EventEdit
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        event={eventData}
        institution={initialInstitution?.institution}
        userInstitutions={institutionOptions}
        showOnlyPreview={showOnlyPreview}
        eventFormActions={id ? detailActions : []}
        categories={categories}
      />
      {renderModal()}
    </>
  );
};

export default EventEditContainer;
