import { useDecodeFilesToBlobs } from '@sim-admin-frontends/utils-shared';
import { FC } from 'react';
import styled from 'styled-components';

import { TRaffleFormValues } from '../../../types/TRaffles';

const Wrapper = styled.div<{ textPosition?: string; textColor?: string; bgImage?: string }>`
  position: relative;
  aspect-ratio: 1.905;
  display: flex;
  flex-direction: column;
  justify-content: ${({ textPosition }) => textPosition || 'flex-start'};
  gap: ${({ theme }) => theme.spaces.spacing8};
  color: ${({ theme, textColor }) => `#${textColor}` || theme.colors.coolGray10};
  background-color: ${({ theme }) => theme.colors.black};
  ${({ bgImage }) => bgImage && `background-image: url('${bgImage}')`};
  background-size: cover;
  padding: ${({ theme }) => theme.spaces.spacing16};
  border-radius: ${({ theme }) => theme.spaces.spacing8};
  margin: ${({ theme }) => theme.spaces.spacing16};
  overflow: hidden;
`;

const Title = styled.span<{ width?: number; fontSize: number; lineHeight: number }>`
  ${({ width }) => width && width >= 1 && width <= 100 && `width: ${width}%`};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: 600;
  line-height: ${({ lineHeight }) => `${lineHeight}px`};
`;

const Subtitle = styled.span<{ width?: number; fontSize: number; lineHeight: number }>`
  ${({ width }) => width && width >= 1 && width <= 100 && `width: ${width}%`};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: 300;
  line-height: ${({ lineHeight }) => `${lineHeight}px`};
`;

const RafflePreview: FC<Partial<TRaffleFormValues>> = ({
  title,
  titleWidth,
  subtitle,
  subtitleWidth,
  textColor,
  textPosition,
  bgImage,
}) => {
  const testIdPrefix = 'RafflePreview';

  const windowHeight = window.innerHeight;
  const titleFontsize = windowHeight / 35;
  const titleLineHeight = windowHeight / 32.5;
  const subtitleFontSize = windowHeight / 70;
  const subtitleLineHeight = windowHeight / 60;
  const bgImageUrl = useDecodeFilesToBlobs(bgImage)[0];

  return (
    <Wrapper
      data-testid={`${testIdPrefix}#Wrapper`}
      textPosition={textPosition?.value}
      textColor={textColor}
      bgImage={bgImageUrl}
    >
      <Title
        data-testid={`${testIdPrefix}#Title`}
        width={titleWidth}
        fontSize={titleFontsize}
        lineHeight={titleLineHeight}
      >
        {title}
      </Title>
      <Subtitle
        data-testid={`${testIdPrefix}#Subtitle`}
        width={subtitleWidth}
        fontSize={subtitleFontSize}
        lineHeight={subtitleLineHeight}
      >
        {subtitle}
      </Subtitle>
    </Wrapper>
  );
};

export default RafflePreview;
