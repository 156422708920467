import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, get, useForm, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import {
  FormDateTimePicker,
  FormInput,
  FormSectionHeader,
  FormSelect,
  FormUpload,
  FormWrapper,
  FormWysiwyg,
  SwitchableFormSection,
  TSelectItem,
  TSelectRef,
  MAX_VIDEO_UPLOAD_SIZE,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  SUPPORTED_VIDEO_FORMATS,
  FormImageCropperUpload,
  DEFAULT_RADIUS_MILES_STRING,
  TableIconProps,
  ActionButtons,
  FormSectionWithSwitch,
} from '@sim-admin-frontends/ui-shared';
import {
  ImageInput,
  State,
  TAnnouncementDetail,
  TCategories,
  TInstitution,
  TInstitutionListItem,
  VideoInput,
} from '@sim-admin-frontends/data-access';
import {
  getInstitutionInitValues,
  getLangOptionByVal,
  getLangOptions,
  isFile,
  MaybeDate,
  roundMinutes,
  TGeoShape,
  TMapCenter,
} from '@sim-admin-frontends/utils-shared';

import { usePlaceInfo } from '../../../contexts/placeContext';
import AnnouncementPreview from '../preview/AnnouncementPreview';
import { TAnnouncementFormValues, TAnnouncementsFormTypes } from '../../../types/TAnnouncements';
import { SwitchableSectionContentWrapper } from './AnnouncementEditStyles';
import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import { FALLBACK_TIMEZONE } from '../../../constants/Constants';
import {
  getAreaOfInterest,
  getTitleFromWysiwyg,
  prepareFormValues,
  requiredTitleSchema,
  shouldSaveDraftButtonBeVisible,
} from '../../../utils/announcementsUtils';
import { generateCirclePolygonPaths, generatePolygon, getMeters } from '../../../utils/mapUtils';
import PageContentEditable, {
  ContentHeader,
  ContentHeaderWrapper,
  HeaderWrapper,
} from '../../layout/pageContent/PageContentEditable';
import { getPlaceOptions } from '../../../utils/placeUtils';
import FormButtons from './FormButtons';
import AnnouncementNotificationSection from './AnnouncementNotificationSection';
import useYTVideoInEditor from '../../../hooks/useYTVideoInEditor';
import useDateTimeChanged from '../../../hooks/useDateTimeChanged';
import { imagesValidation, notificationsValidation } from './validation';
import { TLoggedUser } from '../../../types/TUser';
import { getCategoriesOptions, getInitialCategoriesOptions } from '../../../utils/categoriesUtils';
import HighlightableElement from '../../highlightable-elements/HighlightableElement';
import { TAnnouncementCreationSteps } from '../../../types/TWalkthrough';
import AnnouncementAreaOfInterest from './AnnouncementAreaOfInterest';
import PostGeneratorBadge from '../../postGenerator/PostGeneratorBadge';
import { LinkDetailButton } from '../../common/LinkDetailButton';
import { getPostLink } from '../../../utils/postUtils';
import useChangePublisher from '../../../hooks/useChangePublisher';
import { useAuthInfo } from '../../../contexts/userContext';

const schema = (t: TFunction, timezone: string, isScheduling: boolean, isMultiCity: boolean) => {
  return Yup.object().shape({
    title: Yup.string(),
    categories: Yup.mixed().when('isLocalBusiness', {
      is: (isLocalBusiness?: boolean) => !isLocalBusiness,
      then: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
          }),
        )
        .compact()
        .min(1, t('common.validation.required')),
    }),
    content: Yup.string().test('equals', t('common.validation.required'), (value) => {
      return value !== SERIALIZED_WYSIWYG_EMPTY_VALUE;
    }),
    places: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null | undefined) => !(isMultiCity && (value || []).length < 1),
    ),
    scheduledAt: Yup.mixed()
      .test(
        'required',
        t('common.validation.required'),
        (value: MaybeDate) => !isScheduling || !!value,
      )
      .when('publishedAt', (publishedAt) =>
        Yup.date().test(
          'greaterThanPublished',
          t('common.validation.date.greaterThanPublished'),
          (value?: MaybeDate) => !isScheduling || (!!value && value > publishedAt),
        ),
      ),
    images: imagesValidation(t),
    videos: Yup.array().of(
      Yup.mixed()
        .test('fileSize', t('common.validation.file.tooLarge'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_VIDEO_UPLOAD_SIZE : true,
        )
        .test(
          'fileType',
          t('common.validation.file.unsupportedFormat'),
          (file: File | VideoInput) =>
            file && isFile(file) ? SUPPORTED_VIDEO_FORMATS.includes(file.type) : true,
        ),
    ),
    notifications: notificationsValidation(t, timezone),
    publishedAt: Yup.mixed()
      .test('required', t('common.validation.required'), (value: MaybeDate) => !!value)
      .test(
        'maxdate',
        t('common.validation.date.futureDate'),
        (value: Date) => value <= roundMinutes(utcToZonedTime(new Date(), timezone)),
      ),
  });
};

type Props = {
  user: TLoggedUser | null;
  showOnlyPreview?: boolean;
  onSubmit: (values: TAnnouncementFormValues) => Promise<void>;
  onSubmitDraft: (values: TAnnouncementFormValues) => Promise<void>;
  onDiscard: () => void;
  categories: TCategories;
  announcement?: TAnnouncementDetail;
  institution: TInstitution;
  userInstitutions: TInstitutionListItem[];
  institutionCenter: TMapCenter;
  postContent?: string;
  annoucementFormActions: TableIconProps[];
  volunteeringModeEnabled: boolean;
};

const AnnouncementsEdit: FC<Props> = ({
  user,
  showOnlyPreview,
  onSubmit,
  onSubmitDraft,
  onDiscard,
  announcement,
  categories,
  institution,
  userInstitutions,
  institutionCenter,
  postContent,
  annoucementFormActions,
  volunteeringModeEnabled,
}) => {
  const now = new Date();
  const isEdit = announcement !== undefined;
  const cantSchedule =
    announcement?.publishmentState === State.Published ||
    announcement?.publishmentState === State.Unpublished;
  const { isLocalBusiness } = useAuthInfo();
  const categoriesOptions = getCategoriesOptions(categories);

  const [isDraft, setIsDraft] = useState(false);
  const categoriesRef = useRef<TSelectRef>();
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const parsedPostContent = postContent ? JSON.parse(postContent) : undefined;
  const createdUsingAI = !!postContent;
  const institutionLang = institution?.lang || undefined;
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;
  const countryCode = places?.[0].countryCode || '';
  const { link } = getPostLink(announcement?.id ?? '');

  const [isScheduling, setScheduling] = useState(!!announcement?.scheduledAt && !cantSchedule);

  const institutionOptions = useMemo(
    () =>
      getInstitutionInitValues(
        user?.attributes?.['custom:institutionUuids'],
        institution,
        userInstitutions,
      ),
    [userInstitutions, institution, user?.attributes],
  );

  const placesOptions = useMemo(() => getPlaceOptions(institution?.places), [institution]);

  const initialCategories = useMemo(() => {
    if (isLocalBusiness) {
      return [{ label: institution?.category.name || '', value: institution?.category.id || '' }];
    }

    return getInitialCategoriesOptions(categoriesOptions, announcement?.categories);
  }, [isLocalBusiness, categoriesOptions, announcement, institution]);

  const isSaveDraftButtonBeVisible = shouldSaveDraftButtonBeVisible(
    announcement?.publishmentState,
    isScheduling,
  );

  const { publisherChanged, onPublisherChange } = useChangePublisher({ userInstitutions });

  useEffect(() => {
    setValue('isLocalBusiness', isLocalBusiness);
    if (isLocalBusiness) {
      trigger('categories');
    }
  }, [isLocalBusiness]);

  useEffect(() => {
    if (!publisherChanged) {
      return;
    }
    setValue('categories', initialCategories);
    categoriesRef?.current?.setValue(initialCategories);
    trigger('places');
  }, [publisherChanged]);

  const initialValues: TAnnouncementFormValues = {
    title: announcement?.title || getTitleFromWysiwyg(parsedPostContent) || '',
    content: announcement?.content || postContent || SERIALIZED_WYSIWYG_EMPTY_VALUE,
    lang: getLangOptionByVal(announcement?.lang || institutionLang, countryCode),
    images: announcement?.imageObjects,
    videos: announcement?.videoObjects,
    categories: initialCategories,
    places: getPlaceOptions(announcement?.places),
    publisher: { label: institution?.name || '', value: institution?.id || '' },
    scheduledAt:
      announcement?.scheduledAt && !cantSchedule
        ? utcToZonedTime(announcement?.scheduledAt, timezone)
        : undefined,
    notifyNow: isEdit ? !!announcement?.notifyNow : false,
    notifications: announcement?.notifications
      ? announcement?.notifications.map((notification) => ({
          value: utcToZonedTime(notification, timezone),
        }))
      : [],
    publishedAt: announcement?.publishedAt
      ? utcToZonedTime(announcement?.publishedAt, timezone)
      : utcToZonedTime(now, timezone),
    notifyAlsoNonCategoried: announcement?.notifyAlsoNonCategoried || false,
    notifyVolunteersOnly: (announcement?.notifyVolunteersOnly && volunteeringModeEnabled) || false,
    areaOfInterest: getAreaOfInterest(announcement?.areaOfInterest),
    isLocalBusiness,
  };

  const shouldShowCitySelect = (placesOptions || []).length > 1;

  const methods = useForm<TAnnouncementFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, timezone, isScheduling, shouldShowCitySelect)),
    mode: 'all',
  });

  const { handleSubmit, register, formState, setValue, getValues, trigger, control } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const [
    previewTitle,
    previewContent,
    previewLang,
    previewImages,
    previewVideos,
    previewCategories,
    previewPublisher,
    previewAreaOfInterest,
  ] = useWatch({
    name: [
      'title',
      'content',
      'lang',
      'images',
      'videos',
      'categories',
      'publisher',
      'areaOfInterest',
    ],
    control,
  });

  const { onDateTimeChanged: onScheduleDateTimeChanged } = useDateTimeChanged(
    'scheduledAt',
    setValue,
    trigger,
  );

  const { onDateTimeChanged: onPublishedAtDateTimeChanged } = useDateTimeChanged(
    'publishedAt',
    setValue,
    trigger,
  );

  const initialPublishedAtDate = initialValues.publishedAt || utcToZonedTime(now, timezone);

  const initialScheduleDate = initialValues.scheduledAt || roundMinutes(initialPublishedAtDate);

  const onSchedulingSectionVisibilityChanged = () => {
    setValue(
      'scheduledAt',
      !isScheduling ? roundMinutes(utcToZonedTime(now, timezone)) : undefined,
    );
    setScheduling(!isScheduling);
    trigger('scheduledAt');
  };

  const onNotificationsEnabledChange = (value: boolean) => {
    setValue('notifyNow', value);
  };

  const onNotifyAlsoNonCategoriedChange = (value: boolean) => {
    setValue('notifyAlsoNonCategoried', value);
  };

  const onNotifyVolunteersOnlyChange = (value: boolean) => {
    setValue('notifyVolunteersOnly', value);
  };

  const onAreaOfInterestVisibilityChanged = useCallback((value: boolean) => {
    if (!value) {
      setValue('areaOfInterest', null);
      return;
    }
    const radius = getMeters(DEFAULT_RADIUS_MILES_STRING);
    const hexagonPolygonPaths = generateCirclePolygonPaths(institutionCenter, radius);
    const newAreaOfInterest = generatePolygon(hexagonPolygonPaths);
    setValue('areaOfInterest', previewAreaOfInterest ?? newAreaOfInterest);
  }, []);

  const onAreaOfInterestChange = (value: TGeoShape | null) => {
    setValue('areaOfInterest', value);
  };

  useEffect(() => {
    trigger('scheduledAt');
  }, [isScheduling, trigger]);

  const submit = async (values: TAnnouncementFormValues) =>
    onSubmit(prepareFormValues(values, isScheduling, timezone));

  const submitDraft = async (values: TAnnouncementFormValues) =>
    onSubmitDraft(prepareFormValues(values, isScheduling, timezone, true));

  const onSubmitClick = () => {
    setIsDraft(false);
    handleSubmit(submit)();
  };

  const onSaveDraftClick = () => {
    setIsDraft(true);
    methods.control._updateProps({
      resolver: yupResolver(requiredTitleSchema(t)),
    });
    handleSubmit(submitDraft)();
  };

  const pageTitle = announcement
    ? t('updates.form.editPageTitle')
    : t('updates.form.createPageTitle');

  const showPublishers = institutionOptions?.length > 1;

  const { youtubeModal, hasYTVideo } = useYTVideoInEditor(JSON.parse(getValues('content')));

  useEffect(() => {
    if (hasYTVideo) {
      setValue('images', []);
      setValue('videos', []);
    }
  }, [hasYTVideo]);

  const showImage = !previewVideos?.length && !hasYTVideo;
  const showVideo = !previewImages?.length && !hasYTVideo;

  useEffect(() => {
    if (!showImage) {
      setValue('images', []);
    }
  }, [showImage]);

  useEffect(() => {
    if (!showVideo) {
      setValue('videos', []);
    }
  }, [showVideo]);

  const minScheduledDate = roundMinutes(getValues('publishedAt') || utcToZonedTime(now, timezone));

  return (
    <HighlightableElement shouldUseOutline={false} id={TAnnouncementCreationSteps.STEP1}>
      <PageContentEditable
        showOnlyPreview={showOnlyPreview}
        previewTitle={t('updates.preview.title')}
        preview={
          <AnnouncementPreview
            title={previewTitle}
            images={previewImages}
            lang={previewLang}
            videos={previewVideos}
            content={previewContent}
            categories={previewCategories}
            publisher={previewPublisher}
          />
        }
      >
        {youtubeModal}
        <FormWrapper>
          <ActionButtons actionButtons={annoucementFormActions}>
            {!!announcement && announcement.publishmentState === State.Published && (
              <LinkDetailButton value={link} />
            )}
          </ActionButtons>
          <HeaderWrapper>
            <ContentHeaderWrapper>
              <ContentHeader noPadding>{pageTitle}</ContentHeader>
            </ContentHeaderWrapper>
            {createdUsingAI ? <PostGeneratorBadge /> : null}
          </HeaderWrapper>
          <FormProvider {...methods}>
            <HighlightableElement id={TAnnouncementCreationSteps.STEP2}>
              <FormInput
                label={t('updates.form.title')}
                {...register('title')}
                error={errors.title}
                testId="AnnouncementEdit#title"
              />

              <FormWysiwyg
                control={control}
                name="content"
                label={t('updates.form.content')}
                initialValue={JSON.parse(getValues('content'))}
                testId="AnnouncementEdit#wysiwyg"
              />
            </HighlightableElement>
            <HighlightableElement id={TAnnouncementCreationSteps.STEP3}>
              <FormSectionHeader
                title={t('updates.form.language')}
                description={t('updates.form.languageDescription')}
              />

              <FormSelect
                control={control}
                name="lang"
                options={getLangOptions()}
                defaultValue={initialValues.lang}
                testId={'Announcement#languageSlect'}
                allSelectable
              />
            </HighlightableElement>
            {showPublishers && (
              <>
                <FormSectionHeader
                  title={t('updates.form.publisher')}
                  description={t('updates.form.publisherDescription')}
                />
                <FormSelect
                  control={control}
                  name="publisher"
                  error={get(errors, 'publisher')}
                  options={institutionOptions || []}
                  defaultValue={initialValues.publisher}
                  onChange={onPublisherChange}
                  testId="AnnouncementEdit#publisher"
                />
              </>
            )}
            <HighlightableElement id={TAnnouncementCreationSteps.STEP4}>
              {!isLocalBusiness && (
                <>
                  <FormSectionHeader
                    title={t('updates.form.category')}
                    description={t('updates.form.categoryDescription')}
                  />
                  <FormSelect
                    control={control}
                    name="categories"
                    error={get(errors, 'categories')}
                    options={categoriesOptions}
                    defaultValue={initialValues.categories}
                    ref={categoriesRef}
                    testId="AnnouncementEdit#categories"
                    disabled={isLocalBusiness}
                    isMulti
                  />
                </>
              )}
            </HighlightableElement>
            {shouldShowCitySelect && (
              <>
                <FormSectionHeader
                  title={t('updates.form.cities')}
                  description={t('updates.form.citiesDescription')}
                />
                <FormSelect
                  control={control}
                  name="places"
                  error={get(errors, 'places')}
                  options={placesOptions || []}
                  defaultValue={initialValues.places}
                  testId="AnnouncementEdit#places"
                  allSelectable
                  clearable
                  isMulti
                />
              </>
            )}
            {showImage && (
              <HighlightableElement id={TAnnouncementCreationSteps.STEP5}>
                <FormImageCropperUpload
                  initialValue={initialValues.images}
                  title={t('updates.form.imageLabel')}
                  description={t('updates.form.imageSublabel')}
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  trigger={trigger}
                  name="images"
                  dropzoneLabel={t('updates.form.dropzoneLabel')}
                  t={t}
                  multiple
                  testId="AnnouncementEdit#image"
                />
              </HighlightableElement>
            )}
            {showVideo && (
              <HighlightableElement id={TAnnouncementCreationSteps.STEP6}>
                <>
                  <FormSectionHeader
                    title={t('updates.form.videoLabel')}
                    description={t('updates.form.videoSublabel')}
                  />

                  <FormUpload
                    control={control}
                    name="videos"
                    dropzoneLabel={t('updates.form.dropzoneLabel')}
                    t={t}
                    testId="AnnouncementEdit#video"
                    accept={SUPPORTED_VIDEO_FORMATS}
                  />
                </>
              </HighlightableElement>
            )}
            <HighlightableElement id={TAnnouncementCreationSteps.STEP7}>
              <SwitchableFormSection
                title={t('updates.form.scheduleSection')}
                description={t('updates.form.scheduleSectionDescription')}
                onVisibilityChanged={onSchedulingSectionVisibilityChanged}
                initiallyOpened={isScheduling}
                disabled={cantSchedule}
                disabledText={t('updates.form.disabledShedule')}
                testId={'AnnouncementSchedule#switch'}
              >
                <SwitchableSectionContentWrapper>
                  <FormDateTimePicker
                    control={control}
                    name="scheduledAt"
                    initialDate={initialScheduleDate}
                    minDate={minScheduledDate}
                    onChange={onScheduleDateTimeChanged}
                    dateLabel=""
                    timeLabel=""
                    initialTime={format(initialScheduleDate, 'hh:mm')}
                    initialDayPeriod={format(initialScheduleDate, 'a')}
                    testId="AnnouncementSchedule#date"
                  />
                </SwitchableSectionContentWrapper>
              </SwitchableFormSection>
            </HighlightableElement>
            <FormSectionWithSwitch
              title={t('updates.form.notifyAll')}
              description={t('updates.form.notifyAllDescription')}
              testId="AnnoucementEdit#notifyAll"
              initialValue={initialValues.notifyAlsoNonCategoried ?? false}
              onChange={onNotifyAlsoNonCategoriedChange}
            />
            {volunteeringModeEnabled && (
              <FormSectionWithSwitch
                title={t('updates.form.notifyVolunteers')}
                description={t('updates.form.notifyVolunteersDescription')}
                initialValue={initialValues.notifyVolunteersOnly ?? false}
                onChange={onNotifyVolunteersOnlyChange}
                testId="AnnoucementEdit#notifyVolunteers"
              />
            )}
            <SwitchableFormSection
              title={t('updates.form.areaOfInterest')}
              description={t('updates.form.areaOfInterestDescription')}
              initiallyOpened={!!previewAreaOfInterest}
              onVisibilityChanged={onAreaOfInterestVisibilityChanged}
            >
              <SwitchableSectionContentWrapper>
                <AnnouncementAreaOfInterest
                  initalValue={initialValues.areaOfInterest || null}
                  currentValue={previewAreaOfInterest || null}
                  onChange={onAreaOfInterestChange}
                  center={institutionCenter}
                />
              </SwitchableSectionContentWrapper>
            </SwitchableFormSection>
            <HighlightableElement id={TAnnouncementCreationSteps.STEP8}>
              <AnnouncementNotificationSection
                control={control}
                notifyNow={initialValues.notifyNow}
                publishmentState={announcement?.publishmentState}
                isEdit={isEdit}
                announcementType={TAnnouncementsFormTypes.ANNOUNCEMENT}
                onNotificationsEnabledChange={onNotificationsEnabledChange}
              />
              <FormSectionHeader title={t('updates.form.publishedAt')} />
              <FormDateTimePicker
                control={control}
                name="publishedAt"
                initialDate={initialPublishedAtDate}
                onChange={onPublishedAtDateTimeChanged}
                dateLabel=""
                timeLabel=""
                initialTime={format(initialPublishedAtDate, 'hh:mm')}
                maxDate={roundMinutes(utcToZonedTime(now, timezone))}
                initialDayPeriod={format(initialPublishedAtDate, 'a')}
                testId="AnnouncementEdit#publishedAt"
                isTimePickerWritable
              />
            </HighlightableElement>
            <FormButtons
              isSubmitting={isSubmitting}
              isDraft={isDraft}
              getValues={getValues}
              errors={errors}
              publishmentState={announcement?.publishmentState}
              isScheduling={isScheduling}
              onSaveDraftClick={onSaveDraftClick}
              onDiscard={onDiscard}
              onSubmitClick={onSubmitClick}
              isSaveDraftButtonVisible={isSaveDraftButtonBeVisible}
            />
          </FormProvider>
        </FormWrapper>
        <RouteLeavingGuard when={isDirty && !isSubmitting} />
      </PageContentEditable>
    </HighlightableElement>
  );
};

export default AnnouncementsEdit;
