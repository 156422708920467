import { forwardRef, InputHTMLAttributes, ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { TBaseProps } from '../../types/TBaseComponent';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>, TBaseProps {
  invalid?: boolean;
  icon?: ReactElement;
  shouldStretch?: boolean;
  isSmallTexIndent?: boolean;
}

const Wrapper = styled.div<{ $shouldStretch?: boolean }>`
  position: relative;
  ${({ $shouldStretch }) => ($shouldStretch ? 'flex: 1;' : '')}
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: ${({ theme }) => theme.spaces.spacing48};
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spaces.spacing8}; ;
`;

const StyledInput = styled.input<{
  invalid?: boolean;
  iconEnabled?: boolean;
  isSmallTexIndent?: boolean;
}>`
  height: ${({ theme }) => theme.spaces.spacing48};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.coolGray100};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  text-indent: ${({ theme, isSmallTexIndent }) =>
    isSmallTexIndent ? theme.spaces.spacing8 : theme.spaces.spacing16};
  padding: 1px;
  outline-color: ${({ theme }) => theme.colors.blue60};
  font-size: ${({ theme }) => theme.fontSizes.small};

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.blue60};
    padding: 0;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.lightText};
  }
  ${({ invalid, theme }) =>
    invalid &&
    css`
      border: 2px solid ${theme.colors.red60};
    `}

  padding-right: ${({ theme, iconEnabled }) =>
    iconEnabled ? `${theme.spaces.spacing32} !important` : '1px'};
`;

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ testId, icon, shouldStretch, ...props }, ref) => {
    return (
      <Wrapper $shouldStretch={shouldStretch}>
        <IconWrapper>{icon}</IconWrapper>
        <StyledInput ref={ref} data-testid={testId} iconEnabled={!!icon} {...props} />
      </Wrapper>
    );
  },
);

Input.displayName = 'Input';

export { Input };
