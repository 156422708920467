import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  DropdownMenu,
  DropdownMenuItemProps,
  LogoutIcon,
  TBaseProps,
  ArrowDown,
  MediumText,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { TUserInstitutionList } from '@sim-admin-frontends/data-access';
import { generatePath, useHistory } from 'react-router-dom';

import { useAuthInfo } from '../../../contexts/userContext';
import { useInstitutionMenuItems } from '../../../hooks/useInstitutionMenuItems';
import ROUTES from '../../../routing/routes';
import { usePlaceInfo } from '../../../contexts/placeContext';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export interface UserPrefsHeaderProps extends TBaseProps {
  hasError?: boolean;
  institutions?: TUserInstitutionList;
}

const UserPrefsHeader: FC<UserPrefsHeaderProps> = ({ hasError, institutions }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setInstitutionUuid, institutionUuids, institutionUuid, logout, user } = useAuthInfo();
  const { clearPlaces } = usePlaceInfo();

  const theme = useTheme();

  const onSwitch = (newInstitutionUuid: string | undefined) => {
    if (!newInstitutionUuid) {
      return;
    }
    setInstitutionUuid(newInstitutionUuid);
    clearPlaces();
    history.push(generatePath(ROUTES.placeLoading.path));
  };

  const intitutionItems: DropdownMenuItemProps[] = useInstitutionMenuItems(
    institutionUuids,
    institutions || [],
    institutionUuid,
    onSwitch,
  );

  const menuItems: DropdownMenuItemProps[] = [
    ...(hasError ? [] : intitutionItems),
    {
      icon: <LogoutIcon fill={theme.colors.black} />,
      label: t('login.logout'),
      action: logout,
      type: 'normal',
      testId: 'UserHeaderMenu#Logout',
    },
  ];

  const userName = user?.username;

  return (
    <Wrapper>
      <DropdownMenu items={menuItems} testId="UserHeader#Dropdown">
        <ArrowDown height={32} width={24} />
        {userName && <MediumText>{userName}</MediumText>}
      </DropdownMenu>
    </Wrapper>
  );
};

export default UserPrefsHeader;
