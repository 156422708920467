/* eslint-disable @typescript-eslint/ban-ts-comment */
import { History, LocationState } from 'history';
import { generatePath } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import { CellProps } from 'react-table';
import { State, TQuickQuestionListItem } from '@sim-admin-frontends/data-access';
import { TableHeaderTitle, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { formatTableDate } from '@sim-admin-frontends/utils-shared';

import { TAnnouncementsTabTypes } from '../types/TAnnouncements';
import ROUTES from '../routing/routes';
import { getDisabledColumns, getPublishingDateAccessor } from './announcementsUtils';
import QuickQuestionsResultsPopup from '../components/quickQuestions/results/QuickQuestionsResultsPopup';
import { ChatTableMenu } from '../components/cityChat/view/ChatTableMenu';

export const changeChatTab = (history: History<LocationState>, type: TAnnouncementsTabTypes) => {
  history.push(
    generatePath(ROUTES.chatOverview.path, {
      type,
    }),
  );
};

export const createTableColumns = (t: TFunction, refetch: () => void, type: State) => {
  const disabledColumns = getDisabledColumns(type);
  const columns: TExtendedColumn<TQuickQuestionListItem>[] = [
    {
      Header: `${t('polls.table.title')}`,
      accessor: (item) => <TableHeaderTitle item={item.question} maxTextLength={120} />,
      testId: 'Chat#question',
      disableSortBy: true,
    },
    {
      Header: `${t('polls.table.published')}`,
      // @ts-ignore - No no typescript i know better - hopefully :)
      accessor: getPublishingDateAccessor(type),
      testId: 'Chat#publicationDate',
      Cell: disabledColumns.includes('publishedAt')
        ? ''
        : ({ value }: any) => formatTableDate(value),
      disabled: disabledColumns.includes('publishedAt'),
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('chat.table.answers')}`,
      id: '0',
      testId: 'Chat#results',
      disableSortBy: true,
      skeleton: {
        width: 50,
        align: 'right',
      },
      Cell: ({ row }: CellProps<TQuickQuestionListItem>) => {
        return <QuickQuestionsResultsPopup quickQuestion={row.original} showFooter={false} />;
      },
    },
    {
      Header: '',
      id: '1',
      testId: 'Chat#dots',
      disableSortBy: true,
      skeleton: {
        width: 50,
        align: 'right',
      },
      Cell: ({ row }: CellProps<TQuickQuestionListItem>) => {
        return (
          <ChatTableMenu
            type={type}
            postId={row.original.id}
            refetch={refetch}
            testId={`Chat#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  return columns;
};
