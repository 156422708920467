import { FC } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../../..';
import { MediumText, Caption1 } from '../../texts/Texts';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: flex-start;
  justify-content: flex-start;
  padding-right: ${({ theme }) => theme.spaces.spacing16};
`;

const Title = styled(MediumText)`
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  line-height: ${({ theme }) => theme.lineHeights.large};
`;

const Description = styled(Caption1)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.lightText};
  padding-top: ${({ theme }) => theme.spaces.spacing4};
  white-space: pre-line;
`;

export interface FormSectionHeaderProps extends TBaseProps {
  title?: string;
  description?: string;
  className?: string;
}

const SectionHeader: FC<FormSectionHeaderProps> = ({ title, description, className }) => {
  return (
    <Wrapper className={className}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Wrapper>
  );
};

const FormSectionHeader = styled(SectionHeader)<{ bottomMargin?: boolean }>`
  margin: ${({ theme }) => theme.spaces.spacing16} 0
    ${({ theme, bottomMargin = true }) => (bottomMargin ? theme.spaces.spacing16 : 0)} 0;
`;

export { SectionHeader, FormSectionHeader };
