import { KeyboardEvent } from 'react';
import { BaseSelection, Descendant, Editor, Element, Range, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, RenderLeafProps } from 'slate-react';
import styled from 'styled-components';
import {
  EMPTY_CHILDREN,
  TBlockType,
  TCustomElement,
  TImageElement,
  TLinkElement,
  TParagraphElement,
  TTextElement,
  TTextFormat,
  TVideoElement,
  TWysiwygEditor,
  TWysiwygValue,
} from '@simplicity-tech/sim-slate-types';

/**
 * ========= RENDERING =========
 */

const StyledIframe = styled.iframe`
  border: none;
  display: block;
`;

const Image = styled.img`
  max-width: 300px;
  height: auto;
  object-fit: cover;
`;

const Paragraph = styled.p`
  margin: ${({ theme }) => theme.spaces.spacing4} 0;
`;

export const renderElement = (props: RenderElementProps) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'link':
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      );
    case 'image':
      return <Image {...attributes} src={element?.source} alt={element.source} />;
    case 'bullet-list':
      return <ul {...attributes}>{children}</ul>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'video':
      return <StyledIframe title={element.source} {...attributes} src={element.source} />;
    default:
      return <Paragraph {...attributes}>{children}</Paragraph>;
  }
};

export const renderLeaf = (props: RenderLeafProps) => {
  return <Leaf {...props} />;
};

const Leaf = (props: RenderLeafProps) => {
  return (
    <span
      {...props.attributes}
      style={{
        fontWeight: props.leaf.bold ? 'bold' : 'normal',
        fontStyle: props.leaf.italic ? 'italic' : 'normal',
      }}
    >
      {props.children}
    </span>
  );
};

/**
 * ========= MARKS =========
 *
 * https://docs.slatejs.org/v/v0.47/slate-core/mark
 */

export const isMarkActive = (editor: Editor, format: TTextFormat) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: Editor, format: TTextFormat) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

/**
 * ========= BLOCKS =========
 */

export const isBlockActive = (editor: TWysiwygEditor, format: TBlockType) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
  });
  return !!match;
};

export const isList = (format: TBlockType): format is 'bullet-list' => {
  return format === 'bullet-list'; // Extend to include more lists if necessary in the future.
};

export const isTextElement = (element: Descendant): element is TTextElement => {
  return 'children' in element;
};

export const toggleBlock = (editor: TWysiwygEditor, format: TBlockType) => {
  const isActive = isBlockActive(editor, format);
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && isList(n.type),
    split: true,
  });
  const newProperties: Partial<Element> = {
    type: isActive ? 'paragraph' : isList(format) ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList(format)) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

/**
 * ========= LINK =========
 */

export const toggleLink = (editor: TWysiwygEditor) => {
  if (isBlockActive(editor, 'link')) {
    focusPreviousSelection(editor);
    unwrapLink(editor);
  } else {
    toggleMark(editor, 'futureLink');
    editor.blurSelection = editor.selection;
  }
};

export const insertLink = (editor: TWysiwygEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

export const unwrapLink = (editor: TWysiwygEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  });
};

export const wrapLink = (editor: TWysiwygEditor, url: string) => {
  const { selection } = editor;
  //If range is collapsed it means no text is selected https://docs.slatejs.org/v/v0.47/slate-core/range
  const isCollapsed = selection && Range.isCollapsed(selection);

  const link: TLinkElement = {
    type: 'link',
    url,
    children: [{ text: url, type: 'text' }],
  };

  if (isCollapsed) {
    //No text was selected just insert node at the end
    Transforms.insertNodes(editor, link);
  } else {
    //We have text selection - wrap selection into link and split parent element
    // e.g wrapping "link" in "This link is awesome" will result in three split leaves
    Transforms.wrapNodes(editor, link, { split: true });
    //Discard original text selection and move cursor to the end of original text selection
    Transforms.collapse(editor, { edge: 'end' });
  }
};

/**
 * ========= MEDIA =========
 */

export const wrapVideo = (editor: TWysiwygEditor, url: string) => {
  const { selection } = editor;
  const videoLink: TVideoElement = {
    type: 'video',
    source: url,
    children: EMPTY_CHILDREN,
  };
  const newLine: TParagraphElement = {
    type: 'paragraph',
    children: EMPTY_CHILDREN,
  };

  if (selection) {
    const node = editor.children[selection.anchor.path[0]] as TCustomElement | undefined;
    if (!isOnEmptyLine(selection)) editor.insertBreak();
    if (node && isList(node.type)) {
      toggleBlock(editor, 'bullet-list');
    }
  }
  Transforms.wrapNodes(editor, videoLink);
  Transforms.insertNodes(editor, newLine);
};

export const wrapImage = (editor: TWysiwygEditor, url: string) => {
  const { selection } = editor;

  const imageLink: TImageElement = {
    type: 'image',
    source: url,
    children: EMPTY_CHILDREN,
  };
  const newLine: TParagraphElement = {
    type: 'paragraph',
    children: EMPTY_CHILDREN,
  };

  if (selection) {
    const node = editor.children[selection.anchor.path[0]] as TCustomElement | undefined;
    if (!isOnEmptyLine(selection)) editor.insertBreak();
    if (node && isList(node.type)) {
      toggleBlock(editor, 'bullet-list');
    }
  }
  Transforms.wrapNodes(editor, imageLink);
  Transforms.insertNodes(editor, newLine);
};

/**
 * ========= HELPERS =========
 */

export const focusPreviousSelection = (editor: TWysiwygEditor) => {
  ReactEditor.focus(editor);
  Transforms.select(
    editor,
    editor.selection ||
      editor.blurSelection || {
        path: [0, 0],
        offset: 0,
      },
  );
};

export const handleEnter = (editor: TWysiwygEditor, event: KeyboardEvent<HTMLDivElement>) => {
  const { selection } = editor;
  if (selection) {
    // Handles enter-to-jump-out of bullet list when on empty bullet
    if (isOnEmptyLine(selection)) {
      const node = editor.children[selection.anchor.path[0]] as TCustomElement;
      if (node && isList(node.type)) {
        event.preventDefault();
        toggleBlock(editor, 'bullet-list');
      }
    }
  }
};

export const handleSpace = (editor: TWysiwygEditor, event: KeyboardEvent<HTMLDivElement>) => {
  // Handles "-" + space or "*" + space to start bullet list
  const { selection } = editor;
  if (
    selection &&
    selection.focus.offset === 1 &&
    selection.anchor.offset === 1 &&
    Range.isCollapsed(selection)
  ) {
    const node = editor.children[selection.anchor.path[0]];
    if (
      node &&
      isTextElement(node) &&
      node?.children[0].type === 'text' &&
      (node?.children[0]?.text === '-' || node?.children[0]?.text === '*')
    ) {
      toggleBlock(editor, 'bullet-list');
      event.preventDefault();
      editor.deleteBackward('character');
    }
  }
};

export const isOnEmptyLine = (selection: BaseSelection) =>
  selection &&
  selection.focus.offset === 0 &&
  selection.anchor.offset === 0 &&
  Range.isCollapsed(selection);

export const sanitizeWysiwygValue = (value: TWysiwygValue): any =>
  value.map((node) => {
    if (node.type === 'text') {
      return node;
    }

    if (node.children) {
      if (!node.children.length) {
        return {
          ...node,
          children: [{ text: node.type === 'link' ? node.url : '', type: 'text' }],
        };
      }

      return { ...node, children: sanitizeWysiwygValue(node.children) };
    }

    return node;
  });
