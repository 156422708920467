import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingRule, usePagination, useSortBy, useTable } from 'react-table';
import { useTheme } from 'styled-components';
import '../../types/react-table-config.d'; //FIXME: This looks fishy

import { TExtendedColumn } from '../..';
import { TBaseProps } from '../../types/TBaseComponent';
import SortableHeaderCell from './SortableHeaderCell';
import TableSkeleton from './TableSkeleton';
import {
  ButtonsWrapper,
  ItemsLabel,
  NoDataWrapper,
  PaginationButton,
  PaginationSelectWrapper,
  PaginationWrapper,
  StyledArrowLeft,
  StyledArrowRight,
  TableWrapper,
} from './TableStyles';

export interface TableProps<T extends Record<string, unknown>> extends TBaseProps {
  data: T[];
  columns: TExtendedColumn<T>[];
  hasPagination?: boolean;
  hasNextPage?: boolean;
  onNextPress?: () => void;
  onPreviousPress?: () => void;
  onRowPress?: (id: string) => void;
  pageIndex?: number;
  itemsCount?: number;
  loading: boolean;
  initialSortBy?: SortingRule<T>[];
  onSortingChanged?: (sorting: SortingRule<T>[]) => void;
  tablePageSize?: number;
  noRightPadding?: boolean;
  responsive?: boolean;
}

export const TABLE_PAGE_SIZE = 10;

const Table = <T extends Record<string, unknown>>({
  data,
  columns,
  hasPagination = true,
  hasNextPage,
  onNextPress,
  onPreviousPress,
  onRowPress,
  pageIndex,
  itemsCount,
  loading,
  testId,
  initialSortBy = [],
  onSortingChanged,
  tablePageSize,
  noRightPadding,
  responsive,
}: TableProps<T>) => {
  const { t } = useTranslation();
  const memoizedInitialSortBy = useMemo(() => initialSortBy, [initialSortBy]);
  const pageSize = tablePageSize ?? TABLE_PAGE_SIZE;
  const theme = useTheme();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    canNextPage,
    prepareRow,
    page,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: memoizedInitialSortBy,
        pageSize: hasPagination ? pageSize : Number.MAX_SAFE_INTEGER,
      },

      manualPagination: true,
      pageCount: -1, // We have server side sorting -1 is desired
      manualSortBy: true, // We have server side sorting
      disableSortRemove: true, // we dont want to have unsorted state ...yet
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    if (sortBy && onSortingChanged) {
      onSortingChanged(sortBy);
    }
  }, [sortBy, onSortingChanged]);

  const canPreviousPage = pageIndex !== 0;
  const isNextPageDisabled = hasNextPage !== undefined ? !hasNextPage : !canNextPage;
  const currentPage = pageIndex || 0;
  const totalItemsCount = itemsCount || 0;

  const generateItemsString = () => {
    if (!itemsCount) {
      if (currentPage === 0) {
        return `1 - ${pageSize}`;
      }
      return `${currentPage * pageSize + 1} - ${currentPage * pageSize + page.length}`;
    }
    if (currentPage === 0) {
      return t('table.itemsString', {
        items: pageSize > itemsCount ? `1 - ${itemsCount}` : `1 - ${pageSize}`,
        itemsCount: totalItemsCount,
      });
    }
    return t('table.itemsString', {
      items: `${currentPage * pageSize + 1} - ${currentPage * pageSize + page.length}`,
      itemsCount: totalItemsCount,
    });
  };

  const onNextPagePress = () => {
    if (hasNextPage !== undefined) {
      if (hasNextPage && onNextPress) {
        onNextPress();
      }
    } else {
      if (canNextPage && onNextPress) {
        onNextPress();
      }
    }
  };

  return (
    <TableWrapper noRightPadding={noRightPadding} responsive={responsive}>
      <table {...getTableProps()} data-testid={testId}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column) => {
                return (
                  <SortableHeaderCell
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(),
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                    sortable={!column.disableSortBy}
                    sorted={column.isSorted}
                    desc={column.isSortedDesc}
                    disabled={column.disabled}
                    key={column.id}
                    data-testid={column.testId}
                  >
                    {column.render('Header')}
                  </SortableHeaderCell>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <TableSkeleton columns={columns} />
          ) : (
            page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={row.id}
                  onClick={() => onRowPress && onRowPress(row.original.id as string)}
                >
                  {row.cells.map((cell, cellId) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                            background: cell.column.background?.(row, cell) ?? theme.colors.white,
                          },
                        })}
                        key={cellId}
                        title={cell.column.title?.(cell.value)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {data.length < 1 && !loading && (
        <NoDataWrapper>
          <div>{t('common.noData')}</div>
        </NoDataWrapper>
      )}
      {hasPagination && (
        <PaginationWrapper>
          <div>
            <ItemsLabel>{`${generateItemsString()}`}</ItemsLabel>
          </div>
          <ButtonsWrapper>
            <PaginationSelectWrapper>
              <ItemsLabel>
                {itemsCount
                  ? t('table.pageString', {
                      pageIndex: data.length < 1 ? '0' : (currentPage + 1).toString(),
                      pageCount: Math.ceil(itemsCount / pageSize).toString(),
                    })
                  : t('table.pageStringNoCount', {
                      pageIndex: data.length < 1 ? '0' : (currentPage + 1).toString(),
                    })}
              </ItemsLabel>
            </PaginationSelectWrapper>
            <PaginationButton
              disabled={!canPreviousPage}
              onClick={canPreviousPage ? onPreviousPress : undefined}
            >
              <StyledArrowLeft disabled={!canPreviousPage} />
            </PaginationButton>
            <PaginationButton disabled={isNextPageDisabled} onClick={onNextPagePress}>
              <StyledArrowRight disabled={isNextPageDisabled} />
            </PaginationButton>
          </ButtonsWrapper>
        </PaginationWrapper>
      )}
    </TableWrapper>
  );
};

export { Table };
