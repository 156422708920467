import { State } from '@sim-admin-frontends/data-access';
import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateQuickQuestionActions } from '../../../hooks/actionButtons/useGenerateQuickQuestionActions';

export interface QuickQuestionsTableMenuProps extends TBaseProps {
  postId: string;
  refetch: () => void;
  type: State;
}

const QuickQuestionsTableMenu: FC<QuickQuestionsTableMenuProps> = ({
  postId,
  refetch,
  testId,
  type,
}) => {
  const { listActions: items, renderModal } = useGenerateQuickQuestionActions({
    postId,
    refetch,
    type,
  });

  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export { QuickQuestionsTableMenu };
