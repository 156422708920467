const theme = {
  name: 'default',
  colors: {
    transparent: '#00000000',
    primary: '#1b55e3',
    primaryLight: '#3669e7',
    secondary: '#f39834',
    secondaryLight: '#f5a751',
    background: '#161537',
    menuBackground: '#FAFAFA',
    lighterText: '#aca6cc',
    lightText: '#697077',
    darkText: '#161537',
    white: '#fff',
    black: '#000',
    disabledGray: '#FBFBFA',
    orange500: '#f39834',
    orange400: '#f5a751',
    green500: '#46be8a',
    green400: '#5dc698',
    blue10: '#edf5ff',
    blue500: '#1b55e3',
    blue400: '#3669e7',
    blue50: '#4285F4',
    blue60: '#0F62FE',
    red10: '#FFF1F1',
    red60: '#DA1E28',
    red500: '#fb434a',
    red40: '#FF8389',
    red400: '#fc6167',
    gray500: '#6a7a84',
    gray400: '#798993',
    gray200: '#e4e9f0',
    purple60: '#CB6DAB',
    yellow: '#f5B100',
    yellow10: '#FFF7CE',
    yellow50: '#FAB838',
    coolGray10: '#F2F4F8',
    coolGray20: '#DDE1E6',
    coolGray30: '#C1C7CD',
    coolGray40: '#A2A9B0',
    coolGray50: '#878D96',
    coolGray60: '#697077',
    coolGray70: '#4D5358',
    coolGray80: '#343A3F',
    coolGray90: '#21272A',
    coolGray100: '#121619',
    whiteOpacity70: '#FFFFFFB4',
    green10: '#DEFBE6',
    green60: '#198038',
    green70: '#0E6027',
  },
  fonts: {
    primary: 'Manrope',
  },
  fontSizes: {
    extraSmall: '0.6rem',
    smallest: '0.75rem',
    small: '0.8125rem',
    caption1: '0.875rem',
    caption2: '0.9rem',
    medium: '1rem',
    large: '1.25rem',
    preview: '1.0625rem',
    heading1: '1.375rem',
    xLarge: '1.5rem',
    xxLarge: '2rem',
    heading2: '3rem',
    body: '0.75rem',
  },
  fontWeights: {
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  lineHeights: {
    small: '1rem',
    caption1: '1.25rem',
    medium: '1.25rem',
    large: '1.5rem',
    xLarge: '1.75rem',
    xxLarge: '2.75rem',
    heading2: '3.5rem',
  },
  letterSpacing: {
    heading1: '-0.02em',
  },
  spaces: {
    spacing2: '2px',
    spacing4: '4px',
    spacing6: '6px',
    spacing8: '8px',
    spacing16: '16px',
    spacing20: '20px',
    spacing24: '24px',
    spacing32: '32px',
    spacing64: '64px',
    spacing72: '72px',
    spacing10: '10px',
    spacing12: '12px',
    spacing40: '40px',
    spacing48: '48px',
    spacing80: '80px',
    spacing96: '96px',
    spacing120: '120px',
  },
  borderRadius: {
    radiusMax: '100px',
    radius4: '4px',
    radius8: '8px',
    radius16: '16px',
  },

  zIndex: {
    dashBoardAnalyticsDatePopup: 2,
    datePickerPopup: 999,
    selectPopup: 999,
    wysiwygLinkTooltip: 4,
    neighborhoodPolygon: 999,
  },
};

export type TTheme = typeof theme;

export default theme;
