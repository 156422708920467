import { FC, useState } from 'react';
import ReactSlider from 'react-slider';
import styled from 'styled-components';

import { TBaseProps } from '../../types/TBaseComponent';
import { SliderValue } from '../../types/TSlider';
import { SliderThumb, SliderTrack } from './helper';

export const DEFAULT_STEP_VALUE = 0.05;

const StyledSlider = styled(ReactSlider)`
  flex: 1;
  height: ${({ theme }) => theme.spaces.spacing16};
`;

export interface SliderProps extends TBaseProps {
  onChange?: (value: SliderValue, index: number) => void;
  min?: number;
  max: number;
  step?: number;
  defaultValue?: number;
}

const Slider: FC<SliderProps> = ({
  onChange,
  min = 0,
  max,
  step = DEFAULT_STEP_VALUE,
  defaultValue,
}) => {
  const [sliderValue, setSliderValue] = useState<SliderValue>(defaultValue || min);

  const onChangeMerged = (value: SliderValue, index: number) => {
    if (onChange) {
      onChange(value, index);
    }
    setSliderValue(value);
  };

  return (
    <StyledSlider
      onChange={onChangeMerged}
      min={min}
      max={max}
      step={step}
      defaultValue={defaultValue}
      renderTrack={SliderTrack}
      renderThumb={SliderThumb}
      value={sliderValue}
    />
  );
};

export { Slider };
