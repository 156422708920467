import { FC } from 'react';
import {
  useInstitutionBrandingQuery,
  useInstitutionInfoQuery,
} from '@sim-admin-frontends/data-access';
import {
  Button,
  CouponFilledIcon,
  PanelTitle,
  PinIcon,
  Share1Icon,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useDecodeFilesToBlobs } from '@sim-admin-frontends/utils-shared';

import { TCouponFormValues } from '../../../types/TCoupons';
import PageContentPreview from '../../layout/pageContent/PageContentPreview';
import { useAuthInfo } from '../../../contexts/userContext';
import { FALLBACK_INSTITUTION_NAME } from '../../../constants/Constants';
import { ContentPreview } from '../../layout/pageContent/PageContentPreviewStyles';
import PreviewItemImageInList from '../../previewItem/PreviewItemImageInList';
import couponOverlay from '../../../assets/img/couponOverlay.png';
import WideImageWithTexts from '../../previewItem/image/WideImageWithTexts';
import PreviewItemText from '../../previewItem/PreviewItemText';
import { RowText } from '../../previewItem/PreviewItemIconText';
import CouponPreviewRequirements from './CouponPreviewRequirements';
import PreviewItemLocation from '../../previewItem/PreviewItemLocation';
import PreviewItemInstitution from '../../previewItem/PreviewItemInstitution';
import { getTestId } from '../../../utils/testUtils';

const Wrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spaces.spacing8};
`;

const StyledTitle = styled(PanelTitle)`
  padding: ${({ theme }) => theme.spaces.spacing24} 0;
`;

const InstitutionWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spaces.spacing24};
`;

const DescriptionWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.spacing16} 0;
`;

const IconWrapper = styled.div`
  width: ${({ theme }) => theme.spaces.spacing20};
  height: ${({ theme }) => theme.spaces.spacing20};
  margin-right: ${({ theme }) => theme.spaces.spacing12};
`;

const ActivateButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spaces.spacing40};
  margin-bottom: ${({ theme }) => theme.spaces.spacing64};
`;

const CouponPreview: FC<Partial<TCouponFormValues>> = ({
  images,
  title,
  subtitle,
  description,
  fromDate,
  fromTime,
  fromTimePeriod,
  toDate,
  toTime,
  toTimePeriod,
  recurrence,
}) => {
  const testIdPrefix = 'CouponPreview';
  const testIdPrefixDetail = 'CouponPreviewDetail';
  const { t } = useTranslation();

  const { institutionUuid } = useAuthInfo();
  const { data: institutionBranding } = useInstitutionBrandingQuery({ id: institutionUuid });
  const { data: institutionInfo } = useInstitutionInfoQuery({ id: institutionUuid });

  const institutionName = institutionInfo?.institution?.name || FALLBACK_INSTITUTION_NAME;
  const locationInfo = institutionInfo?.institution?.info?.location;
  const institutionLogo = institutionBranding?.institution?.branding?.avatar.links?.self || '';

  const fromToDate =
    fromDate && toDate
      ? `${format(fromDate, 'iii, MMM d, y')} - ${format(toDate, 'MMM d, y')}`
      : '';

  const fromToTime =
    fromTime && fromTimePeriod && toTime && toTimePeriod
      ? `${fromTime} ${fromTimePeriod} - ${toTime} ${toTimePeriod}`
      : null;

  const location = locationInfo
    ? {
        gps: locationInfo.geo,
        name: locationInfo.address,
      }
    : null;

  const image = useDecodeFilesToBlobs(images)[0];

  const isRecurring = !!recurrence;

  const headerIcons = (
    <>
      <IconWrapper>
        <PinIcon />
      </IconWrapper>
      <IconWrapper>
        <Share1Icon />
      </IconWrapper>
    </>
  );

  return (
    <>
      <PageContentPreview
        name={institutionName}
        logo={institutionLogo}
        icons={headerIcons}
        testIdPrefix={testIdPrefix}
      >
        <ContentPreview>
          <PreviewItemImageInList
            image={image}
            placeHolderImage={<CouponFilledIcon />}
            overlay={couponOverlay}
            title={title}
            subtitle={subtitle}
            testIdPrefix={testIdPrefixDetail}
          />
          <StyledTitle>{t('coupons.preview.detailTitle')}</StyledTitle>
          <InstitutionWrapper>
            <PreviewItemInstitution
              logo={institutionLogo}
              name={institutionName}
              testIdPrefix={testIdPrefixDetail}
            />
          </InstitutionWrapper>
          <WideImageWithTexts
            image={image}
            overlay={couponOverlay}
            title={title || ''}
            subtitle={subtitle || ''}
            testId={getTestId(testIdPrefixDetail, 'WideImage')}
          />
          <DescriptionWrapper>
            <PreviewItemText
              slateText={description}
              textPlaceholder={t('updates.preview.contentPlaceholder')}
              testIdPrefix={testIdPrefix}
            />
          </DescriptionWrapper>
          <RowText isFirst>{t('coupon.form.requirements')}</RowText>
          <CouponPreviewRequirements
            fromToDate={fromToDate}
            fromToTime={fromToTime}
            isReccuring={isRecurring}
            testIdPrefix={testIdPrefixDetail}
          />
          <RowText isFirst>{t('common.location')}</RowText>
          <Wrapper>
            <PreviewItemLocation
              location={location}
              placeholderText={t('coupons.preview.locationPlaceholder')}
              testIdPrefix={testIdPrefixDetail}
            />
          </Wrapper>
          <ActivateButtonWrapper>
            <Button variant="dark" size="larger" disabled>
              {t('coupons.activate')}
            </Button>
          </ActivateButtonWrapper>
        </ContentPreview>
      </PageContentPreview>
    </>
  );
};

export default CouponPreview;
