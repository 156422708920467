import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormInput, SmallText, Button, ArrowUpWithLine2 } from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ReactComponent as Logo } from '../../assets/img/simplicity_logo-full.svg';
import { TForgotFormValues, TLoginFormValues } from '../../types/TUser';
import ROUTES from '../../routing/routes';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeadlineText = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spaces.spacing32};
`;

const FormWrapper = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.coolGray100};
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  text-decoration: none;
  & > svg {
    margin-right: ${({ theme }) => theme.spaces.spacing8};
    transform: rotate(-90deg);
  }
  &:hover {
    box-shadow: 0 1px 0 0 ${({ theme }) => theme.colors.coolGray100};
  }
`;

const SubmitButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  width: 100%;
`;

const schema = (t: TFunction) =>
  Yup.object().shape({
    username: Yup.string().required(t('common.validation.required')),
  });

type Props = {
  onForgot: (values: TForgotFormValues) => Promise<void>;
};

const ForgotPassword: FC<Props> = ({ onForgot }) => {
  const { t } = useTranslation();
  const { handleSubmit, register, formState } = useForm<TLoginFormValues>({
    resolver: yupResolver(schema(t)),
  });
  const { errors, isSubmitting } = formState;
  const onSubmit = async (values: TLoginFormValues) => {
    return onForgot(values);
  };

  const handleKeyPress = (keyCode: string) => {
    if (keyCode === 'Enter' || keyCode === 'NumpadEnter') {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <Wrapper>
      <FormWrapper>
        <Logo />
        <HeadlineText>{t('forgotPassword.headline')}</HeadlineText>
        <FormInput
          label={t('forgotPassword.username')}
          {...register('username', { required: true })}
          error={errors.username}
          onKeyDown={(event) => handleKeyPress(event.code)}
          testId="ForgotPassword#username"
        />
        <SubmitButton
          size="larger"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          disabled={isSubmitting}
          testId="ForgotPassword#submit"
        >
          {t('forgotPassword.submit')}
        </SubmitButton>
        <StyledLink to={ROUTES.login.path}>
          <ArrowUpWithLine2 />
          {t('login.backToLogin')}
        </StyledLink>
      </FormWrapper>
    </Wrapper>
  );
};

export default ForgotPassword;
