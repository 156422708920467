import { FC, useState } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import Tippy from '@tippyjs/react';

import { TBaseProps } from '../../types/TBaseComponent';
import { MediumText } from '../texts/Texts';
import { CollapseSideBarIcon, EyeIcon } from '../..';

const TRANSITION_MS = 400;

const Panel = styled.div<{ width: number; side: 'left' | 'right' }>`
  width: ${({ width }) => `${width}px`};
  min-width: ${({ width }) => `${width}px`};
  height: 100%;
  max-height: 100%;
  transition: all ${TRANSITION_MS}ms ease;
  display: flex;
  flex-direction: column;
  position: relative;
  border-left: ${({ side, theme }) =>
    side === 'left' ? 0 : `1px solid ${theme.colors.coolGray20}`};
  border-right: ${({ side, theme }) =>
    side === 'right' ? 0 : `1px solid ${theme.colors.coolGray20}`};
  box-sizing: border-box;
`;

const Header = styled.div<{ side: 'left' | 'right'; collapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ collapsed }) => (collapsed ? 'flex-end' : 'space-between')};
  flex-direction: ${({ side }) => (side === 'left' ? 'row-reverse' : 'row')};
  padding: ${({ theme }) => `${theme.spaces.spacing24} ${theme.spaces.spacing16}`};
`;

export const PanelTitle = styled(MediumText)`
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  line-height: ${({ theme }) => theme.lineHeights.large};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.fade-enter {
    opacity: 0;
    width: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    width: unset;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    display: none;
  }
  transition: opacity ${TRANSITION_MS}ms;
`;

const IconWrapper = styled.div<{ side: 'left' | 'right' }>`
  transform: ${({ side }) => (side === 'left' ? 'rotate(180deg) ' : 'scale(1)')};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  transition: opacity ${TRANSITION_MS}ms;
  overflow: hidden;
  flex: 1;

  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 0;
  }
  &.fade-exit {
    display: none;
  }
`;

const StyledTippy = styled(Tippy)`
  &.tippy-box {
    > .tippy-content {
      padding: ${({ theme }) => theme.spaces.spacing8};
    }
  }
`;

export interface CollapsiblePanelProps extends TBaseProps {
  collapsedWidth: number;
  expandedWidth: number;
  title: string;
  side: 'left' | 'right';
  initiallyCollapsed?: boolean;
  collapsedTooltip?: string;
  expandedTooltip?: string;
}

const CollapsiblePanel: FC<CollapsiblePanelProps> = ({
  collapsedWidth,
  expandedWidth,
  children,
  initiallyCollapsed = false,
  title,
  className,
  side,
  collapsedTooltip = 'Show preview',
  expandedTooltip = 'Hide preview',
}) => {
  const [collapsed, setCollapsed] = useState(initiallyCollapsed);
  const width = collapsed ? collapsedWidth : expandedWidth;
  const onIconClick = () => {
    setCollapsed(!collapsed);
  };
  const tooltip = collapsed ? collapsedTooltip : expandedTooltip;

  return (
    <Panel width={width} side={side} className={className}>
      <Header side={side} collapsed={collapsed}>
        <CSSTransition in={!collapsed} timeout={TRANSITION_MS} classNames={'fade'} unmountOnExit>
          <PanelTitle data-testid="PreviewTitle">{title}</PanelTitle>
        </CSSTransition>
        <IconWrapper data-testid="PreviewIcon" onClick={onIconClick} side={side}>
          <StyledTippy placement="left" content={tooltip} delay={200}>
            {collapsed ? <EyeIcon /> : <CollapseSideBarIcon />}
          </StyledTippy>
        </IconWrapper>
      </Header>
      <CSSTransition in={!collapsed} timeout={TRANSITION_MS} classNames={'fade'} unmountOnExit>
        <Content>{children}</Content>
      </CSSTransition>
    </Panel>
  );
};

export { CollapsiblePanel };
