import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateEventActions } from '../../../hooks/actionButtons/useGenerateEventActions';

export interface EventsTableMenuProps extends TBaseProps {
  eventId: string;
  isFromAdmin?: boolean | null;
  refetch: () => void;
  published: boolean;
}

const EventsTableMenu: FC<EventsTableMenuProps> = ({
  eventId,
  isFromAdmin,
  refetch,
  published,
  testId,
}) => {
  const { listActions: items, renderModal } = useGenerateEventActions({
    isFromAdmin,
    eventId,
    published,
    refetch,
  });

  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export default EventsTableMenu;
