import { FC } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../../types/TBaseComponent';

const Wrapper = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.coolGray40};
  background: ${({ theme }) => theme.colors.coolGray10};
  padding: ${({ theme }) => theme.spaces.spacing8} ${({ theme }) => theme.spaces.spacing12};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
`;

interface TagProps extends TBaseProps {
  label: string;
}

const Tag: FC<TagProps> = ({ className, testId, label }) => {
  return (
    <Wrapper className={className} data-testid={testId}>
      {label}
    </Wrapper>
  );
};

export { Tag, TagProps };
