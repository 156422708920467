import { forwardRef } from 'react';
import { Controller, FieldError, FieldValues } from 'react-hook-form';
import styled from 'styled-components';

import { TSearchItem } from '../../../types/TSelect';
import { TBaseProps, TSelectItem } from '../../..';
import { TFormControlElement } from '../../../types/TForm';
import { Select, SelectProps } from '../../select/Select';
import { FormError } from '../form-error/FormError';
import { FormItemLabel } from '../formStyles';

const Wrapper = styled.div<{ noMargin?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  margin-bottom: ${({ theme, noMargin }) => (noMargin ? '0' : theme.spaces.spacing16)};
`;

export const SubLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.body};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.coolGray70};
  align-self: flex-start;
  margin-top: 6px;
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
`;

export interface FormSelectProps<T> extends SelectProps, TFormControlElement<T>, TBaseProps {
  noMargin?: boolean;
  label?: string;
  subLabel?: string;
  error?: FieldError;
  isReadonly?: boolean;
  onClickItemCallback?: (props: TSearchItem) => void;
}

const FormSelect = forwardRef(
  <T extends FieldValues>(
    {
      noMargin,
      onChange,
      label,
      subLabel,
      testId,
      control,
      name,
      error,
      isMulti,
      ...rest
    }: FormSelectProps<T>,
    ref: any | undefined,
  ) => (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: onChangeField } }) => {
        const onChangeMerged = (value: readonly TSelectItem[] | null) => {
          if (!value) {
            onChangeField(null);
          } else {
            onChangeField(isMulti ? value : value[0]);
          }
          if (onChange) {
            onChange(value);
          }
        };
        return (
          <Wrapper noMargin={noMargin}>
            {label && <FormItemLabel>{label}</FormItemLabel>}
            <Select
              {...rest}
              isMulti={isMulti}
              onChange={onChangeMerged}
              testId={testId}
              ref={ref}
              invalid={!!error}
            />
            {subLabel && <SubLabel>{subLabel}</SubLabel>}
            {error && <FormError error={error} testId={`${testId}-error`} />}
          </Wrapper>
        );
      }}
    />
  ),
);

FormSelect.displayName = 'FormSelect';

export default FormSelect as <T extends FieldValues>(
  props: FormSelectProps<T> & { ref?: any },
) => JSX.Element;
