import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useHighlightableElements } from '../../contexts/highlightableElementsContext';
import { TAnnouncementCreationSteps } from '../../types/TWalkthrough';
import HighlightableElementsModal from '../../components/highlightable-elements/HighlightableElementsModal';
import { INFO_MODAL_Z_INDEX } from '../../components/highlightable-elements/Constants';

const ModalPositionWrapper = styled.div`
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  left: ${({ theme }) => theme.spaces.spacing16};
  z-index: ${INFO_MODAL_Z_INDEX};
`;

const useAnnouncementCreationWalkthrough = () => {
  const { t } = useTranslation();
  const { setHighlightedElementId, highlightedElementId } = useHighlightableElements();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const allSteps = Object.values(TAnnouncementCreationSteps);

  const goForward = () => {
    if (currentStepIndex + 1 === allSteps.length) {
      return;
    }
    setCurrentStepIndex((prevState) => prevState + 1);
  };

  const goBackwards = () => {
    if (highlightedElementId === TAnnouncementCreationSteps.STEP0) {
      setHighlightedElementId(undefined);
      return;
    }
    if (currentStepIndex - 1 < 0) {
      return;
    }
    setCurrentStepIndex((prevState) => prevState - 1);
  };

  useEffect(() => {
    const currentElementId = allSteps[currentStepIndex];
    if (currentElementId === TAnnouncementCreationSteps.OUTRO) {
      setHighlightedElementId(undefined);
      return;
    }
    setHighlightedElementId(currentElementId);
  }, [currentStepIndex]);

  const getModalContent = () => {
    switch (highlightedElementId as TAnnouncementCreationSteps) {
      case TAnnouncementCreationSteps.STEP0:
        return t('walkthrough.announcementCreation.step0');
      case TAnnouncementCreationSteps.STEP1:
        return t('walkthrough.announcementCreation.step1');
      case TAnnouncementCreationSteps.STEP2:
        return t('walkthrough.announcementCreation.step2');
      case TAnnouncementCreationSteps.STEP3:
        return t('walkthrough.announcementCreation.step3');
      case TAnnouncementCreationSteps.STEP4:
        return t('walkthrough.announcementCreation.step4');
      case TAnnouncementCreationSteps.STEP5:
        return t('walkthrough.announcementCreation.step5');
      case TAnnouncementCreationSteps.STEP6:
        return t('walkthrough.announcementCreation.step6');
      case TAnnouncementCreationSteps.STEP7:
        return t('walkthrough.announcementCreation.step7');
      case TAnnouncementCreationSteps.STEP8:
        return t('walkthrough.announcementCreation.step8');
      case TAnnouncementCreationSteps.OUTRO:
      default:
        return '';
    }
  };

  const isIntroStep = highlightedElementId === TAnnouncementCreationSteps.STEP0;

  const renderModal = () => {
    return (
      <ModalPositionWrapper>
        <HighlightableElementsModal
          content={getModalContent()}
          previousButtonText={isIntroStep ? t('common.cancel') : undefined}
          nextButtonText={isIntroStep ? t('common.continue') : undefined}
          onNextClick={goForward}
          onPreviousClick={goBackwards}
        />
      </ModalPositionWrapper>
    );
  };

  return { goForward, goBackwards, renderModal };
};

export default useAnnouncementCreationWalkthrough;
