import { TUserInstitutionList } from '@sim-admin-frontends/data-access';
import { DropdownMenuItemType } from '@sim-admin-frontends/ui-shared';
import { useMemo } from 'react';

export const useInstitutionMenuItems = (
  institutionUuids: string[],
  allInstitutions: TUserInstitutionList,
  currentInstitutionUuid: string,
  onSwitch: (id?: string, isOfficial?: boolean) => void,
) => {
  const type: DropdownMenuItemType = 'normal';

  const items = useMemo(
    () =>
      institutionUuids.map((institutionUuid, i) => {
        const foundInstitution = allInstitutions?.find(
          (institution) => institution.id === institutionUuid,
        );

        const isActive = foundInstitution?.id == currentInstitutionUuid;

        return {
          icon: undefined,
          id: foundInstitution?.id || '',
          label: foundInstitution?.name || `${i}`,
          action: isActive ? undefined : onSwitch,
          type: type,
          testId: `UserHeaderMenu#SwitchInstitution-${foundInstitution?.id}`,
          isActive: isActive,
        };
      }),
    [allInstitutions, currentInstitutionUuid, institutionUuids, onSwitch],
  );

  return items;
};
