import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { FormProvider, useForm, useWatch, get } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { utcToZonedTime } from 'date-fns-tz';
import { format, isValid } from 'date-fns';
import {
  Button,
  ExternalLinkIcon,
  FormDateTimePicker,
  FormInput,
  FormSelect,
  FormUploadSecondary,
  FormWrapper,
  SuccessOutlinedIcon,
  SwitchableFormSection,
  TSelectItem,
  MAX_UPLOAD_SIZE,
  MAX_VIDEO_UPLOAD_SIZE,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_VIDEO_FORMATS,
  FormImageCropperUpload,
  TableIconProps,
  ActionButtons,
} from '@sim-admin-frontends/ui-shared';
import {
  ImageInput,
  State,
  TAnnouncementDetail,
  useInstitutionQuery,
  VideoInput,
} from '@sim-admin-frontends/data-access';
import { isFile, MaybeDate, roundMinutes } from '@sim-admin-frontends/utils-shared';

import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import { SwitchableSectionContentWrapper } from '../../announcements/edit/AnnouncementEditStyles';
import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import {
  CREATE_FORM_URL,
  FALLBACK_INSTITUTION_NAME,
  FALLBACK_TIMEZONE,
} from '../../../constants/Constants';
import {
  prepareFormValues,
  requiredTitleSchema,
  shouldSaveDraftButtonBeVisible,
} from '../../../utils/announcementsUtils';
import PageContentEditable, { ContentHeader } from '../../layout/pageContent/PageContentEditable';
import { TPollFormValues } from '../../../types/TPolls';
import PollPreview from '../preview/PollPreview';
import { getPlaceOptions } from '../../../utils/placeUtils';
import FormButtons from '../../announcements/edit/FormButtons';
import AnnouncementNotificationSection from '../../announcements/edit/AnnouncementNotificationSection';
import { TAnnouncementsFormTypes } from '../../../types/TAnnouncements';
import GoogleFormUrlModal from './GoogleFormUrlModal';

const schema = (t: TFunction, timezone: string, isScheduling: boolean, isMultiCity: boolean) =>
  Yup.object().shape({
    title: Yup.string().required(t('common.validation.required')),
    places: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !(isMultiCity && (value || []).length < 1),
    ),
    content: Yup.string()
      .required(t('common.validation.required'))
      .url(t('common.validation.url'))
      .test(
        'googleFormsLongUrl',
        t('common.validation.googleFormLongUrl'),
        (value: string | undefined) => !(value && value.includes('forms.gle')),
      )
      .test('googleFormsUrl', t('common.validation.googleFormUrl'), (value: string | undefined) =>
        value ? value.includes('forms/d/e') : false,
      ),
    scheduledAt: Yup.mixed()
      .test(
        'required',
        t('common.validation.required'),
        (value: MaybeDate) => !isScheduling || !!value,
      )
      .test(
        'mindate',
        t('common.validation.date.pastDate'),
        (value: MaybeDate) =>
          !isScheduling || !value || value >= roundMinutes(utcToZonedTime(new Date(), timezone)),
      ),
    images: Yup.array().of(
      Yup.mixed()
        .test('fileSize', t('common.validation.file.tooLarge'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
        )
        .test(
          'fileType',
          t('common.validation.file.unsupportedFormat'),
          (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_IMAGE_FORMATS.includes(file.type) : true,
        ),
    ),
    videos: Yup.array().of(
      Yup.mixed()
        .test('fileSize', t('common.validation.file.tooLarge'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_VIDEO_UPLOAD_SIZE : true,
        )
        .test(
          'fileType',
          t('common.validation.file.unsupportedFormat'),
          (file: File | VideoInput) =>
            file && isFile(file) ? SUPPORTED_VIDEO_FORMATS.includes(file.type) : true,
        ),
    ),
    notifications: Yup.array().when('scheduledAt', (scheduledAt) => {
      const laterThanPresentSchema = Yup.date().min(
        roundMinutes(utcToZonedTime(new Date(), timezone)),
        t('common.validation.date.pastDate'),
      );

      const greaterThanScheduledSchema = Yup.date().test(
        'greaterThanScheduled',
        t('common.validation.date.greaterThanScheduled'),
        (value?: Date) => !!value && value > scheduledAt,
      );

      return Yup.array().of(
        Yup.object().shape({
          value: scheduledAt
            ? laterThanPresentSchema.concat(greaterThanScheduledSchema)
            : laterThanPresentSchema,
        }),
      );
    }),
  });

type Props = {
  onSubmit: (values: TPollFormValues) => Promise<void>;
  onSubmitDraft: (values: TPollFormValues) => Promise<void>;
  onDiscard: () => void;
  poll?: TAnnouncementDetail;
  showOnlyPreview?: boolean;
  pollFormActions: TableIconProps[];
};

const PollEdit: FC<Props> = ({
  onSubmit,
  onSubmitDraft,
  onDiscard,
  poll,
  showOnlyPreview,
  pollFormActions,
}) => {
  const cantSchedule =
    poll?.publishmentState === State.Published || poll?.publishmentState === State.Unpublished;

  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;
  const [isScheduling, setScheduling] = useState(!!poll?.scheduledAt && !cantSchedule);

  const isEdit = poll !== undefined;

  const isSaveDraftButtonBeVisible = shouldSaveDraftButtonBeVisible(
    poll?.publishmentState,
    isScheduling,
  );

  const [isDraft, setIsDraft] = useState(false);
  const { institutionUuid } = useAuthInfo();
  const { data } = useInstitutionQuery({
    id: institutionUuid ?? '',
  });

  const initialValues: TPollFormValues = {
    title: poll?.title || '',
    content: poll?.content || '',
    places: getPlaceOptions(poll?.places) || [],
    images: poll?.imageObjects,
    videos: poll?.videoObjects,
    scheduledAt:
      poll?.scheduledAt && !cantSchedule ? utcToZonedTime(poll?.scheduledAt, timezone) : undefined,
    notifyNow: isEdit ? !!poll?.notifyNow : false,
    notifications: poll?.notifications
      ? poll?.notifications.map((notification) => ({
          value: utcToZonedTime(notification, timezone),
        }))
      : [],
  };

  const placesOptions = useMemo(() => getPlaceOptions(data?.institution?.places), [data]);
  const shouldShowCitySelect = (placesOptions || []).length > 1;

  const methods = useForm<TPollFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, timezone, isScheduling, shouldShowCitySelect)),
    mode: 'all',
  });

  const { handleSubmit, register, formState, setValue, getValues, trigger, control } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const [previewTitle, previewContent, previewImages, previewVideos] = useWatch({
    name: ['title', 'content', 'images', 'videos'],
    control,
  });

  const onScheduleDateTimeChanged = useCallback(
    (value?: Date | null) => {
      if (isValid(value)) {
        setValue('scheduledAt', value);
        trigger('scheduledAt');
      }
    },
    [setValue, trigger],
  );

  const initialScheduleDate =
    initialValues.scheduledAt || roundMinutes(utcToZonedTime(new Date(), timezone));

  const onSchedulingSectionVisibilityChanged = () => {
    setValue(
      'scheduledAt',
      !isScheduling ? roundMinutes(utcToZonedTime(new Date(), timezone)) : undefined,
    );
    setScheduling(!isScheduling);
    trigger('scheduledAt');
  };

  const onNotificationsEnabledChange = (value: boolean) => {
    setValue('notifyNow', value);
  };

  useEffect(() => {
    trigger('scheduledAt');
  }, [isScheduling, trigger]);

  const submit = async (values: TPollFormValues) =>
    onSubmit(prepareFormValues(values, isScheduling, timezone));

  const submitDraft = async (values: TPollFormValues) =>
    onSubmitDraft(prepareFormValues(values, isScheduling, timezone));

  const onSubmitClick = () => {
    setIsDraft(false);
    methods.control._updateProps({
      resolver: yupResolver(schema(t, timezone, isScheduling, shouldShowCitySelect)),
    });

    handleSubmit(submit)();
  };

  const onSaveDraftClick = () => {
    setIsDraft(true);
    methods.control._updateProps({
      resolver: yupResolver(requiredTitleSchema(t)),
    });
    handleSubmit(submitDraft)();
  };

  const pageTitle = poll ? t('polls.form.editPageTitle') : t('polls.form.createPageTitle');

  const institutionName = data?.institution?.name || FALLBACK_INSTITUTION_NAME;
  const institutionLogo = data?.institution?.branding?.avatar?.links?.self;

  const shouldShowUrlModal = !errors.content && !(previewContent?.length < 5);

  const showImage = !previewVideos?.length;
  const showVideo = !previewImages?.length;

  useEffect(() => {
    if (!showImage) {
      setValue('images', []);
    }
  }, [showImage]);

  useEffect(() => {
    if (!showVideo) {
      setValue('videos', []);
    }
  }, [showVideo]);

  return (
    <PageContentEditable
      showOnlyPreview={showOnlyPreview}
      previewTitle={t('polls.preview.title')}
      preview={
        <PollPreview
          institutionName={institutionName}
          institutionLogo={institutionLogo}
          title={previewTitle}
          images={previewImages}
          videos={previewVideos}
          iframeUrl={!errors.content ? previewContent : undefined}
        />
      }
    >
      {shouldShowUrlModal && <GoogleFormUrlModal />}
      <FormWrapper>
        <ActionButtons actionButtons={pollFormActions} />
        <ContentHeader>{pageTitle}</ContentHeader>
        <FormProvider {...methods}>
          <FormInput
            label={t('polls.form.title')}
            {...register('title')}
            error={errors.title}
            testId="PollEdit#title"
            placeholder={t('polls.form.titlePlaceholder')}
          />
          {shouldShowCitySelect && (
            <FormSelect
              control={control}
              label={t('updates.form.cities')}
              error={get(errors, 'places')}
              name="places"
              options={placesOptions || []}
              defaultValue={initialValues.places}
              testId="PollEdit#places"
              allSelectable
              clearable
              isMulti
            />
          )}
          {showImage && (
            <FormImageCropperUpload
              initialValue={initialValues.images}
              label={t('polls.form.imageLabel')}
              control={control}
              setValue={setValue}
              getValues={getValues}
              trigger={trigger}
              name="images"
              sublabel={t('polls.form.imageSublabel')}
              dropzoneLabel={t('polls.form.dropzoneLabel')}
              t={t}
              multiple
              testId="PollEdit#image"
            />
          )}
          {showVideo && (
            <FormUploadSecondary
              control={control}
              name="videos"
              dropzoneLabel={t('polls.form.dropzoneLabel')}
              testId="PollEdit#video"
              label={t('polls.form.videoLabel')}
              t={t}
              bottomCaption={t('polls.form.videoSublabel')}
              accept={SUPPORTED_VIDEO_FORMATS}
            />
          )}
          <FormInput
            label={t('polls.form.googleFormLink')}
            {...register('content')}
            error={errors.content}
            testId="PollEdit#content"
            placeholder={t('polls.form.googleFormLinkPlaceholder')}
            icon={!errors.content ? <SuccessOutlinedIcon /> : undefined}
            noMargin
          />
          <a
            href={!errors.content ? previewContent : CREATE_FORM_URL}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="tertiary" appendIcon={<ExternalLinkIcon />}>
              {!errors.content ? t('polls.form.viewForm') : t('polls.form.createForm')}
            </Button>
          </a>
          <SwitchableFormSection
            title={t('polls.form.scheduleSection')}
            description={t('polls.form.scheduleSectionDescription')}
            onVisibilityChanged={onSchedulingSectionVisibilityChanged}
            initiallyOpened={isScheduling}
            disabled={cantSchedule}
            disabledText={t('polls.form.disabledShedule')}
            testId={'PollSchedule#switch'}
          >
            <SwitchableSectionContentWrapper>
              <FormDateTimePicker
                control={control}
                name="scheduledAt"
                initialDate={initialScheduleDate}
                minDate={roundMinutes(utcToZonedTime(new Date(), timezone))}
                onChange={onScheduleDateTimeChanged}
                dateLabel=""
                timeLabel=""
                initialTime={format(initialScheduleDate, 'hh:mm')}
                initialDayPeriod={format(initialScheduleDate, 'a')}
                testId="PollSchedule#date"
              />
            </SwitchableSectionContentWrapper>
          </SwitchableFormSection>
          <AnnouncementNotificationSection
            control={control}
            notifyNow={initialValues.notifyNow}
            publishmentState={poll?.publishmentState}
            isEdit={isEdit}
            announcementType={TAnnouncementsFormTypes.POLL}
            onNotificationsEnabledChange={onNotificationsEnabledChange}
          />
          <FormButtons
            isSubmitting={isSubmitting}
            isDraft={isDraft}
            getValues={getValues}
            errors={errors}
            publishmentState={poll?.publishmentState}
            isScheduling={isScheduling}
            onSaveDraftClick={onSaveDraftClick}
            onDiscard={onDiscard}
            onSubmitClick={onSubmitClick}
            isSaveDraftButtonVisible={isSaveDraftButtonBeVisible}
          />
        </FormProvider>
      </FormWrapper>
      <RouteLeavingGuard when={isDirty && !isSubmitting} />
    </PageContentEditable>
  );
};

export default PollEdit;
