import { yupResolver } from '@hookform/resolvers/yup';
import { TAnnouncementDetail, TCategories, TInstitution } from '@sim-admin-frontends/data-access';
import {
  Button,
  FormSectionHeader,
  FormSelect,
  FormWrapper,
  TSelectRef,
} from '@sim-admin-frontends/ui-shared';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import { FC, useMemo, useRef } from 'react';
import { FormProvider, get, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { TScrapedAnnouncementFormValues } from '../../../types/TAnnouncements';
import { getCategoriesOptions, getCategoryOptionFromIds } from '../../../utils/categoriesUtils';
import PageContentEditable, { ContentHeader } from '../../layout/pageContent/PageContentEditable';
import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import AnnouncementPreview from '../preview/AnnouncementPreview';
import { ButtonsWrapper } from './AnnouncementEditStyles';

const schema = (t: TFunction) =>
  Yup.object().shape({
    categories: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }),
      )
      .nullable()
      .compact()
      .min(1, t('common.validation.required')),
  });

type Props = {
  onSubmit: (values: TScrapedAnnouncementFormValues) => Promise<void>;
  onDiscard: () => void;
  categories: TCategories;
  announcement?: TAnnouncementDetail;
  institution: TInstitution;
};

const ScrapedAnnouncementEdit: FC<Props> = ({
  onSubmit,
  onDiscard,
  announcement,
  categories,
  institution,
}) => {
  const categoriesRef = useRef<TSelectRef>();
  const { t } = useTranslation();
  const categoriesOptions = useMemo(() => getCategoriesOptions(categories), [categories]);
  const announcementPublisher = { label: institution?.name || '', value: institution?.id || '' };
  const getInitialCategories = () => {
    const categoryOptions = getCategoryOptionFromIds(
      categoriesOptions,
      announcement?.categories?.map((category) => category.id),
    );
    return categoryOptions.length ? categoryOptions : undefined;
  };

  const initialValues: TScrapedAnnouncementFormValues = {
    categories: getInitialCategories(),
  };

  const methods = useForm<TScrapedAnnouncementFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });

  const { handleSubmit, formState, control } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const getPreviewCategories = () => {
    if (!announcement?.categories) {
      return [];
    }
    return announcement.categories.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  };

  return (
    <PageContentEditable
      previewTitle={t('updates.preview.title')}
      preview={
        <AnnouncementPreview
          title={announcement?.title || ''}
          images={announcement?.imageObjects}
          videos={announcement?.videoObjects}
          content={announcement?.content || ''}
          categories={getPreviewCategories()}
          publisher={announcementPublisher}
        />
      }
    >
      <FormWrapper>
        <ContentHeader>{t(`updates.form.editPageTitle`)}</ContentHeader>
        <FormProvider {...methods}>
          <>
            <FormSectionHeader
              title={t('updates.form.category')}
              description={t('updates.form.categoryDescription')}
            />
            <FormSelect
              control={control}
              name="categories"
              error={get(errors, 'categories')}
              options={categoriesOptions}
              defaultValue={initialValues.categories}
              ref={categoriesRef}
              testId="AnnouncementEdit#categories"
              isMulti
            />
          </>

          <ButtonsWrapper>
            <Button variant="tertiary" onClick={onDiscard} disabled={isSubmitting}>
              {t('updates.form.cancel')}
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {t('common.submit')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
      <RouteLeavingGuard when={isDirty && !isSubmitting} />
    </PageContentEditable>
  );
};

export default ScrapedAnnouncementEdit;
