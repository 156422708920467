import {
  State,
  useCancelScheduledQuickQuestionMutation,
  useDeleteQuickQuestionMutation,
  usePublishQuickQuestionMutation,
  useSendPostNotificationMutation,
  useUnpublishQuickQuestionMutation,
} from '@sim-admin-frontends/data-access';
import {
  BellIcon,
  ConfirmModal,
  EditIcon,
  EyeIcon,
  EyeSlashIcon,
  loadingToast,
  RemoveCircleIcon,
  TableIconProps,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { useAuthInfo } from '../../contexts/userContext';
import ROUTES from '../../routing/routes';
import { TItemAction } from '../../types/TAnalytics';
import { TAnnouncementsTabTypes } from '../../types/TAnnouncements';
import { QuickQuestionModalActions } from '../../types/TQuickQuestions';
import { logAnnouncementEvent, logQuickQuestionEvent } from '../../utils/analytics/analyticsUtils';
import { changeQuickQuestionsTab } from '../../utils/quickQuestionsUtils';

type Props = {
  postId: string;
  isEditPage?: boolean;
  refetch: () => void;
  type: State;
};

export const useGenerateQuickQuestionActions = ({ postId, isEditPage, refetch, type }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(QuickQuestionModalActions.UNPUBLISH);
  const { institutionUuid } = useAuthInfo();
  const history = useHistory();
  const { t } = useTranslation();

  const editPath = generatePath(
    ROUTES.quickQuestionsEdit.path,
    postId ? { id: postId } : undefined,
  );

  const { mutate: deleteMutation } = useDeleteQuickQuestionMutation({
    onMutate: () => {
      loadingToast(t('quickQuestions.toast.delete.loading'), {
        toastId: QuickQuestionModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(
        QuickQuestionModalActions.DELETE,
        t('quickQuestions.toast.delete.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logQuickQuestionEvent(postId, institutionUuid, TItemAction.DELETE, false);
      updateToast(
        QuickQuestionModalActions.DELETE,
        t('quickQuestions.toast.delete.success'),
        TToastType.SUCCESS,
      );
      changeQuickQuestionsTab(history, TAnnouncementsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const { mutate: unpublishMutation } = useUnpublishQuickQuestionMutation({
    onMutate: () => {
      loadingToast(t('quickQuestions.toast.unpublish.loading'), {
        toastId: QuickQuestionModalActions.UNPUBLISH,
      });
    },
    onError: () => {
      updateToast(
        QuickQuestionModalActions.UNPUBLISH,
        t('quickQuestions.toast.unpublish.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      updateToast(
        QuickQuestionModalActions.UNPUBLISH,
        t('quickQuestions.toast.unpublish.success'),
        TToastType.SUCCESS,
      );
      logQuickQuestionEvent(postId, institutionUuid, TItemAction.UNPUBLISH, false);
      changeQuickQuestionsTab(history, TAnnouncementsTabTypes.UNPUBLISHED);
      refetch();
    },
  });

  const { mutate: publishMutation } = usePublishQuickQuestionMutation({
    onMutate: () => {
      loadingToast(t('quickQuestions.toast.publish.loading'), {
        toastId: QuickQuestionModalActions.PUBLISH,
      });
    },
    onError: (error) => {
      updateToast(QuickQuestionModalActions.PUBLISH, error as string, TToastType.ERROR);
    },
    onSuccess: () => {
      logQuickQuestionEvent(postId, institutionUuid, TItemAction.PUBLISH);
      updateToast(
        QuickQuestionModalActions.PUBLISH,
        t('quickQuestions.toast.publish.success'),
        TToastType.SUCCESS,
      );
      changeQuickQuestionsTab(history, TAnnouncementsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const { mutate: cancelScheduled } = useCancelScheduledQuickQuestionMutation({
    onMutate: () => {
      loadingToast(t('quickQuestions.toast.cancelSend.loading'), {
        toastId: QuickQuestionModalActions.CANCEL_SEND,
      });
    },
    onError: () => {
      updateToast(
        QuickQuestionModalActions.CANCEL_SEND,
        t('quickQuestions.toast.cancelSend.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logQuickQuestionEvent(postId, institutionUuid, TItemAction.CANCEL_SEND);
      updateToast(
        QuickQuestionModalActions.CANCEL_SEND,
        t('quickQuestions.toast.cancelSend.success'),
        TToastType.SUCCESS,
      );
      refetch();
    },
  });

  const { mutate: sendPostNotification } = useSendPostNotificationMutation({
    onMutate: () => {
      loadingToast(t('updates.toast.notification.loading'), {
        toastId: QuickQuestionModalActions.NOTIFY,
      });
    },
    onError: () => {
      updateToast(
        QuickQuestionModalActions.NOTIFY,
        t('updates.toast.notification.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logAnnouncementEvent(postId, institutionUuid, TItemAction.NOTIFY);
      updateToast(
        QuickQuestionModalActions.NOTIFY,
        t('updates.toast.notification.success'),
        TToastType.SUCCESS,
      );
    },
  });

  const deleteQuickQuestion = async () => {
    await deleteMutation({
      id: postId,
    });
  };

  const unpublishQuickQuestion = async () => {
    await unpublishMutation({
      id: postId,
    });
  };

  const publishQuickQuestion = async () => {
    await publishMutation({
      id: postId,
    });
  };

  const sendNotification = async () => {
    await sendPostNotification({
      postUuid: postId,
    });
  };

  const handleDeleteClick = () => {
    setModalDescription(t('quickQuestions.modal.deleteContent'));
    setModalClickAction(QuickQuestionModalActions.DELETE);
    setIsModalVisible(true);
  };

  const handlePublishClick = () => {
    setModalDescription(t('quickQuestions.modal.publishContent'));
    setModalClickAction(QuickQuestionModalActions.PUBLISH);
    setIsModalVisible(true);
  };

  const handleCancelSendClick = () => {
    cancelScheduled({
      id: postId,
    });
  };

  const handleUnpublishClick = () => {
    setModalDescription(t('quickQuestions.modal.unpublishContent'));
    setModalClickAction(QuickQuestionModalActions.UNPUBLISH);
    setIsModalVisible(true);
  };

  const handleNotifyClick = () => {
    setModalDescription(t('updates.modal.sendNotification'));
    setModalClickAction(QuickQuestionModalActions.NOTIFY);
    setIsModalVisible(true);
  };

  const handleConfirmClick = () => {
    if (modalClickAction === QuickQuestionModalActions.DELETE) {
      deleteQuickQuestion();
      setIsModalVisible(false);
    }
    if (modalClickAction === QuickQuestionModalActions.UNPUBLISH) {
      unpublishQuickQuestion();
      setIsModalVisible(false);
    }
    if (modalClickAction === QuickQuestionModalActions.PUBLISH) {
      publishQuickQuestion();
      setIsModalVisible(false);
    }
    if (modalClickAction === QuickQuestionModalActions.NOTIFY) {
      sendNotification();
      setIsModalVisible(false);
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const generateItems = (): TableIconProps[] => {
    switch (type) {
      case State.Draft:
        return [];
      // ToDo: Use this once drafts implemented
      // return [
      //   {
      //     label: t('table.dropdown.edit'),
      //     path: editPath,
      //     testId: 'QuickQuestionsTableMenu#edit',
      //     icon: <EditIcon />,
      //     hidden:isEditPage
      //   },
      //   {
      //     label: t('table.dropdown.delete'),
      //     action: handleDeleteClick,
      //     testId: 'QuickQuestionsTableMenu#delete',
      //     icon: <TrashCanIcon />,
      //   },
      // ];

      case State.Published:
        return [
          {
            label: t('table.dropdown.notify'),
            action: handleNotifyClick,
            testId: 'AnnouncementsTableMenu#notify',
            icon: <BellIcon />,
          },
          {
            label: t('table.dropdown.unpublish'),
            action: handleUnpublishClick,
            testId: 'QuickQuestionsTableMenu#unpublish',
            icon: <EyeSlashIcon />,
          },
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            testId: 'QuickQuestionsTableMenu#edit',
            icon: <EditIcon />,
            hidden: isEditPage,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: 'QuickQuestionsTableMenu#delete',
            icon: <TrashCanIcon />,
          },
        ];

      case State.Scheduled:
        return [
          {
            label: t('table.dropdown.publish'),
            action: handlePublishClick,
            testId: 'QuickQuestionsTableMenu#publish',
            icon: <EyeIcon />,
          },
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            testId: 'QuickQuestionsTableMenu#edit',
            icon: <EditIcon />,
            hidden: isEditPage,
          },
          {
            label: t('table.dropdown.cancelSend'),
            action: handleCancelSendClick,
            testId: 'QuickQuestionsTableMenu#cancelSend',
            icon: <RemoveCircleIcon />,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: 'QuickQuestionsTableMenu#delete',
            icon: <TrashCanIcon />,
          },
        ];

      case State.Unpublished:
        return [
          {
            label: t('table.dropdown.publish'),
            action: handlePublishClick,
            testId: 'QuickQuestionsTableMenu#publish',
            icon: <EyeIcon />,
          },
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            testId: 'QuickQuestionsTableMenu#edit',
            icon: <EditIcon />,
            hidden: isEditPage,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: 'QuickQuestionsTableMenu#delete',
            icon: <TrashCanIcon />,
          },
        ];
      default:
        return [];
    }
  };

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  const renderModal = () => (
    <ConfirmModal
      title={t('quickQuestions.modal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('quickQuestions.modal.backButton')}
      confirmButtonText={t('quickQuestions.modal.confirmButton')}
      testId="QuickQuestions#ConfirmModal"
    />
  );

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
