import { useState } from 'react';

import { TPasswordFieldType } from '../types/TLogin';

const useShowPassword = () => {
  const [passwordFieldType, setPasswordFieldType] = useState<TPasswordFieldType>(
    TPasswordFieldType.PASSWORD,
  );
  const [showPassword, setShowPassword] = useState(false);

  const loginCleanup = () => {
    setShowPassword(false);
    setPasswordFieldType(TPasswordFieldType.PASSWORD);
  };

  const onShowPasswordChange = () => {
    if (showPassword) {
      setPasswordFieldType(TPasswordFieldType.PASSWORD);
    } else {
      setPasswordFieldType(TPasswordFieldType.SHOWPASSWORD);
    }

    setShowPassword(!showPassword);
  };

  return {
    passwordFieldType,
    showPassword,
    onShowPasswordChange,
    loginCleanup,
  };
};

export { useShowPassword };
