import { FC } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import EmergencyEditContainer from '../../components/emergency/edit/EmergencyEdit.container';
import { useAuthInfo } from '../../contexts/userContext';

type EmergencyEditPageProps = {
  id?: string;
};

const EmergencyEditPage: FC<RouteComponentProps<EmergencyEditPageProps>> = ({ match }) => {
  const { isCity, visitorMode } = useAuthInfo();

  if (!isCity || visitorMode) {
    return <Redirect to="/404" />;
  }

  return <EmergencyEditContainer id={match?.params?.id} />;
};

export default EmergencyEditPage;
