import { TUploadedFile } from '@sim-admin-frontends/data-access';
import { prepareFileObjects } from '@sim-admin-frontends/utils-shared';
import {
  MessagingProblemInput,
  MessagingProblemStatus,
} from '@sim-admin-frontends/data-access-admin-be';
import { History, LocationState } from 'history';
import { generatePath } from 'react-router-dom';
import { TFunction } from 'i18next';

import { TProblemsFormValues } from '../types/TProblems';
import ROUTES from '../routing/routes';

export const transformFormValues = (
  formData: TProblemsFormValues,
  uploadedImages: TUploadedFile[] | undefined,
  placeUuid: string | undefined,
  installationUuid: string | undefined,
  institutionUuid: string | undefined,
): MessagingProblemInput => {
  const imageObjects = prepareFileObjects(uploadedImages);

  return {
    address: formData.address || '',
    categoryUuid: formData.category?.value || '',
    description: formData.description || '',
    imageObjects: imageObjects.length ? imageObjects : undefined,
    resolution: formData.resolution || '',
    status: formData.status.value || undefined,
    installationUuid: installationUuid || '',
    email: formData.email || '',
    placeUuid: placeUuid || '',
    reporter: formData.reporter || '',
    cityIsNotCompetent: formData.cityIsNotCompetent || false,
    cityIsNotCompetentTimestamp: formData.cityIsNotCompetent ? new Date().toISOString() : null,
    commentAuthorityId: formData.commentFromAuthority ? institutionUuid : null,
    commentFromAuthority: formData.commentFromAuthority || '',
    connector: formData.connector
      ? {
          id: formData.connector.id,
          type: formData.connector.type.value,
          status: formData.connector.status.value,
          statusMessage: formData.connector.statusMessage,
        }
      : null,
  };
};

export const changeReportProblemTab = (
  history: History<LocationState>,
  type: MessagingProblemStatus,
) => {
  history.push(
    generatePath(ROUTES.reportProblem.path, {
      type,
    }),
  );
};

export const getProblemStatusLabel = (status: MessagingProblemStatus, t: TFunction) => {
  switch (status) {
    case MessagingProblemStatus.InProgress:
      return t('reportProblem.tabs.inProgress');
    case MessagingProblemStatus.Rejected:
      return t('reportProblem.tabs.rejected');
    case MessagingProblemStatus.Solved:
      return t('reportProblem.tabs.solved');
    default:
      return t('reportProblem.tabs.new');
  }
};
