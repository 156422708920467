import { Circle, CircleProps } from '@react-google-maps/api';
import { FC } from 'react';

import { defaultMapOptions, DEFAULT_RADIUS_METERS_NUMBER } from '../../constants/MapConstants';

export interface MapAreaCircleProps extends CircleProps {
  options?: google.maps.CircleOptions;
  radius: number;
}

const MapAreaCircle: FC<MapAreaCircleProps> = ({
  options = defaultMapOptions,
  radius = DEFAULT_RADIUS_METERS_NUMBER,
  ...rest
}) => {
  return <Circle options={options} radius={radius} {...rest} />;
};

export { MapAreaCircle };
