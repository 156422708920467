import { FC, useCallback, useMemo, useState } from 'react';
import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import {
  AnnouncementsByFilterInput,
  ANNOUNCEMENTS_PAGE_SIZE,
  State,
  TAnnouncementDraftListItem,
  TAnnouncementListItem,
  useInfiniteAnnouncementDraftsQuery,
} from '@sim-admin-frontends/data-access';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';
import { useOffsetTablePaging } from '@sim-admin-frontends/utils-shared';

import { AnnouncementsView } from './AnnouncementsView';
import {
  createTableColumns,
  getGqlSorting,
  getInitialGqlSorting,
  getInitialTableSorting,
} from './helper';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import VideoTutorialBanner from '../../videoTutorials/VideoTutorialBanner';

export interface AnnouncementDraftsViewContainerProps {
  wasAnythingPublished: boolean;
}

const AnnouncementDraftsViewContainer: FC<AnnouncementDraftsViewContainerProps> = ({
  wasAnythingPublished,
}) => {
  const { t } = useTranslation();

  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();
  const [orderBy, setOrderBy] = useState(getInitialGqlSorting(State.Draft));
  const institutionId = institutionUuid || '';

  const filter: AnnouncementsByFilterInput = useMemo(
    () => ({
      institutions: [institutionId],
      state: State.Draft,
    }),
    [institutionId],
  );

  const { fetchNextPage, hasNextPage, data, isError, isLoading, isFetchingNextPage, refetch } =
    useInfiniteAnnouncementDraftsQuery(filter, orderBy);

  const columns = createTableColumns(
    t,
    refetch,
    State.Draft,
    places ? places[0]?.timezoneCode : '',
  );

  const { currentPageIndex, maxPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } =
    useOffsetTablePaging(fetchNextPage, ANNOUNCEMENTS_PAGE_SIZE, hasNextPage);

  const onSortingChanged = useCallback(
    (newSorting: SortingRule<TAnnouncementListItem>[]) => {
      setOrderBy(getGqlSorting(newSorting, State.Draft));
      setCurrentPageIndex(0);
    },
    [setCurrentPageIndex],
  );

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const itemsCount = data?.pages[0].announcementsBy.count;

  const announcements = data?.pages[currentPageIndex].announcementsBy.announcements.filter(
    (a): a is TAnnouncementDraftListItem => {
      return a.__typename === 'AnnouncementDraft';
    },
  );
  const loading = isLoading || isFetchingNextPage;

  return (
    <>
      <AnnouncementsView
        hasNextPage={hasNextPage || currentPageIndex < maxPageIndex}
        loading={loading}
        itemsCount={itemsCount as number}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        columns={columns}
        data={announcements || []}
        onSortingChanged={onSortingChanged}
        initialTableSorting={getInitialTableSorting(State.Draft)}
      />
      <VideoTutorialBanner
        text={t('videoTutorials.banner.announcements')}
        visible={!wasAnythingPublished}
        testId={'AnnouncementDraftsView#VideoTutorialBanner'}
      />
    </>
  );
};

export default AnnouncementDraftsViewContainer;
