import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import ReportProblemTabs from '../../components/reportProblem/view/ReportProblemTabs';
import { useIsPlaceAdmin } from '../../hooks/useIsPlaceAdmin';

const ReportProblemPage = () => {
  const { t } = useTranslation();
  const { isPlaceAdmin } = useIsPlaceAdmin();

  if (!isPlaceAdmin) {
    return <Redirect to="/404" />;
  }

  return (
    <PageWrapper>
      <PageHeader
        title={t('reportProblem.pageTitle')}
        caption={t('reportProblem.pageCaption')}
        testId="ReportProblem#PageHeader"
      />
      <ReportProblemTabs />
    </PageWrapper>
  );
};

export default ReportProblemPage;
