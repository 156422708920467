import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { PageHeader } from '@sim-admin-frontends/ui-shared';

import { PageWrapper } from '../../components/layout/PageLayout';
import { useAuthInfo } from '../../contexts/userContext';
import EmergencyTabs from '../../components/emergency/view/EmergencyTabs';

const EmergencyListPage: FC = () => {
  const { t } = useTranslation();

  const { isOfficial, isCity, visitorMode } = useAuthInfo();

  if ((!isOfficial && !isCity) || visitorMode) {
    return <Redirect to="/404" />;
  }

  return (
    <PageWrapper>
      <PageHeader
        title={t('emergency.pageTitle')}
        caption={t('emergency.pageCaption')}
        testId="EmergencyTabs#PageHeader"
      />
      <EmergencyTabs />
    </PageWrapper>
  );
};

export default EmergencyListPage;
