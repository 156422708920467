import React, { FC } from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
`;

type Props = {
  source: string;
};

const RichTextImage: FC<Props> = ({ source }) => {
  return <StyledImage src={source} />;
};

export default RichTextImage;
