export const parseAnalyticsStringData = (data?: string | null) => {
  if (!data) {
    return null;
  }
  try {
    const json = JSON.parse(data);
    const dates = Object.keys(json);
    const values = Object.values(json);
    return Array.from({ length: dates.length }, (_, i) => ({
      dateTime: dates[i],
      value: values[i] as number,
    }));
  } catch (e) {
    return null;
  }
};
