import styled from 'styled-components';

import { ReactComponent as ArrowLeft } from '../../assets/img/arrow_left.svg';
import { ReactComponent as ArrowRight } from '../../assets/img/arrow_right.svg';

export const StyledArrowLeft = styled(ArrowLeft)<{ disabled: boolean }>`
  fill: ${({ disabled, theme }) => (disabled ? theme.colors.coolGray50 : theme.colors.coolGray100)};
`;

export const StyledArrowRight = styled(ArrowRight)<{ disabled: boolean }>`
  fill: ${({ disabled, theme }) => (disabled ? theme.colors.coolGray50 : theme.colors.coolGray100)};
`;

export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30vh;
  color: ${({ theme }) => theme.colors.coolGray100};
`;

export const TableWrapper = styled.div<{ noRightPadding?: boolean; responsive?: boolean }>`
  position: relative;
  table {
    color: ${({ theme }) => theme.colors.coolGray100};
    border-spacing: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.coolGray20};
    width: 100%;
    table-layout: auto;
    tr {
      :hover {
        background-color: ${({ theme }) => theme.colors.coolGray10};
      }
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: ${({ responsive }) => (responsive ? 0 : 'none')};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      font-size: ${({ theme }) => theme.fontSizes.body};
      line-height: ${({ theme }) => theme.lineHeights.small};
      padding: ${({ theme }) => theme.spaces.spacing16};
      padding-right: ${({ theme, noRightPadding }) =>
        noRightPadding ? 'none' : theme.spaces.spacing32};
      border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray20};
      text-align: left;
      min-width: ${({ theme }) => theme.spaces.spacing72};
      :last-child {
        border-right: 0;
        padding: 0 ${({ theme }) => theme.spaces.spacing16};
      }
    }
    th {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      font-size: ${({ theme }) => theme.fontSizes.small};
      line-height: ${({ theme }) => theme.lineHeights.small};
      &.disabled {
        color: ${({ theme }) => theme.colors.coolGray40};
        cursor: not-allowed;
      }
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.coolGray20};
  border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray20};
  padding-left: ${({ theme }) => theme.spaces.spacing16};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.colors.coolGray20};
`;

export const PaginationButton = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-left: 1px solid ${({ theme }) => theme.colors.coolGray20};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  width: 3rem;
  height: 3rem;
`;

export const PaginationSelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spaces.spacing16};
`;

export const ItemsLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.coolGray100};
`;
