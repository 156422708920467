import { FC, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { getErrorMessage, toast, TToastType } from '@sim-admin-frontends/ui-shared';

import { TForgotFormValues } from '../../types/TUser';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordEmailInfoModal from './ForgotPasswordEmailInfoModal';

const ForgotPasswordContainer: FC = () => {
  const [activeUsername, setActiveUsername] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onForgot = async (values: TForgotFormValues) => {
    const { username } = values;
    try {
      await Auth.forgotPassword(username);
      setActiveUsername(username);
      setModalIsOpen(true);
    } catch (err) {
      toast(getErrorMessage(err), TToastType.ERROR);
    }
  };

  const onModalClose = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <ForgotPassword onForgot={onForgot} />
      <ForgotPasswordEmailInfoModal
        isOpen={modalIsOpen}
        username={activeUsername}
        onClose={onModalClose}
      />
    </>
  );
};

export default ForgotPasswordContainer;
