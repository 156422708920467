import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import EmergencyListPage from '../pages/emergency/EmergencyListPage';
import EmergencyEditPage from '../pages/emergency/EmergencyEditPage';
import PrivateRoute from './PrivateRoute';
import ROUTES from './routes';
import NotFound from './NotFound';
import LoginPage from '../pages/login/LoginPage';
import ForgotPasswordPage from '../pages/forgotPassword/ForgotPasswordPage';
import ResetPasswordPage from '../pages/resetPassword/ResetPasswordPage';
import PlaceLoadingPage from '../pages/placeLoading/PlaceLoadingPage';
import CouponsListPage from '../pages/coupons/CouponsListPage';
import AnnouncementsListPage from '../pages/announcements/AnnouncementsListPage';
import AnnouncementsEditPage from '../pages/announcements/AnnouncementsEditPage';
import EventListPage from '../pages/events/EventListPage';
import EventEditPage from '../pages/events/EventEditPage';
import HomePage from '../pages/home/HomePage';
import SettingsPage from '../pages/settings/SettingsPage';
import PollsListPage from '../pages/polls/PollsListPage';
import PollsEditPage from '../pages/polls/PollsEditPage';
import PetDirectoryListPage from '../pages/petDirectory/PetDirectoryListPage';
import ScrapedAnnouncementsEditPage from '../pages/announcements/ScrapedAnnouncementsEditPage';
import RaffleEditPage from '../pages/raffles/RaffleEditPage';
import RaffleListPage from '../pages/raffles/RaffleListPage';
import CouponsEditPage from '../pages/coupons/CouponsEditPage';
import VideoTutorialsPage from '../pages/videoTutorials/VideoTutorialsPage';
import ModeratorListPage from '../pages/moderator/ModeratorListPage';
import ModeratorPreviewPage from '../pages/moderator/ModeratorPreviewPage';
import QuickQuestionEditPage from '../pages/quickQuestions/QuickQuestionEditPage';
import QuickQuestionsListPage from '../pages/quickQuestions/QuickQuestionsListPage';
import ChatEditPage from '../pages/chat/ChatEditPage';
import ChatListPage from '../pages/chat/ChatListPage';
import PostGeneratorPage from '../pages/postGenerator/PostGeneratorPage';
import ReportProblemPage from '../pages/reportProblem/ReportProblemPage';
import ReportProblemEditPage from '../pages/reportProblem/ReportProblemEditPage';
import GAnalytics from '../components/analytics/GAnalytics';
import DonationsListPage from '../pages/donations/DonationsListPage';
import DonationsEditPage from '../pages/donations/DonationsEditPage';
import MarketEditPage from '../pages/market/MarketEditPage';
import MarketListPage from '../pages/market/MarketListPage';

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path={ROUTES.login.path} component={LoginPage} />
      <Route exact path={ROUTES.forgotPassword.path} component={ForgotPasswordPage} />
      <Route exact path={ROUTES.resetPassword.path} component={ResetPasswordPage} />
      <PrivateRoute exact path={ROUTES.home.path} component={HomePage} />
      <Route exact path={ROUTES.placeLoading.path} component={PlaceLoadingPage} />
      <PrivateRoute exact path={ROUTES.couponsEdit.path} component={CouponsEditPage} />
      <PrivateRoute exact path={ROUTES.couponsOverview.path} component={CouponsListPage} />
      <PrivateRoute exact path={ROUTES.announcementsEdit.path} component={AnnouncementsEditPage} />
      <PrivateRoute
        exact
        path={ROUTES.scrapedAnnouncementEdit.path}
        component={ScrapedAnnouncementsEditPage}
      />
      <PrivateRoute
        exact
        path={ROUTES.announcementsOverview.path}
        component={AnnouncementsListPage}
      />
      <PrivateRoute exact path={ROUTES.eventsEdit.path} component={EventEditPage} />
      <PrivateRoute exact path={ROUTES.eventsOverview.path} component={EventListPage} />
      <PrivateRoute exact path={ROUTES.emergencyEdit.path} component={EmergencyEditPage} />
      <PrivateRoute exact path={ROUTES.emergencyOverview.path} component={EmergencyListPage} />
      <PrivateRoute exact path={ROUTES.pollsEdit.path} component={PollsEditPage} />
      <PrivateRoute exact path={ROUTES.pollsOverview.path} component={PollsListPage} />
      {/* <PrivateRoute exact path={ROUTES.insights.path} component={InsightsPage} /> */}
      <PrivateRoute exact path={ROUTES.settings.path} component={SettingsPage} />
      <PrivateRoute
        exact
        path={ROUTES.petDirectoryOverview.path}
        component={PetDirectoryListPage}
      />
      <PrivateRoute exact path={ROUTES.rafflesEdit.path} component={RaffleEditPage} />
      <PrivateRoute exact path={ROUTES.rafflesOverview.path} component={RaffleListPage} />
      <PrivateRoute exact path={ROUTES.videoTutorials.path} component={VideoTutorialsPage} />
      <PrivateRoute exact path={ROUTES.moderator.path} component={ModeratorListPage} />
      <PrivateRoute exact path={ROUTES.moderatorPreview.path} component={ModeratorPreviewPage} />
      <PrivateRoute exact path={ROUTES.quickQuestionsEdit.path} component={QuickQuestionEditPage} />
      <PrivateRoute
        exact
        path={ROUTES.quickQuestionsOverview.path}
        component={QuickQuestionsListPage}
      />
      <PrivateRoute exact path={ROUTES.chatEdit.path} component={ChatEditPage} />
      <PrivateRoute exact path={ROUTES.chatOverview.path} component={ChatListPage} />
      <PrivateRoute exact path={ROUTES.postGenerator.path} component={PostGeneratorPage} />
      <PrivateRoute exact path={ROUTES.reportProblem.path} component={ReportProblemPage} />
      <PrivateRoute exact path={ROUTES.reportProblemEdit.path} component={ReportProblemEditPage} />
      <PrivateRoute exact path={ROUTES.donationsEdit.path} component={DonationsEditPage} />
      <PrivateRoute exact path={ROUTES.donationsOverview.path} component={DonationsListPage} />
      <PrivateRoute exact path={ROUTES.marketEdit.path} component={MarketEditPage} />
      <PrivateRoute exact path={ROUTES.marketOverview.path} component={MarketListPage} />
      <PrivateRoute path={'*'} component={NotFound} />
    </Switch>
  </Router>
);

const Routing = () => (
  <Router>
    <Routes />
    <GAnalytics />
  </Router>
);

export default Routing;
