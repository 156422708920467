import { MarketItemDetailQuery, MarketItemsQuery } from '@sim-admin-frontends/data-access';
import { TExtendedColumn, TSelectItem } from '@sim-admin-frontends/ui-shared';

import { TAnnouncementFormValues } from './TAnnouncements';

export type TMarketFormValuesBase = Omit<TAnnouncementFormValues, 'videos'>;

export interface TMarketFormValues extends TMarketFormValuesBase {
  subtitle: string;
  marketUrl: string;
  marketItemType: TSelectItem;
  marketItemDuration?: number;
  marketItemRating?: number;
  currency?: TSelectItem;
  fromPrice?: number;
  fromPriceBeforeDiscount?: number;
  marketItemInclusions?: string[];
  marketItemExclusions?: string[];
  marketItemAdditionalInfo?: string[];
  cancellationPolicyDescription?: string;
  logisticsStartDescriptions?: string[];
  logisticsEndDescriptions?: string[];
  marketItemOrder?: number;
  marketItemViatorProductCode?: string;
}

export type TMarketListItem = Extract<
  MarketItemsQuery['announcementsBy']['announcements'][number],
  { __typename: 'Announcement' }
>;

export type TMarketColumns = TExtendedColumn<TMarketListItem>[];

export type TMarketDetail = Extract<
  MarketItemDetailQuery['announcement'],
  { __typename?: 'Announcement' }
>;

export const isMarket = (
  marketItem?: MarketItemsQuery['announcementsBy']['announcements'][number],
): marketItem is TMarketListItem =>
  marketItem?.__typename === 'Announcement' && !!marketItem.marketUrl;

export const isMarketDetail = (
  marketItem?: MarketItemDetailQuery['announcement'],
): marketItem is TMarketDetail =>
  marketItem?.__typename === 'Announcement' && !!marketItem.marketUrl;
