import { Video } from '@sim-admin-frontends/data-access';

export type ImageFile = {
  id: string;
  blurHash?: string | null;
  fileName?: string | null;
  links?: {
    self: string;
  };
};

export const isFile = (file: File | ImageFile | Video): file is File => {
  return !!(file as File).name && !!(file as File).size && !!(file as File).lastModified;
};

export const decodeFileToBlob = async (file: File | ImageFile | Video | null | undefined) => {
  if (file && isFile(file)) {
    const blob = new Blob([new Uint8Array(await file.arrayBuffer())], {
      type: file.type,
    });
    return URL.createObjectURL(blob);
  } else {
    return file?.links?.self || '';
  }
};

export const isMinImageDimensions = async (
  file: File | ImageFile,
  minWidth: number,
  minHeight: number,
): Promise<boolean> => {
  if (!(file instanceof File)) {
    return true;
  }

  return await new Promise((resolve) => {
    const url = URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      const isValid = img.width >= minWidth && img.height >= minHeight;
      resolve(isValid);
    };
    img.onerror = () => {
      resolve(false);
    };
    img.src = url;
  });
};
