import Tippy from '@tippyjs/react';
import { FC, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../../../types/TBaseComponent';
import { Switcher } from '../../switcher/Switcher';
import { SectionHeader } from '../form-section-header/FormSectionHeader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spaces.spacing16} 0;
`;

const ControlsWrapper = styled.div<{ controlsPadding: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme, controlsPadding }) => (controlsPadding ? theme.spaces.spacing16 : 0)};
`;

const StyledTippy = styled(Tippy)`
  background: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.body};
  .tippy-arrow {
    color: ${({ theme }) => theme.colors.black};
  }
`;

interface Props extends TBaseProps {
  title?: string;
  customTitleComponent?: () => ReactNode;
  description?: string;
  initiallyOpened?: boolean;
  onVisibilityChanged: (open: boolean) => void;
  disabled?: boolean;
  disabledText?: string;
  controlsPadding?: boolean;
}

const SwitchableFormSection: FC<Props> = ({
  title,
  customTitleComponent,
  description,
  initiallyOpened = false,
  onVisibilityChanged,
  children,
  className,
  testId,
  disabled,
  disabledText,
  controlsPadding = false,
}) => {
  const [opened, setOpened] = useState(initiallyOpened);
  const onOpenChanged = (value: boolean) => {
    if (!disabled) {
      setOpened(value);
      onVisibilityChanged(value);
    }
  };
  const switcher = (
    <span>
      <Switcher onChange={onOpenChanged} value={opened} testId={testId} disabled={disabled} />
    </span>
  );

  return (
    <Wrapper className={className}>
      <ControlsWrapper controlsPadding={controlsPadding}>
        {customTitleComponent
          ? customTitleComponent()
          : title && <SectionHeader title={title} description={description} />}
        {disabled && disabledText ? (
          <StyledTippy content={disabledText} placement="top">
            {switcher}
          </StyledTippy>
        ) : (
          switcher
        )}
      </ControlsWrapper>
      {opened && children}
    </Wrapper>
  );
};

export { SwitchableFormSection };
