import { FC } from 'react';
import { SortingRule } from 'react-table';
import { Table } from '@sim-admin-frontends/ui-shared';

import { TMarketColumns, TMarketListItem } from '../../../../src/types/TMarket';

type MarketViewProps = {
  data: TMarketListItem[];
  columns: TMarketColumns;
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  onSortingChanged: (value: SortingRule<TMarketListItem>[]) => void;
  initialTableSorting: SortingRule<TMarketListItem>[];
  hasNextPage?: boolean;
};

const MarketView: FC<MarketViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  onSortingChanged,
  initialTableSorting,
  hasNextPage,
}) => (
  <Table
    hasNextPage={hasNextPage}
    loading={loading}
    itemsCount={itemsCount}
    pageIndex={pageIndex}
    data={data}
    columns={columns}
    hasPagination={true}
    onNextPress={onNextPress}
    onPreviousPress={onPreviousPress}
    onSortingChanged={onSortingChanged}
    initialSortBy={initialTableSorting}
    testId="MarketView#Table"
  />
);

export default MarketView;
