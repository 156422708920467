import {
  ImageInput,
  TAnnouncementDraftListItem,
  TAnnouncementListItem,
  VideoInput,
} from '@sim-admin-frontends/data-access';
import { TExtendedColumn, TSelectItem } from '@sim-admin-frontends/ui-shared';
import { TGeoShape } from '@sim-admin-frontends/utils-shared';

import { TFormLocation } from './TLocation';

export enum TAnnouncementsTabTypes {
  PUBLISHED = 'published',
  DRAFTS = 'drafts',
  SCHEDULED = 'scheduled',
  UNPUBLISHED = 'unpublished',
}

export enum TAnnouncementsFormTypes {
  ANNOUNCEMENT = 'announcement',
  EMERGENCY = 'emergency',
  POLL = 'poll',
  QUICK_QUESTION = 'quickQuestion',
  DONATION = 'donation',
  MARKET = 'market',
}

export enum AnnouncemetModalActions {
  DELETE = 'DELETE',
  UNPUBLISH = 'UNPUBLISH',
  PUBLISH = 'PUBLISH',
  CANCEL_SEND = 'CANCEL_SEND',
  NOTIFY = 'NOTIFY',
}

interface TAnnouncementFormValuesBase {
  title: string;
  content: string;
  lang?: TSelectItem;
  images?: (File | ImageInput)[] | null;
  videos?: (File | VideoInput)[] | null;
  places?: TSelectItem[];
}

export interface TAnnouncementFormValues extends TAnnouncementFormValuesBase {
  scheduledAt?: Date | null;
  notifications: { value: Date }[];
  notifyNow?: boolean;
  categories?: TSelectItem[];
  publisher?: TSelectItem;
  publishedAt?: Date | null;
  notifyAlsoNonCategoried?: boolean;
  notifyVolunteersOnly?: boolean;
  areaOfInterest?: TGeoShape | null;
  isLocalBusiness?: boolean;
}

export type TScrapedAnnouncementFormValues = {
  categories?: TSelectItem[];
};

export interface TEmergencyFormValues extends TAnnouncementFormValuesBase {
  location?: TFormLocation;
}

export type TAnnouncementTableItem = TAnnouncementListItem | TAnnouncementDraftListItem;

export type TAnnouncementsColumns = TExtendedColumn<TAnnouncementTableItem>[];
