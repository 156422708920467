import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstitutionBrandingQuery } from '@sim-admin-frontends/data-access';

import PageContentPreview from '../../layout/pageContent/PageContentPreview';
import { FALLBACK_INSTITUTION_NAME } from '../../../constants/Constants';
import { TQuickQuestionsFormValues } from '../../../types/TQuickQuestions';
import { QuickQuestionPreviewOptions } from './QuickQuestionPreviewOptions';
import { QuickQuestionPreviewFooter } from './QuickQuestionPreviewFooter';

const QuickQuestionPreview: FC<Partial<TQuickQuestionsFormValues>> = ({
  question,
  questionOptions,
  quickQuestionEndDate,
  publisher,
}) => {
  const { t } = useTranslation();
  const testIdPrefix = 'QuickQuestionPreview';

  const publisherUuid = publisher?.value || '';
  const institutionName = publisher?.label || FALLBACK_INSTITUTION_NAME;

  const { data: institutionBranding } = useInstitutionBrandingQuery({ id: publisherUuid });

  const institutionLogo = institutionBranding?.institution?.branding?.avatar.links?.self || '';

  return (
    <PageContentPreview
      name={institutionName}
      logo={institutionLogo}
      title={question}
      titlePlaceholder={t('quickQuestions.question')}
      testIdPrefix={testIdPrefix}
    >
      <QuickQuestionPreviewOptions options={questionOptions ?? []} />
      <QuickQuestionPreviewFooter endDate={quickQuestionEndDate} />
    </PageContentPreview>
  );
};

export default QuickQuestionPreview;
