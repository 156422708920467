import {
  uploadFilePost,
  UploadMethod,
  UploadType,
  useGenerateNamedUploadUrlsMutation,
  VIDEO_TYPE_FALLBACK,
  VideoInput,
} from '@sim-admin-frontends/data-access';

import { isFile } from '../file-utils';
import { TUploadDirectory, TUploadVideos, TUploadVideosFunction } from '../types/TUpload';

const useUploadVideo = () => {
  const { mutateAsync: fetchUploadUrls } = useGenerateNamedUploadUrlsMutation();

  const filterVideos = (videoFiles: TUploadVideos) => {
    const fileVideos: File[] = [];
    const uploadedVideos: VideoInput[] = [];

    if (videoFiles) {
      videoFiles.forEach((image) => {
        if (!!image && isFile(image)) {
          fileVideos.push(image);
        }
        if (!!image && !isFile(image)) {
          uploadedVideos.push(image);
        }
      });
    }
    return {
      fileVideos,
      uploadedVideos,
    };
  };

  const uploadVideos = async (
    videos: { fileVideos: File[]; uploadedVideos: VideoInput[] },
    { institutionId, placeId }: TUploadDirectory,
  ) => {
    const uploadUrls = [];
    const files = videos.fileVideos.map((video) => ({
      fileName: video.name,
      contentType: video.type || VIDEO_TYPE_FALLBACK,
      uploadType: UploadType.Video,
    }));
    const { generateNamedUploadUrls } = await fetchUploadUrls({
      files,
      uploadMethod: UploadMethod.Post,
      institutionUuid: institutionId,
      placeUuid: placeId,
    });
    if (generateNamedUploadUrls) {
      for (const url of generateNamedUploadUrls) {
        const index = generateNamedUploadUrls.indexOf(url);
        await uploadFilePost(videos.fileVideos[index], url);
        uploadUrls.push(url);
      }
      videos.uploadedVideos.map((uploadedVideo) => {
        uploadUrls.push(uploadedVideo);
      });
    }
    return uploadUrls.length ? uploadUrls : undefined;
  };

  const uploadFormVideos: TUploadVideosFunction = async (videos, { institutionId, placeId }) => {
    const filteredVideos = filterVideos(videos);
    return uploadVideos(filteredVideos, { institutionId, placeId });
  };

  return { uploadFormVideos };
};

export { useUploadVideo };
