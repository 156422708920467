import React, { FC } from 'react';
import { TListItemElement } from '@simplicity-tech/sim-slate-types';
import styled from 'styled-components';

import RichTextChild from './RichTextChild';

const Wrapper = styled.li``;

type Props = {
  element: TListItemElement;
};

const RichTextListItem: FC<Props> = ({ element }) => {
  return (
    <Wrapper>
      {element.children.map((child, i) => (
        <RichTextChild child={child} key={i} />
      ))}
    </Wrapper>
  );
};

export default RichTextListItem;
