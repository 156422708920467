import { FC } from 'react';
import { PageHeader, TableActionHeader, useSearchFilter } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { PageWrapper } from '../../components/layout/PageLayout';
import QuickQuestionsTabs from '../../components/quickQuestions/view/QuickQuestionsTabs';

const QuickQuestionsListPage: FC = () => {
  const { t } = useTranslation();
  const { filter, renderFilterComponent } = useSearchFilter({
    testId: 'QuickQuestionsListPage#Search',
  });

  return (
    <PageWrapper>
      <PageHeader
        title={t('quickQuestions.pageTitle')}
        caption={t('quickQuestions.pageCaption')}
        testId="QuickQuestions#PageHeader"
      />
      <TableActionHeader prependItem={renderFilterComponent()} />
      <QuickQuestionsTabs filter={filter} />
    </PageWrapper>
  );
};

export default QuickQuestionsListPage;
