import { FC } from 'react';
import { ArrowsIcon, CalendarAppIcon, ClockIcon } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import Requirement from './Requirement';
import { getTestId } from '../../../utils/testUtils';

type CouponPreviewRequirementsProps = {
  fromToDate: string;
  fromToTime: string | null;
  isReccuring?: boolean;
  testIdPrefix: string;
};

const CouponPreviewRequirements: FC<CouponPreviewRequirementsProps> = ({
  fromToTime,
  fromToDate,
  isReccuring,
  testIdPrefix,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Requirement
        active
        label={fromToDate}
        icon={<CalendarAppIcon />}
        testId={getTestId(testIdPrefix, `DateRequirement`)}
      />
      {fromToTime && (
        <Requirement
          active
          label={fromToTime}
          icon={<ClockIcon />}
          testId={getTestId(testIdPrefix, `TimeRequirement`)}
        />
      )}
      {isReccuring && (
        <Requirement
          active
          label={t('coupons.preview.available')}
          icon={<ArrowsIcon />}
          testId={getTestId(testIdPrefix, `RecurringRequirement`)}
        />
      )}
    </>
  );
};

export default CouponPreviewRequirements;
