import {
  CouponsOrderByInput,
  CouponsSortBy,
  SortOrder,
  State,
  TCouponListItem,
  CouponValidity,
} from '@sim-admin-frontends/data-access';
import { ACTION_ITEMS_MIN_WIDTH_SMALL, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { formatTableDate, formatTableDateTime } from '@sim-admin-frontends/utils-shared';
import { TFunction } from 'i18next';
import { CellProps, SortingRule } from 'react-table';

import {
  getPublishingDateAccessor,
  getDisabledColumns,
  parse24hTime,
} from '../../../utils/couponsUtils';
import CouponsTableMenu from './CouponsTableMenu';
import CouponTableImageCell from './CouponTableImageCell';

export const createTableColumns = (
  t: TFunction,
  refetch: () => void,
  type: State,
  timeZone?: string,
) => {
  const disabledColumns = getDisabledColumns(type);
  const columns: TExtendedColumn<TCouponListItem>[] = [
    {
      Header: `${t('coupons.table.coupon')}`,
      testId: 'CouponsTable#coupon',
      Cell: ({ row }: CellProps<TCouponListItem>) => {
        const src = row.original.imageObjects?.[0]?.links?.self;
        const thumbnail = row.original.imageObjects?.[0]?.links?.thumbnail || '';

        const title = row.original.title;
        const subtitle = row.original.subtitle;

        return (
          <CouponTableImageCell
            image={src}
            thumbnail={thumbnail}
            title={title || ''}
            subtitle={subtitle || ''}
            alt="couponImage"
            height={95}
          />
        );
      },

      disableSortBy: true,
      skeleton: {
        width: 100,
      },
      width: '100px',
    },
    {
      Header: `${t('coupons.table.published')}`,
      accessor: getPublishingDateAccessor(type),
      testId: 'CouponsTable#publicationDate',
      Cell: disabledColumns.includes('publishedAt')
        ? ''
        : ({ value }: { value: string }) => (value ? formatTableDateTime(value, timeZone) : ''),
      disabled: disabledColumns.includes('publishedAt'),
      disableSortBy: disabledColumns.includes('publishedAt'),
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('coupons.table.lastUpdate')}`,
      accessor: 'updatedAt',
      testId: 'CouponsTable#lastUpdate',
      Cell: disabledColumns.includes('updatedAt')
        ? ''
        : ({ value }) => (value ? formatTableDateTime(value, timeZone) : ''),
      disabled: disabledColumns.includes('updatedAt'),
      disableSortBy: disabledColumns.includes('updatedAt'),
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('coupons.table.validTo')}`,
      accessor: 'validity',
      testId: 'CouponsTable#validTo',
      Cell: ({ value }: { value: CouponValidity }) => {
        const toTime = value.interval?.toTime;

        if (!toTime) {
          return value?.interval?.toDate ? `${formatTableDate(value.interval.toDate)} ` : '';
        } else {
          return value?.interval?.toDate
            ? `${formatTableDate(value.interval.toDate)} ${parse24hTime(toTime, 'hh:mm a')} `
            : '';
        }
      },
      disabled: disabledColumns.includes('validity'),
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('coupons.table.used')}`,
      accessor: 'userActivities',
      testId: 'CouponsTable#used',
      Cell: disabledColumns.includes('used')
        ? ''
        : ({ value }) => (value.activationsCount ? `${value.activationsCount}x` : ''),
      disabled: disabledColumns.includes('used'),
      disableSortBy: true,
      skeleton: {
        width: 50,
      },
    },
    {
      Header: '',
      id: '0',
      testId: 'CouponsTable#dots',
      disableSortBy: true,
      minWidth: ACTION_ITEMS_MIN_WIDTH_SMALL,
      skeleton: {
        width: 50,
        align: 'right',
      },
      Cell: ({ row }: CellProps<TCouponListItem>) => {
        return (
          <CouponsTableMenu
            couponId={row.original.id}
            refetch={refetch}
            testId={`CouponsTable#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  return columns;
};

export const getInitialGqlSorting = (type?: State): CouponsOrderByInput => {
  switch (type) {
    case State.Unpublished:
      return {
        by: CouponsSortBy.UpdatedAt,
        order: SortOrder.Desc,
      };
    case State.Published:
      return {
        by: CouponsSortBy.PublishedAt,
        order: SortOrder.Desc,
      };

    default:
      return {
        by: CouponsSortBy.CreatedAt,
        order: SortOrder.Desc,
      };
  }
};

export const getInitialTableSorting = (type: State): SortingRule<TCouponListItem>[] => {
  switch (type) {
    case State.Unpublished:
    case State.Draft:
      return [
        {
          id: 'updatedAt',
          desc: true,
        },
      ];
    case State.Published:
      return [
        {
          id: 'publishedAt',
          desc: true,
        },
      ];
    default:
      return [];
  }
};

export const getGqlSorting = (
  tableSorting: SortingRule<TCouponListItem>[],
  type?: State,
): CouponsOrderByInput => {
  if (!tableSorting || tableSorting.length !== 1) {
    return getInitialGqlSorting(type);
  }
  const rule = tableSorting[0];

  switch (rule.id) {
    case 'createdAt':
      return {
        by: CouponsSortBy.CreatedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    case 'updatedAt':
      return {
        by: CouponsSortBy.UpdatedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    case 'publishedAt':
      return {
        by: CouponsSortBy.PublishedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    default:
      return getInitialGqlSorting(type);
  }
};
