import { TCategories } from '@sim-admin-frontends/data-access';

export const filterCategories = (
  categories?: TCategories | null,
  options?: {
    filterLegacy?: boolean;
  },
) =>
  categories?.filter(
    (category) => (options?.filterLegacy ? !category.legacy : true) && !category?.isSystem,
  ) || [];
