import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button, TableIconProps, FormButtonsWrapper } from '../..';

const ActionButtonsWrapper = styled(FormButtonsWrapper)`
  margin-bottom: ${({ theme }) => theme.spaces.spacing20};
  margin-top: 0;
`;

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spaces.spacing10};
`;

type Props = {
  actionButtons: TableIconProps[];
};

const ActionButtons: FC<Props> = ({ actionButtons, children }) => {
  const history = useHistory();
  if (!actionButtons.length) {
    return null;
  }

  const handleButtonClick = (action: (() => void) | undefined, path?: string) => {
    if (path) {
      history.push(path);
    }
    if (action) {
      action();
    }
  };

  return (
    <ActionButtonsWrapper>
      <div>
        {children}
        {actionButtons.map(({ label, action, testId, path }) => (
          <StyledButton
            variant="secondary"
            key={testId}
            type="submit"
            onClick={() => handleButtonClick(action, path)}
            testId={testId}
          >
            {label}
          </StyledButton>
        ))}
      </div>
    </ActionButtonsWrapper>
  );
};

export { ActionButtons };
