import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGoogleSheets from 'use-google-sheets';
import styled from 'styled-components';
import { Error, PageHeader, Spinner, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';

import { getAppLang } from '../../i18n/i18n';
import VideoTutorialItem, { Tutorial } from '../../components/videoTutorials/VideoTutorialItem';
import { PageWrapper } from '../layout/PageLayout';
import { GRID_ITEM_TOTAL_WIDTH, VIDEO_TUTORIALS_FALLBACK_LANG } from './constanst';

const Grid = styled.div`
  display: grid;
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
  grid-template-columns: repeat(
    auto-fit,
    minmax(${GRID_ITEM_TOTAL_WIDTH + 'px'}, ${GRID_ITEM_TOTAL_WIDTH + 'px'})
  );
`;

const VideoTutorialsContainer: FC = () => {
  const { t } = useTranslation();
  const language = getAppLang();
  const gridRef = useRef<HTMLInputElement>(null);
  const [itemsInRow, setItemsInRow] = useState<number>(1);

  const { data, loading, error, refetch } = useGoogleSheets({
    sheetId: process.env.NX_TUTORIAL_SPREADSHEET_ID ?? '',
    apiKey: process.env.NX_TUTORIAL_SPREADSHEET_API_KEY ?? '',
  });

  useEffect(() => {
    let observer: ResizeObserver | null = null;
    let localGridRef: HTMLInputElement | null = null;

    if (gridRef.current) {
      localGridRef = gridRef.current;
      (observer = new ResizeObserver(() => {
        if (gridRef?.current?.clientWidth) {
          const localItemsInRow = Math.floor(gridRef?.current?.clientWidth / GRID_ITEM_TOTAL_WIDTH);
          if (localItemsInRow !== itemsInRow) {
            setItemsInRow(localItemsInRow);
          }
        }
      })).observe(gridRef.current);
    }

    return () => {
      if (localGridRef) {
        observer?.unobserve(localGridRef);
      }
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [itemsInRow, gridRef.current]);

  const translatedSheet = useMemo(
    () =>
      data.find((sheet) => sheet.id === language) ||
      data.find((sheet) => sheet.id === VIDEO_TUTORIALS_FALLBACK_LANG) ||
      data[0],
    [data, language],
  );

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (error) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  return (
    <PageWrapper>
      <PageHeader
        title={t('videoTutorials.pageTitle')}
        caption={t('videoTutorials.pageCaption')}
        testId="VideoTutorials#PageHeader"
      />
      <Grid ref={gridRef}>
        {translatedSheet &&
          translatedSheet.data.map((item, index) => {
            const tutorial = item as Tutorial;
            const numberOfRows = translatedSheet.data.length / itemsInRow;
            const isOnlyOneRow = numberOfRows === 1;
            const isInLastRow = index + 1 > (Math.floor(numberOfRows) - 1 || 1) * itemsInRow;

            return (
              <VideoTutorialItem
                key={tutorial.link}
                tutorial={tutorial}
                showDivider={!isInLastRow && !isOnlyOneRow}
                testId="VideoTutorials"
              />
            );
          })}
      </Grid>
    </PageWrapper>
  );
};
export default VideoTutorialsContainer;
