import styled from 'styled-components';
import { TabPanel } from '@sim-admin-frontends/ui-shared';

export const CtaWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: ${({ theme }) => theme.spaces.spacing4};
`;

export const StyledPanel = styled(TabPanel)`
  margin-top: ${({ theme }) => theme.spaces.spacing32};
`;
