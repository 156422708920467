import { TFunction } from 'i18next';
import { CellProps } from 'react-table';
import {
  CategoriesQuery,
  State,
  TAnnouncementListItem,
  TCategory,
} from '@sim-admin-frontends/data-access';
import { TableHeaderTitle, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { formatTableDateTime } from '@sim-admin-frontends/utils-shared';

import EmergencyTableMenu from './EmergencyTableMenu';

const EMERGENCY_CATEGORY_NAME = 'emergency';

export const findEmergencyCategory = (categories?: CategoriesQuery): TCategory | undefined =>
  categories?.categoriesBy.categories.find((category) =>
    category.name.toLowerCase().includes(EMERGENCY_CATEGORY_NAME),
  );

export const createTableColumns = (t: TFunction, refetch: () => void, timeZone?: string) => {
  const columns: TExtendedColumn<TAnnouncementListItem>[] = [
    {
      Header: `${t('emergency.table.title')}`,
      accessor: (item) => <TableHeaderTitle item={item.title} maxTextLength={120} />,
      testId: 'EmergencyTable#title',
      disableSortBy: true,
      width: '60%',
    },
    {
      Header: `${t('emergency.table.published')}`,
      accessor: 'publishedAt',
      testId: 'EmergencyTable#publishDate',
      Cell: ({ value }: any) => formatTableDateTime(value, timeZone),
      skeleton: {
        width: 100,
      },
      width: '15%',
    },
    {
      Header: `${t('emergency.table.pinned')}`,
      accessor: 'publishmentState',
      testId: 'EmergencyTable#alertBanner',
      Cell: ({ value }) =>
        value === State.Pinned ? t('emergency.table.visible') : t('emergency.table.hidden'),
      disableSortBy: true,
      skeleton: {
        width: 110,
      },
      width: '15%',
    },
    {
      Header: '',
      id: '0',
      testId: 'EmergencyTable#dots',
      disableSortBy: true,
      skeleton: {
        width: 50,
        align: 'right',
      },
      width: '10%',
      // eslint-disable-next-line react/display-name
      Cell: ({ row }: CellProps<TAnnouncementListItem>) => {
        const isPinned = row.original.publishmentState === State.Pinned;
        return (
          <EmergencyTableMenu
            postId={row.original.id}
            pinned={isPinned}
            refetch={refetch}
            testId={`EmergencyView#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  return columns;
};
