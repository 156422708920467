const ROUTES = {
  login: {
    path: '/login',
  },
  forgotPassword: {
    path: '/forgotPassword',
  },
  resetPassword: {
    path: '/resetPassword/:username',
  },
  placeLoading: {
    path: '/placeLoading',
  },
  home: {
    path: '/',
  },
  announcementsOverview: {
    path: '/announcements/:type?',
  },
  settings: {
    path: '/settings/:type?',
  },
  videoTutorials: {
    path: '/videoTutorials',
  },
  eventsOverview: {
    path: '/events/:type?',
  },
  rafflesOverview: {
    path: '/raffles/:type?',
  },
  rafflesEdit: {
    path: '/raffles/edit/:id?',
  },
  emergencyOverview: {
    path: '/emergency/:type?',
  },
  emergencyEdit: {
    path: '/emergency/edit/:id?',
  },
  announcementsEdit: {
    path: '/announcements/edit/:id?',
  },
  scrapedAnnouncementEdit: {
    path: '/announcements/categoryEdit/:id',
  },
  eventsEdit: {
    path: '/events/edit/:id?',
  },
  insights: {
    path: '/insights/:type?',
  },
  pollsOverview: {
    path: '/polls/:type?',
  },
  pollsEdit: {
    path: '/polls/edit/:id?',
  },
  petDirectoryOverview: {
    path: '/pets/:type?',
  },
  couponsOverview: {
    path: '/coupons/:type?',
  },
  couponsEdit: {
    path: '/coupons/edit/:id?',
  },
  moderator: {
    path: '/moderator',
  },
  moderatorPreview: {
    path: '/moderator/preview/:type/:id',
  },
  quickQuestionsOverview: {
    path: '/quickQuestions/:type?',
  },
  quickQuestionsEdit: {
    path: '/quickQuestions/edit/:id?',
  },
  chatOverview: {
    path: '/chat/:type?',
  },
  chatEdit: {
    path: '/chat/edit/:id?',
  },
  postGenerator: {
    path: '/postGenerator',
  },
  reportProblem: {
    path: '/reportProblem/:type?',
  },
  reportProblemEdit: {
    path: '/reportProblem/edit/:id?',
  },
  donationsOverview: {
    path: '/donations/:type?',
  },
  donationsEdit: {
    path: '/donations/edit/:id?',
  },
  marketOverview: {
    path: '/market/:type?',
  },
  marketEdit: {
    path: '/market/edit/:id?',
  },
};

export default ROUTES;
