import {
  FormInput,
  FormSectionHeader,
  FormSelect,
  FormTextarea,
  TSelectItems,
} from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { Control, FieldErrors, get, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MessagingProblemConnectorStatus } from '@sim-admin-frontends/data-access-admin-be';

import { TProblemsFormValues } from '../../../types/TProblems';
import { getTestId } from '../../../utils/testUtils';

type ReportProblemConnectorProps = {
  register: UseFormRegister<TProblemsFormValues>;
  control: Control<TProblemsFormValues, object>;
  testIdPrefix: string;
  connector: TProblemsFormValues['connector'];
  errors: FieldErrors<TProblemsFormValues>;
  connectorTypeOptions: TSelectItems;
  connectorStatusOptions: TSelectItems;
};

export const ReportProblemConnector: FC<ReportProblemConnectorProps> = ({
  register,
  control,
  testIdPrefix,
  errors,
  connector,
  connectorTypeOptions,
  connectorStatusOptions,
}) => {
  const { t } = useTranslation();

  if (!connector) {
    return null;
  }

  const { type, status, id, statusMessage } = connector;
  const isIdDisabled =
    status.value !== MessagingProblemConnectorStatus.UploadError &&
    status.value !== MessagingProblemConnectorStatus.InputError;

  return (
    <>
      <FormSectionHeader title={t('reportProblem.form.connector.title')} />
      {id && (
        <FormInput
          label={t('reportProblem.form.connector.id')}
          {...register('connector.id')}
          testId={getTestId(testIdPrefix, 'ConnectorId')}
          disabled={isIdDisabled}
        />
      )}
      {type && (
        <FormSelect
          control={control}
          name="connector.type"
          label={t('reportProblem.form.connector.type')}
          error={get(errors, 'connector.type')}
          options={connectorTypeOptions}
          defaultValue={type}
          testId={getTestId(testIdPrefix, 'ConnectorType')}
          isReadonly
          disabled
        />
      )}
      {status && (
        <FormSelect
          control={control}
          label={t('reportProblem.form.connector.status')}
          name="connector.status"
          error={get(errors, 'connector.status')}
          options={connectorStatusOptions}
          defaultValue={status}
          testId={getTestId(testIdPrefix, 'ConnectorStatus')}
          isReadonly
          disabled
        />
      )}
      {statusMessage && (
        <FormTextarea
          label={t('reportProblem.form.connector.statusMessage')}
          {...register('connector.statusMessage')}
          testId={getTestId(testIdPrefix, 'ConnectorStatusMessage')}
          rows={3}
          disabled
        />
      )}
    </>
  );
};
