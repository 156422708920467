import {
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { decodeFileToBlob, ImageFile } from '@sim-admin-frontends/utils-shared';
import { useCallback, useState, useRef } from 'react';
import { ReactCropperElement } from 'react-cropper';
import { useTheme } from 'styled-components';
import { ImageInput } from '@sim-admin-frontends/data-access';

import {
  FormSectionHeader,
  FormSectionHeaderProps,
} from '../form/form-section-header/FormSectionHeader';
import { FormUpload, FormUploadProps } from '../form/form-upload/FormUpload';
import { ImageCropper } from '../cropper/ImageCropper';
import {
  MIN_IMAGE_UPLOAD_HEIGHT,
  MIN_IMAGE_UPLOAD_WIDTH,
  SUPPORTED_IMAGE_FORMATS,
} from '../../constants/FormConstants';
import { FormUploadSecondary } from '../form/form-upload/FormUploadSecondary';
import { ConfirmModal } from '../modal/ConfirmModal';

interface FormImageCropperUploadProps<T extends FieldValues>
  extends FormSectionHeaderProps,
    FormUploadProps<T> {
  setValue: UseFormSetValue<T>;
  getValues: UseFormGetValues<T>;
  trigger: UseFormTrigger<T>;
  aspectRatio?: number;
  minCropBoxWidth?: number;
  minCropBoxHeight?: number;
  multiple?: boolean;
  sublabel?: string;
  initialValue: (File | ImageInput)[] | null | undefined;
}

const FormImageCropperUpload = <T extends FieldValues>({
  control,
  setValue,
  getValues,
  trigger,
  name,
  title,
  label,
  description,
  dropzoneLabel,
  multiple = false,
  sublabel,
  t,
  testId,
  aspectRatio = 16 / 9,
  minCropBoxWidth = MIN_IMAGE_UPLOAD_WIDTH,
  minCropBoxHeight = MIN_IMAGE_UPLOAD_HEIGHT,
  initialValue,
}: FormImageCropperUploadProps<T>) => {
  const [isCropperOpen, setCropperOpen] = useState(false);
  const [lastFilesLength, setLastFilesLength] = useState(initialValue?.length || 0);
  const [cropperImage, setCropperImage] = useState<string>();
  const [lastFile, setLastFile] = useState<File | ImageFile>();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const cropperRef = useRef<ReactCropperElement>(null);
  const theme = useTheme();

  const onFilesChanged = useCallback(
    async (files?: (File | ImageFile)[]) => {
      if (!files) {
        setLastFilesLength(0);
        return;
      }

      if (files.length - lastFilesLength > 1) {
        setLastFilesLength(files.length);
        return;
      }

      const didRemove = files.length <= lastFilesLength;
      const newFile = files[files.length - 1];
      setLastFile(newFile);
      setLastFilesLength(files.length);

      if (
        didRemove ||
        !(newFile instanceof File) ||
        !SUPPORTED_IMAGE_FORMATS.includes(newFile.type)
      ) {
        return;
      }

      setCropperOpen(true);
      const decodedBlob = await decodeFileToBlob(newFile);
      setCropperImage(decodedBlob);
      setModalVisible(true);
    },
    [lastFilesLength],
  );

  const onCropClick = useCallback(async () => {
    const cropper = cropperRef?.current?.cropper;
    if (!cropper || !(lastFile instanceof File) || !lastFile?.type) {
      return;
    }

    const imageBlob: Blob | null = await new Promise((resolve) =>
      cropper?.getCroppedCanvas().toBlob(resolve, 'image/png'),
    );

    if (!imageBlob) {
      return;
    }

    const file = new File([imageBlob], lastFile.name, { type: lastFile.type });
    const files = getValues(name);
    setValue(name, [...files.slice(0, -1), file] as UnpackNestedValue<PathValue<T, Path<T>>>);
    trigger(name);
    setCropperOpen(false);
    cropper.clear();
  }, [getValues, lastFile, name, setValue, trigger, theme]);

  const onCancelClick = () => {
    setCropperOpen(false);
  };

  const closeModal = () => setModalVisible(false);

  return (
    <>
      {title && <FormSectionHeader title={title} description={description} />}
      {sublabel ? (
        <FormUploadSecondary
          control={control}
          name={name}
          dropzoneLabel={dropzoneLabel}
          multiple={multiple}
          t={t}
          testId={testId}
          onChange={onFilesChanged}
          label={label}
          bottomCaption={sublabel}
          accept={SUPPORTED_IMAGE_FORMATS}
        />
      ) : (
        <FormUpload
          control={control}
          name={name}
          dropzoneLabel={dropzoneLabel}
          multiple={multiple}
          t={t}
          testId={testId}
          onChange={onFilesChanged}
          label={label}
          accept={SUPPORTED_IMAGE_FORMATS}
        />
      )}
      <ImageCropper
        cropperRef={cropperRef}
        src={cropperImage}
        aspectRatio={aspectRatio}
        minCropBoxWidth={minCropBoxWidth}
        minCropBoxHeight={minCropBoxHeight}
        isCropperOpen={isCropperOpen}
        onCropClick={onCropClick}
        onCancelClick={onCancelClick}
        testId={testId}
        t={t}
      />
      <ConfirmModal
        isOpen={isModalVisible}
        title={t('modal.important')}
        content={t('modal.imageCropper.dimensions', {
          dimensions: `${MIN_IMAGE_UPLOAD_WIDTH}x${MIN_IMAGE_UPLOAD_HEIGHT}`,
        })}
        backButtonText={t('modal.iUnderstand')}
        onBackClick={closeModal}
      />
    </>
  );
};

export { FormImageCropperUpload };
