import { TFunction } from 'react-i18next';

import { NamedErrorWithMessage } from '../types/TError';

export const getIsTypedErrorWithMessage = (error: unknown): error is NamedErrorWithMessage =>
  typeof error === 'object' && !!error && 'name' in error && 'message' in error;

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    return error.message;
  }
  if (getIsTypedErrorWithMessage(error)) {
    return error.message;
  }
  return String(error);
};

export const getLoginErrorMessage = (error: unknown, t: TFunction) => {
  if (!getIsTypedErrorWithMessage(error)) {
    return String(error);
  }

  if (error.name === 'UserNotFoundException') {
    return t('error.loginError');
  }

  return error.message;
};
