import { State } from '@sim-admin-frontends/data-access';
import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateAnnoucementActions } from '../../../hooks/actionButtons/useGenerateAnnoucementActions';
import ROUTES from '../../../routing/routes';
import { changeMarketTab } from '../../../utils/marketUtils';

export interface MarketTableMenuProps extends TBaseProps {
  postId: string;
  isFromAdmin?: boolean | null;
  refetch: () => void;
  published: boolean;
  type: State;
}

const MarketTableMenu: FC<MarketTableMenuProps> = ({
  postId,
  isFromAdmin,
  refetch,
  published,
  testId,
  type,
}) => {
  const { listActions: items, renderModal } = useGenerateAnnoucementActions({
    isFromAdmin,
    type,
    published,
    postId,
    refetch,
    settings: {
      translationKey: 'market',
      testIdKey: 'Market',
      editPagePath: ROUTES.marketEdit.path,
      changeTab: changeMarketTab,
    },
  });

  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export default MarketTableMenu;
