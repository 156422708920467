import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ExternalLinkIcon } from '@sim-admin-frontends/ui-shared';
import styled from 'styled-components';

import PageContentPreview from '../../layout/pageContent/PageContentPreview';
import { TPollFormValues } from '../../../types/TPolls';

const StyledIframe = styled.iframe`
  width: 100%;
  flex: 1;
  margin-top: ${({ theme }) => theme.spaces.spacing8};
`;

const ButtonWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.spacing24} 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props extends Partial<TPollFormValues> {
  institutionName: string;
  institutionLogo?: string;
  iframeUrl?: string;
}

const PollPreview: FC<Props> = ({
  institutionName,
  institutionLogo,
  title,
  images,
  iframeUrl,
  videos,
}) => {
  const { t } = useTranslation();
  const testIdPrefix = 'PollPreview';
  return (
    <PageContentPreview
      name={institutionName}
      logo={institutionLogo}
      images={images}
      videos={videos}
      title={title}
      titlePlaceholder={t('polls.preview.titlePlaceholder')}
      testIdPrefix={testIdPrefix}
    >
      {iframeUrl && (
        <>
          <StyledIframe
            title="iframe"
            src={`${iframeUrl}?embedded=true`}
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            data-testid="PollPreview#Content"
          >
            {t('common.loading')}
          </StyledIframe>
          <ButtonWrapper>
            <Button variant="tertiary" appendIcon={<ExternalLinkIcon />}>
              {t('polls.preview.openInBrowser')}
            </Button>
          </ButtonWrapper>
        </>
      )}
    </PageContentPreview>
  );
};

export default PollPreview;
