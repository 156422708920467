import { RouteComponentProps } from 'react-router-dom';
import { FC } from 'react';

import { TSettingsTabs } from '../../types/TSettings';
import SettingsAboutCityContainer from '../../components/settings/aboutCity/SettingsAboutCity.container';
import SettingsProfileContainer from '../../components/settings/profile/SettingsProfile.container';
import NotFound from '../../routing/NotFound';
import { useIsPlaceAdmin } from '../../hooks/useIsPlaceAdmin';
import SettingsMessagesContainer from '../../components/settings/messages/SettingsMessages.container';

const getTab = (type: TSettingsTabs, isAuthorized: boolean) => {
  switch (type) {
    case TSettingsTabs.ABOUT_CITY:
      return isAuthorized ? <SettingsAboutCityContainer /> : <NotFound />;
    case TSettingsTabs.PROFILE:
      return <SettingsProfileContainer />;
    case TSettingsTabs.MESSAGES:
      return isAuthorized ? <SettingsMessagesContainer /> : <NotFound />;
    default:
      return <SettingsProfileContainer />;
  }
};

type Params = {
  type: TSettingsTabs;
};

const SettingsPage: FC<RouteComponentProps<Params>> = ({ match }) => {
  const { params } = match;
  const { isPlaceAdmin } = useIsPlaceAdmin();
  const tab = getTab(params.type, isPlaceAdmin);
  return <>{tab}</>;
};

export default SettingsPage;
