import {
  AnnouncementDraftsQuery,
  AnnouncementsByFilterInput,
  AnnouncementsOrderByInput,
  AnnouncementsQuery,
  ANNOUNCEMENTS_PAGE_SIZE,
  useAnnouncementDraftsQuery,
  useAnnouncementsQuery,
  useInfiniteGraphQLQuery,
} from '..';

export const getAnnouncementsNextPageParam = (
  lastPage: AnnouncementsQuery,
  allPages: AnnouncementsQuery[],
) => {
  return lastPage.announcementsBy.announcements.length === ANNOUNCEMENTS_PAGE_SIZE
    ? allPages.length * ANNOUNCEMENTS_PAGE_SIZE
    : undefined;
};

export const getAnnouncementsDraftsNextPageParam = (
  lastPage: AnnouncementDraftsQuery,
  allPages: AnnouncementDraftsQuery[],
) =>
  lastPage.announcementsBy.announcements.length === ANNOUNCEMENTS_PAGE_SIZE
    ? allPages.length * ANNOUNCEMENTS_PAGE_SIZE
    : undefined;

export const useInfiniteAnnouncementsQuery = (
  filter: AnnouncementsByFilterInput,
  orderBy?: AnnouncementsOrderByInput,
  enabled?: boolean,
) => {
  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    orderBy,
    pageOffset: Number(pageParam ?? 0),
    pageSize: ANNOUNCEMENTS_PAGE_SIZE,
    filter,
  });

  return useInfiniteGraphQLQuery(useAnnouncementsQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam: getAnnouncementsNextPageParam,
    enabled,
  });
};

export const useInfiniteAnnouncementDraftsQuery = (
  filter: AnnouncementsByFilterInput,
  orderBy?: AnnouncementsOrderByInput,
) => {
  const getVariables = ({ pageParam }: { pageParam?: number | string | null }) => ({
    orderBy,
    pageOffset: Number(pageParam ?? 0),
    pageSize: ANNOUNCEMENTS_PAGE_SIZE,
    filter,
  });

  return useInfiniteGraphQLQuery(useAnnouncementDraftsQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam: getAnnouncementsDraftsNextPageParam,
  });
};
