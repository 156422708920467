/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CategoryType } from '@sim-admin-frontends/data-access';
import { SpinnerWrapper } from '@sim-admin-frontends/ui-shared';

import AnnouncementsEditContainer from '../../announcements/edit/AnnouncementEdit.container';
import EventEditContainer from '../../events/edit/EventEdit.container';
import PollEditContainer from '../../polls/edit/PollEdit.container';
import RaffleEditContainer from '../../raffles/edit/RaffleEdit.container';
import NotFound from '../../../routing/NotFound';

export const renderContent = (categoryType?: string, id?: string) => {
  switch (categoryType?.toUpperCase()) {
    case CategoryType.Announcement:
      return <AnnouncementsEditContainer id={id} showOnlyPreview={true} />;
    case CategoryType.Event:
      return <EventEditContainer id={id} showOnlyPreview={true} />;
    case CategoryType.Poll:
      return <PollEditContainer id={id} showOnlyPreview={true} />;
    case CategoryType.Raffle:
      return <RaffleEditContainer id={id} showOnlyPreview={true} />;
    default:
      return (
        <SpinnerWrapper>
          <NotFound />
        </SpinnerWrapper>
      );
  }
};
