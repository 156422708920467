import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TToastType, updateToast } from '@sim-admin-frontends/ui-shared';
import {
  AddCouponMutationVariables,
  State,
  useAddCouponMutation,
  useUpdateCouponMutation,
} from '@sim-admin-frontends/data-access';

import { useAuthInfo } from '../contexts/userContext';
import { determineToastMessage } from '../utils/announcementsUtils';
import { COUPON_TOAST_SHARED_ID } from '../constants/Constants';
import { changeCouponsTab, submitCoupon, submitUpdateCoupon } from '../utils/couponsUtils';
import { TToastMessageType } from '../types/TToastMessage';
import { TAnnouncementsTabTypes } from '../types/TAnnouncements';

export const useCouponSubmit = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { institutionUuid } = useAuthInfo();
  const { t } = useTranslation();

  const { mutateAsync: addCoupon } = useAddCouponMutation();
  const { mutateAsync: updateCoupon } = useUpdateCouponMutation();

  const handleSubmitCoupon = async (
    couponPublishmentState: State | undefined,
    transformedData: AddCouponMutationVariables,
    couponId?: string,
  ) => {
    const toastMessage = determineToastMessage(
      couponPublishmentState,
      false,
      t,
      TToastMessageType.COUPON,
    );
    const tabRedirect = TAnnouncementsTabTypes.PUBLISHED;

    if (couponId) {
      await submitUpdateCoupon(
        transformedData.coupon,
        couponId,
        queryClient,
        institutionUuid,
        updateCoupon,
      );
    } else {
      await submitCoupon(
        transformedData.coupon,
        undefined,
        queryClient,
        institutionUuid,
        addCoupon,
      );
    }
    updateToast(COUPON_TOAST_SHARED_ID, toastMessage, TToastType.SUCCESS);
    changeCouponsTab(history, tabRedirect);
  };

  return {
    handleSubmitCoupon,
  };
};
