export enum TAnnouncementCreationSteps {
  STEP0 = '@announcementCreationStep0',
  STEP1 = '@announcementCreationStep1',
  STEP2 = '@announcementCreationStep2',
  STEP3 = '@announcementCreationStep3',
  STEP4 = '@announcementCreationStep4',
  STEP5 = '@announcementCreationStep5',
  STEP6 = '@announcementCreationStep6',
  STEP7 = '@announcementCreationStep7',
  STEP8 = '@announcementCreationStep8',
  OUTRO = '@announcementCreationOutroStep',
}
