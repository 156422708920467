import { FC, MouseEvent } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { HeaderTabs } from './headerTabs';

const Wrapper = styled.div`
  gap: ${({ theme }) => theme.spaces.spacing16};
  display: flex;
`;

const Tab = styled.div<{ disabled: boolean }>`
  text-decoration: none;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.coolGray40 : theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.caption1};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  padding: ${({ theme }) => theme.spaces.spacing8} 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

type Props = {
  tabs: HeaderTabs;
};

const HeaderContent: FC<Props> = ({ tabs }) => {
  const theme = useTheme();

  const activeStyle: React.CSSProperties = {
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.coolGray100,
    borderBottomStyle: 'solid',
  };

  const onClick = (e: MouseEvent<HTMLAnchorElement>, disabled: boolean) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
  };

  return (
    <Wrapper>
      {tabs.map(({ to, name, disabled = false, testId }) => (
        <Tab
          key={to}
          as={NavLink}
          exact
          to={generatePath(to)}
          disabled={disabled}
          activeStyle={activeStyle}
          onClick={(e: MouseEvent<HTMLAnchorElement>) => onClick(e, disabled)}
          data-testid={testId}
        >
          {name}
        </Tab>
      ))}
    </Wrapper>
  );
};

export default HeaderContent;
