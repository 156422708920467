import { BaseSyntheticEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldPath, FormProvider, get, useForm, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import {
  ExternalLinkIcon,
  FormDateTimePicker,
  FormInput,
  FormSectionHeader,
  FormSelect,
  FormWrapper,
  FormWysiwyg,
  SwitchableFormSection,
  TSelectItem,
  TSelectRef,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  FormImageCropperUpload,
  DEFAULT_RADIUS_MILES_STRING,
  TableIconProps,
  ActionButtons,
  FormSectionWithSwitch,
  SuccessOutlinedIcon,
  Button,
  FormTextarea,
} from '@sim-admin-frontends/ui-shared';
import {
  Currency,
  MarketItemType,
  State,
  TCategories,
  TInstitution,
  TInstitutionListItem,
} from '@sim-admin-frontends/data-access';
import {
  getInstitutionInitValues,
  getLangOptionByVal,
  getLangOptions,
  MaybeDate,
  roundMinutes,
  TGeoShape,
  TMapCenter,
} from '@sim-admin-frontends/utils-shared';
import styled from 'styled-components';

import { usePlaceInfo } from '../../../contexts/placeContext';
import { SwitchableSectionContentWrapper } from '../../announcements/edit/AnnouncementEditStyles';
import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import { FALLBACK_TIMEZONE } from '../../../constants/Constants';
import { getAreaOfInterest } from '../../../utils/announcementsUtils';
import { generateCirclePolygonPaths, generatePolygon, getMeters } from '../../../utils/mapUtils';
import {
  ContentHeader,
  ContentHeaderWrapper,
  HeaderWrapper,
} from '../../layout/pageContent/PageContentEditable';
import { getPlaceOptions } from '../../../utils/placeUtils';
import FormButtons from '../../announcements/edit/FormButtons';
import useDateTimeChanged from '../../../hooks/useDateTimeChanged';
import { imagesValidation, notificationsValidation } from '../../announcements/edit/validation';
import { TLoggedUser } from '../../../types/TUser';
import { getCategoriesOptions, getInitialCategoriesOptions } from '../../../utils/categoriesUtils';
import AnnouncementAreaOfInterest from '../../announcements/edit/AnnouncementAreaOfInterest';
import { TMarketDetail, TMarketFormValues } from '../../../types/TMarket';
import MarketNotificationSection from './MarketNotificationSection';
import { prepareFormValues } from '../../../utils/marketUtils';
import useChangePublisher from '../../../hooks/useChangePublisher';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.spaces.spacing48} 0;
`;

const schema = (t: TFunction, timezone: string, isScheduling: boolean, isMultiCity: boolean) => {
  return Yup.object().shape({
    title: Yup.string(),
    categories: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }),
      )
      .compact()
      .min(1, t('common.validation.required')),
    content: Yup.string().test('equals', t('common.validation.required'), (value) => {
      return value !== SERIALIZED_WYSIWYG_EMPTY_VALUE;
    }),
    marketUrl: Yup.string().url().required(t('common.validation.required')),
    places: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !(isMultiCity && (value || []).length < 1),
    ),
    scheduledAt: Yup.mixed()
      .test(
        'required',
        t('common.validation.required'),
        (value: MaybeDate) => !isScheduling || !!value,
      )
      .when('publishedAt', (publishedAt) =>
        Yup.date().test(
          'greaterThanPublished',
          t('common.validation.date.greaterThanPublished'),
          (value?: MaybeDate) => !isScheduling || (!!value && value > publishedAt),
        ),
      ),
    images: imagesValidation(t),
    notifications: notificationsValidation(t, timezone),
    publishedAt: Yup.mixed()
      .test('required', t('common.validation.required'), (value: MaybeDate) => !!value)
      .test(
        'maxdate',
        t('common.validation.date.futureDate'),
        (value: Date) => value <= roundMinutes(utcToZonedTime(new Date(), timezone)),
      ),
    marketItemDuration: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    marketItemRating: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .test(
        'min',
        t('common.validation.number.min', { num: 0 }),
        (value?: number) => !value || Number(value) >= 0,
      )
      .test(
        'max',
        t('common.validation.number.max', { num: 5 }),
        (value?: number) => !value || Number(value) <= 5,
      )
      .nullable(),
    fromPrice: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    fromPriceBeforeDiscount: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    marketItemOrder: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    marketItemViatorProductCode: Yup.string().nullable(),
    marketItemInclusions: Yup.array().of(Yup.string()),
    marketItemExclusions: Yup.array().of(Yup.string()),
    marketItemAdditionalInfo: Yup.array().of(Yup.string()),
    cancellationPolicyDescription: Yup.string().nullable(),
    logisticsStartDescriptions: Yup.array().of(Yup.string()),
    logisticsEndDescriptions: Yup.array().of(Yup.string()),
  });
};

export const getMarketItemTypeOptions = () =>
  Object.values(MarketItemType).map((value) => ({ label: value, value }));

export const getMarketItemTypeInitialOption = (marketItemType: MarketItemType): TSelectItem => ({
  label: marketItemType,
  value: marketItemType,
});

const getCurrencyOptions = () =>
  Object.values(Currency).map((value) => ({
    label: value,
    value: value,
  }));

const getCurrencyInitilaOption = (currency?: Currency) => {
  if (!currency) {
    return undefined;
  }
  return {
    label: currency,
    value: currency,
  };
};

type Props = {
  user: TLoggedUser | null;
  isLocalBusiness: boolean;
  onSubmit: (values: TMarketFormValues) => Promise<void>;
  onDiscard: () => void;
  categories: TCategories;
  marketItem?: TMarketDetail;
  institution: TInstitution;
  userInstitutions: TInstitutionListItem[];
  institutionCenter: TMapCenter;
  marketFormActions: TableIconProps[];
};

const MarketEdit: FC<Props> = ({
  user,
  isLocalBusiness,
  onSubmit,
  onDiscard,
  marketItem,
  categories,
  institution,
  userInstitutions,
  institutionCenter,
  marketFormActions,
}) => {
  const now = new Date();
  const isEdit = !!marketItem;
  const cantSchedule =
    marketItem?.publishmentState === State.Published ||
    marketItem?.publishmentState === State.Unpublished;

  const categoriesOptions = getCategoriesOptions(categories);
  const initialCategories = useMemo(() => {
    if (isLocalBusiness) {
      return [{ label: institution?.category.name || '', value: institution?.category.id || '' }];
    }

    return getInitialCategoriesOptions(categoriesOptions, marketItem?.categories);
  }, [isLocalBusiness, categoriesOptions, marketItem, institution]);

  const categoriesRef = useRef<TSelectRef>();
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const institutionLang = institution?.lang || undefined;
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;
  const countryCode = places?.[0].countryCode || '';

  const [isScheduling, setScheduling] = useState(!!marketItem?.scheduledAt && !cantSchedule);

  const institutionOptions = useMemo(
    () =>
      getInstitutionInitValues(
        user?.attributes?.['custom:institutionUuids'],
        institution,
        userInstitutions,
      ),
    [userInstitutions, institution, user?.attributes],
  );

  const placesOptions = useMemo(() => getPlaceOptions(institution?.places), [institution]);

  const initialValues: TMarketFormValues = {
    title: marketItem?.title || '',
    content: marketItem?.content || SERIALIZED_WYSIWYG_EMPTY_VALUE,
    subtitle: marketItem?.subtitle || '',
    marketUrl: marketItem?.marketUrl || '',
    lang: getLangOptionByVal(marketItem?.lang || institutionLang, countryCode),
    images: marketItem?.imageObjects,
    categories: initialCategories,
    places: getPlaceOptions(marketItem?.places),
    publisher: { label: institution?.name || '', value: institution?.id || '' },
    scheduledAt:
      marketItem?.scheduledAt && !cantSchedule
        ? utcToZonedTime(marketItem?.scheduledAt, timezone)
        : undefined,
    notifyNow: isEdit ? !!marketItem?.notifyNow : false,
    notifications: marketItem?.notifications
      ? marketItem?.notifications.map((notification) => ({
          value: utcToZonedTime(notification, timezone),
        }))
      : [],
    publishedAt: marketItem?.publishedAt
      ? utcToZonedTime(marketItem?.publishedAt, timezone)
      : utcToZonedTime(now, timezone),
    notifyAlsoNonCategoried: marketItem?.notifyAlsoNonCategoried || false,
    areaOfInterest: getAreaOfInterest(marketItem?.areaOfInterest),
    marketItemType: getMarketItemTypeInitialOption(
      marketItem?.marketItemType || MarketItemType.Memberdeals,
    ),
    marketItemDuration: marketItem?.marketItemDuration || undefined,
    marketItemRating: marketItem?.marketItemRating || undefined,
    currency: getCurrencyInitilaOption(marketItem?.marketItemPricing?.currency),
    fromPrice: marketItem?.marketItemPricing?.summary?.fromPrice || undefined,
    fromPriceBeforeDiscount:
      marketItem?.marketItemPricing?.summary?.fromPriceBeforeDiscount || undefined,
    marketItemInclusions: marketItem?.marketItemInclusions ?? [],
    marketItemExclusions: marketItem?.marketItemExclusions ?? [],
    marketItemAdditionalInfo: marketItem?.marketItemAdditionalInfo ?? [],
    cancellationPolicyDescription:
      marketItem?.marketItemCancellationPolicy?.description || undefined,
    logisticsStartDescriptions: marketItem?.marketItemLogistics?.startDescriptions ?? [],
    logisticsEndDescriptions: marketItem?.marketItemLogistics?.endDescriptions ?? [],
    marketItemOrder: marketItem?.marketItemOrder || undefined,
    marketItemViatorProductCode: marketItem?.marketItemViatorProductCode || undefined,
  };

  const shouldShowCitySelect = (placesOptions || []).length > 1;

  const methods = useForm<TMarketFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, timezone, isScheduling, shouldShowCitySelect)),
    mode: 'all',
  });

  const { handleSubmit, register, formState, setValue, getValues, trigger, control } = methods;
  const { errors, isSubmitting, isDirty, dirtyFields } = formState;

  const [previewAreaOfInterest, previewMarketUrl] = useWatch({
    name: ['areaOfInterest', 'marketUrl'],
    control,
  });

  const { onDateTimeChanged: onScheduleDateTimeChanged } = useDateTimeChanged(
    'scheduledAt',
    setValue,
    trigger,
  );

  const { onDateTimeChanged: onPublishedAtDateTimeChanged } = useDateTimeChanged(
    'publishedAt',
    setValue,
    trigger,
  );

  const initialPublishedAtDate = initialValues.publishedAt || utcToZonedTime(now, timezone);

  const initialScheduleDate = initialValues.scheduledAt || roundMinutes(initialPublishedAtDate);

  const onSchedulingSectionVisibilityChanged = () => {
    setValue(
      'scheduledAt',
      !isScheduling ? roundMinutes(utcToZonedTime(now, timezone)) : undefined,
    );
    setScheduling(!isScheduling);
    trigger('scheduledAt');
  };

  const onNotificationsEnabledChange = (value: boolean) => {
    setValue('notifyNow', value);
  };

  const onNotifyAlsoNonCategoriedChange = (value: boolean) => {
    setValue('notifyAlsoNonCategoried', value);
  };

  const onAreaOfInterestVisibilityChanged = useCallback((value: boolean) => {
    if (!value) {
      setValue('areaOfInterest', null);
      return;
    }
    const radius = getMeters(DEFAULT_RADIUS_MILES_STRING);
    const hexagonPolygonPaths = generateCirclePolygonPaths(institutionCenter, radius);
    const newAreaOfInterest = generatePolygon(hexagonPolygonPaths);
    setValue('areaOfInterest', previewAreaOfInterest ?? newAreaOfInterest);
  }, []);

  const onAreaOfInterestChange = (value: TGeoShape | null) => {
    setValue('areaOfInterest', value);
  };

  const onTextListChange = (event: BaseSyntheticEvent, field: FieldPath<TMarketFormValues>) => {
    const text = event.target.value;
    const values = text.split('\n');
    const transformedValues = values.filter((code: string) => code.length > 0);
    setValue(field, transformedValues);
    trigger(field);
  };

  const onMarketItemInclusionsChange = (event: BaseSyntheticEvent) =>
    onTextListChange(event, 'marketItemInclusions');

  const onMarketItemExclusionsChange = (event: BaseSyntheticEvent) =>
    onTextListChange(event, 'marketItemExclusions');

  const onMarketItemAdditionalInfoChange = (event: BaseSyntheticEvent) =>
    onTextListChange(event, 'marketItemAdditionalInfo');

  const onLogisticsStartDescriptionsChange = (event: BaseSyntheticEvent) =>
    onTextListChange(event, 'logisticsStartDescriptions');

  const onLogisticsEndDescriptionsChange = (event: BaseSyntheticEvent) =>
    onTextListChange(event, 'logisticsEndDescriptions');

  useEffect(() => {
    trigger('scheduledAt');
  }, [isScheduling, trigger]);

  const submit = async (values: TMarketFormValues) =>
    onSubmit(prepareFormValues(values, isScheduling, timezone));

  const onSubmitClick = () => {
    methods.control._updateProps({
      resolver: yupResolver(schema(t, timezone, isScheduling, shouldShowCitySelect)),
    });

    handleSubmit(submit)();
  };

  const { onPublisherChange } = useChangePublisher({ userInstitutions });

  const pageTitle = marketItem ? t('market.form.editPageTitle') : t('market.form.createPageTitle');

  const showPublishers = institutionOptions?.length > 1;

  const minScheduledDate = roundMinutes(getValues('publishedAt') || utcToZonedTime(now, timezone));
  const validUrlField = dirtyFields.marketUrl && !errors.marketUrl;

  return (
    <Wrapper>
      <FormWrapper>
        <ActionButtons actionButtons={marketFormActions} />
        <HeaderWrapper>
          <ContentHeaderWrapper>
            <ContentHeader noPadding>{pageTitle}</ContentHeader>
          </ContentHeaderWrapper>
        </HeaderWrapper>
        <FormProvider {...methods}>
          <FormInput
            label={t('updates.form.title')}
            {...register('title')}
            error={errors.title}
            testId="MarketEdit#title"
          />
          <FormInput
            label={t('market.table.subtitle')}
            {...register('subtitle')}
            error={errors.subtitle}
            testId="MarketEdit#subtitle"
          />
          <FormWysiwyg
            control={control}
            name="content"
            label={t('updates.form.content')}
            initialValue={JSON.parse(getValues('content'))}
            testId="MarketEdit#wysiwyg"
          />
          <FormInput
            label={t('market.form.url')}
            {...register('marketUrl')}
            error={errors.marketUrl}
            testId="MarketEdit#marketUrl"
            placeholder={t('market.form.urlPlaceholder')}
            icon={validUrlField ? <SuccessOutlinedIcon /> : undefined}
            noMargin
          />
          {validUrlField && (
            <a href={previewMarketUrl} target="_blank" rel="noreferrer">
              <Button variant="tertiary" appendIcon={<ExternalLinkIcon />}>
                {t('market.form.openUrl')}
              </Button>
            </a>
          )}
          <FormSectionHeader
            title={t('market.form.marketItemType')}
            description={t('market.form.marketItemTypeDescription')}
          />
          <FormSelect
            control={control}
            name="marketItemType"
            options={getMarketItemTypeOptions()}
            defaultValue={initialValues.marketItemType}
            testId={'Announcement#marketItemTypeSelect'}
          />
          <FormInput
            label={t('market.form.duration')}
            {...register('marketItemDuration')}
            error={errors.marketItemDuration}
            testId="MarketEdit#duration"
            placeholder={t('market.form.duration')}
            type={'number'}
          />
          <FormInput
            label={t('market.form.rating')}
            {...register('marketItemRating')}
            error={errors.marketItemRating}
            testId="MarketEdit#rating"
            placeholder={t('market.form.rating')}
            type={'number'}
            min={0}
            minLength={1}
            max={5}
            maxLength={1}
          />
          <FormInput
            label={t('market.form.fromPrice')}
            {...register('fromPrice')}
            error={errors.fromPrice}
            testId="MarketEdit#fromPrice"
            placeholder={t('market.form.fromPrice')}
            type={'number'}
          />
          <FormInput
            label={t('market.form.fromPriceBeforeDiscount')}
            {...register('fromPriceBeforeDiscount')}
            error={errors.fromPriceBeforeDiscount}
            testId="MarketEdit#fromPriceBeforeDiscount"
            placeholder={t('market.form.fromPriceBeforeDiscount')}
            type={'number'}
          />
          <FormSectionHeader
            title={t('market.form.currency')}
            description={t('market.form.currencyDescription')}
          />
          <FormSelect
            control={control}
            name="currency"
            options={getCurrencyOptions()}
            defaultValue={initialValues.currency}
            testId={'Announcement#currencySelect'}
            clearable
          />
          <FormInput
            label={t('market.form.cancellationPolicyDescription')}
            {...register('cancellationPolicyDescription')}
            error={errors.cancellationPolicyDescription}
            testId="MarketEdit#cancellationPolicyDescription"
            placeholder={t('market.form.cancellationPolicyDescription')}
          />
          <FormTextarea
            label={t('market.form.marketItemInclusions')}
            name={'marketItemInclusions'}
            onChange={onMarketItemInclusionsChange}
            defaultValue={initialValues.marketItemInclusions?.join('\n')}
            testId="Announcement#marketItemInclusions"
          />
          <FormTextarea
            label={t('market.form.marketItemExclusions')}
            name={'marketItemExclusions'}
            onChange={onMarketItemExclusionsChange}
            defaultValue={initialValues.marketItemExclusions?.join('\n')}
            testId="Announcement#marketItemExclusions"
          />
          <FormTextarea
            label={t('market.form.marketItemAdditionalInfo')}
            name={'marketItemAdditionalInfo'}
            onChange={onMarketItemAdditionalInfoChange}
            defaultValue={initialValues.marketItemAdditionalInfo?.join('\n')}
            testId="Announcement#marketItemAdditionalInfo"
          />
          <FormTextarea
            label={t('market.form.logisticsStartDescriptions')}
            name={'logisticsStartDescriptions'}
            onChange={onLogisticsStartDescriptionsChange}
            defaultValue={initialValues.logisticsStartDescriptions?.join('\n')}
            testId="Announcement#logisticsStartDescriptions"
          />
          <FormTextarea
            label={t('market.form.logisticsEndDescriptions')}
            name={'logisticsEndDescriptions'}
            onChange={onLogisticsEndDescriptionsChange}
            defaultValue={initialValues.logisticsEndDescriptions?.join('\n')}
            testId="Announcement#logisticsEndDescriptions"
          />
          <FormInput
            label={t('market.form.marketItemOrder')}
            {...register('marketItemOrder')}
            error={errors.marketItemOrder}
            testId="MarketEdit#marketItemOrder"
            placeholder={t('market.form.marketItemOrder')}
            type={'number'}
          />
          <FormInput
            label={t('market.form.marketItemViatorProductCode')}
            {...register('marketItemViatorProductCode')}
            error={errors.marketItemViatorProductCode}
            testId="MarketEdit#marketItemViatorProductCode"
            placeholder={t('market.form.marketItemViatorProductCode')}
          />

          <FormSectionHeader
            title={t('updates.form.language')}
            description={t('updates.form.languageDescription')}
          />
          <FormSelect
            control={control}
            name="lang"
            options={getLangOptions()}
            defaultValue={initialValues.lang}
            testId={'Announcement#languageSlect'}
            allSelectable
          />
          {showPublishers && (
            <>
              <FormSectionHeader
                title={t('updates.form.publisher')}
                description={t('market.form.publisherDescription')}
              />
              <FormSelect
                control={control}
                name="publisher"
                error={get(errors, 'publisher')}
                options={institutionOptions || []}
                defaultValue={initialValues.publisher}
                onChange={onPublisherChange}
                testId="MarketEdit#publisher"
              />
            </>
          )}
          {!isLocalBusiness && (
            <>
              <FormSectionHeader
                title={t('updates.form.category')}
                description={t('market.form.categoryDescription')}
              />
              <FormSelect
                control={control}
                name="categories"
                error={get(errors, 'categories')}
                options={categoriesOptions}
                defaultValue={initialValues.categories}
                ref={categoriesRef}
                testId="MarketEdit#categories"
                disabled={isLocalBusiness}
                isMulti
              />
            </>
          )}

          {shouldShowCitySelect && (
            <>
              <FormSectionHeader
                title={t('updates.form.cities')}
                description={t('updates.form.citiesDescription')}
              />
              <FormSelect
                control={control}
                name="places"
                error={get(errors, 'places')}
                options={placesOptions || []}
                defaultValue={initialValues.places}
                testId="MarketEdit#places"
                allSelectable
                clearable
                isMulti
              />
            </>
          )}
          <FormImageCropperUpload
            initialValue={initialValues.images}
            title={t('updates.form.imageLabel')}
            description={t('updates.form.imageSublabel')}
            control={control}
            setValue={setValue}
            getValues={getValues}
            trigger={trigger}
            name="images"
            dropzoneLabel={t('updates.form.dropzoneLabel')}
            t={t}
            multiple
            testId="MarketEdit#image"
          />
          <FormSectionWithSwitch
            title={t('updates.form.notifyAll')}
            description={t('updates.form.notifyAllDescription')}
            testId="MarketEdit#notifyAll"
            initialValue={initialValues.notifyAlsoNonCategoried ?? false}
            onChange={onNotifyAlsoNonCategoriedChange}
          />
          <SwitchableFormSection
            title={t('market.form.scheduleSection')}
            description={t('market.form.scheduleSectionDescription')}
            onVisibilityChanged={onSchedulingSectionVisibilityChanged}
            initiallyOpened={isScheduling}
            disabled={cantSchedule}
            disabledText={t('updates.form.disabledShedule')}
            testId={'MarketSchedule#switch'}
          >
            <SwitchableSectionContentWrapper>
              <FormDateTimePicker
                control={control}
                name="scheduledAt"
                initialDate={initialScheduleDate}
                minDate={minScheduledDate}
                onChange={onScheduleDateTimeChanged}
                dateLabel=""
                timeLabel=""
                initialTime={format(initialScheduleDate, 'hh:mm')}
                initialDayPeriod={format(initialScheduleDate, 'a')}
                testId="MarketSchedule#date"
              />
            </SwitchableSectionContentWrapper>
          </SwitchableFormSection>
          <SwitchableFormSection
            title={t('updates.form.areaOfInterest')}
            description={t('updates.form.areaOfInterestDescription')}
            initiallyOpened={!!previewAreaOfInterest}
            onVisibilityChanged={onAreaOfInterestVisibilityChanged}
          >
            <SwitchableSectionContentWrapper>
              <AnnouncementAreaOfInterest
                initalValue={initialValues.areaOfInterest || null}
                currentValue={previewAreaOfInterest || null}
                onChange={onAreaOfInterestChange}
                center={institutionCenter}
              />
            </SwitchableSectionContentWrapper>
          </SwitchableFormSection>

          <MarketNotificationSection
            control={control}
            notifyNow={initialValues.notifyNow}
            publishmentState={marketItem?.publishmentState}
            isEdit={isEdit}
            onNotificationsEnabledChange={onNotificationsEnabledChange}
          />
          <FormSectionHeader title={t('updates.form.publishedAt')} />
          <FormDateTimePicker
            control={control}
            name="publishedAt"
            initialDate={initialPublishedAtDate}
            onChange={onPublishedAtDateTimeChanged}
            dateLabel=""
            timeLabel=""
            initialTime={format(initialPublishedAtDate, 'hh:mm')}
            maxDate={roundMinutes(utcToZonedTime(now, timezone))}
            initialDayPeriod={format(initialPublishedAtDate, 'a')}
            testId="MarketEdit#publishedAt"
            isTimePickerWritable
          />
          <FormButtons
            isSubmitting={isSubmitting}
            getValues={getValues}
            errors={errors}
            publishmentState={marketItem?.publishmentState}
            isScheduling={isScheduling}
            onDiscard={onDiscard}
            onSubmitClick={onSubmitClick}
          />
        </FormProvider>
      </FormWrapper>
      <RouteLeavingGuard when={isDirty && !isSubmitting} />
    </Wrapper>
  );
};

export default MarketEdit;
