import 'react-toastify/dist/ReactToastify.min.css';
import styled from 'styled-components';
import {
  Slide,
  toast as reactToast,
  ToastContainer as ReactToastContainer,
  ToastOptions,
  UpdateOptions,
} from 'react-toastify';

import { CloseIcon, ErrorIcon, SuccessIcon, InfoIcon } from '../..';
import { TToastType } from '../../types/TToast';

const DEFAULT_OPTIONS: ToastOptions = {
  autoClose: 3500,
};

const StyledToastContainer = styled(ReactToastContainer)`
  .Toastify__toast {
    min-height: 50px;
    border-radius: ${({ theme }) => theme.borderRadius.radius8};
    padding: ${({ theme }) => theme.spaces.spacing16} ${({ theme }) => theme.spaces.spacing24};
    box-shadow: 20px 20px 65px rgba(0, 0, 0, 0.07), 5px 5px 15px rgba(0, 0, 0, 0.12);
  }

  .Toastify__toast-body {
    flex: 1;
    color: ${({ theme }) => theme.colors.coolGray100};
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: ${({ theme }) => theme.lineHeights.small};
    padding: 0;
  }

  .Toastify__toast-icon {
    width: auto;
    margin-right: ${({ theme }) => theme.spaces.spacing16};
  }
`;

const StyledCloseButton = styled(CloseIcon)`
  align-self: center;
  margin-left: ${({ theme }) => theme.spaces.spacing8};
`;

const CloseButton = () => <StyledCloseButton />;

const ToastContainer = () => (
  <StyledToastContainer
    hideProgressBar
    position={reactToast.POSITION.TOP_CENTER}
    transition={Slide}
    closeButton={CloseButton}
    autoClose={DEFAULT_OPTIONS.autoClose}
  />
);

const getIcon = (type: TToastType) => {
  switch (type) {
    case reactToast.TYPE.SUCCESS:
      return <SuccessIcon data-testid="Toast#icon-success" />;
    case reactToast.TYPE.ERROR:
      return <ErrorIcon data-testid="Toast#icon-error" />;
    case reactToast.TYPE.INFO:
      return <InfoIcon data-testid="Toast#icon-info" />;
    default:
      return null;
  }
};

const toast = (message: string, type: TToastType, options: ToastOptions = DEFAULT_OPTIONS) => {
  const icon = getIcon(type);
  return reactToast(message, {
    type,
    icon,
    ...options,
  });
};

export const loadingToast = (message: string, options: ToastOptions = DEFAULT_OPTIONS) => {
  return reactToast(message, {
    isLoading: true,

    autoClose: DEFAULT_OPTIONS.autoClose,
    ...options,
  });
};

export const updateToast = (
  id: string | number,
  message: string,
  type: TToastType,
  options?: UpdateOptions,
) => {
  const icon = getIcon(type);
  reactToast.update(id, {
    type,
    render: message,
    icon,
    isLoading: false,
    autoClose: DEFAULT_OPTIONS.autoClose,
    ...options,
  });
};

const toastDismiss = (id?: string) => reactToast.dismiss(id);

export { ToastContainer, toast, toastDismiss };
