import { FC } from 'react';
import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { TQuickQuestionListItem, TQuickQuestionsList } from '@sim-admin-frontends/data-access';

export interface QuickQuestionsViewProps {
  data: TQuickQuestionsList;
  columns: TExtendedColumn<TQuickQuestionListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  hasNextPage?: boolean;
}

const ChatView: FC<QuickQuestionsViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  hasNextPage,
}) => (
  <Table
    hasNextPage={hasNextPage}
    loading={loading}
    itemsCount={itemsCount}
    pageIndex={pageIndex}
    data={data}
    columns={columns}
    hasPagination={true}
    onNextPress={onNextPress}
    onPreviousPress={onPreviousPress}
    testId="ChatView#Table"
  />
);

export { ChatView };
