import {
  AdminDashboardTopReactionsQuery,
  AdminDashboardTotalsAnalyticsQuery,
} from '@sim-admin-frontends/data-access';

export enum TChartDataTimeSpan {
  WEEK = 7,
  MONTH = 30,
  QUARTER = 90,
  HALF_YEAR = 180,
  YEAR = 365,
  ALL = 99999,
  CUSTOM = 'Custom',
}

export enum TChartDataMetrics {
  FOLLOWERS,
  SUBSCRIBERS,
  REACH,
  REACTIONS,
}

export type TTotalsAnalytics = AdminDashboardTotalsAnalyticsQuery['adminDashboardTotalsAnalytics'];
export type TFullPostsType = NonNullable<
  AdminDashboardTopReactionsQuery['adminDashboardTopReactions']
>['posts'];

export type TFullPost = TFullPostsType[number];

export type TQuickQuestionPostType = Extract<TFullPost, { __typename: 'QuickQuestion' }>;

export type TAnnonucementEventPost = Extract<TFullPost, { __typename: 'Announcement' | 'Event' }>;

export const isQuickQuestionPostType = (post: TFullPost): post is TQuickQuestionPostType =>
  !!(post as TQuickQuestionPostType).question;
