import { State } from '@sim-admin-frontends/data-access';
import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateAnnoucementActions } from '../../../hooks/actionButtons/useGenerateAnnoucementActions';
import ROUTES from '../../../routing/routes';
import { changeDonationsTab } from '../../../utils/donationsUtils';

export interface DonationsTableMenuProps extends TBaseProps {
  postId: string;
  isFromAdmin?: boolean | null;
  refetch: () => void;
  published: boolean;
  type: State;
}

const DonationsTableMenu: FC<DonationsTableMenuProps> = ({
  postId,
  isFromAdmin,
  refetch,
  published,
  testId,
  type,
}) => {
  const { listActions: items, renderModal } = useGenerateAnnoucementActions({
    isFromAdmin,
    type,
    published,
    postId,
    refetch,
    settings: {
      translationKey: 'donations',
      testIdKey: 'Donations',
      editPagePath: ROUTES.donationsEdit.path,
      changeTab: changeDonationsTab,
    },
  });

  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export { DonationsTableMenu };
