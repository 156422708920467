import { FC } from 'react';
import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { TQuickQuestionListItem, TQuickQuestionsList } from '@sim-admin-frontends/data-access';
import { SortingRule } from 'react-table';

export interface QuickQuestionsViewProps {
  data: TQuickQuestionsList;
  columns: TExtendedColumn<TQuickQuestionListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  onSortingChanged: (value: SortingRule<TQuickQuestionListItem>[]) => void;
  initialTableSorting: SortingRule<TQuickQuestionListItem>[];
  hasNextPage?: boolean;
}

const QuickQuestionsView: FC<QuickQuestionsViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  onSortingChanged,
  initialTableSorting,
  hasNextPage,
}) => {
  return (
    <>
      <Table
        hasNextPage={hasNextPage}
        loading={loading}
        itemsCount={itemsCount}
        pageIndex={pageIndex}
        data={data}
        columns={columns}
        hasPagination={true}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        onSortingChanged={onSortingChanged}
        initialSortBy={initialTableSorting}
        testId="QuickQuestionsView#Table"
      />
    </>
  );
};

export { QuickQuestionsView };
