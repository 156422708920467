import { EventQuery, EventsByQuery } from '..';

export type TEventDetail = Extract<EventQuery['event'], { __typename?: 'Event' }>;

export type TEventsList = EventsByQuery['eventsBy']['events'];

export type TEventsListItem = EventsByQuery['eventsBy']['events'][number];

export const isEvent = (event?: EventQuery['event']): event is TEventDetail =>
  event?.__typename === 'Event';
