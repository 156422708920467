import { FC, ReactElement } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../../..';

const Wrapper = styled.button`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.spaces.spacing20} ${({ theme }) => theme.spaces.spacing16};
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  background-color: ${({ theme }) => theme.colors.coolGray10};
  font-family: ${({ theme }) => theme.fonts.primary};

  outline: 0;
  border: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.coolGray20};
  }
  &:last-child {
    margin: 0;
  }
`;

const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spaces.spacing16};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  color: ${({ theme }) => theme.colors.coolGray100};
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.body};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.coolGray70};
  margin-top: ${({ theme }) => theme.spaces.spacing4};
`;

export interface DashboardButtonProps extends TBaseProps {
  icon?: ReactElement;
  title: string;
  description?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const DashboardButton: FC<DashboardButtonProps> = ({
  icon,
  title,
  description,
  onClick,
  testId,
  disabled = false,
}) => {
  return (
    <>
      {!disabled && (
        <Wrapper data-testid={testId} onClick={onClick}>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <TextWrapper>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </TextWrapper>
        </Wrapper>
      )}
    </>
  );
};

export { DashboardButton };
