import { Controller, FieldValues } from 'react-hook-form';
import styled from 'styled-components';

import { DatePicker, DatePickerProps, FormError, FormItemLabel, Header, Wrapper } from '../../..';
import { TFormControlElement } from '../../../types/TForm';

const StyledLabel = styled(FormItemLabel)`
  flex: 1;
  font-weight: ${({ theme }) => theme.fontWeights.light};
`;

interface FormDatePickerProps<T> extends DatePickerProps, TFormControlElement<T> {
  label?: string;
  noMargin?: boolean;
  noError?: boolean;
}

const FormDatePicker = <T extends FieldValues>({
  control,
  initialValue,
  name,
  onDateChanged,
  className,
  label,
  noMargin,
  noError = false,
  testId,
  ...rest
}: FormDatePickerProps<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange: onChangeField }, fieldState: { error } }) => {
      const onChangeMerged = (value: Date | null) => {
        onChangeField(value);
        if (onDateChanged) onDateChanged(value);
      };
      return (
        <Wrapper className={className} noMargin={noMargin}>
          <Header>
            <StyledLabel>{label}</StyledLabel>
          </Header>
          <DatePicker
            {...rest}
            initialValue={initialValue}
            onDateChanged={onChangeMerged}
            testId={testId}
          />
          {!noError && !!error && <FormError error={error} testId={`${testId}-error`} />}
        </Wrapper>
      );
    }}
  />
);

export { FormDatePicker };
