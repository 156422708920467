import { FC } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../../..';

const TextWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: ${({ theme }) => theme.spaces.spacing8};
  vertical-align: center;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.caption1};
  line-height: ${({ theme }) => theme.lineHeights.caption1};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  color: ${({ theme }) => theme.colors.coolGray100};
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.body};
  max-height: ${({ theme }) => theme.lineHeights.caption1};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.coolGray70};
`;

export interface DashboardItemTitleProps extends TBaseProps {
  title: string;
  description: string;
}

const DashboardItemTitle: FC<DashboardItemTitleProps> = ({ title, description, testId }) => (
  <TextWrapper>
    <Title data-testid={`${testId}#${title}-Title`}>{title}</Title>
    <Description title={description} data-testid={`${testId}#${title}-Description`}>
      {description}
    </Description>
  </TextWrapper>
);

export { DashboardItemTitle };
