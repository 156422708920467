import styled from 'styled-components';
import { TFunction } from 'react-i18next';
import { CellProps } from 'react-table';
import { ApprovalState, Image, PetState, TPetsListItem } from '@sim-admin-frontends/data-access';
import { formatTableDateTime } from '@sim-admin-frontends/utils-shared';
import { Checkbox, TableImageCell, TExtendedColumn } from '@sim-admin-frontends/ui-shared';

import { PetDirectoryTableMenu } from '../components/petDirectory/view/PetDirectoryTableMenu';

const ApprovalStatePending = styled.span`
  color: ${({ theme }) => theme.colors.red400};
`;

const ApprovalStatePublished = styled.span`
  color: ${({ theme }) => theme.colors.green400};
`;

const getColumnFromType = (
  type: string,
  t: TFunction,
  refetch: () => void,
  timeZone?: string,
): TExtendedColumn<TPetsListItem> | null => {
  switch (type) {
    case 'image':
      return {
        Header: `${t('petDirectory.table.image')}`,
        testId: 'PetDirecotryTable#Image',
        accessor: 'imageObjects',
        Cell: ({ value }: { value: Image[] }) => {
          const src = value?.[0]?.links?.self;
          const thumbnail = value?.[0]?.links?.thumbnail || '';
          if (!src) {
            return null;
          }
          return <TableImageCell image={src} thumbnail={thumbnail} alt="petImage" />;
        },
        disableSortBy: true,
        skeleton: {
          width: 50,
        },
        width: '50px',
      };
    case 'name':
      return {
        Header: `${t('petDirectory.table.name')}`,
        testId: 'PetDirecotryTable#Name',
        accessor: 'name',
        disableSortBy: true,
        skeleton: {
          width: 100,
        },
      };
    case 'reward':
      return {
        Header: `${t('petDirectory.table.reward')}`,
        testId: 'PetDirecotryTable#Reward',
        disableSortBy: true,
        accessor: 'reward',
        Cell: ({ value }: { value: string }) => {
          if (!value) {
            return '-';
          }
          return `$ ${value}`;
        },
        skeleton: {
          width: 100,
        },
      };
    case 'phoneNumber':
      return {
        Header: `${t('petDirectory.table.contact')}`,
        testId: 'PetDirecotryTable#Contact',
        disableSortBy: true,
        accessor: 'phoneNumber',
        skeleton: {
          width: 100,
        },
      };
    case 'email':
      return {
        Header: `${t('petDirectory.table.email')}`,
        testId: 'PetDirecotryTable#Email',
        disableSortBy: true,
        accessor: 'email',
        skeleton: {
          width: 100,
        },
      };
    case 'approval':
      return {
        Header: `${t('petDirectory.table.approval')}`,
        testId: 'PetDirecotryTable#Approval',
        disableSortBy: true,
        accessor: 'approvalState',
        Cell: ({ value }: { value: ApprovalState }) => {
          switch (value) {
            case ApprovalState.Published:
              return <ApprovalStatePublished>{t('common.published')}</ApprovalStatePublished>;
            case ApprovalState.Unpublished:
              return <span>{t('common.unpublished')}</span>;
            default:
              return <ApprovalStatePending>{t('common.pending')}</ApprovalStatePending>;
          }
        },
        skeleton: {
          width: 100,
        },
      };
    case 'foundAt':
      return {
        Header: `${t('petDirectory.table.when')}`,
        testId: 'PetDirecotryTable#When',
        accessor: 'foundAt',
        Cell: ({ value }: { value: string }) => {
          if (!value) {
            return '-';
          }
          return formatTableDateTime(value, timeZone);
        },
        skeleton: {
          width: 100,
        },
      };
    case 'lostAt':
      return {
        Header: `${t('petDirectory.table.when')}`,
        testId: 'PetDirecotryTable#When',
        accessor: 'lostAt',
        Cell: ({ value }: { value: string }) => {
          if (!value) {
            return '-';
          }
          return formatTableDateTime(value, timeZone);
        },
        skeleton: {
          width: 100,
        },
      };
    case 'nature':
      return {
        Header: `${t('petDirectory.table.nature')}`,
        testId: 'PetDirecotryTable#Nature',
        disableSortBy: true,
        accessor: 'tags',
        Cell: ({ value }: { value: string[] }) => {
          if (!value) {
            return '-';
          }
          return value.join(',');
        },
        skeleton: {
          width: 100,
        },
      };
    case 'editApproval':
      return {
        Header: '',
        id: '0',
        testId: 'PetDirecotryTable#dots',
        disableSortBy: true,
        minWidth: 35,
        skeleton: {
          width: 50,
          align: 'right',
        },
        // eslint-disable-next-line react/display-name
        Cell: ({ row }: CellProps<TPetsListItem>) => {
          return (
            <PetDirectoryTableMenu
              petId={row.original.id}
              isPublished={row.original.approvalState === ApprovalState.Published}
              refetch={refetch}
              testId={`PetDirectoryView#TableMenu${row.index}-${row.original.id}`}
            />
          );
        },
      };
    default:
      return null;
  }
};

export const createTableColumns = (
  t: TFunction,
  refetch: () => void,
  state: PetState,
  timeZone?: string,
  postsToUnpublish?: string[],
  onCheckboxChange?: (id: string) => () => void,
) => {
  let columnTypes = [];
  switch (state) {
    case PetState.Lost:
      columnTypes = [
        'image',
        'name',
        'lostAt',
        'reward',
        'phoneNumber',
        'approval',
        'editApproval',
      ];
      break;
    case PetState.Found:
      columnTypes = ['image', 'nature', 'foundAt', 'phoneNumber', 'approval', 'editApproval'];
      break;
    default:
      columnTypes = ['image', 'name', 'reward', 'phoneNumber', 'email', 'approval', 'editApproval'];
      break;
  }

  const initialColumns: TExtendedColumn<TPetsListItem>[] = [];

  const columns: TExtendedColumn<TPetsListItem>[] = columnTypes.reduce((previous, type) => {
    const column = getColumnFromType(type, t, refetch, timeZone);

    if (!column) {
      return previous;
    }

    return [...previous, column];
  }, initialColumns);

  if (!onCheckboxChange || !postsToUnpublish) {
    return columns;
  }

  columns?.unshift({
    Header: ``,
    testId: `PetDirecotryTable#checkBox`,
    id: '2',
    disableSortBy: true,
    width: '2%',
    skeleton: {
      width: 50,
    },
    Cell: ({ row }: CellProps<TPetsListItem>) => {
      const isPublished =
        row.original.approvalState === ApprovalState.Published ||
        row.original.approvalState === ApprovalState.Pending;
      if (!isPublished) {
        return null;
      }
      const isChecked = postsToUnpublish.includes(row.original.id);
      return <Checkbox checked={isChecked} onChange={onCheckboxChange(row.original.id)} />;
    },
  });

  return columns;
};
