import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateEmergencyActions } from '../../../hooks/actionButtons/useGenerateEmergencyActions';

export interface EmergencyTableMenuProps extends TBaseProps {
  postId: string;
  refetch: () => void;
  pinned: boolean;
}

const EmergencyTableMenu: FC<EmergencyTableMenuProps> = ({ postId, refetch, pinned, testId }) => {
  const { listActions: items, renderModal } = useGenerateEmergencyActions({
    postId,
    pinned,
    refetch,
  });

  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export default EmergencyTableMenu;
