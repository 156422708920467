import { FC } from 'react';
import styled from 'styled-components';

import { HIGHLIGHTABLE_OVERLAY_Z_INDEX } from './Constants';

const Wrapper = styled.div`
  opacity: 0.5;
  background-color: #000;
  width: 100%;
  height: 100%;
  z-index: ${HIGHLIGHTABLE_OVERLAY_Z_INDEX};
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
`;

const HighlightableElementsOverlay: FC = ({ children }) => {
  return (
    <>
      <Wrapper />
      {children}
    </>
  );
};

export default HighlightableElementsOverlay;
