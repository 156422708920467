import { useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

const returnTabIndex = (pathName: string, tabs: string[]): number => {
  const foundIndex = tabs.findIndex((tab) => {
    return pathName.split('/').includes(tab);
  });

  return foundIndex !== -1 ? foundIndex : 0;
};

export const useTabNavigation = (
  basePath: string,
  tabs: string[],
  clearSelection?: () => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraParams?: Record<string, any>,
) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [tabIndex, setTabIndex] = useState(returnTabIndex(pathname, tabs));
  useEffect(() => {
    const unregister = history.listen((location) => {
      setTabIndex(returnTabIndex(location.pathname, tabs));
    });
    return () => {
      unregister();
    };
  }, [history]);

  useEffect(() => {
    if (clearSelection) {
      clearSelection();
    }
    return history.push(
      generatePath(basePath, {
        type: tabs[tabIndex],
        ...extraParams,
      }),
    );
  }, [tabIndex, history]);

  return { tabIndex, setTabIndex };
};
