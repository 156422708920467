import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { TMapCenter } from '@sim-admin-frontends/utils-shared';
import { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DEFAULT_MAP_ZOOM } from '../../constants/MapConstants';
import { TBaseProps } from '../../types/TBaseComponent';
import { MediumText } from '../texts/Texts';

export interface MapSelectProps extends TBaseProps {
  center: TMapCenter;
  mapContainerStyle?: CSSProperties;
  onReset?: () => void;
  zoom?: number;
  isResetButtonVisible?: boolean;
  onClickBottomText?: () => void;
  bottomText?: string;
  env: string;
}

export const MapButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  gap: ${({ theme }) => theme.spaces.spacing8};
`;

const defaultContainerStyle = {
  width: '100%',
  height: '400px',
};

const MediumTextWithCursor = styled(MediumText)`
  &:hover {
    cursor: pointer;
  }
`;

const MapAreaSelect: FC<MapSelectProps> = ({
  children,
  center,
  mapContainerStyle = defaultContainerStyle,
  onReset,
  onClickBottomText,
  isResetButtonVisible,
  bottomText,
  zoom,
  env,
}) => {
  const { t } = useTranslation();
  return (
    <LoadScript googleMapsApiKey={env}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={zoom ?? DEFAULT_MAP_ZOOM}
      >
        {children}
      </GoogleMap>
      <MapButtonsWrapper>
        {isResetButtonVisible && (
          <MediumTextWithCursor onClick={onReset}>{t('common.reset')}</MediumTextWithCursor>
        )}
        {bottomText ? <MediumText onClick={onClickBottomText}>{bottomText}</MediumText> : null}
      </MapButtonsWrapper>
    </LoadScript>
  );
};

export { MapAreaSelect };
