import { PropsWithChildren } from 'react';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import { TExtendedColumn } from '../../types/TTable';
import { TABLE_PAGE_SIZE } from './Table';

type ColumnType = Record<string, unknown>;

interface Props<T extends ColumnType> {
  columns: TExtendedColumn<T>[];
}

const SKELETON_WIDTH_VARIATION_PERCENT = 20;

const TableSkeleton = <T extends ColumnType>({ columns }: PropsWithChildren<Props<T>>) => {
  // if we have nested columns we need to render skeleton for each nested column
  const nestedColumns = columns.reduce<TExtendedColumn<T>[]>((acc, curr) => {
    if (curr.columns) {
      return [...acc, ...curr.columns];
    }
    return [...acc, curr];
  }, []);

  return (
    <>
      {[...Array(TABLE_PAGE_SIZE).keys()].map((i) => {
        return (
          <tr key={i}>
            {nestedColumns.map((c, j) => {
              const { skeleton } = c;
              return (
                <td
                  key={`${c.id}-${j}`}
                  style={{
                    textAlign: skeleton?.align ? skeleton.align : 'left',
                  }}
                >
                  <Skeleton
                    width={
                      skeleton?.width
                        ? skeleton.width
                        : `${
                            100 - Math.floor(Math.random() * SKELETON_WIDTH_VARIATION_PERCENT) + 0
                          }%`
                    }
                  />
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

export default TableSkeleton;
