import { ImageInput } from '@sim-admin-frontends/data-access';
import { TSelectItem, TSelectItems } from '@sim-admin-frontends/ui-shared';

export type TRaffleFormValues = {
  title: string;
  titleWidth: number;
  subtitle: string;
  subtitleWidth: number;
  textColor: string;
  textPosition: TSelectItem;
  bgImage: (File | ImageInput)[] | null;
  datetimeFrom: Date;
  datetimeTo: Date;
  showIncChanceFlag: boolean;
  termsAndConditionsUrl: string;
  privacyPolicyUrl: string;
  invitationMessage: string;
  lang?: TSelectItem;
  publisher?: TSelectItem;
  places?: TSelectItems;
  notifications: { value: Date }[];
};

export enum RaffleModalActions {
  DELETE = 'DELETE',
  UNPUBLISH = 'UNPUBLISH',
  PUBLISH = 'PUBLISH',
}
