import { FC } from 'react';
import styled from 'styled-components';

import { SliderMovableValue, SliderValue } from '../../types/TSlider';

const StyledTrack = styled.div<{ index?: number }>`
  bottom: ${({ theme }) => theme.spaces.spacing4};
  height: ${({ theme }) => theme.spaces.spacing8};
  background: ${({ theme, index }) =>
    index === 1 ? theme.colors.coolGray30 : theme.colors.yellow50};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
`;

type SliderTrackProps = {
  value?: SliderMovableValue;
};

export const SliderTrack: FC<SliderTrackProps> = <T extends SliderValue>(
  { ...props },
  state: { index: number; value: T },
) => <StyledTrack {...props} index={state.index} />;

const StyledThumb = styled.div`
  height: ${({ theme }) => theme.spaces.spacing8};
  width: ${({ theme }) => theme.spaces.spacing8};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.coolGray30};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  border-width: ${({ theme }) => theme.spaces.spacing4};
  border-color: ${({ theme }) => theme.colors.yellow50};
  border-style: solid;
  cursor: grab;
`;

type SliderThumbProps = {
  value?: SliderMovableValue;
};

export const SliderThumb: FC<SliderThumbProps> = ({ ...props }) => <StyledThumb {...props} />;
