import { render, RenderOptions } from '@testing-library/react';
import { FC } from 'react';
import { MemoryRouter } from 'react-router-dom';

import { ThemeProvider } from '../lib/themeProvider/ThemeProvider';

class ResizeObserver {
  observe() {
    // do nothing
  }
  unobserve() {
    // do nothing
  }
  disconnect() {
    // do nothing
  }
}

const AllTheProviders: FC = ({ children }) => {
  window.ResizeObserver = ResizeObserver;

  return (
    <ThemeProvider>
      <MemoryRouter>{children}</MemoryRouter>
    </ThemeProvider>
  );
};

const customRender = (ui: React.ReactElement, options: RenderOptions = {}) =>
  render(ui, { wrapper: AllTheProviders, ...options });

// re-export everything
export * from '@testing-library/react';

// override render method
export { customRender as render };
