export enum TToastMessageType {
  ANNOUNCEMENT = 'updates',
  EMERGENCY = 'emergency',
  EVENT = 'events',
  POLL = 'polls',
  COUPON = 'coupon',
  RAFFLE = 'raffles',
  QUICK_QUESTION = 'quickQuestion',
  DONATION = 'donation',
  MARKET = 'market',
}

export enum TToastMessageActionType {
  create = 'create',
  edit = 'edit',
  schedule = 'schedule',
}
