import { TSelectItem } from '@sim-admin-frontends/ui-shared';

export type TQuickQuestionsFormValues = {
  question: string;
  scheduledAt?: Date | null;
  notifications: { value: Date }[];
  notifyNow?: boolean;
  publishedAt?: Date | null;
  privateResults?: boolean;
  quickQuestionEndDate: Date;
  questionOptions: string[];
  publisher?: TSelectItem;
  lang?: TSelectItem;
};

export type TQuickQuestionOption = { answer: string; count: number };
export type TQuickQuestionOptions = TQuickQuestionOption[];

export enum QuickQuestionModalActions {
  DELETE = 'DELETE',
  UNPUBLISH = 'UNPUBLISH',
  PUBLISH = 'PUBLISH',
  CANCEL_SEND = 'CANCEL_SEND',
  NOTIFY = 'NOTIFY',
}
