import { TAreaOfInterest, TQueryGeoshapeCircle } from '@sim-admin-frontends/data-access';
import { DEFAULT_RADIUS_MILES_STRING, METERS_IN_MILE } from '@sim-admin-frontends/ui-shared';
import {
  TGeoShape,
  TGeoShapeCircle,
  TGeoShapePolygon,
  TMapCenter,
  TSelectedMapAreaShape,
} from '@sim-admin-frontends/utils-shared';

import { TCircleCoordinates, TPolygonCoordinates } from '../types/TMap';
import { TOSMType } from '../types/TOpenStreetMap';

export const isCircleType = (obj: TAreaOfInterest): obj is TQueryGeoshapeCircle =>
  !!(obj as TQueryGeoshapeCircle).radius;

export const isCircleGeoShape = (obj: TGeoShape | null): obj is TGeoShapeCircle =>
  !!(obj as TGeoShapeCircle)?.radius;

export const getMeters = (milesString: string) => {
  const miles = Number(milesString.split('miles')[0].trim());
  return miles * METERS_IN_MILE;
};

export const generateCirclePolygonPaths = (
  coords: TMapCenter,
  radius: number,
): TPolygonCoordinates => {
  const { lng, lat } = coords;
  const numPoints = 6;
  const angleStep = (2 * Math.PI) / numPoints;
  const points = [];
  for (let i = 0; i < numPoints; i++) {
    const x = lng + (radius / 100000) * Math.cos(i * angleStep);
    const y = lat + (radius / 100000) * Math.sin(i * angleStep);
    points.push([x, y]);
  }
  return [points];
};

export const generatePolygonPaths = (coordinates: TPolygonCoordinates) => {
  return coordinates[0].map((item) => ({ lng: item[0], lat: item[1] }));
};

export const generatePolygon = (coordinates: TPolygonCoordinates) => {
  const newShape: TGeoShapePolygon = {
    type: TSelectedMapAreaShape.POLYGON,
    coordinates,
    radius: null,
  };
  return newShape;
};

export const generateCircle = (coordinates: TCircleCoordinates, radius?: string) => {
  const newShape: TGeoShapeCircle = {
    type: TSelectedMapAreaShape.CIRCLE,
    coordinates,
    radius: radius || DEFAULT_RADIUS_MILES_STRING,
  };
  return newShape;
};

export const getPolygonCenter = (vertices: number[][] | number) => {
  if (!Array.isArray(vertices)) {
    return null;
  }
  const ctr = { lat: 0, lng: 0 };

  for (const vertex of vertices) {
    ctr.lng += vertex[0];
    ctr.lat += vertex[1];
  }

  ctr.lng /= vertices.length;
  ctr.lat /= vertices.length;

  return ctr;
};

export const getOsmIdPrefix = (type: TOSMType | undefined) => {
  switch (type) {
    case TOSMType.NODE:
      return 'N';
    case TOSMType.RELATION:
      return 'R';
    case TOSMType.WAY:
    default:
      return 'W';
  }
};

export const converToLatLng = (coordinates: number[]) => {
  return {
    lat: coordinates[1],
    lng: coordinates[0],
  };
};

export const convertToPolygon = (coordinates: number[][]) => {
  const [firstPoint] = coordinates;
  return coordinates.concat([firstPoint]).map(converToLatLng);
};
