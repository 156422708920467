import { useCallback, useState } from 'react';
import { Button, loadingToast, TToastType, updateToast } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { UseUnpublishMutation } from '../types/TUnpublish';
import { logPublishBulk } from '../utils/analytics/analyticsUtils';
import { useAuthInfo } from '../contexts/userContext';
import { TItemAction, TPublishBulkAction } from '../types/TAnalytics';

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spaces.spacing8};
`;

enum ModalActions {
  UNPUBLISH = 'UNPUBLISH',
}

export const useBulkUnpublish = (
  useUnpublishMutation: UseUnpublishMutation,
  analyticsAction: TPublishBulkAction,
) => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const [postsToUnpublish, setPostsToUnpublish] = useState<string[]>([]);
  const [isUnpublishMutationSettled, setIsUnpublishMutationSettled] = useState(false);

  const clearSelection = () => {
    setPostsToUnpublish([]);
  };

  const { mutateAsync: unpublishMutation } = useUnpublishMutation({
    onMutate: () => {
      loadingToast(t('common.toast.unpublish.loading'), {
        toastId: ModalActions.UNPUBLISH,
      });
    },
    onError: () => {
      updateToast(ModalActions.UNPUBLISH, t('common.toast.unpublish.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(ModalActions.UNPUBLISH, t('common.toast.unpublish.success'), TToastType.SUCCESS);
      logPublishBulk(analyticsAction, institutionUuid, postsToUnpublish, TItemAction.UNPUBLISH);
    },
    onSettled: () => {
      clearSelection();
      setIsUnpublishMutationSettled(true);
    },
  });

  const addPost = (id: string) => {
    setPostsToUnpublish((prevPosts) => [...prevPosts, id]);
  };

  const removePost = (id: string) => {
    setPostsToUnpublish((prevPosts) => prevPosts.filter((postId) => postId !== id));
  };

  const onUnpublishSelect = (id: string) => () => {
    if (!postsToUnpublish.includes(id)) {
      addPost(id);
      return;
    }

    removePost(id);
  };

  const onUnpublishBulkClick = useCallback(
    async (redirectFunction?: () => void) => {
      setIsUnpublishMutationSettled(false);

      await unpublishMutation({
        id: postsToUnpublish[0],
        batch: postsToUnpublish,
      });

      if (redirectFunction) {
        redirectFunction();
      }
    },
    [postsToUnpublish, unpublishMutation],
  );

  const renderBulkUnpublishButton = useCallback(
    (redirectFunction?: () => void) => {
      if (postsToUnpublish.length < 1) {
        return null;
      }

      const onUnpublishBulkClickWithRedirect = () => onUnpublishBulkClick(redirectFunction);

      return (
        <StyledButton onClick={onUnpublishBulkClickWithRedirect} testId="Tabs#UnpublishBulk">
          {t('common.unpublishSelected')}
        </StyledButton>
      );
    },
    [onUnpublishBulkClick, postsToUnpublish.length, t],
  );

  return {
    postsToUnpublish,
    onUnpublishSelect,
    renderBulkUnpublishButton,
    isUnpublishMutationSettled,
    clearSelection,
  };
};
