import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal, TBaseProps } from '@sim-admin-frontends/ui-shared';

export interface AnnouncementDiscardModalProps extends TBaseProps {
  isOpen: boolean;
  onBackClick: () => void;
  onConfirmClick: () => void;
}

const FormDiscardModal: FC<AnnouncementDiscardModalProps> = ({
  isOpen,
  onBackClick,
  onConfirmClick,
}) => {
  const { t } = useTranslation();
  return (
    <ConfirmModal
      isOpen={isOpen}
      onBackClick={onBackClick}
      onConfirmClick={onConfirmClick}
      title={t('modal.confirmDiscard.title')}
      content={t('modal.confirmDiscard.content')}
      backButtonText={t('modal.confirmDiscard.backButton')}
      confirmButtonText={t('modal.confirmDiscard.confirmButton')}
    />
  );
};

export { FormDiscardModal };
