import styled, { css } from 'styled-components';

import { stripPx } from '../../utils/styleUtils';

const SelectStyles = styled.div<{ invalid?: boolean; valueClickable?: boolean }>`
  width: 100%;
  position: relative;
  & .react-select__control {
    min-height: ${({ theme }) => theme.spaces.spacing48};
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.coolGray20};
    color: ${({ theme }) => theme.colors.coolGray100};
    border-radius: ${({ theme }) => theme.borderRadius.radius4};
    padding: ${({ theme }) => theme.spaces.spacing8};
    padding-left: ${({ theme }) => theme.spaces.spacing16};
    box-shadow: none;
    box-sizing: border-box;
    & :hover {
      border: 1px solid ${({ theme }) => theme.colors.coolGray20};
      ${({ invalid, theme }) =>
        invalid &&
        `
          border: 2px solid ${theme.colors.red60};
        `}
    }

    ${({ invalid, theme }) =>
      invalid &&
      `
        /* -1px for border */
        padding: ${stripPx(theme.spaces.spacing8) - 1}px;
        padding-left: 15px;
        border: 2px solid ${theme.colors.red60};
      `}
  }

  & .react-select__indicator,
  .react-select__value-container {
    padding: 0;
  }

  & .react-select__control--menu-is-open {
    border: none;
    box-shadow: none;
    border: 2px solid ${({ theme }) => theme.colors.blue60} !important;
    min-height: ${({ theme }) => theme.spaces.spacing48};
    /* -1px for border */
    padding: ${({ theme }) => stripPx(theme.spaces.spacing8) - 1}px;
    padding-left: 15px;
    outline: solid !important;
    outline-color: ${({ theme }) => theme.colors.blue60} !important;
    outline-width: 1px !important;
    & :hover {
      box-shadow: none;
      border: 2px solid ${({ theme }) => theme.colors.blue60};
    }
  }

  & .react-select__indicator-separator {
    display: none;
  }

  & .react-select__menu {
    padding: ${({ theme }) => theme.spaces.spacing8};
    border-radius: ${({ theme }) => theme.borderRadius.radius8};
    border: none;
    box-shadow: 10px 10px 44px rgba(0, 0, 0, 0.04), 2px 2px 15px rgba(0, 0, 0, 0.08);
    z-index: ${({ theme }) => theme.zIndex.selectPopup};
  }

  & .react-select__placeholder,
  .react-select__single-value,
  .react-select__menu-notice--no-options {
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: ${({ theme }) => theme.lineHeights.small};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-family: Manrope, serif;
    color: ${({ theme }) => theme.colors.coolGray100};
  }

  & .react-select__option {
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: ${({ theme }) => theme.lineHeights.small};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-family: Manrope, serif;
    color: ${({ theme }) => theme.colors.coolGray100};
    border-radius: ${({ theme }) => theme.borderRadius.radius4};
    padding: 10px ${({ theme }) => theme.spaces.spacing8};
    :hover {
      background-color: ${({ theme }) => theme.colors.coolGray10};
    }
  }
  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background-color: transparent;
  }
  & .react-select__multi-value {
    ${({ theme, valueClickable }) =>
      valueClickable &&
      css`
        :hover {
          cursor: pointer;
          background-color: ${theme.colors.coolGray10};
        }
      `}
  }
`;

export { SelectStyles };
