import { CategoryType, TPostCategory } from '@sim-admin-frontends/data-access';

export const getCategoryType = (categories: TPostCategory[]): CategoryType => {
  const categoryTypes = categories[categories.length - 1]?.categoryTypes ?? [];
  if (categoryTypes?.includes(CategoryType.Event)) {
    return CategoryType.Event;
  }
  if (categoryTypes?.includes(CategoryType.Poll)) {
    return CategoryType.Poll;
  }
  if (categoryTypes?.includes(CategoryType.Raffle)) {
    return CategoryType.Raffle;
  }
  return CategoryType.Announcement;
};
