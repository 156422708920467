import { PageHeader, TableActionHeader, useSearchFilter } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AnnouncementTabs from '../../components/announcements/view/AnnouncementsTabs';
import { PageWrapper } from '../../components/layout/PageLayout';

const AnnouncementsListPage: FC = () => {
  const { t } = useTranslation();
  const { filter, renderFilterComponent } = useSearchFilter({
    testId: 'AnnouncementsListPage#Search',
  });

  return (
    <PageWrapper>
      <PageHeader
        title={t('updates.pageTitle')}
        caption={t('updates.pageCaption')}
        testId="AnnouncementsTabs#PageHeader"
      />
      <TableActionHeader prependItem={renderFilterComponent()} />
      <AnnouncementTabs filter={filter} />
    </PageWrapper>
  );
};

export default AnnouncementsListPage;
