import {
  MessagingProblemsFilterInput,
  MessagingProblemsByQuery,
  PROBLEMS_PAGE_SIZE,
  useInfiniteGraphQLQuery,
  useMessagingProblemsByQuery,
  MessagingProblemsOrderByInput,
  MessagingCategoriesByFilterInput,
  useMessagingCategoriesByQuery,
  MessagingCategoriesByQuery,
} from '..';

export const useInfiniteReportProblemQuery = (
  filter: MessagingProblemsFilterInput,
  orderBy?: MessagingProblemsOrderByInput,
) => {
  const getNextPageParam = (
    lastPage: MessagingProblemsByQuery,
    allPages: MessagingProblemsByQuery[],
  ) =>
    lastPage.messagingProblemsBy.messagingProblems.length === PROBLEMS_PAGE_SIZE
      ? allPages.length * PROBLEMS_PAGE_SIZE
      : undefined;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    filter,
    orderBy,
    pageSize: PROBLEMS_PAGE_SIZE,
    pageOffset: Number(pageParam ?? 0),
  });

  return useInfiniteGraphQLQuery(useMessagingProblemsByQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
  });
};

export const useInfiniteReportProblemCategoriesQuery = (
  filter: MessagingCategoriesByFilterInput,
) => {
  const getNextPageParam = (lastPage: MessagingCategoriesByQuery) =>
    lastPage.messagingCategoriesBy?.nextToken;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    filter,
    nextToken: pageParam,
  });

  return useInfiniteGraphQLQuery(useMessagingCategoriesByQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
  });
};
