import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageHeader, TableActionHeader, useSearchFilter } from '@sim-admin-frontends/ui-shared';
import React from 'react';

import { PageWrapper } from '../../components/layout/PageLayout';
import EventsTabs from '../../components/events/view/EventsTabs';

const EventListPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { filter, renderFilterComponent } = useSearchFilter({
    testId: 'EventListPage#Search',
  });
  return (
    <PageWrapper>
      <PageHeader
        title={t('events.pageTitle')}
        caption={t('events.pageCaption')}
        testId="EventsTabs#PageHeader"
      />
      <TableActionHeader prependItem={renderFilterComponent()} />
      <EventsTabs filter={filter} />
    </PageWrapper>
  );
};

export default EventListPage;
