import { CategoryType, ModeratorPostByQuery } from '..';

export type TModeratorFullPost = ModeratorPostByQuery['moderationPostsBy']['posts'][number];

export type TModeratorListItem = Extract<
  ModeratorPostByQuery['moderationPostsBy']['posts'][number],
  { __typename?: 'Announcement' | 'Event' }
>;

export type TQuickQuestionModerationPost = Extract<
  ModeratorPostByQuery['moderationPostsBy']['posts'][number],
  { __typename?: 'QuickQuestion' }
>;

export type TPostCategory = { __typename?: 'Category'; categoryTypes: Array<CategoryType> };

export const isQuickQuestion = (post: TModeratorFullPost): post is TQuickQuestionModerationPost =>
  !!(post as TQuickQuestionModerationPost).question;
