import { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import styled from 'styled-components';

const ImageWrapper = styled.div<{ isLarge?: boolean }>`
  width: ${({ theme, isLarge }) => (isLarge ? theme.spaces.spacing96 : theme.spaces.spacing32)};
  height: ${({ theme, isLarge }) => (isLarge ? theme.spaces.spacing96 : theme.spaces.spacing32)};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  overflow: hidden;
  position: relative;
  cursor: pointer;
  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

type TableImageCellProps = {
  image: string;
  thumbnail: string;
  alt: string;
  isLarge?: boolean;
};

const TableImageCell = ({ image, thumbnail, alt, isLarge = false }: TableImageCellProps) => {
  const [toggler, setToggler] = useState(false);
  return (
    <>
      <ImageWrapper onClick={() => setToggler(!toggler)} isLarge={isLarge}>
        <img src={thumbnail} alt={alt} />
      </ImageWrapper>
      <FsLightbox
        toggler={toggler}
        sources={[image]}
        types={['image']}
        disableLocalStorage
        loadOnlyCurrentSource
      />
    </>
  );
};

export { TableImageCell };
