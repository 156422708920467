import { FC } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../../types/TBaseComponent';

const TRANSITION_DURATION = 0.15;

const Wrapper = styled.div<{ selected: boolean; $disabled: boolean }>`
  width: 32px;
  background-color: ${({ theme, selected, $disabled }) =>
    $disabled
      ? theme.colors.coolGray20
      : selected
      ? theme.colors.green60
      : theme.colors.coolGray50};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  padding: ${({ theme }) => theme.spaces.spacing4};
  transition: background-color ${TRANSITION_DURATION}s ease-in-out;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

const Dot = styled.div<{ selected: boolean }>`
  width: ${({ theme }) => theme.spaces.spacing16};
  height: ${({ theme }) => theme.spaces.spacing16};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  transform: translateX(${({ selected, theme }) => (selected ? theme.spaces.spacing16 : 0)});
  transition: transform ${TRANSITION_DURATION}s ease-in-out;
`;

export interface SwitchProps extends TBaseProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const Switcher: FC<SwitchProps> = ({ value, onChange, testId, disabled }) => {
  const onClick = () => {
    if (!disabled) {
      onChange(!value);
    }
  };
  return (
    <Wrapper onClick={onClick} selected={value} data-testid={testId} $disabled={!!disabled}>
      <Dot selected={value} />
    </Wrapper>
  );
};

export { Switcher };
