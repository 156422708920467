import {
  useDeleteCouponMutation,
  usePublishCouponMutation,
  useUnpublishCouponMutation,
} from '@sim-admin-frontends/data-access';
import {
  ConfirmModal,
  EditIcon,
  loadingToast,
  TableIconProps,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { useAuthInfo } from '../../contexts/userContext';
import ROUTES from '../../routing/routes';
import { TItemAction } from '../../types/TAnalytics';
import { TAnnouncementsTabTypes } from '../../types/TAnnouncements';
import { CouponModalActions } from '../../types/TCoupons';
import { logAnnouncementEvent } from '../../utils/analytics/analyticsUtils';
import { changeCouponsTab } from '../../utils/couponsUtils';

type Props = {
  couponId: string;
  refetch: () => void;
  isEditPage?: boolean;
};

export const useGenerateCouponActions = ({ couponId, refetch, isEditPage }: Props) => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(CouponModalActions.UNPUBLISH);

  const editPath = generatePath(ROUTES.couponsEdit.path, couponId ? { id: couponId } : undefined);

  const { mutate: deleteMutation } = useDeleteCouponMutation({
    onMutate: () => {
      loadingToast(t('updates.toast.delete.loading'), {
        toastId: CouponModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(CouponModalActions.DELETE, t('updates.toast.delete.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      logAnnouncementEvent(couponId, institutionUuid, TItemAction.DELETE, false);
      updateToast(CouponModalActions.DELETE, t('updates.toast.delete.success'), TToastType.SUCCESS);
      refetch();
    },
  });

  const { mutate: unpublishMutation } = useUnpublishCouponMutation({
    onMutate: () => {
      loadingToast(t('updates.toast.unpublish.loading'), {
        toastId: CouponModalActions.UNPUBLISH,
      });
    },
    onError: () => {
      updateToast(
        CouponModalActions.UNPUBLISH,
        t('updates.toast.unpublish.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      updateToast(
        CouponModalActions.UNPUBLISH,
        t('updates.toast.unpublish.success'),
        TToastType.SUCCESS,
      );
      logAnnouncementEvent(couponId, institutionUuid, TItemAction.UNPUBLISH, false);
      changeCouponsTab(history, TAnnouncementsTabTypes.UNPUBLISHED);
      refetch();
    },
  });

  const { mutate: publishMutation } = usePublishCouponMutation({
    onMutate: () => {
      loadingToast(t('updates.toast.publish.loading'), {
        toastId: CouponModalActions.PUBLISH,
      });
    },
    onError: () => {
      updateToast(CouponModalActions.PUBLISH, t('updates.toast.publish.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      logAnnouncementEvent(couponId, institutionUuid, TItemAction.PUBLISH);
      updateToast(
        CouponModalActions.PUBLISH,
        t('updates.toast.publish.success'),
        TToastType.SUCCESS,
      );
      changeCouponsTab(history, TAnnouncementsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const deleteCoupon = async () => {
    await deleteMutation({
      id: couponId,
    });
  };

  const unpublishCoupon = async () => {
    await unpublishMutation({
      id: couponId,
    });
  };

  const publishCoupon = async () => {
    await publishMutation({
      id: couponId,
    });
  };

  const handleDeleteClick = () => {
    setModalDescription(t('coupons.modal.deleteContent'));
    setModalClickAction(CouponModalActions.DELETE);
    setIsModalVisible(true);
  };

  const handleConfirmClick = () => {
    if (modalClickAction === CouponModalActions.DELETE) {
      deleteCoupon();
      setIsModalVisible(false);
    }
    if (modalClickAction === CouponModalActions.UNPUBLISH) {
      unpublishCoupon();
      setIsModalVisible(false);
    }
    if (modalClickAction === CouponModalActions.PUBLISH) {
      publishCoupon();
      setIsModalVisible(false);
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const generateItems = (): TableIconProps[] => [
    {
      label: t('table.dropdown.edit'),
      path: editPath,
      testId: 'CouponsTableMenu#edit',
      icon: <EditIcon />,
      hidden: isEditPage,
    },
    {
      label: t('table.dropdown.delete'),
      action: handleDeleteClick,
      testId: 'CouponsTableMenu#delete',
      icon: <TrashCanIcon />,
    },
  ];

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  const renderModal = () => (
    <ConfirmModal
      title={t('coupons.modal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('coupons.modal.backButton')}
      confirmButtonText={t('coupons.modal.confirmButton')}
      testId="Coupons#ConfirmModal"
    />
  );

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
