/* eslint-disable @typescript-eslint/ban-ts-comment */
import { QueryClient, UseMutateAsyncFunction } from 'react-query';
import { generatePath } from 'react-router-dom';
import { History, LocationState } from 'history';
import { TFunction } from 'react-i18next';
import { formatTableDate, prepareFileObjects } from '@sim-admin-frontends/utils-shared';
import {
  AddAnnouncementDraftMutation,
  AddAnnouncementDraftMutationVariables,
  AnnouncementDraftInput,
  AnnouncementInput,
  CategoriesQuery,
  CategoryType,
  State,
  TAnnouncementDraftListItem,
  TAnnouncementListItem,
  TCategory,
  TUploadedFile,
} from '@sim-admin-frontends/data-access';
import { CellProps } from 'react-table';
import {
  ACTION_ITEMS_MIN_WIDTH_SMALL,
  Button,
  ExternalLinkIcon,
  TableHeaderTitle,
  TExtendedColumn,
} from '@sim-admin-frontends/ui-shared';

import { TItemAction } from '../types/TAnalytics';
import ROUTES from '../routing/routes';
import { logAnnouncementEvent } from './analytics/analyticsUtils';
import { invalidateAnnouncementDraftsQuery } from './queryUtils';
import { TPollFormValues, TPollsColumns, TPollTableItem } from '../types/TPolls';
import { TAnnouncementsTabTypes } from '../types/TAnnouncements';
import { PollsTableMenu } from '../components/polls/view/PollsTableMenu';
import { getDisabledColumns, getPublishingDateAccessor } from './announcementsUtils';
import { getUnpublishCheckbox } from './publishUtils';

export const changePollsTab = (history: History<LocationState>, type: TAnnouncementsTabTypes) => {
  history.push(
    generatePath(ROUTES.pollsOverview.path, {
      type,
    }),
  );
};

export const transformFormValues = (
  formData: TPollFormValues,
  uploadedImages: TUploadedFile[] | undefined,
  uploadedVideos: TUploadedFile[] | undefined,
  institutionUuid: string,
  category: TCategory | undefined,
  defaultPostLang = '',
): AnnouncementInput => {
  const imageObjects = prepareFileObjects(uploadedImages);
  const videoObjects = prepareFileObjects(uploadedVideos);

  return {
    title: formData.title,
    content: formData.content,
    placeUuids: formData.places?.map((place) => place.value),
    pushContent: ' ',
    institutionUuid,
    categoryUuid: category?.id,
    lang: formData.lang?.value || defaultPostLang,
    imageObjects,
    videoObjects,
    scheduledAt: formData.scheduledAt?.toISOString(),
    notifyNow: formData.notifyNow,
    notifications:
      formData.notifyNow && formData.notifications.length > 0
        ? formData.notifications.map((notification) => notification.value.toISOString())
        : null,
  };
};

export const submitDraft = async (
  input: AnnouncementDraftInput,
  queryClient: QueryClient,
  placeId: string,
  institutionUuid: string,
  addDraft: UseMutateAsyncFunction<
    AddAnnouncementDraftMutation,
    unknown,
    AddAnnouncementDraftMutationVariables
  >,
) => {
  const { addAnnouncementDraft: result } = await addDraft({
    announcementDraft: input,
  });
  invalidateAnnouncementDraftsQuery(queryClient);
  logAnnouncementEvent(result.id, institutionUuid, TItemAction.CREATE_DRAFT);
};

export const findPollCategory = (categories?: CategoriesQuery): TCategory | undefined =>
  categories?.categoriesBy.categories.find((category) =>
    category.categoryTypes.includes(CategoryType.Poll),
  );

export const createTableColumns = (
  t: TFunction,
  refetch: () => void,
  type: State,
  postsToUnpublish?: string[],
  onCheckboxChange?: (id: string) => () => void,
) => {
  const disabledColumns = getDisabledColumns(type);

  const columns: TPollsColumns = [
    {
      Header: `${t('polls.table.title')}`,
      accessor: (item) => <TableHeaderTitle item={item.title} />,
      testId: 'AnnouncementsTable#title',
      disabled: disabledColumns.includes('title'),
      disableSortBy: true,
    },
    {
      Header: `${t('polls.table.published')}`,
      // @ts-ignore - No no typescript i know better - hopefully :)
      accessor: getPublishingDateAccessor(type),
      testId: 'PollsTable#publicationDate',
      Cell: disabledColumns.includes('publishedAt')
        ? ''
        : ({ value }: any) => formatTableDate(value),
      disabled: disabledColumns.includes('publishedAt'),
      disableSortBy: disabledColumns.includes('publishedAt'),
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('polls.table.googleForm')}`,
      accessor: 'content',
      testId: 'PollsTable#results',
      disabled: disabledColumns.includes('content'),
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
      Cell: ({ row }: CellProps<TPollTableItem>) => (
        <a href={row.original.content ?? ''} target="_blank" rel="noreferrer">
          <Button
            testId={`PollsView#ViewForm${row.index}-${row.original.id}`}
            variant="tertiary"
            appendIcon={<ExternalLinkIcon />}
            noPadding
          >
            {t('polls.table.viewForm')}
          </Button>
        </a>
      ),
    },
    {
      Header: '',
      id: '0',
      testId: 'PollsTable#dots',
      disableSortBy: true,
      minWidth: ACTION_ITEMS_MIN_WIDTH_SMALL,
      skeleton: {
        width: 50,
        align: 'right',
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ row }: CellProps<TAnnouncementListItem | TAnnouncementDraftListItem>) => {
        const isPublished = row.original.publishmentState === State.Published;
        return (
          <PollsTableMenu
            type={type}
            postId={row.original.id}
            isFromAdmin={row.original.createdByAdmin}
            published={isPublished}
            refetch={refetch}
            testId={`PollsView#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  getUnpublishCheckbox<TPollTableItem, TExtendedColumn<TPollTableItem>[]>(
    columns,
    'PollsTable',
    postsToUnpublish,
    onCheckboxChange,
  );

  return columns;
};
