import React, { FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import { TEST_ID_PAGE_PREVIEW_PREFIX } from '../layout/pageContent/PageContentPreview';
import { getTestId } from '../../utils/testUtils';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spaces.spacing16};
`;

const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spaces.spacing12};
`;

export const RowText = styled.div<{ isFirst?: boolean }>`
  color: ${({ theme }) => theme.colors.coolGray90};
  padding-top: ${({ theme, isFirst }) => (isFirst ? 0 : theme.spaces.spacing4)};
  font-weight: ${({ theme, isFirst }) => (isFirst ? theme.fontWeights.bold : '')};
`;

type Props = {
  children: ReactNode;
  icon?: ReactElement;
  testIdPrefix?: string;
  testIdSuffix: string;
};

const PreviewItemIconText: FC<Props> = ({
  children,
  icon,
  testIdPrefix = TEST_ID_PAGE_PREVIEW_PREFIX,
  testIdSuffix,
}) => (
  <Row data-testid={getTestId(testIdPrefix, testIdSuffix)}>
    {icon && <IconWrapper>{icon}</IconWrapper>}
    <div>{children}</div>
  </Row>
);

export default PreviewItemIconText;
