import { RafflesQuery, RaffleQuery } from '..';

export type TRaffleDetail = Extract<RaffleQuery['announcement'], { __typename?: 'Announcement' }>;
export type TRaffleList = TRaffleListItem[];

export type TRaffleListItem = Extract<
  RafflesQuery['announcementsBy']['announcements'][number],
  { __typename?: 'Announcement' }
>;

export const isRaffle = (raffle?: RaffleQuery['announcement']): raffle is TRaffleDetail =>
  raffle?.__typename === 'Announcement';
