/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { forwardRef, useState, InputHTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';

import { CheckboxType } from '../..';
import { TBaseProps } from '../../types/TBaseComponent';
import { getDayCheckbox, getIcon } from './helper';

const CheckboxContainer = styled.div<{ $disabled?: boolean }>`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 1px;
`;

interface CheckboxProps extends TBaseProps, Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  variant?: CheckboxType;
  text?: string;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      variant = CheckboxType.REGULAR,
      text,
      testId,
      className,
      checked,
      disabled,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const theme = useTheme();
    const [innerChecked, setInnerChecked] = useState(!!checked);

    const getVariant = () => {
      switch (variant) {
        case CheckboxType.DAY:
          return getDayCheckbox(innerChecked, text);
        case CheckboxType.REGULAR:
        default:
          return getIcon(theme, checked, disabled);
      }
    };

    const innerOnChange = () => {
      setInnerChecked(!innerChecked);
    };

    return (
      <label>
        <CheckboxContainer className={className} data-testid={testId} $disabled={disabled}>
          {getVariant()}
          <HiddenCheckbox
            ref={ref}
            checked={innerChecked}
            value="on"
            {...rest}
            onChange={(e) => {
              if (disabled) {
                return;
              }
              if (onChange) {
                onChange(e);
              }
              innerOnChange();
            }}
            data-testid={`${testId}-Checkbox`}
          />
        </CheckboxContainer>
      </label>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export { Checkbox, CheckboxProps };
