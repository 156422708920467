import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import {
  CollapsiblePanel,
  CollapsiblePanelProps,
  device,
  PanelTitle,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import {
  SIDEPANEL_COLLAPSED_WIDTH_PX,
  SIDEPANEL_EXPANDED_WIDTH_PX,
  SIDEPANEL_DEFAULT_SIDE,
} from '../../../constants/Constants';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: ${({ theme }) => theme.spaces.spacing48} ${({ theme }) => theme.spaces.spacing32};
  align-items: center;
  overflow: auto;
`;

export const PreviewPanel = styled(CollapsiblePanel)`
  display: none;
  ${device.lg} {
    display: flex;
  }
`;

export const StyledTitle = styled(PanelTitle)`
  padding: 10px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spaces.spacing24};
  justify-items: center;
  vertical-align: middle;
`;

export const ContentHeader = styled.div<{ noPadding?: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  color: ${({ theme }) => theme.colors.coolGray100};
  padding-bottom: ${({ theme, noPadding }) => (noPadding ? 0 : theme.spaces.spacing24)};
`;

export const ContentHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

type Props = {
  showOnlyPreview?: boolean;
  preview?: ReactNode;
  previewTitle?: string;
  side?: CollapsiblePanelProps['side'];
  collapsedWidth?: number;
  expandedWidth?: number;
};

const PageContentEditable: FC<Props> = ({
  children,
  showOnlyPreview = false,
  preview,
  previewTitle,
  side,
  collapsedWidth,
  expandedWidth,
}) => {
  const { t } = useTranslation();
  const title = previewTitle ?? t('common.preview') ?? '';

  const wrappedPreview = showOnlyPreview ? (
    <div>
      <StyledTitle data-testid="PreviewTitle">{title}</StyledTitle>
      {preview}
    </div>
  ) : (
    <PreviewPanel
      title={title}
      side={side || SIDEPANEL_DEFAULT_SIDE}
      collapsedWidth={collapsedWidth || SIDEPANEL_COLLAPSED_WIDTH_PX}
      expandedWidth={expandedWidth || SIDEPANEL_EXPANDED_WIDTH_PX}
    >
      {preview}
    </PreviewPanel>
  );

  return (
    <Wrapper>
      {!showOnlyPreview && <MainContent>{children}</MainContent>}
      {preview && wrappedPreview}
    </Wrapper>
  );
};

export default PageContentEditable;
