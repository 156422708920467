import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BookmarkIcon, PiggyIcon, ShareIcon } from '@sim-admin-frontends/ui-shared';
import { useInstitutionBrandingQuery } from '@sim-admin-frontends/data-access';

import PageContentPreview from '../../layout/pageContent/PageContentPreview';
import PreviewItemText from '../../previewItem/PreviewItemText';
import { ContentPreview } from '../../layout/pageContent/PageContentPreviewStyles';
import { FALLBACK_INSTITUTION_NAME } from '../../../constants/Constants';
import { TDonationFormValues } from '../../../types/TDonations';

const DonationsPreview: FC<Partial<TDonationFormValues>> = ({
  title,
  images,
  videos,
  content,
  publisher,
}) => {
  const { t } = useTranslation();
  const testIdPrefix = 'DonationPreview';

  const publisherUuid = publisher?.value || '';
  const institutionName = publisher?.label || FALLBACK_INSTITUTION_NAME;

  const { data: institutionBranding } = useInstitutionBrandingQuery({ id: publisherUuid });

  const institutionLogo = institutionBranding?.institution?.branding?.avatar.links?.self || '';

  return (
    <PageContentPreview
      name={institutionName}
      logo={institutionLogo}
      images={images}
      videos={videos}
      title={title}
      titlePlaceholder={t('donations.preview.titlePlaceholder')}
      testIdPrefix={testIdPrefix}
      buttons={[
        { icon: <BookmarkIcon />, testId: 'Like', text: t('common.save') },
        { icon: <PiggyIcon />, testId: 'Donate', text: t('donations.donateNow') },
        { icon: <ShareIcon />, testId: 'Share', text: t('common.share') },
      ]}
    >
      <ContentPreview>
        <PreviewItemText
          slateText={content}
          textPlaceholder={t('updates.preview.contentPlaceholder')}
          testIdPrefix={testIdPrefix}
        />
      </ContentPreview>
    </PageContentPreview>
  );
};

export default DonationsPreview;
