import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Auth } from '@aws-amplify/auth';
import { Link, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  Button,
  ErrorIcon,
  LargeText,
  Spinner,
  toast,
  TToastType,
} from '@sim-admin-frontends/ui-shared';
import {
  InstitutionPlaceRole,
  useInstitutionPlacesQuery,
  useInstiutionRoleQuery,
} from '@sim-admin-frontends/data-access';
import { getIsOfficial } from '@sim-admin-frontends/utils-shared';

import {
  analyticsClear,
  analyticsSetUserData,
  analyticsSetUserProperty,
} from '../../utils/analytics/analyticsUtils';
import { formatCityFromPlace } from '../../utils/placeUtils';
import ROUTES from '../../routing/routes';
import { useAuthInfo } from '../../contexts/userContext';
import { usePlaceInfo } from '../../contexts/placeContext';
import { TSelectedPlace } from '../../types/TPlace';

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled(LargeText)`
  padding: ${({ theme }) => theme.spaces.spacing24};
`;

const PlaceLoading: FC = () => {
  const { setPlaces, clearPlaces, places } = usePlaceInfo();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    user,
    institutionUuid,
    clearUser,
    setIsOfficialInstitution,
    setIsCityInstitution,
    setIsLocalBusiness,
    setVisitorMode,
  } = useAuthInfo();
  useInstiutionRoleQuery(
    {
      id: institutionUuid,
    },
    {
      enabled: !!places?.[0]?.id,
      onSuccess: (data) => {
        if (!data.institution?.inPlaces) {
          return;
        }

        const isOfficialInstitution = getIsOfficial(data.institution.inPlaces[0]?.role);
        const isCityInstitution = data.institution.inPlaces[0]?.role === InstitutionPlaceRole.City;
        const isLocalBusiness =
          data.institution.inPlaces[0]?.role === InstitutionPlaceRole.LocalBusiness;
        const visitorMode = data.institution.visitorMode || false;
        setIsOfficialInstitution(isOfficialInstitution);
        setIsCityInstitution(isCityInstitution);
        setIsLocalBusiness(isLocalBusiness);
        setVisitorMode(visitorMode);
        history.push(ROUTES.home.path);
      },
    },
  );

  const onBackToLoginClick = () => {
    Auth.signOut();
  };

  useInstitutionPlacesQuery(
    {
      id: institutionUuid || '',
    },
    {
      enabled: !!institutionUuid,
      onSuccess: (data) => {
        const institutionPlaces = data?.institution?.places || [];
        if (institutionPlaces.length < 1) {
          Sentry.captureMessage('Failed to load valid places.', {
            level: Sentry.Severity.Warning,
          });
          return;
        }

        const selectedPlaces: TSelectedPlace[] = institutionPlaces.map((place: TSelectedPlace) => ({
          id: place.id,
          name: place.name,
          stateCode: place.stateCode,
          timezoneCode: place.timezoneCode,
          countryCode: place.countryCode,
        }));

        const email = user?.attributes?.email ?? 'No-email';
        const username = user?.username;
        const city = formatCityFromPlace(institutionPlaces[0]);

        if (!institutionPlaces[0])
          Sentry.captureMessage('Failed to send place to analytics. Place is undefined.', {
            level: Sentry.Severity.Warning,
          });
        else analyticsSetUserData(institutionPlaces[0].id, city, email);

        analyticsSetUserProperty({
          Email: email,
          City: city,
          Username: username,
        });

        setPlaces(selectedPlaces);
      },
      onError: () => {
        toast(t('placeLoading.failedToLoad'), TToastType.ERROR);
        clearUser();
        clearPlaces();
        analyticsClear();
        history.push(ROUTES.login.path);
      },
    },
  );

  if (!institutionUuid) {
    return (
      <Wrapper>
        <ErrorIcon width={48} height={48} />
        <Text>{t('placeLoading.noInstitution')}</Text>
        <Link to={ROUTES.login.path}>
          <Button onClick={onBackToLoginClick}>{t('placeLoading.back')}</Button>
        </Link>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Spinner />
      <Text>{t('common.loading')}</Text>
    </Wrapper>
  );
};

export default PlaceLoading;
