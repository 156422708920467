import { Button, TabPanel, TTabPanelTabs } from '@sim-admin-frontends/ui-shared';
import { FC, useCallback, useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { State } from '@sim-admin-frontends/data-access';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { CtaWrapper } from '../../common/TabsStyles';
import { ChatViewContainer } from './ChatView.container';
import { TAnnouncementsTabTypes } from '../../../types/TAnnouncements';

export const chatTabs = [TAnnouncementsTabTypes.PUBLISHED, TAnnouncementsTabTypes.UNPUBLISHED];

type Props = {
  filter: string;
};

const ChatTabs: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();

  const { setTabIndex, tabIndex } = useTabNavigation(ROUTES.chatOverview.path, chatTabs);

  const getViewContainer = useCallback(
    (state: State) => <ChatViewContainer searchFilter={filter} state={state} />,
    [filter],
  );

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: getViewContainer(State.Published),
        label: t('updates.tabs.published'),
        testId: 'ChatTabs#published',
      },
      {
        content: getViewContainer(State.Unpublished),
        label: t('updates.tabs.unpublished'),
        testId: 'ChatTabs#unpublished',
      },
    ],
    [getViewContainer, t],
  );

  return (
    <TabPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex}>
      <CtaWrapper>
        <Link to={generatePath(ROUTES.chatEdit.path)}>
          <Button testId="ChatTabs#AddCityChat">{t('chat.addChatMessage')}</Button>
        </Link>
      </CtaWrapper>
    </TabPanel>
  );
};

export default ChatTabs;
