import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.radius16};
  border: 1px ${({ theme }) => theme.colors.coolGray20} solid;
  padding: ${({ theme }) => theme.spaces.spacing24};
`;

const OptionWrapper = styled.div<{ isFirst: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spaces.spacing12};
  border: 1px solid ${({ theme }) => theme.colors.coolGray100};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  margin-top: ${({ theme, isFirst }) => (isFirst ? 0 : theme.spaces.spacing16)};
`;

const OptionLabel = styled.div`
  text-align: center;
  margin: 0 ${({ theme }) => theme.spaces.spacing12};
  color: ${({ theme }) => theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

type QuickQuestionOptionProps = {
  label: string;
  isFirst: boolean;
};

const QuickQuestionOption: FC<QuickQuestionOptionProps> = ({ label, isFirst }) => {
  return (
    <OptionWrapper isFirst={isFirst}>
      <OptionLabel data-testid={'QuickQuestionPreview#optionText'}>{label}</OptionLabel>
    </OptionWrapper>
  );
};

type QuickQuestionOptionsProps = {
  options: string[];
};

const QuickQuestionPreviewOptions: FC<QuickQuestionOptionsProps> = ({ options }) => {
  return (
    <Wrapper>
      {options.map((option, index) => {
        if (!option) return null;
        return <QuickQuestionOption isFirst={!index} label={option} key={index} />;
      })}
    </Wrapper>
  );
};

export { QuickQuestionPreviewOptions };
