import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SwitchableFormSection } from '@sim-admin-frontends/ui-shared';
import { State } from '@sim-admin-frontends/data-access';

import { SwitchableSectionContentWrapper } from './AnnouncementEditStyles';
import { AnnouncementNotificationSettings } from './AnnouncementNotificationSettings';
import useIsSystemDisabled from '../../../hooks/useIsSystemDisabled';
import { TAnnouncementFormValues, TAnnouncementsFormTypes } from '../../../types/TAnnouncements';

export type AnnouncementNotificationSectionProps = {
  control: Control<TAnnouncementFormValues>;
  notifyNow?: boolean;
  publishmentState?: State;
  isEdit: boolean;
  announcementType: TAnnouncementsFormTypes;
  onNotificationsEnabledChange: (value: boolean) => void;
};

const AnnouncementNotificationSection = ({
  control,
  notifyNow,
  publishmentState,
  isEdit,
  announcementType,
  onNotificationsEnabledChange,
}: AnnouncementNotificationSectionProps) => {
  const { t } = useTranslation();
  const { notificationsDisabled } = useIsSystemDisabled();

  const isNotificationDisabled =
    isEdit &&
    !!notifyNow &&
    (publishmentState == State.Published || publishmentState === State.Unpublished);

  const isAnnouncement = announcementType == TAnnouncementsFormTypes.ANNOUNCEMENT;
  const title = isAnnouncement
    ? t('updates.form.sendNotificationTitle')
    : t('polls.form.sendNotificationTitle');
  const description = isAnnouncement
    ? t('updates.form.sendNotificationDescription')
    : t('polls.form.sendNotificationDescription');
  const testPrefix =
    announcementType == TAnnouncementsFormTypes.ANNOUNCEMENT ? 'Announcement' : 'Poll';

  return (
    <SwitchableFormSection
      title={title}
      description={description}
      initiallyOpened={notifyNow}
      onVisibilityChanged={onNotificationsEnabledChange}
      disabled={isNotificationDisabled || notificationsDisabled}
      disabledText={t('updates.form.disabledText')}
      testId={`${testPrefix}Notification#switch`}
    >
      <SwitchableSectionContentWrapper>
        <AnnouncementNotificationSettings control={control} />
      </SwitchableSectionContentWrapper>
    </SwitchableFormSection>
  );
};

export default AnnouncementNotificationSection;
