import { MenuItem, MenuItemWrapper, TMenuEntry } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { AnalyticsEvent } from '../../utils/analytics/analyticsEvents';
import { AnalyticsRoutes } from '../../utils/analytics/analyticsRoutes';
import { analyticsLogEvent } from '../../utils/analytics/analyticsUtils';

const Divider = styled.hr`
  margin: ${({ theme }) => theme.spaces.spacing8};
  height: 1px;
  border: 0;
  background-color: ${({ theme }) => theme.colors.coolGray20};
`;

type Props = {
  item: TMenuEntry;
  institutionUuid: string;
};

const SideMenuItem: FC<Props> = ({ item, institutionUuid }) => {
  const theme = useTheme();
  const onClick = () => {
    const analyticsRoute = AnalyticsRoutes[item.path];
    analyticsLogEvent({
      type: AnalyticsEvent.MENU_CLICK,
      data: { Name: analyticsRoute.name, InstitutionId: institutionUuid },
    });
  };

  if (item.disabled) {
    return null;
  }

  return (
    <>
      <MenuItemWrapper
        as={NavLink}
        exact={item.path === '/'}
        to={generatePath(item.path)}
        onClick={onClick}
        activeStyle={{
          backgroundColor: theme.colors.yellow10,
        }}
        disabled={item.disabled}
        data-testid={item.testId}
      >
        <MenuItem {...item} label={item.label} />
      </MenuItemWrapper>
      {item.hasDivider && <Divider />}
    </>
  );
};

export default SideMenuItem;
