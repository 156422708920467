import {
  CouponsByFilterInput,
  CouponsQuery,
  CouponsOrderByInput,
  useCouponsQuery,
  useInfiniteGraphQLQuery,
} from '..';

export const useInfiniteCouponsQuery = (
  filter: CouponsByFilterInput,
  orderBy?: CouponsOrderByInput,
) => {
  const getNextPageParam = (lastPage: CouponsQuery) => lastPage.couponsBy.nextToken;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    orderBy,
    nextToken: pageParam,
    filter,
  });

  return useInfiniteGraphQLQuery(useCouponsQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
  });
};
