import { DotIcon } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { getTestId } from '../../utils/testUtils';

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

const InstitutionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PreviewLogo = styled.img`
  width: ${({ theme }) => theme.spaces.spacing32};
  height: ${({ theme }) => theme.spaces.spacing32};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  margin-right: ${({ theme }) => theme.spaces.spacing8};
`;

const SmallText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
`;

const PlaceName = styled(SmallText)`
  color: ${({ theme }) => theme.colors.coolGray100};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

const Dot = styled(DotIcon)`
  margin-left: ${({ theme }) => theme.spaces.spacing4};
`;

const Time = styled(SmallText)`
  margin-left: ${({ theme }) => theme.spaces.spacing4};
  color: ${({ theme }) => theme.colors.coolGray60};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

type Props = {
  logo?: string;
  testIdPrefix: string;
  name?: string;
  icons?: JSX.Element;
};

const PreviewItemInstitution: FC<Props> = ({ logo, testIdPrefix, name, icons }) => {
  const { t } = useTranslation();
  return (
    <InstitutionWrapper>
      <FlexCenter>
        {logo && (
          <PreviewLogo
            src={logo}
            alt={logo ? `${name} logo` : 'logo'}
            data-testid={getTestId(testIdPrefix, 'Logo')}
          />
        )}
        <PlaceName data-testid={getTestId(testIdPrefix, 'Name')}>{name}</PlaceName>
        <Dot />
        <Time>{t('events.preview.time')}</Time>
      </FlexCenter>
      <FlexCenter>{icons}</FlexCenter>
    </InstitutionWrapper>
  );
};

export default PreviewItemInstitution;
