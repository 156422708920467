import { FC } from 'react';
import styled from 'styled-components';
import { Button, MediumText } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

const ModalWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.spacing24};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.radius16};
  display: flex;
  max-width: 320px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 ${({ theme }) => theme.spaces.spacing6} ${({ theme }) => theme.spaces.spacing4}
    rgba(0, 0, 0, 0.3);
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${({ theme }) => theme.spaces.spacing24};
`;

const Content = styled(MediumText)`
  text-align: center;
`;

const Divider = styled.div`
  width: ${({ theme }) => theme.spaces.spacing8};
`;

type Props = {
  content?: string;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  previousButtonText?: string;
  nextButtonText?: string;
};

const HighlightableElementsModal: FC<Props> = ({
  content,
  onNextClick,
  onPreviousClick,
  previousButtonText,
  nextButtonText,
}) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper>
      {content ? <Content>{content}</Content> : null}
      <ButtonsWrapper>
        {onPreviousClick ? (
          <Button onClick={onPreviousClick}>{previousButtonText ?? t('common.previous')}</Button>
        ) : null}
        <Divider />
        {onNextClick ? (
          <Button onClick={onNextClick}>{nextButtonText ?? t('common.next')}</Button>
        ) : null}
      </ButtonsWrapper>
    </ModalWrapper>
  );
};

export default HighlightableElementsModal;
