import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TBaseProps, Button } from '../..';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorText = styled.div`
  padding: ${({ theme }) => theme.spaces.spacing16} 0;
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: ${({ theme }) => theme.lineHeights.large};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.coolGray100};
  text-align: center;
`;

const IconWrapper = styled.div`
  width: ${({ theme }) => theme.spaces.spacing72};
  height: ${({ theme }) => theme.spaces.spacing72};
`;

export interface ErrorProps extends TBaseProps {
  icon?: ReactElement;
  caption: string;
  buttonLabel?: string;
  onClick?: () => void;
}

const Error: FC<ErrorProps> = ({
  icon,
  caption,
  buttonLabel,
  onClick,
  testId = 'ErrorComponent',
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper data-testid={testId}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <ErrorText>{caption}</ErrorText>
      <Button onClick={onClick} testId={`${testId}-Button`}>
        {buttonLabel || t('error.retry')}
      </Button>
    </Wrapper>
  );
};

export { Error };
