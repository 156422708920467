import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { MaybeDate } from '@sim-admin-frontends/utils-shared';
import { RecurringPeriod } from '@sim-admin-frontends/data-access';

import { transformDay } from '../../../utils/couponsUtils';
import { RecurrenceEnd } from '../../../types/TCoupons';

export const recurrenceValidation = (
  t: TFunction,
  isRecurring: boolean,
  endsOn: RecurrenceEnd,
  fromDate: Date,
  toDate: Date,
) =>
  Yup.object({
    every: Yup.string().test(
      'isPositive',
      t('common.validation.number.positive'),
      (value?: string) => !isRecurring || (!!value && Number(value) > 0),
    ),
    type: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
    day: Yup.mixed().when('type', (type) =>
      Yup.array()
        .of(Yup.mixed())
        .test(
          'required',
          t('common.validation.required'),
          (value?: (string | boolean | undefined)[]) => {
            const transformedDay = transformDay(value);
            const isWeekly = type.value === RecurringPeriod.Weekly;
            return (
              !isRecurring ||
              !isWeekly ||
              (!!value && !!transformedDay && transformedDay?.length > 0)
            );
          },
        ),
    ),

    toDate: Yup.mixed()
      .test(
        'required',
        t('common.validation.required'),
        (value: MaybeDate) => !isRecurring || endsOn !== RecurrenceEnd.ON_DATE || !!value,
      )
      .test(
        'greaterThanFromDate',
        t('common.validation.date.greaterThanFromDate'),
        (value?: MaybeDate) =>
          !isRecurring || endsOn !== RecurrenceEnd.ON_DATE || (!!value && value >= fromDate),
      )
      .test(
        'lessThanToDate',
        t('common.validation.date.lessThanToDate'),
        (value?: MaybeDate) =>
          !isRecurring || endsOn !== RecurrenceEnd.ON_DATE || (!!value && value <= toDate),
      ),
    occurrences: Yup.string().test(
      'isPositive',
      t('common.validation.number.positive'),
      (value?: string) =>
        !isRecurring ||
        endsOn !== RecurrenceEnd.AFTER_OCCURRENCES ||
        (!!value && Number(value) > 0),
    ),
  });
