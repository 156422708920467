import React, { FC, useState, MouseEvent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const COPIED_TEXT_DELAY = 2000;

const Wrapper = styled.div`
  cursor: pointer;
  width: inherit;
  text-overflow: ellipsis;
`;

type ClickableCopyTextProps = {
  value: string;
  valueToCopy?: string;
};

const ClickableCopyText: FC<ClickableCopyTextProps> = ({ value, valueToCopy }) => {
  const { t } = useTranslation();
  const [text, setText] = useState(value);
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    // If metaKey (Mac) or ctrlKey (Windows) is pressed, open the link in a new tab
    if (e.metaKey || e.ctrlKey) {
      window.open(valueToCopy ?? value, '_blank');
    } else {
      navigator.clipboard.writeText(valueToCopy ?? value);
      setText(t('common.copiedToClipboard'));
      setTimeout(() => setText(value), COPIED_TEXT_DELAY);
    }
  };
  return (
    <Wrapper title={t('common.clickToCopy')} onClick={onClick}>
      {text}
    </Wrapper>
  );
};

export { ClickableCopyText };
