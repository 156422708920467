import { FC } from 'react';
import styled from 'styled-components';

import { renderContent } from './helper';

type Props = {
  id?: string;
  type?: string;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 700px;
  height: 100%;
`;

const ModeratorPreviewContainer: FC<Props> = ({ id, type }) => (
  <Container>
    <ContentWrapper>{renderContent(type, id)}</ContentWrapper>
  </Container>
);

export default ModeratorPreviewContainer;
