import { useCallback } from 'react';
import { isValid } from 'date-fns';
import { FieldValue, FieldValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

const useDateTimeChanged = <T extends FieldValues>(
  field: FieldValue<T>,
  setValue: UseFormSetValue<T>,
  trigger: UseFormTrigger<T>,
) => {
  const onDateTimeChanged = useCallback(
    (value) => {
      if (isValid(value)) {
        setValue(field, value);
        trigger(field);
      }
    },
    [field, setValue, trigger],
  );

  return { onDateTimeChanged };
};

export default useDateTimeChanged;
