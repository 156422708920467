import { TSelectItem } from '@sim-admin-frontends/ui-shared';

export type TChatFormValues = {
  question: string;
  publisher?: TSelectItem;
  lang?: TSelectItem;
};

export enum ChatModalActions {
  DELETE = 'DELETE',
  UNPUBLISH = 'UNPUBLISH',
  PUBLISH = 'PUBLISH',
}
