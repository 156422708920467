import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import LoginContainer from '../../components/login/Login.container';
import useAnalytics from '../../hooks/useAnalytics';

const LoginPage: FC<RouteComponentProps> = () => {
  useAnalytics();
  return <LoginContainer />;
};

export default LoginPage;
