import { TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import styled from 'styled-components';

export const HiddenOverflow = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: pre-wrap !important;
  overflow: hidden;
`;

const ImageWrapper = styled.div<{ $height: number }>`
  height: ${({ $height }) => $height}px;
  overflow: ${({ $height }) => ($height < 100 ? 'hidden' : 'unset')};
  aspect-ratio: 1;
  border: 1px solid;
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  border-color: ${({ theme }) => theme.colors.coolGray20};
  margin-right: ${({ theme }) => theme.spaces.spacing16};
`;

const ContentWrapper = styled.div`
  overflow: hidden;
  aspect-ratio: 1;
  display: flex;
  position: relative;
`;

const StyledImage = styled.img`
  height: 100%;
  aspect-ratio: 1;
  border: 1px solid;
  background-color: ${({ theme }) => theme.colors.coolGray40};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  border-color: ${({ theme }) => theme.colors.coolGray20};
  object-fit: cover;
`;

const StyledOverlay = styled.img<{ $height: number }>`
  height: ${({ $height }) => $height}px;
  aspect-ratio: 1;
  position: absolute;
`;

const TextsWrapper = styled.div<{ $height: number }>`
  display: inline-table;
  position: absolute;
  width: 90%;
  max-height: 90%;
  bottom: 0;
  margin: ${({ $height, theme }) =>
    $height < 100 ? theme.spaces.spacing4 : theme.spaces.spacing8};
`;

const Title = styled(HiddenOverflow)<{ $height: number }>`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  /* We use this component in list also */
  font-size: ${({ theme, $height }) =>
    $height < 100 ? theme.fontSizes.medium : theme.fontSizes.xxLarge};
  line-height: ${({ theme, $height }) => ($height < 100 ? theme.lineHeights.medium : '2.5rem')};
`;

const Subtitle = styled(HiddenOverflow)<{ $height: number }>`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  /* We use this component in list also */
  font-size: ${({ theme, $height }) =>
    $height < 100 ? theme.fontSizes.extraSmall : theme.fontSizes.medium};
`;

interface Props extends TBaseProps {
  onClick?: () => void;
  height: number;
  image?: string;
  overlay: string;
  title: string;
  subtitle: string;
}

const SquareImageWithTexts: FC<Props> = ({
  onClick,
  height,
  image,
  overlay,
  title,
  subtitle,
  testId,
}) => (
  <ImageWrapper $height={height} onClick={onClick}>
    <ContentWrapper>
      {image && <StyledImage src={image} data-testid={`${testId}-Image`} />}
      <StyledOverlay src={overlay} $height={height} />
      <TextsWrapper $height={height}>
        <Title $height={height} data-testid={`${testId}-Title`}>
          {title}
        </Title>
        <Subtitle $height={height} data-testid={`${testId}-Subtitle`}>
          {subtitle}
        </Subtitle>
      </TextsWrapper>
    </ContentWrapper>
  </ImageWrapper>
);

export default SquareImageWithTexts;
