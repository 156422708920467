import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BookmarkIcon, ShareIcon } from '@sim-admin-frontends/ui-shared';

import { TEmergencyFormValues } from '../../../types/TAnnouncements';
import PageContentPreview from '../../layout/pageContent/PageContentPreview';
import PreviewItemCategory from '../../previewItem/PreviewItemCategory';
import PreviewItemLocation from '../../previewItem/PreviewItemLocation';
import PreviewItemText from '../../previewItem/PreviewItemText';
import { ContentPreview } from '../../layout/pageContent/PageContentPreviewStyles';

interface Props extends Partial<TEmergencyFormValues> {
  institutionName: string;
  institutionLogo?: string;
}

const EmergencyPreview: FC<Props> = ({
  institutionName,
  institutionLogo,
  title,
  images,
  videos,
  location,
  content,
}) => {
  const { t } = useTranslation();
  const testIdPrefix = 'EmergencyPreview';
  return (
    <PageContentPreview
      name={institutionName}
      logo={institutionLogo}
      images={images}
      videos={videos}
      title={title}
      titlePlaceholder={t('emergency.preview.titlePlaceholder')}
      testIdPrefix={testIdPrefix}
      buttons={[
        { icon: <BookmarkIcon />, testId: 'Like', text: t('common.save') },
        { icon: <ShareIcon />, testId: 'Share', text: t('common.share') },
      ]}
    >
      <ContentPreview>
        <PreviewItemText
          slateText={content}
          textPlaceholder={t('emergency.preview.contentPlaceholder')}
          testIdPrefix={testIdPrefix}
        />
        <PreviewItemLocation location={location} showIcon={false} testIdPrefix={testIdPrefix} />
        <PreviewItemCategory
          label={t('emergency.preview.categoryName')}
          testIdPrefix={testIdPrefix}
        />
      </ContentPreview>
    </PageContentPreview>
  );
};

export default EmergencyPreview;
