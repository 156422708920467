import { yupResolver } from '@hookform/resolvers/yup';
import {
  TInstitution,
  TInstitutionListItem,
  TRaffleDetail,
} from '@sim-admin-frontends/data-access';
import {
  Button,
  FormButtonsWrapper,
  FormDateTimePicker,
  FormInput,
  FormSelect,
  FormSwitcherWithLabel,
  FormUpload,
  FormWrapper,
  FormWysiwyg,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  SUPPORTED_IMAGE_FORMATS,
  TableIconProps,
  TSelectItem,
  ActionButtons,
} from '@sim-admin-frontends/ui-shared';
import {
  getInstitutionInitValues,
  getLangOptionByVal,
  getLangOptions,
  isEmpty,
  MaybeDate,
  roundMinutes,
} from '@sim-admin-frontends/utils-shared';
import { TWysiwygValue } from '@simplicity-tech/sim-slate-types';
import { format, addMonths, subHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { FC, useCallback, useMemo } from 'react';
import { FormProvider, get, useForm, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import { FALLBACK_TIMEZONE } from '../../../constants/Constants';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import useDateTimeChanged from '../../../hooks/useDateTimeChanged';
import useIsSystemDisabled from '../../../hooks/useIsSystemDisabled';
import { TRaffleFormValues } from '../../../types/TRaffles';
import { getPlaceOptions } from '../../../utils/placeUtils';
import { imagesValidation } from '../../announcements/edit/validation';
import PageContentEditable, { ContentHeader } from '../../layout/pageContent/PageContentEditable';
import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import RafflePreview from '../preview/RafflePreview';

const ButtonsWrapper = styled(FormButtonsWrapper)`
  justify-content: flex-end;
`;

const Divider = styled.div`
  height: ${({ theme }) => theme.spaces.spacing16};
`;

const schema = (t: TFunction, timezone: string, isMultiCity: boolean) =>
  Yup.object().shape({
    title: Yup.string().required(t('common.validation.required')),
    titleWidth: Yup.number()
      .required(t('common.validation.required'))
      .typeError(t('common.validation.required'))
      .test(
        'min',
        t('common.validation.number.min', { num: 1 }),
        (value?: number) => !!value && Number(value) >= 1,
      )
      .test(
        'max',
        t('common.validation.number.max', { num: 100 }),
        (value?: number) => !!value && Number(value) <= 100,
      ),
    subtitle: Yup.string().required(t('common.validation.required')),
    subtitleWidth: Yup.number()
      .required(t('common.validation.required'))
      .typeError(t('common.validation.required'))
      .test(
        'min',
        t('common.validation.number.min', { num: 1 }),
        (value?: number) => !!value && Number(value) >= 1,
      )
      .test(
        'max',
        t('common.validation.number.max', { num: 100 }),
        (value?: number) => !!value && Number(value) <= 100,
      ),
    textColor: Yup.string().required(t('common.validation.required')),
    textPosition: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !((value || []).length < 1),
    ),
    bgImage: Yup.array()
      .nullable()
      .test('required', t('common.validation.required'), (value) => !!value)
      .concat(imagesValidation(t)),
    datetimeFrom: Yup.mixed().test(
      'required',
      t('common.validation.required'),
      (value: MaybeDate) => !!value,
    ),
    datetimeTo: Yup.mixed()
      .test('required', t('common.validation.required'), (value: MaybeDate) => !!value)
      .test(
        'greaterThanDatetimeFrom',
        t('common.validation.date.greaterThanFromDate'),
        (value: MaybeDate, context) => !!value && value > context.parent.datetimeFrom,
      ),
    termsAndConditionsUrl: Yup.string().url().required(t('common.validation.required')),
    privacyPolicyUrl: Yup.string().url().required(t('common.validation.required')),
    invitationMessage: Yup.string().test(
      'equals',
      t('common.validation.required'),
      (value) => value !== SERIALIZED_WYSIWYG_EMPTY_VALUE,
    ),
    places: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !(isMultiCity && (value || []).length < 1),
    ),
  });

type Props = {
  onSubmit: (values: TRaffleFormValues) => Promise<void>;
  onDiscard: () => void;
  raffle?: TRaffleDetail;
  institution: TInstitution;
  userInstitutions: TInstitutionListItem[];
  showOnlyPreview?: boolean;
  raffleDetailActions: TableIconProps[];
};

const RaffleEdit: FC<Props> = ({
  onSubmit,
  onDiscard,
  raffle,
  institution,
  userInstitutions,
  showOnlyPreview,
  raffleDetailActions,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthInfo();
  const { places } = usePlaceInfo();
  const { postsDisabled } = useIsSystemDisabled();

  const defaultTitleWidth = 100;
  const defaultSubtitleWidth = 100;
  const defaultTextColor = 'FFF';
  const defaultTermsAndConditionsUrl = 'https://onesimplicity.com/legal/terms-of-service/';
  const defaultPrivacyPolicyUrl = 'https://onesimplicity.com/legal/privacy-policy/';
  const defaultInvitationMessage: TWysiwygValue = [
    {
      type: 'paragraph',
      children: [
        {
          type: 'text',
          text: `${t('common.downloadHere')}: `,
        },
        {
          type: 'link',
          url: 'https://simplicity.app.link/',
          children: [{ type: 'text', text: 'https://simplicity.app.link/' }],
        },
      ],
    },
  ];

  const pageTitle = raffle ? t('raffles.form.editPageTitle') : t('raffles.form.createPageTitle');
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;
  const countryCode = places?.[0].countryCode || '';

  const textPositionOptions = [
    {
      label: 'Top',
      value: 'flex-start',
    },
    {
      label: 'Center',
      value: 'center',
    },
    {
      label: 'Bottom',
      value: 'flex-end',
    },
  ];
  const institutionOptions = useMemo(
    () =>
      getInstitutionInitValues(
        user?.attributes?.['custom:institutionUuids'],
        institution,
        userInstitutions,
      ),
    [userInstitutions, institution, user?.attributes],
  );
  const placesOptions = useMemo(() => getPlaceOptions(institution?.places), [institution]);

  const initialTextPosition =
    textPositionOptions.find(({ value }) => value === raffle?.raffleOptions?.textPosition) ||
    textPositionOptions[0];
  const initialPublisher = { label: institution?.name || '', value: institution?.id || '' };

  const showCitySelect = !!(placesOptions || []).length;
  const showPublishers = !!(institutionOptions || []).length;
  const institutionLang = institution?.lang || undefined;

  const initialValues: TRaffleFormValues = {
    title: raffle?.title || '',
    titleWidth: raffle?.raffleOptions?.titleWidth || defaultTitleWidth,
    subtitle: raffle?.subtitle || '',
    subtitleWidth: raffle?.raffleOptions?.textWidth || defaultSubtitleWidth,
    invitationMessage: raffle?.content || JSON.stringify(defaultInvitationMessage),
    textColor: raffle?.raffleOptions?.textColor || defaultTextColor,
    textPosition: initialTextPosition,
    bgImage: raffle?.imageObjects || null,
    datetimeFrom: raffle?.raffleOptions?.datetimeFrom
      ? utcToZonedTime(new Date(raffle.raffleOptions.datetimeFrom), timezone)
      : utcToZonedTime(roundMinutes(new Date()), timezone),
    datetimeTo: raffle?.raffleOptions?.datetimeTo
      ? utcToZonedTime(raffle.raffleOptions.datetimeTo, timezone)
      : utcToZonedTime(roundMinutes(addMonths(subHours(new Date(), 1), 1)), timezone),
    showIncChanceFlag: raffle?.raffleOptions?.showIncChanceFlag || false,
    termsAndConditionsUrl:
      raffle?.raffleOptions?.termsAndConditionsUrl || defaultTermsAndConditionsUrl,
    privacyPolicyUrl: raffle?.raffleOptions?.privacyPolicyUrl || defaultPrivacyPolicyUrl,
    places: getPlaceOptions(raffle?.places) || [],
    publisher: initialPublisher,
    lang: getLangOptionByVal(raffle?.lang || institutionLang, countryCode),
    notifications: raffle?.notifications
      ? raffle?.notifications.map((notification) => ({
          value: utcToZonedTime(notification, timezone),
        }))
      : [],
  };

  const methods = useForm<TRaffleFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, timezone, showCitySelect)),
    mode: 'all',
  });

  const { handleSubmit, register, formState, setValue, trigger, control } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const [
    previewTitle,
    previewTitleWidth,
    previewSubtitle,
    previewSubtitleWidth,
    previewTextColor,
    previewTextPosition,
    previewBgImage,
  ] = useWatch({
    control,
    name: [
      'title',
      'titleWidth',
      'subtitle',
      'subtitleWidth',
      'textColor',
      'textPosition',
      'bgImage',
    ],
  });

  const { onDateTimeChanged: onDatetimeFromChanged } = useDateTimeChanged(
    'datetimeFrom',
    setValue,
    trigger,
  );
  const { onDateTimeChanged: onDatetimeToChanged } = useDateTimeChanged(
    'datetimeTo',
    setValue,
    trigger,
  );

  const onShowIncChanceFlagChanged = useCallback(
    (value: boolean) => {
      setValue('showIncChanceFlag', value);
      trigger('showIncChanceFlag');
    },
    [setValue, trigger],
  );

  const submit = async (values: TRaffleFormValues) => onSubmit(values);

  const onSubmitClick = () => {
    methods.control._updateProps({
      resolver: yupResolver(schema(t, timezone, showCitySelect)),
    });
    handleSubmit(submit)();
  };

  return (
    <PageContentEditable
      showOnlyPreview={showOnlyPreview}
      previewTitle={t('raffles.preview.title')}
      preview={
        <RafflePreview
          title={previewTitle}
          titleWidth={previewTitleWidth}
          subtitle={previewSubtitle}
          subtitleWidth={previewSubtitleWidth}
          textColor={previewTextColor}
          textPosition={previewTextPosition}
          bgImage={previewBgImage}
        />
      }
    >
      <FormWrapper>
        <ActionButtons actionButtons={raffleDetailActions} />
        <ContentHeader>{pageTitle}</ContentHeader>
        <FormProvider {...methods}>
          <FormInput
            {...register('title')}
            label={t('raffles.form.title')}
            placeholder={t('raffles.form.titlePlaceholder')}
            error={errors.title}
            testId="RaffleEdit#title"
          />
          <FormInput
            {...register('titleWidth')}
            label={t('raffles.form.titleWidth')}
            placeholder={t('raffles.form.titleWidthPlaceholder')}
            type={'number'}
            min={1}
            minLength={1}
            max={100}
            maxLength={3}
            error={errors.titleWidth}
            testId="RaffleEdit#titleWidth"
          />
          <FormInput
            {...register('subtitle')}
            label={t('raffles.form.subtitle')}
            placeholder={t('raffles.form.subtitlePlaceholder')}
            error={errors.subtitle}
            testId="RaffleEdit#subtitle"
          />
          <FormInput
            {...register('subtitleWidth')}
            label={t('raffles.form.subtitleWidth')}
            placeholder={t('raffles.form.subtitleWidthPlaceholder')}
            type={'number'}
            min={1}
            minLength={1}
            max={100}
            maxLength={3}
            error={errors.subtitleWidth}
            testId="RaffleEdit#subtitleWidth"
          />
          <FormInput
            {...register('textColor')}
            label={t('raffles.form.textColor')}
            placeholder={t('raffles.form.textColorPlaceholder')}
            minLength={3}
            maxLength={6}
            error={errors.textColor}
            testId="RaffleEdit#textColor"
          />
          <FormSelect
            control={control}
            name="textPosition"
            label={t('raffles.form.textPosition')}
            placeholder={t('raffles.form.textPositionPlaceholder')}
            options={textPositionOptions || []}
            defaultValue={initialValues.textPosition}
            testId={'RaffleEdit#textPosition'}
            allSelectable
          />
          <FormUpload
            name="bgImage"
            control={control}
            label={t('raffles.form.bgImage')}
            dropzoneLabel={t('updates.form.dropzoneLabel')}
            multiple={false}
            accept={SUPPORTED_IMAGE_FORMATS}
            testId="RaffleEdit#bgImage"
            t={t}
          />
          <FormDateTimePicker
            name="datetimeFrom"
            control={control}
            dateLabel={t('raffles.form.dateFrom')}
            timeLabel={t('raffles.form.timeFrom')}
            minDate={utcToZonedTime(new Date(), timezone)}
            initialDate={initialValues.datetimeFrom}
            initialTime={format(initialValues.datetimeFrom, 'hh:mm')}
            initialDayPeriod={format(initialValues.datetimeFrom, 'a')}
            onChange={onDatetimeFromChanged}
            testId="RaffleEdit#datetimeFrom"
          />
          <FormDateTimePicker
            name="datetimeTo"
            control={control}
            dateLabel={t('raffles.form.dateTo')}
            timeLabel={t('raffles.form.timeTo')}
            minDate={utcToZonedTime(new Date(), timezone)}
            initialDate={initialValues.datetimeTo}
            initialTime={format(initialValues.datetimeTo, 'hh:mm')}
            initialDayPeriod={format(initialValues.datetimeTo, 'a')}
            onChange={onDatetimeToChanged}
            testId="RaffleEdit#datetimeTo"
          />
          <FormInput
            {...register('termsAndConditionsUrl')}
            label={t('raffles.form.termsAndConditionsUrl')}
            placeholder={t('raffles.form.termsAndConditionsUrlPlaceholder')}
            error={errors.termsAndConditionsUrl}
            testId="RaffleEdit#termsAndConditionsUrl"
          />
          <FormInput
            {...register('privacyPolicyUrl')}
            label={t('raffles.form.privacyPolicyUrl')}
            placeholder={t('raffles.form.privacyPolicyUrlPlaceholder')}
            error={errors.privacyPolicyUrl}
            testId="RaffleEdit#privacyPolicyUrl"
          />
          {showCitySelect && (
            <FormSelect
              control={control}
              name="places"
              label={t('updates.form.cities')}
              placeholder={t('updates.form.citiesDescription')}
              options={placesOptions || []}
              defaultValue={initialValues.places}
              testId="RaffleEdit#placesSelect"
              error={get(errors, 'places')}
              allSelectable
              isMulti
              clearable
            />
          )}
          {showPublishers && (
            <FormSelect
              control={control}
              name="publisher"
              label={t('updates.form.publisher')}
              placeholder={t('updates.form.publisherDescription')}
              options={institutionOptions || []}
              defaultValue={initialValues.publisher}
              testId="RaffleEdit#publisher"
              error={get(errors, 'publisher')}
            />
          )}
          <FormSelect
            control={control}
            name="lang"
            label={t('updates.form.language')}
            placeholder={t('updates.form.languageDescription')}
            options={getLangOptions()}
            defaultValue={initialValues.lang}
            testId={'RaffleEdit#languageSelect'}
            error={get(errors, 'lang')}
            allSelectable
          />
          <FormSwitcherWithLabel
            infoContent={t('raffles.form.showIncChanceFlagDescription')}
            label={t('raffles.form.showIncChanceFlag')}
            initialValue={initialValues.showIncChanceFlag}
            onChange={onShowIncChanceFlagChanged}
            testId="RaffleEdit#showIncChanceFlag"
          />

          <Divider />
          <FormWysiwyg
            control={control}
            name="invitationMessage"
            label={t('raffles.form.invitationMessage')}
            initialValue={JSON.parse(initialValues.invitationMessage)}
            testId="RaffleEdit#invitationMessage"
          />
          <ButtonsWrapper>
            <Button variant="tertiary" onClick={onDiscard} disabled={isSubmitting}>
              {t('updates.form.cancel')}
            </Button>
            <Button
              type="submit"
              onClick={onSubmitClick}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors) || postsDisabled}
              disabledText={postsDisabled ? t('updates.form.disabledText') : undefined}
              testId={'PublishButton'}
            >
              {t('updates.form.publish')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
      <RouteLeavingGuard when={isDirty && !isSubmitting} />
    </PageContentEditable>
  );
};

export default RaffleEdit;
