import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateCouponActions } from '../../../hooks/actionButtons/useGenerateCouponActions';

export interface CouponsTableMenuProps extends TBaseProps {
  couponId: string;
  refetch: () => void;
}

const CouponsTableMenu: FC<CouponsTableMenuProps> = ({ couponId, refetch, testId }) => {
  const { listActions: items, renderModal } = useGenerateCouponActions({
    couponId,
    refetch,
  });

  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export default CouponsTableMenu;
