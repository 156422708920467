import styled from 'styled-components';

import { Label } from '../label/Label';
import { SmallestText } from '../texts/Texts';

export const FormItemWrapper = styled.div<{ noMargin?: boolean }>`
  width: 100%;
  margin-bottom: ${({ theme, noMargin }) => (noMargin ? '0' : theme.spaces.spacing16)};
  position: relative;
`;

export const FormItemLabel = styled(Label)`
  margin-bottom: 6px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${({ theme }) => theme.spaces.spacing24};
  width: 75%;
  max-width: 580px;
`;

export const FormButtonsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spaces.spacing8};
`;

export const BottomCaption = styled(SmallestText)`
  margin-top: ${({ theme }) => theme.spaces.spacing4};
`;
