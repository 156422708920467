import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, get, useForm, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import {
  FormDateTimePicker,
  FormInput,
  FormSectionHeader,
  FormSelect,
  FormUpload,
  FormWrapper,
  FormWysiwyg,
  SwitchableFormSection,
  TSelectItem,
  TSelectRef,
  MAX_VIDEO_UPLOAD_SIZE,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  SUPPORTED_VIDEO_FORMATS,
  FormImageCropperUpload,
  DEFAULT_RADIUS_MILES_STRING,
  TableIconProps,
  ActionButtons,
  FormSectionWithSwitch,
} from '@sim-admin-frontends/ui-shared';
import {
  ImageInput,
  State,
  TCategories,
  TInstitution,
  TInstitutionListItem,
  VideoInput,
  Currency,
} from '@sim-admin-frontends/data-access';
import {
  getInstitutionInitValues,
  getLangOptionByVal,
  getLangOptions,
  isFile,
  MaybeDate,
  roundMinutes,
  TGeoShape,
  TMapCenter,
} from '@sim-admin-frontends/utils-shared';

import { usePlaceInfo } from '../../../contexts/placeContext';
import { SwitchableSectionContentWrapper } from '../../announcements/edit/AnnouncementEditStyles';
import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import { FALLBACK_TIMEZONE } from '../../../constants/Constants';
import { getAreaOfInterest } from '../../../utils/announcementsUtils';
import { generateCirclePolygonPaths, generatePolygon, getMeters } from '../../../utils/mapUtils';
import PageContentEditable, {
  ContentHeader,
  ContentHeaderWrapper,
  HeaderWrapper,
} from '../../layout/pageContent/PageContentEditable';
import { getPlaceOptions } from '../../../utils/placeUtils';
import FormButtons from '../../announcements/edit/FormButtons';
import useYTVideoInEditor from '../../../hooks/useYTVideoInEditor';
import useDateTimeChanged from '../../../hooks/useDateTimeChanged';
import { imagesValidation, notificationsValidation } from '../../announcements/edit/validation';
import { TLoggedUser } from '../../../types/TUser';
import { getCategoriesOptions, getInitialCategoriesOptions } from '../../../utils/categoriesUtils';
import AnnouncementAreaOfInterest from '../../announcements/edit/AnnouncementAreaOfInterest';
import { TDonationDetail, TDonationFormValues } from '../../../types/TDonations';
import DonationsPreview from '../view/DonationsPreview';
import DonationsNotificationSection from './DonationsNotificationSection';
import { prepareFormValues } from '../../../utils/donationsUtils';
import useChangePublisher from '../../../hooks/useChangePublisher';
import { useAuthInfo } from '../../../contexts/userContext';

const schema = (t: TFunction, timezone: string, isScheduling: boolean, isMultiCity: boolean) => {
  return Yup.object().shape({
    title: Yup.string(),
    categories: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        }),
      )
      .compact()
      .min(1, t('common.validation.required')),
    content: Yup.string().test('equals', t('common.validation.required'), (value) => {
      return value !== SERIALIZED_WYSIWYG_EMPTY_VALUE;
    }),
    places: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !(isMultiCity && (value || []).length < 1),
    ),
    scheduledAt: Yup.mixed()
      .test(
        'required',
        t('common.validation.required'),
        (value: MaybeDate) => !isScheduling || !!value,
      )
      .when('publishedAt', (publishedAt) =>
        Yup.date().test(
          'greaterThanPublished',
          t('common.validation.date.greaterThanPublished'),
          (value?: MaybeDate) => !isScheduling || (!!value && value > publishedAt),
        ),
      ),
    images: imagesValidation(t),
    videos: Yup.array().of(
      Yup.mixed()
        .test('fileSize', t('common.validation.file.tooLarge'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_VIDEO_UPLOAD_SIZE : true,
        )
        .test(
          'fileType',
          t('common.validation.file.unsupportedFormat'),
          (file: File | VideoInput) =>
            file && isFile(file) ? SUPPORTED_VIDEO_FORMATS.includes(file.type) : true,
        ),
    ),
    notifications: notificationsValidation(t, timezone),
    publishedAt: Yup.mixed()
      .test('required', t('common.validation.required'), (value: MaybeDate) => !!value)
      .test(
        'maxdate',
        t('common.validation.date.futureDate'),
        (value: Date) => value <= roundMinutes(utcToZonedTime(new Date(), timezone)),
      ),
    donationTargetAmount: Yup.mixed()
      .required(t('common.validation.required'))
      .test(
        'isInRange',
        t('common.validation.greaterThanOne'),
        (value?: number) => (value ?? 0) >= 1,
      ),
  });
};

type Props = {
  user: TLoggedUser | null;
  onSubmit: (values: TDonationFormValues) => Promise<void>;
  onDiscard: () => void;
  categories: TCategories;
  donation?: TDonationDetail;
  institution: TInstitution;
  userInstitutions: TInstitutionListItem[];
  institutionCenter: TMapCenter;
  donationFormActions: TableIconProps[];
};

const DonationEdit: FC<Props> = ({
  user,
  onSubmit,
  onDiscard,
  donation,
  categories,
  institution,
  userInstitutions,
  institutionCenter,
  donationFormActions,
}) => {
  const now = new Date();
  const isEdit = donation !== undefined;
  const cantSchedule =
    donation?.publishmentState === State.Published ||
    donation?.publishmentState === State.Unpublished;
  const { isLocalBusiness } = useAuthInfo();
  const categoriesOptions = getCategoriesOptions(categories);
  const initialCategories = useMemo(() => {
    if (isLocalBusiness) {
      return [{ label: institution?.category.name || '', value: institution?.category.id || '' }];
    }

    return getInitialCategoriesOptions(categoriesOptions, donation?.categories);
  }, [isLocalBusiness, categoriesOptions, donation, institution]);

  const categoriesRef = useRef<TSelectRef>();
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const institutionLang = institution?.lang || undefined;
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;
  const countryCode = places?.[0].countryCode || '';

  const [isScheduling, setScheduling] = useState(!!donation?.scheduledAt && !cantSchedule);

  const institutionOptions = useMemo(
    () =>
      getInstitutionInitValues(
        user?.attributes?.['custom:institutionUuids'],
        institution,
        userInstitutions,
      ),
    [userInstitutions, institution, user?.attributes],
  );

  const placesOptions = useMemo(() => getPlaceOptions(institution?.places), [institution]);

  const currencyOptions = Object.values(Currency).map((value) => ({
    label: value,
    value: value,
  }));

  const { publisherChanged, onPublisherChange } = useChangePublisher({ userInstitutions });

  useEffect(() => {
    if (!publisherChanged) {
      return;
    }
    setValue('categories', initialCategories);
    categoriesRef?.current?.setValue(initialCategories);
    trigger('places');
  }, [publisherChanged]);

  const initialValues: TDonationFormValues = {
    title: donation?.title || '',
    content: donation?.content || SERIALIZED_WYSIWYG_EMPTY_VALUE,
    lang: getLangOptionByVal(donation?.lang || institutionLang, countryCode),
    images: donation?.imageObjects,
    videos: donation?.videoObjects,
    categories: initialCategories,
    places: getPlaceOptions(donation?.places),
    publisher: { label: institution?.name || '', value: institution?.id || '' },
    scheduledAt:
      donation?.scheduledAt && !cantSchedule
        ? utcToZonedTime(donation?.scheduledAt, timezone)
        : undefined,
    notifyNow: isEdit ? !!donation?.notifyNow : false,
    notifications: donation?.notifications
      ? donation?.notifications.map((notification) => ({
          value: utcToZonedTime(notification, timezone),
        }))
      : [],
    publishedAt: donation?.publishedAt
      ? utcToZonedTime(donation?.publishedAt, timezone)
      : utcToZonedTime(now, timezone),
    notifyAlsoNonCategoried: donation?.notifyAlsoNonCategoried || false,
    areaOfInterest: getAreaOfInterest(donation?.areaOfInterest),
    donationCurrency: donation?.donationCurrency
      ? { label: donation.donationCurrency, value: donation.donationCurrency }
      : currencyOptions[0],
    donationTargetAmount: donation?.donationTargetAmount
      ? donation.donationTargetAmount.toString()
      : '',
  };

  const shouldShowCitySelect = (placesOptions || []).length > 1;

  const methods = useForm<TDonationFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, timezone, isScheduling, shouldShowCitySelect)),
    mode: 'all',
  });

  const { handleSubmit, register, formState, setValue, getValues, trigger, control } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const [
    previewTitle,
    previewContent,
    previewLang,
    previewImages,
    previewVideos,
    previewPublisher,
    previewAreaOfInterest,
  ] = useWatch({
    name: ['title', 'content', 'lang', 'images', 'videos', 'publisher', 'areaOfInterest'],
    control,
  });

  const { onDateTimeChanged: onScheduleDateTimeChanged } = useDateTimeChanged(
    'scheduledAt',
    setValue,
    trigger,
  );

  const { onDateTimeChanged: onPublishedAtDateTimeChanged } = useDateTimeChanged(
    'publishedAt',
    setValue,
    trigger,
  );

  const initialPublishedAtDate = initialValues.publishedAt || utcToZonedTime(now, timezone);

  const initialScheduleDate = initialValues.scheduledAt || roundMinutes(initialPublishedAtDate);

  const onSchedulingSectionVisibilityChanged = () => {
    setValue(
      'scheduledAt',
      !isScheduling ? roundMinutes(utcToZonedTime(now, timezone)) : undefined,
    );
    setScheduling(!isScheduling);
    trigger('scheduledAt');
  };

  const onNotificationsEnabledChange = (value: boolean) => {
    setValue('notifyNow', value);
  };

  const onNotifyAlsoNonCategoriedChange = (value: boolean) => {
    setValue('notifyAlsoNonCategoried', value);
  };

  const onAreaOfInterestVisibilityChanged = useCallback((value: boolean) => {
    if (!value) {
      setValue('areaOfInterest', null);
      return;
    }
    const radius = getMeters(DEFAULT_RADIUS_MILES_STRING);
    const hexagonPolygonPaths = generateCirclePolygonPaths(institutionCenter, radius);
    const newAreaOfInterest = generatePolygon(hexagonPolygonPaths);
    setValue('areaOfInterest', previewAreaOfInterest ?? newAreaOfInterest);
  }, []);

  const onAreaOfInterestChange = (value: TGeoShape | null) => {
    setValue('areaOfInterest', value);
  };

  useEffect(() => {
    trigger('scheduledAt');
  }, [isScheduling, trigger]);

  const submit = async (values: TDonationFormValues) =>
    onSubmit(prepareFormValues(values, isScheduling, timezone));

  const onSubmitClick = () => {
    methods.control._updateProps({
      resolver: yupResolver(schema(t, timezone, isScheduling, shouldShowCitySelect)),
    });

    handleSubmit(submit)();
  };

  const pageTitle = donation
    ? t('donations.form.editPageTitle')
    : t('donations.form.createPageTitle');

  const showPublishers = institutionOptions?.length > 1;

  const { youtubeModal, hasYTVideo } = useYTVideoInEditor(JSON.parse(getValues('content')));

  useEffect(() => {
    if (hasYTVideo) {
      setValue('images', []);
      setValue('videos', []);
    }
  }, [hasYTVideo]);

  const showImage = !previewVideos?.length && !hasYTVideo;
  const showVideo = !previewImages?.length && !hasYTVideo;

  useEffect(() => {
    if (!showImage) {
      setValue('images', []);
    }
  }, [showImage]);

  useEffect(() => {
    if (!showVideo) {
      setValue('videos', []);
    }
  }, [showVideo]);

  const minScheduledDate = roundMinutes(getValues('publishedAt') || utcToZonedTime(now, timezone));

  return (
    <PageContentEditable
      previewTitle={t('donations.preview.title')}
      preview={
        <DonationsPreview
          title={previewTitle}
          images={previewImages}
          lang={previewLang}
          videos={previewVideos}
          content={previewContent}
          publisher={previewPublisher}
        />
      }
    >
      {youtubeModal}
      <FormWrapper>
        <ActionButtons actionButtons={donationFormActions} />
        <HeaderWrapper>
          <ContentHeaderWrapper>
            <ContentHeader noPadding>{pageTitle}</ContentHeader>
          </ContentHeaderWrapper>
        </HeaderWrapper>
        <FormProvider {...methods}>
          <FormInput
            label={t('updates.form.title')}
            {...register('title')}
            error={errors.title}
            testId="DonationEdit#title"
          />

          <FormWysiwyg
            control={control}
            name="content"
            label={t('updates.form.content')}
            initialValue={JSON.parse(getValues('content'))}
            testId="DonationEdit#wysiwyg"
          />
          <FormSectionHeader
            title={t('updates.form.language')}
            description={t('updates.form.languageDescription')}
          />

          <FormSelect
            control={control}
            name="lang"
            options={getLangOptions()}
            defaultValue={initialValues.lang}
            testId={'Announcement#languageSlect'}
            allSelectable
          />
          {showPublishers && (
            <>
              <FormSectionHeader
                title={t('updates.form.publisher')}
                description={t('donations.form.publisherDescription')}
              />
              <FormSelect
                control={control}
                name="publisher"
                error={get(errors, 'publisher')}
                options={institutionOptions || []}
                defaultValue={initialValues.publisher}
                onChange={onPublisherChange}
                testId="DonationEdit#publisher"
              />
            </>
          )}
          {!isLocalBusiness && (
            <>
              <FormSectionHeader
                title={t('updates.form.category')}
                description={t('donations.form.categoryDescription')}
              />
              <FormSelect
                control={control}
                name="categories"
                error={get(errors, 'categories')}
                options={categoriesOptions}
                defaultValue={initialValues.categories}
                ref={categoriesRef}
                testId="DonationEdit#categories"
                disabled={isLocalBusiness}
                isMulti
              />
            </>
          )}

          {shouldShowCitySelect && (
            <>
              <FormSectionHeader
                title={t('updates.form.cities')}
                description={t('updates.form.citiesDescription')}
              />
              <FormSelect
                control={control}
                name="places"
                error={get(errors, 'places')}
                options={placesOptions || []}
                defaultValue={initialValues.places}
                testId="DonationEdit#places"
                allSelectable
                clearable
                isMulti
              />
            </>
          )}
          {showImage && (
            <FormImageCropperUpload
              initialValue={initialValues.images}
              title={t('updates.form.imageLabel')}
              description={t('updates.form.imageSublabel')}
              control={control}
              setValue={setValue}
              getValues={getValues}
              trigger={trigger}
              name="images"
              dropzoneLabel={t('updates.form.dropzoneLabel')}
              t={t}
              multiple
              testId="DonationEdit#image"
            />
          )}
          {showVideo && (
            <>
              <FormSectionHeader
                title={t('updates.form.videoLabel')}
                description={t('updates.form.videoSublabel')}
              />

              <FormUpload
                control={control}
                name="videos"
                dropzoneLabel={t('updates.form.dropzoneLabel')}
                t={t}
                testId="DonationEdit#video"
                accept={SUPPORTED_VIDEO_FORMATS}
              />
            </>
          )}
          <FormSectionWithSwitch
            title={t('updates.form.notifyAll')}
            description={t('updates.form.notifyAllDescription')}
            testId="DonationEdit#notifyAll"
            initialValue={initialValues.notifyAlsoNonCategoried ?? false}
            onChange={onNotifyAlsoNonCategoriedChange}
          />
          <SwitchableFormSection
            title={t('donation.form.scheduleSection')}
            description={t('donation.form.scheduleSectionDescription')}
            onVisibilityChanged={onSchedulingSectionVisibilityChanged}
            initiallyOpened={isScheduling}
            disabled={cantSchedule}
            disabledText={t('updates.form.disabledShedule')}
            testId={'DonationsSchedule#switch'}
          >
            <SwitchableSectionContentWrapper>
              <FormDateTimePicker
                control={control}
                name="scheduledAt"
                initialDate={initialScheduleDate}
                minDate={minScheduledDate}
                onChange={onScheduleDateTimeChanged}
                dateLabel=""
                timeLabel=""
                initialTime={format(initialScheduleDate, 'hh:mm')}
                initialDayPeriod={format(initialScheduleDate, 'a')}
                testId="DonationSchedule#date"
              />
            </SwitchableSectionContentWrapper>
          </SwitchableFormSection>
          <SwitchableFormSection
            title={t('updates.form.areaOfInterest')}
            description={t('updates.form.areaOfInterestDescription')}
            initiallyOpened={!!previewAreaOfInterest}
            onVisibilityChanged={onAreaOfInterestVisibilityChanged}
          >
            <SwitchableSectionContentWrapper>
              <AnnouncementAreaOfInterest
                initalValue={initialValues.areaOfInterest || null}
                currentValue={previewAreaOfInterest || null}
                onChange={onAreaOfInterestChange}
                center={institutionCenter}
              />
            </SwitchableSectionContentWrapper>
          </SwitchableFormSection>

          <DonationsNotificationSection
            control={control}
            notifyNow={initialValues.notifyNow}
            publishmentState={donation?.publishmentState}
            isEdit={isEdit}
            onNotificationsEnabledChange={onNotificationsEnabledChange}
          />
          <FormSectionHeader
            title={t('donations.form.donationTargetAmount')}
            description={t('donations.form.donationTargetAmountDescription')}
          />
          <FormInput
            placeholder={t('donations.form.targetAmountPlaceholder')}
            {...register('donationTargetAmount')}
            error={errors.donationTargetAmount}
            testId="DonationEdit#donationTargetAmount"
          />
          <FormSectionHeader
            title={t('donations.form.currency')}
            description={t('donations.form.currencyDescription')}
          />
          <FormSelect
            control={control}
            name="donationCurrency"
            error={get(errors, 'donationCurrency')}
            options={currencyOptions || []}
            defaultValue={initialValues.donationCurrency}
            disabled={!!donation}
            testId="DonationEdit#donationCurrency"
          />
          <FormSectionHeader title={t('updates.form.publishedAt')} />
          <FormDateTimePicker
            control={control}
            name="publishedAt"
            initialDate={initialPublishedAtDate}
            onChange={onPublishedAtDateTimeChanged}
            dateLabel=""
            timeLabel=""
            initialTime={format(initialPublishedAtDate, 'hh:mm')}
            maxDate={roundMinutes(utcToZonedTime(now, timezone))}
            initialDayPeriod={format(initialPublishedAtDate, 'a')}
            testId="DonationEdit#publishedAt"
            isTimePickerWritable
          />
          <FormButtons
            isSubmitting={isSubmitting}
            getValues={getValues}
            errors={errors}
            publishmentState={donation?.publishmentState}
            isScheduling={isScheduling}
            onDiscard={onDiscard}
            onSubmitClick={onSubmitClick}
          />
        </FormProvider>
      </FormWrapper>
      <RouteLeavingGuard when={isDirty && !isSubmitting} />
    </PageContentEditable>
  );
};

export default DonationEdit;
