export enum TSelectedMapAreaShape {
  POLYGON = 'Polygon',
  CIRCLE = 'Circle',
}

export type TGeoShapeCircle = {
  type: TSelectedMapAreaShape.CIRCLE;
  coordinates: number[];
  radius: string;
};

export type TGeoShapePolygon = {
  type: TSelectedMapAreaShape.POLYGON;
  coordinates: number[][][];
  radius: null;
};

export type TGeoShape = TGeoShapeCircle | TGeoShapePolygon;

export type TMapCenter = { lat: number; lng: number };
