import React, { FC } from 'react';
import styled from 'styled-components';
import { Tag } from '@sim-admin-frontends/ui-shared';

import { TEST_ID_PAGE_PREVIEW_PREFIX } from '../layout/pageContent/PageContentPreview';
import { getTestId } from '../../utils/testUtils';

const Category = styled.div`
  margin: ${({ theme }) => theme.spaces.spacing24} ${({ theme }) => theme.spaces.spacing8} 0 0;
  display: inline-table;
`;

interface PreviewItemCategoryProps {
  label: string;
  testIdPrefix?: string;
}

const PreviewItemCategory: FC<PreviewItemCategoryProps> = ({
  label,
  testIdPrefix = TEST_ID_PAGE_PREVIEW_PREFIX,
}) => (
  <Category>
    <Tag label={label} testId={getTestId(testIdPrefix, 'Category')} />
  </Category>
);

export default PreviewItemCategory;
