/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  AnnouncementsOrderByInput,
  AnnouncementSortBy,
  SortOrder,
  State,
  TAnnouncementListItem,
} from '@sim-admin-frontends/data-access';
import { formatTableDate, formatTableDateTime } from '@sim-admin-frontends/utils-shared';
import { TFunction } from 'i18next';
import { CellProps, SortingRule } from 'react-table';
import {
  TableHeaderTitle,
  TExtendedColumn,
  ClickableCopyText,
} from '@sim-admin-frontends/ui-shared';

import { NEWS_CATEGORY_NAME } from '../../../constants/Constants';
import {
  getDisabledColumns,
  getPublishingDateAccessor,
  isEmergency,
  parseRichText,
} from '../../../utils/announcementsUtils';
import EmergencyTableMenu from '../../emergency/view/EmergencyTableMenu';
import { AnnouncementsTableMenu } from './AnnouncementsTableMenu';
import { TAnnouncementTableItem } from '../../../types/TAnnouncements';
import { getUnpublishCheckbox } from '../../../utils/publishUtils';
import { getPostLink } from '../../../utils/postUtils';

export const createTableColumns = (
  t: TFunction,
  refetch: () => void,
  type: State,
  timeZone?: string,
  postsToUnpublish?: string[],
  onCheckboxChange?: (id: string) => () => void,
) => {
  const disabledColumns = getDisabledColumns(type);

  const columns: TExtendedColumn<TAnnouncementTableItem>[] = [
    {
      Header: `${t('updates.table.title')}`,
      accessor: (item) => (
        <TableHeaderTitle
          item={item.title || parseRichText(item?.content ?? '')}
          maxTextLength={100}
        />
      ),
      testId: 'AnnouncementsTable#title',
      disabled: disabledColumns.includes('title'),
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('updates.table.published')}`,
      // @ts-ignore - No no typescript i know better - hopefully :)
      accessor: getPublishingDateAccessor(type),
      testId: 'AnnouncementsTable#publicationDate',
      Cell: disabledColumns.includes('publishedAt')
        ? ''
        : ({ value }: any) => (value ? formatTableDateTime(value, timeZone) : ''),
      disabled: disabledColumns.includes('publishedAt'),
      disableSortBy: disabledColumns.includes('publishedAt'),
      skeleton: {
        width: 100,
      },
    },

    {
      Header: `${t('updates.table.lastUpdate')}`,
      accessor: 'updatedAt',
      testId: 'AnnouncementsTable#lastUpdate',
      Cell: disabledColumns.includes('updatedAt')
        ? ''
        : ({ value }) => (value ? formatTableDate(value) : ''),
      disabled: disabledColumns.includes('updatedAt'),
      disableSortBy: disabledColumns.includes('updatedAt'),
      skeleton: {
        width: 100,
      },
    },

    {
      Header: `${t('updates.table.source')}`,
      accessor: 'createdByAdmin',
      testId: 'AnnouncementsTable#source',
      Cell: disabledColumns.includes('createdByAdmin')
        ? ''
        : ({ value }) => (value ? t('updates.table.original') : t('updates.table.scraped')),
      disabled: disabledColumns.includes('createdByAdmin'),
      disableSortBy: true,
      skeleton: {
        width: 60,
      },
    },

    {
      Header: `${t('updates.table.categories')}`,
      accessor: 'categories',
      testId: 'AnnouncementsTable#categories',
      Cell: disabledColumns.includes('categories')
        ? ''
        : ({ value }) => {
            if (!value?.length) {
              return null;
            }

            return (
              value
                .slice(0, 5)
                .filter((i) => i.name !== NEWS_CATEGORY_NAME)
                .map((i) => i.name) +
              (value.length > 5 ? `, (${t('common.more').toLowerCase()})` : '')
            );
          },
      disabled: disabledColumns.includes('categories'),
      disableSortBy: true,
      skeleton: {
        width: 110,
      },
    },
    {
      Header: `${t('updates.table.link')}`,
      accessor: 'id',
      testId: 'AnnouncementsTable#link',
      Cell: disabledColumns.includes('link')
        ? ''
        : ({ value }) => {
            const { link, label } = getPostLink(value);
            return <ClickableCopyText value={label} valueToCopy={link} />;
          },
      disabled: disabledColumns.includes('link'),
      disableSortBy: true,
      skeleton: {
        width: 60,
      },
    },
    {
      Header: '',
      id: '0',
      testId: 'AnnouncementsTable#dots',
      disableSortBy: true,
      minWidth: 100,
      skeleton: {
        width: 50,
        align: 'right',
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ row }: CellProps<TAnnouncementTableItem>) => {
        const isPublished = row.original.publishmentState === State.Published;
        const isEmergencyAlert = isEmergency(row.original.category);
        if (isEmergencyAlert) {
          const isPinned = row.original.publishmentState === State.Pinned;
          return (
            <EmergencyTableMenu
              postId={row.original.id}
              refetch={refetch}
              pinned={isPinned}
              testId={`EmergencyView#TableMenu${row.index}-${row.original.id}`}
            />
          );
        }
        return (
          <AnnouncementsTableMenu
            type={type}
            postId={row.original.id}
            isFromAdmin={row.original.createdByAdmin}
            published={isPublished}
            refetch={refetch}
            testId={`AnnouncementsView#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  getUnpublishCheckbox<TAnnouncementTableItem, TExtendedColumn<TAnnouncementTableItem>[]>(
    columns,
    'AnnouncementsTable',
    postsToUnpublish,
    onCheckboxChange,
  );

  return columns;
};

export const getInitialGqlSorting = (type?: State): AnnouncementsOrderByInput => {
  switch (type) {
    case State.Unpublished:
    case State.Draft:
      return {
        by: AnnouncementSortBy.UpdatedAt,
        order: SortOrder.Desc,
      };
    case State.Scheduled:
      return {
        by: AnnouncementSortBy.ScheduledAt,
        order: SortOrder.Asc,
      };
    case State.Published:
      return {
        by: AnnouncementSortBy.PublishedAt,
        order: SortOrder.Desc,
      };

    default:
      return {
        by: AnnouncementSortBy.CreatedAt,
        order: SortOrder.Desc,
      };
  }
};

export const getInitialTableSorting = (type: State): SortingRule<TAnnouncementListItem>[] => {
  switch (type) {
    case State.Unpublished:
    case State.Draft:
      return [
        {
          id: 'updatedAt',
          desc: true,
        },
      ];
    case State.Published:
      return [
        {
          id: 'publishedAt',
          desc: true,
        },
      ];
    case State.Scheduled:
      return [
        {
          id: 'scheduledAt',
          desc: false,
        },
      ];

    default:
      return [];
  }
};

export const getGqlSorting = (
  tableSorting: SortingRule<TAnnouncementListItem>[],
  type?: State,
): AnnouncementsOrderByInput => {
  if (!tableSorting || tableSorting.length !== 1) {
    return getInitialGqlSorting(type);
  }
  const rule = tableSorting[0];

  switch (rule.id) {
    case 'createdAt':
      return {
        by: AnnouncementSortBy.CreatedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    case 'updatedAt':
      return {
        by: AnnouncementSortBy.UpdatedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    case 'publishedAt':
      return {
        by: AnnouncementSortBy.PublishedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    case 'scheduledAt':
      return {
        by: AnnouncementSortBy.ScheduledAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    default:
      return getInitialGqlSorting(type);
  }
};
