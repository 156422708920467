import { Controller, FieldError, FieldValues } from 'react-hook-form';

import { TBaseProps } from '../../types/TBaseComponent';
import { TFormControlElement } from '../../types/TForm';
import {
  LocationInput,
  LocationInputProps,
  LocationInputValue,
} from '../location-input/LocationInput';

export interface FormLocationInputProps<T>
  extends LocationInputProps,
    TFormControlElement<T>,
    TBaseProps {
  error?: FieldError;
}

const FormLocationInput = <T extends FieldValues>({
  control,
  name,
  onValueChange,
  error,
  ...rest
}: FormLocationInputProps<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange: onChangeField } }) => {
      const onChangeMerged = (value: LocationInputValue) => {
        onChangeField(value);
        onValueChange(value);
      };
      return <LocationInput {...rest} error={error} onValueChange={onChangeMerged} />;
    }}
  ></Controller>
);

export { FormLocationInput };
