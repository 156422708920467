import { useState } from 'react';
import {
  Error as ErrorComponent,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { generatePath, useHistory } from 'react-router-dom';

import usePostGenerator from '../../hooks/usePostGenerator';
import PostGenerator from './PostGenerator';
import { TPostAIFormValues } from '../../types/TPostGenerator';
import ROUTES from '../../routing/routes';
import { POST_GENERATOR_TOAST_SHARED_ID } from '../../constants/Constants';

const PostGeneratorContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [postContent, setPostContent] = useState('');
  const { isLoading, isError, refetch, fetchPost } = usePostGenerator();

  const onSubmit = async (values: TPostAIFormValues) => {
    try {
      loadingToast(t('postGenerator.form.generatingPost'), {
        toastId: POST_GENERATOR_TOAST_SHARED_ID,
      });
      const data = await fetchPost(values.input, values.postType);
      const postContentData = data?.generatePost?.PostContent;

      if (!postContentData) {
        throw new Error('No GeneratePostQuery PostContent response');
      }
      setPostContent(postContentData.trimStart());
      updateToast(
        POST_GENERATOR_TOAST_SHARED_ID,
        t('postGenerator.form.successful'),
        TToastType.SUCCESS,
      );
    } catch (err) {
      updateToast(POST_GENERATOR_TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const navigateToAnnouncement = (values: TPostAIFormValues) => {
    history.push(generatePath(ROUTES.announcementsEdit.path), {
      postContent: values.output,
    });
  };

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return (
      <SpinnerWrapper>
        <ErrorComponent caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  return (
    <PostGenerator
      onSubmit={onSubmit}
      postContent={postContent}
      navigateToAnnouncement={navigateToAnnouncement}
    />
  );
};

export default PostGeneratorContainer;
