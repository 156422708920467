import {
  CircleType,
  GeoShapeCircleInput,
  GeoShapeInput,
  GeoShapePolygonInput,
  PolygonType,
} from '@sim-admin-frontends/data-access';

import { TSelectedMapAreaShape, TGeoShape } from '../types/TGeoShape';

export const getSelectedMapArea = (
  selectedMapArea: TGeoShape | null | undefined,
): GeoShapeInput | null | undefined => {
  if (!selectedMapArea) {
    return null;
  }
  if (selectedMapArea?.type === TSelectedMapAreaShape.CIRCLE) {
    const circle: GeoShapeCircleInput = {
      type: CircleType.Circle,
      radius: selectedMapArea.radius,
      coordinates: selectedMapArea.coordinates,
    };
    return { circle };
  } else if (selectedMapArea?.type === TSelectedMapAreaShape.POLYGON) {
    const polygonCoordinates = selectedMapArea.coordinates[0];
    const polygon: GeoShapePolygonInput = {
      type: PolygonType.Polygon,
      coordinates: [[...polygonCoordinates, polygonCoordinates[0]]],
    };
    return { polygon };
  }

  return null;
};
