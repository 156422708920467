import { FC } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { Fonts, GlobalStyles } from '../globalStyles/GlobalStyles';
import theme from './theme';

import '../../types/styled.d';

const ThemeProvider: FC = ({ children }) => (
  <StyledThemeProvider theme={theme}>
    <Fonts />
    <GlobalStyles />
    {children}
  </StyledThemeProvider>
);

export { ThemeProvider };
