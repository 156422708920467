import { FC } from 'react';
import styled from 'styled-components';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { Error, Navbar, Sider, VideoPlayerIcon } from '@sim-admin-frontends/ui-shared';
import { useGetServiceInfoQuery } from '@sim-admin-frontends/data-access';

import {
  HEADER_HEIGHT_PX,
  SIDER_COLLAPSED_WIDTH_PX,
  SIDER_WIDTH_PX,
} from '../../constants/Constants';
import useAnalytics from '../../hooks/useAnalytics';
import { ContactUs } from '../contact-us/ContactUs';
import InstitutionHeader from './header/InstitutionHeader';
import Header from './header/Header';
import SideMenu from './SideMenu';
import SideMenuItem from './SideMenuItem';
import { useAuthInfo } from '../../contexts/userContext';
import ROUTES from '../../routing/routes';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const Version = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.menuBackground};
  padding: 0 ${({ theme }) => theme.spaces.spacing16};
  text-align: center;
`;

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  overflow: auto;
  flex: 1;
  position: relative;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const BottomSidebarWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.spacing8};
  border-top: 1px solid ${({ theme }) => theme.colors.coolGray20};
`;

const ContactUsWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.spacing24};
`;

const ErrorWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
`;

const Layout: FC = ({ children }) => {
  useAnalytics();
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const { data } = useGetServiceInfoQuery();
  const adminVersion = `Admin: ${process.env.NX_IA_ADMIN_VERSION}`;
  const beVersion = `BE: ${data?.service?.version || 'N/A'}`;
  const tutorialItem = {
    label: t('mainMenu.videoTutorials'),
    icon: <VideoPlayerIcon />,
    path: ROUTES.videoTutorials.path,
    testId: 'mainMenu#videoTutorials',
  };

  return (
    <Wrapper>
      <Sider
        width={SIDER_WIDTH_PX}
        collapsedWidth={SIDER_COLLAPSED_WIDTH_PX}
        headerHeight={HEADER_HEIGHT_PX}
        header={<InstitutionHeader />}
      >
        <SideMenu />
        <BottomSidebarWrapper>
          <SideMenuItem
            item={tutorialItem}
            institutionUuid={institutionUuid}
            key={tutorialItem.label}
          />
          <ContactUsWrapper>
            <ContactUs />
          </ContactUsWrapper>
        </BottomSidebarWrapper>
        <Version>{`${adminVersion} • ${beVersion}`}</Version>
      </Sider>
      <RightWrapper>
        <Navbar headerHeight={HEADER_HEIGHT_PX}>
          <Header />
        </Navbar>
        <ErrorBoundary
          fallback={() => (
            <ContentWrapper>
              <ErrorWrapper>
                <Error caption={t('error.globalError')} onClick={() => window.location.reload()} />
              </ErrorWrapper>
            </ContentWrapper>
          )}
        >
          <ContentWrapper>{children}</ContentWrapper>
        </ErrorBoundary>
      </RightWrapper>
    </Wrapper>
  );
};

export default Layout;
