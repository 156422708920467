import { FC } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../../types/TBaseComponent';

const TRANSITION_DURATION = 0.15;

const Wrapper = styled.div<{ checked: boolean; $disabled: boolean }>`
  width: ${({ theme }) => theme.spaces.spacing6};
  height: ${({ theme }) => theme.spaces.spacing6};
  background-color: ${({ theme, checked, $disabled }) =>
    $disabled ? theme.colors.coolGray10 : checked ? theme.colors.blue50 : theme.colors.coolGray30};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  padding: ${({ theme }) => theme.spaces.spacing6};
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
  transition: background-color ${TRANSITION_DURATION}s ease-in-out;
`;

const Dot = styled.div<{ checked: boolean }>`
  width: ${({ theme }) => theme.spaces.spacing6};
  height: ${({ theme }) => theme.spaces.spacing6};
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.white : theme.colors.coolGray30};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  transition: background-color ${TRANSITION_DURATION}s ease-in-out;
`;

export interface RadioButtonProps extends TBaseProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const RadioButton: FC<RadioButtonProps> = ({ value, onChange, testId, disabled }) => {
  const onClick = () => {
    if (!disabled) {
      onChange(!value);
    }
  };
  return (
    <Wrapper checked={value} $disabled={!!disabled} onClick={onClick} data-testid={testId}>
      <Dot checked={value} data-testid={`${testId}-Dot`} />
    </Wrapper>
  );
};

export { RadioButton };
