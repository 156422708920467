import {
  useInfiniteGraphQLQuery,
  PetsByFilter,
  PetsOrderBy,
  PetsSortBy,
  SortOrder,
  PetsByQuery,
  usePetsByQuery,
} from '..';
import { PetsInPlaceByFilter, PetsInPlaceByQuery, usePetsInPlaceByQuery } from '../graphql/graphql';

export const DEFAULT_PETS_ORDER_BY = {
  by: PetsSortBy.CreatedAt,
  order: SortOrder.Desc,
};

export const useInfinitePetsByQuery = (
  filter: PetsByFilter,
  orderBy: PetsOrderBy = DEFAULT_PETS_ORDER_BY,
) => {
  const getNextPageParam = (lastPage: PetsByQuery) => lastPage.petsBy.nextToken;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    orderBy,
    nextToken: pageParam,
    filter,
  });

  return useInfiniteGraphQLQuery(usePetsByQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
  });
};

export const useInuseInfinitePetsInPlaceByQuery = (
  filter: PetsInPlaceByFilter,
  orderBy: PetsOrderBy = DEFAULT_PETS_ORDER_BY,
) => {
  const getNextPageParam = (lastPage: PetsInPlaceByQuery) => lastPage.petsInPlaceBy.nextToken;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    orderBy,
    nextToken: pageParam,
    filter,
  });

  return useInfiniteGraphQLQuery(usePetsInPlaceByQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
  });
};
