import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Modal, LargeText, Button, Caption1 } from '@sim-admin-frontends/ui-shared';

import ROUTES from '../../routing/routes';

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spaces.spacing24};
  flex-direction: column;
  width: 320px;
`;

const Headline = styled(LargeText)`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const Text = styled(Caption1)`
  padding-top: ${({ theme }) => theme.spaces.spacing8};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  white-space: pre-line;
`;

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spaces.spacing24};
  width: 100%;
`;

type Props = {
  username: string;
  isOpen: boolean;
  onClose: () => void;
};

const ForgotPasswordEmailInfoModal: FC<Props> = ({ username, isOpen, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onButtonClick = () => {
    history.push(
      generatePath(ROUTES.resetPassword.path, {
        username: encodeURIComponent(username),
      }),
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Wrapper>
        <Headline>{t('forgotPassword.modal.headline')}</Headline>
        <Text>{t('forgotPassword.modal.text', { username })}</Text>
        <StyledButton onClick={onButtonClick}>
          {t('forgotPassword.modal.continueButton')}
        </StyledButton>
      </Wrapper>
    </Modal>
  );
};

export default ForgotPasswordEmailInfoModal;
