import { Button } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TGeneratorPostType, TPostAIFormValues } from '../../types/TPostGenerator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spaces.spacing48} 0;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.heading1};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
  margin-bottom: ${({ theme }) => theme.spaces.spacing20};
`;

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spaces.spacing16};
  padding: ${({ theme }) => theme.spaces.spacing12} 0;
  width: 140px;
`;

type PostGeneratorTypePickerProps = {
  methods: UseFormReturn<TPostAIFormValues>;
};

const PostGeneratorTypePicker: FC<PostGeneratorTypePickerProps> = ({ methods }) => {
  const { t } = useTranslation();
  const { setValue, control } = methods;
  const [watchedType] = useWatch({ name: ['postType'], control });

  const onTypeClick = (type: TGeneratorPostType) => () => {
    setValue('postType', type);
  };

  const getButtonVariant = (type: TGeneratorPostType) =>
    watchedType === type ? 'dark' : 'bordered';

  return (
    <Container>
      <Header>{t('postGenerator.form.postType.title')}</Header>
      <Wrapper>
        <StyledButton
          size="smaller"
          variant={getButtonVariant(TGeneratorPostType.PRESS_RELEASE)}
          onClick={onTypeClick(TGeneratorPostType.PRESS_RELEASE)}
          testId="PostGenerator#press-release"
        >
          {t('postGenerator.form.postType.pressRelease')}
        </StyledButton>
        <StyledButton
          size="smaller"
          variant={getButtonVariant(TGeneratorPostType.ANNOUNCEMENT)}
          onClick={onTypeClick(TGeneratorPostType.ANNOUNCEMENT)}
          testId="PostGenerator#announcement"
        >
          {t('postGenerator.form.postType.announcement')}
        </StyledButton>
        <StyledButton
          size="smaller"
          variant={getButtonVariant(TGeneratorPostType.TWITTER_POST)}
          onClick={onTypeClick(TGeneratorPostType.TWITTER_POST)}
          testId="PostGenerator#twitter-post"
        >
          {t('postGenerator.form.postType.twitterPost')}
        </StyledButton>
      </Wrapper>
    </Container>
  );
};

export default PostGeneratorTypePicker;
