import { useEffect, useState } from 'react';

const useResizeWidth = (smallWidth: number, mediumWidth: number, largeWidth: number) => {
  const [width, setWidth] = useState(largeWidth);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < mediumWidth) {
        setWidth(smallWidth);
      } else if (window.innerWidth < largeWidth) {
        setWidth(mediumWidth);
      } else {
        setWidth(largeWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return width;
};

export { useResizeWidth };
