import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormDateTimePicker,
  PlusSmallIcon,
  SwitchableFormSection,
} from '@sim-admin-frontends/ui-shared';
import { State } from '@sim-admin-frontends/data-access';
import { FC, useCallback } from 'react';
import { roundMinutes } from '@sim-admin-frontends/utils-shared';
import { utcToZonedTime } from 'date-fns-tz';
import { addMinutes, format } from 'date-fns';
import styled from 'styled-components';

import { TQuickQuestionsFormValues } from '../../../../types/TQuickQuestions';
import useIsSystemDisabled from '../../../../hooks/useIsSystemDisabled';
import { SwitchableSectionContentWrapper } from '../../../announcements/edit/AnnouncementEditStyles';
import { FALLBACK_TIMEZONE } from '../../../../constants/Constants';
import { usePlaceInfo } from '../../../../contexts/placeContext';

const INITIAL_MINUTES_ADDED = 15;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export type Props = {
  control: Control<TQuickQuestionsFormValues>;
  notifyNow?: boolean;
  publishmentState?: State;
  isEdit: boolean;
  onNotificationsEnabledChange: (value: boolean) => void;
};

const QuickQuestionNotificationsSection: FC<Props> = ({
  control,
  notifyNow,
  publishmentState,
  isEdit,
  onNotificationsEnabledChange,
}) => {
  const { t } = useTranslation();
  const { notificationsDisabled } = useIsSystemDisabled();
  const { places } = usePlaceInfo();
  const name = 'notifications';
  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;

  const onAddClick = () => {
    const now = roundMinutes(
      utcToZonedTime(addMinutes(new Date(), INITIAL_MINUTES_ADDED), timezone),
    );
    append({ value: now });
  };

  const isNotificationDisabled =
    isEdit &&
    !!notifyNow &&
    (publishmentState == State.Published || publishmentState === State.Unpublished);

  const renderFields = useCallback(
    () =>
      fields.map((field, index) => {
        const now = roundMinutes(utcToZonedTime(new Date(), timezone));
        return (
          <ItemWrapper key={field.id}>
            <FormDateTimePicker
              control={control}
              name={`${name}.${index}.value`}
              onCloseClick={() => remove(index)}
              minDate={now}
              initialDate={field.value}
              initialTime={format(field.value, 'hh:mm')}
              initialDayPeriod={format(field.value, 'a')}
              isDisabled={field.value < now}
              testId="AnnouncementNotification#date"
            />
          </ItemWrapper>
        );
      }),
    [fields],
  );

  return (
    <SwitchableFormSection
      title={t('quickQuestion.notification')}
      description={t('quickQuestion.notificationDescription')}
      initiallyOpened={notifyNow}
      onVisibilityChanged={onNotificationsEnabledChange}
      disabled={isNotificationDisabled || notificationsDisabled}
      disabledText={t('updates.form.disabledText')}
      testId={`QuickQuestionNotification#switch`}
    >
      <SwitchableSectionContentWrapper>
        <div>
          {renderFields()}
          <Button
            variant="tertiary"
            size="smallest"
            onClick={onAddClick}
            prependIcon={<PlusSmallIcon />}
            testId="AnnouncementNotification#add"
          >
            {t('updates.form.addNotification')}
          </Button>
        </div>
      </SwitchableSectionContentWrapper>
    </SwitchableFormSection>
  );
};

export default QuickQuestionNotificationsSection;
