import { FC, useMemo, useCallback, useState } from 'react';
import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import {
  AnnouncementsByFilterInput,
  ANNOUNCEMENTS_PAGE_SIZE,
  CategoryType,
  State,
  TPollDraftListItem,
  TPollListItem,
  useCategoriesQuery,
  useInfinitePollDraftsQuery,
} from '@sim-admin-frontends/data-access';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';
import { useOffsetTablePaging } from '@sim-admin-frontends/utils-shared';

import {
  getGqlSorting,
  getInitialGqlSorting,
  getInitialTableSorting,
} from '../../announcements/view/helper';
import { useAuthInfo } from '../../../contexts/userContext';
import { createTableColumns, findPollCategory } from '../../../utils/pollsUtils';
import { PollsView } from './PollsView';
import VideoTutorialBanner from '../../videoTutorials/VideoTutorialBanner';

export interface PollsDraftViewContainerProps {
  wasAnythingPublished: boolean;
}

const PollDraftsViewContainer: FC<PollsDraftViewContainerProps> = ({ wasAnythingPublished }) => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const [orderBy, setOrderBy] = useState(getInitialGqlSorting(State.Draft));
  const institutionId = institutionUuid || '';

  const {
    isLoading: categoriesLoading,
    error: categoriesError,
    data: categories,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.Poll,
    },
  });

  const pollCategory = useMemo(() => findPollCategory(categories), [categories]);

  const filter: AnnouncementsByFilterInput = useMemo(
    () => ({
      institutions: [institutionId],
      state: State.Draft,
      ...(pollCategory && { categories: [pollCategory.id] }),
    }),
    [institutionId, pollCategory],
  );

  const { fetchNextPage, hasNextPage, data, isError, isLoading, isFetchingNextPage, refetch } =
    useInfinitePollDraftsQuery(filter, orderBy);

  const columns = createTableColumns(t, refetch, State.Draft);

  const { currentPageIndex, maxPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } =
    useOffsetTablePaging(fetchNextPage, ANNOUNCEMENTS_PAGE_SIZE, hasNextPage);

  const onSortingChanged = useCallback(
    (newSorting: SortingRule<TPollListItem>[]) => {
      setOrderBy(getGqlSorting(newSorting, State.Draft));
      setCurrentPageIndex(0);
    },
    [setCurrentPageIndex],
  );

  if (isError || categoriesError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const itemsCount = data?.pages[0].announcementsBy.count;

  const polls = data?.pages[currentPageIndex].announcementsBy.announcements.filter(
    (a): a is TPollDraftListItem => {
      return a.__typename === 'AnnouncementDraft';
    },
  );

  const isTutorialBannerVisible = !wasAnythingPublished;

  return (
    <>
      <PollsView
        hasNextPage={hasNextPage || currentPageIndex < maxPageIndex}
        loading={isLoading || isFetchingNextPage || categoriesLoading}
        itemsCount={itemsCount as number}
        pageIndex={currentPageIndex}
        onNextPress={onNextPress}
        onPreviousPress={onPreviousPress}
        columns={columns}
        data={polls ?? []}
        onSortingChanged={onSortingChanged}
        initialTableSorting={getInitialTableSorting(State.Draft)}
      />
      <VideoTutorialBanner
        text={t('videoTutorials.banner.polls')}
        visible={isTutorialBannerVisible}
        testId={'PollDraftsView#VideoTutorialBanner'}
      />
    </>
  );
};

export default PollDraftsViewContainer;
