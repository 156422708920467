import Tippy from '@tippyjs/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TBaseProps } from '../../..';

const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTippy = styled(Tippy)`
  background: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.body};
  .tippy-arrow {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Content = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xxLarge};
  line-height: ${({ theme }) => theme.lineHeights.xxLarge};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  color: ${({ theme }) => theme.colors.coolGray100};
`;

const Percentage = styled.div<{ isPositive: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: ${({ theme }) => theme.lineHeights.small};
  color: ${({ isPositive, theme }) => (isPositive ? theme.colors.green60 : theme.colors.red60)};
  margin: 0 ${({ theme }) => theme.spaces.spacing16};
  margin-top: ${({ theme }) => theme.spaces.spacing4};
  background-color: ${({ isPositive, theme }) =>
    isPositive ? theme.colors.green10 : theme.colors.red10};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  padding: ${({ theme }) => theme.spaces.spacing2} ${({ theme }) => theme.spaces.spacing4};
`;

export interface DashboardItemAnalyticsProps extends TBaseProps {
  actual: number;
  diff: number;
}

const DashboardItemAnalytics: FC<DashboardItemAnalyticsProps> = ({ actual, diff, testId }) => {
  const { t } = useTranslation();
  const period = Math.floor(actual / (1 + diff / 100));
  const getPreviousPeriodValue = () => (
    <span data-testid={`${testId}-Popup`}>{t('dashboard.percentageTooltip', { period })}</span>
  );
  return (
    <ContentWrapper>
      <Content data-testid={`${testId}-Value`}>{actual}</Content>
      <StyledTippy placement="bottom" content={getPreviousPeriodValue()}>
        <Percentage isPositive={diff >= 0} data-testid={`${testId}-SinglePercentage`}>
          {diff >= 0 ? '+' : '-'}
          {diff + '%'}
        </Percentage>
      </StyledTippy>
    </ContentWrapper>
  );
};

export { DashboardItemAnalytics };
