import { FC } from 'react';
import styled from 'styled-components';
import { device, MediumText, SmallText } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${device.md} {
    display: flex;
  }
`;

const Title = styled(MediumText)`
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  margin-bottom: ${({ theme }) => theme.spaces.spacing8};
  line-height: ${({ theme }) => theme.lineHeights.large};
`;

const Subtitle = styled(SmallText)`
  color: ${({ theme }) => theme.colors.lightText};
`;

const ContactUs: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Title>{t('contactUs.title')}</Title>
      <Subtitle>{t('contactUs.description')}</Subtitle>
    </Wrapper>
  );
};

export { ContactUs };
