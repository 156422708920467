import { CellProps, SortingRule } from 'react-table';
import { TFunction } from 'i18next';
import { formatTableDateTime } from '@sim-admin-frontends/utils-shared';
import {
  EventsOrderByInput,
  EventSortBy,
  SortOrder,
  State,
  TEventsListItem,
} from '@sim-admin-frontends/data-access';
import {
  Checkbox,
  ClickableCopyText,
  TableHeaderTitle,
  TExtendedColumn,
} from '@sim-admin-frontends/ui-shared';

import EventsTableMenu from './EventsTableMenu';
import { getPostLink } from '../../../utils/postUtils';

export const createTableColumns = (
  t: TFunction,
  refetch: () => void,
  state: State,
  timeZone?: string,
  postsToUnpublish?: string[],
  onCheckboxChange?: (id: string) => () => void,
) => {
  const columns: TExtendedColumn<TEventsListItem>[] = [
    {
      Header: `${t('events.table.title')}`,
      accessor: (item) => <TableHeaderTitle item={item.title} />,
      disableSortBy: true,
      minWidth: 25,
      testId: 'EventsTable#title',
    },
    {
      Header: `${t('events.table.category')}`,
      accessor: 'category',
      testId: 'EventsTable#category',
      Cell: ({ value }) => value?.name || '',
      disableSortBy: true,
      skeleton: {
        width: 110,
      },
    },
    {
      Header: `${t('events.table.startDate')}`,
      accessor: 'datetimeFrom',
      testId: 'EventsTable#startDate',
      Cell: ({ value }) => (value ? formatTableDateTime(value, timeZone) : ''),
      title: (value: string) =>
        value ? formatTableDateTime(value, timeZone) : t('events.table.unpublished'),
    },
    {
      Header: `${t('events.table.published')}`,
      accessor: 'publishedAt',
      testId: 'EventsTable#publicationDate',
      Cell: (row) =>
        row.value ? formatTableDateTime(row.value, timeZone) : t('events.table.unpublished'),
      title: (value: string) =>
        value ? formatTableDateTime(value, timeZone) : t('events.table.unpublished'),
    },
    {
      Header: `${t('events.table.source')}`,
      accessor: 'createdByAdmin',
      testId: 'EventsTable#source',
      Cell: ({ value }) => (value ? t('events.table.original') : t('events.table.scraped')),
      disableSortBy: true,
      skeleton: {
        width: 90,
      },
    },
    {
      Header: `${t('updates.table.link')}`,
      accessor: 'id',
      testId: 'EventsTable#link',
      Cell: ({ value }) => {
        const { link, label } = getPostLink(value);
        return state === State.Published ? (
          <ClickableCopyText value={label} valueToCopy={link} />
        ) : (
          ''
        );
      },
      disabled: state === State.Unpublished,
      disableSortBy: true,
      skeleton: {
        width: 60,
      },
    },
    {
      Header: '',
      id: '0',
      testId: 'EventsTable#dots',
      disableSortBy: true,
      minWidth: 25,
      skeleton: {
        width: 50,
        align: 'right',
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ row }: CellProps<TEventsListItem>) => {
        return (
          <EventsTableMenu
            eventId={row.original.id}
            isFromAdmin={row.original.createdByAdmin}
            refetch={refetch}
            published={!!row.original.publishedAt}
            testId={`EventsView#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  if (!onCheckboxChange || !postsToUnpublish) {
    return columns;
  }

  columns.splice(0, 0, {
    testId: 'EventsTable#checkBox',
    id: '1',
    disableSortBy: true,
    width: '2%',
    skeleton: {
      width: 50,
    },
    Cell: ({ row }: CellProps<TEventsListItem>) => {
      const isPublished = !!row.original.publishedAt;
      if (!isPublished) {
        return null;
      }
      const isChecked = postsToUnpublish.includes(row.original.id);
      return <Checkbox checked={isChecked} onChange={onCheckboxChange(row.original.id)} />;
    },
  });

  return columns;
};

export const DEFAULT_SORTING_GQL: EventsOrderByInput = {
  by: EventSortBy.PublishedAt,
  order: SortOrder.Desc,
};

export const DEFAULT_SORTING_TABLE: SortingRule<TEventsListItem>[] = [
  {
    id: 'publishedAt',
    desc: true,
  },
];

export const getGqlSorting = (tableSorting: SortingRule<TEventsListItem>[]): EventsOrderByInput => {
  if (!tableSorting || tableSorting.length !== 1) {
    return DEFAULT_SORTING_GQL;
  }
  const rule = tableSorting[0];

  switch (rule.id) {
    case 'publishedAt':
      return {
        by: EventSortBy.PublishedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    case 'datetimeFrom':
      return {
        by: EventSortBy.FromDatetime,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    default:
      return DEFAULT_SORTING_GQL;
  }
};

export const getTableSorting = (gqlSorting: EventsOrderByInput): SortingRule<TEventsListItem>[] => {
  if (!gqlSorting) {
    return [];
  }
  switch (gqlSorting.by) {
    case EventSortBy.PublishedAt:
      return [
        {
          id: 'publishedAt',
          desc: gqlSorting.order === SortOrder.Desc,
        },
      ];
    case EventSortBy.FromDatetime:
      return [
        {
          id: 'datetimeFrom',
          desc: gqlSorting.order === SortOrder.Desc,
        },
      ];
    default:
      return [];
  }
};
