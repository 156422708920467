import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SwitchableFormSection } from '@sim-admin-frontends/ui-shared';
import { State } from '@sim-admin-frontends/data-access';

import useIsSystemDisabled from '../../../hooks/useIsSystemDisabled';
import { TCouponFormValues } from '../../../types/TCoupons';
import { SwitchableSectionContentWrapper } from '../../announcements/edit/AnnouncementEditStyles';
import { CouponNotificationSettings } from './CouponNotificationSettings';

export type CouponNotificationSectionProps = {
  control: Control<TCouponFormValues>;
  notifyNow?: boolean;
  publishmentState?: State;
  isEdit: boolean;
  onNotificationsEnabledChange: (value: boolean) => void;
};

const CouponNotificationSection = ({
  control,
  notifyNow,
  publishmentState,
  isEdit,
  onNotificationsEnabledChange,
}: CouponNotificationSectionProps) => {
  const { t } = useTranslation();
  const { notificationsDisabled } = useIsSystemDisabled();

  const isNotificationDisabled =
    isEdit &&
    !!notifyNow &&
    (publishmentState == State.Published || publishmentState === State.Unpublished);

  return (
    <SwitchableFormSection
      title={t('coupon.form.sendNotificationTitle')}
      description={t('coupon.form.sendNotificationDescription')}
      initiallyOpened={notifyNow}
      onVisibilityChanged={onNotificationsEnabledChange}
      disabled={isNotificationDisabled || notificationsDisabled}
      disabledText={t('updates.form.disabledText')}
      testId={'couponNotification#switch'}
    >
      <SwitchableSectionContentWrapper>
        <CouponNotificationSettings control={control} />
      </SwitchableSectionContentWrapper>
    </SwitchableFormSection>
  );
};

export default CouponNotificationSection;
