import { FC, useMemo } from 'react';
import {
  HornIcon,
  CalendarIcon,
  EmergencyIcon,
  SpinnerWrapper,
  DashboardCardProps,
  Error,
  Spinner,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import {
  DashboardAnalyticsFilterInput,
  useAdminDashboardTopReactionsQuery,
  useAdminDashboardTotalsAnalyticsQuery,
} from '@sim-admin-frontends/data-access';
import { generatePath, useHistory } from 'react-router-dom';

import { generateItems, generateTopPosts } from './helper';
import { DashboardExtraActions, DashboardView } from './DashboardView';
import ROUTES from '../../routing/routes';
import { useAuthInfo } from '../../contexts/userContext';
import { usePlaceInfo } from '../../contexts/placeContext';

const DashboardViewContainer: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();
  const placeId = places?.[0].id;
  const { isCity, visitorMode } = useAuthInfo();

  const analyticFilter: DashboardAnalyticsFilterInput = useMemo(
    () => ({
      placeUuid: placeId ?? '',
      institutionUuid: institutionUuid ?? '',
    }),
    [placeId, institutionUuid],
  );

  const {
    data: topReactions,
    isError: topReactionsIsError,
    isLoading: topReactionsLoading,
    refetch: topReactionsRefetch,
  } = useAdminDashboardTopReactionsQuery({ filter: analyticFilter });

  const {
    data: totalAnalytics,
    isError: totalAnalysisIsError,
    isLoading: totalAnalysisLoading,
    refetch: totalAnalysisRefetch,
  } = useAdminDashboardTotalsAnalyticsQuery({ filter: analyticFilter });

  const isError = topReactionsIsError || totalAnalysisIsError;
  const isLoading = topReactionsLoading || totalAnalysisLoading;

  const refetch = () => {
    if (topReactionsIsError) topReactionsRefetch();
    if (totalAnalysisIsError) totalAnalysisRefetch();
  };

  if (isError || isLoading)
    return (
      <SpinnerWrapper>
        {isLoading ? (
          <Spinner />
        ) : (
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        )}
      </SpinnerWrapper>
    );

  const topPosts: DashboardCardProps[] = generateTopPosts(
    topReactions?.adminDashboardTopReactions?.posts ?? [],
  );

  const extraActions: DashboardExtraActions = [
    {
      icon: <HornIcon />,
      title: t('dashboard.announcementCta'),
      description: t('dashboard.announcementCtaDescription'),
      testId: 'Dashboard#announcementCreate',
      onClick: () => history.push(generatePath(ROUTES.announcementsEdit.path)),
    },
    {
      icon: <CalendarIcon />,
      title: t('dashboard.eventCta'),
      description: t('dashboard.eventCtaDescription'),
      testId: 'Dashboard#eventCreate',
      onClick: () => history.push(generatePath(ROUTES.eventsEdit.path)),
    },
    {
      icon: <EmergencyIcon />,
      title: t('dashboard.emergencyCta'),
      description: t('dashboard.emergencyCtaDescription'),
      testId: 'Dashboard#emergencyCreate',
      disabled: !isCity || visitorMode,
      onClick: () => history.push(generatePath(ROUTES.emergencyEdit.path)),
    },
  ];

  const items = generateItems(totalAnalytics?.adminDashboardTotalsAnalytics, t);

  return (
    <>
      <DashboardView items={items} extraActions={extraActions} topPosts={topPosts} />
    </>
  );
};

export { DashboardViewContainer };
