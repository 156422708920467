import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { SmallText } from '@sim-admin-frontends/ui-shared';

import { TChartDataTimeSpan } from '../../../types/TDashboard';

const TabButton = styled.div<{ isFirst?: boolean; isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spaces.spacing4} ${({ theme }) => theme.spaces.spacing16};
  border-left: ${({ isFirst, theme }) =>
    isFirst ? 'unset' : `1px solid ${theme.colors.coolGray20}`};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.coolGray20 : 'unset')};
  transition: background-color 0.33s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.coolGray20 : theme.colors.coolGray10};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spaces.spacing8};
`;

const ButtonLabel = styled(SmallText)<{ isSelected: boolean }>`
  color: ${({ theme }) => theme.colors.coolGray70};
  font-weight: ${({ theme, isSelected }) =>
    isSelected ? theme.fontWeights.bold : theme.fontWeights.regular};
  user-select: none;
`;

type Props = {
  isFirst?: boolean;
  label: string;
  type: TChartDataTimeSpan;
  selectedTimeSpan: TChartDataTimeSpan;
  onClick: (arg: TChartDataTimeSpan) => () => void;
  icon?: ReactElement;
  testId?: string;
};
const AnalyticsChartTabButton: FC<Props> = ({
  isFirst,
  selectedTimeSpan,
  label,
  type,
  onClick,
  icon,
  testId = 'DashboardChartTab#',
}) => (
  <TabButton
    isFirst={isFirst}
    onClick={onClick(type)}
    isSelected={selectedTimeSpan === type}
    data-testid={testId + label}
  >
    {icon && <IconWrapper>{icon}</IconWrapper>}
    <ButtonLabel isSelected={selectedTimeSpan === type}>{label}</ButtonLabel>
  </TabButton>
);

export { AnalyticsChartTabButton };
