import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { TToastType, updateToast } from '@sim-admin-frontends/ui-shared';
import {
  AddAnnouncementDraftMutationVariables,
  AddAnnouncementMutationVariables,
  State,
  useAddAnnouncementDraftMutation,
  useAddAnnouncementMutation,
  useUpdateAnouncementDraftMutation,
  useUpdateAnouncementMutation,
} from '@sim-admin-frontends/data-access';

import { useAuthInfo } from '../contexts/userContext';
import { TAnnouncementsFormTypes, TAnnouncementsTabTypes } from '../types/TAnnouncements';
import {
  determineDraftToastMessage,
  determineTabRedirect,
  determineToastMessage,
  submitAnnouncement,
  submitDraft,
  submitUpdateAnnouncement,
  submitUpdateDraft,
} from '../utils/announcementsUtils';
import { ANNOUNCEMENT_TOAST_SHARED_ID } from '../constants/Constants';
import { TToastMessageType } from '../types/TToastMessage';

export const ANNOUNCEMENTS_SUBMIT_DEFAULT_TAB = TAnnouncementsTabTypes.PUBLISHED;

export type TUseAnnouncementSubmitAfterSubmitCb = (
  announcementTabType: TAnnouncementsTabTypes,
) => void;

export type TUseAnnouncementTypeSubmit = {
  announcementType: TAnnouncementsFormTypes;
  toastMessageType: TToastMessageType;
  afterSubmitCb?: TUseAnnouncementSubmitAfterSubmitCb;
  afterDraftSubmitCb?: TUseAnnouncementSubmitAfterSubmitCb;
};

export const useAnnouncementTypeSubmit = ({
  announcementType,
  toastMessageType,
  afterSubmitCb,
  afterDraftSubmitCb,
}: TUseAnnouncementTypeSubmit) => {
  const queryClient = useQueryClient();
  const { institutionUuid } = useAuthInfo();
  const { t } = useTranslation();

  const { mutateAsync: addAnnouncement } = useAddAnnouncementMutation();
  const { mutateAsync: updateAnnouncement } = useUpdateAnouncementMutation();
  const { mutateAsync: addDraft } = useAddAnnouncementDraftMutation();
  const { mutateAsync: updateDraft } = useUpdateAnouncementDraftMutation();

  const handleSubmitAnnouncement = async (
    announcementPublishmentState: State | undefined,
    transformedData: AddAnnouncementMutationVariables,
    announcementId?: string,
  ) => {
    const isScheduled = !!transformedData.announcement.scheduledAt;
    const toastMessage = determineToastMessage(
      announcementPublishmentState,
      isScheduled,
      t,
      toastMessageType,
    );
    const tabRedirect = determineTabRedirect(isScheduled);
    const wasDraft = announcementPublishmentState === State.Draft;

    if (announcementId) {
      if (wasDraft) {
        await submitAnnouncement(
          transformedData.announcement,
          announcementId,
          queryClient,
          institutionUuid,
          announcementType,
          addAnnouncement,
        );
      } else {
        await submitUpdateAnnouncement(
          transformedData.announcement,
          announcementId,
          queryClient,
          institutionUuid,
          announcementType,
          updateAnnouncement,
        );
      }
    } else {
      await submitAnnouncement(
        transformedData.announcement,
        undefined,
        queryClient,
        institutionUuid,
        announcementType,
        addAnnouncement,
      );
    }
    updateToast(ANNOUNCEMENT_TOAST_SHARED_ID, toastMessage, TToastType.SUCCESS);
    if (afterSubmitCb) {
      afterSubmitCb(tabRedirect);
    }
  };

  const handleSubmitDraftAnnouncement = async (
    announcementPublishmentState: State | undefined,
    transformedData: AddAnnouncementDraftMutationVariables,
    announcementId?: string,
  ) => {
    const isScheduled = !!transformedData.announcementDraft.scheduledAt;
    const toastMessage = determineDraftToastMessage(announcementPublishmentState, isScheduled, t);
    if (announcementId) {
      await submitUpdateDraft(
        transformedData.announcementDraft,
        announcementId,
        queryClient,
        institutionUuid,
        updateDraft,
      );
    } else {
      await submitDraft(transformedData.announcementDraft, queryClient, institutionUuid, addDraft);
    }
    updateToast(ANNOUNCEMENT_TOAST_SHARED_ID, toastMessage, TToastType.SUCCESS);
    if (afterDraftSubmitCb) {
      afterDraftSubmitCb(ANNOUNCEMENTS_SUBMIT_DEFAULT_TAB);
    }
  };

  return {
    handleSubmitAnnouncement,
    handleSubmitDraftAnnouncement,
  };
};
