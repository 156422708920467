import {
  CategoryType,
  QuickQuestionInput,
  State,
  TQuickQuestion,
  useAddQuickQuestionMutation,
  useCategoriesQuery,
  useInstitutionQuery,
  useQuickQuestionQuery,
  useUpdateQuickQuestionMutation,
  useUserInstitutionsQuery,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { zonedTimeToUtc } from 'date-fns-tz';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getDefaultLangCode } from '@sim-admin-frontends/utils-shared';

import { CHAT_MESSAGE_END_DATE, DEFAULT_CHAT_OPTIONS } from '../../../constants/ChatConstants';
import { FALLBACK_TIMEZONE } from '../../../constants/Constants';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { useAuthInfo } from '../../../contexts/userContext';
import { useGenerateChatActions } from '../../../hooks/actionButtons/useGenerateChatActions';
import { TAnnouncementsTabTypes } from '../../../types/TAnnouncements';
import { TChatFormValues } from '../../../types/TChat';
import { changeChatTab } from '../../../utils/chatUtils';
import { transformOptions } from '../../../utils/quickQuestionsUtils';
import CityChatEdit from './CityChatEdit';

const SHARED_TOAST_ID = 'chatFormToast';

type Props = {
  id?: string;
};

const CityChatEditContainer: FC<Props> = ({ id }) => {
  const { user, institutionUuid } = useAuthInfo();
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const history = useHistory();
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;

  const {
    data: questionData,
    isError: isErrorQuestion,
    isLoading: isLoadingQuestion,
  } = useQuickQuestionQuery({ id: id ?? '' }, { enabled: !!id });

  const {
    data: initialInstitution,
    isLoading: isInstitutionLoading,
    isError: isInstitutionError,
    refetch: refetchInstitution,
  } = useInstitutionQuery({
    id: institutionUuid || '',
  });
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
    refetch: refetchUserData,
  } = useUserInstitutionsQuery({
    username: user?.username || '',
  });

  const {
    isLoading: areCategoriesLoading,
    error: categoriesError,
    data: categories,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.QuickQuestion,
    },
  });

  const quickQuestionCategory = useMemo(
    () =>
      categories?.categoriesBy.categories.find((category) =>
        category.categoryTypes.includes(CategoryType.QuickQuestion),
      ),
    [categories],
  );

  const { mutateAsync: addQuickQuestion } = useAddQuickQuestionMutation();
  const { mutateAsync: editQuickQuestion } = useUpdateQuickQuestionMutation();

  const refetch = () => {
    if (isUserDataError) {
      refetchUserData();
    }
    if (isInstitutionError) {
      refetchInstitution();
    }
  };

  const quickQuestion = questionData?.post as TQuickQuestion;

  const { detailActions, renderModal } = useGenerateChatActions({
    postId: id || '',
    refetch,
    type: quickQuestion?.publishmentState || State.Published,
    isEditPage: true,
  });

  const isLoading =
    isInstitutionLoading || isUserDataLoading || areCategoriesLoading || isLoadingQuestion;
  const isError = isInstitutionError || isUserDataError || categoriesError || isErrorQuestion;

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const institutionOptions = userData?.adminUser?.institutions || [];
  const defaultPostLang = getDefaultLangCode(places?.[0].countryCode || '');

  const transformData = (formValues: TChatFormValues): QuickQuestionInput => {
    return {
      question: formValues.question,
      questionOptions: transformOptions(DEFAULT_CHAT_OPTIONS),
      privateResults: true,
      quickQuestionEndDate: zonedTimeToUtc(CHAT_MESSAGE_END_DATE, timezone).toISOString(),
      institutionUuid: formValues.publisher?.value ?? '',
      lang: formValues.lang?.value || defaultPostLang,
      categoryUuids: [quickQuestionCategory?.id ?? ''],
      notifyNow: true,
      isChat: true,
    };
  };

  const onSubmit = async (formValues: TChatFormValues) => {
    try {
      loadingToast(t('updates.form.toastSubmitting'), {
        toastId: SHARED_TOAST_ID,
      });
      const transformedData = transformData(formValues);

      if (id) {
        await editQuickQuestion({
          id,
          quickQuestion: transformedData,
        });
      } else {
        await addQuickQuestion({
          quickQuestion: transformedData,
        });
      }
      updateToast(
        SHARED_TOAST_ID,
        id ? t('chat.form.toastEdit') : t('chat.form.toastCreate'),
        TToastType.SUCCESS,
      );
      changeChatTab(history, TAnnouncementsTabTypes.PUBLISHED);
    } catch (err) {
      updateToast(SHARED_TOAST_ID, getErrorMessage(err), TToastType.ERROR);
    }
  };

  const onDiscard = () => {
    changeChatTab(history, TAnnouncementsTabTypes.PUBLISHED);
  };

  return (
    <>
      <CityChatEdit
        quickQuestion={quickQuestion}
        submit={onSubmit}
        onDiscard={onDiscard}
        user={user}
        institution={initialInstitution?.institution}
        userInstitutions={institutionOptions}
        chatFormActions={id ? detailActions : []}
      />
      {renderModal()}
    </>
  );
};

export default CityChatEditContainer;
