import { useMemo } from 'react';
import {
  InstitutionPlaceRole,
  TFeatureFlagType,
  useInstiutionRoleQuery,
} from '@sim-admin-frontends/data-access';
import { usePlaceQuery } from '@sim-admin-frontends/data-access-admin-be';

import { isFeatureEnabled } from '../utils/placeUtils';
import { useAuthInfo } from '../contexts/userContext';
import { usePlaceInfo } from '../contexts/placeContext';
import { useIsPlaceAdmin } from './useIsPlaceAdmin';

const useIsReportProblemEnabled = () => {
  const { institutionUuid } = useAuthInfo();
  const { isPlaceAdmin } = useIsPlaceAdmin();
  const { places } = usePlaceInfo();
  const placeId = places?.[0].id || '';

  const {
    data: placeData,
    isLoading: placeIsLoading,
    isError: placeIsError,
  } = usePlaceQuery({
    id: placeId,
  });
  const {
    data: institutionData,
    isLoading: institutionIsLoading,
    isError: institutionIsError,
  } = useInstiutionRoleQuery({ id: institutionUuid });

  const isLocalBusiness =
    institutionData?.institution?.inPlaces?.[0]?.role === InstitutionPlaceRole.LocalBusiness;

  const isEnabled = useMemo(
    () =>
      isFeatureEnabled(placeData?.place?.featureFlags || [], TFeatureFlagType.REPORT_A_PROBLEM) &&
      isPlaceAdmin &&
      !isLocalBusiness,
    [isLocalBusiness, isPlaceAdmin, placeData?.place?.featureFlags],
  );

  return {
    isEnabled,
    isLoading: placeIsLoading || institutionIsLoading,
    isError: placeIsError || institutionIsError,
  };
};

export default useIsReportProblemEnabled;
