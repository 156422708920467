import styled from 'styled-components';
import { FC, useCallback } from 'react';
import {
  Tab as ReactTab,
  Tabs as ReactTabs,
  TabList as ReactTabList,
  TabPanel as ReactTabPanel,
} from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import { TTabPanelTabs, TBaseProps } from '../..';
import { TabHeaderButton } from './TabHeaderButton';

const Styles = styled.div`
  display: flex;
  flex: 1;
  & .react-tabs,
  .react-tabs__tab-panel {
    width: 100%;
  }
  & .react-tabs__tab,
  .react-tabs__tab-list {
    border: unset;
    position: relative;
    margin: 0;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: ${({ theme }) => theme.fontSizes.caption1};
    line-height: ${({ theme }) => theme.lineHeights.caption1};
    color: ${({ theme }) => theme.colors.coolGray70};
    padding: 0;
    & :focus {
      box-shadow: none;
    }
  }

  & .react-tabs__tab--selected {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: ${({ theme }) => theme.fontSizes.caption1};
    line-height: ${({ theme }) => theme.lineHeights.caption1};
    color: ${({ theme }) => theme.colors.coolGray100};
  }
`;

export interface TabPanelProps extends TBaseProps {
  tabs: TTabPanelTabs;
  onIndexChange?: (value: number) => void;
  tabIndex: number;
}

const TabPanel: FC<TabPanelProps> = ({ tabs, children, className, onIndexChange, tabIndex }) => {
  const onSelect = useCallback((index: number) => {
    if (!onIndexChange) {
      return;
    }
    onIndexChange(index);
  }, []);

  return (
    <Styles className={className}>
      <ReactTabs selectedIndex={tabIndex} onSelect={onSelect}>
        <ReactTabList>
          {tabs.map((tab, index) => {
            if (!tab.label) {
              return null;
            }

            return (
              <ReactTab key={tab.label}>
                <TabHeaderButton
                  label={tab.label}
                  count={tab.count}
                  selected={index === tabIndex}
                  testId={tab.testId}
                />
              </ReactTab>
            );
          })}
          {children}
        </ReactTabList>
        {tabs.map((tab, index) => (
          <ReactTabPanel key={index}>{tab.content}</ReactTabPanel>
        ))}
      </ReactTabs>
    </Styles>
  );
};

export { TabPanel };
