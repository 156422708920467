import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGenerateRaffleActions } from '../../../hooks/actionButtons/useGenerateRaffleActions';

export interface RafflesTableMenuProps extends TBaseProps {
  raffleId: string;
  refetch: () => void;
  published: boolean;
}

const RafflesTableMenu: FC<RafflesTableMenuProps> = ({ raffleId, refetch, published, testId }) => {
  const { listActions: items, renderModal } = useGenerateRaffleActions({
    raffleId,
    refetch,
    published,
  });

  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export default RafflesTableMenu;
