import { Children, FC, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spaces.spacing16} 0;
`;

const RightWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spaces.spacing8};
`;

type Props = {
  prependItem?: ReactElement;
};

const TableActionHeader: FC<Props> = ({ children, prependItem }) => {
  return (
    <Wrapper>
      {prependItem && <LeftWrapper>{prependItem}</LeftWrapper>}
      <RightWrapper>
        {Children.map(children, (child: ReactNode) => (
          <ItemWrapper>{child}</ItemWrapper>
        ))}
      </RightWrapper>
    </Wrapper>
  );
};

export { TableActionHeader };
