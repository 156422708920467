import styled from 'styled-components';
import { FC } from 'react';

import { FormSwitcher, FormSwitchProps } from '../form-switcher/FormSwitcher';
import {
  FormSectionHeader,
  FormSectionHeaderProps,
} from '../form-section-header/FormSectionHeader';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const SwitcherWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing24};
`;

type Props = FormSectionHeaderProps & FormSwitchProps;

const FormSectionWithSwitch: FC<Props> = ({
  title,
  description,
  className,
  initialValue,
  onChange,
}) => {
  return (
    <Row className={className}>
      <FormSectionHeader title={title} description={description} />
      <SwitcherWrapper>
        <FormSwitcher initialValue={initialValue} onChange={onChange} />
      </SwitcherWrapper>
    </Row>
  );
};

export { FormSectionWithSwitch };
