import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.ul``;

const RichTextList: FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default RichTextList;
