import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, CloseIcon, Modal } from '@sim-admin-frontends/ui-shared';
import { TQuickQuestionListItem } from '@sim-admin-frontends/data-access';

import PreviewItemInstitution from '../../previewItem/PreviewItemInstitution';
import QuickQuestionResults from './QuickQuestionResults';
import { QuickQuestionPreviewFooter } from '../preview/QuickQuestionPreviewFooter';

const MODAL_MIN_WIDTH = 350;

const Title = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  color: ${({ theme }) => theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  word-wrap: break-word;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spaces.spacing24};
  padding-bottom: 0;
  min-width: ${MODAL_MIN_WIDTH}px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const Footer = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.spaces.spacing16};
`;

type Props = {
  quickQuestion: TQuickQuestionListItem;
  showFooter?: boolean;
};

const QuickQuestionsResultsPopup: FC<Props> = ({ quickQuestion, showFooter = true }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { institution, question, questionOptions, quickQuestionEndDate } = quickQuestion;

  const onButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const onCloseClick = () => {
    setIsOpen(false);
  };

  const transformedOptions = useMemo(() => {
    const parsedOptions = JSON.parse(questionOptions ?? '');
    const optionsArr: string | Record<string, any>[] = Object.entries(parsedOptions);
    return optionsArr.map((option) => ({
      id: option[0],
      answer: option[1].answer,
      count: option[1].count,
    }));
  }, [questionOptions]);

  const allAnswersCount = useMemo(
    () => transformedOptions.reduce((prev, { count }) => prev + count, 0),
    [transformedOptions],
  );

  return (
    <>
      <Button variant="tertiary" noPadding onClick={onButtonClick}>
        {t('common.view')}
      </Button>
      <Modal isOpen={isOpen}>
        <Wrapper>
          <Header>
            <CloseButton onClick={onCloseClick}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <PreviewItemInstitution
            logo={institution?.branding?.avatar.links?.self}
            name={institution?.name}
            testIdPrefix={'QuickQuestion#results'}
          />
          <Title>{question}</Title>
          <QuickQuestionResults options={transformedOptions} answersCount={allAnswersCount} />
          {showFooter ? (
            <QuickQuestionPreviewFooter
              endDate={quickQuestionEndDate ? new Date(quickQuestionEndDate) : new Date()}
              votes={allAnswersCount}
            />
          ) : (
            <Footer />
          )}
        </Wrapper>
      </Modal>
    </>
  );
};

export default QuickQuestionsResultsPopup;
