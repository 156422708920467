import { FC } from 'react';
import styled from 'styled-components';
import { FormInput, Button, LargeText, PasswordRequirements } from '@sim-admin-frontends/ui-shared';
import { TFunction, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { TChangePasswordValues } from '../../types/TUser';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Headline = styled(LargeText)`
  text-align: center;
  padding: ${({ theme }) => theme.spaces.spacing24};
`;

const FormWrapper = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubmitButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  width: 100%;
`;

const PASSWORD_SPECIAL_CHARS = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

const schema = (t: TFunction) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t('common.validation.required'))
      .test('has-one-lower', t('common.validation.passwordMustContainsOneLower'), (value) =>
        value ? !!value.match(/[a-z]/) : false,
      )
      .test('has-one-upper', t('common.validation.passwordMustContainsOneUpper'), (value) =>
        value ? !!value.match(/[A-Z]/g) : false,
      )
      .test('has-one-num', t('common.validation.passwordMustContainsOneNumber'), (value) =>
        value ? !!value.match(/[0-9]/g) : false,
      )
      .test('has-one-special', t('common.validation.passwordMustContainsOneSpecial'), (value) =>
        value ? !!value.match(new RegExp(`[${PASSWORD_SPECIAL_CHARS}]`, 'g')) : false,
      )
      .min(8, t('common.validation.min', { num: 8 })),
    passwordConfirm: Yup.string()
      .required(t('common.validation.required'))
      .test('passwords-match', t('common.validation.passwordsMustMatch'), function (value) {
        return this.parent.password === value;
      }),
  });

type Props = {
  onCompleteRegistration: (values: TChangePasswordValues) => void;
};

const CompleteRegistration: FC<Props> = ({ onCompleteRegistration }) => {
  const { t } = useTranslation();

  const { handleSubmit, register, formState } = useForm<TChangePasswordValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema(t)),
  });

  const {
    errors,
    isSubmitting,
    dirtyFields: { password: passwordIsDirty },
    isSubmitted,
  } = formState;

  const onSubmit = async (values: TChangePasswordValues) => {
    return onCompleteRegistration(values);
  };

  const showPasswordRequirements = passwordIsDirty || isSubmitted;

  const passwordRequirements = [
    { errorKey: 'has-one-lower', text: t('common.passwordRequirements.hasOneLower') },
    { errorKey: 'has-one-upper', text: t('common.passwordRequirements.hasOneUpper') },
    { errorKey: 'has-one-num', text: t('common.passwordRequirements.hasOneNum') },
    {
      errorKey: 'has-one-special',
      text: (
        <span>
          {t('common.passwordRequirements.hasOneSpecial')}
          <br />
          <span dangerouslySetInnerHTML={{ __html: PASSWORD_SPECIAL_CHARS }} />
        </span>
      ),
    },
    { errorKey: 'min', text: t('common.passwordRequirements.min', { num: 8 }) },
  ];

  return (
    <Wrapper>
      <FormWrapper>
        <Headline>{t('completeRegistration.headline')}</Headline>
        <FormInput
          label={t('completeRegistration.password')}
          type="password"
          {...register('password')}
          error={errors.password}
        />
        {showPasswordRequirements && (
          <PasswordRequirements
            title={t('completeRegistration.passwordRequirements')}
            items={passwordRequirements}
            errors={errors?.password?.types}
          />
        )}
        <FormInput
          label={t('completeRegistration.passwordConfirm')}
          {...register('passwordConfirm')}
          type="password"
          error={errors.passwordConfirm}
        />
        <SubmitButton
          size="larger"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          {t('completeRegistration.submit')}
        </SubmitButton>
      </FormWrapper>
    </Wrapper>
  );
};

export default CompleteRegistration;
