import { Dispatch, FC, useState } from 'react';
import styled from 'styled-components';
import { Button, DatePicker } from '@sim-admin-frontends/ui-shared';
import { CSVLink } from 'react-csv';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { TCustomDataInterval } from '@sim-admin-frontends/data-access';
import { useTranslation } from 'react-i18next';

import { TChartDataTimeSpan } from '../../../types/TDashboard';
import { AnalyticsChartTabButton } from './AnalyticsChartTabButton';
import { ReactComponent as DashboardCalendarIcon } from '../../../assets/img/dashboardCalendar.svg';
import { ReactComponent as ExportIcon } from '../../../assets/img/export.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.coolGray20};
  padding: ${({ theme }) => theme.spaces.spacing16};
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
  box-sizing: border-box;
`;

const TabBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  overflow-x: auto;
`;

const CSVButtonLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.caption1};
  line-height: ${({ theme }) => theme.lineHeights.large};
  color: ${({ theme }) => theme.colors.coolGray70};
`;

const CSVIcon = styled(ExportIcon)`
  margin-right: ${({ theme }) => theme.spaces.spacing8};
`;

const DatePickerPopup = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: ${({ theme }) => theme.spaces.spacing64};
  z-index: ${({ theme }) => theme.zIndex.dashBoardAnalyticsDatePopup};
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  padding: ${({ theme }) => theme.spaces.spacing16};
  align-items: center;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spaces.spacing8};
`;

type Props = {
  selectedTimeSpan: TChartDataTimeSpan;
  setSelectedTimeSpan: Dispatch<TChartDataTimeSpan>;
  csvData: CommonPropTypes;
  setCustomInterval: Dispatch<TCustomDataInterval>;
  customInterval: TCustomDataInterval;
};

const AnalyticsChartHeader: FC<Props> = ({
  selectedTimeSpan,
  setSelectedTimeSpan,
  csvData,
  setCustomInterval,
  customInterval,
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { t } = useTranslation();
  const onButtonClick = (button: TChartDataTimeSpan) => () => {
    if (button === TChartDataTimeSpan.CUSTOM) {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
    }
    setSelectedTimeSpan(button);
  };

  const onPopupButtonClick = () => {
    setShowDatePicker(false);
  };

  const onDateChange = (startDate?: Date | null, endDate?: Date | null) => {
    if (startDate) {
      setCustomInterval({
        ...customInterval,
        startDate,
      });
    }
    if (endDate) {
      setCustomInterval({
        ...customInterval,
        endDate,
      });
    }
  };

  return (
    <Wrapper>
      <TabBar>
        <AnalyticsChartTabButton
          isFirst
          label={t('dashboard.analyticsChart.tabBar.custom')}
          type={TChartDataTimeSpan.CUSTOM}
          selectedTimeSpan={selectedTimeSpan}
          onClick={onButtonClick}
          icon={<DashboardCalendarIcon />}
        />
        {showDatePicker && (
          <DatePickerPopup>
            <DatePicker
              maxDate={new Date()}
              initialValue={customInterval.startDate}
              onDateChanged={(value) => onDateChange(value)}
            />
            <Divider> - </Divider>
            <DatePicker
              maxDate={new Date()}
              initialValue={customInterval.endDate}
              minDate={customInterval.startDate}
              onDateChanged={(value) => onDateChange(undefined, value)}
            />
            <Button variant="tertiary" onClick={onPopupButtonClick}>
              {t('common.close')}
            </Button>
          </DatePickerPopup>
        )}
        <AnalyticsChartTabButton
          label={t('dashboard.analyticsChart.tabBar.7D')}
          type={TChartDataTimeSpan.WEEK}
          selectedTimeSpan={selectedTimeSpan}
          onClick={onButtonClick}
        />
        <AnalyticsChartTabButton
          label={t('dashboard.analyticsChart.tabBar.30D')}
          type={TChartDataTimeSpan.MONTH}
          selectedTimeSpan={selectedTimeSpan}
          onClick={onButtonClick}
        />
        <AnalyticsChartTabButton
          label={t('dashboard.analyticsChart.tabBar.3M')}
          type={TChartDataTimeSpan.QUARTER}
          selectedTimeSpan={selectedTimeSpan}
          onClick={onButtonClick}
        />
        <AnalyticsChartTabButton
          label={t('dashboard.analyticsChart.tabBar.6M')}
          type={TChartDataTimeSpan.HALF_YEAR}
          selectedTimeSpan={selectedTimeSpan}
          onClick={onButtonClick}
        />
        <AnalyticsChartTabButton
          label={t('dashboard.analyticsChart.tabBar.12M')}
          type={TChartDataTimeSpan.YEAR}
          selectedTimeSpan={selectedTimeSpan}
          onClick={onButtonClick}
        />
        <AnalyticsChartTabButton
          label={t('dashboard.analyticsChart.tabBar.allTime')}
          type={TChartDataTimeSpan.ALL}
          selectedTimeSpan={selectedTimeSpan}
          onClick={onButtonClick}
        />
      </TabBar>
      <CSVLink {...csvData}>
        <Button testId="DashboardChartTab#ExportCSV" variant={'tertiary'}>
          <CSVIcon />
          <CSVButtonLabel>{t('dashboard.analyticsChart.tabBar.exportCsv')}</CSVButtonLabel>
        </Button>
      </CSVLink>
    </Wrapper>
  );
};

export { AnalyticsChartHeader };
