import { TFunction } from 'i18next';
import {
  DashboardCardProps,
  DashboardItemAnalyticsProps,
  DashboardItemTitleProps,
} from '@sim-admin-frontends/ui-shared';
import { formatDateObject } from '@sim-admin-frontends/utils-shared';

import {
  isQuickQuestionPostType,
  TAnnonucementEventPost,
  TFullPost,
  TFullPostsType,
  TTotalsAnalytics,
} from '../../types/TDashboard';

export interface ItemsProps extends DashboardItemTitleProps, DashboardItemAnalyticsProps {}
const MAX_TOP_POSTS_TO_RENDER = 3;

const getDescription = (post: TFullPost): string | null | undefined => {
  if (!post) return null;
  switch (post.__typename) {
    case 'Announcement':
      return post.announcementContent;
    case 'Event':
      return post.eventContent;
    default:
      return null;
  }
};

const getDate = (post: TFullPost): string | null | undefined => {
  if (!post || isQuickQuestionPostType(post) || !post?.createdAt) return null;
  return formatDateObject(new Date(post.createdAt));
};

export const generateTopPosts = (posts: TFullPostsType): DashboardCardProps[] => {
  const filteredPosts = (posts.filter((post) => !isQuickQuestionPostType(post)) ||
    []) as TAnnonucementEventPost[];

  const result: DashboardCardProps[] = [];

  for (let i = 0; i < MAX_TOP_POSTS_TO_RENDER; i++) {
    result.push({
      institution: filteredPosts[i]?.institution?.name ?? '',
      institutionLogo: filteredPosts[i]?.institution?.branding?.avatar.links?.self,
      updatedAt: getDate(filteredPosts[i]),
      title: filteredPosts[i]?.title ?? '',
      images: filteredPosts[i]?.imageObjects,
      description: getDescription(filteredPosts[i]),
    });
  }

  return result;
};

export const generateItems = (data: TTotalsAnalytics, t: TFunction): ItemsProps[] => {
  return [
    {
      title: t('dashboard.followers'),
      description: t('dashboard.followersDescription'),
      testId: 'DashboardItem#maus',
      actual: data?.Followers?.actual || 0,
      diff: data?.Followers?.diff || 0,
    },
    {
      title: t('dashboard.subscribers'),
      description: t('dashboard.subscribersDescription'),
      testId: 'DashboardItem#subscribers',
      actual: data?.Subscribers?.actual || 0,
      diff: data?.Subscribers?.diff || 0,
    },
    {
      title: t('dashboard.reach'),
      description: t('dashboard.reachDescription'),
      testId: 'DashboardItem#notificationsDelivered',
      actual: data?.Reach?.actual || 0,
      diff: data?.Reach?.diff || 0,
    },
  ];
};
