import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { captureException } from '@sentry/react';
import {
  AddAnnouncementDraftMutationVariables,
  AddAnnouncementMutationVariables,
  CategoryType,
  isAnnouncement,
  State,
  UploadType,
  useAnnouncementQuery,
  useCategoriesQuery,
  useInstitutionAnnouncementCountsQuery,
  useInstitutionInfoQuery,
  useInstitutionQuery,
  useUserInstitutionsQuery,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import {
  filterCategories,
  getDefaultLangCode,
  useUploadImage,
  useUploadVideo,
} from '@sim-admin-frontends/utils-shared';
import {
  FeatureFlagInternalType,
  usePlaceFeautureFlagsQuery,
} from '@sim-admin-frontends/data-access-admin-be';

import AnnouncementEdit from './AnnouncementEdit';
import {
  TAnnouncementFormValues,
  TAnnouncementsFormTypes,
  TAnnouncementsTabTypes,
} from '../../../types/TAnnouncements';
import { useAuthInfo } from '../../../contexts/userContext';
import { usePlaceInfo } from '../../../contexts/placeContext';
import NotFound from '../../../routing/NotFound';
import { useAnnouncementSubmit } from '../../../hooks/useAnnouncementSubmit';
import { ANNOUNCEMENT_TOAST_SHARED_ID } from '../../../constants/Constants';
import { changeAnnouncementsTab, transformFormValues } from '../../../utils/announcementsUtils';
import HighlightableElementsOverlay from '../../highlightable-elements/HighlightableElementsOverlay';
import { useHighlightableElements } from '../../../contexts/highlightableElementsContext';
import useAnnouncementCreationWalkthrough from '../../../hooks/walkthrough/useAnnouncementCreationWalkthrough';
import { useGenerateAnnoucementActions } from '../../../hooks/actionButtons/useGenerateAnnoucementActions';
import ROUTES from '../../../routing/routes';

type Props = {
  id?: string;
  showOnlyPreview?: boolean;
  postContent?: string;
};

const AnnouncementsEditContainer: FC<Props> = ({ id, showOnlyPreview, postContent }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user, institutionUuid, isLocalBusiness, visitorMode } = useAuthInfo();
  const { places } = usePlaceInfo();
  const { uploadFormImages } = useUploadImage();
  const { uploadFormVideos } = useUploadVideo();
  const defaultPostLang = getDefaultLangCode(places?.[0].countryCode || '');
  const placeId = places?.[0].id || '';
  const {
    data: placeFeatureFlagsData,
    isLoading: isPlaceFeautureFlagsLoading,
    isError: isPlaceFeautureFlagsError,
  } = usePlaceFeautureFlagsQuery({
    id: placeId,
  });
  const { highlightedElementId } = useHighlightableElements();
  const { renderModal } = useAnnouncementCreationWalkthrough();

  const volunteeringModeFeatureFlag = useMemo(
    () =>
      placeFeatureFlagsData?.place?.featureFlags?.find(
        (flag) => flag.type === FeatureFlagInternalType.VolunteeringMode,
      ),
    [placeFeatureFlagsData],
  );

  const { handleSubmitAnnouncement, handleSubmitDraftAnnouncement } = useAnnouncementSubmit(
    TAnnouncementsFormTypes.ANNOUNCEMENT,
  );

  const {
    data: postsCountData,
    isLoading: isLoadingPostsCount,
    isError: isErrorPostsCount,
  } = useInstitutionAnnouncementCountsQuery({
    id: institutionUuid,
  });

  const {
    data,
    isLoading: isAnnouncementLoading,
    error: announcementError,
    refetch,
  } = useAnnouncementQuery({ id: id || '' }, { enabled: !!id });

  const announcementData = isAnnouncement(data?.announcement) ? data?.announcement : undefined;

  const {
    isLoading: areCategoriesLoading,
    error: categoriesError,
    data: categoriesOfficial,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.Announcement,
      visitorMode,
    },
  });

  const {
    data: initialInstitution,
    isLoading: isInstitutionsLoading,
    error: institutionError,
  } = useInstitutionQuery({
    id: institutionUuid || '',
  });

  const {
    data: institutionInfoData,
    isLoading: isInstitutionInfoLoading,
    isError: isInstitutionInfoError,
  } = useInstitutionInfoQuery({
    id: institutionUuid,
  });

  const institutionGeolocation = institutionInfoData?.institution?.info?.location?.geo;
  const institutionCenter = {
    lat: institutionGeolocation?.lat || 0,
    lng: institutionGeolocation?.lon || 0,
  };

  const onDiscard = () => {
    changeAnnouncementsTab(history, TAnnouncementsTabTypes.PUBLISHED);
  };

  const onSubmit = async (formValues: TAnnouncementFormValues) => {
    try {
      loadingToast(t('updates.form.toastSubmitting'), {
        toastId: ANNOUNCEMENT_TOAST_SHARED_ID,
      });
      const publisherId = formValues.publisher?.value ?? '';
      const uploadedImages = await uploadFormImages(UploadType.News, formValues.images, {
        institutionId: publisherId,
      });
      const uploadedVideos = await uploadFormVideos(formValues.videos, {
        institutionId: publisherId,
      });
      const mutationVariables: AddAnnouncementMutationVariables = {
        announcement: transformFormValues(
          formValues,
          uploadedImages,
          uploadedVideos,
          defaultPostLang,
        ),
      };
      await handleSubmitAnnouncement(announcementData?.publishmentState, mutationVariables, id);
    } catch (err) {
      updateToast(ANNOUNCEMENT_TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const onSubmitDraft = async (formValues: TAnnouncementFormValues) => {
    try {
      loadingToast(t('updates.form.toastSubmitting'), {
        toastId: ANNOUNCEMENT_TOAST_SHARED_ID,
      });
      const publisherId = formValues.publisher?.value ?? '';
      const uploadedImages = await uploadFormImages(UploadType.News, formValues.images, {
        institutionId: publisherId,
      });
      const uploadedVideos = await uploadFormVideos(formValues.videos, {
        institutionId: publisherId,
      });
      const mutationVariables: AddAnnouncementDraftMutationVariables = {
        announcementDraft: transformFormValues(
          formValues,
          uploadedImages,
          uploadedVideos,
          defaultPostLang,
        ),
      };
      await handleSubmitDraftAnnouncement(
        announcementData?.publishmentState,
        mutationVariables,
        id,
      );
    } catch (err) {
      updateToast(ANNOUNCEMENT_TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const {
    data: userData,
    isLoading: isLoadingInstitutions,
    isError: isErrorInstitutions,
  } = useUserInstitutionsQuery({
    username: user?.username || '',
  });

  const isLoading =
    isAnnouncementLoading ||
    areCategoriesLoading ||
    isInstitutionsLoading ||
    isLoadingInstitutions ||
    isInstitutionInfoLoading ||
    isLoadingPostsCount ||
    isPlaceFeautureFlagsLoading;

  const isError =
    announcementError ||
    categoriesError ||
    institutionError ||
    isErrorInstitutions ||
    isInstitutionInfoError ||
    isErrorPostsCount ||
    isPlaceFeautureFlagsError;

  const categories = useMemo(() => {
    if (isLocalBusiness) {
      return [];
    }
    return filterCategories(categoriesOfficial?.categoriesBy.categories || [], {
      filterLegacy: true,
    });
  }, [categoriesOfficial, isLocalBusiness]);

  const announcement = announcementData;
  const isFromAdmin = announcement?.createdByAdmin;
  const published = announcement?.publishmentState === State.Published;
  const type = announcement?.publishmentState || State.Published;
  const postId = announcement?.id || '';

  const { detailActions, renderModal: renderActionModal } = useGenerateAnnoucementActions({
    isFromAdmin,
    type,
    published,
    postId,
    refetch,
    isEditPage: true,
    settings: {
      translationKey: 'updates',
      testIdKey: 'Announcements',
      editPagePath: ROUTES.announcementsEdit.path,
      changeTab: changeAnnouncementsTab,
    },
  });

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  if (isError)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  if (id && !announcementData)
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );

  const institutionOptions = userData?.adminUser?.institutions || [];
  const hasPosts = !!postsCountData?.institution?.publishedNewsCount;

  return (
    <>
      {!hasPosts && highlightedElementId ? (
        <HighlightableElementsOverlay>{renderModal()}</HighlightableElementsOverlay>
      ) : null}
      <AnnouncementEdit
        user={user}
        showOnlyPreview={showOnlyPreview}
        onSubmit={onSubmit}
        onSubmitDraft={onSubmitDraft}
        onDiscard={onDiscard}
        announcement={announcement}
        categories={categories}
        institution={initialInstitution?.institution}
        userInstitutions={institutionOptions}
        institutionCenter={institutionCenter}
        postContent={postContent}
        annoucementFormActions={id ? detailActions : []}
        volunteeringModeEnabled={!!volunteeringModeFeatureFlag?.enabled}
      />
      {renderActionModal()}
    </>
  );
};

export default AnnouncementsEditContainer;
