import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Heading1, Modal, SmallText, Button } from '../..';
import { TBaseProps } from '../../types/TBaseComponent';

const MODAL_WIDTH_PX = 264;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spaces.spacing24} ${({ theme }) => theme.spaces.spacing16};
`;

const Content = styled(SmallText)`
  margin-top: ${({ theme }) => theme.spaces.spacing8};
`;

const BackButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spaces.spacing24};
`;

const ConfirmButton = styled(Button).attrs({
  variant: 'danger',
})`
  margin-top: ${({ theme }) => theme.spaces.spacing8};
`;

export interface ConfirmModalProps extends TBaseProps {
  isOpen: boolean;
  title: string;
  backButtonText?: string;
  confirmButtonText?: string;
  onBackClick?: () => void;
  onConfirmClick?: () => void;
  content?: string | ReactNode;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  title,
  backButtonText,
  confirmButtonText,
  onBackClick,
  onConfirmClick,
  content,
  testId,
}) => {
  return (
    <Modal isOpen={isOpen} maxWidth={MODAL_WIDTH_PX}>
      <Wrapper>
        <Heading1>{title}</Heading1>
        <Content>{content}</Content>
        {onBackClick && (
          <BackButton onClick={onBackClick} testId={`${testId}-Back`}>
            {backButtonText}
          </BackButton>
        )}
        {onConfirmClick && (
          <ConfirmButton onClick={onConfirmClick} testId={`${testId}-Confirm`}>
            {confirmButtonText}
          </ConfirmButton>
        )}
      </Wrapper>
    </Modal>
  );
};

export { ConfirmModal };
