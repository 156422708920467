import { generatePath } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import { History, LocationState } from 'history';
import {
  formatTableDate,
  formatTableDateTime,
  getSelectedMapArea,
  prepareFileObjects,
} from '@sim-admin-frontends/utils-shared';
import {
  AnnouncementInput,
  Currency,
  MarketItemType,
  PostType,
  Pricing,
  State,
  TUploadedFile,
} from '@sim-admin-frontends/data-access';
import { CellProps } from 'react-table';
import { ClickableCopyText, TableHeaderTitle } from '@sim-admin-frontends/ui-shared';
import { serializeToString } from '@simplicity-tech/sim-slate-types';
import { zonedTimeToUtc } from 'date-fns-tz';

import ROUTES from '../routing/routes';
import { TAnnouncementsTabTypes, TAnnouncementTableItem } from '../types/TAnnouncements';
import { getDisabledColumns, getPublishingDateAccessor, parseRichText } from './announcementsUtils';
import { getUnpublishCheckbox } from './publishUtils';
import { TMarketFormValues, TMarketListItem, TMarketColumns } from '../types/TMarket';
import MarketTableMenu from '../components/market/view/MarketTableMenu';
import { getPostLinkLabel } from './postUtils';

export const changeMarketTab = (history: History<LocationState>, type: TAnnouncementsTabTypes) => {
  history.push(
    generatePath(ROUTES.marketOverview.path, {
      type,
    }),
  );
};

const getMarketItemType = (marketItemType?: string): MarketItemType => {
  if (!marketItemType) {
    return MarketItemType.Memberdeals;
  }
  return marketItemType as MarketItemType;
};

const getPricing = (
  currency?: string,
  fromPrice?: number,
  fromPriceBeforeDiscount?: number,
): Pricing | undefined => {
  if (!currency) {
    return undefined;
  }
  return {
    currency: currency as Currency,
    summary: fromPrice
      ? {
          fromPrice: parseNumber(fromPrice, parseFloat),
          fromPriceBeforeDiscount: parseNumber(fromPriceBeforeDiscount, parseFloat),
        }
      : undefined,
  };
};

const parseNumber = (value: any, transform: (value: any) => number) => {
  const parsedValue = transform(value);
  return Number.isNaN(parsedValue) ? undefined : parsedValue;
};

export const transformFormValues = (
  formData: TMarketFormValues,
  uploadedImages: TUploadedFile[] | undefined,
  defaultPostLang = '',
): AnnouncementInput => {
  const imageObjects = prepareFileObjects(uploadedImages);
  const areaOfInterest = getSelectedMapArea(formData.areaOfInterest);

  return {
    title: formData.title,
    content: formData.content,
    subtitle: formData.subtitle,
    marketUrl: formData.marketUrl,
    pushContent: serializeToString(formData.content),
    institutionUuid: formData.publisher?.value ?? '',
    categoryUuids: formData.categories?.map((category) => category.value),
    placeUuids: formData.places?.map((place) => place.value),
    lang: formData.lang?.value || defaultPostLang,
    imageObjects,
    scheduledAt: formData.scheduledAt?.toISOString(),
    notifications:
      formData.notifyNow && formData.notifications?.length && formData.notifications.length > 0
        ? formData.notifications?.map((notification) => notification.value.toISOString())
        : null,
    notifyAlsoNonCategoried: formData.notifyAlsoNonCategoried,
    notifyNow: formData.notifyNow,
    publishedAt: formData.scheduledAt ? undefined : formData.publishedAt?.toISOString(),
    areaOfInterest,
    typename: PostType.MarketItem,
    marketItemType: getMarketItemType(formData.marketItemType?.value),
    marketItemRating: parseNumber(formData.marketItemRating, parseFloat),
    marketItemDuration: parseNumber(formData.marketItemDuration, parseFloat),
    marketItemPricing: getPricing(
      formData.currency?.value,
      formData.fromPrice,
      formData.fromPriceBeforeDiscount,
    ),
    marketItemInclusions: formData.marketItemInclusions,
    marketItemExclusions: formData.marketItemExclusions,
    marketItemAdditionalInfo: formData.marketItemAdditionalInfo,
    marketItemOrder: parseNumber(formData.marketItemOrder, parseInt),
    marketItemViatorProductCode: formData.marketItemViatorProductCode,
    marketItemCancellationPolicy: { description: formData.cancellationPolicyDescription },
    marketItemLogistics: {
      startDescriptions: formData.logisticsStartDescriptions ?? [],
      endDescriptions: formData.logisticsEndDescriptions ?? [],
    },
  };
};

export const createTableColumns = (
  t: TFunction,
  refetch: () => void,
  type: State,
  timeZone?: string,
  postsToUnpublish?: string[],
  onCheckboxChange?: (id: string) => () => void,
) => {
  const disabledColumns = getDisabledColumns(type);

  const columns: TMarketColumns = [
    {
      Header: `${t('updates.table.title')}`,
      accessor: (item) => (
        <TableHeaderTitle
          item={item.title || parseRichText(item?.content ?? '')}
          maxTextLength={100}
        />
      ),
      testId: 'MarketTable#title',
      disabled: disabledColumns.includes('title'),
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('market.table.subtitle')}`,
      accessor: (item) => <TableHeaderTitle item={item.subtitle ?? ''} maxTextLength={100} />,
      testId: 'MarketTable#subtitle',
      disabled: disabledColumns.includes('subtitle'),
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('market.table.url')}`,
      accessor: 'marketUrl',
      Cell: ({ value }) => {
        if (!value) {
          return null;
        }
        const label = getPostLinkLabel(value);
        return <ClickableCopyText value={label} valueToCopy={value} />;
      },
      disabled: disabledColumns.includes('marketUrl'),
      disableSortBy: true,
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('updates.table.published')}`,
      accessor: getPublishingDateAccessor(type),
      testId: 'MarketTable#publicationDate',
      Cell: disabledColumns.includes('publishedAt')
        ? ''
        : ({ value }: any) => (value ? formatTableDateTime(value, timeZone) : ''),
      disabled: disabledColumns.includes('publishedAt'),
      disableSortBy: disabledColumns.includes('publishedAt'),
      skeleton: {
        width: 100,
      },
    },
    {
      Header: `${t('updates.table.lastUpdate')}`,
      accessor: 'updatedAt',
      testId: 'MarketTable#lastUpdate',
      Cell: disabledColumns.includes('updatedAt')
        ? ''
        : ({ value }) => (value ? formatTableDate(value) : ''),
      disabled: disabledColumns.includes('updatedAt'),
      disableSortBy: disabledColumns.includes('updatedAt'),
      skeleton: {
        width: 100,
      },
    },
    {
      Header: '',
      id: '0',
      testId: 'MarketTable#dots',
      disableSortBy: true,
      minWidth: 100,
      skeleton: {
        width: 50,
        align: 'right',
      },
      // eslint-disable-next-line react/display-name
      Cell: ({ row }: CellProps<TAnnouncementTableItem>) => {
        const isPublished = row.original.publishmentState === State.Published;
        const isFromAdmin = row.original.createdByAdmin;
        return (
          <MarketTableMenu
            type={type}
            isFromAdmin={isFromAdmin}
            postId={row.original.id}
            published={isPublished}
            refetch={refetch}
            testId={`MarketView#TableMenu${row.index}-${row.original.id}`}
          />
        );
      },
    },
  ];

  getUnpublishCheckbox<TMarketListItem, TMarketColumns>(
    columns,
    'MarketTable',
    postsToUnpublish,
    onCheckboxChange,
  );

  return columns;
};

export const prepareFormValues = (
  values: TMarketFormValues,
  isScheduling: boolean,
  timezone: string,
) => {
  return {
    ...values,
    lang: values.lang,
    scheduledAt:
      isScheduling && values.scheduledAt ? zonedTimeToUtc(values.scheduledAt, timezone) : undefined,
    notifications: values.notifications?.map((notification) => ({
      value: zonedTimeToUtc(notification.value, timezone),
    })),
    publishedAt: values.publishedAt ? zonedTimeToUtc(values.publishedAt, timezone) : undefined,
  };
};
