import styled from 'styled-components';
import { FC } from 'react';

import { TBaseProps } from '../..';
import { ReactComponent as LeftArrow } from '../../assets/img/arrow_left.svg';
import { ReactComponent as RightArrow } from '../../assets/img/arrow_right.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spaces.spacing8};
`;

const Label = styled.div`
  font-family: Manrope, serif;
  font-size: ${({ theme }) => theme.fontSizes.caption1};
  line-height: ${({ theme }) => theme.fontSizes.caption1};
  color: ${({ theme }) => theme.colors.coolGray100};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
`;

const LeftButton = styled(LeftArrow)`
  fill: ${({ theme }) => theme.colors.coolGray100};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  & :hover {
    background-color: ${({ theme }) => theme.colors.coolGray10};
  }
`;

const RightButton = styled(RightArrow)`
  fill: ${({ theme }) => theme.colors.coolGray100};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  & :hover {
    background-color: ${({ theme }) => theme.colors.coolGray10};
  }
`;

export interface CustomHeaderProps extends TBaseProps {
  month: string;
  onNextClick: () => void;
  onPrevClick: () => void;
}

const DatePickerHeader: FC<CustomHeaderProps> = ({ month, onNextClick, onPrevClick }) => {
  return (
    <Wrapper>
      <LeftButton onClick={onPrevClick} />
      <Label>{month}</Label>
      <RightButton onClick={onNextClick} />
    </Wrapper>
  );
};

export { DatePickerHeader };
