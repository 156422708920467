import { TUploadedFile } from '@sim-admin-frontends/data-access';

export const prepareFileObjects = (uploadedFiles: TUploadedFile[] | undefined) => {
  if (uploadedFiles) {
    return uploadedFiles.map((file) => ({
      id: file.id,
      fileName: file.fileName,
      filePath: file.filePath,
    }));
  }
  return [];
};
