import { Polygon, PolygonProps } from '@react-google-maps/api';
import { TMapCenter } from '@sim-admin-frontends/utils-shared';
import { FC } from 'react';

import { defaultMapOptions } from '../../constants/MapConstants';

export interface MapAreaPolygonProps extends PolygonProps {
  options?: google.maps.PolygonOptions;
  paths: TMapCenter[];
}

const MapAreaPolygon: FC<MapAreaPolygonProps> = ({
  options = defaultMapOptions,
  paths,
  ...rest
}) => {
  if (!paths) {
    return null;
  }
  return <Polygon paths={paths} options={options} {...rest} />;
};

export { MapAreaPolygon };
