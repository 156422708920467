import { useLocation } from 'react-router';
import { useEffect, useRef } from 'react';
import { match as Match, useRouteMatch } from 'react-router-dom';

import { useAuthInfo } from '../contexts/userContext';
import { AnalyticsEvent } from '../utils/analytics/analyticsEvents';
import { analyticsLogEvent, initializeAnalytics } from '../utils/analytics/analyticsUtils';
import { AnalyticsRoutes } from '../utils/analytics/analyticsRoutes';

const usePrevious = (value: Match) => {
  const ref = useRef<Match>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useAnalytics = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const prevMatch = usePrevious(match);
  const { institutionUuid } = useAuthInfo();

  const trackScreenView = () => {
    if (match.path === prevMatch?.path) return;
    const currentAnalyticsRoute = AnalyticsRoutes[match.path];
    if (!currentAnalyticsRoute) return;
    const referrerAnalyticsRoute = prevMatch ? AnalyticsRoutes[prevMatch.path] : null;
    analyticsLogEvent({
      type: AnalyticsEvent.SCREEN_VIEW,
      data: {
        Name: currentAnalyticsRoute.name,
        Subsection: currentAnalyticsRoute?.subsection,
        Referrer: referrerAnalyticsRoute?.name,
        InstitutionId: institutionUuid,
      },
    });
  };

  useEffect(() => {
    initializeAnalytics();
  }, []);

  useEffect(() => {
    trackScreenView();
  }, [location]);
};

export default useAnalytics;
