import { FieldError } from 'react-hook-form';
import { FC, forwardRef, Ref, TextareaHTMLAttributes } from 'react';

import { TBaseProps } from '../../../types/TBaseComponent';
import { FormError } from '../form-error/FormError';
import { Textarea } from '../../textarea/Textarea';
import { FormItemLabel, FormItemWrapper } from '../formStyles';

export interface FormTextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, TBaseProps {
  label?: string;
  ref?: Ref<HTMLTextAreaElement>;
  error?: FieldError;
}

const FormTextarea: FC<FormTextareaProps> = forwardRef(
  ({ label, testId, error, ...inputProps }, ref) => {
    return (
      <FormItemWrapper>
        <FormItemLabel testId={`${testId}-label`}>{label}</FormItemLabel>
        <Textarea {...inputProps} testId={testId} ref={ref} invalid={!!error} />
        {error && <FormError error={error} testId={`${testId}-error`} />}
      </FormItemWrapper>
    );
  },
);

FormTextarea.displayName = 'FormTextarea';

export { FormTextarea };
