import {
  ModerationPostsByFilterInput,
  ModeratorPostByQuery,
  useInfiniteGraphQLQuery,
  useModeratorPostByQuery,
} from '..';

export const MODERATOR_LISTING_PAGE_SIZE = 10;
export const MODERATOR_LISTING_FILTER_PLACE_UUIDS = ['inInstitution'];

export const useInfiniteModeratorsQuery = (
  filter: ModerationPostsByFilterInput,
  enabled?: boolean,
) => {
  const getNextPageParam = (lastPage: ModeratorPostByQuery, allPages: ModeratorPostByQuery[]) =>
    lastPage.moderationPostsBy.posts.length === MODERATOR_LISTING_PAGE_SIZE
      ? allPages.length * MODERATOR_LISTING_PAGE_SIZE
      : 0;

  const getVariables = ({ pageParam }: { pageParam?: string | null }) => ({
    pageOffset: Number(pageParam ?? 0),
    pageSize: MODERATOR_LISTING_PAGE_SIZE,
    filter,
  });

  return useInfiniteGraphQLQuery(useModeratorPostByQuery, getVariables, {
    refetchOnReconnect: true,
    getNextPageParam,
    enabled,
  });
};
