import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { useAuthInfo } from '../../contexts/userContext';
import { usePlaceInfo } from '../../contexts/placeContext';
import {
  FALLBACK_GOOGLE_GA_MEASUREMENT_ID,
  PAGE_VIEW_HIT,
  PAGE_VIEW_HIT_TITLE,
} from '../../constants/AnalyticsConstants';

ReactGA.initialize(
  [
    {
      trackingId: process.env.NX_GOOGLE_GA_MEASUREMENT_ID || FALLBACK_GOOGLE_GA_MEASUREMENT_ID,
    },
  ],
  {
    testMode:
      process.env.NODE_ENV === 'development' || process.env.NX_ENVIRONMENT === 'development',
  },
);

const GAnalytics = () => {
  const { user } = useAuthInfo();
  const { places } = usePlaceInfo();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: PAGE_VIEW_HIT,
      page: location.pathname + location.search,
      title: PAGE_VIEW_HIT_TITLE,
    });
  }, [location]);

  useEffect(() => {
    if (user) {
      ReactGA.set({
        userId: user.attributes?.sub,
      });
      ReactGA.event('login', {
        userId: user.attributes?.sub,
      });
    } else {
      ReactGA.set({
        userId: null,
      });
    }
  }, [user]);

  useEffect(() => {
    const place = places?.[0];

    if (place) {
      ReactGA.set({
        placeId: place.id,
        placeName: place.name,
      });
      ReactGA.event('change_place', {
        placeId: place.id,
        placeName: place.name,
        userId: user?.attributes?.sub || null,
      });
    } else {
      ReactGA.set({
        placeId: null,
      });
      ReactGA.set({
        placeName: null,
      });
    }
  }, [places]);

  return null;
};

export default GAnalytics;
