import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import {
  PetsInPlaceByFilter,
  PetState,
  TPetsInPlaceList,
  TPetsInPlaceListItem,
  useInuseInfinitePetsInPlaceByQuery,
} from '@sim-admin-frontends/data-access';
import { useTranslation } from 'react-i18next';
import { SortingRule } from 'react-table';
import { useTablePaging } from '@sim-admin-frontends/utils-shared';

import { usePlaceInfo } from '../../../contexts/placeContext';
import { createTableColumns } from '../../../utils/petsUtils';
import { getGqlSorting, getInitialGqlSorting, getInitialTableSorting } from './helper';
import PetDirectoryView from './PetDirectoryView';
import { useAuthInfo } from '../../../contexts/userContext';
import { TUnpublishContainerProps } from '../../../types/TUnpublish';

export interface AnnouncementsViewContainerProps extends TUnpublishContainerProps {
  state?: PetState;
}

const PetDirectoryViewContainer: FC<AnnouncementsViewContainerProps> = ({
  state = PetState.Home,
  postsToUnpublish,
  onUnpublishSelect,
  isUnpublishMutationSettled,
}) => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();
  const [orderBy, setOrderBy] = useState(getInitialGqlSorting(state));

  const filter: PetsInPlaceByFilter = useMemo(
    () => ({
      state,
    }),
    [state],
  );

  const { fetchNextPage, hasNextPage, data, isError, isLoading, isFetchingNextPage, refetch } =
    useInuseInfinitePetsInPlaceByQuery(filter, orderBy);

  useEffect(() => {
    if (isUnpublishMutationSettled) {
      refetch();
    }
  }, [isUnpublishMutationSettled]);

  const { currentPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } = useTablePaging(
    fetchNextPage,
    hasNextPage,
  );

  const onSortingChanged = useCallback(
    (newSorting: SortingRule<TPetsInPlaceListItem>[]) => {
      setOrderBy(getGqlSorting(newSorting, state));
      setCurrentPageIndex(0);
    },
    [setCurrentPageIndex, state],
  );

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [institutionUuid]);

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const columns = createTableColumns(
    t,
    refetch,
    state,
    places?.[0].timezoneCode,
    postsToUnpublish,
    onUnpublishSelect,
  );
  if (!columns) {
    return null;
  }

  const pets = data?.pages?.[currentPageIndex]?.petsInPlaceBy?.pets || [];
  const itemsCount = pets?.length;

  return (
    <PetDirectoryView
      hasNextPage={!!hasNextPage}
      loading={isLoading || isFetchingNextPage}
      itemsCount={itemsCount as number}
      pageIndex={currentPageIndex}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      columns={columns}
      data={pets as TPetsInPlaceList}
      onSortingChanged={onSortingChanged}
      initialTableSorting={getInitialTableSorting(state)}
    />
  );
};

export default PetDirectoryViewContainer;
