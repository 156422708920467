import { FC, useCallback } from 'react';
import { FormProvider, useForm, get } from 'react-hook-form';
import styled from 'styled-components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import {
  Button,
  FormButtonsWrapper,
  FormInput,
  FormLocationInput,
  FormTextarea,
  LocationInputValue,
  PageHeader,
} from '@sim-admin-frontends/ui-shared';
import { TInstitutionInfo } from '@sim-admin-frontends/data-access';

import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import { TInstitutionProfileFormValues } from '../../../types/TInstitution';
import { PageWrapper } from '../../layout/PageLayout';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const PageContent = styled.div`
  max-width: 580px;
`;

const Form = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing32};
  margin-bottom: ${({ theme }) => theme.spaces.spacing64};
`;

const schema = (t: TFunction) =>
  Yup.object().shape({
    contactEmail: Yup.string().email(t('common.validation.email')).max(255),
    location: Yup.object().shape({
      name: Yup.string().required(t('common.validation.required')),
    }),
    website: Yup.mixed().test(
      'required',
      t('common.validation.website'),
      (value: string) => (value.startsWith('https://') || value.startsWith('http://')) && !!value,
    ),
  });

type Props = {
  data: TInstitutionInfo;
  onSubmit: (values: TInstitutionProfileFormValues) => Promise<void>;
  onDiscard: () => void;
  institutionName: string;
};

const SettingsProfile: FC<Props> = ({ institutionName, onDiscard, onSubmit, data }) => {
  const { t } = useTranslation();
  const initialData = data?.info || null;
  const initialValues: TInstitutionProfileFormValues = {
    description: initialData?.description ?? '',
    website: initialData?.website ?? '',
    contactEmail: initialData?.contactEmail ?? '',
    contactPhone: initialData?.contactPhone ?? '',
    location: initialData?.location
      ? {
          geo: initialData.location.geo,
          name: initialData.location.address,
        }
      : undefined,
  };

  const methods = useForm<TInstitutionProfileFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });
  const { handleSubmit, register, formState, setValue, trigger, control, reset } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const submit = (values: TInstitutionProfileFormValues) => {
    onSubmit(values);
    reset(values);
  };

  const onSubmitClick = () => {
    handleSubmit(submit)();
  };

  const onLocationValueChange = useCallback(
    (value: LocationInputValue) => {
      if (value.gps) {
        setValue('location', {
          name: value.name,
          geo: value.gps,
        });
        trigger('location');
      }
    },
    [setValue, trigger],
  );

  return (
    <PageWrapper>
      <Page>
        <PageContent>
          <PageHeader
            title={t('settings.profile.title')}
            caption={t('settings.profile.description')}
            testId="SettingsTabs#PageHeader"
          />
          <Form>
            <FormProvider {...methods}>
              <FormInput
                label={t('settings.profile.form.name')}
                disabled={true}
                value={institutionName}
                testId="Settings#Profile#name"
              />
              <FormTextarea
                label={t('settings.profile.form.description')}
                {...register('description')}
                error={errors.description}
                testId="Settings#Profile#description"
              />
              <FormInput
                label={t('settings.profile.form.web')}
                {...register('website')}
                error={errors.website}
                testId="Settings#Profile#web"
              />
              <FormInput
                label={t('settings.profile.form.email')}
                {...register('contactEmail')}
                error={errors.contactEmail}
                testId="Settings#Profile#email"
              />
              <FormInput
                label={t('settings.profile.form.phone')}
                {...register('contactPhone')}
                error={errors.contactPhone}
                testId="Settings#Profile#phone"
              />
              <FormLocationInput
                control={control}
                name="location"
                error={get(errors, 'location.name')}
                label={t('settings.profile.form.address')}
                placeholder={t('events.form.searchLocation')}
                onValueChange={onLocationValueChange}
                defaultValue={{
                  label: initialValues.location?.name || '',
                  value: initialValues.location?.name || '',
                }}
              />
              <FormButtonsWrapper>
                <Button
                  size="smaller"
                  variant="tertiary"
                  onClick={onDiscard}
                  disabled={isSubmitting}
                  testId="Settings#Profile#cancel"
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  size="smaller"
                  type="submit"
                  onClick={onSubmitClick}
                  isLoading={isSubmitting}
                  disabled={isSubmitting || !isEmpty(errors) || !isDirty}
                  testId="Settings#Profile#save"
                >
                  {t('common.save')}
                </Button>
              </FormButtonsWrapper>
            </FormProvider>
            <RouteLeavingGuard when={isDirty && !isSubmitting} />
          </Form>
        </PageContent>
      </Page>
    </PageWrapper>
  );
};

export default SettingsProfile;
