import React, { FC } from 'react';

import QuickQuestionResultItem from './QuickQuestionResultItem';
import { TQuickQuestionOptions } from '../../../types/TQuickQuestions';

type Props = {
  options: TQuickQuestionOptions;
  answersCount: number;
};

const QuickQuestionResults: FC<Props> = ({ options, answersCount }) => {
  return (
    <div>
      {options.map((option, index) => (
        <QuickQuestionResultItem
          key={index}
          label={option.answer}
          percentage={option.count ? (option.count / answersCount) * 100 : 0}
        />
      ))}
    </div>
  );
};

export default QuickQuestionResults;
