import { FC } from 'react';
import styled from 'styled-components';
import { generatePath, NavLink } from 'react-router-dom';
import { SimplicityShapeIcon } from '@sim-admin-frontends/ui-shared';

import ROUTES from '../../routing/routes';

type VideoTutorialBannerProps = {
  text: string;
  visible: boolean;
  testId?: string;
};

const Container = styled.div`
  height: ${({ theme }) => theme.spaces.spacing64};
  flex: 1;
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spaces.spacing24};
  margin-top: ${({ theme }) => theme.spaces.spacing24};
  background-color: ${({ theme }) => theme.colors.black};
`;

const Text = styled.div`
  white-space: nowrap;
  display: flex;
  align-self: center;
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.white};
  margin-right: ${({ theme }) => theme.spaces.spacing24};
`;

const StyledSimplicityShapeIcon = styled(SimplicityShapeIcon)`
  align-self: flex-end;
`;

const VideoTutorialBanner: FC<VideoTutorialBannerProps> = ({ text, visible, testId }) =>
  visible ? (
    <Container as={NavLink} to={generatePath(ROUTES.videoTutorials.path)} data-testid={testId}>
      <Text>{text}</Text>
      <StyledSimplicityShapeIcon />
    </Container>
  ) : null;

export default VideoTutorialBanner;
