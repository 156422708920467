import {
  ImageInput,
  TPollDraftListItem,
  TPollListItem,
  VideoInput,
} from '@sim-admin-frontends/data-access';
import { TExtendedColumn, TSelectItem } from '@sim-admin-frontends/ui-shared';

export type TPollFormValues = {
  title: string;
  content: string;
  lang?: TSelectItem;
  images?: (File | ImageInput)[] | null;
  videos?: (File | VideoInput)[] | null;
  scheduledAt?: Date | null;
  notifications: { value: Date }[];
  notifyNow?: boolean;
  places?: TSelectItem[];
};

export type TPollTableItem = TPollListItem | TPollDraftListItem;

export type TPollsColumns = TExtendedColumn<TPollTableItem>[];

export enum PollModalActions {
  DELETE = 'DELETE',
  UNPUBLISH = 'UNPUBLISH',
  PUBLISH = 'PUBLISH',
  CANCEL_SEND = 'CANCEL_SEND',
  NOTIFY = 'NOTIFY',
}
