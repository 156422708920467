import { FC } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../../types/TBaseComponent';

/* eslint-disable-next-line */
export interface LabelProps extends TBaseProps {}

const StyledLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  color: ${({ theme }) => theme.colors.lightText};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const Label: FC<LabelProps> = ({ children, className, testId }) => (
  <StyledLabel data-testid={testId} className={className}>
    {children}
  </StyledLabel>
);

export { Label };
