import { useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';

//region,userPoolId,userPoolWebClientId - are all non sensitive values
export const useInitAmplify = () => {
  return useEffect(() => {
    Auth.configure({
      Auth: {
        region: 'us-east1',
        userPoolId: process.env.NX_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.NX_COGNITO_CLIENT_ID,
      },
    });
  }, []);
};
