import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '@sim-admin-frontends/ui-shared';
import { TWysiwygValue } from '@simplicity-tech/sim-slate-types';

const useYTVideoInEditor = (editor: TWysiwygValue) => {
  const { t } = useTranslation();
  const actualVideoCount = editor.filter((children) => children.type === 'video').length;
  const [lastVideoCount, setLastVideCount] = useState(0);
  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (actualVideoCount != lastVideoCount) {
      if (actualVideoCount > lastVideoCount) {
        setModalVisible(true);
      }
      setLastVideCount(actualVideoCount);
    }
  }, [editor]);

  const closeModal = () => setModalVisible(false);

  const youtubeModal = (() => (
    <ConfirmModal
      isOpen={isModalVisible}
      title={t('modal.important')}
      content={t('modal.ytVideo.iSeeVideo')}
      backButtonText={t('modal.iUnderstand')}
      onBackClick={closeModal}
    />
  ))();

  const hasYTVideo = !!actualVideoCount;

  return { youtubeModal, hasYTVideo };
};

export default useYTVideoInEditor;
