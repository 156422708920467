import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TFeatureFlagType } from '@sim-admin-frontends/data-access';

import ModeratorPreviewContainer from '../../components/moderator/preview/ModeratorPreview.container';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

export type ModeratorPreviewPageProps = {
  id?: string;
  type?: string;
};

const ModeratorPreviewPage: FC<RouteComponentProps<ModeratorPreviewPageProps>> = ({ match }) => {
  const { shouldShowFeatureFlagDisabledUI, showFeatureFlagDisabledUI } = useFeatureFlag(
    TFeatureFlagType.MODERATOR,
  );

  if (shouldShowFeatureFlagDisabledUI) {
    return showFeatureFlagDisabledUI();
  }

  return <ModeratorPreviewContainer id={match?.params?.id} type={match?.params?.type} />;
};

export default ModeratorPreviewPage;
