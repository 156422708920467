import { FC } from 'react';
import styled from 'styled-components';
import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { BookmarkIcon, ShareIcon, SmallText } from '@sim-admin-frontends/ui-shared';

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InfoLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.smallest};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.coolGray70};
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spaces.spacing16} ${({ theme }) => theme.spaces.spacing8};
`;

const ButtonText = styled(SmallText)`
  color: ${({ theme }) => theme.colors.coolGray60};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  padding: ${({ theme }) => theme.spaces.spacing8};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type Props = {
  endDate?: Date;
  votes?: number;
};

const getInfoLabel = (endDate: Date | undefined, t: TFunction, votes: number) => {
  const now = new Date();
  return `${votes} ${t('quickQuestions.votes')} • ${formatDistance(endDate ?? now, now)} ${t(
    'quickQuestions.left',
  )}`;
};

const QuickQuestionPreviewFooter: FC<Props> = ({ endDate, votes = 0 }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {endDate && <InfoLabel>{getInfoLabel(endDate, t, votes)}</InfoLabel>}
      <BottomWrapper>
        <ButtonWrapper data-testid={'QuickQuestionPreview#Save'}>
          <BookmarkIcon />
          <ButtonText>{t('common.save')}</ButtonText>
        </ButtonWrapper>
        <ButtonWrapper data-testid={'QuickQuestionPreview#Share'}>
          <ShareIcon />
          <ButtonText>{t('common.share')}</ButtonText>
        </ButtonWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

export { QuickQuestionPreviewFooter };
