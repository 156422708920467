import { SimpliBotIcon } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import postGeneratorBanner from '../../assets/img/postGeneratorBanner.png';

const Banner = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.radius16};
  aspect-ratio: 5.5;
  margin-bottom: ${({ theme }) => theme.spaces.spacing24};
`;

const BannerOverlay = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  left: ${({ theme }) => theme.spaces.spacing24};
  align-items: center;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.xxLarge};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-left: ${({ theme }) => theme.spaces.spacing16};
`;

const CaptionWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  right: ${({ theme }) => theme.spaces.spacing16};
  bottom: ${({ theme }) => theme.spaces.spacing12}; ;
`;

const Caption = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.light};
`;

const PostGeneratorBanner = () => {
  const { t } = useTranslation();

  return (
    <Banner>
      <BannerOverlay src={postGeneratorBanner} />
      <TitleWrapper>
        <SimpliBotIcon />
        <Title>{t('postGenerator.form.banner.openAi')}</Title>
      </TitleWrapper>
      <CaptionWrapper>
        <Caption>{t('postGenerator.form.banner.caption')}</Caption>
      </CaptionWrapper>
    </Banner>
  );
};

export default PostGeneratorBanner;
