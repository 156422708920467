import { TWysiwygEditor } from '@simplicity-tech/sim-slate-types';

import { wrapImage, wrapVideo } from '../helpers/helper';

export const YTVideoPrefix = 'https://www.youtube.com/embed/';
export const YTRegex =
  /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:youtube\.com|youtu.be))(?:\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(?:\S+)?$/;
const imageRegex = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/gi;

const withMedia = (editor: TWysiwygEditor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === 'image' || element.type === 'video' ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');
    const matchesVideo = text.match(YTRegex);
    const matchesImage = text.match(imageRegex);
    if (matchesVideo) {
      const url = YTVideoPrefix + matchesVideo[1];
      wrapVideo(editor, url);
    } else if (matchesImage) {
      wrapImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export default withMedia;
