import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import { toDate as toDateFunction, utcToZonedTime } from 'date-fns-tz';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import {
  FormDatePicker,
  FormInput,
  FormSectionHeader,
  FormTimePicker,
  FormWrapper,
  FormWysiwyg,
  Icon,
  Label,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  SubLabel,
  SUPPORTED_IMAGE_FORMATS,
  SwitchableFormSection,
  TableIconProps,
  ActionButtons,
  FormImageCropperUpload,
} from '@sim-admin-frontends/ui-shared';
import { TCouponDetail } from '@sim-admin-frontends/data-access';
import { MaybeDate } from '@sim-admin-frontends/utils-shared';

import PageContentEditable, { ContentHeader } from '../../layout/pageContent/PageContentEditable';
import {
  CouponRecurrence,
  RecurrenceEnd,
  TCouponFormValues,
  TTimeValues,
} from '../../../types/TCoupons';
import FormButtons from '../../announcements/edit/FormButtons';
import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import CouponNotificationSection from './CouponNotificationSection';
import {
  AM,
  END_DAY_TIME_12H,
  END_DAY_TIME_24H,
  FALLBACK_TIMEZONE,
  PM,
  START_DAY_TIME_12H,
  START_DAY_TIME_24H,
} from '../../../constants/Constants';
import { usePlaceInfo } from '../../../contexts/placeContext';
import useDateTimeChanged from '../../../hooks/useDateTimeChanged';
import { imagesValidation, notificationsValidation } from '../../announcements/edit/validation';
import {
  getInitialDay,
  getRecurrenceEnd,
  getRecurrenceTypeOption,
  getRecurrenceTypeOptions,
  parse24hTime,
  prepareFormValues,
  getDateMaxTime,
} from '../../../utils/couponsUtils';
import CouponPreview from '../preview/CouponPreview';
import RecurrenceSettings from '../../recurrenceSettings/RecurrenceSettings';
import { recurrenceValidation } from './validation';

const DEFAULT_COUPON_RECURRENCE_OCCURRENCES = 1;

const Wrapper = styled.div<{ placement?: 'flex-start' | 'center' | 'flex-end' }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: ${({ placement }) => placement || 'center'};
`;

const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: ${({ theme }) => theme.spaces.spacing8};
`;

const Separator = styled.div`
  height: 100%;
  width: ${({ theme }) => theme.spaces.spacing16};
`;

const schema = (t: TFunction, timezone: string, isRecurring: boolean, endsOn: RecurrenceEnd) => {
  return Yup.object().shape({
    title: Yup.string().required(t('common.validation.required')),
    description: Yup.string().test('equals', t('common.validation.required'), (value) => {
      return value !== SERIALIZED_WYSIWYG_EMPTY_VALUE;
    }),
    images: Yup.array()
      .test('required', t('common.validation.required'), (value) => !!value)
      .concat(imagesValidation(t)),
    fromDate: Yup.mixed().test(
      'required',
      t('common.validation.required'),
      (value: MaybeDate) => !!value,
    ),
    toDate: Yup.mixed()
      .test('required', t('common.validation.required'), (value: MaybeDate) => !!value)
      .test(
        'greaterThanFromDate',
        t('common.validation.date.greaterThanFromDate'),
        (value, context) => !!value && value >= context.parent.fromDate,
      ),
    toTime: Yup.string().test(
      'greaterThanFromTime',
      t('common.validation.time.greaterThanFromTime'),
      (_, context) => {
        const { fromTime, fromTimePeriod, toTime, toTimePeriod } = context.parent;
        const areValuesSet = !!fromTime && !!fromTimePeriod && !!toTime && !!toTimePeriod;
        const parsedFromTime = parse(`${fromTime} ${fromTimePeriod}`, 'h:mm a', new Date());
        const parsedToTime = parse(`${toTime} ${toTimePeriod}`, 'h:mm a', new Date());

        return !areValuesSet || parsedToTime > parsedFromTime;
      },
    ),
    notifications: notificationsValidation(t, timezone),
    recurrence: Yup.object().when(['fromDate', 'toDate'], (fromDate, toDate) =>
      recurrenceValidation(t, isRecurring, endsOn, fromDate, toDate),
    ),
  });
};

type Props = {
  onSubmit: (values: TCouponFormValues) => Promise<void>;
  onDiscard: () => void;
  coupon?: TCouponDetail;
  couponFormActions: TableIconProps[];
};

const CouponEdit: FC<Props> = ({ onSubmit, onDiscard, coupon, couponFormActions }) => {
  const { t } = useTranslation();
  const pageTitle = coupon ? t('coupon.form.editPageTitle') : t('coupon.form.createPageTitle');
  const { places } = usePlaceInfo();
  const now = useMemo(() => new Date(), []);
  const timezone = places?.[0].timezoneCode || FALLBACK_TIMEZONE;

  const [isRecurring, setRecurring] = useState(!!coupon?.validity.recurring);

  const isEdit = coupon !== undefined;

  const couponInterval = coupon?.validity.interval;

  const recurerenceTypeOptions = getRecurrenceTypeOptions(t);
  const defaultRecurrence: CouponRecurrence = useMemo(
    () => ({
      type: recurerenceTypeOptions[0],
      every: 1,
      day: ['0'],
      fromDate: couponInterval?.fromDate
        ? toDateFunction(couponInterval?.fromDate)
        : toDateFunction(now),
      timeFrom: couponInterval?.fromTime || START_DAY_TIME_24H,
      toDate: coupon?.validity?.recurring?.toDate
        ? toDateFunction(coupon.validity.recurring.toDate)
        : now,
      timeTo: couponInterval?.toTime || END_DAY_TIME_24H,
      occurrences: coupon?.validity?.recurring?.ocurrences || DEFAULT_COUPON_RECURRENCE_OCCURRENCES,
    }),
    [coupon, couponInterval, now, recurerenceTypeOptions],
  );

  const initialValues: TCouponFormValues = useMemo(
    () => ({
      title: coupon?.title || '',
      subtitle: coupon?.subtitle || '',
      description: coupon?.description || SERIALIZED_WYSIWYG_EMPTY_VALUE,
      images: coupon?.imageObjects,
      isInfinite: coupon?.validity.isInfinite,
      fromDate: couponInterval?.fromDate
        ? toDateFunction(couponInterval?.fromDate)
        : toDateFunction(now),
      toDate: couponInterval?.toDate ? toDateFunction(couponInterval?.toDate) : now,
      fromTime: couponInterval?.fromTime
        ? parse24hTime(couponInterval?.fromTime, 'hh:mm')
        : undefined,
      fromTimePeriod: couponInterval?.fromTime
        ? parse24hTime(couponInterval?.fromTime, 'a')
        : undefined,
      toTime: couponInterval?.toTime ? parse24hTime(couponInterval?.toTime, 'hh:mm') : undefined,
      toTimePeriod: couponInterval?.toTime ? parse24hTime(couponInterval?.toTime, 'a') : undefined,
      notifyNow: isEdit ? !!coupon?.notifyNow : false,
      notifications: coupon?.notifications
        ? coupon?.notifications.map((notification) => ({
            value: utcToZonedTime(notification, timezone),
          }))
        : [],
      publishedAt: coupon?.publishedAt
        ? utcToZonedTime(coupon?.publishedAt, timezone)
        : utcToZonedTime(now, timezone),
      recurrence: coupon?.validity?.recurring
        ? {
            type:
              getRecurrenceTypeOption(t, coupon.validity.recurring.type) || defaultRecurrence.type,
            every: coupon?.validity?.recurring.every,
            day: getInitialDay(t, coupon.validity.recurring?.day),
            fromDate: toDateFunction(coupon.validity.recurring.fromDate),
            timeFrom: coupon.validity.recurring.timeFrom,
            toDate: coupon.validity.recurring.toDate
              ? toDateFunction(coupon.validity.recurring.toDate)
              : now,
            timeTo: coupon.validity.recurring.timeTo,
            occurrences:
              coupon?.validity?.recurring.ocurrences || DEFAULT_COUPON_RECURRENCE_OCCURRENCES,
          }
        : undefined,
    }),
    [coupon, couponInterval, defaultRecurrence.type, isEdit, now, t, timezone],
  );

  const initialRecurrence = initialValues.recurrence || defaultRecurrence;
  const defaultRecurrenceEnd = getRecurrenceEnd(
    coupon?.validity?.recurring?.toDate,
    coupon?.validity?.recurring?.ocurrences,
  );
  const [endsOn, setEndsOn] = useState(defaultRecurrenceEnd);

  const methods = useForm<TCouponFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, timezone, isRecurring, endsOn)),
    mode: 'all',
  });
  const { handleSubmit, register, formState, setValue, getValues, trigger, control } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const [
    previewImages,
    previewDescription,
    previewTitle,
    previewSubtitle,
    previewFromDate,
    previewFromTime,
    previewFromTimePeriod,
    previewToDate,
    previewToTime,
    previewToTimePeriod,
    previewRecurrence,
  ] = useWatch({
    name: [
      'images',
      'description',
      'title',
      'subtitle',
      'fromDate',
      'fromTime',
      'fromTimePeriod',
      'toDate',
      'toTime',
      'toTimePeriod',
      'recurrence',
    ],
    control,
  });

  const fromTimeInitialValue = initialValues?.fromTime || START_DAY_TIME_12H;
  const fromTimeDayPeriodInitialValue = initialValues?.fromTimePeriod || AM;
  const toTimeInitialValue = initialValues?.toTime || END_DAY_TIME_12H;
  const toTimeDayPeriodInitialValue = initialValues?.toTimePeriod || PM;
  const setTimeValueAndTriggerOthers = (name: TTimeValues) => (value: string) => {
    setValue(name, value);
    trigger(['fromTime', 'toTime', 'fromTimePeriod', 'toTimePeriod']);
  };
  const onTimeSectionVisibilityChanged = (open: boolean) => {
    if (open) {
      setValue('fromTime', fromTimeInitialValue);
      setValue('fromTimePeriod', fromTimeDayPeriodInitialValue);
      setValue('toTime', toTimeInitialValue);
      setValue('toTimePeriod', toTimeDayPeriodInitialValue);
    } else {
      setValue('fromTime', undefined);
      setValue('fromTimePeriod', undefined);
      setValue('toTime', undefined);
      setValue('toTimePeriod', undefined);
    }
    trigger(['fromTime', 'toTime', 'fromTimePeriod', 'toTimePeriod']);
  };

  const { onDateTimeChanged: onFromDateTimeChanged } = useDateTimeChanged(
    'fromDate',
    setValue,
    trigger,
  );

  const onValidFromDateTimeChanged = (value: MaybeDate) => {
    onFromDateTimeChanged(value);
    trigger('toDate');
    trigger('recurrence.toDate');
  };

  const { onDateTimeChanged: onToDateTimeChanged } = useDateTimeChanged(
    'toDate',
    setValue,
    trigger,
  );

  const onValidToDateTimeChanged = (value: MaybeDate) => {
    onToDateTimeChanged(value);
    trigger('recurrence.toDate');
  };

  const onIsInfiniteChange = (value: boolean) => {
    setValue('isInfinite', value);
  };

  const onNotificationsEnabledChange = (value: boolean) => {
    setValue('notifyNow', value);
  };

  const submit = async (values: TCouponFormValues) =>
    onSubmit(prepareFormValues(values, timezone, false, endsOn));

  const onSubmitClick = () => {
    methods.control._updateProps({
      resolver: yupResolver(schema(t, timezone, isRecurring, endsOn)),
    });

    handleSubmit(submit)();
  };

  const initialFromDate = getDateMaxTime(initialValues.fromDate);
  const initialToDate = getDateMaxTime(initialValues.toDate || initialFromDate);

  const minToDate = getValues('fromDate');
  const toDateCeiling = getValues('toDate');

  const onRecurrenceSectionVisibilityChanged = () => {
    // if is currently recurring.. then after click is not recurring
    if (initialValues.recurrence) {
      setValue('recurrence', isRecurring ? undefined : initialValues.recurrence);
    } else {
      setValue('recurrence', isRecurring ? undefined : defaultRecurrence);
    }
    setEndsOn(defaultRecurrenceEnd);
    setRecurring(!isRecurring);
  };

  const onEndOnChange = (recurrenceType: RecurrenceEnd) => {
    if (endsOn === recurrenceType) {
      return;
    }

    setEndsOn(recurrenceType);
  };

  useEffect(() => {
    trigger('recurrence.occurrences');
    trigger('recurrence.toDate');
  }, [endsOn]);

  useEffect(() => {
    trigger('recurrence');
  }, [isRecurring]);

  return (
    <PageContentEditable
      previewTitle={t('coupons.preview.title')}
      preview={
        <CouponPreview
          images={previewImages}
          description={previewDescription}
          title={previewTitle}
          subtitle={previewSubtitle}
          fromDate={previewFromDate}
          fromTime={previewFromTime}
          fromTimePeriod={previewFromTimePeriod}
          toDate={previewToDate}
          toTime={previewToTime}
          toTimePeriod={previewToTimePeriod}
          recurrence={previewRecurrence}
        />
      }
    >
      <FormWrapper>
        <ActionButtons actionButtons={couponFormActions} />
        <ContentHeader>{pageTitle}</ContentHeader>
        <FormProvider {...methods}>
          <FormInput
            label={t('coupon.form.title')}
            placeholder={'Type custom value here'}
            {...register('title')}
            error={errors.title}
            testId="CouponEdit#title"
          />
          <FormInput
            label={t('coupon.form.subtitle')}
            placeholder={'Type small describing title here'}
            {...register('subtitle')}
            error={errors.subtitle}
            testId="CouponEdit#subtitle"
          />
          <FormImageCropperUpload
            initialValue={initialValues.images}
            title={t('coupon.form.imageLabel')}
            control={control}
            setValue={setValue}
            getValues={getValues}
            trigger={trigger}
            name="images"
            dropzoneLabel={t('coupon.form.dropzoneLabel')}
            t={t}
            testId="CouponEdit#image"
            accept={SUPPORTED_IMAGE_FORMATS}
          />
          <SubLabel>{t('coupon.form.imageSublabel')}</SubLabel>
          <FormWysiwyg
            control={control}
            name="description"
            label={t('coupon.form.content')}
            initialValue={JSON.parse(getValues('description'))}
            testId="CouponEdit#wysiwyg"
          />
          <FormSectionHeader bottomMargin={false} title={t('coupon.form.requirements')} />
          <>
            <Wrapper>
              <Label>{t('coupon.form.setDate')}</Label>
              <Tippy placement="bottom" content={t('coupon.form.setDateInfo')}>
                <Icon />
              </Tippy>
            </Wrapper>
            <DateTimeWrapper>
              <FormDatePicker
                label={t('coupon.form.from')}
                control={control}
                name="fromDate"
                initialValue={initialFromDate}
                onDateChanged={onValidFromDateTimeChanged}
                testId="CouponValid#fromdate"
              />
              <Separator />
              <FormDatePicker
                label={t('coupon.form.to')}
                control={control}
                name="toDate"
                initialValue={initialToDate}
                minDate={minToDate}
                onDateChanged={onValidToDateTimeChanged}
                testId="CouponValid#todate"
              />
            </DateTimeWrapper>
          </>
          <SwitchableFormSection
            customTitleComponent={() => (
              <Wrapper>
                <Label>{t('coupon.form.isInfinite')}</Label>
                <Tippy placement="bottom" content={t('coupon.form.isInfiniteInfo')}>
                  <Icon />
                </Tippy>
              </Wrapper>
            )}
            onVisibilityChanged={onIsInfiniteChange}
            initiallyOpened={!!initialValues.isInfinite}
            testId={'CouponIsInfinite#switch'}
          />

          <SwitchableFormSection
            customTitleComponent={() => (
              <Wrapper>
                <Label>{t('coupon.form.setTime')}</Label>
                <Tippy placement="bottom" content={t('coupon.form.setTimeInfo')}>
                  <Icon />
                </Tippy>
              </Wrapper>
            )}
            onVisibilityChanged={onTimeSectionVisibilityChanged}
            initiallyOpened={!!(initialValues.fromTime && initialValues.toTime)}
            testId={'CouponSetTime#switch'}
          >
            <DateTimeWrapper>
              <FormTimePicker
                initialTime={fromTimeInitialValue}
                initialDayPeriod={fromTimeDayPeriodInitialValue}
                label={t('coupon.form.from')}
                control={control}
                name="fromTime"
                onChange={setTimeValueAndTriggerOthers('fromTime')}
                onDayPeriodChanged={setTimeValueAndTriggerOthers('fromTimePeriod')}
                testId="CouponValid#fromtime"
              />
              <Separator />
              <FormTimePicker
                initialTime={toTimeInitialValue}
                initialDayPeriod={toTimeDayPeriodInitialValue}
                label={t('coupon.form.to')}
                control={control}
                name="toTime"
                onChange={setTimeValueAndTriggerOthers('toTime')}
                onDayPeriodChanged={setTimeValueAndTriggerOthers('toTimePeriod')}
                testId="CouponValid#totime"
              />
            </DateTimeWrapper>
          </SwitchableFormSection>
          <SwitchableFormSection
            customTitleComponent={() => (
              <Wrapper>
                <Label>{t('coupon.form.setRecurrence')}</Label>
                <Tippy placement="bottom" content={t('coupon.form.setRecurrenceInfo')}>
                  <Icon />
                </Tippy>
              </Wrapper>
            )}
            onVisibilityChanged={onRecurrenceSectionVisibilityChanged}
            initiallyOpened={!!initialValues.recurrence}
            testId={'CouponSetRecurrence#switch'}
          >
            <RecurrenceSettings
              initialRecurrence={initialRecurrence}
              minToDate={minToDate}
              maxToDate={toDateCeiling}
              endsOn={endsOn}
              onEndOnChange={onEndOnChange}
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </SwitchableFormSection>
          <CouponNotificationSection
            control={control}
            notifyNow={initialValues.notifyNow}
            publishmentState={coupon?.publishmentState}
            isEdit={isEdit}
            onNotificationsEnabledChange={onNotificationsEnabledChange}
          />
          <FormButtons
            isSubmitting={isSubmitting}
            getValues={getValues}
            errors={errors}
            publishmentState={coupon?.publishmentState}
            onDiscard={onDiscard}
            onSubmitClick={onSubmitClick}
          />
        </FormProvider>
      </FormWrapper>
      <RouteLeavingGuard when={isDirty && !isSubmitting} />
    </PageContentEditable>
  );
};

export default CouponEdit;
