import { DEFAULT_RADIUS_MILES_STRING } from '@sim-admin-frontends/ui-shared';
import { TGeoShape, TMapCenter } from '@sim-admin-frontends/utils-shared';
import { useCallback, useRef } from 'react';

import {
  generateCirclePolygonPaths,
  generatePolygon,
  getMeters,
  isCircleGeoShape,
} from '../utils/mapUtils';

export const useMapAreaSelect = (
  onChange: (value: TGeoShape | null) => void,
  initalValue: TGeoShape | null,
  center: TMapCenter,
) => {
  const polygonRef = useRef<google.maps.Polygon>();
  const onLoadPolygon = useCallback((polygon) => {
    polygonRef.current = polygon;
  }, []);
  const onEditPath = useCallback(() => {
    if (!polygonRef.current) {
      return;
    }
    const newCoords = polygonRef.current
      .getPath()
      .getArray()
      .map((coord) => [coord.lng(), coord.lat()]);
    const newShape = generatePolygon([newCoords]);
    onChange(newShape);
  }, []);

  const resetToInital = () => {
    const radius = getMeters(DEFAULT_RADIUS_MILES_STRING);
    const hexagonPolygonPaths = generateCirclePolygonPaths(center, radius);
    const newShape = generatePolygon(hexagonPolygonPaths);
    onChange(initalValue ?? newShape);
  };

  const changeCircleToPolygon = (currentValue: TGeoShape | null) => {
    if (!isCircleGeoShape(currentValue)) {
      return;
    }
    const newShape = generatePolygon(
      generateCirclePolygonPaths(center, getMeters(currentValue?.radius)),
    );
    onChange(newShape);
  };

  return {
    onLoadPolygon,
    onEditPath,
    reset: resetToInital,
    changeCircleToPolygon,
  };
};
