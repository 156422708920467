import { FC } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../../types/TBaseComponent';
import { Checkbox } from '../checkbox/Checkbox';
import { SmallText } from '../texts/Texts';

const StyledLabel = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-left: ${({ theme }) => theme.spaces.spacing12};
  padding-top: ${({ theme }) => theme.spaces.spacing2};
  cursor: pointer;
  user-select: none;
`;

const StyledDiv = styled.div`
  display: inline-flex;
  align-items: center;
`;

interface CheckboxWithLabelProps extends TBaseProps {
  label: string;
  value: boolean;
  onValueChange: () => void;
}

const CheckboxWithLabel: FC<CheckboxWithLabelProps> = ({ label, value, onValueChange, testId }) => (
  <StyledDiv>
    <Checkbox onChange={onValueChange} checked={value} testId={testId} />
    <StyledLabel data-testid={`${testId}-label`} onClick={onValueChange}>
      {label}
    </StyledLabel>
  </StyledDiv>
);

export { CheckboxWithLabel };
