import { FC } from 'react';
import { generatePath } from 'react-router-dom';
import { Table, TExtendedColumn } from '@sim-admin-frontends/ui-shared';
import { MODERATOR_LISTING_PAGE_SIZE, TModeratorListItem } from '@sim-admin-frontends/data-access';

import ROUTES from '../../../routing/routes';
import { getCategoryType } from '../../../utils/moderatorUtils';

type ModeratorViewProps = {
  data: TModeratorListItem[];
  columns: TExtendedColumn<TModeratorListItem>[];
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  hasNextPage?: boolean;
};

const ModeratorView: FC<ModeratorViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  hasNextPage,
}) => {
  const onRowPress = (id: string) => {
    const itemData = data.find((item) => item.id === id);
    const type = getCategoryType(itemData?.categories ?? []).toLowerCase();
    const path = generatePath(ROUTES.moderatorPreview.path, { id, type });
    window.open(path, '_blank')?.focus();
  };

  return (
    <Table
      hasNextPage={hasNextPage}
      loading={loading}
      itemsCount={itemsCount}
      pageIndex={pageIndex}
      data={data}
      columns={columns}
      hasPagination={true}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      onRowPress={onRowPress}
      tablePageSize={MODERATOR_LISTING_PAGE_SIZE}
      testId="ModeratorView#Table"
    />
  );
};

export default ModeratorView;
