import { ChangeEvent, FC, useState } from 'react';
import styled from 'styled-components';

import { TBaseProps } from '../..';
import { Input } from '../input/Input';
import { SelectStyles } from '../select/SelectStyles';

const StyledInput = styled(Input)`
  height: 50px;

  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  &::-webkit-datetime-edit-ampm-field {
    display: none;
  }

  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export interface TimeInputProps extends TBaseProps {
  onChange: (value: string) => void;
  defaultValue?: string;
  disabled?: boolean;
}

const minTime = '01:00';
const maxTime = '12:59';
const defaultTime = '12:00';

const TimeInput: FC<TimeInputProps> = ({ onChange, defaultValue, testId, disabled }) => {
  const [timeValue, setTimeValue] = useState(defaultValue);
  const onTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.length === 0 ? defaultTime : e.target.value;
    if (value < minTime || value > maxTime) {
      setTimeValue(defaultValue || defaultTime);
      onChange(defaultValue || defaultTime);
      return;
    }

    setTimeValue(value);
    onChange(value);
  };

  return (
    <SelectStyles>
      <StyledInput
        type="time"
        onChange={onTimeChange}
        value={timeValue}
        testId={`${testId}-time-input`}
        disabled={disabled}
        min={minTime}
        max={maxTime}
        isSmallTexIndent
      />
    </SelectStyles>
  );
};

export { TimeInput };
