import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ScrapedAnnouncementEditContainer from '../../components/announcements/edit/ScrapedAnnouncementEdit.container';

type Props = {
  id?: string;
};

const ScrapedAnnouncementsEditPage: FC<RouteComponentProps<Props>> = ({ match }) => (
  <ScrapedAnnouncementEditContainer id={match?.params?.id || ''} />
);
export default ScrapedAnnouncementsEditPage;
