import { FC, useEffect, useState } from 'react';
import { Location } from 'history';
import { Prompt, useHistory } from 'react-router-dom';

import { FormDiscardModal } from './FormDiscardModal';

type RouteLeavingGuardProps = {
  when: boolean;
};

const RouteLeavingGuard: FC<RouteLeavingGuardProps> = ({ when }) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const onBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const onConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={onBlockedNavigation} />
      <FormDiscardModal
        isOpen={modalVisible}
        onConfirmClick={onConfirmNavigationClick}
        onBackClick={closeModal}
      />
    </>
  );
};

export { RouteLeavingGuard };
