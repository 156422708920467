import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LocationPinAppIcon } from '@sim-admin-frontends/ui-shared';
import { EventLocationInput } from '@sim-admin-frontends/data-access';

import ItemIconText, { RowText } from './PreviewItemIconText';
import { TEST_ID_PAGE_PREVIEW_PREFIX } from '../layout/pageContent/PageContentPreview';

const LocationWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
`;

const PlaceholderText = styled.div`
  color: ${({ theme }) => theme.colors.coolGray30};
  padding-top: ${({ theme }) => theme.spaces.spacing4};
`;

type Props = {
  location?: EventLocationInput | null;
  placeholderText?: string;
  showIcon?: boolean;
  testIdPrefix?: string;
};

const PreviewItemLocation: FC<Props> = ({
  location,
  placeholderText,
  showIcon = true,
  testIdPrefix = TEST_ID_PAGE_PREVIEW_PREFIX,
}) => {
  const { t } = useTranslation();
  const locationName = location?.online ? t('event.online') : location?.name || '';

  if (!placeholderText && !locationName) {
    return null;
  }

  if (!locationName) {
    return <PlaceholderText>{placeholderText}</PlaceholderText>;
  }

  return (
    <LocationWrapper>
      <ItemIconText
        icon={showIcon ? <LocationPinAppIcon /> : undefined}
        testIdPrefix={testIdPrefix}
        testIdSuffix="Location"
      >
        {locationName.split(',').map((item, index) => {
          const isFirst = index === 0;
          return (
            <RowText key={index} isFirst={isFirst}>
              {item.trim()}
            </RowText>
          );
        })}
      </ItemIconText>
    </LocationWrapper>
  );
};

export default PreviewItemLocation;
