import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';

import AnnouncementsEditContainer from '../../components/announcements/edit/AnnouncementEdit.container';

export type UpdateEditPageProps = {
  id?: string;
};

export type AnnouncementEditState = {
  postContent?: string;
};

const AnnouncementsEditPage: FC<
  RouteComponentProps<UpdateEditPageProps, StaticContext, AnnouncementEditState>
> = ({ match, location }) => {
  const postContent = location?.state?.postContent;

  return <AnnouncementsEditContainer id={match?.params?.id} postContent={postContent} />;
};

export default AnnouncementsEditPage;
