import Tippy, { useSingleton } from '@tippyjs/react';
import React, { FC, MouseEvent } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { IS_MAC } from '@sim-admin-frontends/utils-shared';

import { BoldIcon, ItalicIcon, LinkIcon, ListBulletIcon, UnlinkIcon } from '../../..';
import { BlockAction, LinkAction, MarkAction } from './ToolbarActions';
const Wrapper = styled.div<{ focused: boolean; $invalid: boolean }>`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px
    ${({ theme, focused, $invalid }) => (focused || $invalid ? '3px' : theme.spaces.spacing4)};

  border: ${({ theme, focused }) =>
    focused ? `2px solid ${theme.colors.blue60}` : `1px solid ${theme.colors.coolGray20}`};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-sizing: border-box;

  ${({ $invalid, theme, focused }) =>
    $invalid &&
    !focused &&
    css`
      border: 2px solid ${theme.colors.red60};
      border-bottom: 0;
    `}
`;

const StyledTippy = styled(Tippy)`
  background: ${({ theme }) => theme.colors.black};
  .tippy-arrow {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const ToolbarSection = styled.div`
  display: flex;
`;

const ToolbarCategory = styled.div`
  padding: 0 ${({ theme }) => theme.spaces.spacing4};
  display: flex;
`;

interface ToolBarProps {
  editorFocused: boolean;
  invalid: boolean;
  onMouseDown?: (event: MouseEvent<HTMLDivElement>) => void;
}

const Toolbar: FC<ToolBarProps> = ({ editorFocused, invalid, onMouseDown }) => {
  const [source, target] = useSingleton();
  const theme = useTheme();
  const iconColor = editorFocused ? theme.colors.black : theme.colors.coolGray50;

  return (
    <Wrapper onMouseDown={onMouseDown} focused={editorFocused} $invalid={invalid}>
      <StyledTippy singleton={source} delay={500} duration={1000} placement="bottom" />
      <ToolbarSection>
        <ToolbarCategory>
          <MarkAction
            format="bold"
            icon={<BoldIcon fill={iconColor} />}
            tippyTarget={target}
            label="Bold"
            activeLabel="Bold"
            shortcut={IS_MAC ? '⌘B' : 'Ctrl+B'}
          />
          <MarkAction
            format="italic"
            icon={<ItalicIcon fill={iconColor} />}
            tippyTarget={target}
            label="Italic"
            activeLabel="Italic"
            shortcut={IS_MAC ? '⌘I' : 'Ctrl+I'}
          />
          <BlockAction
            format={'bullet-list'}
            icon={<ListBulletIcon fill={iconColor} />}
            tippyTarget={target}
            label={'Bullet list'}
            activeLabel={'Bullet list'}
          />
        </ToolbarCategory>
      </ToolbarSection>
      <ToolbarSection>
        <ToolbarCategory>
          <LinkAction
            icon={<LinkIcon fill={iconColor} />}
            activeIcon={<UnlinkIcon fill={iconColor} />}
            tippyTarget={target}
            label="Create link"
            activeLabel="Unlink"
            shortcut={IS_MAC ? '⌘K' : 'Ctrl+K'}
          />
        </ToolbarCategory>
      </ToolbarSection>
    </Wrapper>
  );
};

export default Toolbar;
