/* eslint-disable react/no-array-index-key */
import styled from 'styled-components';
import React, { FC } from 'react';
import { TCustomElement } from '@simplicity-tech/sim-slate-types';

import RichTextImage from './elements/RichTextImage';
import RichTextChild from './elements/RichTextChild';
import RichTextList from './elements/RichTextList';
import RichTextListItem from './elements/RichTextListItem';
import RichTextBaseElement from './elements/RichTextBase';
import RichTextVideo from './elements/RichTextVideo';

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.spaces.spacing8};
`;

type Props = {
  value: TCustomElement[];
};

const RichTextRenderer: FC<Props> = ({ value }) => {
  const mapped = value.map((element, i) => {
    switch (element.type) {
      case 'paragraph':
        return (
          <RichTextBaseElement key={i}>
            <div>
              {element.children.map((child, j) => (
                <RichTextChild child={child} key={j} />
              ))}
            </div>
          </RichTextBaseElement>
        );
      case 'bullet-list':
        return (
          <RichTextList key={i}>
            {element.children.map((subElement, j) => (
              <RichTextListItem element={subElement} key={j} />
            ))}
          </RichTextList>
        );
      case 'video':
        return (
          <RichTextBaseElement key={i}>
            <RichTextVideo source={element.source} />
          </RichTextBaseElement>
        );
      case 'image':
        return (
          <RichTextBaseElement key={i}>
            <RichTextImage source={element.source} />
          </RichTextBaseElement>
        );
      default:
        return null;
    }
  });

  return <Wrapper>{mapped}</Wrapper>;
};

export default RichTextRenderer;
