/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useContext, useState } from 'react';

import { TSelectedPlace } from '../types/TPlace';

const LOCAL_STORAGE_PLACE_KEY = 'selectedPlaces';

const defaultContext: TPlaceContext = {
  places: null,
  setPlaces: () => {},
  clearPlaces: () => {},
};

type TPlaceContext = {
  places: TSelectedPlace[] | null;
  setPlaces: (places: TSelectedPlace[]) => void;
  clearPlaces: () => void;
};

const PlaceContext = createContext<TPlaceContext>(defaultContext);

const PlaceContextProvider: FC = ({ children }) => {
  const [places, setPlaceHook] = useState<TSelectedPlace[] | null>(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_PLACE_KEY) || 'null'),
  );

  const setPlaces = (newPlaces: TSelectedPlace[]) => {
    setPlaceHook(newPlaces);
    localStorage.setItem(LOCAL_STORAGE_PLACE_KEY, JSON.stringify(newPlaces));
  };

  const clearPlaces = () => {
    localStorage.removeItem(LOCAL_STORAGE_PLACE_KEY);
    setPlaceHook(null);
  };

  const value = { places, setPlaces, clearPlaces };
  return <PlaceContext.Provider value={value}>{children}</PlaceContext.Provider>;
};

const usePlaceInfo = () => {
  const context = useContext(PlaceContext);
  if (context === undefined) {
    throw new Error('usePlaceInfo must be used within a PlaceContextProvider');
  }
  return context;
};

export { PlaceContextProvider, usePlaceInfo };
