import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spaces.spacing4};
  font-size: ${({ theme }) => theme.fontSizes.body};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium}; ;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;

const Shortcut = styled.div`
  margin-left: ${({ theme }) => theme.spaces.spacing16};
  color: ${({ theme }) => theme.colors.coolGray30};
`;

type Props = {
  label: string;
  shortcut?: string;
};

const ToolbarTooltip: FC<Props> = ({ label, shortcut }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      {shortcut && <Shortcut>{shortcut}</Shortcut>}
    </Wrapper>
  );
};

export default ToolbarTooltip;
