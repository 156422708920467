import { DefaultTheme } from 'styled-components';

import { CheckboxChecked, CheckboxDisabled, CheckboxUnChecked } from '../..';
import { Round, LabelText } from './CheckboxStyles';

export const getDayCheckbox = (checked: boolean, text?: string) => (
  <Round $checked={checked}>
    <LabelText $checked={checked}>{text}</LabelText>
  </Round>
);

export const getIcon = (theme: DefaultTheme, checked?: boolean, disabled?: boolean) => {
  if (disabled) {
    if (checked) {
      return <CheckboxDisabled />;
    } else {
      return <CheckboxUnChecked fill={theme.colors.coolGray30} />;
    }
  }
  return checked ? <CheckboxChecked /> : <CheckboxUnChecked />;
};
