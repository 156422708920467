import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Caption1, MediumText, PlusIcon } from '@sim-admin-frontends/ui-shared';

import { TMessagingCategory } from '../../../types/TMessagingCategories';

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacing32};
`;

const Categories = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
`;

const CategoryRow = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  padding: ${({ theme }) => theme.spaces.spacing12};
  margin-bottom: ${({ theme }) => theme.spaces.spacing8};
`;

const CategoryInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CategoryName = styled(Caption1)`
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
`;

const CategoryEmails = styled.span`
  color: ${({ theme }) => theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.medium};
`;

const HeadingCategory = styled(MediumText)`
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  line-height: ${({ theme }) => theme.lineHeights.large};
`;

const TextCategory = styled(Caption1)`
  padding-top: ${({ theme }) => theme.spaces.spacing8};
`;

const ButtonAdd = styled(Button)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  padding: 0;
  margin-left: ${({ theme }) => theme.spaces.spacing8};
`;

const ButtonAddText = styled(Caption1)`
  margin-left: ${({ theme }) => theme.spaces.spacing8};
`;

type CategoryProps = {
  category: TMessagingCategory;
  onDelete: (category: TMessagingCategory) => Promise<void>;
  onSelect: (category: TMessagingCategory) => void;
};

const SettingsMessagesCategory: FC<CategoryProps> = ({ category, onDelete, onSelect }) => {
  const { t } = useTranslation();

  const onSelectClick = async () => {
    onSelect(category);
  };

  const onDeleteClick = () => {
    onDelete(category);
  };

  return (
    <CategoryRow key={category.name}>
      <CategoryInfo>
        <CategoryName>{category.name}</CategoryName>
        <CategoryEmails>
          {[category.email, ...(category.additionalEmails || [])].join(', ')}
        </CategoryEmails>
      </CategoryInfo>
      <Button
        size="smaller"
        variant="tertiary"
        onClick={onSelectClick}
        testId="Settings#Messaging#Category#Edit"
      >
        {t('settings.messaging.categories.edit')}
      </Button>
      <Button
        size="smaller"
        variant="tertiary"
        onClick={onDeleteClick}
        testId="Settings#Messaging#Category#Delete"
      >
        {t('settings.messaging.categories.delete')}
      </Button>
    </CategoryRow>
  );
};

type Props = {
  onAddCategoryClick: () => void;
  onDelete: (category: TMessagingCategory) => Promise<void>;
  onSelect: (category: TMessagingCategory) => void;
  categories: TMessagingCategory[];
};

const SettingsMessagesCategories: FC<Props> = ({
  categories,
  onSelect,
  onDelete,
  onAddCategoryClick,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <HeadingCategory>{t('settings.messaging.categories.heading')}</HeadingCategory>
      <TextCategory>{t('settings.messaging.categories.text')}</TextCategory>
      <Categories>
        {categories.map((category) => (
          <SettingsMessagesCategory
            key={category.name}
            category={category}
            onSelect={onSelect}
            onDelete={onDelete}
          />
        ))}
      </Categories>
      <ButtonAdd variant="tertiary" onClick={onAddCategoryClick}>
        <PlusIcon />
        <ButtonAddText>{t('settings.messaging.categories.create')}</ButtonAddText>
      </ButtonAdd>
    </Wrapper>
  );
};

export default SettingsMessagesCategories;
