import styled from 'styled-components';
import { FC } from 'react';

import { TBaseProps } from '../../types/TBaseComponent';
import { Select } from '../select/Select';
import { TSelectItems } from '../../types/TSelect';
import { TimeInput } from './TimeInput';

export interface TimePickerProps extends TBaseProps {
  onTimeChanged: (value: string) => void;
  onDayPeriodChanged: (value: string) => void;
  initialTime?: string;
  initialDayPeriod?: string;
  disabled?: boolean;
  isWritable?: boolean;
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: ${({ theme }) => theme.spaces.spacing8};
`;

const generateTimeOptions = (): TSelectItems => {
  const timeOptions: TSelectItems = [];
  for (let hour = 1; hour <= 12; hour++) {
    for (let minute = 0; minute <= 45; minute += 15) {
      const stringHour = hour < 10 ? '0' + hour.toString() : hour.toString();
      const stringMinute = minute === 0 ? '0' + minute.toString() : minute.toString();
      const fullTime = `${stringHour}:${stringMinute}`;
      const option = {
        label: fullTime,
        value: fullTime,
      };
      timeOptions.push(option);
    }
  }
  return timeOptions;
};

const timeOptions = generateTimeOptions();

const dayPeriodOptions: TSelectItems = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' },
];

const TimePicker: FC<TimePickerProps> = ({
  onTimeChanged,
  onDayPeriodChanged,
  className,
  initialTime,
  initialDayPeriod,
  testId,
  disabled,
  isWritable = false,
}) => {
  const initialTimeOption =
    timeOptions.find((option) => option.label === initialTime) || timeOptions[0];
  const initialDayPeriodOption =
    dayPeriodOptions.find((option) => option.label === initialDayPeriod) || dayPeriodOptions[0];

  return (
    <Wrapper className={className}>
      {isWritable ? (
        <TimeInput
          onChange={onTimeChanged}
          defaultValue={initialTime}
          testId={`${testId}-writableTime`}
          disabled={disabled}
        />
      ) : (
        <Select
          options={timeOptions}
          onChange={(value) => onTimeChanged(value?.[0].value || timeOptions[0].value)}
          defaultValue={initialTimeOption}
          testId={`${testId}-time`}
          disabled={disabled}
        />
      )}
      <Select
        options={dayPeriodOptions}
        onChange={(value) => onDayPeriodChanged(value?.[0].value || dayPeriodOptions[0].value)}
        defaultValue={initialDayPeriodOption}
        testId={`${testId}-period`}
        disabled={disabled}
      />
    </Wrapper>
  );
};

export { TimePicker };
