import { FC } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { device, SimplicityIconSmall, Caption1, Spinner } from '@sim-admin-frontends/ui-shared';
import { useInstitutionQuery } from '@sim-admin-frontends/data-access';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import ROUTES from '../../../routing/routes';
import { useAuthInfo } from '../../../contexts/userContext';
import { HEADER_HEIGHT_PX } from '../../../constants/Constants';

const SpinnerWrapper = styled.div`
  width: 32px;
`;

const InstitutionPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT_PX}px;
  padding: ${({ theme }) => theme.spaces.spacing8};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.menuBackground};
`;

const Wrapper = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spaces.spacing48};
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spaces.spacing8};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};

  ${device.md} {
    justify-content: start;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.coolGray10};
  }
`;

const TitleWrapper = styled.div`
  width: 144px;
  height: ${({ theme }) => theme.spaces.spacing24};
  margin: 0 ${({ theme }) => theme.spaces.spacing8};
`;

const Title = styled(Caption1)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.large};
  display: none;
  ${device.md} {
    display: inline-block;
    flex: 1;
  }
`;

const sharedLogoStyle = css`
  &:hover {
    cursor: pointer;
  }
  width: 20px;
  height: ${({ theme }) => theme.spaces.spacing24};
  padding: 3px 5px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
`;

const SimplicityLogo = styled(SimplicityIconSmall)`
  ${sharedLogoStyle};
`;

const InstitutionLogo = styled.img`
  ${sharedLogoStyle};
`;

const InstitutionHeader: FC = () => {
  const history = useHistory();

  const onLogoClick = () => {
    history.push(ROUTES.home.path);
  };

  const { institutionUuid } = useAuthInfo();
  const { data, isLoading } = useInstitutionQuery({
    id: institutionUuid || '',
  });

  if (isLoading)
    return (
      <InstitutionPanelWrapper>
        <Wrapper>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
          <TitleWrapper>
            <Skeleton />
          </TitleWrapper>
        </Wrapper>
      </InstitutionPanelWrapper>
    );

  const logo = data?.institution?.branding?.avatar?.links?.self;
  const title = data?.institution?.name;

  return (
    <InstitutionPanelWrapper>
      <Wrapper>
        {logo ? (
          <InstitutionLogo src={logo} alt="institution logo" onClick={onLogoClick} />
        ) : (
          <SimplicityLogo onClick={onLogoClick} />
        )}
        <TitleWrapper>
          <Title data-testid="HeaderInstitution#title">{title}</Title>
        </TitleWrapper>
      </Wrapper>
    </InstitutionPanelWrapper>
  );
};

export default InstitutionHeader;
