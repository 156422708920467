import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import PollEditContainer from '../../components/polls/edit/PollEdit.container';

type PollsEditPageProps = {
  id?: string;
};

const PollsEditPage: FC<RouteComponentProps<PollsEditPageProps>> = ({ match }) => (
  <PollEditContainer id={match?.params?.id} />
);

export default PollsEditPage;
