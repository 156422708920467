import React, { FC } from 'react';
import styled from 'styled-components';
import { TCustomText } from '@simplicity-tech/sim-slate-types';

const Text = styled.span<{ bold: boolean; italic: boolean }>`
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  flex-wrap: wrap;
  flex: 1;
`;

type Props = {
  content: TCustomText;
};

const RichTextText: FC<Props> = ({ content }) => {
  const { bold = false, italic = false, text } = content;

  return (
    <Text italic={italic} bold={bold}>
      {text}
    </Text>
  );
};

export default RichTextText;
