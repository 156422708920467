import { PageHeader } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { PageWrapper } from '../../components/layout/PageLayout';
import MarketTabs from '../../components/market/view/MarketTabs';
import { useAuthInfo } from '../../contexts/userContext';

const MarketListPage: FC = () => {
  const { t } = useTranslation();
  const { visitorMode } = useAuthInfo();

  if (!visitorMode) {
    return <Redirect to="/404" />;
  }

  return (
    <PageWrapper>
      <PageHeader
        title={t('market.pageTitle')}
        caption={t('market.pageCaption')}
        testId="MarketTabs#PageHeader"
      />
      <MarketTabs />
    </PageWrapper>
  );
};

export default MarketListPage;
