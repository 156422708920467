import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isQuickQuestion,
  ModerationPostsByFilterInput,
  MODERATOR_LISTING_PAGE_SIZE,
  TModeratorListItem,
  MODERATOR_LISTING_FILTER_PLACE_UUIDS,
  useInfiniteModeratorsQuery,
} from '@sim-admin-frontends/data-access';
import { Error, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';
import { useOffsetTablePaging } from '@sim-admin-frontends/utils-shared';

import ModeratorView from './ModeratorView';
import { useAuthInfo } from '../../../contexts/userContext';
import { createTableColumns } from './helper';
import { usePlaceInfo } from '../../../contexts/placeContext';
import { getAppLang } from '../../../i18n/i18n';

interface ModeratorViewContainerProps {
  searchFilter: string;
}

const ModeratorViewContainer: FC<ModeratorViewContainerProps> = ({ searchFilter }) => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();

  const filter: ModerationPostsByFilterInput = useMemo(
    () => ({
      lang: getAppLang(),
      institutionUuids: [institutionUuid],
      placeUuids: MODERATOR_LISTING_FILTER_PLACE_UUIDS,
      text: searchFilter || undefined,
    }),
    [institutionUuid, searchFilter],
  );

  const { fetchNextPage, hasNextPage, data, isLoading, isFetchingNextPage, isError, refetch } =
    useInfiniteModeratorsQuery(filter);

  const { currentPageIndex, maxPageIndex, onPreviousPress, onNextPress, setCurrentPageIndex } =
    useOffsetTablePaging(fetchNextPage, MODERATOR_LISTING_PAGE_SIZE, hasNextPage);

  useEffect(() => {
    setCurrentPageIndex(0);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [institutionUuid]);

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const columns = createTableColumns(t, refetch, places?.[0].timezoneCode);
  const moderators = data?.pages[currentPageIndex]?.moderationPostsBy.posts.filter(
    (post) => !isQuickQuestion(post),
  );
  const itemsCount = data?.pages[currentPageIndex]?.moderationPostsBy.totalCount;
  const loading = isLoading || isFetchingNextPage;

  return (
    <ModeratorView
      hasNextPage={hasNextPage || currentPageIndex < maxPageIndex}
      loading={loading}
      itemsCount={itemsCount as number}
      pageIndex={currentPageIndex}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      columns={columns}
      data={(moderators as TModeratorListItem[]) || []}
    />
  );
};

export default ModeratorViewContainer;
