import React, { useCallback } from 'react';
import styled from 'styled-components';

const BOTTOM_ROW_FLEX = 0.75;
const TOP_ROW_FLEX = 0.6;

const Wrapper = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.radius16};
  width: 100%;
  aspect-ratio: 1.775;
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  margin-bottom: ${({ theme }) => theme.spaces.spacing8};
  border-color: ${({ theme }) => theme.colors.coolGray20};
  border-width: 1px;
  overflow: hidden;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

const BottomRow = styled.div<{ flex?: number }>`
  display: flex;
  flex-direction: row;
  flex: ${({ flex }) => flex || 1};
  overflow: hidden;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const NumberLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: ${({ theme }) => theme.spaces.spacing8};
  right: ${({ theme }) => theme.spaces.spacing16};
  text-align: center;
`;

const ImageWrapper = styled.div<{
  leftMargin?: boolean;
  topMargin?: boolean;
  flex?: number;
  darken?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex || 1};
  margin-left: ${({ theme, leftMargin }) => (leftMargin ? theme.spaces.spacing4 : 0)};
  margin-top: ${({ theme, topMargin }) => (topMargin ? theme.spaces.spacing4 : 0)};
  background-color: ${({ darken, theme }) => (darken ? theme.colors.coolGray100 : 'transparent')};
  overflow: hidden;
`;

const BaseImage = styled.img<{
  leftMargin?: boolean;
  topMargin?: boolean;
  rightMargin?: boolean;
  bottomMargin?: boolean;
  flex?: number;
  darken?: boolean;
  smallerWidth?: number;
}>`
  height: 100%;
  width: ${({ smallerWidth }) => (smallerWidth ? `calc(100% - ${smallerWidth}px)` : '100%')};
  margin-left: ${({ theme, leftMargin }) => (leftMargin ? theme.spaces.spacing4 : 0)};
  margin-top: ${({ theme, topMargin }) => (topMargin ? theme.spaces.spacing4 : 0)};
  margin-right: ${({ theme, rightMargin }) => (rightMargin ? theme.spaces.spacing8 : 0)};
  margin-bottom: ${({ theme, bottomMargin }) => (bottomMargin ? theme.spaces.spacing4 : 0)};
  opacity: ${({ darken }) => (darken ? 0.4 : 1)};
  overflow: hidden;
  object-fit: cover;
`;

type TImageData = string[];

const useImageGrid = (imageData?: TImageData) => {
  // Functions to render grid for each value
  const renderLessThan3 = useCallback(
    (imageArray: TImageData) => (
      <TopRow>
        {imageArray.map((image, index) => (
          <ImageWrapper key={index}>
            <BaseImage
              smallerWidth={index === 0 && imageArray.length > 1 ? 4 : undefined}
              rightMargin={index === 0}
              leftMargin={index === 1}
              src={image}
            />
          </ImageWrapper>
        ))}
      </TopRow>
    ),
    [imageData],
  );

  const render3 = useCallback(
    (imageArray: TImageData) => (
      <ColumnWrapper>
        <TopRow>
          {imageArray.map((image, index) =>
            index < 2 ? (
              <ImageWrapper flex={index === 0 ? TOP_ROW_FLEX : undefined} key={index}>
                <BaseImage
                  rightMargin={index === 0}
                  smallerWidth={index === 0 ? 4 : undefined}
                  leftMargin={index === 1}
                  bottomMargin
                  src={image}
                />
              </ImageWrapper>
            ) : null,
          )}
        </TopRow>
        <BottomRow>
          {imageArray.map((image, index) =>
            index >= 2 ? (
              <ImageWrapper key={index}>
                <BaseImage topMargin src={image} />
              </ImageWrapper>
            ) : null,
          )}
        </BottomRow>
      </ColumnWrapper>
    ),
    [imageData],
  );

  const render4 = useCallback(
    (imageArray: TImageData) => (
      <ColumnWrapper>
        <TopRow>
          {imageArray.map((image, index) =>
            index < 2 ? (
              <ImageWrapper key={index}>
                <BaseImage
                  leftMargin={index === 1}
                  smallerWidth={index === 0 ? 4 : undefined}
                  rightMargin={index === 0}
                  bottomMargin
                  src={image}
                />
              </ImageWrapper>
            ) : null,
          )}
        </TopRow>
        <BottomRow>
          {imageArray.map((image, index) =>
            index >= 2 ? (
              <ImageWrapper key={index}>
                <BaseImage
                  topMargin
                  leftMargin={index === 3}
                  smallerWidth={index === 2 ? 4 : undefined}
                  rightMargin={index === 2}
                  src={image}
                />
              </ImageWrapper>
            ) : null,
          )}
        </BottomRow>
      </ColumnWrapper>
    ),
    [imageData],
  );

  const render5 = useCallback(
    (imageArray: TImageData) => (
      <ColumnWrapper>
        <TopRow>
          {imageArray.map((image, index) =>
            index < 2 ? (
              <ImageWrapper key={index}>
                <BaseImage
                  leftMargin={index === 1}
                  rightMargin={index === 0}
                  smallerWidth={index === 0 ? 4 : undefined}
                  bottomMargin
                  src={image}
                />
              </ImageWrapper>
            ) : null,
          )}
        </TopRow>
        <BottomRow flex={BOTTOM_ROW_FLEX}>
          {imageArray.map((image, index) =>
            index >= 2 ? (
              <ImageWrapper key={index}>
                <BaseImage
                  topMargin
                  smallerWidth={index === 2 ? 4 : 8}
                  leftMargin={index !== 2}
                  rightMargin={index !== 4}
                  src={image}
                />
              </ImageWrapper>
            ) : null,
          )}
        </BottomRow>
      </ColumnWrapper>
    ),
    [imageData],
  );

  const renderMoreThan5 = useCallback(
    (imageArray: TImageData) => (
      <ColumnWrapper>
        <TopRow>
          {imageArray.map((image, index) =>
            index < 2 ? (
              <ImageWrapper key={index}>
                <BaseImage
                  leftMargin={index === 1}
                  rightMargin={index === 0}
                  smallerWidth={index === 0 ? 4 : undefined}
                  bottomMargin
                  src={image}
                />
              </ImageWrapper>
            ) : null,
          )}
        </TopRow>
        <BottomRow flex={BOTTOM_ROW_FLEX}>
          {imageArray.map((image, index) =>
            index >= 2 && index < 4 ? (
              <ImageWrapper key={index}>
                <BaseImage
                  topMargin
                  leftMargin={index !== 2}
                  rightMargin
                  src={image}
                  smallerWidth={index === 2 ? 4 : 8}
                />
              </ImageWrapper>
            ) : null,
          )}
          <ImageWrapper topMargin leftMargin darken>
            <BaseImage darken src={imageArray[4]} />
            <NumberLabel>+{imageArray.length - 5}</NumberLabel>
          </ImageWrapper>
        </BottomRow>
      </ColumnWrapper>
    ),
    [imageData],
  );

  // Render full grid
  const renderGrid = useCallback(
    (imageArray: TImageData) => {
      if (!imageArray || !imageArray.length) {
        return null;
      }
      // 1 or 2 images
      if (imageArray.length < 3) {
        return renderLessThan3(imageArray);
      }
      // 3 images
      if (imageArray.length === 3) {
        return render3(imageArray);
      }
      // 4 images
      if (imageArray.length === 4) {
        return render4(imageArray);
      }
      // 5 images
      if (imageArray.length === 5) {
        return render5(imageArray);
      }
      // 6 or more images
      if (imageArray.length > 5) {
        return renderMoreThan5(imageArray);
      }
      // default
      return null;
    },
    [imageData],
  );

  const renderImageGrid = useCallback(
    (imageArray: TImageData) => <Wrapper>{renderGrid(imageArray)}</Wrapper>,
    [imageData],
  );

  return { renderImageGrid };
};

export { useImageGrid };
