import {
  useDeleteAnnouncementMutation,
  usePublishAnnouncementMutation,
  useUnpublishAnnouncementMutation,
} from '@sim-admin-frontends/data-access';
import {
  ConfirmModal,
  EditIcon,
  EyeIcon,
  EyeSlashIcon,
  loadingToast,
  TableIconProps,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { useAuthInfo } from '../../contexts/userContext';
import ROUTES from '../../routing/routes';
import { TItemAction } from '../../types/TAnalytics';
import { TAnnouncementsTabTypes } from '../../types/TAnnouncements';
import { RaffleModalActions } from '../../types/TRaffles';
import { AnalyticsEvent } from '../../utils/analytics/analyticsEvents';
import { analyticsLogEvent } from '../../utils/analytics/analyticsUtils';
import { changeRafflesTab } from '../../utils/raffleUtils';

type Props = {
  raffleId: string;
  refetch: () => void;
  published: boolean;
  isEditPage?: boolean;
};

export const useGenerateRaffleActions = ({ raffleId, refetch, published, isEditPage }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(RaffleModalActions.UNPUBLISH);
  const { t } = useTranslation();
  const history = useHistory();
  const { institutionUuid } = useAuthInfo();

  const editPath = generatePath(ROUTES.rafflesEdit.path, raffleId ? { id: raffleId } : undefined);

  const { mutate: deleteMutation } = useDeleteAnnouncementMutation({
    onMutate: () =>
      loadingToast(t('raffles.toast.delete.loading'), {
        toastId: RaffleModalActions.DELETE,
      }),
    onError: () => {
      updateToast(RaffleModalActions.DELETE, t('raffles.toast.delete.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      analyticsLogEvent({
        type: AnalyticsEvent.RAFFLE_ACTION,
        data: { Id: raffleId, Action: TItemAction.DELETE, InstitutionId: institutionUuid },
      });
      updateToast(RaffleModalActions.DELETE, t('raffles.toast.delete.success'), TToastType.SUCCESS);
      changeRafflesTab(history, TAnnouncementsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const { mutate: unpublishMutation } = useUnpublishAnnouncementMutation({
    onMutate: () =>
      loadingToast(t('raffles.toast.unpublish.loading'), {
        toastId: RaffleModalActions.UNPUBLISH,
      }),
    onError: () => {
      updateToast(
        RaffleModalActions.UNPUBLISH,
        t('raffles.toast.unpublish.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      analyticsLogEvent({
        type: AnalyticsEvent.RAFFLE_ACTION,
        data: { Id: raffleId, Action: TItemAction.UNPUBLISH, InstitutionId: institutionUuid },
      });
      updateToast(
        RaffleModalActions.UNPUBLISH,
        t('raffles.toast.unpublish.success'),
        TToastType.SUCCESS,
      );
      changeRafflesTab(history, TAnnouncementsTabTypes.UNPUBLISHED);
      refetch();
    },
  });

  const { mutate: publishMutation } = usePublishAnnouncementMutation({
    onMutate: () =>
      loadingToast(t('raffles.toast.publish.loading'), {
        toastId: RaffleModalActions.PUBLISH,
      }),
    onError: () => {
      updateToast(RaffleModalActions.PUBLISH, t('raffles.toast.publish.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      analyticsLogEvent({
        type: AnalyticsEvent.RAFFLE_ACTION,
        data: {
          Id: raffleId,
          Action: TItemAction.PUBLISH,
          Notification: false,
          InstitutionId: institutionUuid,
        },
      });
      changeRafflesTab(history, TAnnouncementsTabTypes.PUBLISHED);
      updateToast(
        RaffleModalActions.PUBLISH,
        t('raffles.toast.publish.success'),
        TToastType.SUCCESS,
      );
      refetch();
    },
  });

  const deleteRaffle = async () => {
    await deleteMutation({
      id: raffleId,
    });
  };

  const unpublishRaffle = async () => {
    await unpublishMutation({
      id: raffleId,
    });
  };

  const publishRaffle = async () => {
    await publishMutation({
      id: raffleId,
    });
  };

  const handleDeleteClick = () => {
    setModalDescription(t('raffles.modal.deleteContent'));
    setModalClickAction(RaffleModalActions.DELETE);
    setIsModalVisible(true);
  };

  const handlePublishClick = () => {
    if (published) {
      setModalDescription(t('raffles.modal.unpublishContent'));
      setModalClickAction(RaffleModalActions.UNPUBLISH);
      setIsModalVisible(true);
    } else {
      setModalDescription(t('raffles.modal.publishContent'));
      setModalClickAction(RaffleModalActions.PUBLISH);
      setIsModalVisible(true);
    }
  };

  const handleConfirmClick = () => {
    if (modalClickAction === RaffleModalActions.DELETE) {
      deleteRaffle();
      setIsModalVisible(false);
    }
    if (modalClickAction === RaffleModalActions.UNPUBLISH) {
      unpublishRaffle();
      setIsModalVisible(false);
    }
    if (modalClickAction === RaffleModalActions.PUBLISH) {
      publishRaffle();
      setIsModalVisible(false);
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const generateItems = (): TableIconProps[] => [
    {
      label: published ? t('table.dropdown.unpublish') : t('table.dropdown.publish'),
      action: handlePublishClick,
      testId: published ? 'RafflesTableMenu#unpublish' : 'RafflesTableMenu#publish',
      icon: published ? <EyeSlashIcon /> : <EyeIcon />,
    },
    {
      label: t('table.dropdown.edit'),
      path: editPath,
      testId: 'RafflesTableMenu#edit',
      icon: <EditIcon />,
      hidden: isEditPage,
    },
    {
      label: t('table.dropdown.delete'),
      action: handleDeleteClick,
      testId: 'RafflesTableMenu#delete',
      icon: <TrashCanIcon />,
    },
  ];

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  const renderModal = () => (
    <ConfirmModal
      title={t('raffles.modal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('raffles.modal.backButton')}
      confirmButtonText={t('raffles.modal.confirmButton')}
      testId="Raffles#ConfirmModal"
    />
  );

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
