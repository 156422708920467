import React, {
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { Instance } from 'tippy.js';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';

import { focusPreviousSelection, insertLink, isMarkActive } from '../helpers/helper';
import { LinkIcon } from '../../..';
import { Input } from '../../input/Input';

const PopupLinkIcon = styled(LinkIcon)`
  padding: ${({ theme }) => `0 ${theme.spaces.spacing8}`};
`;

const StyledTippy = styled(Tippy)`
  &.tippy-box {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0;
    border-radius: ${({ theme }) => theme.borderRadius.radius8};
    border: 1px solid ${({ theme }) => theme.colors.coolGray10};
    box-shadow: 10px 10px 44px rgba(0, 0, 0, 0.04), 2px 2px 15px rgba(0, 0, 0, 0.08);

    > .tippy-content {
      padding: 0;
    }
  }
`;

const PopupWrapper = styled.div<{ hasContent: boolean }>`
  padding: ${({ theme }) => theme.spaces.spacing8};
  width: 320px;
  z-index: ${({ theme }) => theme.zIndex.wysiwygLinkTooltip};
  transition: height 0.33s ease-in-out;
  height: ${({ hasContent: isValidLink }) => (isValidLink ? 92 : 48)}px;
  overflow: hidden;
`;

const PopupButton = styled.button<{ hasContent: boolean; disabled: boolean }>`
  padding: ${({ theme }) => theme.spaces.spacing8};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.coolGray100};
  border-radius: ${({ theme }) => theme.borderRadius.radius4};
  padding: 10px ${({ theme }) => theme.spaces.spacing8};
  background-color: ${({ theme }) => theme.colors.white};
  outline: none;
  width: 100%;
  border: none;
  text-align: start;
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  opacity: ${({ hasContent }) => (hasContent ? 1 : 0)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: opacity 0.33s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.colors.coolGray10};
  }
`;

type Props = {
  // Necessary b/c of a typings problem in tippy-react.
  children: ReactElement;
};

const LinkTooltip = forwardRef<any, Props>(({ children }, ref) => {
  const editor = useSlate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [link, setLink] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const isFutureLink = isMarkActive(editor, 'futureLink');

  useEffect(() => {
    if (isFutureLink) setIsPopupOpen(true);
  }, [isFutureLink]);

  useEffect(() => {
    // Safari 🤠
    if (isPopupOpen) setTimeout(() => inputRef?.current?.focus(), 0);
  }, [isPopupOpen]);

  const handleConfirmClick = useCallback(() => {
    const linkCopy = `${link}`;
    clearLinkTooltip();
    insertLink(editor, linkCopy);
  }, [editor, link]);

  const onClickOutside = (_: Instance<unknown>, event: Event) => {
    event.preventDefault();
    clearLinkTooltip();
  };

  const clearLinkTooltip = () => {
    focusPreviousSelection(editor);
    Editor.removeMark(editor, 'futureLink');
    setLink('');
    setIsPopupOpen(false);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      handleConfirmClick();
    }
    if (event.code === 'Escape') {
      event.preventDefault();
      clearLinkTooltip();
    }
  };

  const handleWrapperClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <StyledTippy
      content={
        <PopupWrapper onMouseDown={handleWrapperClick} hasContent={!!link.length}>
          <Input
            placeholder={'Insert link'}
            value={link}
            onChange={(e) => setLink(e.target.value)}
            ref={inputRef}
            onKeyDown={handleKeyPress}
          />
          <PopupButton hasContent={!!link.length} onClick={handleConfirmClick} disabled={false}>
            <PopupLinkIcon />
            Create link
          </PopupButton>
        </PopupWrapper>
      }
      interactive
      placement="bottom-end"
      animation={'shift-toward'}
      arrow={'false'}
      appendTo="parent"
      visible={isPopupOpen}
      onClickOutside={onClickOutside}
      ref={ref}
      zIndex={9999}
    >
      {children}
    </StyledTippy>
  );
});

LinkTooltip.displayName = 'LinkTooltip';

export default LinkTooltip;
