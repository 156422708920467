const getEnvironmentLinkPrefix = () => {
  if (process.env.NX_AWS_GRAPHQL_ENDPOINT?.includes('testing')) {
    return 'testing-posts';
  }
  if (process.env.NX_AWS_GRAPHQL_ENDPOINT?.includes('staging')) {
    return 'staging-posts';
  }
  return 'posts';
};

export const getPostLinkLabel = (url: string) => {
  const start = url.substring(0, 8);
  const end = url.substring(url.length - 8);
  return `${start}...${end}`;
};

export const getPostLink = (id: string) => {
  const environmentPostsLinkPrefix = getEnvironmentLinkPrefix();
  const link = `https://${environmentPostsLinkPrefix}.onesimplicity.com/post/${id}`;
  return { link, label: getPostLinkLabel(link) };
};
