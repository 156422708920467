import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  AddCouponMutationVariables,
  InstitutionPlaceRole,
  UploadType,
  useCouponQuery,
  useInstiutionRoleQuery,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useUploadImage } from '@sim-admin-frontends/utils-shared';

import NotFound from '../../../routing/NotFound';
import { COUPON_TOAST_SHARED_ID } from '../../../constants/Constants';
import CouponEdit from './CouponEdit';
import { TCouponFormValues } from '../../../types/TCoupons';
import { changeCouponsTab, transformFormValues } from '../../../utils/couponsUtils';
import { TAnnouncementsTabTypes } from '../../../types/TAnnouncements';
import { useCouponSubmit } from '../../../hooks/useCouponSubmit';
import { useAuthInfo } from '../../../contexts/userContext';
import { useGenerateCouponActions } from '../../../hooks/actionButtons/useGenerateCouponActions';

type Props = {
  id?: string;
};

const CouponEditContainer: FC<Props> = ({ id }) => {
  const history = useHistory();
  const { institutionUuid } = useAuthInfo();
  const { t } = useTranslation();
  const { uploadFormImages } = useUploadImage();
  const { handleSubmitCoupon } = useCouponSubmit();

  const {
    data: iRoleData,
    isLoading: isIRoleLoading,
    isError: isIRoleError,
    refetch: iRoleRefetch,
  } = useInstiutionRoleQuery({ id: institutionUuid });

  const institutionRole = iRoleData?.institution?.inPlaces?.[0]?.role;

  const isEnabled = institutionRole === InstitutionPlaceRole.LocalBusiness;

  const {
    data,
    isLoading: isCouponLoading,
    isError: isCouponError,
    refetch: couponRefetch,
  } = useCouponQuery({ id: id || '' }, { enabled: !!id });

  const onDiscard = () => {
    changeCouponsTab(history, TAnnouncementsTabTypes.PUBLISHED);
  };

  const onSubmit = async (formValues: TCouponFormValues) => {
    try {
      loadingToast(t('updates.form.toastSubmitting'), {
        toastId: COUPON_TOAST_SHARED_ID,
      });
      const uploadedImages = await uploadFormImages(UploadType.Coupon, formValues.images, {
        institutionId: institutionUuid,
      });
      const mutationVariables: AddCouponMutationVariables = {
        coupon: transformFormValues(institutionUuid, formValues, uploadedImages),
      };
      await handleSubmitCoupon(data?.coupon?.publishmentState, mutationVariables, id);
    } catch (err) {
      updateToast(COUPON_TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const refetch = () => {
    couponRefetch();
    iRoleRefetch();
  };

  const { detailActions, renderModal } = useGenerateCouponActions({
    couponId: id || '',
    refetch,
    isEditPage: true,
  });

  const isLoading = isCouponLoading || isIRoleLoading;

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  const isError = isCouponError || isIRoleError;

  if (isError)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  if (id && !data?.coupon)
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );

  if (!isEnabled) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <CouponEdit
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        coupon={data?.coupon}
        couponFormActions={id ? detailActions : []}
      />
      {renderModal()}
    </>
  );
};
export default CouponEditContainer;
