import styled from 'styled-components';

export const ExtraSmallText = styled.div`
  color: ${({ theme }) => theme.colors.coolGray70};
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  line-height: ${({ theme }) => theme.lineHeights.small};
`;

export const SmallestText = styled.div`
  color: ${({ theme }) => theme.colors.coolGray70};
  font-size: ${({ theme }) => theme.fontSizes.smallest};
  line-height: ${({ theme }) => theme.lineHeights.small};
`;

export const SmallText = styled.div`
  color: ${({ theme }) => theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
`;

export const MediumText = styled.div`
  color: ${({ theme }) => theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: ${({ theme }) => theme.lineHeights.medium};
`;

export const LargeText = styled.div`
  color: ${({ theme }) => theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: ${({ theme }) => theme.lineHeights.large};
`;

export const Caption1 = styled.span`
  color: ${({ theme }) => theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.caption1};
  line-height: ${({ theme }) => theme.lineHeights.caption1};
`;

export const Heading1 = styled.div`
  color: ${({ theme }) => theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.heading1};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
`;
