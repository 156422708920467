import { FC } from 'react';
import { Table } from '@sim-admin-frontends/ui-shared';
import { SortingRule } from 'react-table';

import { TDonationListItem, TDonationsColumns } from '../../../types/TDonations';

export interface DonationsViewProps {
  data: TDonationListItem[];
  columns: TDonationsColumns;
  onNextPress: () => void;
  onPreviousPress: () => void;
  pageIndex: number;
  itemsCount: number;
  loading: boolean;
  onSortingChanged: (value: SortingRule<TDonationListItem>[]) => void;
  initialTableSorting: SortingRule<TDonationListItem>[];
  hasNextPage?: boolean;
}

const DonationsView: FC<DonationsViewProps> = ({
  data,
  columns,
  onNextPress,
  pageIndex,
  onPreviousPress,
  itemsCount,
  loading,
  onSortingChanged,
  initialTableSorting,
  hasNextPage,
}) => {
  return (
    <Table
      hasNextPage={hasNextPage}
      loading={loading}
      itemsCount={itemsCount}
      pageIndex={pageIndex}
      data={data}
      columns={columns}
      hasPagination={true}
      onNextPress={onNextPress}
      onPreviousPress={onPreviousPress}
      onSortingChanged={onSortingChanged}
      initialSortBy={initialTableSorting}
      testId="DonationsView#Table"
    />
  );
};

export { DonationsView };
