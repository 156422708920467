import useScript from './useScript';

const useInitFacebook = () =>
  useScript('https://connect.facebook.net/en_US/sdk.js', () => {
    FB.init({
      version: 'v12.0',
      xfbml: true,
      autoLogAppEvents: true,
      appId: process.env.NX_FB_APP_ID,
    });
  });

export default useInitFacebook;
