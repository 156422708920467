import styled from 'styled-components';
import { FC, useState } from 'react';
import { format, isValid, parse } from 'date-fns';
import { MaybeDate } from '@sim-admin-frontends/utils-shared';

import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';
import { TBaseProps } from '../../types/TBaseComponent';
import { TimePicker } from '../time-picker/TimePicker';
import { DatePicker } from '../date-picker/DatePicker';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: ${({ theme }) => theme.spaces.spacing16};
`;

const CloseButton = styled(CloseIcon)<{ $disabled: boolean }>`
  padding: 10px;
  &:hover {
    cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
  }
`;

const DATE_TIME_FORMAT = 'dd-MM-yyyy hh:mm a';
const DATE_FORMAT = 'dd-MM-yyyy';

const getInitialDateTime = (
  date: Date | undefined,
  time: string | undefined,
  period: string | undefined,
) => {
  if (!date || !isValid(date)) {
    return new Date();
  } else {
    return formatDateTime(date, time, period);
  }
};

const formatDateTime = (
  date: MaybeDate,
  time: string | undefined | null,
  period: string | undefined | null,
) => {
  if (!date) {
    return null;
  }
  return parse(`${format(date, DATE_FORMAT)} ${time} ${period}`, DATE_TIME_FORMAT, new Date());
};

const parseTime = (date: MaybeDate, lastTime?: string | null) =>
  date && isValid(date) ? format(date, 'hh:mm') : lastTime;
const parsePeriod = (date: MaybeDate, lastPeriod?: string | null) =>
  date && isValid(date) ? format(date, 'a') : lastPeriod;

export interface DateTimePickerProps extends TBaseProps {
  onChange?: (arg: MaybeDate) => void;
  initialTime?: string;
  initialDayPeriod?: string;
  initialDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  onCloseClick?: () => void;
  isDisabled?: boolean;
  isCloseDisabled?: boolean;
  isTimePickerWritable?: boolean;
}

const DateTimePicker: FC<DateTimePickerProps> = ({
  onChange,
  initialTime,
  initialDayPeriod,
  className,
  initialDate,
  minDate,
  maxDate,
  onCloseClick,
  testId,
  isDisabled = false,
  isCloseDisabled = false,
  isTimePickerWritable = false,
}) => {
  const now = new Date();

  const [date, setDate] = useState<MaybeDate>(
    getInitialDateTime(initialDate, initialTime, initialDayPeriod),
  );

  const handleCloseClick = () => {
    if (onCloseClick && !isCloseDisabled) {
      onCloseClick();
    }
  };

  const [lastTime, setLastTime] = useState<string | null | undefined>(null);
  const [lastPeriod, setLastPeriod] = useState<string | null | undefined>(null);

  const onDateChange = (newDate: MaybeDate) => {
    if (!newDate || !isValid(newDate)) {
      setLastPeriod(parsePeriod(date, lastPeriod));
      setLastTime(parseTime(date, lastTime));
      setDateAndNotify(null);
    } else {
      setLastPeriod(null);
      setLastTime(null);
      const period = parsePeriod(date, lastPeriod);
      const time = parseTime(date, lastTime);
      setDateAndNotify(formatDateTime(newDate, time, period));
    }
  };

  const onTimeChanged = (newTime: string) => {
    if (!date) {
      setLastTime(newTime);
      setDateAndNotify(null);
    } else {
      const period = parsePeriod(date);
      setDateAndNotify(formatDateTime(date, newTime, period));
    }
  };

  const onPeriodChanged = (newPeriod: string) => {
    if (!date) {
      setLastPeriod(newPeriod);
      setDateAndNotify(null);
    } else {
      const time = parseTime(date);
      setDateAndNotify(formatDateTime(date, time, newPeriod));
    }
  };

  const setDateAndNotify = (value: Date | null) => {
    setDate(value);
    if (onChange) onChange(value);
  };

  return (
    <Wrapper className={className}>
      <DatePicker
        minDate={minDate || undefined}
        maxDate={maxDate || undefined}
        initialValue={initialDate || now}
        onDateChanged={onDateChange}
        testId={testId}
        disabled={isDisabled}
      />
      <TimePicker
        onTimeChanged={onTimeChanged}
        onDayPeriodChanged={onPeriodChanged}
        initialTime={initialTime}
        initialDayPeriod={initialDayPeriod}
        testId={testId}
        disabled={isDisabled}
        isWritable={isTimePickerWritable}
      />
      {onCloseClick && <CloseButton onClick={handleCloseClick} $disabled={isCloseDisabled} />}
    </Wrapper>
  );
};

export { DateTimePicker };
