import {
  State,
  useCancelScheduledAnnouncementMutation,
  useDeleteAnnouncementMutation,
  usePublishAnnouncementMutation,
  useSendPostNotificationMutation,
  useUnpublishAnnouncementMutation,
} from '@sim-admin-frontends/data-access';
import {
  BellIcon,
  EditIcon,
  EyeIcon,
  EyeSlashIcon,
  RemoveCircleIcon,
  TToastType,
  TableIconProps,
  TrashCanIcon,
  loadingToast,
  updateToast,
  ConfirmModal,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { useState } from 'react';

import { useAuthInfo } from '../../contexts/userContext';
import { usePlaceInfo } from '../../contexts/placeContext';
import ROUTES from '../../routing/routes';
import { PollModalActions } from '../../types/TPolls';
import { changePollsTab } from '../../utils/pollsUtils';
import { TItemAction } from '../../types/TAnalytics';
import { TAnnouncementsTabTypes } from '../../types/TAnnouncements';
import { logAnnouncementEvent } from '../../utils/analytics/analyticsUtils';

type Props = {
  postId: string;
  refetch: () => void;
  isFromAdmin?: boolean | null;
  published: boolean;
  type: State;
  isEditPage?: boolean;
};

export const useGeneratePollActions = ({
  postId,
  refetch,
  isFromAdmin,
  published,
  type,
  isEditPage,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(PollModalActions.UNPUBLISH);
  const { institutionUuid } = useAuthInfo();
  const history = useHistory();
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const placeId = places?.[0]?.id || '';

  const editPath = generatePath(ROUTES.pollsEdit.path, postId ? { id: postId } : undefined);

  const { mutate: deleteMutation } = useDeleteAnnouncementMutation({
    onMutate: () => {
      loadingToast(t('polls.toast.delete.loading'), {
        toastId: PollModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(PollModalActions.DELETE, t('polls.toast.delete.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      logAnnouncementEvent(postId || '', institutionUuid, TItemAction.DELETE, false);
      updateToast(PollModalActions.DELETE, t('polls.toast.delete.success'), TToastType.SUCCESS);
      changePollsTab(history, TAnnouncementsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const { mutate: unpublishMutation } = useUnpublishAnnouncementMutation({
    onMutate: () => {
      loadingToast(t('polls.toast.unpublish.loading'), {
        toastId: PollModalActions.UNPUBLISH,
      });
    },
    onError: () => {
      updateToast(PollModalActions.UNPUBLISH, t('polls.toast.unpublish.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(
        PollModalActions.UNPUBLISH,
        t('polls.toast.unpublish.success'),
        TToastType.SUCCESS,
      );
      logAnnouncementEvent(postId || '', institutionUuid, TItemAction.UNPUBLISH, false);
      changePollsTab(history, TAnnouncementsTabTypes.UNPUBLISHED);
      refetch();
    },
  });

  const { mutate: publishMutation } = usePublishAnnouncementMutation({
    onMutate: () => {
      loadingToast(t('polls.toast.publish.loading'), {
        toastId: PollModalActions.PUBLISH,
      });
    },
    onError: () => {
      updateToast(PollModalActions.PUBLISH, t('polls.toast.publish.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      logAnnouncementEvent(postId || '', institutionUuid, TItemAction.PUBLISH);
      updateToast(PollModalActions.PUBLISH, t('polls.toast.publish.success'), TToastType.SUCCESS);
      changePollsTab(history, TAnnouncementsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const { mutate: cancelScheduled } = useCancelScheduledAnnouncementMutation({
    onMutate: () => {
      loadingToast(t('polls.toast.cancelSend.loading'), {
        toastId: PollModalActions.CANCEL_SEND,
      });
    },
    onError: () => {
      updateToast(
        PollModalActions.CANCEL_SEND,
        t('polls.toast.cancelSend.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logAnnouncementEvent(postId || '', institutionUuid, TItemAction.CANCEL_SEND);
      updateToast(
        PollModalActions.CANCEL_SEND,
        t('polls.toast.cancelSend.success'),
        TToastType.SUCCESS,
      );
      refetch();
    },
  });

  const { mutate: sendPostNotification } = useSendPostNotificationMutation({
    onMutate: () => {
      loadingToast(t('updates.toast.notification.loading'), {
        toastId: PollModalActions.NOTIFY,
      });
    },
    onError: () => {
      updateToast(PollModalActions.NOTIFY, t('updates.toast.notification.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      logAnnouncementEvent(postId || '', institutionUuid, TItemAction.NOTIFY);
      updateToast(
        PollModalActions.NOTIFY,
        t('updates.toast.notification.success'),
        TToastType.SUCCESS,
      );
    },
  });

  const deleteArticle = async () => {
    await deleteMutation({
      id: postId || '',
    });
  };

  const unpublishArticle = async () => {
    await unpublishMutation({
      id: postId || '',
    });
  };

  const publishArticle = async () => {
    await publishMutation({
      id: postId || '',
    });
  };

  const sendNotification = async () => {
    await sendPostNotification({
      postUuid: postId || '',
    });
  };

  const handleUnpublishClick = () => {
    setModalDescription(t('polls.modal.unpublishContent'));
    setModalClickAction(PollModalActions.UNPUBLISH);
    setIsModalVisible(true);
  };

  const handleNotifyClick = () => {
    setModalDescription(t('updates.modal.sendNotification'));
    setModalClickAction(PollModalActions.NOTIFY);
    setIsModalVisible(true);
  };

  const handleCancelSendClick = () => {
    cancelScheduled({
      id: postId || '',
      placeUuid: placeId,
    });
  };

  const handleDeleteClick = () => {
    setModalDescription(t('polls.modal.deleteContent'));
    setModalClickAction(PollModalActions.DELETE);
    setIsModalVisible(true);
  };

  const handlePublishClick = () => {
    setModalDescription(t('polls.modal.publishContent'));
    setModalClickAction(PollModalActions.PUBLISH);
    setIsModalVisible(true);
  };

  const handleConfirmClick = () => {
    if (modalClickAction === PollModalActions.DELETE) {
      deleteArticle();
      setIsModalVisible(false);
    }
    if (modalClickAction === PollModalActions.UNPUBLISH) {
      unpublishArticle();
      setIsModalVisible(false);
    }
    if (modalClickAction === PollModalActions.PUBLISH) {
      publishArticle();
      setIsModalVisible(false);
    }
    if (modalClickAction === PollModalActions.NOTIFY) {
      sendNotification();
      setIsModalVisible(false);
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const generateItems = (): TableIconProps[] => {
    if (!isFromAdmin) {
      return [
        {
          label: published ? t('table.dropdown.unpublish') : t('table.dropdown.publish'),
          action: published ? handleUnpublishClick : handlePublishClick,
          testId: published ? 'PollsTableMenu#unpublish' : 'PollsTableMenu#publish',
          icon: published ? <EyeSlashIcon /> : <EyeIcon />,
        },
      ];
    }
    switch (type) {
      case State.Draft:
        return [
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            testId: 'PollsTableMenu#edit',
            icon: <EditIcon />,
            hidden: isEditPage,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: 'PollsTableMenu#delete',
            icon: <TrashCanIcon />,
          },
        ];

      case State.Published:
        return [
          {
            label: t('table.dropdown.notify'),
            action: handleNotifyClick,
            testId: 'AnnouncementsTableMenu#notify',
            icon: <BellIcon />,
          },
          {
            label: t('table.dropdown.unpublish'),
            action: handleUnpublishClick,
            testId: 'PollsTableMenu#unpublish',
            icon: <EyeSlashIcon />,
          },
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            testId: 'PollsTableMenu#edit',
            icon: <EditIcon />,
            hidden: isEditPage,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: 'PollsTableMenu#delete',
            icon: <TrashCanIcon />,
          },
        ];

      case State.Scheduled:
        return [
          {
            label: t('table.dropdown.publish'),
            action: handlePublishClick,
            testId: 'PollsTableMenu#publish',
            icon: <EyeIcon />,
          },
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            testId: 'PollsTableMenu#edit',
            icon: <EditIcon />,
            hidden: isEditPage,
          },
          {
            label: t('table.dropdown.cancelSend'),
            action: handleCancelSendClick,
            testId: 'PollsTableMenu#cancelSend',
            icon: <RemoveCircleIcon />,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: 'PollsTableMenu#delete',
            icon: <TrashCanIcon />,
          },
        ];

      case State.Unpublished:
        return [
          {
            label: t('table.dropdown.publish'),
            action: handlePublishClick,
            testId: 'PollsTableMenu#publish',
            icon: <EyeIcon />,
          },
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            testId: 'PollsTableMenu#edit',
            icon: <EditIcon />,
            hidden: isEditPage,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: 'PollsTableMenu#delete',
            icon: <TrashCanIcon />,
          },
        ];
      default:
        return [];
    }
  };

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  const renderModal = () => (
    <ConfirmModal
      title={t('polls.modal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('polls.modal.backButton')}
      confirmButtonText={t('polls.modal.confirmButton')}
      testId="Polls#ConfirmModal"
    />
  );

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
