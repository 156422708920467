import { FieldErrors, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@sim-admin-frontends/ui-shared';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import { State } from '@sim-admin-frontends/data-access';

import { ButtonsWrapper, SaveDraftButtonWrapper } from './AnnouncementEditStyles';
import { determinePrimaryButtonLabel } from '../../../utils/announcementsUtils';
import useIsSystemDisabled from '../../../hooks/useIsSystemDisabled';

type FormButtonsProps<T extends FieldValues> = {
  isSubmitting: boolean;
  isDraft?: boolean;
  getValues: (payload?: string | string[]) => any;
  errors: FieldErrors<T>;
  publishmentState?: State;
  isScheduling?: boolean;
  onSaveDraftClick?: () => void;
  onDiscard: () => void;
  onSubmitClick: () => void;
  isSaveDraftButtonVisible?: boolean;
  isQuickQuestion?: boolean;
};

const FormButtons = <T extends FieldValues>({
  isSubmitting,
  isDraft = false,
  getValues,
  errors,
  publishmentState,
  isScheduling,
  onSaveDraftClick,
  onDiscard,
  onSubmitClick,
  isSaveDraftButtonVisible = false,
  isQuickQuestion,
}: FormButtonsProps<T>) => {
  const { t } = useTranslation();
  const { postsDisabled } = useIsSystemDisabled();

  const cantSaveAsDraft =
    publishmentState === State.Published || publishmentState === State.Unpublished;
  const isSaveDraftDisabled =
    isSubmitting || getValues(isQuickQuestion ? 'question' : 'title').length < 1 || cantSaveAsDraft;

  const isSubmitDisabled = isSubmitting || !isEmpty(errors) || postsDisabled;

  const isSaveDraftLoading = isDraft && isSubmitting;

  const isSubmitLoading = !isDraft && isSubmitting;

  const primaryButtonLabel = determinePrimaryButtonLabel(
    publishmentState,
    isScheduling || false,
    t,
  );

  return (
    <ButtonsWrapper>
      <SaveDraftButtonWrapper>
        {isSaveDraftButtonVisible && (
          <Button
            variant={'secondary'}
            onClick={onSaveDraftClick}
            isLoading={isSaveDraftLoading}
            disabled={isSaveDraftDisabled}
          >
            {t('updates.form.saveDraft')}
          </Button>
        )}
      </SaveDraftButtonWrapper>
      <div>
        <Button variant="tertiary" onClick={onDiscard} disabled={isSubmitting}>
          {t('updates.form.cancel')}
        </Button>
        <Button
          type="submit"
          onClick={onSubmitClick}
          testId={'PublishButton'}
          isLoading={isSubmitLoading}
          disabled={isSubmitDisabled}
          disabledText={postsDisabled ? t('updates.form.disabledText') : undefined}
        >
          {primaryButtonLabel}
        </Button>
      </div>
    </ButtonsWrapper>
  );
};

export default FormButtons;
