import { useState } from 'react';

export const useTablePaging = (fetchNextPage: () => void, hasNextPage?: boolean) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [maxPageIndex, setMaxPageIndex] = useState(0);

  const onNextPress = async () => {
    if (hasNextPage && maxPageIndex === currentPageIndex) {
      setMaxPageIndex(currentPageIndex + 1);
      await fetchNextPage();
    }
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const onPreviousPress = () => {
    setCurrentPageIndex(currentPageIndex - 1);
  };

  return { onNextPress, onPreviousPress, currentPageIndex, setCurrentPageIndex, maxPageIndex };
};
