import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SwitchableFormSection } from '@sim-admin-frontends/ui-shared';
import { State } from '@sim-admin-frontends/data-access';

import { SwitchableSectionContentWrapper } from '../../announcements/edit/AnnouncementEditStyles';
import useIsSystemDisabled from '../../../hooks/useIsSystemDisabled';
import { TDonationFormValues } from '../../../types/TDonations';
import { DonationsNotificationsSettings } from './DonationsNotificationsSettings';

export type DonationsNotificationSectionProps = {
  control: Control<TDonationFormValues>;
  notifyNow?: boolean;
  publishmentState?: State;
  isEdit: boolean;
  onNotificationsEnabledChange: (value: boolean) => void;
};

const DonationsNotificationSection = ({
  control,
  notifyNow,
  publishmentState,
  isEdit,
  onNotificationsEnabledChange,
}: DonationsNotificationSectionProps) => {
  const { t } = useTranslation();
  const { notificationsDisabled } = useIsSystemDisabled();

  const isNotificationDisabled =
    isEdit &&
    !!notifyNow &&
    (publishmentState == State.Published || publishmentState === State.Unpublished);

  return (
    <SwitchableFormSection
      title={t('updates.form.sendNotificationTitle')}
      description={t('donations.form.sendNotificationDescription')}
      initiallyOpened={notifyNow}
      onVisibilityChanged={onNotificationsEnabledChange}
      disabled={isNotificationDisabled || notificationsDisabled}
      disabledText={t('updates.form.disabledText')}
      testId={`DonationsNotification#switch`}
    >
      <SwitchableSectionContentWrapper>
        <DonationsNotificationsSettings control={control} />
      </SwitchableSectionContentWrapper>
    </SwitchableFormSection>
  );
};

export default DonationsNotificationSection;
