import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { TFeatureFlagType } from '@sim-admin-frontends/data-access';
import { usePlaceQuery } from '@sim-admin-frontends/data-access-admin-be';
import { Error, Spinner, SpinnerWrapper } from '@sim-admin-frontends/ui-shared';

import { useIsPlaceAdmin } from './useIsPlaceAdmin';
import { isFeatureEnabled } from '../utils/placeUtils';
import { usePlaceInfo } from '../contexts/placeContext';
import { useAuthInfo } from '../contexts/userContext';

export const useFeatureFlag = (featureFlag: TFeatureFlagType) => {
  const { t } = useTranslation();
  const { places } = usePlaceInfo();
  const { isCity } = useAuthInfo();
  const { isPlaceAdmin } = useIsPlaceAdmin();
  const placeId = places?.[0]?.id || '';

  const { data, isLoading, isError, refetch } = usePlaceQuery({
    id: placeId,
  });

  const isEnabled = useMemo(
    () => isFeatureEnabled(data?.place?.featureFlags || [], featureFlag),
    [data?.place?.featureFlags, featureFlag],
  );

  const shouldShowFeatureFlagDisabledUI =
    (!isCity && !isPlaceAdmin) || isLoading || !places || isError || !isEnabled;

  const showFeatureFlagDisabledUI = () => {
    if (!isCity && !isPlaceAdmin) {
      return <Redirect to="/404" />;
    }

    if (isLoading || !places) {
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );
    }

    if (isError) {
      return (
        <SpinnerWrapper>
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        </SpinnerWrapper>
      );
    }

    if (!isEnabled) {
      return <Redirect to="/404" />;
    }
    return null;
  };

  return { shouldShowFeatureFlagDisabledUI, showFeatureFlagDisabledUI };
};
