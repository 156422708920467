import { TTabPanelTabs } from '@sim-admin-frontends/ui-shared';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MessagingProblemStatus } from '@sim-admin-frontends/data-access-admin-be';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { StyledPanel } from '../../common/TabsStyles';
import ReportProblemViewContainer from './ReportProblemView.container';
import { getProblemStatusLabel } from '../../../utils/reportProblemUtils';

export const reportProblemPaths = [
  MessagingProblemStatus.New,
  MessagingProblemStatus.InProgress,
  MessagingProblemStatus.Rejected,
  MessagingProblemStatus.Solved,
];

const ReportProblemTabs: FC = () => {
  const { t } = useTranslation();
  const { setTabIndex, tabIndex } = useTabNavigation(ROUTES.reportProblem.path, reportProblemPaths);

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: <ReportProblemViewContainer status={MessagingProblemStatus.New} />,
        label: getProblemStatusLabel(MessagingProblemStatus.New, t),
        testId: 'ReportProblemTabs#new',
      },
      {
        content: <ReportProblemViewContainer status={MessagingProblemStatus.InProgress} />,
        label: getProblemStatusLabel(MessagingProblemStatus.InProgress, t),
        testId: 'ReportProblemTabs#inProgress',
      },
      {
        content: <ReportProblemViewContainer status={MessagingProblemStatus.Rejected} />,
        label: getProblemStatusLabel(MessagingProblemStatus.Rejected, t),
        testId: 'ReportProblemTabs#rejected',
      },
      {
        content: <ReportProblemViewContainer status={MessagingProblemStatus.Solved} />,
        label: getProblemStatusLabel(MessagingProblemStatus.Solved, t),
        testId: 'ReportProblemTabs#solved',
      },
    ],
    [t],
  );

  return <StyledPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex} />;
};

export default ReportProblemTabs;
