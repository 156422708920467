import { captureException } from '@sentry/react';
import {
  CategoryType,
  isAnnouncement,
  State,
  useAnnouncementQuery,
  useCategoriesQuery,
  useInstitutionQuery,
  useUpdateAnnouncementCategoriesMutation,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  getErrorMessage,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { filterCategories } from '@sim-admin-frontends/utils-shared';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ANNOUNCEMENT_TOAST_SHARED_ID } from '../../../constants/Constants';
import { useAuthInfo } from '../../../contexts/userContext';
import NotFound from '../../../routing/NotFound';
import {
  TAnnouncementsTabTypes,
  TScrapedAnnouncementFormValues,
} from '../../../types/TAnnouncements';
import { changeAnnouncementsTab } from '../../../utils/announcementsUtils';
import ScrapedAnnouncementEdit from './ScrapedAnnouncementEdit';

type Props = {
  id: string;
};

const ScrapedAnnouncementEditContainer: FC<Props> = ({ id = '' }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { institutionUuid, visitorMode } = useAuthInfo();
  const { data: initialInstitution } = useInstitutionQuery({
    id: institutionUuid || '',
  });

  const {
    data,
    isLoading: isAnnouncementLoading,
    error: announcementError,
    refetch,
  } = useAnnouncementQuery({ id: id || '' });

  const announcementData = isAnnouncement(data?.announcement) ? data?.announcement : undefined;

  const {
    isLoading: areCategoriesLoading,
    error: categoriesError,
    data: allCategories,
  } = useCategoriesQuery({
    filter: {
      categoryType: CategoryType.Announcement,
      visitorMode,
    },
  });

  const categories = useMemo(
    () =>
      filterCategories(allCategories?.categoriesBy.categories || [], {
        filterLegacy: true,
      }),
    [allCategories?.categoriesBy.categories],
  );

  const { mutateAsync: updateCategories } = useUpdateAnnouncementCategoriesMutation();

  const onDiscard = () => {
    changeAnnouncementsTab(history, TAnnouncementsTabTypes.PUBLISHED);
  };

  const onSuccess = () => {
    const state = announcementData?.publishmentState;
    changeAnnouncementsTab(
      history,
      state && state === State.Unpublished
        ? TAnnouncementsTabTypes.UNPUBLISHED
        : TAnnouncementsTabTypes.PUBLISHED,
    );
  };

  const onSubmit = async (formValues: TScrapedAnnouncementFormValues) => {
    try {
      loadingToast(t('updates.form.toastSubmitting'), {
        toastId: ANNOUNCEMENT_TOAST_SHARED_ID,
      });
      await updateCategories({
        id: id,
        categoryUuids: formValues.categories?.map((category) => category.value) ?? [],
      });
      updateToast(ANNOUNCEMENT_TOAST_SHARED_ID, t('updates.form.toastEdit'), TToastType.SUCCESS);
      onSuccess();
    } catch (err) {
      updateToast(ANNOUNCEMENT_TOAST_SHARED_ID, getErrorMessage(err), TToastType.ERROR);
      captureException(err);
    }
  };

  const isLoading = isAnnouncementLoading || areCategoriesLoading;

  const isError = announcementError || categoriesError;
  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );

  if (isError)
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );

  if (id && !announcementData)
    return (
      <SpinnerWrapper>
        <NotFound />
      </SpinnerWrapper>
    );

  return (
    <ScrapedAnnouncementEdit
      institution={initialInstitution?.institution}
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      announcement={announcementData}
      categories={categories}
    />
  );
};

export default ScrapedAnnouncementEditContainer;
