import { QueryClient } from 'react-query';
import {
  ANNOUNCEMENTS_PAGE_SIZE,
  useAnnouncementDraftsQuery,
  useAnnouncementQuery,
  useCouponQuery,
} from '@sim-admin-frontends/data-access';

import { DEFAULT_LANGUAGE } from '../constants/Constants';

export const invalidateAnnouncementQuery = (queryClient: QueryClient, id: string) =>
  queryClient.invalidateQueries(
    useAnnouncementQuery.getKey({
      id,
    }),
  );

export const invalidateAnnouncementDraftsQuery = (queryClient: QueryClient) =>
  queryClient.invalidateQueries(
    useAnnouncementDraftsQuery.getKey({
      filter: {
        lang: DEFAULT_LANGUAGE,
      },
      pageOffset: 0, //TODO: use real offset
      pageSize: ANNOUNCEMENTS_PAGE_SIZE,
    }),
  );

export const invalidateCouponQuery = (queryClient: QueryClient, id: string) =>
  queryClient.invalidateQueries(
    useCouponQuery.getKey({
      id,
    }),
  );
