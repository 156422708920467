import React, { FC, ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { useImageGrid } from '@sim-admin-frontends/ui-shared';
import { ImageInput, Video } from '@sim-admin-frontends/data-access';
import { useDecodeFilesToBlobs } from '@sim-admin-frontends/utils-shared';

import { getTestId } from '../../../utils/testUtils';
import PreviewItemInstitution from '../../previewItem/PreviewItemInstitution';

const textColorStyle = (theme: DefaultTheme, hasContent: boolean, color?: string) => {
  if (hasContent) {
    return color ? `#${color}` : theme.colors.coolGray100;
  }
  return theme.colors.coolGray30;
};

const Wrapper = styled.div<{ bgColor?: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  position: relative;
  padding: 0 ${({ theme }) => theme.spaces.spacing16};
  background-color: ${({ bgColor }) => (bgColor ? `#${bgColor}` : 'transparent')};
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

const SmallText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.lineHeights.small};
`;

const Title = styled.div<{ hasContent: boolean; width?: number; color?: string }>`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  color: ${({ theme, hasContent, color }) => textColorStyle(theme, hasContent, color)};
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  word-wrap: break-word;
  ${({ width }) => width && `width: ${width}%;`}
`;

const Subtitle = styled.div<{ hasContent: boolean; width?: number; color?: string }>`
  margin-top: ${({ theme }) => theme.spaces.spacing16};
  color: ${({ theme, hasContent, color }) => textColorStyle(theme, hasContent, color)};
  font-size: ${({ theme }) => theme.fontSizes.caption1};
  line-height: ${({ theme }) => theme.lineHeights.medium};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  word-wrap: break-word;
  ${({ width }) => width && `width: ${width}%;`}
`;

const Footer = styled.div`
  padding: ${({ theme }) => theme.spaces.spacing16} 0px;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -${({ theme }) => theme.spaces.spacing16};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -10px 48px rgba(0, 0, 0, 0.04), 0px -2px 16px rgba(0, 0, 0, 0.02);
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spaces.spacing24};
`;

const ButtonText = styled(SmallText)`
  color: ${({ theme }) => theme.colors.coolGray60};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  padding: ${({ theme }) => theme.spaces.spacing8};
`;

const ImageWrapper = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.radius16};
  width: 100%;
  aspect-ratio: 1.775;
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  margin-bottom: ${({ theme }) => theme.spaces.spacing8};
  border-color: ${({ theme }) => theme.colors.coolGray20};
  border-width: 1px;
`;

const StyledVideo = styled.video`
  width: 100%;
  aspect-ratio: 1.775;
  border-radius: ${({ theme }) => theme.borderRadius.radius16};
`;

export const TEST_ID_PAGE_PREVIEW_PREFIX = 'PageContentPreview';

interface PreviewFooterButtonsProps {
  icon: ReactNode;
  text: string;
  testId?: string;
}

export interface PageContentPreviewProps {
  name: string;
  title?: string;
  titleWidth?: number;
  titlePlaceholder?: string;
  subtitle?: string;
  subtitleWidth?: number;
  subtitlePlaceholder?: string;
  textColor?: string;
  bgColor?: string;
  logo?: string;
  icons?: JSX.Element;
  images?: (File | ImageInput)[] | null;
  videos?: (File | Video)[] | null;
  buttons?: PreviewFooterButtonsProps[];
  testIdPrefix?: string;
}

const PageContentPreview: FC<PageContentPreviewProps> = ({
  name,
  logo,
  icons,
  title,
  titleWidth,
  titlePlaceholder,
  subtitle,
  subtitleWidth,
  subtitlePlaceholder,
  textColor,
  bgColor,
  images,
  videos,
  buttons,
  testIdPrefix = TEST_ID_PAGE_PREVIEW_PREFIX,
  children,
}) => {
  const previewTitle = title || titlePlaceholder;
  const previewSubtitle = subtitle || subtitlePlaceholder;
  const imageStrings = useDecodeFilesToBlobs(images);
  const videoStrings = useDecodeFilesToBlobs(videos);
  const { renderImageGrid } = useImageGrid(imageStrings);

  return (
    <Wrapper bgColor={bgColor}>
      <PreviewItemInstitution name={name} logo={logo} icons={icons} testIdPrefix={testIdPrefix} />
      {!!previewTitle && (
        <Title
          hasContent={!!title}
          width={titleWidth}
          color={textColor}
          data-testid={getTestId(testIdPrefix, 'Title')}
        >
          {previewTitle}
        </Title>
      )}
      {!!previewSubtitle && (
        <Subtitle
          hasContent={!!subtitle}
          width={subtitleWidth}
          color={textColor}
          data-testid={getTestId(testIdPrefix, 'Subtitle')}
        >
          {previewSubtitle}
        </Subtitle>
      )}
      {!!imageStrings.length && (
        <ImageWrapper data-testid={getTestId(testIdPrefix, 'Image')}>
          {renderImageGrid(imageStrings)}
        </ImageWrapper>
      )}
      {!!videoStrings.length && (
        <div>
          <StyledVideo
            data-testid={getTestId(testIdPrefix, 'Video')}
            src={videoStrings[0]}
            controls
          >
            <track kind="captions" />
          </StyledVideo>
        </div>
      )}
      {children}
      {buttons?.length && (
        <Footer>
          <ButtonsWrapper>
            {buttons.map(({ icon, text: buttonText, testId }, i) => (
              <FlexCenter key={buttonText}>
                {icon}
                <ButtonText data-testid={getTestId(testIdPrefix, testId || `Button${i}`)}>
                  {buttonText}
                </ButtonText>
              </FlexCenter>
            ))}
          </ButtonsWrapper>
        </Footer>
      )}
    </Wrapper>
  );
};

export default PageContentPreview;
