export enum TPetsTabsTypes {
  HOME = 'Home',
  LOST = 'Lost',
  FOUND = 'Found',
}

export enum TPetModalActions {
  DELETE = 'DELETE',
  UNPUBLISH = 'UNPUBLISH',
  PUBLISH = 'PUBLISH',
}
