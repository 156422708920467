import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  useInstitutionInfoQuery,
  useUpdateInstitutionInfoMutation,
} from '@sim-admin-frontends/data-access';
import {
  Error,
  loadingToast,
  Spinner,
  SpinnerWrapper,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useQueryClient } from 'react-query';

import { useAuthInfo } from '../../../contexts/userContext';
import ROUTES from '../../../routing/routes';
import { TInstitutionProfileFormValues } from '../../../types/TInstitution';
import SettingsProfile from './SettingsProfile';

const SHARED_TOAST_ID = 'institutionProfile';

const SettingsProfileContainer: FC = () => {
  const history = useHistory();
  const { institutionUuid } = useAuthInfo();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data, isLoading, isError, refetch } = useInstitutionInfoQuery({
    id: institutionUuid,
  });
  const { mutateAsync: updateInstitutionInfo } = useUpdateInstitutionInfoMutation({
    onMutate: () =>
      loadingToast(t('settings.profile.toast.saveLoading'), {
        toastId: SHARED_TOAST_ID,
      }),
    onError: (e: Error) => updateToast(SHARED_TOAST_ID, e.toString(), TToastType.ERROR),
    onSuccess: () =>
      updateToast(SHARED_TOAST_ID, t('settings.profile.toast.saveSuccess'), TToastType.SUCCESS),
  });

  const handleDiscard = () => {
    history.push(ROUTES.home.path);
  };

  const handleSubmit = async (values: TInstitutionProfileFormValues) => {
    await updateInstitutionInfo({
      id: institutionUuid,
      info: {
        ...values,
        location:
          values.location && values.location.name !== ''
            ? {
                geo: values.location.geo,
                address: values.location.name,
              }
            : undefined,
      },
    });
    queryClient.invalidateQueries(
      useInstitutionInfoQuery.getKey({
        id: institutionUuid,
      }),
    );
  };

  if (isLoading || isError) {
    return (
      <SpinnerWrapper>
        {isLoading ? (
          <Spinner />
        ) : (
          <Error caption={t('error.fetchingDataError')} onClick={refetch} />
        )}
      </SpinnerWrapper>
    );
  }
  return (
    <SettingsProfile
      institutionName={data?.institution?.name || 'Unknown'}
      onDiscard={handleDiscard}
      onSubmit={handleSubmit}
      data={data?.institution}
    />
  );
};

export default SettingsProfileContainer;
