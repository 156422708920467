import { TMapCenter } from '@sim-admin-frontends/utils-shared';

export type TOptions = {
  center: TMapCenter;
  osmType?: TOSMType;
  radius?: number;
};

export type TOSMShape = {
  id: string;
  display_name: string;
  geojson: {
    coordinates: TMapCenter[];
    type: string;
  };
};

export enum TOSMType {
  WAY = 'way',
  NODE = 'node',
  RELATION = 'relation',
  POLYGON = 'polygon',
  AREA = 'area',
}

export type TOSMShapes = TOSMShape[];

export type TOpenStreetMapFeatures = {
  features: {
    geometry: {
      coordinates: number[][];
      type: string;
    };
    properties: {
      display_name: string;
      osm_id: string;
    };
  }[];
};
