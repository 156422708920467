import { State } from '@sim-admin-frontends/data-access';
import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGeneratePollActions } from '../../../hooks/actionButtons/useGeneratePollActions';

export interface PollsTableMenuProps extends TBaseProps {
  postId: string;
  isFromAdmin?: boolean | null;
  refetch: () => void;
  published: boolean;
  type: State;
}

const PollsTableMenu: FC<PollsTableMenuProps> = ({
  postId,
  isFromAdmin,
  refetch,
  published,
  testId,
  type,
}) => {
  const { listActions: items, renderModal } = useGeneratePollActions({
    postId,
    refetch,
    isFromAdmin,
    published,
    type,
  });

  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export { PollsTableMenu };
