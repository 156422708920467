import { createGlobalStyle } from 'styled-components';

export const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Manrope';
    font-weight: 800;
    src: local('Manrope'), url(${'assets/fonts/Manrope-ExtraBold.otf'}) format('opentype');
  }

  @font-face {
    font-family: 'Manrope';
    font-weight: 700;
    src: local('Manrope'), url('assets/fonts/Manrope-Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Manrope';
    font-weight: 600;
    src: local('Manrope'), url('assets/fonts/Manrope-SemiBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Manrope';
    font-weight: 500;
    src: local('Manrope'), url('assets/fonts/Manrope-Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'Manrope';
    src: local('Manrope'), url('assets/fonts/Manrope-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'Manrope';
    font-weight: 300;
    src: local('Manrope'), url('assets/fonts/Manrope-Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'Manrope';
    font-weight: 200;
    src: local('Manrope'), url('assets/fonts/Manrope-ExtraLight.otf') format('opentype');
  }
`;

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fonts.primary}, serif;
    color: ${({ theme }) => theme.colors.lightText};
    margin: 0;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1 !important;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0 !important;
  }
`;
