import {
  useDeleteAnnouncementMutation,
  usePinAnnouncementMutation,
  useUnpinAnnouncementMutation,
} from '@sim-admin-frontends/data-access';
import {
  EditIcon,
  EyeIcon,
  EyeSlashIcon,
  TToastType,
  TableIconProps,
  TrashCanIcon,
  loadingToast,
  updateToast,
  ConfirmModal,
} from '@sim-admin-frontends/ui-shared';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { logEmergencyEvent } from '../../utils/analytics/analyticsUtils';
import ROUTES from '../../routing/routes';
import { TItemAction } from '../../types/TAnalytics';
import { useAuthInfo } from '../../contexts/userContext';
import { usePlaceInfo } from '../../contexts/placeContext';
import { EmergencyModalActions, TEmergencyTabTypes } from '../../types/TEmergency';
import { changeEmergencyTab } from '../../utils/emergencyUtils';

type Props = {
  postId: string;
  pinned: boolean;
  refetch: () => void;
  isEditPage?: boolean;
};

export const useGenerateEmergencyActions = ({ postId, pinned, refetch, isEditPage }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(EmergencyModalActions.UNPIN);
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();
  const { places } = usePlaceInfo();
  const placeId = places?.[0]?.id || '';
  const history = useHistory();

  const editPath = generatePath(ROUTES.emergencyEdit.path, postId ? { id: postId } : undefined);

  const { mutate: deleteMutation } = useDeleteAnnouncementMutation({
    onMutate: () => {
      loadingToast(t('emergency.toast.delete.loading'), {
        toastId: EmergencyModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(
        EmergencyModalActions.DELETE,
        t('emergency.toast.delete.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logEmergencyEvent(postId || '', institutionUuid, TItemAction.DELETE, false);
      updateToast(
        EmergencyModalActions.DELETE,
        t('emergency.toast.delete.success'),
        TToastType.SUCCESS,
      );
      refetch();
    },
  });

  const { mutate: unpinMutation } = useUnpinAnnouncementMutation({
    onMutate: () => {
      loadingToast(t('emergency.toast.unpublish.loading'), {
        toastId: EmergencyModalActions.UNPIN,
      });
    },
    onError: () => {
      updateToast(
        EmergencyModalActions.UNPIN,
        t('emergency.toast.unpublish.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      updateToast(
        EmergencyModalActions.UNPIN,
        t('emergency.toast.unpublish.success'),
        TToastType.SUCCESS,
      );
      logEmergencyEvent(postId || '', institutionUuid, TItemAction.UNPUBLISH, false);
      changeEmergencyTab(history, TEmergencyTabTypes.HIDDEN);
      refetch();
    },
  });

  const { mutate: pinMutation } = usePinAnnouncementMutation({
    onMutate: () => {
      loadingToast(t('emergency.toast.publish.loading'), {
        toastId: EmergencyModalActions.PIN,
      });
    },
    onError: () => {
      updateToast(EmergencyModalActions.PIN, t('emergency.toast.publish.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      logEmergencyEvent(postId || '', institutionUuid, TItemAction.PUBLISH);
      updateToast(
        EmergencyModalActions.PIN,
        t('emergency.toast.publish.success'),
        TToastType.SUCCESS,
      );
      changeEmergencyTab(history, TEmergencyTabTypes.VISIBLE);
      refetch();
    },
  });

  const deleteArticle = async () => {
    await deleteMutation({
      id: postId || '',
    });
  };

  const unpinArticle = async () => {
    await unpinMutation({
      id: postId || '',
      placeUuid: placeId,
    });
  };

  const pinArticle = async () => {
    await pinMutation({
      id: postId || '',
      placeUuid: placeId,
    });
  };

  const handleDeleteClick = () => {
    setModalDescription(t('emergency.modal.deleteContent'));
    setModalClickAction(EmergencyModalActions.DELETE);
    setIsModalVisible(true);
  };

  const handlePinClick = () => {
    setModalDescription(t('emergency.modal.pinContent'));
    setModalClickAction(EmergencyModalActions.PIN);
    setIsModalVisible(true);
  };

  const handleUnpinClick = () => {
    setModalDescription(t('emergency.modal.unpinContent'));
    setModalClickAction(EmergencyModalActions.UNPIN);
    setIsModalVisible(true);
  };

  const handleConfirmClick = () => {
    if (modalClickAction === EmergencyModalActions.DELETE) {
      deleteArticle();
      setIsModalVisible(false);
    }
    if (modalClickAction === EmergencyModalActions.UNPIN) {
      unpinArticle();
      setIsModalVisible(false);
    }
    if (modalClickAction === EmergencyModalActions.PIN) {
      pinArticle();
      setIsModalVisible(false);
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const generateItems = (): TableIconProps[] => [
    {
      label: t('table.dropdown.edit'),
      path: editPath,
      testId: 'EmergencyTableMenu#edit',
      icon: <EditIcon />,
      hidden: isEditPage,
    },
    {
      label: pinned ? t('table.dropdown.unpin') : t('table.dropdown.pin'),
      action: pinned ? handleUnpinClick : handlePinClick,
      testId: pinned ? 'EmergencyTableMenu#unpin' : 'EmergencyTableMenu#pin',
      icon: pinned ? <EyeSlashIcon /> : <EyeIcon />,
    },
    {
      label: t('table.dropdown.delete'),
      action: handleDeleteClick,
      testId: 'EmergencyTableMenu#delete',
      icon: <TrashCanIcon />,
    },
  ];

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  const renderModal = () => (
    <ConfirmModal
      title={t('emergency.modal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('emergency.modal.backButton')}
      confirmButtonText={t('emergency.modal.confirmButton')}
      testId="Emergency#ConfirmModal"
    />
  );

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
