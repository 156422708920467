import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TTabPanelTabs } from '@sim-admin-frontends/ui-shared';
import { PetState, useUnpublishPetMutation } from '@sim-admin-frontends/data-access';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';

import ROUTES from '../../../routing/routes';
import { TPetsTabsTypes } from '../../../types/TPets';
import { CtaWrapper, StyledPanel } from '../../common/TabsStyles';
import PetDirectoryViewContainer from './PetDirectoryView.container';
import { useBulkUnpublish } from '../../../hooks/useBulkUnpublish';
import { AnalyticsEvent } from '../../../utils/analytics/analyticsEvents';

export const petDirectoryTabs = [TPetsTabsTypes.HOME, TPetsTabsTypes.FOUND, TPetsTabsTypes.LOST];

const PetDirectoryTabs: FC = () => {
  const { t } = useTranslation();

  const {
    postsToUnpublish,
    onUnpublishSelect,
    renderBulkUnpublishButton,
    isUnpublishMutationSettled,
    clearSelection,
  } = useBulkUnpublish(useUnpublishPetMutation, AnalyticsEvent.PET_ACTION);

  const { setTabIndex, tabIndex } = useTabNavigation(
    ROUTES.petDirectoryOverview.path,
    petDirectoryTabs,
    clearSelection,
  );

  const getViewContainer = useCallback(
    (state: PetState) => (
      <PetDirectoryViewContainer
        state={state}
        postsToUnpublish={postsToUnpublish}
        onUnpublishSelect={onUnpublishSelect}
        isUnpublishMutationSettled={isUnpublishMutationSettled}
      />
    ),
    [isUnpublishMutationSettled, onUnpublishSelect, postsToUnpublish],
  );

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: getViewContainer(PetState.Home),
        label: t('petDirectory.tabs.home'),
        testId: 'PetDirectoryTabs#Home',
      },
      {
        content: getViewContainer(PetState.Found),
        label: t('petDirectory.tabs.found'),
        testId: 'PetDirectoryTabs#Found',
      },
      {
        content: getViewContainer(PetState.Lost),
        label: t('petDirectory.tabs.lost'),
        testId: 'PetDirectoryTabs#Lost',
      },
    ],
    [getViewContainer, t],
  );

  return (
    <StyledPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex}>
      <CtaWrapper>{renderBulkUnpublishButton()}</CtaWrapper>
    </StyledPanel>
  );
};

export default PetDirectoryTabs;
