import { TFunction } from 'i18next';

export const getCheckboxes = (t: TFunction) => [
  {
    value: '0',
    text: t('coupon.form.recurrence.dayAcronyms.sunday'),
  },
  {
    value: '1',
    text: t('coupon.form.recurrence.dayAcronyms.monday'),
  },
  {
    value: '2',
    text: t('coupon.form.recurrence.dayAcronyms.tuesday'),
  },
  {
    value: '3',
    text: t('coupon.form.recurrence.dayAcronyms.wednesday'),
  },
  {
    value: '4',
    text: t('coupon.form.recurrence.dayAcronyms.thursday'),
  },
  {
    value: '5',
    text: t('coupon.form.recurrence.dayAcronyms.friday'),
  },
  {
    value: '6',
    text: t('coupon.form.recurrence.dayAcronyms.saturday'),
  },
];
