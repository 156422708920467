import { TBaseProps, TCheckbox, TCheckboxes } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ theme }) => theme.spaces.spacing2} 0;
  padding-bottom: ${({ theme }) => theme.spaces.spacing16};
  padding-top: ${({ theme }) => theme.spaces.spacing6};
`;

export interface DayCheckboxGroupProps extends TBaseProps {
  name: string;
  renderCheckbox: (checkbox: TCheckbox, name: string, index: number) => JSX.Element;
  checkboxes: TCheckboxes;
}
const DayCheckboxGroup: FC<DayCheckboxGroupProps> = ({
  name,
  renderCheckbox,
  checkboxes,
  testId,
}) => {
  return (
    <Wrapper data-testid={testId}>
      {checkboxes.map((checkbox, index) => renderCheckbox(checkbox, `${name}${[index]}`, index))}
    </Wrapper>
  );
};

export default DayCheckboxGroup;
