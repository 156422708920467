import { State, useUnpublishEventMutation } from '@sim-admin-frontends/data-access';
import { Button, TabPanel, TTabPanelTabs } from '@sim-admin-frontends/ui-shared';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory } from 'react-router-dom';

import { changeEventsTab } from '../../../utils/eventsUtils';
import { useBulkUnpublish } from '../../../hooks/useBulkUnpublish';
import ROUTES from '../../../routing/routes';
import { TEventsTabTypes } from '../../../types/TEvents';
import { AnalyticsEvent } from '../../../utils/analytics/analyticsEvents';
import { CtaWrapper } from '../../common/TabsStyles';
import EventsViewContainer from './EventsView.container';

export const eventTabs = [TEventsTabTypes.PUBLISHED, TEventsTabTypes.UNPUBLISHED];

type Props = {
  filter: string;
};

const EventsTabs: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    postsToUnpublish,
    onUnpublishSelect,
    renderBulkUnpublishButton,
    isUnpublishMutationSettled,
    clearSelection,
  } = useBulkUnpublish(useUnpublishEventMutation, AnalyticsEvent.EVENT_ACTION);

  const { setTabIndex, tabIndex } = useTabNavigation(
    ROUTES.eventsOverview.path,
    eventTabs,
    clearSelection,
  );

  const getViewContainer = useCallback(
    (state: State) => (
      <EventsViewContainer
        postsToUnpublish={postsToUnpublish}
        onUnpublishSelect={onUnpublishSelect}
        isUnpublishMutationSettled={isUnpublishMutationSettled}
        state={state}
        searchFilter={filter}
      />
    ),
    [isUnpublishMutationSettled, onUnpublishSelect, postsToUnpublish],
  );

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: getViewContainer(State.Published),
        label: t('common.published'),
        testId: 'EventsTabs#published',
      },
      {
        content: getViewContainer(State.Unpublished),
        label: t('common.unpublished'),
        testId: 'EventsTabs#unpublished',
      },
    ],
    [getViewContainer, t],
  );

  const redirectToUnpublishedTab = () => {
    changeEventsTab(history, TEventsTabTypes.UNPUBLISHED);
  };

  return (
    <TabPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex}>
      <CtaWrapper>
        {renderBulkUnpublishButton(redirectToUnpublishedTab)}
        <Link to={generatePath(ROUTES.eventsEdit.path)}>
          <Button testId="EventsTabs#AddEvents">{t('events.addEvent')}</Button>
        </Link>
      </CtaWrapper>
    </TabPanel>
  );
};

export default EventsTabs;
