import { serializeToString } from '@simplicity-tech/sim-slate-types';
import { AnnouncementInput, TCategory, TUploadedFile } from '@sim-admin-frontends/data-access';
import { prepareFileObjects } from '@sim-admin-frontends/utils-shared';

import { TEmergencyFormValues } from '../../../types/TAnnouncements';
import { DEFAULT_LANGUAGE } from '../../../constants/Constants';

export const transformFormValues = (
  formData: TEmergencyFormValues,
  uploadedImages: TUploadedFile[] | undefined,
  uploadedVideos: TUploadedFile[] | undefined,
  institutionUuid: string,
  category?: TCategory,
): AnnouncementInput => {
  const imageObjects = prepareFileObjects(uploadedImages);
  const videoObjects = prepareFileObjects(uploadedVideos);

  return {
    title: formData.title,
    content: formData.content,
    pushContent: serializeToString(formData.content),
    institutionUuid,
    categoryUuid: category?.id,
    placeUuids: formData.places?.map((place) => place.value),
    lang: DEFAULT_LANGUAGE,
    imageObjects,
    videoObjects,
    notifyNow: true,
    location: formData.location || undefined,
  };
};
