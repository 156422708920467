import {
  PetsOrderBy,
  PetsSortBy,
  PetState,
  SortOrder,
  TPetsInPlaceListItem,
} from '@sim-admin-frontends/data-access';
import { SortingRule } from 'react-table';

export const DEFAULT_PETS_ORDER_BY = {
  by: PetsSortBy.CreatedAt,
  order: SortOrder.Desc,
};

export const getInitialGqlSorting = (type?: PetState): PetsOrderBy => {
  switch (type) {
    case PetState.Home:
      return {
        by: PetsSortBy.CreatedAt,
        order: SortOrder.Desc,
      };
    case PetState.Found:
      return {
        by: PetsSortBy.FoundAt,
        order: SortOrder.Desc,
      };
    case PetState.Lost:
      return {
        by: PetsSortBy.LostAt,
        order: SortOrder.Desc,
      };
    default:
      return DEFAULT_PETS_ORDER_BY;
  }
};

export const getInitialTableSorting = (type: PetState): SortingRule<TPetsInPlaceListItem>[] => {
  switch (type) {
    case PetState.Home:
      return [
        {
          id: 'createdAt',
          desc: true,
        },
      ];
    case PetState.Found:
      return [
        {
          id: 'foundAt',
          desc: true,
        },
      ];
    case PetState.Lost:
      return [
        {
          id: 'lostAt',
          desc: true,
        },
      ];

    default:
      return [];
  }
};

export const getGqlSorting = (
  tableSorting: SortingRule<TPetsInPlaceListItem>[],
  type?: PetState,
): {
  by: PetsSortBy;
  order: SortOrder;
} => {
  if (!tableSorting || tableSorting.length !== 1) {
    return getInitialGqlSorting(type);
  }
  const rule = tableSorting[0];

  switch (rule.id) {
    case 'createdAt':
      return {
        by: PetsSortBy.CreatedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    case 'updatedAt':
      return {
        by: PetsSortBy.UpdatedAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    case 'lostAt':
      return {
        by: PetsSortBy.LostAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    case 'foundAt':
      return {
        by: PetsSortBy.FoundAt,
        order: rule.desc ? SortOrder.Desc : SortOrder.Asc,
      };
    default:
      return getInitialGqlSorting(type);
  }
};
