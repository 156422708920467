import { TFeatureFlagType, PlaceFeatureFlag } from '@sim-admin-frontends/data-access';

import { TInstututionPlaces, TSelectedPlace } from '../types/TPlace';

export const formatCity = (city: string, stateCode: string) => `${city}, ${stateCode}`;

export const formatCityFromPlace = (place?: TSelectedPlace | null) =>
  place ? formatCity(place?.name, place?.stateCode) : 'No-city';

export const getPlaceOptions = (places: TInstututionPlaces) =>
  places?.map((place) => ({ value: place.id, label: place.name }));

export const isFeatureEnabled = (
  featureFlags: PlaceFeatureFlag[],
  soughtFeature: TFeatureFlagType,
) => featureFlags.some((featureFlag) => featureFlag.url === soughtFeature && !!featureFlag.enabled);
