import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import {
  device,
  DashboardCardProps,
  DashboardCard,
  DashboardButton,
  DashboardItemTitle,
  DashboardItemAnalytics,
} from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { ItemsProps } from './helper';
import useMultiCityTableSelect from '../../hooks/useMultiCityTableSelect';
import { DashboardAnalyticsChart } from './analyticsChart/DashboardAnalyticsChart';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  margin: 0 -${({ theme }) => theme.spaces.spacing8};
  padding: ${({ theme }) => theme.spaces.spacing16} 0;
`;

const ItemFlexWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: ${({ theme }) => theme.spaces.spacing8};
  box-sizing: border-box;
  ${device.sm} {
    flex-basis: 50%;
    width: 50%;
  }
  ${device.md} {
    flex-basis: 33.3333%;
    width: 33.3333%;
  }
  ${device.lg} {
    flex-basis: 25%;
    width: 25%;
  }
`;

const AnalyticTable = styled.table`
  table-layout: fixed;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.coolGray20};
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  border-collapse: collapse;
  overflow: hidden;
  margin: ${({ theme }) => theme.spaces.spacing8};

  ${device.sm} {
    width: 100%;
  }
  ${device.md} {
    width: 100%;
  }
  ${device.lg} {
    width: 15%;
  }
`;

const AnalyticTableHeader = styled.th`
  padding: ${({ theme }) => theme.spaces.spacing16};
  background-color: ${({ theme }) => theme.colors.white};
`;

const AnalyticTableRow = styled.tr`
  background-color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => theme.spaces.spacing96};
`;

const AnalyticTableColumn = styled.td`
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  overflow: hidden;
`;

const TitleTableColumn = styled(AnalyticTableColumn)`
  padding-bottom: ${({ theme }) => theme.spaces.spacing16};
`;

export type DashboardExtraActions = Array<{
  title: string;
  description: string;
  icon: ReactElement;
  testId?: string;
  onClick: () => void;
  disabled?: boolean;
}>;

interface DashboardViewProps {
  items: ItemsProps[];
  extraActions: DashboardExtraActions;
  topPosts: DashboardCardProps[];
}

const DashboardView: FC<DashboardViewProps> = ({ items, extraActions, topPosts }) => {
  const { t } = useTranslation();
  const { renderSelect } = useMultiCityTableSelect();

  const renderTableHeader = () =>
    renderSelect() ? <AnalyticTableHeader colSpan={2}>{renderSelect()}</AnalyticTableHeader> : null;

  return (
    <Wrapper>
      <AnalyticTable>
        {renderTableHeader()}
        <tbody>
          {items.map((item, index) => {
            const { actual, diff, title, description } = item;

            return (
              <AnalyticTableRow key={index}>
                <AnalyticTableColumn>
                  <DashboardItemAnalytics
                    actual={actual}
                    diff={diff}
                    testId={`DashboardItemAnalytics#${title}`}
                  />
                </AnalyticTableColumn>
                <TitleTableColumn>
                  <DashboardItemTitle
                    title={title}
                    description={description}
                    testId="DashboardItemTitle"
                  />
                </TitleTableColumn>
              </AnalyticTableRow>
            );
          })}
        </tbody>
      </AnalyticTable>
      <DashboardAnalyticsChart />
      {topPosts.map((post, index) => {
        const { institution, institutionLogo, updatedAt, title, images, description } = post;
        return (
          <ItemFlexWrapper key={'topPost_' + index}>
            <DashboardCard
              institution={institution}
              institutionLogo={institutionLogo}
              updatedAt={updatedAt}
              title={title}
              images={images}
              description={description}
              errMessage={t('dashboard.no_more_posts')}
              testId={'DashboardCard#' + index}
            />
          </ItemFlexWrapper>
        );
      })}

      <ItemFlexWrapper key="extra">
        <ButtonsWrapper>
          {extraActions.map((item, key) => (
            <DashboardButton key={item.title + key} {...item} disabled={item.disabled} />
          ))}
        </ButtonsWrapper>
      </ItemFlexWrapper>
    </Wrapper>
  );
};

export { DashboardView };
