import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { device, Error, Spinner, stripPx } from '@sim-admin-frontends/ui-shared';
import { useTranslation } from 'react-i18next';

import { LegendButton } from './LegendButton';
import { AnalyticsChartHeader } from './AnalyticsChartHeader';
import { TChartDataMetrics } from '../../../types/TDashboard';
import useDashboardAnalyticsChart from '../../../hooks/useDashboardAnalyticsChart';

const CHART_DOT_RADIUS = 0;
const CONTAINER_HEIGHT = 300;
const MIN_CHART_WIDTH = 500;
const CHART_LINE_TYPE = 'monotone';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.coolGray20};
  border-radius: ${({ theme }) => theme.borderRadius.radius8};
  margin: ${({ theme }) => theme.spaces.spacing8};
  ${device.sm} {
    width: 100%;
  }
  ${device.lg} {
    width: calc(75% - ${({ theme }) => theme.spaces.spacing16});
  }
  box-sizing: border-box;
  position: relative;
  min-width: ${MIN_CHART_WIDTH}px;
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  overflow: hidden !important;
  height: 100%;
  width: calc(100% - ${({ theme }) => theme.spaces.spacing32});
  padding-right: ${({ theme }) => theme.spaces.spacing32};
  position: relative;

  & .recharts-surface {
    overflow: visible !important;
  }

  /* This is a silly hack so that chart doesn't rescale with horizontal scroll bar */
  & .recharts-wrapper {
    position: absolute !important;
  }
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spaces.spacing64} 0 ${({ theme }) => theme.spaces.spacing16} 0;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: ${CONTAINER_HEIGHT}px;
`;

const DashboardAnalyticsChart: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    chartData,
    isLoading,
    isError,
    error,
    refetch,
    selectedTimeSpan,
    setSelectedTimeSpan,
    customInterval,
    setCustomInterval,
    enabledMetrics,
    csvReport,
    onLegendButtonClick,
  } = useDashboardAnalyticsChart();

  if (isLoading || isError) {
    return (
      <Wrapper>
        <SpinnerWrapper>
          {isLoading && <Spinner />}
          {isError && <Error caption={(error as Error).message} onClick={refetch} />}
        </SpinnerWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <AnalyticsChartHeader
        selectedTimeSpan={selectedTimeSpan}
        setSelectedTimeSpan={setSelectedTimeSpan}
        setCustomInterval={setCustomInterval}
        customInterval={customInterval}
        csvData={csvReport}
      />
      <ChartWrapper>
        <ResponsiveContainer width="100%" height={CONTAINER_HEIGHT}>
          <LineChart
            margin={{
              top: stripPx(theme.spaces.spacing16),
              right: stripPx(theme.spaces.spacing24),
              bottom: stripPx(theme.spaces.spacing16),
            }}
            data={chartData}
          >
            <CartesianGrid strokeDasharray="3 3" stroke={theme.colors.coolGray10} />
            <XAxis
              dataKey="name"
              stroke={theme.colors.coolGray100}
              minTickGap={stripPx(theme.spaces.spacing16)}
            />
            <YAxis stroke={theme.colors.coolGray100} />
            <Tooltip />
            {enabledMetrics.followers && (
              <Line
                type={CHART_LINE_TYPE}
                dataKey="followers"
                name={t('dashboard.analyticsChart.followers')}
                stroke={theme.colors.red60}
                dot={{ r: CHART_DOT_RADIUS }}
              />
            )}
            {enabledMetrics.subscribers && (
              <Line
                type={CHART_LINE_TYPE}
                dataKey="subscribers"
                name={t('dashboard.analyticsChart.subscribers')}
                stroke={theme.colors.blue60}
                dot={{ r: CHART_DOT_RADIUS }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>

        <LegendWrapper>
          <LegendButton
            label={t('dashboard.analyticsChart.followers')}
            color={theme.colors.red60}
            onClick={onLegendButtonClick(TChartDataMetrics.FOLLOWERS)}
            isActive={enabledMetrics.followers}
          />
          <LegendButton
            label={t('dashboard.analyticsChart.subscribers')}
            color={theme.colors.blue60}
            onClick={onLegendButtonClick(TChartDataMetrics.SUBSCRIBERS)}
            isActive={enabledMetrics.subscribers}
          />
        </LegendWrapper>
      </ChartWrapper>
    </Wrapper>
  );
};

export { DashboardAnalyticsChart };
