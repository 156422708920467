import { geocodeByPlaceId } from 'react-google-places-autocomplete';

export const getTimezoneId = async (location: Record<string, number>, apiKey: string) => {
  const timestamp = Math.floor(Date.now() / 1000);
  const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${location.lat.toString()},${location.lng.toString()}&timestamp=${timestamp}&key=${apiKey}`;
  const response = await fetch(url);
  const result = await response.json();
  return result.timeZoneId;
};

export const getFinalPlaceData = async (placeId: string, apiKey: string) => {
  const placeData = (await geocodeByPlaceId(placeId))[0];
  const fullCountryName = placeData.address_components.find((component: Record<string, any>) =>
    component.types.includes('country'),
  );
  const fullStateName = placeData.address_components.find((component: Record<string, any>) =>
    component.types.includes('administrative_area_level_1'),
  );
  const fullCityName = placeData.address_components.find(
    (component: Record<string, any>) =>
      component.types.includes('locality') ||
      component.types.includes('administrative_area_level_2'),
  );

  const name = fullCityName?.long_name || '';
  const countryCode = fullCountryName?.short_name || '';
  const countryName = fullCountryName?.long_name || '';
  const stateCode = `${countryCode}${
    fullStateName?.short_name ? `-${fullStateName.short_name}` : ''
  }`;
  const stateName = fullStateName?.long_name || '';

  const coordinates = {
    lat: placeData?.geometry?.location.lat(),
    lng: placeData?.geometry?.location.lng(),
  };

  const timezoneCode = await getTimezoneId(coordinates, apiKey);

  const finalPlaceData = {
    name,
    timezoneCode,
    stateName,
    stateCode,
    countryCode,
    countryName,
    gps: {
      lat: coordinates?.lat,
      lon: coordinates?.lng,
    },
  };

  return finalPlaceData;
};
