import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Error, Menu, Spinner, TMenuEntry } from '@sim-admin-frontends/ui-shared';
import { useInstiutionRoleQuery } from '@sim-admin-frontends/data-access';
import { usePlaceQuery } from '@sim-admin-frontends/data-access-admin-be';

import { useAuthInfo } from '../../contexts/userContext';
import { usePlaceInfo } from '../../contexts/placeContext';
import { createMainMenuItems } from '../menu/MainMenu';
import SideMenuItem from './SideMenuItem';
import { useIsPlaceAdmin } from '../../hooks/useIsPlaceAdmin';

const SpinnerWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledMenu = styled(Menu)`
  overflow-y: auto;
  padding: ${({ theme }) => theme.spaces.spacing8};
  flex: 1;
`;

const SideMenu: FC = () => {
  const { places } = usePlaceInfo();
  const { t } = useTranslation();
  const { institutionUuid, user, isCity, visitorMode } = useAuthInfo();
  const { isPlaceAdmin } = useIsPlaceAdmin();
  const placeId = places?.[0]?.id || '';

  const {
    data: placeData,
    isLoading: isPlaceLoading,
    isError: isPlaceError,
    refetch: placeRefetch,
  } = usePlaceQuery({
    id: placeId,
  });

  const {
    data: institutionData,
    isLoading: institutionIsLoading,
    isError: institutionIsError,
    refetch: institutionRefetch,
  } = useInstiutionRoleQuery({ id: institutionUuid });

  const menuItems = useMemo(
    () =>
      placeData && institutionData
        ? createMainMenuItems(
            t,
            isPlaceAdmin,
            isCity,
            placeData?.place?.featureFlags || [],
            institutionData?.institution?.inPlaces?.[0]?.role,
            institutionData?.institution?.isChatEnabled,
            visitorMode,
          )
        : [],
    [t, user, institutionData, placeData, visitorMode],
  );

  const isLoading = isPlaceLoading || institutionIsLoading || !places;

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const isError = isPlaceError || institutionIsError || !menuItems || !menuItems.length;

  const refetch = () => {
    placeRefetch();
    institutionRefetch();
  };

  if (isError) {
    return (
      <SpinnerWrapper>
        <Error caption={t('error.fetchingDataError')} onClick={refetch} />
      </SpinnerWrapper>
    );
  }

  const renderMenuItem = (item: TMenuEntry) => (
    <SideMenuItem item={item} institutionUuid={institutionUuid} key={item.label} />
  );

  return <StyledMenu items={menuItems} render={renderMenuItem} />;
};

export default SideMenu;
