import { TFunction } from 'i18next';

import { TAnnouncementsFormTypes } from '../types/TAnnouncements';
import { TToastMessageActionType, TToastMessageType } from '../types/TToastMessage';

export const announcementFormTypeToToastMessage = (
  announcementFormType: TAnnouncementsFormTypes,
) => {
  const map = {
    [TAnnouncementsFormTypes.EMERGENCY]: TToastMessageType.EMERGENCY,
    [TAnnouncementsFormTypes.POLL]: TToastMessageType.POLL,
    [TAnnouncementsFormTypes.ANNOUNCEMENT]: TToastMessageType.ANNOUNCEMENT,
    [TAnnouncementsFormTypes.QUICK_QUESTION]: TToastMessageType.QUICK_QUESTION,
    [TAnnouncementsFormTypes.DONATION]: TToastMessageType.DONATION,
    [TAnnouncementsFormTypes.MARKET]: TToastMessageType.MARKET,
  };

  return map[announcementFormType];
};

export const getToastMessage = (
  toastMessageType: TToastMessageType,
  toastMessageAction: TToastMessageActionType,
  t: TFunction,
) => {
  const map = {
    [TToastMessageType.ANNOUNCEMENT]: {
      [TToastMessageActionType.create]: () => t('updates.form.toastCreate'),
      [TToastMessageActionType.edit]: () => t('updates.form.toastEdit'),
      [TToastMessageActionType.schedule]: () => t('updates.form.toastSchedule'),
    },
    [TToastMessageType.EMERGENCY]: {
      [TToastMessageActionType.create]: () => t('emergency.form.toastCreate'),
      [TToastMessageActionType.edit]: () => t('emergency.form.toastEdit'),
      [TToastMessageActionType.schedule]: () => t('emergency.form.toastSchedule'),
    },
    [TToastMessageType.EVENT]: {
      [TToastMessageActionType.create]: () => t('events.form.toastCreate'),
      [TToastMessageActionType.edit]: () => t('events.form.toastEdit'),
      [TToastMessageActionType.schedule]: () => t('events.form.toastSchedule'),
    },
    [TToastMessageType.POLL]: {
      [TToastMessageActionType.create]: () => t('polls.form.toastCreate'),
      [TToastMessageActionType.edit]: () => t('polls.form.toastEdit'),
      [TToastMessageActionType.schedule]: () => t('polls.form.toastSchedule'),
    },
    [TToastMessageType.COUPON]: {
      [TToastMessageActionType.create]: () => t('coupon.form.toastCreate'),
      [TToastMessageActionType.edit]: () => t('coupon.form.toastEdit'),
      [TToastMessageActionType.schedule]: () => t('coupon.form.toastSchedule'),
    },
    [TToastMessageType.RAFFLE]: {
      [TToastMessageActionType.create]: () => t('raffles.form.toastCreate'),
      [TToastMessageActionType.edit]: () => t('raffles.form.toastEdit'),
      [TToastMessageActionType.schedule]: () => t('raffles.form.toastSchedule'),
    },
    [TToastMessageType.QUICK_QUESTION]: {
      [TToastMessageActionType.create]: () => t('quickQuestions.form.toastCreate'),
      [TToastMessageActionType.edit]: () => t('quickQuestions.form.toastEdit'),
      [TToastMessageActionType.schedule]: () => t('quickQuestions.form.toastSchedule'),
    },
    [TToastMessageType.DONATION]: {
      [TToastMessageActionType.create]: () => t('donations.form.toastCreate'),
      [TToastMessageActionType.edit]: () => t('donations.form.toastEdit'),
      [TToastMessageActionType.schedule]: () => t('donations.form.toastSchedule'),
    },
    [TToastMessageType.MARKET]: {
      [TToastMessageActionType.create]: () => t('updates.form.toastCreate'),
      [TToastMessageActionType.edit]: () => t('updates.form.toastEdit'),
      [TToastMessageActionType.schedule]: () => t('market.form.toastSchedule'),
    },
  };

  return map[toastMessageType][toastMessageAction]();
};
