import { State, useUnpublishAnnouncementMutation } from '@sim-admin-frontends/data-access';
import { Button, TTabPanelTabs } from '@sim-admin-frontends/ui-shared';
import { useTabNavigation } from '@sim-admin-frontends/utils-shared';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory } from 'react-router-dom';

import { changePollsTab } from '../../../utils/pollsUtils';
import { useBulkUnpublish } from '../../../hooks/useBulkUnpublish';
import ROUTES from '../../../routing/routes';
import { TAnnouncementsTabTypes } from '../../../types/TAnnouncements';
import { AnalyticsEvent } from '../../../utils/analytics/analyticsEvents';
import { CtaWrapper, StyledPanel } from '../../common/TabsStyles';
import { DonationsViewContainer } from './DonationsView.container';

const donationsTabs = [
  TAnnouncementsTabTypes.PUBLISHED,
  TAnnouncementsTabTypes.SCHEDULED,
  TAnnouncementsTabTypes.UNPUBLISHED,
];

const DonationsTabs: FC = () => {
  const { t } = useTranslation();

  const {
    postsToUnpublish,
    onUnpublishSelect,
    renderBulkUnpublishButton,
    isUnpublishMutationSettled,
    clearSelection,
  } = useBulkUnpublish(useUnpublishAnnouncementMutation, AnalyticsEvent.ANNOUNCEMENT_ACTION);

  const { setTabIndex, tabIndex } = useTabNavigation(
    ROUTES.donationsOverview.path,
    donationsTabs,
    clearSelection,
  );
  const history = useHistory();

  const getViewContainer = useCallback(
    (state: State) => (
      <DonationsViewContainer
        state={state}
        postsToUnpublish={postsToUnpublish}
        onUnpublishSelect={onUnpublishSelect}
        isUnpublishMutationSettled={isUnpublishMutationSettled}
      />
    ),
    [isUnpublishMutationSettled, onUnpublishSelect, postsToUnpublish],
  );

  const tabs: TTabPanelTabs = useMemo(
    () => [
      {
        content: getViewContainer(State.Published),
        label: t('updates.tabs.published'),
        testId: 'DonationsTabs#published',
      },
      {
        content: getViewContainer(State.Scheduled),
        label: t('updates.tabs.scheduled'),
        testId: 'DonationsTabs#scheduled',
      },
      {
        content: getViewContainer(State.Unpublished),
        label: t('updates.tabs.unpublished'),
        testId: 'DonationsTabs#unpublished',
      },
    ],
    [getViewContainer, t],
  );

  const redirectToUnpublishedTab = () => {
    changePollsTab(history, TAnnouncementsTabTypes.UNPUBLISHED);
  };

  return (
    <StyledPanel tabs={tabs} tabIndex={tabIndex} onIndexChange={setTabIndex}>
      <CtaWrapper>
        {renderBulkUnpublishButton(redirectToUnpublishedTab)}
        <Link to={generatePath(ROUTES.donationsEdit.path)}>
          <Button testId="DonationsTabs#AddDonation">{t('donations.createDonation')}</Button>
        </Link>
      </CtaWrapper>
    </StyledPanel>
  );
};

export default DonationsTabs;
