import {
  useDeleteEventMutation,
  usePublishEventMutation,
  useSendPostNotificationMutation,
  useUnpublishEventMutation,
} from '@sim-admin-frontends/data-access';
import {
  BellIcon,
  ConfirmModal,
  EditIcon,
  EyeIcon,
  EyeSlashIcon,
  loadingToast,
  TableIconProps,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { useAuthInfo } from '../../contexts/userContext';
import ROUTES from '../../routing/routes';
import { TItemAction } from '../../types/TAnalytics';
import { EventModalActions, TEventsTabTypes } from '../../types/TEvents';
import { AnalyticsEvent } from '../../utils/analytics/analyticsEvents';
import { analyticsLogEvent, logAnnouncementEvent } from '../../utils/analytics/analyticsUtils';
import { changeEventsTab } from '../../utils/eventsUtils';

type TAnnoucementActions = {
  isFromAdmin?: boolean | null;
  eventId: string;
  published: boolean;
  refetch: () => void;
  isEditPage?: boolean;
};

export const useGenerateEventActions = ({
  isFromAdmin,
  eventId,
  published,
  refetch,
  isEditPage,
}: TAnnoucementActions) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(EventModalActions.UNPUBLISH);
  const { t } = useTranslation();
  const history = useHistory();
  const { institutionUuid } = useAuthInfo();

  const editPath = generatePath(ROUTES.eventsEdit.path, eventId ? { id: eventId } : undefined);

  const { mutate: deleteMutation } = useDeleteEventMutation({
    onMutate: () =>
      loadingToast(t('events.toast.delete.loading'), {
        toastId: EventModalActions.DELETE,
      }),
    onError: () => {
      updateToast(EventModalActions.DELETE, t('events.toast.delete.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      analyticsLogEvent({
        type: AnalyticsEvent.EVENT_ACTION,
        data: { Id: eventId, Action: TItemAction.DELETE, InstitutionId: institutionUuid },
      });
      updateToast(EventModalActions.DELETE, t('events.toast.delete.success'), TToastType.SUCCESS);
      changeEventsTab(history, TEventsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const { mutate: unpublishMutation } = useUnpublishEventMutation({
    onMutate: () =>
      loadingToast(t('events.toast.unpublish.loading'), {
        toastId: EventModalActions.UNPUBLISH,
      }),
    onError: () => {
      updateToast(EventModalActions.UNPUBLISH, t('events.toast.unpublish.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      analyticsLogEvent({
        type: AnalyticsEvent.EVENT_ACTION,
        data: { Id: eventId, Action: TItemAction.UNPUBLISH, InstitutionId: institutionUuid },
      });
      updateToast(
        EventModalActions.UNPUBLISH,
        t('events.toast.unpublish.success'),
        TToastType.SUCCESS,
      );
      changeEventsTab(history, TEventsTabTypes.UNPUBLISHED);
      refetch();
    },
  });

  const { mutate: publishMutation } = usePublishEventMutation({
    onMutate: () =>
      loadingToast(t('events.toast.publish.loading'), {
        toastId: EventModalActions.PUBLISH,
      }),
    onError: () => {
      updateToast(EventModalActions.PUBLISH, t('events.toast.publish.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      analyticsLogEvent({
        type: AnalyticsEvent.EVENT_ACTION,
        data: {
          Id: eventId,
          Action: TItemAction.PUBLISH,
          Notification: true,
          InstitutionId: institutionUuid,
        },
      });
      updateToast(EventModalActions.PUBLISH, t('events.toast.publish.success'), TToastType.SUCCESS);
      changeEventsTab(history, TEventsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const { mutate: sendPostNotification } = useSendPostNotificationMutation({
    onMutate: () => {
      loadingToast(t('updates.toast.notification.loading'), {
        toastId: EventModalActions.NOTIFY,
      });
    },
    onError: () => {
      updateToast(
        EventModalActions.NOTIFY,
        t('updates.toast.notification.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logAnnouncementEvent(eventId, institutionUuid, TItemAction.NOTIFY);
      updateToast(
        EventModalActions.NOTIFY,
        t('updates.toast.notification.success'),
        TToastType.SUCCESS,
      );
    },
  });

  const sendNotification = async () => {
    await sendPostNotification({
      postUuid: eventId,
    });
  };

  const handleDeleteClick = () => {
    setModalDescription(t('events.modal.deleteContent'));
    setModalClickAction(EventModalActions.DELETE);
    setIsModalVisible(true);
  };

  const handlePublishClick = () => {
    if (published) {
      setModalDescription(t('events.modal.unpublishContent'));
      setModalClickAction(EventModalActions.UNPUBLISH);
      setIsModalVisible(true);
    } else {
      setModalDescription(t('events.modal.publishContent'));
      setModalClickAction(EventModalActions.PUBLISH);
      setIsModalVisible(true);
    }
  };

  const deleteEvent = async () => {
    await deleteMutation({
      id: eventId,
    });
  };

  const unpublishEvent = async () => {
    await unpublishMutation({
      id: eventId,
    });
  };

  const publishEvent = async () => {
    await publishMutation({
      id: eventId,
    });
  };

  const handleConfirmClick = () => {
    if (modalClickAction === EventModalActions.DELETE) {
      deleteEvent();
      setIsModalVisible(false);
    }
    if (modalClickAction === EventModalActions.UNPUBLISH) {
      unpublishEvent();
      setIsModalVisible(false);
    }
    if (modalClickAction === EventModalActions.PUBLISH) {
      publishEvent();
      setIsModalVisible(false);
    }
    if (modalClickAction === EventModalActions.NOTIFY) {
      sendNotification();
      setIsModalVisible(false);
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const handleNotifyClick = () => {
    setModalDescription(t('updates.modal.sendNotification'));
    setModalClickAction(EventModalActions.NOTIFY);
    setIsModalVisible(true);
  };

  const generateItems = (): TableIconProps[] => {
    const items = [
      {
        label: published ? t('table.dropdown.unpublish') : t('table.dropdown.publish'),
        action: handlePublishClick,
        testId: published ? 'EventsTableMenu#unpublish' : 'EventsTableMenu#publish',
        icon: published ? <EyeSlashIcon /> : <EyeIcon />,
      },
      {
        label: t('table.dropdown.edit'),
        path: editPath,
        hidden: isEditPage,
        testId: 'EventsTableMenu#edit',
        icon: <EditIcon />,
      },
    ];

    if (published) {
      items.unshift({
        label: t('table.dropdown.notify'),
        action: handleNotifyClick,
        testId: 'EventsTableMenu#notify',
        icon: <BellIcon />,
      });
    }

    if (isFromAdmin) {
      items.push({
        label: t('table.dropdown.delete'),
        action: handleDeleteClick,
        testId: 'EventsTableMenu#delete',
        icon: <TrashCanIcon />,
      });
    }

    return items;
  };

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  const renderModal = () => (
    <ConfirmModal
      title={t('updates.modal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('updates.modal.backButton')}
      confirmButtonText={t('updates.modal.confirmButton')}
      testId="Events#ConfirmModal"
    />
  );

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
