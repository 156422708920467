import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConfirmModal,
  loadingToast,
  TableIconProps,
  TrashCanIcon,
  TToastType,
  updateToast,
  EyeIcon,
  EyeSlashIcon,
} from '@sim-admin-frontends/ui-shared';
import {
  useDeletePetMutation,
  usePublishPetMutation,
  useUnpublishPetMutation,
} from '@sim-admin-frontends/data-access';

import { logPetEvent } from '../../utils/analytics/analyticsUtils';
import { TItemAction } from '../../types/TAnalytics';
import { TPetModalActions } from '../../types/TPets';

type Props = {
  petId: string;
  refetch: () => void;
  isPublished?: boolean;
};

export const useGeneratePetDirectoryActions = ({ petId, refetch, isPublished }: Props) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(TPetModalActions.UNPUBLISH);

  const { mutate: deleteMutation } = useDeletePetMutation({
    onMutate: () => {
      loadingToast(t('petDirectory.toast.delete.loading'), {
        toastId: TPetModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(TPetModalActions.DELETE, t('petDirectory.toast.delete.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      logPetEvent(petId, TItemAction.DELETE);
      updateToast(
        TPetModalActions.DELETE,
        t('petDirectory.toast.delete.success'),
        TToastType.SUCCESS,
      );
      refetch();
    },
  });

  const { mutate: publishMutation } = usePublishPetMutation({
    onMutate: () => {
      loadingToast(t('petDirectory.toast.publish.loading'), {
        toastId: TPetModalActions.PUBLISH,
      });
    },
    onError: () => {
      updateToast(
        TPetModalActions.PUBLISH,
        t('petDirectory.toast.publish.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logPetEvent(petId, TItemAction.PUBLISH);
      updateToast(
        TPetModalActions.PUBLISH,
        t('petDirectory.toast.publish.success'),
        TToastType.SUCCESS,
      );
      refetch();
    },
  });

  const { mutate: unpublishMutation } = useUnpublishPetMutation({
    onMutate: () => {
      loadingToast(t('petDirectory.toast.unpublish.loading'), {
        toastId: TPetModalActions.UNPUBLISH,
      });
    },
    onError: () => {
      updateToast(
        TPetModalActions.UNPUBLISH,
        t('petDirectory.toast.unpublish.error'),
        TToastType.ERROR,
      );
    },
    onSuccess: () => {
      logPetEvent(petId, TItemAction.UNPUBLISH);
      updateToast(
        TPetModalActions.UNPUBLISH,
        t('petDirectory.toast.unpublish.success'),
        TToastType.SUCCESS,
      );
      refetch();
    },
  });

  const deletePet = async () => {
    await deleteMutation({
      id: petId,
    });
  };

  const unpublishPet = async () => {
    await unpublishMutation({
      id: petId,
    });
  };

  const publishPet = async () => {
    await publishMutation({
      id: petId,
    });
  };

  const handleConfirmClick = () => {
    if (modalClickAction === TPetModalActions.DELETE) {
      deletePet();
      setIsModalVisible(false);
    }
    if (modalClickAction === TPetModalActions.UNPUBLISH) {
      unpublishPet();
      setIsModalVisible(false);
    }
    if (modalClickAction === TPetModalActions.PUBLISH) {
      publishPet();
      setIsModalVisible(false);
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const handleDeleteClick = () => {
    setModalDescription(t('petDirectory.modal.deleteContent'));
    setModalClickAction(TPetModalActions.DELETE);
    setIsModalVisible(true);
  };

  const handlePublishClick = () => {
    setModalDescription(t('petDirectory.modal.publishContent'));
    setModalClickAction(TPetModalActions.PUBLISH);
    setIsModalVisible(true);
  };

  const handleUnpublishClick = () => {
    setModalDescription(t('petDirectory.modal.unpublishContent'));
    setModalClickAction(TPetModalActions.UNPUBLISH);
    setIsModalVisible(true);
  };

  const generateItems = () => {
    const tableItems: TableIconProps[] = [
      {
        label: t('table.dropdown.publish'),
        action: handlePublishClick,
        testId: 'PetDirectoryTableMenu#Publish',
        hidden: isPublished,
        icon: <EyeIcon />,
      },
      {
        label: t('table.dropdown.unpublish'),
        action: handleUnpublishClick,
        testId: 'PetDirectoryTableMenu#Unpublish',
        hidden: !isPublished,
        icon: <EyeSlashIcon />,
      },
      {
        label: t('table.dropdown.delete'),
        action: handleDeleteClick,
        testId: 'PetDirectoryTableMenu#Delete',
        icon: <TrashCanIcon />,
      },
    ];
    return tableItems;
  };

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  const renderModal = () => (
    <ConfirmModal
      title={t('petDirectory.modal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('petDirectory.modal.backButton')}
      confirmButtonText={t('petDirectory.modal.confirmButton')}
      testId="PetDirectory#ConfirmModal"
    />
  );
  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
