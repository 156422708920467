import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch, get } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormButtonsWrapper,
  FormInput,
  FormSectionHeader,
  FormSelect,
  FormWrapper,
  FormWysiwyg,
  TSelectItem,
  MAX_UPLOAD_SIZE,
  MAX_VIDEO_UPLOAD_SIZE,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_VIDEO_FORMATS,
  FormImageCropperUpload,
  FormUploadSecondary,
  TableIconProps,
  ActionButtons,
  FormLocationInput,
  LocationInputValue,
} from '@sim-admin-frontends/ui-shared';
import {
  ImageInput,
  TAnnouncementDetail,
  TInstitution,
  useInstitutionQuery,
  VideoInput,
} from '@sim-admin-frontends/data-access';
import {
  getLangOptionByVal,
  getLangOptions,
  isEmpty,
  isFile,
} from '@sim-admin-frontends/utils-shared';

import { FALLBACK_INSTITUTION_NAME } from '../../../constants/Constants';
import { TEmergencyFormValues } from '../../../types/TAnnouncements';
import PageContentEditable, { ContentHeader } from '../../layout/pageContent/PageContentEditable';
import { RouteLeavingGuard } from '../../modal/RouteLeavingGuard';
import { useAuthInfo } from '../../../contexts/userContext';
import EmergencyPreview from '../preview/EmergencyPreview';
import { getPlaceOptions } from '../../../utils/placeUtils';
import useIsSystemDisabled from '../../../hooks/useIsSystemDisabled';
import useYTVideoInEditor from '../../../hooks/useYTVideoInEditor';

const schema = (t: TFunction, isMultiCity: boolean) =>
  Yup.object().shape({
    title: Yup.string().required(t('common.validation.required')),
    places: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !(isMultiCity && (value || []).length < 1),
    ),
    content: Yup.string().test('equals', t('common.validation.required'), (value) => {
      return value !== SERIALIZED_WYSIWYG_EMPTY_VALUE;
    }),
    images: Yup.array().of(
      Yup.mixed()
        .test('fileSize', t('common.validation.file.tooLarge'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
        )
        .test(
          'fileType',
          t('common.validation.file.unsupportedFormat'),
          (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_IMAGE_FORMATS.includes(file.type) : true,
        ),
    ),
    videos: Yup.array().of(
      Yup.mixed()
        .test('fileSize', t('common.validation.file.tooLarge'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_VIDEO_UPLOAD_SIZE : true,
        )
        .test(
          'fileType',
          t('common.validation.file.unsupportedFormat'),
          (file: File | VideoInput) =>
            file && isFile(file) ? SUPPORTED_VIDEO_FORMATS.includes(file.type) : true,
        ),
    ),
  });

type Props = {
  onSubmit: (values: TEmergencyFormValues) => Promise<void>;
  onDiscard: () => void;
  announcement?: TAnnouncementDetail;
  institution: TInstitution;
  countryCode?: string;
  emergencyFormActions: TableIconProps[];
};

const EmergencyEdit: FC<Props> = ({
  onSubmit,
  onDiscard,
  announcement,
  institution,
  countryCode,
  emergencyFormActions,
}) => {
  const { t } = useTranslation();
  const { institutionUuid } = useAuthInfo();

  const { data } = useInstitutionQuery({
    id: institutionUuid || '',
  });
  const { postsDisabled } = useIsSystemDisabled();
  const isEdit = announcement !== undefined;
  const submitButtonLabel = isEdit ? t('common.save') : t('common.publish');

  const placesOptions = useMemo(() => getPlaceOptions(institution?.places), [institution?.places]);
  const shouldShowCitySelect = (placesOptions || []).length > 1;
  const institutionLang = institution?.lang || undefined;

  const initialValues: TEmergencyFormValues = {
    title: announcement?.title || '',
    content: announcement?.content || SERIALIZED_WYSIWYG_EMPTY_VALUE,
    lang: getLangOptionByVal(announcement?.lang || institutionLang, countryCode),
    images: announcement?.imageObjects,
    videos: announcement?.videoObjects,
    location:
      announcement?.location && announcement.location.gps
        ? { name: announcement.location.name, gps: announcement.location.gps }
        : undefined,
    places: getPlaceOptions(announcement?.places) || [],
  };

  const methods = useForm<TEmergencyFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, shouldShowCitySelect)),
    mode: 'onSubmit',
  });

  const { handleSubmit, register, formState, setValue, getValues, trigger, control } = methods;
  const { errors, isSubmitting, isDirty } = formState;

  const [previewTitle, previewLang, previewImages, previewVideos, previewContent, previewLocation] =
    useWatch({
      name: ['title', 'lang', 'images', 'videos', 'content', 'location'],
      control,
    });

  const submit = async (values: TEmergencyFormValues) => onSubmit(values);

  const onSubmitClick = () => {
    handleSubmit(submit)();
  };

  const pageTitle = announcement
    ? t('emergency.form.editPageTitle')
    : t('emergency.form.createPageTitle');

  const onLocationValueChange = useCallback(
    (value: LocationInputValue) => {
      if (value.gps) {
        setValue('location', {
          name: value.name,
          gps: value.gps,
        });
        trigger('location');
      }
    },
    [setValue, trigger],
  );

  const institutionName = data?.institution?.name || FALLBACK_INSTITUTION_NAME;
  const institutionLogo = data?.institution?.branding?.avatar?.links?.self;
  const { youtubeModal, hasYTVideo } = useYTVideoInEditor(JSON.parse(getValues('content')));

  useEffect(() => {
    if (hasYTVideo) {
      setValue('images', []);
      setValue('videos', []);
    }
  }, [hasYTVideo]);

  const showImage = !previewVideos?.length && !hasYTVideo;
  const showVideo = !previewImages?.length && !hasYTVideo;

  useEffect(() => {
    if (!showImage) {
      setValue('images', []);
    }
  }, [showImage]);

  useEffect(() => {
    if (!showVideo) {
      setValue('videos', []);
    }
  }, [showVideo]);

  return (
    <PageContentEditable
      previewTitle={t('emergency.preview.title')}
      preview={
        <EmergencyPreview
          institutionName={institutionName}
          institutionLogo={institutionLogo}
          title={previewTitle}
          images={previewImages}
          videos={previewVideos}
          content={previewContent}
          lang={previewLang}
          location={previewLocation}
        />
      }
    >
      {youtubeModal}
      <FormWrapper>
        <ActionButtons actionButtons={emergencyFormActions} />
        <ContentHeader>{pageTitle}</ContentHeader>
        <FormProvider {...methods}>
          <FormInput
            label={t('emergency.form.title')}
            {...register('title')}
            error={errors.title}
            testId="EmergencyEdit#title"
          />
          <FormWysiwyg
            control={control}
            name="content"
            label={t('emergency.form.content')}
            initialValue={JSON.parse(getValues('content'))}
            testId="EmergencyEdit#wysiwyg"
          />
          <>
            <FormSectionHeader
              title={t('updates.form.language')}
              description={t('updates.form.languageDescription')}
            />
            <FormSelect
              control={control}
              name="lang"
              options={getLangOptions()}
              defaultValue={initialValues.lang}
              testId={'Announcement#languageSlect'}
              allSelectable
            />
          </>
          {shouldShowCitySelect && (
            <FormSelect
              control={control}
              label={t('updates.form.cities')}
              error={get(errors, 'places')}
              name="places"
              options={placesOptions || []}
              defaultValue={initialValues.places}
              testId="EmergencyEdit#places"
              allSelectable
              clearable
              isMulti
            />
          )}
          {showImage && (
            <FormImageCropperUpload
              initialValue={initialValues.images}
              label={t('emergency.form.image')}
              control={control}
              setValue={setValue}
              getValues={getValues}
              trigger={trigger}
              name="images"
              sublabel={t('emergency.form.imageSublabel')}
              dropzoneLabel={t('emergency.form.dropzoneLabel')}
              t={t}
              multiple
              testId="EmergencyEdit#image"
            />
          )}
          {showVideo && (
            <FormUploadSecondary
              control={control}
              name="videos"
              dropzoneLabel={t('emergency.form.dropzoneLabel')}
              testId="EmergencyEdit#video"
              label={t('emergency.form.video')}
              t={t}
              bottomCaption={t('emergency.form.videoSublabel')}
              accept={SUPPORTED_VIDEO_FORMATS}
            />
          )}
          <FormLocationInput
            control={control}
            name="location"
            label={t('emergency.form.location')}
            placeholder={t('common.searchLocation')}
            testId="EmergencyEdit#location"
            onValueChange={onLocationValueChange}
            defaultValue={{
              label: initialValues.location?.name || '',
              value: initialValues.location?.name || '',
            }}
          />
          <FormButtonsWrapper>
            <Button variant="tertiary" onClick={onDiscard} disabled={isSubmitting}>
              {t('common.cancel')}
            </Button>
            <Button
              type="submit"
              onClick={onSubmitClick}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors) || postsDisabled}
              disabledText={postsDisabled ? t('updates.form.disabledText') : undefined}
              testId={'PublishButton'}
            >
              {submitButtonLabel}
            </Button>
          </FormButtonsWrapper>
        </FormProvider>
      </FormWrapper>
      <RouteLeavingGuard when={isDirty && !isSubmitting} />
    </PageContentEditable>
  );
};

export default EmergencyEdit;
