import Mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';

import { TAnalyticsEvent, TItemAction, TPublishBulkAction } from '../../types/TAnalytics';
import { AnalyticsEvent } from './analyticsEvents';

export const analyticsSetUserId = (id: string) => Mixpanel.identify(id);

export const analyticsClear = () => Mixpanel.reset();

export const analyticsSetUserData = (placeId: string, city: string, email: string) =>
  Mixpanel.register({ PlaceId: placeId, City: city, Email: email });

export const analyticsLogEvent = (event: TAnalyticsEvent) => {
  Mixpanel.track(event.type, event.data);
};

export const analyticsSetUserProperty = (props: Record<string, string | undefined>) => {
  Mixpanel.people.set(props);
};

export const initializeAnalytics = () => {
  const apiKey = process.env.NX_MIXPANEL_API_KEY;
  if (!apiKey) {
    Sentry.captureMessage('Failed to initialize analytics. Missing API key', {
      level: Sentry.Severity.Warning,
    });
    return;
  }
  Mixpanel.init(apiKey);
};

export const logAnnouncementEvent = (
  id: string,
  institutionId: string,
  action: TItemAction,
  notification = true,
) =>
  analyticsLogEvent({
    type: AnalyticsEvent.ANNOUNCEMENT_ACTION,
    data: {
      Id: id,
      Action: action,
      Notification: notification,
      InstitutionId: institutionId,
    },
  });

export const logEmergencyEvent = (
  id: string,
  institutionId: string,
  action: TItemAction,
  notification = true,
) =>
  analyticsLogEvent({
    type: AnalyticsEvent.EMERGENCY_ACTION,
    data: {
      Id: id,
      Action: action,
      Notification: notification,
      InstitutionId: institutionId,
    },
  });

export const logCouponEvent = (
  id: string,
  institutionId: string,
  action: TItemAction,
  notification = true,
) =>
  analyticsLogEvent({
    type: AnalyticsEvent.COUPON_ACTION,
    data: {
      Id: id,
      Action: action,
      Notification: notification,
      InstitutionId: institutionId,
    },
  });

export const logPetEvent = (id: string, action: TItemAction) =>
  analyticsLogEvent({
    type: AnalyticsEvent.PET_ACTION,
    data: {
      Id: id,
      Action: action,
    },
  });

export const logQuickQuestionEvent = (
  id: string,
  institutionId: string,
  action: TItemAction,
  notification = true,
) =>
  analyticsLogEvent({
    type: AnalyticsEvent.QUICK_QUESTION_ACTION,
    data: {
      Id: id,
      Action: action,
      Notification: notification,
      InstitutionId: institutionId,
    },
  });

export const logChatEvent = (
  id: string,
  institutionId: string,
  action: TItemAction,
  notification = true,
) =>
  analyticsLogEvent({
    type: AnalyticsEvent.CHAT_ACTION,
    data: {
      Id: id,
      Action: action,
      Notification: notification,
      InstitutionId: institutionId,
    },
  });

export const logPublishBulk = (
  type: TPublishBulkAction,
  institutionId: string,
  Ids: string[],
  action: TItemAction.PUBLISH | TItemAction.UNPUBLISH,
  notification = false,
) =>
  analyticsLogEvent(
    type !== AnalyticsEvent.PET_ACTION
      ? {
          type: type,
          data: {
            Id: Ids[0],
            Action: action,
            Ids,
            Notification: notification,
            InstitutionId: institutionId,
          },
        }
      : {
          type: type,
          data: {
            Id: Ids[0],
            Action: action,
            Ids,
          },
        },
  );
