import { ActiveIcon, TBaseProps } from '@sim-admin-frontends/ui-shared';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spaces.spacing16} ${({ theme }) => theme.spaces.spacing8};
  justify-content: space-between;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: ${({ theme }) => theme.spaces.spacing20};
  height: ${({ theme }) => theme.spaces.spacing20};
  margin-right: ${({ theme }) => theme.spaces.spacing12};
`;

const InactiveIcon = styled.div`
  width: ${({ theme }) => theme.spaces.spacing20};
  height: ${({ theme }) => theme.spaces.spacing20};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
  background-color: ${({ theme }) => theme.colors.coolGray40};
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.coolGray100};
  font-size: ${({ theme }) => theme.fontSizes.preview};
  line-height: ${({ theme }) => theme.lineHeights.xLarge};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

interface RequirementProps extends TBaseProps {
  icon: ReactElement;
  label: string;
  active: boolean;
}

const Requirement: FC<RequirementProps> = ({ icon, label, active, testId }) => (
  <Wrapper>
    <TextWrapper>
      <IconWrapper data-testid={`${testId}-IconWrapper`}>{icon}</IconWrapper>
      <Text data-testid={`${testId}-Text`}>{label}</Text>
    </TextWrapper>
    {active ? (
      <ActiveIcon data-testid={`${testId}-Active`} />
    ) : (
      <InactiveIcon data-testid={`${testId}-Inactive`} />
    )}
  </Wrapper>
);

export default Requirement;
