import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
} from 'react-query';

import { fetcher } from '../graphql/graphql';

interface UseQueryFn<TData extends Record<string, any>, TVariables extends Record<string, any>> {
  (variables: TVariables, options?: UseQueryOptions<TData>): unknown;
  document: string;
  getKey: (variables: TVariables) => unknown[];
}

export const useInfiniteGraphQLQuery = <
  TData extends Record<string, any>,
  TVariables extends Record<string, any | never>,
>(
  useQuery: UseQueryFn<TData, TVariables>,
  getVariables: ({ pageParam }: { pageParam?: string | null }) => TVariables,
  options?: UseInfiniteQueryOptions<TData, Error>,
): UseInfiniteQueryResult<TData, Error> =>
  useInfiniteQuery<TData, Error>(
    useQuery.getKey(getVariables({})),
    ({ pageParam }) => fetcher<TData, TVariables>(useQuery.document, getVariables({ pageParam }))(),
    options,
  );
