import {
  State,
  useDeleteQuickQuestionMutation,
  usePublishQuickQuestionMutation,
  useUnpublishQuickQuestionMutation,
} from '@sim-admin-frontends/data-access';
import {
  ConfirmModal,
  EditIcon,
  EyeIcon,
  EyeSlashIcon,
  loadingToast,
  TableIconProps,
  TrashCanIcon,
  TToastType,
  updateToast,
} from '@sim-admin-frontends/ui-shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { useAuthInfo } from '../../contexts/userContext';
import ROUTES from '../../routing/routes';
import { TItemAction } from '../../types/TAnalytics';
import { TAnnouncementsTabTypes } from '../../types/TAnnouncements';
import { ChatModalActions } from '../../types/TChat';
import { logChatEvent } from '../../utils/analytics/analyticsUtils';
import { changeChatTab } from '../../utils/chatUtils';

type Props = {
  postId: string;
  refetch: () => void;
  type: State;
  isEditPage?: boolean;
};

export const useGenerateChatActions = ({ postId, refetch, type, isEditPage }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalClickAction, setModalClickAction] = useState(ChatModalActions.UNPUBLISH);
  const { institutionUuid } = useAuthInfo();
  const history = useHistory();
  const { t } = useTranslation();

  const editPath = generatePath(ROUTES.chatEdit.path, postId ? { id: postId } : undefined);

  const { mutate: deleteMutation } = useDeleteQuickQuestionMutation({
    onMutate: () => {
      loadingToast(t('chat.toast.delete.loading'), {
        toastId: ChatModalActions.DELETE,
      });
    },
    onError: () => {
      updateToast(ChatModalActions.DELETE, t('chat.toast.delete.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      logChatEvent(postId, institutionUuid, TItemAction.DELETE, false);
      updateToast(ChatModalActions.DELETE, t('chat.toast.delete.success'), TToastType.SUCCESS);
      changeChatTab(history, TAnnouncementsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const { mutate: unpublishMutation } = useUnpublishQuickQuestionMutation({
    onMutate: () => {
      loadingToast(t('chat.toast.unpublish.loading'), {
        toastId: ChatModalActions.UNPUBLISH,
      });
    },
    onError: () => {
      updateToast(ChatModalActions.UNPUBLISH, t('chat.toast.unpublish.error'), TToastType.ERROR);
    },
    onSuccess: () => {
      updateToast(
        ChatModalActions.UNPUBLISH,
        t('chat.toast.unpublish.success'),
        TToastType.SUCCESS,
      );
      logChatEvent(postId, institutionUuid, TItemAction.UNPUBLISH, false);
      changeChatTab(history, TAnnouncementsTabTypes.UNPUBLISHED);
      refetch();
    },
  });

  const { mutate: publishMutation } = usePublishQuickQuestionMutation({
    onMutate: () => {
      loadingToast(t('chat.toast.publish.loading'), {
        toastId: ChatModalActions.PUBLISH,
      });
    },
    onError: (error) => {
      updateToast(ChatModalActions.PUBLISH, error as string, TToastType.ERROR);
    },
    onSuccess: () => {
      logChatEvent(postId, institutionUuid, TItemAction.PUBLISH);
      updateToast(ChatModalActions.PUBLISH, t('chat.toast.publish.success'), TToastType.SUCCESS);
      changeChatTab(history, TAnnouncementsTabTypes.PUBLISHED);
      refetch();
    },
  });

  const deleteMessage = async () => {
    await deleteMutation({
      id: postId,
    });
  };

  const unpublishMessage = async () => {
    await unpublishMutation({
      id: postId,
    });
  };

  const publishMessage = async () => {
    await publishMutation({
      id: postId,
    });
  };

  const handleDeleteClick = () => {
    setModalDescription(t('chat.modal.deleteContent'));
    setModalClickAction(ChatModalActions.DELETE);
    setIsModalVisible(true);
  };

  const handlePublishClick = () => {
    setModalDescription(t('chat.modal.publishContent'));
    setModalClickAction(ChatModalActions.PUBLISH);
    setIsModalVisible(true);
  };

  const handleUnpublishClick = () => {
    setModalDescription(t('chat.modal.unpublishContent'));
    setModalClickAction(ChatModalActions.UNPUBLISH);
    setIsModalVisible(true);
  };

  const handleConfirmClick = () => {
    if (modalClickAction === ChatModalActions.DELETE) {
      deleteMessage();
      setIsModalVisible(false);
    }
    if (modalClickAction === ChatModalActions.UNPUBLISH) {
      unpublishMessage();
      setIsModalVisible(false);
    }
    if (modalClickAction === ChatModalActions.PUBLISH) {
      publishMessage();
      setIsModalVisible(false);
    }
  };

  const handleCancelClick = () => {
    setIsModalVisible(false);
  };

  const generateItems = (): TableIconProps[] => {
    switch (type) {
      case State.Published:
        return [
          {
            label: t('table.dropdown.unpublish'),
            action: handleUnpublishClick,
            testId: 'ChatTableMenu#unpublish',
            icon: <EyeSlashIcon />,
          },
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            testId: 'ChatTableMenu#edit',
            icon: <EditIcon />,
            hidden: isEditPage,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: 'ChatTableMenu#delete',
            icon: <TrashCanIcon />,
          },
        ];
      case State.Unpublished:
        return [
          {
            label: t('table.dropdown.publish'),
            action: handlePublishClick,
            testId: 'ChatTableMenu#publish',
            icon: <EyeIcon />,
          },
          {
            label: t('table.dropdown.edit'),
            path: editPath,
            testId: 'ChatTableMenu#edit',
            icon: <EditIcon />,
            hidden: isEditPage,
          },
          {
            label: t('table.dropdown.delete'),
            action: handleDeleteClick,
            testId: 'ChatTableMenu#delete',
            icon: <TrashCanIcon />,
          },
        ];
      default:
        return [];
    }
  };

  const listItems = generateItems();
  const detailItems = listItems.filter((item) => !item.hidden);

  const renderModal = () => (
    <ConfirmModal
      title={t('chat.modal.title')}
      content={modalDescription}
      isOpen={isModalVisible}
      onBackClick={handleCancelClick}
      onConfirmClick={handleConfirmClick}
      backButtonText={t('chat.modal.backButton')}
      confirmButtonText={t('chat.modal.confirmButton')}
      testId="Chat#ConfirmModal"
    />
  );

  return {
    listActions: listItems,
    detailActions: detailItems,
    renderModal,
  };
};
