import { Controller, FieldValues } from 'react-hook-form';
import styled from 'styled-components';

import { FormError, FormItemLabel, Header, TBaseProps, Wrapper } from '../../..';
import { TFormControlElement } from '../../../types/TForm';
import { TimePicker } from '../../time-picker/TimePicker';

const StyledLabel = styled(FormItemLabel)<{ isBold?: boolean }>`
  flex: 1;
  font-weight: ${({ theme, isBold }) =>
    isBold ? theme.fontWeights.bold : theme.fontWeights.light};
`;

interface FormTimePickerProps<T> extends TBaseProps, TFormControlElement<T> {
  onChange?: (arg: string) => void;
  onDayPeriodChanged?: (arg: string) => void;
  label?: string;
  initialTime?: string;
  initialDayPeriod?: string;
  disabled?: boolean;
  isWritable?: boolean;
  isBold?: boolean;
}

const FormTimePicker = <T extends FieldValues>({
  control,
  name,
  onChange,
  onDayPeriodChanged,
  className,
  label,
  testId,
  isBold,
  ...rest
}: FormTimePickerProps<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange: onChangeField }, fieldState: { error } }) => {
      const onChangeMerged = (value: string) => {
        onChangeField(value);
        if (onChange) onChange(value);
      };
      return (
        <Wrapper className={className}>
          <Header>
            <StyledLabel isBold={isBold}>{label}</StyledLabel>
          </Header>
          <TimePicker
            {...rest}
            onDayPeriodChanged={(arg: string) => {
              onDayPeriodChanged && onDayPeriodChanged(arg);
            }}
            onTimeChanged={onChangeMerged}
            testId={testId}
          />
          {error && <FormError error={error} testId={`${testId}-error`} />}
        </Wrapper>
      );
    }}
  />
);

export { FormTimePicker };
