import { State } from '@sim-admin-frontends/data-access';
import { Button, TTabPanelTabs, TabPanel } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import ROUTES from '../../../routing/routes';
import { TCouponsTabTypes } from '../../../types/TCoupons';
import { CtaWrapper } from '../../common/TabsStyles';
import CouponsViewContainer from './CouponsView.container';

export const couponsTabs = [TCouponsTabTypes.PUBLISHED];
type Props = {
  filter: string;
};

const CouponsTabs: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();

  const tabs: TTabPanelTabs = [
    {
      content: <CouponsViewContainer searchFilter={filter} state={State.Published} />,
    },
  ];

  return (
    <TabPanel tabs={tabs} tabIndex={0}>
      <CtaWrapper>
        <Link to={generatePath(ROUTES.couponsEdit.path)}>
          <Button testId="CouponsTabs#AddCoupon">{t('coupons.addCoupon')}</Button>
        </Link>
      </CtaWrapper>
    </TabPanel>
  );
};

export default CouponsTabs;
