import { generatePath } from 'react-router-dom';
import { History, LocationState } from 'history';
import { QueryClient, UseMutateAsyncFunction } from 'react-query';
import { format, parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  AddCouponDraftMutation,
  AddCouponDraftMutationVariables,
  AddCouponMutation,
  AddCouponMutationVariables,
  CouponInput,
  CouponValidityInput,
  RecurringPeriod,
  State,
  TUploadedFile,
  UpdateCouponDraftMutation,
  UpdateCouponDraftMutationVariables,
  UpdateCouponMutation,
  UpdateCouponMutationVariables,
} from '@sim-admin-frontends/data-access';
import { prepareFileObjects } from '@sim-admin-frontends/utils-shared';
import { TFunction } from 'i18next';

import ROUTES from '../routing/routes';
import { TAnnouncementsTabTypes } from '../types/TAnnouncements';
import { RecurrenceEnd, TCouponFormValues } from '../types/TCoupons';
import { TItemAction } from '../types/TAnalytics';
import { invalidateCouponQuery } from './queryUtils';
import { logAnnouncementEvent, logCouponEvent } from './analytics/analyticsUtils';
import { getCheckboxes } from '../components/recurrenceSettings/helper';

const DEFAULT_COUPON_RECURRENCE_EVERY = 1;

export const getDisabledColumns = (type: State) => {
  switch (type) {
    case State.Scheduled:
      return ['used'];
    case State.Draft:
      return ['publishedAt', 'used'];
    case State.Unpublished:
      return ['publishedAt'];
    default:
      return [];
  }
};

export const changeCouponsTab = (history: History<LocationState>, type: TAnnouncementsTabTypes) => {
  history.push(
    generatePath(ROUTES.couponsOverview.path, {
      type,
    }),
  );
};

export const parse24hTime = (time: string, formatString: string) =>
  format(parse(time, 'HH:mm', new Date()), formatString);

export const parse12hTime = (time: string, formatString: string) =>
  format(parse(time, 'hh:mm a', new Date()), formatString);

export const prepareFormValues = (
  values: TCouponFormValues,
  timezone: string,
  isDraft?: boolean,
  endsOn?: RecurrenceEnd,
) => {
  const newValues = {
    ...values,
    notifications: values.notifications?.map((notification) => ({
      value: zonedTimeToUtc(notification.value, timezone),
    })),
    recurrence: values.recurrence
      ? {
          ...values.recurrence,
          occurrences:
            endsOn === RecurrenceEnd.AFTER_OCCURRENCES ? values.recurrence?.occurrences : undefined,
          toDate: endsOn === RecurrenceEnd.ON_DATE ? values.recurrence?.toDate : undefined,
        }
      : undefined,
  };
  return isDraft
    ? newValues
    : {
        ...newValues,
        publishedAt: values.publishedAt ? zonedTimeToUtc(values.publishedAt, timezone) : undefined,
      };
};

export const transformFormValues = (
  institutionUuid: string,
  formData: TCouponFormValues,
  uploadedImages: TUploadedFile[] | undefined,
): CouponInput => {
  const imageObjects = prepareFileObjects(uploadedImages);
  const validity: CouponValidityInput = {
    interval: {
      fromDate: format(formData.fromDate, 'yyyy-MM-dd'),
      toDate: format(formData.toDate, 'yyyy-MM-dd'),
      fromTime: formData?.fromTime
        ? parse12hTime(`${formData.fromTime} ${formData.fromTimePeriod}`, 'HH:mm')
        : null,
      toTime: formData?.toTime
        ? parse12hTime(`${formData.toTime} ${formData.toTimePeriod}`, 'HH:mm')
        : null,
    },
    isInfinite: formData.isInfinite,
    // to check if recurrence is set, check for every since it is required in recurrence
    recurring: formData.recurrence?.every
      ? {
          type: formData.recurrence.type.value as RecurringPeriod,
          every: formData.recurrence.every || DEFAULT_COUPON_RECURRENCE_EVERY,
          day: transformDay(formData.recurrence.day) || [],
          fromDate: format(formData.fromDate, 'yyyy-MM-dd'),
          timeFrom: formData?.fromTime
            ? parse12hTime(`${formData.fromTime} ${formData.fromTimePeriod}`, 'HH:mm')
            : formData.recurrence.timeFrom,
          toDate: formData.recurrence.toDate
            ? format(formData.recurrence.toDate, 'yyyy-MM-dd')
            : null,
          timeTo: formData?.toTime
            ? parse12hTime(`${formData.toTime} ${formData.toTimePeriod}`, 'HH:mm')
            : formData.recurrence.timeTo,
          ocurrences: formData.recurrence.occurrences,
        }
      : null,
  };

  return {
    institutionUuid,
    title: formData.title,
    subtitle: formData.subtitle,
    description: formData.description,
    validity,
    imageObjects,
    notifications:
      formData.notifyNow && formData.notifications?.length && formData.notifications.length > 0
        ? formData.notifications?.map((notification) => notification.value.toISOString())
        : null,
    notifyNow: formData.notifyNow,
  };
};

export const submitCoupon = async (
  input: CouponInput,
  id: string | undefined,
  queryClient: QueryClient,
  institutionUuid: string,
  addCoupon: UseMutateAsyncFunction<AddCouponMutation, unknown, AddCouponMutationVariables>,
) => {
  const { addCoupon: result } = await addCoupon({
    coupon: { ...input, institutionUuid, id },
  });

  const isScheduled = !!input.scheduledAt;

  const action = isScheduled ? TItemAction.SCHEDULE : TItemAction.CREATE;

  if (id) invalidateCouponQuery(queryClient, id);
  if (result) logCouponEvent(result.id, institutionUuid, action);
};

export const submitUpdateCoupon = async (
  input: CouponInput,
  updateId: string,
  queryClient: QueryClient,
  institutionUuid: string,
  updateCoupon: UseMutateAsyncFunction<
    UpdateCouponMutation,
    unknown,
    UpdateCouponMutationVariables
  >,
) => {
  await updateCoupon({ id: updateId, coupon: input });
  invalidateCouponQuery(queryClient, updateId);
  logCouponEvent(updateId, institutionUuid, TItemAction.EDIT);
};

export const submitDraft = async (
  input: CouponInput,
  queryClient: QueryClient,
  institutionUuid: string,
  addDraft: UseMutateAsyncFunction<
    AddCouponDraftMutation,
    unknown,
    AddCouponDraftMutationVariables
  >,
) => {
  const { addCouponDraft: result } = await addDraft({
    coupon: input,
  });
  if (result) logAnnouncementEvent(result.id, institutionUuid, TItemAction.CREATE_DRAFT);
};

export const submitUpdateDraft = async (
  input: CouponInput,
  updateId: string,
  queryClient: QueryClient,
  institutionUuid: string,
  updateDraft: UseMutateAsyncFunction<
    UpdateCouponDraftMutation,
    unknown,
    UpdateCouponDraftMutationVariables
  >,
) => {
  await updateDraft({
    id: updateId,
    coupon: input,
  });
  invalidateCouponQuery(queryClient, updateId);
  logCouponEvent(updateId, institutionUuid, TItemAction.EDIT_DRAFT);
};

export const getRecurrenceTypeOptions = (t: TFunction) => [
  { label: t('coupon.form.recurrence.week'), value: RecurringPeriod.Weekly },
  { label: t('coupon.form.recurrence.month'), value: RecurringPeriod.Monthly },
];

export const getRecurrenceTypeOption = (t: TFunction, type: RecurringPeriod) => {
  const recurrenceTypeOptions = getRecurrenceTypeOptions(t);
  return recurrenceTypeOptions.find((option) => option.value === type);
};

export const getRecurrenceEnd = (
  toDate: string | null | undefined,
  occurrences: number | null | undefined,
) => {
  if (toDate) {
    return RecurrenceEnd.ON_DATE;
  }
  if (occurrences) {
    return RecurrenceEnd.AFTER_OCCURRENCES;
  }
  return RecurrenceEnd.NEVER;
};

export const getInitialDay = (t: TFunction, days?: number[]) =>
  getCheckboxes(t).map((c) => (days?.includes(Number(c.value)) ? c.value : false));

export const transformDay = (days?: (string | boolean | undefined)[] | null) =>
  days?.filter((day): day is string => typeof day === 'string').map((day) => Number(day));

export const getDateMaxTime = (date: Date) => new Date(date.setHours(23, 59, 59, 0));

export const getPublishingDateAccessor = (type: State) => {
  switch (type) {
    case State.Published:
      return 'publishedAt';
    default:
      return 'createdAt'; // doesnt really matter for other states its disabled anyway
  }
};
