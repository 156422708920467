import React, { FC, useState, useEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Modal,
  Button,
  FormInput,
  PlusIcon,
  Caption1,
  LargeText,
  FormTextarea,
  MODAL_TRANSITION_LENGTH_MS,
  TToastType,
  toast,
  getErrorMessage,
} from '@sim-admin-frontends/ui-shared';
import {
  useAddMessagingCategoryMutation,
  useUpdateMessagingCategoryMutation,
} from '@sim-admin-frontends/data-access-admin-be';

import {
  TMessagingCategory,
  TMessagingCategoryFormValues,
} from '../../../types/TMessagingCategories';
import { useAuthInfo } from '../../../contexts/userContext';

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 360px;
  padding: ${({ theme }) => theme.spaces.spacing24};
  text-align: left;
`;

const RowAddEmail = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.spacing16};
`;

const Headline = styled(LargeText)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const Text = styled(Caption1)`
  display: inline-block;
  padding-top: ${({ theme }) => theme.spaces.spacing8};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  white-space: pre-line;
`;

const AddText = styled(Caption1)`
  margin-left: 10px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const StyledFormTextarea = styled(FormTextarea)`
  min-height: 56px;
`;

const FormButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.spaces.spacing24};
`;

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spaces.spacing8};
  &:first-child {
    margin-top: 0;
  }
`;

const schema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(t('common.validation.required')).max(255),
    description: Yup.string().max(64),
    email: Yup.string().required().email(t('common.validation.email')),
    additionalEmails: Yup.array()
      .of(Yup.string().email(t('common.validation.email')).max(255))
      .compact()
      .nullable(true),
  });

const MAX_NUM_OF_EMAILS = 5;

const initialValues: TMessagingCategoryFormValues = {
  name: '',
  email: '',
  description: '',
  additionalEmails: null,
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedCategory: TMessagingCategory | null;
  onMutateSuccess?: () => void;
};

const MessagingCategoryAddModal: FC<Props> = ({
  isOpen,
  onClose,
  selectedCategory,
  onMutateSuccess,
}) => {
  const { t } = useTranslation();
  const { placeUuid } = useAuthInfo();
  const [additionalEmailsCount, setAdditionalEmailsCount] = useState(0);

  const { mutateAsync: addMessagingCategory } = useAddMessagingCategoryMutation({
    onSuccess: onMutateSuccess,
  });
  const { mutateAsync: updateMessagingCategory } = useUpdateMessagingCategoryMutation({
    onSuccess: onMutateSuccess,
  });

  let modalHeadline = t(`settings.messaging.categories.createModal.heading`);
  let modalHeadlineDescription = t(`settings.messaging.categories.createModal.description`);

  if (selectedCategory) {
    modalHeadline = t(`settings.messaging.categories.editModal.heading`);
    modalHeadlineDescription = t(`settings.messaging.categories.editModal.description`);
  }

  const methods = useForm<TMessagingCategoryFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: true,
  });

  const { handleSubmit, register, formState, reset } = methods;
  const { errors, isSubmitting, isDirty, isValid } = formState;

  useEffect(() => {
    if (selectedCategory) {
      const { name, email, description, additionalEmails } = selectedCategory;
      reset({
        name: name || '',
        email: email || '',
        description: description || '',
        additionalEmails: additionalEmails || null,
      });
      if (additionalEmails && additionalEmails.length > 0) {
        setAdditionalEmailsCount(additionalEmails.length);
      }
    }
  }, [selectedCategory, isOpen]);

  const submit = async (values: TMessagingCategoryFormValues) => {
    try {
      if (!placeUuid) {
        throw new Error(t('error.globalError'));
      }
      let toastSuccessMessage = t(`settings.messaging.categories.createModal.toastSuccess`);
      if (selectedCategory) {
        toastSuccessMessage = t(`settings.messaging.categories.editModal.toastSuccess`);
        await updateMessagingCategory({
          id: selectedCategory.id,
          data: {
            placeUuid,
            ...values,
          },
        });
      } else {
        await addMessagingCategory({
          data: {
            placeUuid,
            ...values,
          },
        });
      }
      handleClose();
      toast(t(toastSuccessMessage), TToastType.SUCCESS);
    } catch (err) {
      toast(getErrorMessage(err), TToastType.ERROR);
    }
  };

  const onSubmitClick = () => {
    handleSubmit(submit)();
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setAdditionalEmailsCount(0);
      reset(initialValues);
    }, MODAL_TRANSITION_LENGTH_MS);
  };

  const onAddEmailClick = () => {
    setAdditionalEmailsCount(additionalEmailsCount + 1);
  };

  const showAddEmail = additionalEmailsCount <= MAX_NUM_OF_EMAILS;

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Wrapper>
        <Header>
          <Headline>{t(modalHeadline)}</Headline>
          {!selectedCategory && <Text>{t(modalHeadlineDescription)}</Text>}
        </Header>
        <FormProvider {...methods}>
          <FormInput
            label={t('settings.messaging.categories.form.name')}
            testId="Settings#MessagesCategories#Name"
            {...register('name')}
            error={errors.name}
          />
          <StyledFormTextarea
            label={t('settings.messaging.categories.form.description')}
            {...register('description')}
            error={errors.description}
            testId="Settings#MessagesCategory#Description"
          />
          <FormInput
            label={t('settings.messaging.categories.form.email')}
            {...register('email')}
            error={errors.email}
            testId="Settings#MessagesCategory#Email"
          />
          {[...new Array(additionalEmailsCount)].map((_, index) => {
            return (
              <FormInput
                key={index}
                label={t('settings.messaging.categories.form.email')}
                {...register(`additionalEmails.${index}`, {
                  required: false,
                })}
                error={
                  Array.isArray(errors.additionalEmails)
                    ? errors.additionalEmails[index]
                    : errors.additionalEmails
                }
                testId="Settings#MessagesCategory#AdditionalEmail"
              />
            );
          })}
          {showAddEmail && (
            <RowAddEmail data-testid="Settings#MessagesCategory#AddEmail" onClick={onAddEmailClick}>
              <PlusIcon /> <AddText>{t('settings.messaging.categories.addEmail')}</AddText>
            </RowAddEmail>
          )}
          <FormButtonsWrapper>
            <StyledButton
              type="submit"
              onClick={onSubmitClick}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isDirty || !isValid}
              testId="Settings#MessagesCategory#Save"
            >
              {t('common.save')}
            </StyledButton>
            <StyledButton
              variant="bordered"
              onClick={handleClose}
              disabled={isSubmitting}
              testId="Settings#MessagesCategory#Cancel"
            >
              {t('common.cancel')}
            </StyledButton>
          </FormButtonsWrapper>
        </FormProvider>
      </Wrapper>
    </Modal>
  );
};

export default MessagingCategoryAddModal;
