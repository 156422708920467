import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${({ theme }) => theme.spaces.spacing12} 0;
`;

const Dot = styled.div<{ color: string; isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  margin-right: ${({ theme }) => theme.spaces.spacing12};
  background-color: ${({ isActive, color, theme }) => (isActive ? color : theme.colors.coolGray50)};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
`;

const InnerDot = styled.div`
  display: flex;
  width: 5px;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.radiusMax};
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.caption1};
  line-height: ${({ theme }) => theme.lineHeights.large};
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  color: ${({ theme }) => theme.colors.coolGray100};
`;

type Props = {
  label: string;
  color: string;
  onClick: () => void;
  isActive: boolean;
  testId?: string;
};
const LegendButton: FC<Props> = ({
  label,
  color,
  onClick,
  isActive,
  testId = 'DashboardChartLegend',
}) => (
  <Wrapper onClick={onClick}>
    <Dot color={color} isActive={isActive}>
      {isActive && <InnerDot />}
    </Dot>
    <Label data-testid={`${testId}#${label}`}>{label}</Label>
  </Wrapper>
);

export { LegendButton };
