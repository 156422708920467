import { TableIcons, TBaseProps } from '@sim-admin-frontends/ui-shared';
import { FC } from 'react';

import { useGeneratePetDirectoryActions } from '../../../hooks/actionButtons/useGeneratePetDirectoryActions';

export interface PollsTableMenuProps extends TBaseProps {
  petId: string;
  refetch: () => void;
  published?: boolean;
  isPublished?: boolean;
}

const PetDirectoryTableMenu: FC<PollsTableMenuProps> = ({
  petId,
  refetch,
  testId,
  isPublished,
}) => {
  const { renderModal, listActions: items } = useGeneratePetDirectoryActions({
    petId,
    refetch,
    isPublished,
  });
  return (
    <TableIcons testId={testId} items={items}>
      {renderModal()}
    </TableIcons>
  );
};

export { PetDirectoryTableMenu };
